import { CommonResultId } from '../common/CommonResultId'
import { EventStatusId } from '../EventStatusId'
import { ResultId } from '../ResultId'
import { HandballEventStatusId } from './EventStatusId'
import { HandballResultId } from './HandballResultId'

export const handballStatusResultsMap: {
  [key in EventStatusId]?: ResultId[]
} = {
  [HandballEventStatusId.FirstHalf]: [
    CommonResultId.Result,
    CommonResultId.RegularTime,
    HandballResultId.FirstHalf,
  ],
  [HandballEventStatusId.SecondHalf]: [
    CommonResultId.Result,
    CommonResultId.RegularTime,
    HandballResultId.SecondHalf,
  ],
  [HandballEventStatusId.ExtratimeFirstHalf]: [
    CommonResultId.Result,
    HandballResultId.FirstExtratimeFirstHalf,
    CommonResultId.Result,
  ],
  [HandballEventStatusId.ExtratimeSecondHalf]: [
    CommonResultId.Result,
    HandballResultId.FirstExtratimeSecondHalf,
    CommonResultId.Result,
  ],
  [HandballEventStatusId.SecondExtratimeFirstHalf]: [
    CommonResultId.Result,
    HandballResultId.SecondExtratimeFirstHalf,
    CommonResultId.Result,
  ],
  [HandballEventStatusId.SecondExtratimeSecondHalf]: [
    CommonResultId.Result,
    HandballResultId.SecondExtratimeSecondHalf,
    CommonResultId.Result,
  ],
  [HandballEventStatusId.PenaltyShootout]: [CommonResultId.Penalty],
}
