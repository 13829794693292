/* eslint-disable no-console */
import { registerSW } from 'virtual:pwa-register'

import { showLoader } from '@mobile/globalState/loader'

export let swRegistration: ServiceWorkerRegistration | undefined
export const updateSW = registerSW({
  onOfflineReady() {
    console.info(
      'No internet connection found. App is running in offline mode.',
    )
  },
  onNeedRefresh() {
    showLoader('ScoutsCollector is updating. Please wait...')
    console.info('ScoutsCollector is updating.')
    updateSW()
  },
  onRegisteredSW(_, r) {
    console.info('Service worker has been registered.')
    swRegistration = r
  },
  onRegisterError(error) {
    console.error('Error during service worker registration:', error)
  },
})
