<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { TeamSide } from '@collector/sportsapi-client-legacy'
import { BallPosition } from '@mobile/reusables/types/position/BallPosition'
import RelationIcon, {
  RelationIconType,
} from '@mobile/views/Relation/Shared/RelationIcon/RelationIcon.vue'

export default defineComponent({
  components: { RelationIcon },
  props: {
    position: {
      type: Object as PropType<BallPosition>,
      required: true,
    },
    incidentId: {
      type: Number,
      required: false,
    },
    side: {
      type: String as PropType<TeamSide>,
      required: false,
    },
  },
  setup(props) {
    function getBallColor(): string {
      return `bg-${props.side} ring-${props.side}`
    }

    return {
      getBallColor,
      RelationIconType,
    }
  },
})
</script>

<template>
  <RelationIcon
    v-if="incidentId"
    class="absolute -mx-2.5 -my-2.5 h-5 w-5 rounded-full ring-4 ring-opacity-40"
    :class="getBallColor()"
    :iconId="incidentId"
    :size="20"
    :style="{ top: `${position.y}px`, left: `${position.x}px` }"
    :type="RelationIconType.Incidents"
  />

  <div
    v-if="!incidentId"
    class="absolute -mx-1 -my-1 h-2 w-2 rounded-full ring-4 ring-opacity-50"
    :class="getBallColor()"
    :style="{ top: `${position.y}px`, left: `${position.x}px` }"
  ></div>
</template>
