import {
  CommonResultId,
  SoccerResultId,
  SoccerStatId,
} from '@collector/sportsapi-client-legacy'

import { ResultIdsSet } from '../../ParticipantsDataTable/ResultIdsSet'
import { StatIdsSet } from '../../ParticipantsDataTable/StatIdsSet'
import { VerifyResultsSportFactory } from './VerifyResultsSportFactory'

export function verifyResultsSoccerFactory(): VerifyResultsSportFactory {
  function getResultsIdsSet(): ResultIdsSet {
    return new ResultIdsSet([
      CommonResultId.Result,
      CommonResultId.RegularTime,
      SoccerResultId.FirstHalf,
      SoccerResultId.SecondHalf,
      SoccerResultId.ExtratimeFirstHalf,
      SoccerResultId.ExtratimeSecondHalf,
      CommonResultId.Overtime,
      CommonResultId.Penalty,
    ])
  }

  function getStatIdsSet(): StatIdsSet {
    return new StatIdsSet([
      SoccerStatId.Corners,
      SoccerStatId.YellowCards,
      SoccerStatId.RedCards,
    ])
  }

  return {
    getResultsIdsSet,
    getStatIdsSet,
  }
}
