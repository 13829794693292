export const enum VolleyballEventStatusId {
  NotStarted = 1,
  Interrupted = 2,
  Cancelled = 3,
  Postponed = 5,
  StartDelayed = 6,
  Abandoned = 7,
  Finished = 11,
  FinishedAwardedWin = 12,
  SecondSet = 65,
  FirstSet = 134,
  ThirdSet = 135,
  FourthSet = 136,
  FifthSet = 137,
  ToFinish = 152,
  GoldenSet = 154,
  BreakAfterFirstSet = 155,
  BreakAfterSecondSet = 156,
  BreakAfterThirdSet = 157,
  BreakAfterFourthSet = 158,
  BreakAfterFifthSet = 159,
}
