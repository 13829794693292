<script lang="ts">
import { chain } from 'lodash'
import { computed, defineComponent } from 'vue'

import {
  Confirmation,
  CricketAttributeId,
  CricketIncidentId,
  LegalIllegalBallAttributesMap,
  Participant,
} from '@collector/sportsapi-client-legacy'
import {
  isIncidentDeletable,
  ProbableIncident,
} from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import Button from '@mobile/components/Button/Button.vue'
import * as popup from '@mobile/globalState/popup'
import { useEventStatus } from '@mobile/views/Relation/Shared/EventStatus/useEventStatus'
import {
  ProbableLinkedIncident,
  ProbableLinkedParticipant,
} from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useOverlays } from '@mobile/views/Relation/Sports/Cricket/useOverlays'

import { useCricketConfiguration } from '../../CricketRelationDependencies/useCricketConfiguration'
import EditBall from '../../Popups/EditBall/EditBall.vue'

export default defineComponent({
  components: { Button },
  setup() {
    const { activeParticipant } = useCricketConfiguration()
    function isParticipantActive(
      participant: ProbableLinkedParticipant,
    ): boolean {
      const { id: activeParticipantId } = activeParticipant.value
        .participant as Participant
      const { id: participantId } = participant.getParticipant().value

      return activeParticipantId === participantId
    }
    const {
      probableEvent,
      linkedProbableIncidents,
      probableLinkedFirstParticipant,
      probableLinkedSecondParticipant,
      sport,
    } = useEventInjections()
    const { isMatchStarted } = useEventStatus(probableEvent)
    const { visibleOverlay, overlayComponents } = useOverlays()

    const filteredIncidents = computed(() =>
      linkedProbableIncidents.value.filter(
        (linkedProbableIncident) =>
          [CricketIncidentId.LegalBall, CricketIncidentId.IllegalBall].includes(
            linkedProbableIncident.incident.incident_id as CricketIncidentId,
          ) &&
          linkedProbableIncident.incident.participant_id ===
            activeParticipant.value?.participant?.id,
      ),
    )

    const probableLinkedParticipant = computed(() => {
      if (isParticipantActive(probableLinkedFirstParticipant.value)) {
        return probableLinkedFirstParticipant.value
      }

      return probableLinkedSecondParticipant.value
    })

    function mapAttributeName(attribute: CricketAttributeId): string {
      return LegalIllegalBallAttributesMap[attribute]
    }

    function getIncidentOverBallInformation(
      probableIncident: ProbableIncident,
    ): string {
      const { current_ball, current_over } =
        probableIncident.additional_info ?? {}
      const information = `${current_over ?? '-'}.${current_ball ?? '-'}`

      if (probableIncident.incident_id !== CricketIncidentId.IllegalBall) {
        return information
      }

      const currentOverIncidents = filteredIncidents.value
        .filter(
          ({ incident }) =>
            incident.additional_info?.current_over === current_over,
        )
        .reverse()
      const incidentIndex = currentOverIncidents.findIndex(
        ({ incident }) => incident.id === probableIncident.id,
      )

      const index = chain(currentOverIncidents)
        .take(incidentIndex + 1)
        .takeRightWhile(
          ({ incident: { incident_id } }) =>
            incident_id === CricketIncidentId.IllegalBall,
        )
        .findIndex(({ incident: { id } }) => id === probableIncident.id)
        .value()

      return `${information}-${index + 1}`
    }

    function prevIncidentIsOut(
      linkedProbableIncident: ProbableLinkedIncident,
    ): boolean {
      const filteredIncidents = linkedProbableIncidents.value.filter(
        (linkedProbableIncident) =>
          [
            CricketIncidentId.LegalBall,
            CricketIncidentId.IllegalBall,
            CricketIncidentId.Out,
          ].includes(
            linkedProbableIncident.incident.incident_id as CricketIncidentId,
          ),
      )

      const incidentIndex = filteredIncidents.findIndex(
        ({ incident }) => incident.id === linkedProbableIncident.incident.id,
      )
      if (incidentIndex === -1) {
        return false
      }

      const lastIncident = filteredIncidents[incidentIndex + 1]

      return lastIncident?.incident?.incident_id === CricketIncidentId.Out
    }

    function isDeletable(
      probableLinkedIncident: ProbableLinkedIncident,
    ): boolean {
      return isIncidentDeletable(probableLinkedIncident.incident, sport)
    }

    function openPopup(incident: ProbableIncident): void {
      popup.show({
        component: EditBall,
        props: {
          incident,
          probableLinkedParticipant,
        },
        showHeader: true,
      })
    }

    return {
      isDeletable,
      isMatchStarted,
      CricketIncidentId,
      visibleOverlay,
      overlayComponents,
      filteredIncidents,
      mapAttributeName,
      openPopup,
      isParticipantActive,
      getIncidentOverBallInformation,
      prevIncidentIsOut,
      Confirmation,
      isIncidentDeletable,
    }
  },
})
</script>

<template>
  <div
    v-if="isMatchStarted"
    class="col-span-full grid h-24 grid-flow-col gap-2 overflow-auto"
  >
    <div class="flex space-x-2">
      <Button
        v-for="linkedProbableIncident in filteredIncidents"
        :key="linkedProbableIncident.incident.incident_id"
        class="min-w-14"
        :color="isDeletable(linkedProbableIncident) ? 'white' : 'red'"
        size="xl"
        :disabled="!isDeletable(linkedProbableIncident)"
        data-test-id="EditBallButton"
        @click="openPopup(linkedProbableIncident.incident)"
      >
        <div
          v-if="linkedProbableIncident.incident.attribute_ids.length"
          class="flex flex-col space-y-1"
        >
          <div class="font-normal">
            {{
              getIncidentOverBallInformation(linkedProbableIncident.incident)
            }}
          </div>
          <div>
            <span
              v-for="attribute in linkedProbableIncident.incident.attribute_ids"
              :key="attribute"
            >
              {{ mapAttributeName(attribute as CricketAttributeId) }}
            </span>
            <span>
              {{ prevIncidentIsOut(linkedProbableIncident) ? 'W' : '' }}
            </span>
          </div>
        </div>
        <span v-else>-</span>
      </Button>
    </div>
  </div>
</template>
