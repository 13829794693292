export abstract class ProbableTimeAction {
  public abstract isTimeSetter(): boolean
  public abstract getTimeToSet(): number | null
  public abstract isTimeStarter(): boolean
  public abstract isTimeStopper(): boolean
  public abstract getActionTime(): number

  public isTimeStatusSetter(): boolean {
    return this.isTimeStarter() || this.isTimeStopper()
  }

  public isTimeOrStatusSetter(): boolean {
    return this.isTimeStatusSetter() || this.isTimeSetter()
  }
}
