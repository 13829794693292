<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { CricketIncidentId } from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'
import {
  IncidentStorePayload,
  useIncidentAction,
} from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

import ExtrasButton from '../../Buttons/ExtrasButton.vue'
import NoRunsButton from '../../Buttons/NoRunsButton.vue'
import { useCricketConfiguration } from '../../CricketRelationDependencies/useCricketConfiguration'
import { prepareAdditionalInfo } from '../../prepareAdditionalInfo'
import { NumberOfRunsPopup } from '../NumberOfRuns/NumberOfRunsPopup'
import { WicketOutcomePopup } from '../WicketOutcome/WicketOutcomePopup'

export default defineComponent({
  components: {
    Button,
    NoRunsButton,
    ExtrasButton,
  },
  props: {
    context: {
      type: Object as PropType<PopupContext | null>,
      required: false,
    },
  },
  setup(props) {
    const eventInjections = useEventInjections()
    const { probableIncidents } = eventInjections
    const { activeParticipant } = useCricketConfiguration()

    function proceed(incidentId: CricketIncidentId): void {
      const additionalInfo = prepareAdditionalInfo(
        activeParticipant,
        probableIncidents,
      )

      const payload = {
        incident_id: incidentId,
        participant_team_id: activeParticipant.value.participant?.id,
        additional_info: additionalInfo,
      }
      const incidentActionComposable = useIncidentAction(
        eventInjections,
        payload,
      )

      props.context?.openPopup(WicketOutcomePopup, { incidentActionComposable })
    }

    function proceedRuns(): void {
      props.context?.openPopup(NumberOfRunsPopup)
    }

    function proceedDeadBall(): void {
      const additionalInfo = prepareAdditionalInfo(
        activeParticipant,
        probableIncidents,
      )
      const payload: IncidentStorePayload = {
        incident_id: CricketIncidentId.DeadBall,
        participant_team_id: activeParticipant.value.participant?.id,
        additional_info: additionalInfo,
      }

      useIncidentAction(eventInjections, payload)

      props.context?.closeAllPopups()
    }

    function getIncidentName(incidentId: CricketIncidentId): string {
      return eventInjections.sport.value.getIncidentTranslation(incidentId)
    }

    return {
      CricketIncidentId,
      proceed,
      proceedRuns,
      proceedDeadBall,
      getIncidentName,
    }
  },
})
</script>

<template>
  <div class="grid grid-cols-3 grid-rows-3 gap-4 px-4">
    <NoRunsButton v-bind="{ context }" />
    <Button
      :size="'sm'"
      :color="'white'"
      :withIcon="false"
      :type="'horizontal'"
      @click="proceedRuns()"
    >
      Runs
    </Button>
    <ExtrasButton v-bind="{ context }" />
    <Button
      :size="'sm'"
      :color="'white'"
      :withIcon="false"
      :type="'horizontal'"
      @click="proceed(CricketIncidentId.DismissalRunOut)"
    >
      {{ getIncidentName(CricketIncidentId.DismissalRunOut) }}
    </Button>
    <Button
      :size="'sm'"
      :color="'white'"
      :withIcon="false"
      :type="'horizontal'"
      @click="proceed(CricketIncidentId.DismissalCaughtDisc)"
    >
      {{ getIncidentName(CricketIncidentId.DismissalCaughtDisc) }}
    </Button>
    <Button
      :size="'sm'"
      :color="'white'"
      :withIcon="false"
      :type="'horizontal'"
      @click="proceedDeadBall"
    >
      {{ getIncidentName(CricketIncidentId.DeadBall) }}
    </Button>
  </div>
</template>
