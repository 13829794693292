<script lang="ts">
import { defineComponent } from 'vue'

import { CricketIncidentId } from '@collector/sportsapi-client-legacy'
import ParticipantLogo from '@mobile/components/ParticipantLogo/ParticipantLogo.vue'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: {
    ParticipantLogo,
    IncidentButton,
  },
  setup() {
    const { probableAwayParticipant, probableHomeParticipant } =
      useEventInjections()

    return {
      probableAwayParticipant,
      probableHomeParticipant,
      CricketIncidentId,
    }
  },
})
</script>

<template>
  <IncidentButton
    class="grid h-full w-1/3 items-center"
    :type="'horizontal'"
    :participantId="probableHomeParticipant.id"
    :incidentId="CricketIncidentId.FirstInningsHomeStarted"
    :color="'home'"
    :size="'sm'"
    :withIcon="false"
  >
    <div class="flex flex-col">
      <ParticipantLogo :participant="probableHomeParticipant" />
      <div class="text-center">
        {{ probableHomeParticipant.short_name }}
      </div>
    </div>
  </IncidentButton>

  <IncidentButton
    class="grid h-full w-1/3 items-center"
    :type="'horizontal'"
    :participantId="probableAwayParticipant.id"
    :incidentId="CricketIncidentId.FirstInningsAwayStarted"
    :color="'home'"
    :size="'sm'"
    :withIcon="false"
  >
    <div class="flex flex-col">
      <ParticipantLogo :participant="probableAwayParticipant" />
      <div class="text-center">
        {{ probableAwayParticipant.short_name }}
      </div>
    </div>
  </IncidentButton>
</template>
