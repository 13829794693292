import { BasketballIncidentId } from '@collector/sportsapi-client-legacy'

export const BasketballManualAddIncidentIds = [
  BasketballIncidentId.TwoPointShotMissed,
  BasketballIncidentId.ThreePointShotMissed,
  BasketballIncidentId.OffensiveRebound,
  BasketballIncidentId.DefensiveRebound,
  BasketballIncidentId.Foul,
  BasketballIncidentId.Turnover,
  BasketballIncidentId.Block,
  BasketballIncidentId.Steal,
]

export const BasketballManualAddIncidentSubstitutionIds = []
