<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import { nullCoalesce, opChain } from '@collector/shared-utils'
import {
  CricketEventStatusId,
  CricketResultId,
} from '@collector/sportsapi-client-legacy'
import { byId } from '@mobile/reusables/entityUtils'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useCricketMainResultExtractor } from '@mobile/views/Relation/Sports/Cricket/useCricketMainResultExtractor'

import { useCricketConfiguration } from '../../../CricketRelationDependencies/useCricketConfiguration'

export default defineComponent({
  props: {
    probableLinkedParticipant: {
      type: Object as PropType<ProbableLinkedParticipant>,
      required: true,
    },
  },
  setup() {
    const { activeParticipant } = useCricketConfiguration()
    const { probableEvent } = useEventInjections()
    const { extractedValues } = useCricketMainResultExtractor(
      activeParticipant,
      probableEvent,
    )

    // TOOD: remove all of it bellow when we will have proper computation of main result
    const inningRuns = computed(() => {
      if (!activeParticipant.value.participant) {
        return null
      }

      return byId(
        activeParticipant.value.participant.results,
        CricketResultId.FirstInningRuns,
      )
    })

    const wickets = computed(() => {
      if (!activeParticipant.value.participant) {
        return null
      }

      return byId(
        activeParticipant.value.participant.results,
        CricketResultId.Wickets,
      )
    })

    const isSuperOverStatus = computed(() =>
      [
        CricketEventStatusId.SuperOverHome,
        CricketEventStatusId.SuperOverAway,
      ].includes(probableEvent.value.status_id as CricketEventStatusId),
    )

    return {
      extractedValues,
      isSuperOverStatus,
      inningRuns,
      wickets,
      opChain,
      nullCoalesce,
    }
  },
})
</script>

<template>
  <div
    class="shadow-condensed flex h-7 items-center justify-center rounded p-1 text-xl font-bold text-white"
    :class="probableLinkedParticipant.css.bgClass"
  >
    <template v-if="isSuperOverStatus">
      {{ extractedValues.runs }} / {{ extractedValues.wickets }}
    </template>
    <template v-else>
      {{
        nullCoalesce(
          opChain(inningRuns, (v) => v.value),
          '-',
        )
      }}
      /
      {{
        nullCoalesce(
          opChain(wickets, (v) => v.value),
          '-',
        )
      }}
    </template>
  </div>
</template>
