import { Component } from 'vue'

import Shirt1 from '@mobile/components/Skins/Shirt1.vue'
import Shirt2 from '@mobile/components/Skins/Shirt2.vue'
import Shirt3 from '@mobile/components/Skins/Shirt3.vue'
import Shirt4 from '@mobile/components/Skins/Shirt4.vue'
import Shirt5 from '@mobile/components/Skins/Shirt5.vue'
import Shirt6 from '@mobile/components/Skins/Shirt6.vue'
import Shirt7 from '@mobile/components/Skins/Shirt7.vue'
import Shirt8 from '@mobile/components/Skins/Shirt8.vue'
import Shirt9 from '@mobile/components/Skins/Shirt9.vue'
import Shirt10 from '@mobile/components/Skins/Shirt10.vue'
import Shirt11 from '@mobile/components/Skins/Shirt11.vue'

export const skinPatterns: ReadonlyMap<number, Component> = new Map([
  [1, Shirt1],
  [2, Shirt2],
  [3, Shirt3],
  [4, Shirt4],
  [5, Shirt5],
  [6, Shirt6],
  [7, Shirt7],
  [8, Shirt8],
  [9, Shirt9],
  [10, Shirt10],
  [11, Shirt11],
])
