<script lang="ts">
import { defineComponent } from 'vue'

import { opChain } from '@collector/shared-utils'
import { BeachVolleyballIncidentId } from '@collector/sportsapi-client-legacy'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import { useBeachVolleyballConfiguration } from '@mobile/views/Relation/Sports/BeachVolleyball/BeachVolleyballRelationDependencies/useBeachVolleyballConfiguration'

export default defineComponent({
  components: { IncidentButton },

  setup() {
    const { activeParticipant } = useBeachVolleyballConfiguration()

    return {
      BeachVolleyballIncidentId,
      activeParticipant,
      opChain,
    }
  },
})
</script>

<template>
  <div class="flex flex-col space-y-4">
    <IncidentButton
      class="flex-1"
      :type="'horizontal'"
      :size="'xl'"
      :color="activeParticipant.color"
      :incidentId="BeachVolleyballIncidentId.ServiceError"
      :participantId="opChain(activeParticipant.participant, (v) => v.id)"
    />
    <IncidentButton
      class="flex-1"
      :type="'horizontal'"
      :size="'xl'"
      :color="activeParticipant.color"
      :incidentId="BeachVolleyballIncidentId.AttackError"
      :participantId="opChain(activeParticipant.participant, (v) => v.id)"
    />

    <div class="flex flex-1 space-x-4">
      <IncidentButton
        class="h-full w-1/2"
        :type="'vertical'"
        :size="'md'"
        :color="'white'"
        :incidentId="BeachVolleyballIncidentId.TechnicalErrors"
      />
      <IncidentButton
        class="h-full w-1/2"
        :type="'vertical'"
        :size="'md'"
        :color="'white'"
        :incidentId="BeachVolleyballIncidentId.Point"
      />
    </div>
  </div>
</template>
