import { SportId } from '@collector/sportsapi-client-legacy'

import { verifyResultsAmericanFootballFactory } from './verifyResultsAmericanFootballFactory'
import { verifyResultsBasketball3x3Factory } from './verifyResultsBasketball3x3Factory'
import { verifyResultsBasketballFactory } from './verifyResultsBasketballFactory'
import { verifyResultsBeachVolleyballFactory } from './verifyResultsBeachVolleyballFactory'
import { verifyResultsCricketFactory } from './verifyResultsCricketFactory'
import { verifyResultsDefaultFactory } from './verifyResultsDefaultFactory'
import { verifyResultsHandballFactory } from './verifyResultsHandballFactory'
import { verifyResultsSoccerFactory } from './verifyResultsSoccerFactory'
import { VerifyResultsSportFactory } from './VerifyResultsSportFactory'
import { verifyResultsVolleyballFactory } from './verifyResultsVolleyballFactory'

const factories: { [key: number]: VerifyResultsSportFactory | undefined } = {
  [SportId.Soccer]: verifyResultsSoccerFactory(),
  [SportId.Handball]: verifyResultsHandballFactory(),
  [SportId.Basketball]: verifyResultsBasketballFactory(),
  [SportId.Cricket]: verifyResultsCricketFactory(),
  [SportId.Volleyball]: verifyResultsVolleyballFactory(),
  [SportId.BeachVolleyball]: verifyResultsBeachVolleyballFactory(),
  [SportId.AmericanFootball]: verifyResultsAmericanFootballFactory(),
  [SportId.Basketball3x3]: verifyResultsBasketball3x3Factory(),
}

export function useVerifyResultsFactory(
  sportId: SportId,
): VerifyResultsSportFactory {
  return factories[sportId] ?? verifyResultsDefaultFactory()
}
