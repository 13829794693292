<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'

import ByesButton from '../../Buttons/ByesButton.vue'
import LegByesButton from '../../Buttons/LegByesButton.vue'
import NoBallBatRunsButton from '../../Buttons/NoBallBatRunsButton.vue'
import NoBallRunsButton from '../../Buttons/NoBallRunsButton.vue'
import NoRunsButton from '../../Buttons/NoRunsButton.vue'
import RunsButton from '../../Buttons/RunsButton.vue'
import WidesButton from '../../Buttons/WidesButton.vue'

export default defineComponent({
  components: {
    WidesButton,
    NoBallRunsButton,
    NoBallBatRunsButton,
    LegByesButton,
    ByesButton,
    NoRunsButton,
    RunsButton,
  },
  props: {
    context: {
      type: Object as PropType<PopupContext | null>,
      required: false,
    },
  },
})
</script>

<template>
  <div>
    <div class="grid grid-cols-3 grid-rows-3 gap-4 px-4">
      <NoRunsButton v-bind="{ context }" />
      <RunsButton v-bind="{ context }" />
      <WidesButton v-bind="{ context }" />
      <NoBallRunsButton v-bind="{ context }" />
      <NoBallBatRunsButton v-bind="{ context }" />
      <LegByesButton v-bind="{ context }" />
      <ByesButton v-bind="{ context }" />
    </div>
  </div>
</template>
