<script lang="ts">
import ResizeObserver from 'resize-observer-polyfill'
import { computed, defineComponent, ref, watch } from 'vue'

import { opChain } from '@collector/shared-utils'
import { eventBus } from '@mobile/globalState/eventBus'
import * as popup from '@mobile/globalState/popup'
import { BallPosition } from '@mobile/reusables/types/position/BallPosition'
import { getEventCoords } from '@mobile/reusables/useGestureDirective'
import { usePressTap } from '@mobile/reusables/usePressTap'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import PitchBall from '@mobile/views/Relation/Sports/Soccer/LiveView/Pitch/PitchBall.vue'
import { useBallPositionConverters } from '@mobile/views/Relation/Sports/Soccer/LiveView/Pitch/useBallPositionConverters'
import { useBallPossessionPlugin } from '@mobile/views/Relation/Sports/Soccer/LiveView/Pitch/useBallPossessionPlugin'
import { usePitchOverlays } from '@mobile/views/Relation/Sports/Soccer/LiveView/Pitch/usePitchOverlays'
import {
  PossessionDirection,
  usePossessionDirection,
} from '@mobile/views/Relation/Sports/Soccer/LiveView/Pitch/usePossessionDirection'
import IncidentsWithPositionPopup from '@mobile/views/Relation/Sports/Soccer/Popups/IncidentsWithPosition/IncidentsWithPositionPopup.vue'
import { useSoccerConfiguration } from '@mobile/views/Relation/Sports/Soccer/SoccerRelationDependencies/useSoccerConfiguration'

export default defineComponent({
  components: { PitchBall },
  emits: ['sidesSwitched'],
  setup() {
    const {
      actionQueue,
      probableEvent,
      probableClock,
      sidesSwitched,
      probableHomeParticipant,
      probableAwayParticipant,
      linkedProbableIncidents,
    } = useEventInjections()
    const { possession } = useSoccerConfiguration()

    const {
      convertSportsAPIPositionToOurPosition,
      convertOurPositionToSportsAPIPosition,
    } = useBallPositionConverters(sidesSwitched)
    const { visibleOverlay, overlayComponents } = usePitchOverlays()

    const interactiveArea = ref<HTMLElement | null>(null)
    const pitchTopRef = ref<HTMLImageElement | null>(null)
    const pitchTopDimensions = ref({
      width: 0,
      height: 0,
    })
    const pitchTopResizeObserver = new ResizeObserver(() => {
      if (pitchTopRef.value && interactiveArea.value) {
        pitchTopDimensions.value.width = pitchTopRef.value.width
        pitchTopDimensions.value.height = pitchTopRef.value.height
        interactiveArea.value.style.width = `${pitchTopRef.value.width}px`
        interactiveArea.value.style.height = `${pitchTopRef.value.height}px`
      }
    })
    watch(pitchTopRef, (ref) => {
      if (ref) {
        pitchTopResizeObserver.disconnect()
        pitchTopResizeObserver.observe(ref)
      }
    })

    const {
      lastIncident,
      incidentWithPositionIfLast,
      positionableIncidentIfLast,
      addOrUpdatePossessionIncident,
      updatePositionFor,
    } = useBallPossessionPlugin(linkedProbableIncidents)
    const { possessionDirection } = usePossessionDirection(
      sidesSwitched,
      possession.current,
    )

    function getOurPosition(event: TouchEvent | PointerEvent): BallPosition {
      const boundingRect = (
        event.target as HTMLElement
      ).getBoundingClientRect?.()
      const { x, y } = getEventCoords(event)

      return {
        x: convertOurPositionToSportsAPIPosition(
          x - boundingRect.left,
          pitchTopDimensions.value.width,
        ),
        y: convertOurPositionToSportsAPIPosition(
          y - boundingRect.top,
          pitchTopDimensions.value.height,
        ),
      }
    }

    const headerIncident = computed(() => {
      return (
        positionableIncidentIfLast.value || incidentWithPositionIfLast.value
      )
    })

    function handlePitchPress(event: TouchEvent): void {
      const position = getOurPosition(event)

      popup.show({
        component: IncidentsWithPositionPopup,
        props: { position },
        showHeader: true,
      })
    }

    function setMinOrMaxRangeOnCoordinate(positionCoordinate: number): number {
      if (positionCoordinate > 100) {
        return 100
      }
      if (positionCoordinate < 1) {
        return 1
      }

      return positionCoordinate
    }

    function handlePitchTap(event: TouchEvent | PointerEvent): void {
      if (!lastIncident.value) {
        return
      }

      const position = getOurPosition(event)
      position.x = setMinOrMaxRangeOnCoordinate(position.x)
      position.y = setMinOrMaxRangeOnCoordinate(position.y)

      if (
        positionableIncidentIfLast.value &&
        positionableIncidentIfLast.value.incident.x_pos === null &&
        positionableIncidentIfLast.value.incident.y_pos === null
      ) {
        actionQueue.value.add(
          updatePositionFor(lastIncident.value, position, probableEvent),
        )
      } else {
        actionQueue.value.add(
          addOrUpdatePossessionIncident(
            probableClock,
            position,
            possession.current.value,
            lastIncident,
            probableHomeParticipant,
            probableAwayParticipant,
            probableEvent,
          ),
        )
      }

      eventBus.emit('HighlightPosition', getEventCoords(event))
    }

    const { onTap, onPress, onPressUp, onPanStart } = usePressTap(
      eventBus,
      handlePitchTap,
      handlePitchPress,
    )

    return {
      interactiveArea,
      pitchTopRef,
      overlayComponents,
      pitchTopDimensions,
      visibleOverlay,
      possessionDirection,
      PossessionDirection,
      pitchTopResizeObserver,
      probableHomeParticipant,
      probableAwayParticipant,
      incidentWithPositionIfLast,
      headerIncident,
      convertSportsAPIPositionToOurPosition,
      handlePitchPress,
      handlePitchTap,
      onTap,
      onPress,
      onPressUp,
      onPanStart,
      sidesSwitched,
      opChain,
    }
  },
})
</script>

<template>
  <div
    class="bg-soccer-bottom relative items-center justify-center bg-cover bg-center"
  >
    <div
      class="text-xxs absolute -top-2 left-1/2 -translate-x-1/2 transform whitespace-nowrap px-4 py-0.5 font-bold text-white"
      :class="`bg-${opChain(headerIncident, (v) => v.side)}`"
    >
      {{ opChain(headerIncident, (v) => v.incident.incident_name) }}
    </div>
    <div
      class="absolute inset-0 flex h-full w-full items-center justify-center p-2"
      data-test-id="SoccerRelationPitch"
    >
      <div
        ref="interactiveArea"
        v-gesture:tap="onTap"
        v-gesture:press="onPress"
        v-gesture:pressup="onPressUp"
        v-gesture:panstart="onPanStart"
        class="absolute"
      ></div>
      <img
        ref="pitchTopRef"
        class="pitch-top pointer-events-none h-full w-auto"
        src="@mobile/assets/pitches/soccer/top.svg"
      />
      <img
        v-if="!visibleOverlay"
        class="text-home pointer-events-none absolute fill-current stroke-current opacity-40"
        :class="{
          'rotate-180 transform':
            possessionDirection === PossessionDirection.RightToLeft,
        }"
        src="@mobile/assets/pitches/soccer/pitch-arrow.svg"
        :style="{
          width: `${pitchTopDimensions.width}px`,
          height: `${pitchTopDimensions.height}px`,
        }"
        :data-cy="
          possessionDirection !== PossessionDirection.RightToLeft
            ? 'homeSidePosition'
            : 'awaySidePosition'
        "
      />
      <div
        v-if="!visibleOverlay"
        class="pointer-events-none absolute"
        :style="{
          width: `${pitchTopDimensions.width}px`,
          height: `${pitchTopDimensions.height}px`,
        }"
      >
        <PitchBall
          v-if="incidentWithPositionIfLast"
          :position="{
            x: convertSportsAPIPositionToOurPosition(
              Number(incidentWithPositionIfLast.incident.x_pos),
              pitchTopDimensions.width,
            ),
            y: convertSportsAPIPositionToOurPosition(
              Number(incidentWithPositionIfLast.incident.y_pos),
              pitchTopDimensions.height,
            ),
          }"
          :side="
            opChain(incidentWithPositionIfLast, (v) => v.side) || undefined
          "
          :incidentId="opChain(headerIncident, (v) => v.incident.incident_id)"
        />
      </div>
      <div
        v-for="(overlayComponent, index) in overlayComponents"
        v-show="
          overlayComponent.props.incidentId ===
          opChain(visibleOverlay, (v) => v.props.incidentId)
        "
        :key="index"
        class="absolute"
        :style="{
          width: `${pitchTopDimensions.width}px`,
          height: `${pitchTopDimensions.height}px`,
        }"
      >
        <component
          :is="overlayComponent.component"
          v-bind="overlayComponent.props"
          @sidesSwitched="$emit('sidesSwitched')"
        />
      </div>
    </div>
  </div>
</template>
