<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue'

import Ripple from '@mobile/components/PositionHighlighter/Ripple.vue'
import { eventBus, Events } from '@mobile/globalState/eventBus'
import { generateUniqueId } from '@mobile/uniqueId'

export default defineComponent({
  components: { Ripple },
  setup() {
    const ripples = ref<Array<Events['HighlightPosition'] & { uuid: string }>>(
      [],
    )
    function removeRipple(uuid: string): void {
      const index = ripples.value.findIndex((ripple) => ripple.uuid === uuid)

      if (index !== -1) {
        ripples.value.splice(index, 1)
      }
    }

    function onHighlightPosition(e: Events['HighlightPosition']): void {
      ripples.value.push({
        ...e,
        uuid: generateUniqueId(),
      })
    }

    onMounted(() => {
      eventBus.on('HighlightPosition', onHighlightPosition)
    })

    onBeforeUnmount(() => {
      eventBus.off('HighlightPosition', onHighlightPosition)
    })

    return {
      ripples,
      removeRipple,
    }
  },
})
</script>

<template>
  <div class="pointer-events-none absolute bottom-0 left-0 right-0 top-0 z-50">
    <Ripple
      v-for="ripple in ripples"
      :key="ripple.uuid"
      class="relative"
      :style="{ left: `${ripple.x}px`, top: `${ripple.y}px` }"
      :reversed="ripple.reversed"
      @animationCompleted="removeRipple(ripple.uuid)"
    />
  </div>
</template>
