<script lang="ts">
import { defineComponent } from 'vue'

import LogoHorizontalDev from '@mobile/assets/logo-horizontal-development.svg'
import LogoHorizontal from '@mobile/assets/logo-horizontal.svg'

export default defineComponent({
  setup() {
    const logoSrc =
      import.meta.env.VITE_APP_MODE === 'production'
        ? LogoHorizontal
        : LogoHorizontalDev

    return { logoSrc }
  },
})
</script>

<template>
  <img
    :src="logoSrc"
    alt="STATSCORE Logo"
  />
</template>
