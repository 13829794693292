import { ComputedRef } from 'vue'

import { SubParticipant } from '@collector/sportsapi-client-legacy'
import router from '@mobile/router'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

type EventSeasonParticipantsComposable = {
  eventSeasonParticipants: ComputedRef<SubParticipant[]>
}

export function useEventSeasonParticipants(): EventSeasonParticipantsComposable {
  const ioc = router.currentRoute.value.meta.ioc
  if (!ioc) {
    throw Error(
      'IoC Container has to be instantiated and injected into route meta before using sport configuration',
    )
  }

  const { eventSeasonParticipants } = ioc.get(
    RelationSportCommonIoCBindings.SeasonParticipants,
  )

  return { eventSeasonParticipants }
}
