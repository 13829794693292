<script lang="ts">
import { computed, defineComponent, PropType, Ref, ref } from 'vue'

import {
  BasketballFreeThrowsAwardedAttribute,
  BasketballIncidentId,
  Participant,
} from '@collector/sportsapi-client-legacy'
import { AddIncident } from '@mobile/ActionQueue/Actions/Actions'
import Button from '@mobile/components/Button/Button.vue'
import * as popup from '@mobile/globalState/popup'
import AttributesSelection from '@mobile/views/Relation/Shared/Popup/builder/AttributesSelection.vue'
import PopupControls from '@mobile/views/Relation/Shared/Popup/PopupControls.vue'
import TeamSelect from '@mobile/views/Relation/Shared/Popup/TeamSelect.vue'
import { IncidentActionComposable } from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: {
    PopupControls,
    Button,
    TeamSelect,
    AttributesSelection,
  },
  props: {
    incidentActionComposable: {
      type: Object as PropType<IncidentActionComposable>,
      required: true,
    },
  },
  setup() {
    const selectedParticipant: Ref<Participant | null> = ref(null)
    const selectedAttribute: Ref<BasketballFreeThrowsAwardedAttribute | null> =
      ref(null)
    const { actionQueue, probableEvent } = useEventInjections()
    const supportedAttributes = [
      BasketballFreeThrowsAwardedAttribute.One,
      BasketballFreeThrowsAwardedAttribute.Two,
      BasketballFreeThrowsAwardedAttribute.Three,
    ]

    function sendIncident(): void {
      if (!canBeProceeded.value) {
        return
      }

      actionQueue.value.add(
        new AddIncident({
          incident_id: BasketballIncidentId.FreeThrowsAwarded,
          status_id: probableEvent.value.status_id,
          participant_team_id: selectedParticipant.value?.id,
          attribute_ids: [
            selectedAttribute.value,
          ] as BasketballFreeThrowsAwardedAttribute[],
        }),
      )

      popup.hide()
    }

    const canBeProceeded = computed(
      () => !!selectedParticipant.value && !!selectedAttribute.value,
    )

    function closePopup(): void {
      popup.hide()
    }

    function handleParticipantSelected({
      getParticipant,
    }: ProbableLinkedParticipant): void {
      selectedParticipant.value = getParticipant().value
    }

    function handleAttributeSelected(
      attribute: BasketballFreeThrowsAwardedAttribute,
    ): void {
      selectedAttribute.value = attribute ? Number(attribute) : null
    }

    return {
      closePopup,
      sendIncident,
      canBeProceeded,
      selectedParticipant,
      supportedAttributes,
      handleAttributeSelected,
      handleParticipantSelected,
    }
  },
})
</script>

<template>
  <div class="flex flex-col">
    <TeamSelect
      class="w-full"
      :withButtons="false"
      @participantSelected="handleParticipantSelected"
    />

    <AttributesSelection
      v-bind="{
        incidentActionComposable,
        attributes: supportedAttributes,
        updateIncident: false,
      }"
      title="Select attribute"
      @attributeSelected="handleAttributeSelected"
    />
  </div>

  <PopupControls>
    <template #buttons>
      <div class="flex justify-end space-x-2">
        <Button
          :color="'red'"
          :size="'lg'"
          @click="closePopup()"
        >
          Close
        </Button>
        <Button
          :color="'green'"
          :size="'lg'"
          :disabled="!canBeProceeded"
          @click="sendIncident()"
        >
          Send
        </Button>
      </div>
    </template>
  </PopupControls>
</template>
