import { Container } from 'inversify'
import { get } from 'lodash'

import {
  BasketballEventStatusId,
  BasketballIncidentId,
  BasketballThreePointShotMadeAttribute,
  CollectorEventTime,
  EventStatusId,
  IncidentId,
} from '@collector/sportsapi-client-legacy'
import { AddIncident } from '@mobile/ActionQueue/Actions/Actions'
import * as popup from '@mobile/globalState/popup'
import { getOrThrow } from '@mobile/reusables/entityUtils'
import { ConfirmSendingIncidentPopup } from '@mobile/views/Relation/Shared/Popups/ConfirmSendingIncident/ConfirmSendingIncidentPopup'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { IncidentHandler } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/IncidentHandlersConfiguration'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

import { FoulPopup } from '../Popups/Foul/FoulPopup'
import { FreeThrowsAwardedPopup } from '../Popups/FreeThrowsAwarded/FreeThrowsAwardedPopup'
import { InjuryPopup } from '../Popups/Injury/InjuryPopup'
import { ThrowInPopup } from '../Popups/ThrowIn/ThrowInPopup'
import { TimeoutPopup } from '../Popups/Timeout/TimeoutPopup'
import { BasketballRelationIoCBindings } from './BasketballRelationDependencies'

export function bindIncidentHandlersConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.IncidentHandlersConfiguration)
    .toDynamicValue(async (ctx) => {
      const [actionQueue, probableEvent, probableClock] = await Promise.all([
        await ctx.container.getAsync(RelationIoCBindings.ActionQueue),
        await ctx.container.getAsync(RelationIoCBindings.ProbableEvent),
        await ctx.container.getAsync(RelationIoCBindings.ProbableClock),
        await ctx.container.getAsync(
          RelationIoCBindings.ProbableLinkedHomeParticipant,
        ),
        await ctx.container.getAsync(
          RelationIoCBindings.ProbableLinkedAwayParticipant,
        ),
        await ctx.container.getAsync(RelationIoCBindings.SidesSwitched),
      ])
      const eventStatusesConfiguration = await ctx.container.getAsync(
        RelationSportCommonIoCBindings.EventStatusesConfiguration,
      )
      const activeParticipant = await ctx.container.getAsync(
        BasketballRelationIoCBindings.ActiveParticipant,
      )

      const addIncident: IncidentHandler['handler'] = ({
        incidentId,
        participantId,
        status,
        time = {
          minute: probableClock.value.minute,
          second: probableClock.value.second,
        },
        attributeIds = [],
      }) => {
        actionQueue.value.add(
          new AddIncident({
            incident_id: incidentId,
            status_id: status ?? probableEvent.value.status_id,
            participant_team_id: participantId,
            ...time,
            attribute_ids: attributeIds,
          }),
        )
      }

      const isDisabledByCurrentEventStatus = (
        incidentId: IncidentId,
      ): boolean => {
        const eventStatusConfiguration =
          eventStatusesConfiguration[probableEvent.value.status_id]

        return !eventStatusConfiguration?.allowedIncidents?.includes(incidentId)
      }

      const getTimeForEventStatus = (
        eventStatusId: EventStatusId,
      ): CollectorEventTime => {
        const configuration = getOrThrow(
          eventStatusesConfiguration,
          eventStatusId,
        )

        return (
          configuration.time ?? {
            minute: 0,
            second: 0,
          }
        )
      }

      const getTimeForEndOvertimeStatus = (): CollectorEventTime => {
        const actualStatus = probableEvent.value.status_id

        const overtimeEndStatuses = {
          [BasketballEventStatusId.FirstOvertime]:
            BasketballEventStatusId.BreakAfterFirstOvertime,
          [BasketballEventStatusId.SecondOvertime]:
            BasketballEventStatusId.BreakAfterSecondOvertime,
          [BasketballEventStatusId.ThirdOvertime]:
            BasketballEventStatusId.BreakAfterThirdOvertime,
          [BasketballEventStatusId.FourthOvertime]:
            BasketballEventStatusId.BreakAfterFourthQuarter,
        }

        const overtimeEndStatus = get(
          overtimeEndStatuses,
          actualStatus,
          BasketballEventStatusId.FinishedRegularTime,
        )

        return getTimeForEventStatus(overtimeEndStatus)
      }

      return {
        [BasketballIncidentId.NotStarted]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(BasketballEventStatusId.NotStarted),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.StartDelayed]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(BasketballEventStatusId.StartDelayed),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.Timeout]: {
          handler: () =>
            popup.show({
              component: TimeoutPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.TvTimeout]: {
          handler: () =>
            addIncident({ incidentId: BasketballIncidentId.TvTimeout }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.PlayUnderReview]: {
          handler: () =>
            addIncident({ incidentId: BasketballIncidentId.PlayUnderReview }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.BreakDuringGame]: {
          handler: () =>
            addIncident({ incidentId: BasketballIncidentId.BreakDuringGame }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.Injury]: {
          handler: () =>
            popup.show({
              component: InjuryPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.Foul]: {
          handler: ({ participantId, status, time }) => {
            if (participantId && status) {
              addIncident({
                incidentId: BasketballIncidentId.Foul,
                participantId,
                status,
                time,
              })
            } else {
              popup.show({
                component: FoulPopup,
                showHeader: true,
              })
            }
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.FreeThrowsAwarded]: {
          handler: () =>
            popup.show({
              component: FreeThrowsAwardedPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.ThrowIn]: {
          handler: () =>
            popup.show({
              component: ThrowInPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.InPossession]: {
          handler: () =>
            addIncident({
              incidentId: BasketballIncidentId.InPossession,
              participantId: activeParticipant.value.participant?.id,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.TwoPointShotMade]: {
          handler: () =>
            addIncident({
              incidentId: BasketballIncidentId.TwoPointShotMade,
              participantId: activeParticipant.value.participant?.id,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.BreakAfterFirstQuarter]: {
          handler: () => {
            addIncident({
              incidentId: BasketballIncidentId.BreakAfter,
              time: getTimeForEventStatus(
                BasketballEventStatusId.BreakAfterFirstQuarter,
              ),
            })
            addIncident({
              incidentId: BasketballIncidentId.BreakAfterFirstQuarter,
              time: getTimeForEventStatus(
                BasketballEventStatusId.BreakAfterFirstQuarter,
              ),
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.BreakAfterSecondQuarter]: {
          handler: () => {
            addIncident({
              incidentId: BasketballIncidentId.BreakAfter,
              time: getTimeForEventStatus(
                BasketballEventStatusId.BreakAfterSecondQuarter,
              ),
            })
            addIncident({
              incidentId: BasketballIncidentId.BreakAfterSecondQuarter,
              time: getTimeForEventStatus(
                BasketballEventStatusId.BreakAfterSecondQuarter,
              ),
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.BreakAfterThirdQuarter]: {
          handler: () => {
            addIncident({
              incidentId: BasketballIncidentId.BreakAfter,
              time: getTimeForEventStatus(
                BasketballEventStatusId.BreakAfterThirdQuarter,
              ),
            })
            addIncident({
              incidentId: BasketballIncidentId.BreakAfterThirdQuarter,
              time: getTimeForEventStatus(
                BasketballEventStatusId.BreakAfterThirdQuarter,
              ),
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.BreakAfterFirstOvertime]: {
          handler: () => {
            addIncident({
              incidentId: BasketballIncidentId.BreakAfter,
              time: getTimeForEventStatus(
                BasketballEventStatusId.BreakAfterFirstOvertime,
              ),
            })
            addIncident({
              incidentId: BasketballIncidentId.BreakAfterFirstOvertime,
              time: getTimeForEventStatus(
                BasketballEventStatusId.BreakAfterFirstOvertime,
              ),
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.BreakAfterSecondOvertime]: {
          handler: () => {
            addIncident({
              incidentId: BasketballIncidentId.BreakAfter,
              time: getTimeForEventStatus(
                BasketballEventStatusId.BreakAfterSecondOvertime,
              ),
            })
            addIncident({
              incidentId: BasketballIncidentId.BreakAfterSecondOvertime,
              time: getTimeForEventStatus(
                BasketballEventStatusId.BreakAfterSecondOvertime,
              ),
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.BreakAfterThirdOvertime]: {
          handler: () => {
            addIncident({
              incidentId: BasketballIncidentId.BreakAfter,
              time: getTimeForEventStatus(
                BasketballEventStatusId.BreakAfterThirdOvertime,
              ),
            })
            addIncident({
              incidentId: BasketballIncidentId.BreakAfterThirdOvertime,
              time: getTimeForEventStatus(
                BasketballEventStatusId.BreakAfterThirdOvertime,
              ),
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.BreakAfterFourthOvertime]: {
          handler: () => {
            addIncident({
              incidentId: BasketballIncidentId.BreakAfter,
              time: getTimeForEventStatus(
                BasketballEventStatusId.BreakAfterFourthOvertime,
              ),
            })
            addIncident({
              incidentId: BasketballIncidentId.BreakAfterFourthOvertime,
              time: getTimeForEventStatus(
                BasketballEventStatusId.BreakAfterFourthOvertime,
              ),
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.BreakAfterFirstHalf]: {
          handler: () => {
            addIncident({ incidentId: BasketballIncidentId.BreakAfter })
            addIncident({
              incidentId: BasketballIncidentId.BreakAfterFirstHalf,
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.FinishedAfterOvertime]: {
          handler: () => {
            addIncident({
              incidentId: BasketballIncidentId.FinishedAfterOvertime,
              time: getTimeForEndOvertimeStatus(),
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.FinishedRegularTime]: {
          handler: () => {
            addIncident({
              incidentId: BasketballIncidentId.FinishedRegularTime,
              time: getTimeForEventStatus(
                BasketballEventStatusId.FinishedRegularTime,
              ),
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.FinishedAwardedWin]: {
          handler: () => {
            addIncident({
              incidentId: BasketballIncidentId.FinishedAwardedWin,
              time: getTimeForEventStatus(
                BasketballEventStatusId.FinishedAwardedWin,
              ),
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.SecondQuarterStarted]: {
          handler: ({ incidentId }) =>
            popup.show({
              component: ConfirmSendingIncidentPopup,
              showHeader: true,
              props: {
                incidentId,
                time: getTimeForEventStatus(
                  BasketballEventStatusId.SecondQuarter,
                ),
              },
            }),

          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.ThirdQuarterStarted]: {
          handler: ({ incidentId }) =>
            popup.show({
              component: ConfirmSendingIncidentPopup,
              showHeader: true,
              props: {
                incidentId,
                time: getTimeForEventStatus(
                  BasketballEventStatusId.ThirdQuarter,
                ),
              },
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.FourthQuarterStarted]: {
          handler: ({ incidentId }) =>
            popup.show({
              component: ConfirmSendingIncidentPopup,
              showHeader: true,
              props: {
                incidentId,
                time: getTimeForEventStatus(
                  BasketballEventStatusId.FourthQuarter,
                ),
              },
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.FirstOvertimeStarted]: {
          handler: ({ incidentId }) =>
            popup.show({
              component: ConfirmSendingIncidentPopup,
              showHeader: true,
              props: {
                incidentId,
                time: getTimeForEventStatus(
                  BasketballEventStatusId.FirstOvertime,
                ),
              },
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.SecondOvertimeStarted]: {
          handler: ({ incidentId }) =>
            popup.show({
              component: ConfirmSendingIncidentPopup,
              showHeader: true,
              props: {
                incidentId,
                time: getTimeForEventStatus(
                  BasketballEventStatusId.SecondOvertime,
                ),
              },
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.ThirdOvertimeStarted]: {
          handler: ({ incidentId }) =>
            popup.show({
              component: ConfirmSendingIncidentPopup,
              showHeader: true,
              props: {
                incidentId,
                time: getTimeForEventStatus(
                  BasketballEventStatusId.ThirdOvertime,
                ),
              },
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.FourthOvertimeStarted]: {
          handler: ({ incidentId }) =>
            popup.show({
              component: ConfirmSendingIncidentPopup,
              showHeader: true,
              props: {
                incidentId,
                time: getTimeForEventStatus(
                  BasketballEventStatusId.FourthOvertime,
                ),
              },
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.FifthOvertimeStarted]: {
          handler: ({ incidentId }) =>
            popup.show({
              component: ConfirmSendingIncidentPopup,
              showHeader: true,
              props: {
                incidentId,
                time: getTimeForEventStatus(
                  BasketballEventStatusId.FifthOvertime,
                ),
              },
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.WaitingForOvertime]: {
          handler: () => {
            addIncident({
              incidentId: BasketballIncidentId.WaitingForOvertime,
              time: getTimeForEventStatus(
                BasketballEventStatusId.WaitingForOvertime,
              ),
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BasketballIncidentId.ThreePointShotMade]: {
          handler: ({ participantId, status, time }) => {
            const attributeIds = [
              BasketballThreePointShotMadeAttribute.JumpShot,
            ]

            addIncident({
              incidentId: BasketballIncidentId.ThreePointShotMade,
              participantId,
              status,
              time,
              attributeIds,
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        fallbackHandler: {
          handler: ({ incidentId, participantId, status, time }) =>
            addIncident({
              time,
              status,
              incidentId,
              participantId,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
      }
    })
    .inSingletonScope()
}
