import { Container } from 'inversify'
import { ref } from 'vue'

import {
  CollectorPushMessageEvent,
  NotUuidThatWasDispatchedByIncidentsQueue,
  PushMessageType,
} from '@collector/sportsapi-client-legacy'
import { RepositoryEmitter } from '@mobile/database/Repository'
import * as app from '@mobile/globalState/app'
import * as scoutMessenger from '@mobile/globalState/scoutMessenger'
import * as sportsAPI from '@mobile/globalState/sportsAPI'
import {
  updatePairs,
  updatePairsByKey,
  updateProps,
} from '@mobile/reusables/entityUtils'

import { RelationIoCBindings } from './types'

export async function bindEvent(
  ioc: Container,
  eventId: number,
): Promise<void> {
  let unbindDatabaseWatchers: () => void

  ioc
    .bind(RelationIoCBindings.FactualEvent)
    .toDynamicValue(async () => {
      const event = await loadEvent(eventId)
      return ref(event)
    })
    .inSingletonScope()
    .onActivation(async (ctx, event) => {
      const onCreateOrUpdate:
        | RepositoryEmitter<CollectorPushMessageEvent>['created']
        | RepositoryEmitter<CollectorPushMessageEvent>['updated'] = async (
        changedEvent,
      ) => {
        const eventValue = event.value
        if (eventValue && eventValue.eventId === changedEvent.eventId) {
          scoutMessenger.state.messageConfirmator.onUuid(
            changedEvent.uuid,
            () => {
              updateCollectorEventPushMessage(eventValue, changedEvent)
            },
            (err: Error) => {
              // Confirm event update that wasn't dispatched by incidents queue
              if (err instanceof NotUuidThatWasDispatchedByIncidentsQueue) {
                updateCollectorEventPushMessage(eventValue, changedEvent)
              }
            },
            false,
          )
        }
      }
      app.state.database.events.on('created', onCreateOrUpdate)
      app.state.database.events.on('updated', onCreateOrUpdate)

      unbindDatabaseWatchers = () => {
        app.state.database.events.off('created', onCreateOrUpdate)
        app.state.database.events.off('updated', onCreateOrUpdate)
      }

      return event
    })
    .when(() => true)
    .onDeactivation(async () => {
      unbindDatabaseWatchers()
    })
    .when(() => true)
}

export async function loadEvent(
  eventId: number,
): Promise<CollectorPushMessageEvent> {
  let event = await app.state.database.events.getByEventId(eventId)

  if (!event) {
    // since we stopped a flood of events, we need to get the event from the API
    const eventFromApi = await sportsAPI.state.client.getEvent(eventId)

    event = {
      data: eventFromApi,
      eventId: eventFromApi.id,
      pushMessageUt: 0,
      source: 1,
      type: PushMessageType.Event,
      uuid: '',
    }

    await app.state.database.events.save(event)
  }

  return event
}

export function updateCollectorEventPushMessage(
  target: CollectorPushMessageEvent,
  updateData: CollectorPushMessageEvent,
): void {
  if (target.pushMessageUt > updateData.pushMessageUt) {
    return
  }

  // @TODO Add 'source' after types update
  updateProps(target, updateData, ['pushMessageUt', 'uuid'])
  updateProps(target.data, updateData.data, [
    'name',
    'sport_id',
    'status_id',
    'status_type',
    'bet_status',
    'bet_corners',
    'bet_cards',
    'relation_status',
    'verified_result',
    'scoutsfeed',
    'clock_time',
    'clock_status',
    'referee_id',
    'coverage_type',
  ])
  updatePairsByKey(
    'name',
    target.data.betting?.bet_statuses ?? [],
    updateData.data.betting?.bet_statuses ?? [],
    (targetBettingStatuses, updateBettingStatuses) => {
      updateProps(targetBettingStatuses, updateBettingStatuses, ['value'])
    },
  )
  updatePairs(
    target.data.details,
    updateData.data.details,
    (targetDetail, updateDataDetail) =>
      updateProps(targetDetail, updateDataDetail, ['value']),
  )
  updatePairs(
    target.data.participants,
    updateData.data.participants,
    (targetParticipant, updateDataParticipant) => {
      updateProps(targetParticipant, updateDataParticipant, [
        'counter',
        'name',
        'short_name',
        'acronym',
        'area_id',
        'area_code',
        'area_name',
        'ut',
      ])
      updatePairs(
        targetParticipant.stats,
        updateDataParticipant.stats,
        (targetStat, updateDataStat) =>
          updateProps(targetStat, updateDataStat, ['value']),
      )
      updatePairs(
        targetParticipant.results,
        updateDataParticipant.results,
        (targetResult, updateDataResult) =>
          updateProps(targetResult, updateDataResult, ['value']),
      )
    },
  )
}
