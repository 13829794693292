<script lang="ts">
import { Container } from 'inversify'
import { defineComponent } from 'vue'

import EventHeader from '@mobile/views/Relation/Shared/EventHeader/EventHeader.vue'
import { bindSeasonParticipants } from '@mobile/views/Relation/Shared/RelationDependencies/bindSeasonParticipants'
import { bindSkins } from '@mobile/views/Relation/Shared/RelationDependencies/bindSkins'

import { bindAssignableIncidentConfiguration } from './IceHockeyRelationDependencies/bindAssignableIncidentConfiguration'
import { bindEventClockConfiguration } from './IceHockeyRelationDependencies/bindEventClockConfiguration'
import { bindEventHeaderConfiguration } from './IceHockeyRelationDependencies/bindEventHeaderConfiguration'
import { bindEventNotificationHooks } from './IceHockeyRelationDependencies/bindEventNotificationHooks'
import { bindEventStatusesConfiguration } from './IceHockeyRelationDependencies/bindEventStatusesConfiguration'
import { bindEventStatusesList } from './IceHockeyRelationDependencies/bindEventStatusesList'
import { bindIncidentHandlersConfiguration } from './IceHockeyRelationDependencies/bindIncidentHandlersConfiguration'
import { bindIncidentsConfiguration } from './IceHockeyRelationDependencies/bindIncidentsConfiguration'
import { bindLineupsConfiguration } from './IceHockeyRelationDependencies/bindLineupsConfiguration'

export default defineComponent({
  components: { EventHeader },
})

export function bindIceHockeyRelationDependencies(
  ioc: Container,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Record<any, any> {
  bindEventStatusesConfiguration(ioc)
  bindIncidentsConfiguration(ioc)
  bindIncidentHandlersConfiguration(ioc)
  bindEventStatusesList(ioc)
  bindLineupsConfiguration(ioc)
  bindAssignableIncidentConfiguration(ioc)
  bindEventNotificationHooks(ioc)
  bindEventHeaderConfiguration(ioc)
  bindEventClockConfiguration(ioc)

  bindSeasonParticipants(ioc)
  bindSkins(ioc)

  return {}
}
</script>

<template>
  <EventHeader />
  <div class="grid h-full w-full place-content-center">
    <div>Not implemented yet</div>
  </div>
</template>
