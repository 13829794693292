<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'

import { SoccerIncidentId } from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import { BallPosition } from '@mobile/reusables/types/position/BallPosition'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import Breadcrumbs from '@mobile/views/Relation/Shared/Popup/Breadcrumbs.vue'
import PopupControls from '@mobile/views/Relation/Shared/Popup/PopupControls.vue'
import { useIncidentAction } from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: {
    PopupControls,
    Button,
    IncidentButton,
    Breadcrumbs,
  },
  props: {
    participantId: {
      type: Number,
      required: true,
    },
    position: {
      type: Object as PropType<BallPosition>,
      required: true,
    },
  },
  emits: ['close'],
  setup(props) {
    const eventInjections = useEventInjections()
    const { homeParticipant, awayParticipant } = eventInjections

    useIncidentAction(eventInjections, {
      incident_id: SoccerIncidentId.ShotOffTarget,
      x_pos: Math.min(Math.max(props.position.x, 1), 100),
      y_pos: Math.min(Math.max(props.position.y, 1), 100),
      participant_team_id: props.participantId,
    })

    const goalKickIncidentId = SoccerIncidentId.GoalKick
    const goalKickButtonColor = ref<'home' | 'away'>(
      homeParticipant.value.id === props.participantId ? 'away' : 'home',
    )
    const goalKickParticipantId = ref(
      homeParticipant.value.id === props.participantId
        ? awayParticipant.value.id
        : homeParticipant.value.id,
    )

    return {
      goalKickIncidentId,
      goalKickParticipantId,
      goalKickButtonColor,
    }
  },
})
</script>

<template>
  <div>
    <Breadcrumbs name="Goal kick after shot off target" />

    <div class="text-gray px-4 py-6 text-center font-bold">
      Do you want to add goal kick?
    </div>

    <PopupControls>
      <template #buttons>
        <div class="flex justify-end space-x-2">
          <Button
            :color="'red'"
            :size="'lg'"
            @click="$emit('close')"
          >
            No goal kick
          </Button>
          <IncidentButton
            :type="'horizontal'"
            :incidentId="goalKickIncidentId"
            :participantId="goalKickParticipantId"
            :size="'lg'"
            :color="goalKickButtonColor"
            :withIcon="true"
            @incidentHandled="$emit('close')"
          />
        </div>
      </template>
    </PopupControls>
  </div>
</template>
