import wretch, { Wretch } from 'wretch'

// TODO: Extract to a separate lib since it doesn't belong to the ApiClient
export class ScoutMessengerClient {
  private client: Wretch

  constructor(apiUrl: string) {
    this.client = wretch()

    this.client = this.client.url(apiUrl)
  }

  public async getIncidentIdByUuid(uuid: string): Promise<string> {
    const response = (await this.client
      .url(`/incidents/${uuid}`)
      .get()
      .json()) as { incidentId: string }

    return response.incidentId
  }
}
