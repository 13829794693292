import { Ref, ref } from 'vue'

import { Event } from '@collector/sportsapi-client-legacy'
import { ListOption } from '@mobile/views/Relation/DetailsPanel/Sports/composables/useSportOptions/types'

export type EventSelectOptions = {
  eventParticipantsSelectOptions: Ref<ListOption[]>
}

export function resolveEventSelectOptions(
  probableEvent: Ref<Event>,
): EventSelectOptions {
  const eventParticipantsSelectOptions = ref<ListOption[]>(
    probableEvent.value.participants.map(({ id: value, short_name: name }) => ({
      name,
      value,
    })),
  )

  return { eventParticipantsSelectOptions }
}
