<script lang="ts">
import { cloneDeep } from 'lodash'
import { defineComponent, PropType, Ref, ref, watch } from 'vue'

import { nullCoalesce } from '@collector/shared-utils'
import { CommonStatId } from '@collector/sportsapi-client-legacy'
import { UpdateEventParticipants } from '@mobile/ActionQueue/Actions/Actions'
import Button from '@mobile/components/Button/Button.vue'
import ParticipantLogo from '@mobile/components/ParticipantLogo/ParticipantLogo.vue'
import { participantDiff } from '@mobile/reusables/eventUtils'
import { skinPatterns } from '@mobile/reusables/skinPatterns'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

import { useParticipantSkins } from '../../../useParticipantSkins'
import PopupControls from '../../Popup/PopupControls.vue'

export default defineComponent({
  components: {
    PopupControls,
    ParticipantLogo,
    Button,
  },
  props: {
    probableLinkedParticipant: {
      type: Object as PropType<ProbableLinkedParticipant>,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const participant = props.probableLinkedParticipant.getParticipant()
    const { actionQueue } = useEventInjections()
    const { participantSkins, reloadSkins, currentSkin } =
      useParticipantSkins(participant)
    const selectedSkin: Ref<number | null> = ref(null)
    const showOverlay = ref(false)

    watch(currentSkin, (currentSkin) => {
      selectedSkin.value = currentSkin?.id ?? null
    })

    function isSkinSelected(patternId: number): boolean {
      return selectedSkin.value === patternId
    }

    function isCurrentSkin(skinId: number): boolean {
      return currentSkin.value?.id === skinId
    }

    function selectSkin(skinId: number): void {
      selectedSkin.value = skinId
    }

    async function save(): Promise<void> {
      const staticParticipant = cloneDeep(participant.value)

      const index = staticParticipant.stats.findIndex(
        (stat) => stat.id === CommonStatId.SkinPattern,
      )
      if (index === -1) {
        return
      }

      staticParticipant.stats.splice(index, 1, {
        id: CommonStatId.SkinPattern,
        value: selectedSkin.value,
      })

      actionQueue.value.add(
        new UpdateEventParticipants({
          data: [participantDiff(participant.value, staticParticipant)],
        }),
      )

      emit('close')
    }

    async function performSkinsReloading(): Promise<void> {
      showOverlay.value = true
      try {
        await reloadSkins()
      } catch (_error) {
        //
      } finally {
        showOverlay.value = false
      }
    }

    return {
      participantSkins,
      isCurrentSkin,
      selectSkin,
      skinPatterns,
      isSkinSelected,
      currentSkin,
      save,
      reloadSkins,
      showOverlay,
      performSkinsReloading,
      nullCoalesce,
    }
  },
})
</script>

<template>
  <div>
    <div class="h-safe flex w-full flex-col">
      <div
        :class="[
          'bg-gradient-to-r',
          probableLinkedParticipant.css.gradientFrom,
          probableLinkedParticipant.css.gradientTo,
        ]"
      >
        <div class="flex items-center justify-between px-2 py-1">
          <div class="flex items-center space-x-3">
            <ParticipantLogo
              class="w-10"
              :participant="probableLinkedParticipant.getParticipant().value"
            />
            <div class="text-sm font-bold text-white">
              {{ probableLinkedParticipant.getParticipant().value.name }}
            </div>
          </div>
          <div>
            <button
              class="rounded-md border border-white px-3 text-sm text-white"
              @click="performSkinsReloading"
            >
              Refresh
            </button>
          </div>
        </div>
      </div>
      <div class="flex flex-1 items-center justify-center p-3">
        <div class="text-center">
          <div class="pb-3 font-bold">Select skin</div>
          <div class="flex space-x-3">
            <div
              v-for="skin in participantSkins"
              :key="`skin-${skin.id}`"
              class="relative"
            >
              <div
                v-if="isCurrentSkin(skin.id)"
                class="absolute -left-2 -top-2 h-6 w-6 rounded-full"
                :class="isSkinSelected(skin.id) ? 'bg-green' : 'bg-cloudygray'"
              >
                <FontAwesomeIcon
                  class="text-xs text-white"
                  icon="check"
                />
              </div>
              <div
                class="h-32 w-32 rounded-md border-4 border-solid bg-white p-3"
                :class="{
                  'border-blue': isSkinSelected(skin.id),
                  'border-green':
                    isSkinSelected(skin.id) && isCurrentSkin(skin.id),
                  'border-white': !isSkinSelected(skin.id),
                }"
                @click="selectSkin(skin.id)"
              >
                <component
                  :is="
                    nullCoalesce(
                      skinPatterns.get(skin.pattern_id),
                      skinPatterns.get(1),
                    )
                  "
                  :firstColor="`#${skin.first_color}`"
                  :secondColor="`#${skin.second_color}`"
                  :thirdColor="`#${skin.third_color}`"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="showOverlay"
        class="bg-silver absolute flex h-full w-full items-center justify-center bg-opacity-50 uppercase"
      >
        <span class="bg-gray mr-4 h-10 w-10 animate-ping rounded-full" />
      </div>
    </div>

    <PopupControls v-if="!showOverlay">
      <template #buttons>
        <div class="flex justify-end space-x-2">
          <Button
            :color="'red'"
            :size="'lg'"
            @click="$emit('close')"
          >
            Cancel
          </Button>
          <Button
            :color="'green'"
            :size="'lg'"
            @click="save()"
          >
            Save
          </Button>
        </div>
      </template>
    </PopupControls>
  </div>
</template>
