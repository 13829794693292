import { BasketballEventStatusId } from '@collector/sportsapi-client-legacy'

export const BasketballManualAddStatusIds = [
  BasketballEventStatusId.FirstQuarter,
  BasketballEventStatusId.SecondQuarter,
  BasketballEventStatusId.ThirdQuarter,
  BasketballEventStatusId.FourthQuarter,
  BasketballEventStatusId.FirstOvertime,
  BasketballEventStatusId.SecondOvertime,
  BasketballEventStatusId.ThirdOvertime,
  BasketballEventStatusId.FourthOvertime,
  BasketballEventStatusId.FifthOvertime,
]
