<script lang="ts">
import { defineComponent, PropType } from 'vue'

import {
  CricketAttributeId,
  CricketIncidentId,
  DecisionReviewAttributesId,
} from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'
import { useIncidentAction } from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

import { useCricketConfiguration } from '../../CricketRelationDependencies/useCricketConfiguration'
import { prepareAdditionalInfo } from '../../prepareAdditionalInfo'

export default defineComponent({
  components: { Button },
  props: {
    context: {
      type: Object as PropType<PopupContext | null>,
      required: false,
    },
  },
  setup(props) {
    const { activeParticipant } = useCricketConfiguration()
    const eventInjections = useEventInjections()
    const { probableIncidents } = eventInjections

    function proceed(attributeId: CricketAttributeId): void {
      const additionalInfo = prepareAdditionalInfo(
        activeParticipant,
        probableIncidents,
      )

      useIncidentAction(eventInjections, {
        incident_id: CricketIncidentId.DecisionReview,
        participant_team_id: activeParticipant.value.participant?.id,
        attribute_ids: [attributeId],
        additional_info: additionalInfo,
      })

      props.context?.closePopup()
    }

    return {
      proceed,
      DecisionReviewAttributesId,
    }
  },
})
</script>

<template>
  <div>
    <div class="grid grid-cols-2 grid-rows-2 gap-4 px-4">
      <Button
        :size="'sm'"
        :color="'white'"
        :withIcon="false"
        :type="'horizontal'"
        @click="proceed(DecisionReviewAttributesId.Retained)"
      >
        Successful
      </Button>
      <Button
        :size="'sm'"
        :color="'white'"
        :withIcon="false"
        :type="'horizontal'"
        @click="proceed(DecisionReviewAttributesId.Lost)"
      >
        Unsuccessful
      </Button>
    </div>
  </div>
</template>
