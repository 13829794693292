import { chain } from 'lodash'
import { computed, Ref } from 'vue'

import {
  CricketEventStatusId,
  CricketIncidentId,
  Event,
} from '@collector/sportsapi-client-legacy'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import { EventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

const statues: {
  [key: number]: { startIncidentId: number; endIncidentId: number } | undefined
} = {
  [CricketEventStatusId.FirstInningsHome]: {
    startIncidentId: CricketIncidentId.FirstInningsHomeStarted,
    endIncidentId: CricketIncidentId.FirstInningsAwayStarted,
  },
  [CricketEventStatusId.FirstInningsAway]: {
    startIncidentId: CricketIncidentId.FirstInningsAwayStarted,
    endIncidentId: CricketIncidentId.FirstInningsHomeStarted,
  },
  [CricketEventStatusId.SuperOverHome]: {
    startIncidentId: CricketIncidentId.SuperOverHomeStarted,
    endIncidentId: CricketIncidentId.SuperOverAwayStarted,
  },
  [CricketEventStatusId.SuperOverAway]: {
    startIncidentId: CricketIncidentId.SuperOverAwayStarted,
    endIncidentId: CricketIncidentId.SuperOverHomeStarted,
  },
}

function hasInningStarOfOverIncident(
  probableEvent: Ref<Event>,
  probableIncidents: Ref<ProbableIncident[]>,
): boolean {
  const statusMap = statues[probableEvent.value.status_id]

  if (!statusMap) {
    return false
  }

  const { startIncidentId, endIncidentId } = statusMap
  const index = probableIncidents.value.findIndex(
    ({ incident_id }) => incident_id === startIncidentId,
  )
  const slicedIncidents = chain(probableIncidents.value)
    .take(index + 1)
    .reverse()
  const currentInningsIncidents = !slicedIncidents
    .find({ incident_id: endIncidentId })
    .value()
    ? slicedIncidents
    : slicedIncidents.takeRightWhile({ incident_id: endIncidentId })

  return !!currentInningsIncidents
    .find({ incident_id: CricketIncidentId.StartOfOver })
    .value()
}

export function resolveStartOverIncidentEnabled(
  eventInjections: EventInjections,
): Ref<boolean> {
  const { probableCurrentIncidents, probableEvent } = eventInjections

  const startOfOverEnabled = computed(
    () =>
      chain([...probableCurrentIncidents.value])
        .reverse()
        .filter(({ incident_id }) =>
          [CricketIncidentId.StartOfOver, CricketIncidentId.EndOfOver].includes(
            incident_id as CricketIncidentId,
          ),
        )
        .last()
        .value()?.incident_id === CricketIncidentId.EndOfOver,
  )

  return computed(
    () =>
      !hasInningStarOfOverIncident(probableEvent, probableCurrentIncidents) ||
      startOfOverEnabled.value,
  )
}
