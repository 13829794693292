import { Container } from 'inversify'

import { BasketballIncidentId } from '@collector/sportsapi-client-legacy'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindEventStatusesList(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.EventStatusesList)
    .toDynamicValue(() => {
      return [
        BasketballIncidentId.NotStarted,
        BasketballIncidentId.StartDelayed,
        BasketballIncidentId.MatchAboutToStart,
        BasketballIncidentId.FirstQuarterStarted,
        BasketballIncidentId.BreakAfterFirstQuarter,
        BasketballIncidentId.SecondQuarterStarted,
        BasketballIncidentId.BreakAfterSecondQuarter,
        BasketballIncidentId.ThirdQuarterStarted,
        BasketballIncidentId.BreakAfterThirdQuarter,
        BasketballIncidentId.FourthQuarterStarted,
        BasketballIncidentId.FinishedRegularTime,
        BasketballIncidentId.WaitingForOvertime,
        BasketballIncidentId.FirstOvertimeStarted,
        BasketballIncidentId.BreakAfterFirstOvertime,
        BasketballIncidentId.SecondOvertimeStarted,
        BasketballIncidentId.BreakAfterSecondOvertime,
        BasketballIncidentId.ThirdOvertimeStarted,
        BasketballIncidentId.BreakAfterThirdOvertime,
        BasketballIncidentId.FourthOvertimeStarted,
        BasketballIncidentId.BreakAfterFourthOvertime,
        BasketballIncidentId.FifthOvertimeStarted,
        BasketballIncidentId.FirstHalfStarted,
        BasketballIncidentId.BreakAfterFirstHalf,
        BasketballIncidentId.SecondHalfStarted,
        BasketballIncidentId.FinishedAfterOvertime,
        BasketballIncidentId.FinishedAwardedWin,
        BasketballIncidentId.Interrupted,
        BasketballIncidentId.Postponed,
        BasketballIncidentId.Cancelled,
        BasketballIncidentId.Abandoned,
        BasketballIncidentId.ToFinish,
      ]
    })
    .inSingletonScope()
}
