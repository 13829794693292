<script lang="ts">
import { computed, defineComponent, PropType, shallowRef, watch } from 'vue'

import Button from '@mobile/components/Button/Button.vue'
import { getParticipantButtonColor } from '@mobile/components/Button/ButtonUtils'
import ParticipantLogo from '@mobile/components/ParticipantLogo/ParticipantLogo.vue'
import PopupControls from '@mobile/views/Relation/Shared/Popup/PopupControls.vue'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: {
    Button,
    ParticipantLogo,
    PopupControls,
  },
  props: {
    defaultParticipant: {
      type: Object as PropType<ProbableLinkedParticipant | null>,
      default: null,
    },
    withButtons: {
      type: Boolean,
      default: true,
    },
    withTitle: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['participantSelected', 'close'],
  setup(props, { emit }) {
    const selectedParticipant = shallowRef(props.defaultParticipant)
    const {
      probableLinkedFirstParticipant,
      probableFirstParticipant,
      probableLinkedSecondParticipant,
      probableSecondParticipant,
    } = useEventInjections()

    function isParticipantSelected(
      participant: ProbableLinkedParticipant,
    ): boolean {
      if (!selectedParticipant.value) {
        return false
      }

      return participant.side === selectedParticipant.value.side
    }

    const firstParticipantColor = computed(() =>
      getParticipantButtonColor(
        probableLinkedFirstParticipant.value.getParticipant().value,
      ),
    )

    const secondParticipantColor = computed(() =>
      getParticipantButtonColor(
        probableLinkedSecondParticipant.value.getParticipant().value,
      ),
    )

    watch(
      () => props.defaultParticipant,
      () => {
        selectedParticipant.value = props.defaultParticipant
      },
    )

    function selectParticipant(participant: ProbableLinkedParticipant): void {
      selectedParticipant.value = participant

      if (!props.withButtons) {
        emit('participantSelected', selectedParticipant.value)
      }
    }

    function next(): void {
      emit('participantSelected', selectedParticipant.value)
    }

    return {
      next,
      isParticipantSelected,
      firstParticipantColor,
      secondParticipantColor,
      componentParticipant: selectedParticipant,
      probableLinkedFirstParticipant,
      probableFirstParticipant,
      probableLinkedSecondParticipant,
      probableSecondParticipant,
      selectParticipant,
    }
  },
})
</script>

<template>
  <div
    class="px-4 py-6"
    :class="{ 'py-3': !!$slots.default }"
  >
    <div
      v-if="withTitle"
      class="pb-4 text-center font-bold"
    >
      Select team
    </div>
    <div class="flex space-x-2">
      <Button
        class="flex w-full justify-center"
        :color="firstParticipantColor"
        :size="'sm'"
        :invertedColor="!isParticipantSelected(probableLinkedFirstParticipant)"
        @click="selectParticipant(probableLinkedFirstParticipant)"
      >
        <div
          class="flex h-full items-center space-x-1"
          data-test-id="FirstParticipantButton"
        >
          <ParticipantLogo
            class="h-full w-auto"
            :participant="probableFirstParticipant"
          />
          <div>{{ probableFirstParticipant.short_name }}</div>
        </div>
      </Button>
      <Button
        class="flex w-full justify-center"
        :color="secondParticipantColor"
        :size="'sm'"
        :invertedColor="!isParticipantSelected(probableLinkedSecondParticipant)"
        @click="selectParticipant(probableLinkedSecondParticipant)"
      >
        <div class="flex h-full items-center space-x-1">
          <ParticipantLogo
            class="h-full w-auto"
            :participant="probableSecondParticipant"
          />
          <div>{{ probableSecondParticipant.short_name }}</div>
        </div>
      </Button>
    </div>

    <slot></slot>
  </div>

  <PopupControls v-if="withButtons">
    <template #buttons>
      <div class="flex justify-end space-x-2">
        <Button
          :color="'red'"
          :size="'lg'"
          @click="$emit('close')"
        >
          Close
        </Button>
        <Button
          :color="'green'"
          :disabled="!componentParticipant"
          :size="'lg'"
          @click="next"
        >
          Send
        </Button>
      </div>
    </template>
  </PopupControls>
</template>
