import Dexie from 'dexie'

import { Participant } from '@collector/sportsapi-client-legacy'

import { Repository } from './Repository'

export type RefereeRecord = {
  eventId: number
  referee: Participant
}

export class RefereesRepository extends Repository<RefereeRecord> {
  static readonly tableName = 'referees'
  static readonly indexes = 'eventId'

  constructor(database: Dexie) {
    super(database.table<RefereeRecord>(RefereesRepository.tableName))
  }

  public async find(eventId: number): Promise<RefereeRecord | undefined> {
    return this.table.where({ eventId }).first()
  }

  public async removeByEventId(eventId: number): Promise<number> {
    return this.table.where({ eventId }).delete()
  }

  public async save(eventId: number, referee: Participant): Promise<void> {
    await this.table.put({
      eventId,
      referee,
    })
  }
}
