<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
  },
})
</script>

<template>
  <span class="text-lg font-bold">{{ title }}</span>
  <hr class="text-lightgray my-4" />
</template>
