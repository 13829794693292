import { isNumber } from 'lodash'

import {
  Event,
  IncidentsQueuePatchDTO,
  IncidentsQueuePostDTO,
  Sport,
} from '@collector/sportsapi-client-legacy'
import { getCurrentEvent } from '@mobile/globalState/event'

import { Action } from '../../Action'
import { ProbableIncident } from './ProbableIncident'

export type ProbableData = {
  probableEvent?: Event
  probableIncidents: ProbableIncident[]
}

export default abstract class IncidentAction extends Action<ProbableData> {
  public actionOrIncidentId: string | null = null
  public incidentId: string | null = null

  constructor() {
    super()
  }

  protected resolveEventTime(
    incidentsQueueDTO: IncidentsQueuePostDTO | IncidentsQueuePatchDTO,
  ): string | null {
    let eventTime: string | null = null

    if (
      isNumber(incidentsQueueDTO.minute) &&
      isNumber(incidentsQueueDTO.second)
    ) {
      eventTime = `${String(incidentsQueueDTO.minute).padStart(2, '0')}:${String(incidentsQueueDTO.second).padStart(2, '0')}`
    }

    return eventTime
  }

  protected resolveParticipantName(
    incidentsQueueDTO: IncidentsQueuePostDTO | IncidentsQueuePatchDTO,
  ): string | null {
    let participantName: string | null = null
    const event = getCurrentEvent()
    const participant = event.participants.find(
      (participant) => participant.id === incidentsQueueDTO.participant_team_id,
    )

    if (participant) {
      participantName = participant.short_name
    }

    return participantName
  }

  protected resolveParticipantTeamId(
    incidentsQueueDTO: IncidentsQueuePostDTO | IncidentsQueuePatchDTO,
  ): number | null {
    return incidentsQueueDTO.participant_team_id ?? null
  }

  protected resolveStatusName(statusId: number, sport: Sport): string {
    return (
      sport.statuses.find((status) => status.id === statusId)?.short_name ??
      'Missing translation'
    )
  }

  protected resolveIncidentName(incidentId: number, sport: Sport): string {
    return (
      sport.incidents.find((incident) => incident.id === incidentId)?.name ??
      'Missing translation'
    )
  }

  public setIncidentId(incidentId: string): void {
    this.incidentId = incidentId
    this.actionOrIncidentId = incidentId
  }
}
