import { Container } from 'inversify'
import { isNumber } from 'lodash'
import { ref, watch } from 'vue'

import {
  ClockStatus,
  CollectorPushMessageEvent,
  Event,
} from '@collector/sportsapi-client-legacy'
import { ActionQueue } from '@mobile/ActionQueue/ActionQueue'
import {
  AddIncident,
  UpdateEventTime,
} from '@mobile/ActionQueue/Actions/Actions'
import { getOrThrow } from '@mobile/reusables/entityUtils'
import { ProbableTimeAction } from '@mobile/views/Relation/Shared/ProbableTimeAction/ProbableTimeAction'
import { ProbableTimeAddIncidentAction } from '@mobile/views/Relation/Shared/ProbableTimeAction/ProbableTimeAddIncidentAction'
import { ProbableTimeUpdateEventTimeAction } from '@mobile/views/Relation/Shared/ProbableTimeAction/ProbableTimeUpdateEventTimeAction'
import {
  getMinuteAndSecond,
  isTimeInEventStatusBoundary,
  setupClockTicker,
} from '@mobile/views/Relation/Shared/RelationDependencies/bindClock'
import { EventStatusesConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/EventStatusesConfiguration'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

import { IncidentsConfiguration } from '../RelationSportCommonDependencies/IncidentsConfiguration'
import { Clock, RelationIoCBindings } from './types'

export function bindProbableClock(ioc: Container): void {
  let unbindProbableClock: () => void

  ioc
    .bind(RelationIoCBindings.ProbableClock)
    .toDynamicValue(async (ctx) => {
      const [factualEvent, probableEvent, actionQueue] = await Promise.all([
        ctx.container.getAsync(RelationIoCBindings.FactualEvent),
        ctx.container.getAsync(RelationIoCBindings.ProbableEvent),
        ctx.container.getAsync(RelationIoCBindings.ActionQueue),
      ])
      const [eventStatusesConfiguration, incidentsConfiguration] =
        await Promise.all([
          ctx.container.getAsync(
            RelationSportCommonIoCBindings.EventStatusesConfiguration,
          ),
          ctx.container.getAsync(
            RelationSportCommonIoCBindings.IncidentsConfiguration,
          ),
        ])

      return ref(
        resolveProbableClock(
          factualEvent.value,
          probableEvent.value,
          actionQueue.value,
          eventStatusesConfiguration,
          incidentsConfiguration,
        ),
      )
    })
    .inSingletonScope()
    .onActivation(async (ctx, probableClock) => {
      const [factualEvent, probableEvent, actionQueue] = await Promise.all([
        ctx.container.getAsync(RelationIoCBindings.FactualEvent),
        ctx.container.getAsync(RelationIoCBindings.ProbableEvent),
        ctx.container.getAsync(RelationIoCBindings.ActionQueue),
      ])

      const [eventStatusesConfiguration, incidentsConfiguration] =
        await Promise.all([
          ctx.container.getAsync(
            RelationSportCommonIoCBindings.EventStatusesConfiguration,
          ),
          ctx.container.getAsync(
            RelationSportCommonIoCBindings.IncidentsConfiguration,
          ),
        ])

      let unwatchClockTicker = setupClockTicker(
        probableEvent,
        eventStatusesConfiguration,
        probableClock,
      )
      const unwatchProbableClockDependencies = watch(
        () => [
          actionQueue.value.queue,
          factualEvent.value.data.clock_time,
          factualEvent.value.data.clock_status,
          probableEvent.value.status_id,
          probableEvent.value.details,
        ],
        () => {
          const newProbableClock = resolveProbableClock(
            factualEvent.value,
            probableEvent.value,
            actionQueue.value,
            eventStatusesConfiguration,
            incidentsConfiguration,
          )

          probableClock.value = { ...newProbableClock }
        },
        { deep: true },
      )

      const unwatchProbableClockEventConfigurationDependencies = watch(
        () => [eventStatusesConfiguration],
        () => {
          const newProbableClock = resolveProbableClock(
            factualEvent.value,
            probableEvent.value,
            actionQueue.value,
            eventStatusesConfiguration,
            incidentsConfiguration,
          )

          // Setup clock ticker again if event status changed
          unwatchClockTicker()
          unwatchClockTicker = setupClockTicker(
            probableEvent,
            eventStatusesConfiguration,
            probableClock,
          )

          probableClock.value = { ...newProbableClock }
        },
        { deep: true },
      )

      unbindProbableClock = () => {
        unwatchClockTicker()
        unwatchProbableClockDependencies()
        unwatchProbableClockEventConfigurationDependencies()
      }

      return probableClock
    })
    .when(() => true)
    .onDeactivation(async () => {
      unbindProbableClock()
    })
    .when(() => true)
}

function resolveProbableClock(
  factualEvent: CollectorPushMessageEvent,
  probableEvent: Event,
  actionQueue: ActionQueue,
  eventStatusesConfiguration: EventStatusesConfiguration,
  incidentsConfiguration: IncidentsConfiguration,
): Clock {
  const factualClockTime = factualEvent.data.clock_time
  const factualClockStatus = factualEvent.data.clock_status

  const probableTimeActions = actionQueue.queue
    .flatMap((action) =>
      action instanceof AddIncident || action instanceof UpdateEventTime
        ? [action]
        : [],
    )
    .map((action) => {
      if (action instanceof AddIncident) {
        const incidentConfiguration =
          incidentsConfiguration[action.incidentsQueuePostDTO.incident_id] ??
          incidentsConfiguration.fallbackIncidentConfiguration

        return new ProbableTimeAddIncidentAction(action, incidentConfiguration)
      }

      return new ProbableTimeUpdateEventTimeAction(action)
    })

  let prevProbableTimeAction: ProbableTimeAction | null = null
  let probableTime = factualClockTime !== null ? factualClockTime * 1000 : null
  let probableStatus = factualClockStatus
  for (const probableTimeAction of probableTimeActions) {
    if (probableTimeAction.isTimeSetter()) {
      probableTime = probableTimeAction.getTimeToSet()
    } else if (probableTime === null && probableTimeAction.isTimeStarter()) {
      probableTime = 0
    } else if (probableTime !== null) {
      if (
        prevProbableTimeAction?.isTimeStarter() &&
        probableTimeAction.isTimeStatusSetter()
      ) {
        probableTime += getTimePassedBetweenActions(
          probableTimeAction,
          prevProbableTimeAction,
        )
      } else if (
        !prevProbableTimeAction &&
        !probableTimeAction.isTimeSetter() &&
        probableTimeAction.isTimeStopper() &&
        factualClockStatus === ClockStatus.Running
      ) {
        probableTime += getTimePassedBetweenActionAndEventClockTime(
          probableTimeAction,
          factualEvent,
        )
      }
    }

    if (probableTimeAction.isTimeStarter()) {
      probableStatus = ClockStatus.Running
    } else if (probableTimeAction.isTimeStopper()) {
      probableStatus = ClockStatus.Stopped
    }

    if (probableTimeAction.isTimeOrStatusSetter()) {
      prevProbableTimeAction = probableTimeAction
    }
  }

  if (probableTime !== null) {
    if (
      prevProbableTimeAction?.isTimeStarter() ||
      (prevProbableTimeAction?.isTimeSetter() &&
        probableStatus === ClockStatus.Running)
    ) {
      probableTime += getTimePassedSinceLastAction(prevProbableTimeAction)
    }

    if (!prevProbableTimeAction && factualClockStatus === ClockStatus.Running) {
      probableTime += getTimePassedSinceCurrentClockTime(factualEvent)
    }

    probableTime = covertMillisecondsToSeconds(probableTime)
  }

  const eventStatusConfiguration = getOrThrow(
    eventStatusesConfiguration,
    probableEvent.status_id,
  )

  const finalClockTime =
    isNumber(probableTime) &&
    !isTimeInEventStatusBoundary(probableTime, eventStatusConfiguration)
      ? (eventStatusConfiguration.timeBoundaryInSeconds ?? null)
      : probableTime
  const finalClockStatus = probableStatus
  const { minute, minuteFormatted, second, secondFormatted } =
    getMinuteAndSecond(eventStatusConfiguration, finalClockTime)

  return {
    time: finalClockTime,
    status: finalClockStatus,
    minute,
    minuteFormatted,
    second,
    secondFormatted,
  }
}

function getTimePassedBetweenActionAndEventClockTime(
  probableTimeAction: ProbableTimeAction,
  event: CollectorPushMessageEvent,
): number {
  return Math.abs(
    probableTimeAction.getActionTime() -
      convertSecondsToMilliseconds(event.pushMessageUt),
  )
}

function getTimePassedBetweenActions(
  probableTimeAction: ProbableTimeAction,
  prevProbableTimeAction: ProbableTimeAction,
): number {
  return Math.abs(
    probableTimeAction.getActionTime() - prevProbableTimeAction.getActionTime(),
  )
}

function getTimePassedSinceCurrentClockTime(
  event: CollectorPushMessageEvent,
): number {
  return Date.now() - convertSecondsToMilliseconds(event.pushMessageUt)
}

function getTimePassedSinceLastAction(
  prevProbableTimeAction: ProbableTimeAction,
): number {
  return Date.now() - prevProbableTimeAction.getActionTime()
}

function covertMillisecondsToSeconds(ms: number): number {
  return Math.floor(ms / 1000)
}

function convertSecondsToMilliseconds(s: number): number {
  return s * 1000
}
