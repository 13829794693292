import {
  Confirmation,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'
import { useNavigationSeparator } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useNavigationSeparator'
import { usePopupButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/usePopupButton'
import { PopupBuilder } from '@mobile/views/Relation/Shared/Popup/builder/PopupBuilder'
import SelectedParticipant from '@mobile/views/Relation/Shared/Popup/builder/SelectedParticipant.vue'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'

import { PenaltyGoalPopup } from './PenaltyGoalPopup'

export const ConfirmedPenaltyPopup = new PopupBuilder('Confirmed Penalty')
  .setNavigationButtons([
    usePopupButton({
      props: {
        text: 'Close',
        color: 'red',
        onClick: (context: PopupContext | null) => context?.closePopup(),
      },
    }),
    usePopupButton({
      props: {
        text: 'Saved by Goalkeeper',
        color: 'blue',
        onClick: (context: PopupContext | null) => {
          context?.sendIncident({
            incident_id: SoccerIncidentId.PenaltySavedByGoalkeeper,
            confirmation: Confirmation.Confirmed,
            participant_team_id: context?.incidentActionComposable
              .getProbableLinkedParticipant()
              ?.getParticipant().value?.id,
          })
          context?.closeAllPopups()
        },
      },
    }),
    usePopupButton({
      props: {
        text: 'Missed',
        color: 'blue',
        onClick: (context: PopupContext | null) => {
          context?.sendIncident({
            incident_id: SoccerIncidentId.MissedPenalty,
            confirmation: Confirmation.Confirmed,
            participant_team_id: context?.incidentActionComposable
              .getProbableLinkedParticipant()
              ?.getParticipant().value?.id,
          })
          context?.closeAllPopups()
        },
      },
    }),
    ...useNavigationSeparator(2),
    usePopupButton({
      props: {
        text: 'Scored',
        color: 'green',
        onClick: (context: PopupContext | null) =>
          context?.openPopup(PenaltyGoalPopup),
      },
    }),
  ])
  .appendBody(SelectedParticipant)
  .build()
