import { IncidentQueueApiError } from '../errors/IncidentQueueApiError'
import { IncidentQueueTimeoutError } from '../errors/IncidentQueueTimeoutError'
import {
  CollectorPushMessageError,
  CollectorPushMessageEvent,
  CollectorPushMessageIncident,
} from '../types/shared/collector.push.message.types'
import { LineupsRecord } from '../types/shared/EventsLineups'

export type SocketIncidentResponse = {
  event: CollectorPushMessageEvent
  incident: CollectorPushMessageIncident
}

type onUuidListenerObject = {
  onWebsocketUuidMessage: (data: SocketIncidentResponse) => void
  onError: (error: Error) => void
  timeout: number
}

export class NotUuidThatWasDispatchedByIncidentsQueue extends Error {}

export class WebsocketMessageConfirmator {
  public readonly onUuidListeners: Map<string, onUuidListenerObject[]> =
    new Map()

  public readonly onAnyEventListeners: Map<number, Set<() => void>> = new Map()

  public readonly onLineupListeners: Map<number, Set<() => void>> = new Map()

  public readonly incidentsToResolve: Map<
    string,
    {
      incident: CollectorPushMessageIncident
      timeout: number
    }
  > = new Map()

  public readonly eventsToResolve: Map<
    string,
    {
      event: CollectorPushMessageEvent
      timeout: number
    }
  > = new Map()

  public readonly errorsToResolve: Map<
    string,
    {
      error: CollectorPushMessageError
      timeout: number
    }
  > = new Map()

  public readonly lineupsToResolve: Map<
    number,
    {
      lineup: LineupsRecord
      timeout: number
    }
  > = new Map()

  public readonly uuidsFromIncidentsQueue: Set<string> = new Set()

  constructor(
    private readonly responseTimeout: number,
    private readonly waitingTimeMessageTimeout: number,
  ) {}

  public waitForAnyEventMessage(
    eventId: number,
    timeoutMs: number,
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const cleanup = (): void => {
        const listeners = this.onAnyEventListeners.get(eventId)
        listeners?.delete(success)
        if (!listeners?.size) {
          this.onAnyEventListeners.delete(eventId)
        }
      }
      const success = (): void => {
        resolve()
        cleanup()
      }
      const fail = (): void => {
        reject(new Error('No return message has been received'))
        cleanup()
      }
      let eventListeners = this.onAnyEventListeners.get(eventId)
      if (!eventListeners) {
        eventListeners = new Set()
      }
      eventListeners.add(success)
      this.onAnyEventListeners.set(eventId, eventListeners)
      setTimeout(() => {
        fail()
      }, timeoutMs)
    })
  }

  public waitForLineupMessage(
    eventId: number,
    timeoutMs: number,
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const cleanup = (): void => {
        const listeners = this.onLineupListeners.get(eventId)
        listeners?.delete(success)
        if (!listeners?.size) {
          this.onLineupListeners.delete(eventId)
        }
      }
      const success = (): void => {
        resolve()
        cleanup()
      }
      const fail = (): void => {
        reject(new Error('No return message has been received'))
        cleanup()
      }
      let lineupListeners = this.onLineupListeners.get(eventId)
      if (!lineupListeners) {
        lineupListeners = new Set()
      }
      lineupListeners.add(success)
      this.onLineupListeners.set(eventId, lineupListeners)
      setTimeout(() => {
        fail()
      }, timeoutMs)
    })
  }

  private fireAnyEventListeners(eventId: number): void {
    // This timeout is needed so same queue actions won't overlap with eachother
    setTimeout(() => {
      this.onAnyEventListeners.get(eventId)?.forEach((cb) => cb())
    })
  }

  private fireLineupListeners(eventId: number): void {
    // This timeout is needed so same queue actions won't overlap with eachother
    setTimeout(() => {
      this.onLineupListeners.get(eventId)?.forEach((cb) => cb())
    })
  }

  public onUuid(
    uuid: string,
    onWebsocketUuidMessage: (event: SocketIncidentResponse) => void,
    onError: (data: Error) => void,
    fromIncidentsQueue: boolean,
  ): void {
    if (fromIncidentsQueue) {
      this.uuidsFromIncidentsQueue.add(uuid)
    }

    // Since SportsAPI doesn't have a way to queue event updates while receiving uuid
    // this is workaround to confirm event updates by erroring out uuids that haven't been dispatched
    // through SportsAPI incidents queue
    if (!fromIncidentsQueue && !this.uuidsFromIncidentsQueue.has(uuid)) {
      onError(new NotUuidThatWasDispatchedByIncidentsQueue())
      return
    }

    const listeners = this.onUuidListeners.get(uuid) || []
    listeners.push({
      onWebsocketUuidMessage,
      onError,
      timeout: window.setTimeout(() => {
        this.onUuidListeners.delete(uuid)
        this.uuidsFromIncidentsQueue.delete(uuid)
        onError(new IncidentQueueTimeoutError(uuid))
      }, this.responseTimeout),
    })
    this.onUuidListeners.set(uuid, listeners)

    this.resolveIfAllRelatedMessagesHaveBeenReceived(uuid)
  }

  public resolveIfAllRelatedMessagesHaveBeenReceived(uuid: string): void {
    const listeners = this.onUuidListeners.get(uuid) ?? []

    const errorToResolve = this.errorsToResolve.get(uuid)
    const incidentToResolve = this.incidentsToResolve.get(uuid)
    const eventToResolve = this.eventsToResolve.get(uuid)
    if (errorToResolve) {
      listeners.forEach((resolver) => {
        resolver.onError(
          new IncidentQueueApiError(uuid, errorToResolve.error.data.message),
        )
      })
      this.onUuidListeners.delete(uuid)
    } else if (eventToResolve && incidentToResolve) {
      listeners.forEach((listener) => {
        listener.onWebsocketUuidMessage({
          event: eventToResolve.event,
          incident: incidentToResolve.incident,
        })
      })
      this.onUuidListeners.delete(uuid)
    }
  }

  public receiveIncident(incident: CollectorPushMessageIncident): void {
    this.addIncidentToResolve(incident)
    this.fireAnyEventListeners(incident.eventId)
    this.resolveIfAllRelatedMessagesHaveBeenReceived(incident.uuid)
  }

  public receiveEvent(event: CollectorPushMessageEvent): void {
    this.addEventToResolve(event)
    this.fireAnyEventListeners(event.eventId)
    this.resolveIfAllRelatedMessagesHaveBeenReceived(event.uuid)
  }

  public receiveLineup(lineup: LineupsRecord): void {
    this.addLineupToResolve(lineup)
    this.fireLineupListeners(lineup.eventId)
  }

  public receiveError(error: CollectorPushMessageError): void {
    this.addErrorToResolve(error)
    this.fireAnyEventListeners(error.eventId)
    this.resolveIfAllRelatedMessagesHaveBeenReceived(error.uuid)
  }

  private addIncidentToResolve(incident: CollectorPushMessageIncident): void {
    const uuid = incident.uuid

    this.incidentsToResolve.set(uuid, {
      incident,
      timeout: window.setTimeout(() => {
        this.incidentsToResolve.delete(uuid)
        this.uuidsFromIncidentsQueue.delete(uuid)
      }, this.waitingTimeMessageTimeout),
    })
  }

  private addEventToResolve(event: CollectorPushMessageEvent): void {
    const uuid = event.uuid

    this.eventsToResolve.set(uuid, {
      event,
      timeout: window.setTimeout(() => {
        this.eventsToResolve.delete(uuid)
        this.uuidsFromIncidentsQueue.delete(uuid)
      }, this.waitingTimeMessageTimeout),
    })
  }

  private addErrorToResolve(error: CollectorPushMessageError): void {
    const uuid = error.uuid

    this.errorsToResolve.set(uuid, {
      error,
      timeout: window.setTimeout(() => {
        this.incidentsToResolve.delete(uuid)
        this.uuidsFromIncidentsQueue.delete(uuid)
      }, this.waitingTimeMessageTimeout),
    })
  }

  private addLineupToResolve(lineup: LineupsRecord): void {
    this.lineupsToResolve.set(lineup.eventId, {
      lineup,
      timeout: window.setTimeout(() => {
        this.lineupsToResolve.delete(lineup.eventId)
      }, this.waitingTimeMessageTimeout),
    })
  }
}
