import { AppDatabase } from '@mobile/database/AppDatabase'

export async function useDatabase(databaseName: string): Promise<AppDatabase> {
  const database = new AppDatabase(databaseName)
  try {
    await database.open()
  } catch (_error) {
    await database.delete()
    await database.open()
  }

  return database
}
