export enum VolleyballIncidentId {
  Point = 294,
  AttackPoint = 295,
  BlockPoint = 296,
  ServiceAce = 298,
  Serve = 301,
  RepeatAction = 302,
  ServiceError = 304,
  Challenge = 306,
  Timeout = 308,
  TimeoutOver = 2719,
  TechnicalTimeoutOver = 2713,
  YellowCard = 309,
  SetBall = 310,
  SetWon = 311,
  MatchBall = 312,
  NotStarted = 318,
  Interrupted = 329,
  Cancelled = 339,
  Postponed = 328,
  StartDelayed = 327,
  Abandoned = 330,
  Finished = 326,
  FinishedAwardedWin = 325,
  FirstSetStared = 320,
  SecondSetStarted = 321,
  ThirdSetStarted = 322,
  FourthSetStarted = 323,
  FifthSetStarted = 324,
  RedCard = 335,
  ToFinish = 342,
  GoldenSetStart = 2001,
  BreakAfterFirstSet = 2003,
  BreakAfterSecondSet = 2004,
  BreakAfterThirdSet = 2005,
  BreakAfterFourthSet = 2006,
  BreakAfterFifthSet = 2007,
  BreakDuringTheGame = 2009,
  InPossession = 2013,
  AttackError = 2051,
  TechnicalErrors = 2053,
  MatchAboutToStart = 2712,
  RefereeChallenge = 2610,
  TechnicalTimeout = 314,
  Cards = 2720,
}
