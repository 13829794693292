import { first, includes } from 'lodash'
import { computed, Ref } from 'vue'

import { CricketIncidentId } from '@collector/sportsapi-client-legacy'
import { EventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

const filteredIncidents = [
  CricketIncidentId.LegalBall,
  CricketIncidentId.StartOfOver,
  CricketIncidentId.IllegalBall,
  CricketIncidentId.BowlerRunningIn,
  CricketIncidentId.FirstInningsAwayStarted,
  CricketIncidentId.FirstInningsHomeStarted,
  CricketIncidentId.SuperOverHomeStarted,
  CricketIncidentId.SuperOverAwayStarted,
]

export function resolveBowlerRunningIncidentRequired(
  eventInjections: EventInjections,
): Ref<boolean> {
  const { probableIncidents } = eventInjections

  const incidents = computed(() => {
    return probableIncidents.value.filter((incident) => {
      return includes(filteredIncidents, incident.incident_id)
    })
  })

  return computed(
    () =>
      first(incidents.value)?.incident_id !== CricketIncidentId.BowlerRunningIn,
  )
}
