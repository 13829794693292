import {
  BeachVolleyballEventDetailId,
  Event,
  relationStatuses,
  SportDetailInputType,
} from '@collector/sportsapi-client-legacy'
import { CollectorSport } from '@mobile/reusables/classes/CollectorSport'

import {
  EventPreparationEventDetail,
  EventPreparationEventDetailsSportFactory,
} from './types'
import { getDetailName, getDetailSelectItems } from './utils'

export function eventPreparationEventDetailsBeachVolleyballFactory(
  event: Event,
  sport: CollectorSport,
): EventPreparationEventDetailsSportFactory {
  function getDetails(): EventPreparationEventDetail[] {
    return [
      {
        name: 'Relation status',
        fieldName: 'relation_status',
        type: SportDetailInputType.Select,
        items: relationStatuses,
      },
      {
        id: BeachVolleyballEventDetailId.PossibleGoldenSet,
        name: getDetailName(
          sport,
          BeachVolleyballEventDetailId.PossibleGoldenSet,
        ),
        type: SportDetailInputType.Select,
        items: getDetailSelectItems(
          event,
          sport,
          BeachVolleyballEventDetailId.PossibleGoldenSet,
        ),
      },
    ]
  }

  return { getDetails }
}
