import {
  CommonResultId,
  HandballResultId,
  HandballStatId,
} from '@collector/sportsapi-client-legacy'

import { ResultIdsSet } from '../../ParticipantsDataTable/ResultIdsSet'
import { StatIdsSet } from '../../ParticipantsDataTable/StatIdsSet'
import { VerifyResultsSportFactory } from './VerifyResultsSportFactory'

export function verifyResultsHandballFactory(): VerifyResultsSportFactory {
  function getResultsIdsSet(): ResultIdsSet {
    return new ResultIdsSet([
      CommonResultId.Result,
      CommonResultId.RegularTime,
      HandballResultId.FirstHalf,
      HandballResultId.SecondHalf,
      HandballResultId.FirstExtratimeFirstHalf,
      HandballResultId.FirstExtratimeSecondHalf,
      HandballResultId.SecondExtratimeFirstHalf,
      HandballResultId.SecondExtratimeSecondHalf,
      CommonResultId.Overtime,
      CommonResultId.Penalty,
    ])
  }

  function getStatIdsSet(): StatIdsSet {
    return new StatIdsSet([
      HandballStatId.TwoMinutesSuspensions,
      HandballStatId.YellowCards,
      HandballStatId.RedCards,
      HandballStatId.BlueCards,
      HandballStatId.PenaltiesEfficiency,
    ])
  }

  return {
    getResultsIdsSet,
    getStatIdsSet,
  }
}
