<script lang="ts">
import { defineComponent } from 'vue'

import {
  BasketballIncidentId,
  BasketballStatId,
} from '@collector/sportsapi-client-legacy'
import ParticipantComponent from '@mobile/components/Participant/Participant.vue'
import MainResult from '@mobile/views/Relation/Shared/LiveView/LiveViewHeader/MainResult.vue'
import Stat from '@mobile/views/Relation/Shared/LiveView/LiveViewHeader/Stat.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import FoulBonusButton from '@mobile/views/Relation/Sports/Basketball3x3/LiveView/LiveViewHeader/FoulBonusButton.vue'

export default defineComponent({
  components: {
    MainResult,
    ParticipantComponent,
    Stat,
    FoulBonusButton,
  },
  setup() {
    const {
      probableFirstParticipant,
      probableLinkedFirstParticipant,
      probableSecondParticipant,
      probableLinkedSecondParticipant,
    } = useEventInjections()

    return {
      probableFirstParticipant,
      probableLinkedFirstParticipant,
      probableSecondParticipant,
      probableLinkedSecondParticipant,
      BasketballStatId,
      BasketballIncidentId,
    }
  },
})
</script>

<template>
  <div class="flex items-center space-x-2 px-2 py-1">
    <div
      class="flex h-8 w-32 items-center justify-start md:w-36 lg:w-48 xl:w-56"
    >
      <ParticipantComponent :participant="probableFirstParticipant" />
    </div>
    <div class="flex h-8 flex-1 flex-row items-center justify-between">
      <div class="flex items-center space-x-2">
        <MainResult
          :probableLinkedParticipant="probableLinkedFirstParticipant"
        />
      </div>
      <FoulBonusButton :participant="probableFirstParticipant" />
      <Stat :statId="BasketballStatId.CurrentQuarterFouls" />
      <FoulBonusButton :participant="probableSecondParticipant" />
      <div class="flex items-center space-x-2">
        <MainResult
          :probableLinkedParticipant="probableLinkedSecondParticipant"
        />
      </div>
    </div>
    <div
      class="flex h-8 w-32 flex-row items-center justify-end md:w-36 lg:w-48 xl:w-56"
    >
      <ParticipantComponent
        :participant="probableSecondParticipant"
        :reverse="true"
      />
    </div>
  </div>
</template>
