<script lang="ts">
import { computed, defineComponent } from 'vue'

import { opChain } from '@collector/shared-utils'
import { CricketIncidentId } from '@collector/sportsapi-client-legacy'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useCricketConfiguration } from '@mobile/views/Relation/Sports/Cricket/CricketRelationDependencies/useCricketConfiguration'

import { resolveBowlerRunningDisabled } from '../resolveBowlerRunningDisabled'
import { resolveStartOverIncidentEnabled } from '../resolveStartOverIncident'

export default defineComponent({
  components: { IncidentButton },
  setup() {
    const eventInjections = useEventInjections()
    const { activeParticipant } = useCricketConfiguration()
    const liveViewButtonEnabled = computed(() => {
      const startOverIncidentEnabled =
        resolveStartOverIncidentEnabled(eventInjections)
      const bowlerRunningDisabled =
        resolveBowlerRunningDisabled(eventInjections)

      if (bowlerRunningDisabled.value) {
        return false
      }

      return startOverIncidentEnabled.value === false
    })

    return {
      CricketIncidentId,
      activeParticipant,
      liveViewButtonEnabled,
      opChain,
    }
  },
})
</script>

<template>
  <div class="grid content-between gap-4">
    <IncidentButton
      class="w-full"
      :type="'horizontal'"
      :incidentId="CricketIncidentId.BowlerRunningIn"
      :participantId="opChain(activeParticipant.participant, (v) => v.id)"
      :size="'xl'"
      :color="'white'"
      :disabled="!liveViewButtonEnabled"
    />
  </div>
</template>
