<script lang="ts">
import { defineComponent } from 'vue'

import {
  CommonIncidentId,
  HandballIncidentId,
} from '@collector/sportsapi-client-legacy'
import BetMarketButton from '@mobile/views/Relation/Shared/BetMarketButton/BetMarketButton.vue'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'

export default defineComponent({
  components: {
    IncidentButton,
    BetMarketButton,
  },
  setup() {
    return {
      HandballIncidentId,
      CommonIncidentId,
    }
  },
})
</script>

<template>
  <div class="grid gap-4">
    <IncidentButton
      class="h-full"
      :type="'horizontal'"
      :size="'xxs'"
      :color="'white'"
      :incidentId="HandballIncidentId.TwoMinuteSuspension"
    />
    <IncidentButton
      class="h-full"
      :type="'horizontal'"
      :size="'xxs'"
      :color="'white'"
      :incidentId="HandballIncidentId.SuspensionOver"
    />
    <BetMarketButton
      class="h-full"
      marketName="bet_status"
      :size="'xxs'"
      :withIcon="true"
      :openMarketsIncident="CommonIncidentId.Betstart"
      :closeMarketsIncident="CommonIncidentId.Betstop"
    />
  </div>
</template>
