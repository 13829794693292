<script lang="ts">
import { defineComponent, Ref, ref, shallowRef } from 'vue'

import { generateUniqueId } from '@mobile/uniqueId'
import TeamSelect from '@mobile/views/Relation/Shared/Popup/TeamSelect.vue'
import { IncidentActionComposable } from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import AddParticipantSubstitution from '@mobile/views/Relation/Sports/Soccer/Popups/Substitution/AddParticipantSubstitution.vue'
import DisplayParticipantSubstitution from '@mobile/views/Relation/Sports/Soccer/Popups/Substitution/DisplayParticipantSubstitution.vue'

type SubmittedSubstitutions = {
  probableLinkedParticipant: ProbableLinkedParticipant
  incidentActionSubstitutionOut: IncidentActionComposable
  incidentActionSubstitutionIn: IncidentActionComposable
  id: string
}

export default defineComponent({
  components: {
    TeamSelect,
    AddParticipantSubstitution,
    DisplayParticipantSubstitution,
  },
  emits: ['close'],
  setup() {
    const { probableLinkedFirstParticipant, probableLinkedSecondParticipant } =
      useEventInjections()

    const selectedParticipant: Ref<ProbableLinkedParticipant | null> =
      shallowRef(null)
    const submittedSubstitutions: Ref<SubmittedSubstitutions[]> = ref([])

    function substitutionAdded(data: Omit<SubmittedSubstitutions, 'id'>): void {
      const {
        probableLinkedParticipant,
        incidentActionSubstitutionOut,
        incidentActionSubstitutionIn,
      } = data
      submittedSubstitutions.value.unshift({
        id: generateUniqueId(),
        probableLinkedParticipant,
        incidentActionSubstitutionOut,
        incidentActionSubstitutionIn,
      })
      selectedParticipant.value = null
    }

    function deleteSubstitution(id: string): void {
      submittedSubstitutions.value = submittedSubstitutions.value.filter(
        (submittedSubstitution) => submittedSubstitution.id !== id,
      )
    }

    function setParticipant(participant: ProbableLinkedParticipant): void {
      selectedParticipant.value = participant
    }

    return {
      setParticipant,
      substitutionAdded,
      deleteSubstitution,
      selectedParticipant,
      submittedSubstitutions,
      probableLinkedFirstParticipant,
      probableLinkedSecondParticipant,
    }
  },
})
</script>

<template>
  <div>
    <TeamSelect
      v-bind="{ defaultParticipant: selectedParticipant, withButtons: false }"
      @participantSelected="setParticipant"
    />
    <div class="space-y-2 px-4">
      <AddParticipantSubstitution
        :probableLinkedParticipant="selectedParticipant"
        @substitutionAdded="substitutionAdded"
      />
      <DisplayParticipantSubstitution
        v-for="submittedSubstitution in submittedSubstitutions"
        :key="submittedSubstitution.id"
        v-bind="submittedSubstitution"
        @deletedSubstitution="deleteSubstitution"
      />
    </div>
  </div>
</template>
