export enum HandballStatId {
  TwoMinutesSuspensions = 122,
  PlayersInPlay = 626,
  PenaltyGoals = 121,
  EmptyNet = 1964,
  Penalties = 481,
  YellowCards = 8,
  RedCards = 9,
  BlueCards = 593,
  PenaltiesEfficiency = 640,
}
