import { Container } from 'inversify'

import { CommonIncidentId } from '@collector/sportsapi-client-legacy'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindEventHeaderConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.EventHeaderConfiguration)
    .toDynamicValue(() => {
      return {
        betMarketButtons: [
          {
            marketName: 'bet_status',
            size: 'xxs',
            openMarketsText: 'BS',
            closeMarketsText: 'BS',
            openMarketsIncident: CommonIncidentId.Betstart,
            closeMarketsIncident: CommonIncidentId.Betstop,
            withIcon: false,
            type: 'horizontal',
          },
        ],
        hasUnconfirmedEvents: false,
      }
    })
    .inSingletonScope()
}
