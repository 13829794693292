import { Container } from 'inversify'
import { computed, Ref, ref } from 'vue'

import { Event, SubParticipant } from '@collector/sportsapi-client-legacy'
import * as app from '@mobile/globalState/app'
import * as sportsAPI from '@mobile/globalState/sportsAPI'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

async function initSeasonParticipants(
  participantId: number,
  probableEvent: Ref<Event>,
): Promise<SubParticipant[]> {
  const { id: eventId, season_id: seasonId } = probableEvent.value
  const record = await app.state.database.seasonParticipants.findForParticipant(
    eventId,
    participantId,
  )

  if (record) {
    return record.participants
  }

  let seasonParticipants: SubParticipant[] = []

  try {
    const response = await sportsAPI.state.client.getSeasonParticipants(
      participantId,
      seasonId,
    )

    seasonParticipants = response.api.data.participants
  } catch (_error) {
    await app.state.database.seasonParticipants.save(eventId, participantId, [])
  }

  return seasonParticipants
}

export function bindSeasonParticipants(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.SeasonParticipants)
    .toDynamicValue(async (ctx) => {
      const [probableEvent, probableHomeParticipant, probableAwayParticipant] =
        await Promise.all([
          ctx.container.getAsync(RelationIoCBindings.ProbableEvent),
          ctx.container.getAsync(RelationIoCBindings.ProbableHomeParticipant),
          ctx.container.getAsync(RelationIoCBindings.ProbableAwayParticipant),
        ])

      const homeSeasonParticipants: Ref<SubParticipant[]> = ref(
        await initSeasonParticipants(
          probableHomeParticipant.value.id,
          probableEvent,
        ),
      )
      const awaySeasonParticipants: Ref<SubParticipant[]> = ref(
        await initSeasonParticipants(
          probableAwayParticipant.value.id,
          probableEvent,
        ),
      )

      const { id: eventId } = probableEvent.value

      return {
        homeSeasonParticipants: computed({
          get() {
            return homeSeasonParticipants.value
          },
          async set(value) {
            await app.state.database.seasonParticipants.save(
              eventId,
              probableHomeParticipant.value.id,
              value,
            )
            homeSeasonParticipants.value = value
          },
        }),
        awaySeasonParticipants: computed({
          get() {
            return awaySeasonParticipants.value
          },
          async set(value) {
            await app.state.database.seasonParticipants.save(
              eventId,
              probableAwayParticipant.value.id,
              value,
            )
            awaySeasonParticipants.value = value
          },
        }),
        eventSeasonParticipants: computed(() => [
          ...homeSeasonParticipants.value,
          ...awaySeasonParticipants.value,
        ]),
      }
    })
    .inSingletonScope()
}
