<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import { Participant } from '@collector/sportsapi-client-legacy'
import ParticipantLogo from '@mobile/components/ParticipantLogo/ParticipantLogo.vue'

export default defineComponent({
  components: { ParticipantLogo },
  props: {
    participant: {
      type: Object as PropType<Participant>,
      required: true,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const participantClasses = computed(() =>
      props.reverse ? ['flex-row-reverse'] : ['flex-row'],
    )

    const participantNameAlignmentClasses = computed(() =>
      props.reverse ? ['mr-1', 'text-right'] : ['ml-1', 'text-left'],
    )

    const participantNameSideClasses = computed(
      () =>
        ({
          1: ['text-home'],
          2: ['text-away'],
        })[props.participant.counter] ?? [],
    )

    return {
      participantClasses,
      participantNameAlignmentClasses,
      participantNameSideClasses,
    }
  },
})
</script>

<template>
  <div
    class="flex items-center"
    :class="participantClasses"
  >
    <div class="w-8">
      <ParticipantLogo
        class="min-w-full max-w-full"
        :participant
      />
    </div>
    <div
      class="text-xs font-bold"
      :class="[
        ...participantNameAlignmentClasses,
        ...participantNameSideClasses,
      ]"
    >
      {{ participant.short_name }}
    </div>
  </div>
</template>
