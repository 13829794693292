import {
  DetailType,
  IncidentAttributeId,
  ShotOnTargetAttribute,
  SoccerGoalAttribute,
  SoccerIncidentAttributeNames,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'
import { SportDetailsConfiguration } from '@mobile/views/Relation/DetailsPanel/Sports/composables/useSportOptions/types'

const DistanceAttributes = [
  SoccerIncidentAttributeNames.InsidePenaltyArea,
  SoccerIncidentAttributeNames.OutsidePenaltyArea,
]
const PartOfBodyAttributes = [
  SoccerIncidentAttributeNames.LeftFoot,
  SoccerIncidentAttributeNames.RightFoot,
  SoccerIncidentAttributeNames.Head,
]
const PlacementAttributes = [
  SoccerIncidentAttributeNames.UpperLeft,
  SoccerIncidentAttributeNames.UpperMiddle,
  SoccerIncidentAttributeNames.UpperRight,
  SoccerIncidentAttributeNames.BottomLeft,
  SoccerIncidentAttributeNames.BottomMiddle,
  SoccerIncidentAttributeNames.BottomRight,
]
const GoalTypeAttributes = [
  SoccerIncidentAttributeNames.PowerShot,
  SoccerIncidentAttributeNames.Volley,
  SoccerIncidentAttributeNames.BicycleKick,
  SoccerIncidentAttributeNames.Chip,
  SoccerIncidentAttributeNames.OneOnOne,
  SoccerIncidentAttributeNames.Crossbar,
  SoccerIncidentAttributeNames.Post,
]
const ShotWoodworkAttribute = [SoccerIncidentAttributeNames.ShotWoodwork]
const ShotOnTargetShotTypeAttributes = [
  SoccerIncidentAttributeNames.PowerShot,
  SoccerIncidentAttributeNames.Volley,
  SoccerIncidentAttributeNames.BicycleKick,
  SoccerIncidentAttributeNames.Chip,
  SoccerIncidentAttributeNames.OneOnOne,
]
const ShotOffTargetShotTypeAttributes = [
  SoccerIncidentAttributeNames.Volley,
  SoccerIncidentAttributeNames.BicycleKick,
  SoccerIncidentAttributeNames.Chip,
  SoccerIncidentAttributeNames.OneOnOne,
]
const ShotBlockedTargetShotTypeAttributes = [
  SoccerIncidentAttributeNames.Volley,
  SoccerIncidentAttributeNames.BicycleKick,
  SoccerIncidentAttributeNames.Chip,
]

export enum SoccerGroupNames {
  Distance = 'Distance',
  PartOfBody = 'Part of body',
  Placement = 'Placement',
  ShotWoodwork = 'Shot woodwork',
  Goalkeeper = 'Goalkeeper',
  GoalType = 'Goal type',
  ShotType = 'Shot type',
}

export const SoccerDetailsConfiguration: SportDetailsConfiguration<SoccerIncidentId> =
  {
    [SoccerIncidentId.Goal]: [
      {
        name: SoccerGroupNames.Distance,
        attributes: DistanceAttributes,
        cols: 2,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
      {
        name: SoccerGroupNames.PartOfBody,
        attributes: [
          ...PartOfBodyAttributes,
          SoccerIncidentAttributeNames.OtherBodyPart,
        ],
        cols: 4,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
      {
        name: SoccerGroupNames.Placement,
        attributes: PlacementAttributes,
        cols: 3,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
      {
        name: SoccerGroupNames.GoalType,
        attributes: GoalTypeAttributes,
        cols: 3,
        important: false,
        type: DetailType.Button,
        multiple: true,
        fulfilledWhen: () => true,
      },
      {
        name: SoccerGroupNames.Goalkeeper,
        attributes: [],
        important: true,
        type: DetailType.Button,
        fulfilledWhen: (_, probableIncident) => {
          return !!probableIncident?.additional_info?.goalkeeper_id
        },
      },
    ],

    [SoccerIncidentId.ShotOnTarget]: [
      {
        name: SoccerGroupNames.Distance,
        attributes: DistanceAttributes,
        cols: 2,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
      {
        name: SoccerGroupNames.PartOfBody,
        attributes: PartOfBodyAttributes,
        cols: 3,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
      {
        name: SoccerGroupNames.Placement,
        attributes: PlacementAttributes,
        cols: 3,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
      {
        name: SoccerGroupNames.ShotType,
        attributes: ShotOnTargetShotTypeAttributes,
        cols: 3,
        important: false,
        type: DetailType.Button,
        multiple: true,
        fulfilledWhen: () => true,
      },
      {
        name: SoccerGroupNames.Goalkeeper,
        attributes: [],
        important: true,
        type: DetailType.Button,
        fulfilledWhen: (_, probableIncident) => {
          const hasGoallineClearance =
            !!probableIncident?.attribute_ids.includes(
              ShotOnTargetAttribute.GoallineClearance,
            )

          return !hasGoallineClearance
            ? !!probableIncident?.additional_info?.goalkeeper_id
            : true
        },
      },
    ],
    [SoccerIncidentId.ShotOffTarget]: [
      {
        name: SoccerGroupNames.Distance,
        attributes: DistanceAttributes,
        cols: 2,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
      {
        name: SoccerGroupNames.PartOfBody,
        attributes: PartOfBodyAttributes,
        cols: 3,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
      {
        name: SoccerGroupNames.ShotWoodwork,
        attributes: ShotWoodworkAttribute,
        cols: 1,
        important: false,
        type: DetailType.Button,
        fulfilledWhen(attributes: IncidentAttributeId[]) {
          if (!attributes.includes(SoccerGoalAttribute.ShotWoodwork))
            return true

          return attributes.some(
            (a) =>
              a === SoccerGoalAttribute.Crossbar ||
              a === SoccerGoalAttribute.Post,
          )
        },
      },
      {
        name: '',
        attributes: [
          SoccerIncidentAttributeNames.Post,
          SoccerIncidentAttributeNames.Crossbar,
        ],
        cols: 2,
        important: false,
        type: DetailType.Button,
        activeWith: SoccerGoalAttribute.ShotWoodwork,
        activeWhen: (selectedAttributes: IncidentAttributeId[]) =>
          selectedAttributes.includes(SoccerGoalAttribute.ShotWoodwork),
        fulfilledWhen: () => true,
      },
      {
        name: SoccerGroupNames.ShotType,
        attributes: ShotOffTargetShotTypeAttributes,
        cols: 4,
        important: false,
        type: DetailType.Button,
        multiple: true,
        fulfilledWhen: () => true,
      },
    ],
    [SoccerIncidentId.ShotBlocked]: [
      {
        name: SoccerGroupNames.Distance,
        attributes: DistanceAttributes,
        cols: 2,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
      {
        name: SoccerGroupNames.PartOfBody,
        attributes: PartOfBodyAttributes,
        cols: 3,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
      {
        name: SoccerGroupNames.ShotType,
        attributes: ShotBlockedTargetShotTypeAttributes,
        cols: 3,
        important: false,
        type: DetailType.Button,
        multiple: true,
        fulfilledWhen: () => true,
      },
    ],
    [SoccerIncidentId.MissedPenalty]: [
      {
        name: SoccerGroupNames.PartOfBody,
        attributes: PartOfBodyAttributes.filter(
          (attribute) => attribute !== SoccerIncidentAttributeNames.Head,
        ),
        cols: 2,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
      {
        name: SoccerGroupNames.ShotWoodwork,
        attributes: ShotWoodworkAttribute,
        cols: 1,
        important: false,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
    ],
    [SoccerIncidentId.PenaltySavedByGoalkeeper]: [
      {
        name: SoccerGroupNames.PartOfBody,
        attributes: PartOfBodyAttributes.filter(
          (attribute) => attribute !== SoccerIncidentAttributeNames.Head,
        ),
        cols: 2,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
      {
        name: SoccerGroupNames.Placement,
        attributes: [
          ...PlacementAttributes.filter(
            (attribute) =>
              attribute !== SoccerIncidentAttributeNames.BottomRight,
          ),
          SoccerIncidentAttributeNames.BottomRightWithDash,
        ],
        cols: 3,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
      {
        name: SoccerGroupNames.Goalkeeper,
        attributes: [],
        important: true,
        optionsFactory: () => [],
        type: DetailType.Select,
        fulfilledWhen: (_, probableIncident) => {
          return !!probableIncident?.additional_info?.goalkeeper_id
        },
      },
    ],
  }

export const SoccerAttributesConfiguration = {
  [SoccerIncidentId.Goal]: [
    SoccerIncidentAttributeNames.OwnGoal,
    SoccerIncidentAttributeNames.PenaltyGoal,
  ],
  [SoccerIncidentId.RedCard]: [
    SoccerIncidentAttributeNames.SecondYellowCard,
    SoccerIncidentAttributeNames.OffField,
  ],
  [SoccerIncidentId.YellowCard]: [SoccerIncidentAttributeNames.OffField],
  [SoccerIncidentId.ShotOffTarget]: [
    SoccerIncidentAttributeNames.ShotWoodwork,
    SoccerIncidentAttributeNames.Post,
    SoccerIncidentAttributeNames.Crossbar,
  ],
  [SoccerIncidentId.MissedPenalty]: [SoccerIncidentAttributeNames.ShotWoodwork],
  [SoccerIncidentId.FreeKick]: [SoccerIncidentAttributeNames.DangerousFreeKick],
  [SoccerIncidentId.VideoAssistantReferee]: [
    SoccerIncidentAttributeNames.PossibleGoal,
    SoccerIncidentAttributeNames.PossiblePenalty,
    SoccerIncidentAttributeNames.PossibleRedCard,
  ],
  [SoccerIncidentId.ShotOnTarget]: [
    SoccerIncidentAttributeNames.GoallineClearance,
  ],
  [SoccerIncidentId.Foul]: [SoccerIncidentAttributeNames.CausesPenalty],
  [SoccerIncidentId.Attack]: [SoccerIncidentAttributeNames.KeyPass],
  [SoccerIncidentId.DangerousAttack]: [SoccerIncidentAttributeNames.KeyPass],
  [SoccerIncidentId.InPossession]: [SoccerIncidentAttributeNames.KeyPass],
}
