import { Container } from 'inversify'

import {
  BeachVolleyballEventStatusId,
  BeachVolleyballIncidentId,
  IncidentId,
} from '@collector/sportsapi-client-legacy'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindEventStatusesConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.EventStatusesConfiguration)
    .toDynamicValue(() => {
      const withCommonIncidentsAllowedDuringTheGame = (
        incidentsId: IncidentId[] = [],
      ): IncidentId[] => {
        return [
          BeachVolleyballIncidentId.YellowCard,
          BeachVolleyballIncidentId.RedCard,
          BeachVolleyballIncidentId.ServiceError,
          BeachVolleyballIncidentId.AttackError,
          BeachVolleyballIncidentId.TechnicalErrors,
          BeachVolleyballIncidentId.Point,
          BeachVolleyballIncidentId.Serve,
          BeachVolleyballIncidentId.ServiceAce,
          BeachVolleyballIncidentId.AttackPoint,
          BeachVolleyballIncidentId.BlockPoint,
          BeachVolleyballIncidentId.SetBall,
          BeachVolleyballIncidentId.SetWon,
          BeachVolleyballIncidentId.MatchBall,
          BeachVolleyballIncidentId.Challenge,
          BeachVolleyballIncidentId.Timeout,
          ...incidentsId,
        ]
      }

      return {
        [BeachVolleyballEventStatusId.NotStarted]: {
          time: null,
          allowedIncidents: [
            BeachVolleyballIncidentId.FirstSetStared,
            BeachVolleyballIncidentId.StartDelayed,
            BeachVolleyballIncidentId.FinishedAwardedWin,
            BeachVolleyballIncidentId.Postponed,
            BeachVolleyballIncidentId.Abandoned,
            BeachVolleyballIncidentId.Cancelled,
          ],
        },
        [BeachVolleyballEventStatusId.Interrupted]: {
          time: null,
          allowedIncidents: [
            BeachVolleyballIncidentId.ToFinish,
            BeachVolleyballIncidentId.FinishedAwardedWin,
            BeachVolleyballIncidentId.Abandoned,
            BeachVolleyballIncidentId.Cancelled,
          ],
        },
        [BeachVolleyballEventStatusId.Postponed]: {
          time: null,
          allowedIncidents: [
            BeachVolleyballIncidentId.StartDelayed,
            BeachVolleyballIncidentId.FinishedAwardedWin,
            BeachVolleyballIncidentId.Abandoned,
            BeachVolleyballIncidentId.Cancelled,
          ],
        },
        [BeachVolleyballEventStatusId.Finished]: { time: null },
        [BeachVolleyballEventStatusId.FinishedAwardedWin]: { time: null },
        [BeachVolleyballEventStatusId.FirstSet]: {
          time: null,
          allowedIncidents: withCommonIncidentsAllowedDuringTheGame([
            BeachVolleyballIncidentId.Interrupted,
            BeachVolleyballIncidentId.SetBreak,
          ]),
        },
        [BeachVolleyballEventStatusId.SecondSet]: {
          time: null,
          allowedIncidents: withCommonIncidentsAllowedDuringTheGame([
            BeachVolleyballIncidentId.Interrupted,
            BeachVolleyballIncidentId.SetBreak,
          ]),
        },
        [BeachVolleyballEventStatusId.ThirdSet]: {
          time: null,
          allowedIncidents: withCommonIncidentsAllowedDuringTheGame([
            BeachVolleyballIncidentId.Interrupted,
            BeachVolleyballIncidentId.GoldenSetStart,
            BeachVolleyballIncidentId.Finished,
          ]),
        },
        [BeachVolleyballEventStatusId.ToFinish]: {
          time: null,
          allowedIncidents: [],
        },
        [BeachVolleyballEventStatusId.GoldenSet]: {
          time: null,
          allowedIncidents: withCommonIncidentsAllowedDuringTheGame([
            BeachVolleyballIncidentId.Interrupted,
            BeachVolleyballIncidentId.Finished,
          ]),
        },
        [BeachVolleyballEventStatusId.BreakAfterFirstSet]: {
          time: null,
          allowedIncidents: [
            BeachVolleyballIncidentId.SecondSetStarted,
            BeachVolleyballIncidentId.Interrupted,
          ],
        },
        [BeachVolleyballEventStatusId.BreakAfterSecondSet]: {
          time: null,
          allowedIncidents: [
            BeachVolleyballIncidentId.ThirdSetStarted,
            BeachVolleyballIncidentId.Interrupted,
          ],
        },
      }
    })
    .inSingletonScope()
}
