import { useCloseButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useCloseButton'
import { useNavigationSeparator } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useNavigationSeparator'
import { PopupBuilder } from '@mobile/views/Relation/Shared/Popup/builder/PopupBuilder'

import PossibleBoundaryPopupDetails from './PossibleBoundaryPopupDetails.vue'

export const PossibleBoundaryPopup = new PopupBuilder('Possible Boundary')
  .appendBody(PossibleBoundaryPopupDetails)
  .setNavigationButtons([
    ...useNavigationSeparator(4),
    useCloseButton({
      color: 'red',
      text: 'Close',
    }),
  ])
  .build()
