import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router'

import * as app from '@mobile/globalState/app'
import { watchOnce } from '@mobile/reusables/watchOnce'

import EventsList from '../views/EventsList/EventsList.vue'
import DetailsPanel from '../views/Relation/DetailsPanel/DetailsPanel.vue'
import Relation from '../views/Relation/Relation.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      name: 'Index',
      path: '/',
      component: {
        setup(): void {
          router.replace({ name: 'EventsList' })
        },
      },
    },
    {
      name: 'EventsList',
      path: '/events-list',
      component: EventsList,
      beforeEnter: waitForAppReady,
    },
    {
      name: 'Relation',
      path: '/relation/:eventId',
      props: true,
      component: Relation,
      beforeEnter: waitForAppReady,
      children: [
        {
          name: 'details-panel',
          path: 'details',
          props: true,
          component: DetailsPanel,
        },
      ],
    },
  ],
})

function waitForAppReady(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
): void {
  app.removeOverlay()

  if (app.state.ready) {
    return next()
  }

  watchOnce(
    () => app.state.ready,
    () => {
      next()
    },
  )
}

router.afterEach((to, from, failure) => {
  if (!failure) {
    from.meta.ioc?.unbindAll()
  }
})

export default router
