<script lang="ts">
import { defineComponent } from 'vue'

import HeaderButton from '@mobile/components/HeaderButton/HeaderButton.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: { HeaderButton },
  setup() {
    const { event } = useEventInjections()
    const eventId = event.value.data.id

    function goToProtocol(): void {
      window.open(
        `${import.meta.env.VITE_SPORTSADMIN_PROTOCOL}${eventId}?ws=yes`,
        '_blank',
      )
    }

    return { goToProtocol }
  },
})
</script>

<template>
  <HeaderButton @click="goToProtocol">Protocol</HeaderButton>
</template>
