<script lang="ts">
import { cloneDeep } from 'lodash'
import { computed, defineComponent, reactive, ref } from 'vue'

import { ResultId } from '@collector/sportsapi-client-legacy'
import UpdateEventParticipants from '@mobile/ActionQueue/Actions/Event/UpdateEventParticipants'
import Participant from '@mobile/components/Participant/Participant.vue'
import Tabs from '@mobile/components/Tabs/Tabs.vue'
import * as popup from '@mobile/globalState/popup'
import { byId } from '@mobile/reusables/entityUtils'
import { participantDiff } from '@mobile/reusables/eventUtils'
import PopupControls from '@mobile/views/Relation/Shared/Popup/PopupControls.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

import { useVerifyResultsFactory } from '../../VerifyResults/factories/VerifyResultsFactory/useVerifyResultsFactory'
import InputNumeric from './InputNumeric.vue'

export default defineComponent({
  components: {
    Tabs,
    Participant,
    InputNumeric,
    PopupControls,
  },
  setup() {
    const {
      probableEvent,
      sport,
      probableHomeParticipant,
      probableAwayParticipant,
      actionQueue,
    } = useEventInjections()
    const verifyResultsFactory = useVerifyResultsFactory(
      probableEvent.value.sport_id,
    )

    const staticHomeParticipant = reactive(
      cloneDeep(probableHomeParticipant.value),
    )
    const staticAwayParticipant = reactive(
      cloneDeep(probableAwayParticipant.value),
    )
    const tabs = ref(['Results', 'Statistics'])
    const tabsCurrentTab = ref(tabs.value[0])
    const resultsToShow = verifyResultsFactory.getResultsIdsSet()

    const results = computed(() =>
      [...resultsToShow].flatMap((resultId: ResultId) => {
        const homeParticipantStat = byId(
          staticHomeParticipant.results || [],
          resultId,
        )
        const awayParticipantStat = byId(
          staticAwayParticipant.results || [],
          resultId,
        )
        const sportResult = byId(sport.value.sportsAPIEntity.results, resultId)

        if (!homeParticipantStat || !awayParticipantStat || !sportResult) {
          return []
        }

        return [
          {
            id: resultId,
            name: sportResult.name,
            home: homeParticipantStat,
            away: awayParticipantStat,
          },
        ]
      }),
    )

    const statisticsToShow = verifyResultsFactory.getStatIdsSet()
    const statistics = computed(() =>
      [...statisticsToShow].flatMap((statId) => {
        const homeParticipantStat = byId(
          staticHomeParticipant.stats || [],
          statId,
        )
        const awayParticipantStat = byId(
          staticAwayParticipant.stats || [],
          statId,
        )
        const sportResult = byId(sport.value.sportsAPIEntity.stats.team, statId)

        if (!homeParticipantStat || !awayParticipantStat || !sportResult) {
          return []
        }

        return [
          {
            id: statId,
            name: sportResult.name,
            home: homeParticipantStat,
            away: awayParticipantStat,
          },
        ]
      }),
    )

    const save = (): void => {
      actionQueue.value.add(
        new UpdateEventParticipants({
          data: [
            participantDiff(
              probableHomeParticipant.value,
              staticHomeParticipant,
            ),
            participantDiff(
              probableAwayParticipant.value,
              staticAwayParticipant,
            ),
          ],
        }),
      )

      popup.hide()
    }

    const cancel = (): void => popup.hide()

    return {
      tabs,
      tabsCurrentTab,
      save,
      cancel,
      results,
      statistics,
      staticHomeParticipant,
      staticAwayParticipant,
    }
  },
})
</script>

<template>
  <div class="bg-silver relative py-4">
    <Tabs
      v-model:currentTab="tabsCurrentTab"
      class="px-4"
      :tabs
    >
      <div class="flex w-full items-center justify-between py-4">
        <div class="flex items-center">
          <Participant :participant="staticHomeParticipant" />
        </div>
        <div class="flex items-center">
          <Participant
            :participant="staticAwayParticipant"
            :reverse="true"
          />
        </div>
      </div>

      <div v-if="tabsCurrentTab === 'Results'">
        <div
          v-for="result in results"
          :key="`eventStats__item-${result.id}`"
        >
          <div class="flex items-center justify-between">
            <InputNumeric
              v-model:value="result.home.value"
              class="mr-4 w-14"
              :min="0"
            />
            <div class="text-gray text-xs">
              {{ result.name }}
            </div>
            <InputNumeric
              v-model:value="result.away.value"
              class="ml-4 w-14"
              :min="0"
            />
          </div>

          <div class="mt-1 flex"></div>
          <hr class="text-gray absolute left-0 right-0 flex opacity-30" />
          <div class="mb-1 flex"></div>
        </div>
        <div
          v-if="results.length == 0"
          class="flex justify-center"
        >
          Results not found
        </div>
      </div>

      <div v-else-if="tabsCurrentTab === 'Statistics'">
        <div
          v-for="statistic in statistics"
          :key="`eventStats__item-${statistic.id}`"
        >
          <div class="flex items-center justify-between">
            <InputNumeric
              v-model:value="statistic.home.value"
              class="mr-4 w-14"
              :min="0"
            />
            <div class="text-gray text-xs">
              {{ statistic.name }}
            </div>
            <InputNumeric
              v-model:value="statistic.away.value"
              class="ml-4 w-14"
              :min="0"
            />
          </div>

          <div class="mt-1 flex"></div>
          <hr class="text-gray absolute left-0 right-0 flex opacity-30" />
          <div class="mb-1 flex"></div>
        </div>
        <div
          v-if="statistics.length == 0"
          class="flex justify-center"
        >
          Stats not found
        </div>
      </div>
    </Tabs>

    <teleport to="#popup">
      <PopupControls
        @confirm="save()"
        @cancel="cancel()"
      />
    </teleport>
  </div>
</template>
