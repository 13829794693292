<script lang="ts">
import { chain } from 'lodash'
import { computed, defineComponent, PropType } from 'vue'

import {
  CommonIncidentId,
  IncidentId,
  Participant,
  SportId,
  TeamSide,
} from '@collector/sportsapi-client-legacy'
import { AddIncident } from '@mobile/ActionQueue/Actions/Actions'
import { getParticipantButtonColor } from '@mobile/components/Button/ButtonUtils'
import ParticipantLogo from '@mobile/components/ParticipantLogo/ParticipantLogo.vue'
import { isHomeTeam } from '@mobile/reusables/entityUtils'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { ActiveParticipant as BasketBallActiveParticipant } from '@mobile/views/Relation/Sports/Basketball/BasketballRelationDependencies/ActiveParticipant'
import { ActiveParticipant as HandballActiveParticipant } from '@mobile/views/Relation/Sports/Handball/HandballRelationDependencies/ActiveParticipant'

type Direction = 'right' | 'left' | 'up' | 'down'

export default defineComponent({
  components: { ParticipantLogo },
  props: {
    participant: {
      type: Object as PropType<Participant>,
      required: true,
    },
    activeParticipant: {
      type: Object as PropType<
        BasketBallActiveParticipant | HandballActiveParticipant
      >,
      required: true,
    },
    inPossessionIncidentId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const {
      actionQueue,
      probableEvent,
      probableClock,
      probableIncidents,
      sidesSwitched,
    } = useEventInjections()

    const buttonClasses = computed(() => {
      const directions: Array<Direction> =
        probableEvent.value.sport_id === SportId.Basketball3x3
          ? ['up', 'down']
          : ['right', 'left']
      const inPossessionShape = (direction: Direction): string =>
        `w-1/2 arrow-${direction}`
      const withoutBallShape = (direction: Direction): string =>
        `w-1/3 chevron-${direction}`
      if (sidesSwitched.value) {
        directions.reverse()
      }

      const background = `bg-${getParticipantButtonColor(props.participant)}`
      const opacity = canInPossessionIncidentBeSent.value
        ? 'opacity-50'
        : 'opacity-80'
      let buttonShape: string

      if (isHomeTeam(props.participant)) {
        buttonShape =
          props.activeParticipant.side === TeamSide.Home
            ? inPossessionShape(directions[0])
            : withoutBallShape(directions[1])
      } else {
        buttonShape =
          props.activeParticipant.side === TeamSide.Away
            ? inPossessionShape(directions[1])
            : withoutBallShape(directions[0])
      }

      return [background, opacity, buttonShape]
    })

    const canInPossessionIncidentBeSent = computed(() => {
      const incidentsToOmit: IncidentId[] = [
        CommonIncidentId.Betstart,
        CommonIncidentId.Betstop,
        CommonIncidentId.TechnicalProblems,
      ]

      const lastIncident = chain(probableIncidents.value)
        .filter(({ incident_id }) => !incidentsToOmit.includes(incident_id))
        .first()
        .value()

      if (!lastIncident) {
        return true
      }

      return !(
        lastIncident.incident_id === props.inPossessionIncidentId &&
        lastIncident.participant_id === props.participant.id
      )
    })

    function sendInPossessionIncident(): void {
      if (!canInPossessionIncidentBeSent.value) {
        return
      }

      actionQueue.value.add(
        new AddIncident({
          incident_id: props.inPossessionIncidentId,
          status_id: probableEvent.value.status_id,
          participant_team_id: props.participant.id,
          ...probableClock.value,
        }),
      )
    }

    return {
      buttonClasses,
      sendInPossessionIncident,
    }
  },
})
</script>

<template>
  <button
    class="transition-clip-path relative flex cursor-pointer flex-col items-center justify-end"
    :class="buttonClasses"
    @click="sendInPossessionIncident"
  >
    <div class="w-21/24 flex flex-col items-center justify-center lg:mb-8">
      <ParticipantLogo
        class="max-h-12"
        :participant
      />
      <span
        class="relative w-full overflow-x-hidden overflow-ellipsis whitespace-nowrap px-4 text-sm font-bold text-white"
      >
        {{ participant.short_name }}
      </span>
    </div>
  </button>
</template>

<style lang="scss" scoped>
.transition-clip-path {
  transition-property: clip-path;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.chevron-up {
  clip-path: polygon(100% 21%, 100% 100%, 0 100%, 0% 21%, 50% 40%);
}
.chevron-down {
  clip-path: polygon(100% 21%, 100% 100%, 0% 100%, 0% 21%, 50% 40%);
}
.arrow-up {
  clip-path: polygon(100% 20%, 100% 100%, 0% 100%, 0% 20%, 50% 0%);
}
.arrow-down {
  clip-path: polygon(100% 20%, 100% 100%, 0% 100%, 0% 20%, 50% 0%);
}

.arrow-right {
  clip-path: polygon(85% 0%, 100% 50%, 85% 100%, 0% 100%, 0 50%, 0% 0%);
}
.arrow-left {
  clip-path: polygon(100% 0%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
}
.chevron-right {
  clip-path: polygon(100% 0, 100% 100%, 0% 100%, 15% 50%, 0% 0%);
}
.chevron-left {
  clip-path: polygon(100% 0, 80% 50%, 100% 100%, 0% 100%, 0% 0%);
}
</style>
