<script lang="ts">
import { defineComponent } from 'vue'

import { ClockStatus } from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import * as popup from '@mobile/globalState/popup'
import EventTimeButton from '@mobile/views/Relation/Shared/EventTimeButton/EventTimeButton.vue'
import PopupControls from '@mobile/views/Relation/Shared/Popup/PopupControls.vue'
import PopupTitle from '@mobile/views/Relation/Shared/Popup/PopupTitle.vue'
import { useTimeChange } from '@mobile/views/Relation/Shared/Popups/EventTime/useTimeChange'

export default defineComponent({
  components: {
    PopupTitle,
    Button,
    EventTimeButton,
    PopupControls,
  },
  setup() {
    const timeChangeValues: number[] = [1, 5, 10]

    const {
      clockTimeChange,
      clockTimeChangeFormatted,
      isTimeChangeable,
      addTime,
      clockTimeAfterChange,
      clockTimeAfterChangeFormatted,
      isTimeSaveable,
      saveClockTime,
    } = useTimeChange()

    function saveAndClosePopup(): void {
      saveClockTime()
      popup.hide()
    }

    function closePopup(): void {
      popup.hide()
    }

    return {
      ClockStatus,
      isTimeChangeable,
      clockTimeChange,
      clockTimeChangeFormatted,
      clockTimeAfterChange,
      clockTimeAfterChangeFormatted,
      isTimeSaveable,
      timeChangeValues,
      addTime,
      saveAndClosePopup,
      closePopup,
    }
  },
})
</script>

<template>
  <div>
    <PopupTitle>
      <template #title>Event time</template>
    </PopupTitle>

    <div class="flex w-full flex-row justify-center space-x-6 pt-6">
      <div class="flex w-60 flex-col items-center">
        <span class="text-sm font-bold">Minutes</span>
        <div class="mt-4 flex space-x-2">
          <Button
            v-for="(timeChangeValue, index) in timeChangeValues"
            :key="index"
            class="w-16"
            :disabled="!isTimeChangeable"
            :color="'white'"
            :size="'sm'"
            @click="addTime(timeChangeValue * 60)"
          >
            <span class="text-2xl font-bold">+{{ timeChangeValue }}</span>
          </Button>
        </div>
        <div class="mt-2 flex space-x-2">
          <Button
            v-for="(timeChangeValue, index) in timeChangeValues"
            :key="index"
            class="w-16"
            :disabled="!isTimeChangeable"
            :color="'white'"
            :size="'sm'"
            @click="addTime(timeChangeValue * 60 * -1)"
          >
            <span class="text-2xl font-bold">-{{ timeChangeValue }}</span>
          </Button>
        </div>
      </div>

      <div class="flex w-32 flex-col items-center">
        <span class="text-sm font-bold">After change</span>
        <div
          v-if="
            clockTimeAfterChangeFormatted.minute !== null &&
            clockTimeAfterChangeFormatted.second !== null
          "
          class="mt-4 flex flex-col items-center"
        >
          <div class="text-blue flex font-mono text-4xl font-bold">
            {{ clockTimeAfterChangeFormatted.minute }}:{{
              clockTimeAfterChangeFormatted.second
            }}
          </div>
          <div
            v-if="clockTimeChange !== 0"
            class="flex font-mono text-sm"
          >
            ({{ clockTimeChange > 0 ? '+' : '-'
            }}{{ clockTimeChangeFormatted.minute }}:{{
              clockTimeChangeFormatted.second
            }})
          </div>
          <div
            v-else
            class="flex text-center font-mono text-sm"
          >
            (Nothing's changed)
          </div>
        </div>
      </div>

      <div class="flex w-60 flex-col items-center">
        <span class="text-sm font-bold">Seconds</span>
        <div class="mt-4 flex space-x-2">
          <Button
            v-for="(timeChangeValue, index) in timeChangeValues"
            :key="index"
            class="w-16"
            :disabled="!isTimeChangeable"
            :color="'white'"
            :size="'sm'"
            @click="addTime(timeChangeValue)"
          >
            <span class="text-2xl font-bold">+{{ timeChangeValue }}</span>
          </Button>
        </div>
        <div class="mt-2 flex space-x-2">
          <Button
            v-for="(timeChangeValue, index) in timeChangeValues"
            :key="index"
            class="w-16"
            :disabled="!isTimeChangeable"
            :color="'white'"
            :size="'sm'"
            @click="addTime(timeChangeValue * -1)"
          >
            <span class="text-2xl font-bold">-{{ timeChangeValue }}</span>
          </Button>
        </div>
      </div>

      <teleport to="#popup">
        <PopupControls>
          <template #buttons>
            <div class="flex w-full flex-row justify-between">
              <div class="flex">
                <EventTimeButton />
              </div>
              <div class="flex space-x-2">
                <Button
                  :color="'red'"
                  :size="'lg'"
                  @click="closePopup()"
                >
                  Close
                </Button>
                <Button
                  :color="'green'"
                  :size="'lg'"
                  :disabled="!isTimeSaveable"
                  @click="saveAndClosePopup()"
                >
                  Save
                </Button>
              </div>
            </div>
          </template>
        </PopupControls>
      </teleport>
    </div>
  </div>
</template>
