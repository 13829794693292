import { useCloseButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useCloseButton'
import { useNavigationSeparator } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useNavigationSeparator'
import { PopupBuilder } from '@mobile/views/Relation/Shared/Popup/builder/PopupBuilder'

import SubstitutionPopupDetails from './SubstitutionPopupDetails.vue'

export const SubstitutionPopup = new PopupBuilder('Substitution')
  .appendBody(SubstitutionPopupDetails)
  .setNavigationButtons([
    ...useNavigationSeparator(4),
    useCloseButton({
      color: 'red',
      text: 'Close',
    }),
  ])
  .build()
