<script setup lang="ts">
import { Participant, Stat, StatId } from '@collector/sportsapi-client-legacy'
import SummaryParticipantName from '@mobile/components/Summary/SummaryParticipantName.vue'
import SummaryParticipantStat from '@mobile/components/Summary/SummaryParticipantStat.vue'
import { byId, getTeamSide } from '@mobile/reusables/entityUtils'
import { getDetailsPanelStats } from '@mobile/views/Relation/DetailsPanel/components/Summary/utils'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

interface Props {
  participant: Participant
  direction: 'right' | 'left'
}

const props = defineProps<Props>()

const { probableEvent } = useEventInjections()

const statsToDisplay = getDetailsPanelStats(probableEvent.value.sport_id)

function getStatValue(statId: StatId): NonNullable<Stat['value']> {
  return byId(props.participant.stats, statId)?.value ?? '-'
}
</script>

<template>
  <SummaryParticipantName
    :color="getTeamSide(probableEvent.participants, participant.id)"
    :direction
  >
    {{ participant.short_name }}
  </SummaryParticipantName>

  <div
    class="flex space-x-4"
    :class="[
      direction === 'right'
        ? 'justify-end'
        : 'flex-row-reverse justify-end space-x-reverse',
    ]"
  >
    <SummaryParticipantStat
      v-for="statId in statsToDisplay"
      :key="`details-panel-stat-${statId}`"
      :reversed="
        getTeamSide(probableEvent.participants, participant.id) !== 'home'
      "
      :value="getStatValue(statId)"
      :statId
    />
  </div>
</template>
