import { Container } from 'inversify'
import { includes } from 'lodash'

import {
  CommonResultId,
  VolleyballCardsAttributeIds,
  VolleyballEventStatusId,
  VolleyballIncidentId,
  volleyballStatusResultsMap,
} from '@collector/sportsapi-client-legacy'
import {
  addToEventResult,
  changeEventStatus,
} from '@mobile/ActionQueue/Actions/Event/EventAction'
import { getOppositeTeam } from '@mobile/reusables/entityUtils'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindIncidentsConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.IncidentsConfiguration)
    .toDynamicValue(() => {
      return {
        [VolleyballIncidentId.NotStarted]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              VolleyballEventStatusId.NotStarted,
            )
          },
        },
        [VolleyballIncidentId.StartDelayed]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              VolleyballEventStatusId.StartDelayed,
            )
          },
        },
        [VolleyballIncidentId.Finished]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              VolleyballEventStatusId.Finished,
            )
          },
        },
        [VolleyballIncidentId.Interrupted]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              VolleyballEventStatusId.Interrupted,
            )
          },
        },
        [VolleyballIncidentId.Cancelled]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              VolleyballEventStatusId.Cancelled,
            )
          },
        },
        [VolleyballIncidentId.Postponed]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              VolleyballEventStatusId.Postponed,
            )
          },
        },
        [VolleyballIncidentId.Abandoned]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              VolleyballEventStatusId.Abandoned,
            )
          },
        },
        [VolleyballIncidentId.FirstSetStared]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              VolleyballEventStatusId.FirstSet,
            )
          },
        },
        [VolleyballIncidentId.SecondSetStarted]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              VolleyballEventStatusId.SecondSet,
            )
          },
        },
        [VolleyballIncidentId.ThirdSetStarted]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              VolleyballEventStatusId.ThirdSet,
            )
          },
        },
        [VolleyballIncidentId.FourthSetStarted]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              VolleyballEventStatusId.FourthSet,
            )
          },
        },
        [VolleyballIncidentId.FifthSetStarted]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              VolleyballEventStatusId.FifthSet,
            )
          },
        },
        [VolleyballIncidentId.BreakAfterFirstSet]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              VolleyballEventStatusId.BreakAfterFirstSet,
            )
          },
        },
        [VolleyballIncidentId.BreakAfterSecondSet]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              VolleyballEventStatusId.BreakAfterSecondSet,
            )
          },
        },
        [VolleyballIncidentId.BreakAfterThirdSet]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              VolleyballEventStatusId.BreakAfterThirdSet,
            )
          },
        },
        [VolleyballIncidentId.BreakAfterFourthSet]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              VolleyballEventStatusId.BreakAfterFourthSet,
            )
          },
        },
        [VolleyballIncidentId.BreakAfterFifthSet]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              VolleyballEventStatusId.BreakAfterFifthSet,
            )
          },
        },
        [VolleyballIncidentId.GoldenSetStart]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              VolleyballEventStatusId.GoldenSet,
            )
          },
        },
        [VolleyballIncidentId.Point]: {
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            const resultsToUpdate =
              volleyballStatusResultsMap[probableEvent.status_id] ?? []
            resultsToUpdate.forEach((result) => {
              addToEventResult(
                probableEvent,
                dto.participant_team_id,
                result,
                1,
              )
            })
          },
        },
        [VolleyballIncidentId.AttackPoint]: {
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            const resultsToUpdate =
              volleyballStatusResultsMap[probableEvent.status_id] ?? []
            resultsToUpdate.forEach((result) => {
              addToEventResult(
                probableEvent,
                dto.participant_team_id,
                result,
                1,
              )
            })
          },
        },
        [VolleyballIncidentId.ServiceAce]: {
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            const resultsToUpdate =
              volleyballStatusResultsMap[probableEvent.status_id] ?? []
            resultsToUpdate.forEach((result) => {
              addToEventResult(
                probableEvent,
                dto.participant_team_id,
                result,
                1,
              )
            })
          },
        },
        [VolleyballIncidentId.ServiceError]: {
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            const resultsToUpdate =
              volleyballStatusResultsMap[probableEvent.status_id] ?? []
            const activeParticipant = probableEvent.participants.find(
              ({ id }) => id === dto.participant_team_id,
            )!
            const oppositeTeam = getOppositeTeam(
              probableEvent.participants,
              activeParticipant,
            )!

            resultsToUpdate.forEach((result) => {
              addToEventResult(probableEvent, oppositeTeam.id, result, 1)
            })
          },
        },
        [VolleyballIncidentId.AttackError]: {
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            const resultsToUpdate =
              volleyballStatusResultsMap[probableEvent.status_id] ?? []
            const activeParticipant = probableEvent.participants.find(
              ({ id }) => id === dto.participant_team_id,
            )!
            const oppositeTeam = getOppositeTeam(
              probableEvent.participants,
              activeParticipant,
            )!

            resultsToUpdate.forEach((result) => {
              addToEventResult(probableEvent, oppositeTeam.id, result, 1)
            })
          },
        },
        [VolleyballIncidentId.TechnicalErrors]: {
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            const resultsToUpdate =
              volleyballStatusResultsMap[probableEvent.status_id] ?? []
            const activeParticipant = probableEvent.participants.find(
              ({ id }) => id === dto.participant_team_id,
            )!
            const oppositeTeam = getOppositeTeam(
              probableEvent.participants,
              activeParticipant,
            )!

            resultsToUpdate.forEach((result) => {
              addToEventResult(probableEvent, oppositeTeam.id, result, 1)
            })
          },
        },
        [VolleyballIncidentId.BlockPoint]: {
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            const resultsToUpdate =
              volleyballStatusResultsMap[probableEvent.status_id] ?? []
            resultsToUpdate.forEach((result) => {
              addToEventResult(
                probableEvent,
                dto.participant_team_id,
                result,
                1,
              )
            })
          },
        },
        [VolleyballIncidentId.SetWon]: {
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            addToEventResult(
              probableEvent,
              dto.participant_team_id,
              CommonResultId.Result,
              1,
            )
          },
        },
        [VolleyballIncidentId.Cards]: {
          applyAddIncidentResultToEvent(
            probableEvent,
            sport,
            dto,
            probableIncident,
          ) {
            const resultsToUpdate =
              volleyballStatusResultsMap[probableEvent.status_id] ?? []
            const activeParticipant = probableEvent.participants.find(
              ({ id }) => id === dto.participant_team_id,
            )!
            const oppositeTeam = getOppositeTeam(
              probableEvent.participants,
              activeParticipant,
            )!
            if (
              includes(
                probableIncident.attribute_ids,
                VolleyballCardsAttributeIds.RedCard,
              )
            )
              resultsToUpdate.forEach((result) => {
                addToEventResult(probableEvent, oppositeTeam.id, result, 1)
              })
          },
        },
        fallbackIncidentConfiguration: {},
      }
    })
    .inSingletonScope()
}
