import { Container } from 'inversify'

import { SoccerIncidentId } from '@collector/sportsapi-client-legacy'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindEventStatusesList(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.EventStatusesList)
    .toDynamicValue(() => {
      return [
        SoccerIncidentId.StartDelayed,
        SoccerIncidentId.MatchAboutToStart,
        SoccerIncidentId.FirstHalf,
        SoccerIncidentId.Halftime,
        SoccerIncidentId.SecondHalf,
        SoccerIncidentId.FinishedRegularTime,
        SoccerIncidentId.WaitingForExtratime,
        SoccerIncidentId.ExtratimeFirstHalf,
        SoccerIncidentId.ExtratimeHalftime,
        SoccerIncidentId.ExtratimeSecondHalf,
        SoccerIncidentId.WaitingForPenalty,
        SoccerIncidentId.PenaltyShootoutStarted,
        SoccerIncidentId.FinishedAfterExtratime,
        SoccerIncidentId.FinishedAfterPenalties,
        SoccerIncidentId.FinishedAwardedWin,
        SoccerIncidentId.Interrupted,
        SoccerIncidentId.Postponed,
        SoccerIncidentId.Cancelled,
        SoccerIncidentId.Abandoned,
        SoccerIncidentId.ToFinish,
      ]
    })
    .inSingletonScope()
}
