import { Container } from 'inversify'
import { orderBy } from 'lodash'
import { Ref, ref, watch } from 'vue'

import {
  CricketStatId,
  Participant,
  TeamSide,
} from '@collector/sportsapi-client-legacy'
import { byId, isHomeTeam } from '@mobile/reusables/entityUtils'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { CricketRelationIoCBindings } from '@mobile/views/Relation/Sports/Cricket/CricketRelationDependencies/CricketRelationDependencies'

import { ActiveParticipant } from './ActiveParticipant'

export function bindActiveParticipant(ioc: Container): void {
  let unwatch: () => void

  ioc
    .bind(CricketRelationIoCBindings.ActiveParticipant)
    .toDynamicValue(async (ctx) => {
      const [probableHomeParticipant, probableAwayParticipant] =
        await Promise.all([
          ctx.container.getAsync(RelationIoCBindings.ProbableHomeParticipant),
          ctx.container.getAsync(RelationIoCBindings.ProbableAwayParticipant),
        ])

      return ref(
        getActiveParticipant(probableHomeParticipant, probableAwayParticipant),
      )
    })
    .inSingletonScope()
    .onActivation(async (ctx, activeParticipant) => {
      const [probableHomeParticipant, probableAwayParticipant] =
        await Promise.all([
          ctx.container.getAsync(RelationIoCBindings.ProbableHomeParticipant),
          ctx.container.getAsync(RelationIoCBindings.ProbableAwayParticipant),
        ])

      unwatch = watch(
        () => [
          probableHomeParticipant.value.stats,
          probableAwayParticipant.value.stats,
        ],
        () => {
          activeParticipant.value = getActiveParticipant(
            probableHomeParticipant,
            probableAwayParticipant,
          )
        },
      )

      return activeParticipant
    })
    .when(() => true)
    .onDeactivation(async () => unwatch())
}

function getActiveParticipant(
  probableHomeParticipant: Ref<Participant>,
  probableAwayParticipant: Ref<Participant>,
): ActiveParticipant {
  const battingParticipant =
    getBattingParticipant(probableHomeParticipant, probableAwayParticipant) ||
    null
  const side = getParticipantSide(battingParticipant)

  return {
    participant: battingParticipant,
    side,
  }
}

export function getParticipantSide(
  participant: Participant | null,
): TeamSide | null {
  if (!participant) {
    return null
  }

  return isHomeTeam(participant) ? TeamSide.Home : TeamSide.Away
}

function getBattingParticipant(
  probableHomeParticipant: Ref<Participant>,
  probableAwayParticipant: Ref<Participant>,
): Participant | undefined {
  return orderBy(
    [probableHomeParticipant.value, probableAwayParticipant.value],
    (participant) => byId(participant.stats, CricketStatId.BattingTeam)?.value,
    'desc',
  ).shift()
}
