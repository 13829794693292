import { Ref } from 'vue'

import router from '@mobile/router'
import { HandballRelationIoCBindings } from '@mobile/views/Relation/Sports/Handball/HandballRelationDependencies/HandballRelationDependencies'

import { ActiveParticipant } from './ActiveParticipant'

export function useHandballConfiguration(): {
  activeParticipant: Ref<ActiveParticipant>
} {
  const ioc = router.currentRoute.value.meta.ioc
  if (!ioc) {
    throw Error(
      'IoC Container has to be instantiated and injected into route meta before using handball configuration',
    )
  }

  const activeParticipant = ioc.get(
    HandballRelationIoCBindings.ActiveParticipant,
  )

  return { activeParticipant }
}
