import {
  Event,
  EventDetailId,
  EventStatusId,
  EventUpdateResponse,
  Participant,
  ResultId,
  Sport,
  StatId,
} from '@collector/sportsapi-client-legacy'
import { Action } from '@mobile/ActionQueue/Action'
import * as scoutMessenger from '@mobile/globalState/scoutMessenger'
import { byId } from '@mobile/reusables/entityUtils'
import { Milliseconds } from '@mobile/reusables/types/enums/Milliseconds'

export default abstract class EventAction extends Action<Event> {
  protected async confirmEventUpdateRequest(
    eventId: Event['id'],
    request: Promise<EventUpdateResponse>,
    skipWaiting = false,
  ): Promise<void> {
    const response = await request
    if (
      response.api.data &&
      'message' in response.api.data &&
      response.api.data.message === 'Nothing changed'
    ) {
      return
    }

    if (skipWaiting) return

    const confirmationPromise =
      scoutMessenger.state.messageConfirmator.waitForAnyEventMessage(
        eventId,
        Milliseconds.TEN_SECONDS,
      )

    return confirmationPromise
  }

  protected checkForScoutMessengerConnectionOrThrow(): void {
    // Since event update response is immediate (it doesnt' go through queue like incidents do)
    // we still wait for message from ScoutMessenger. We need to check for scout messenger connection
    // to avoid potential synchronization errors
    if (!scoutMessenger.state.isConnected) {
      throw new Error('Not connected with scout messenger')
    }
  }
}

export function changeEventStatus(
  probableEvent: Event,
  sport: Sport,
  statusId: EventStatusId,
): void {
  probableEvent.status_id = statusId

  const sportStatus = sport.statuses.find((status) => status.id === statusId)
  if (sportStatus) {
    probableEvent.status_type = sportStatus.type
  }
}

export function addToEventResult(
  probableEvent: Event,
  participantId: number | undefined | null,
  statId: ResultId,
  diff: number,
  reset?: boolean,
): void {
  if (!participantId) {
    return
  }

  const participant = byId(probableEvent.participants, participantId)
  if (!participant) {
    return
  }

  const stat = byId(participant.results, statId)

  if (stat === undefined) {
    return
  }

  if (reset) {
    stat.value = 0

    return
  }

  if (typeof stat?.value !== 'number') {
    stat.value = 0
  }

  stat.value = stat.value + diff
}

function ensureStatExists(participant: Participant, statId: StatId): void {
  // when we start an event offline or we are online for a while, stats collection is empty
  // so to record stats offline, let's initialize an object
  // to keep stat's value
  if (!participant.stats.find((x) => x.id === statId)) {
    participant.stats.push({
      id: statId,
      value: 0,
    })
  }
}

export function addToEventStat(
  probableEvent: Event,
  participantId: number | undefined | null,
  statId: StatId,
  diff: number,
  reset?: boolean,
): void {
  if (!participantId) {
    return
  }

  const participant = byId(probableEvent.participants, participantId)
  if (!participant) {
    return
  }

  ensureStatExists(participant, statId)

  const stat = byId(participant.stats, statId)
  if (typeof stat?.value !== 'number') {
    return
  }

  if (reset) {
    stat.value = 0

    return
  }

  stat.value = stat.value + diff
}

export function setEventStat(
  probableEvent: Event,
  participantId: number | undefined | null,
  statId: StatId,
  diff: number,
): void {
  if (!participantId) {
    return
  }

  const participant = byId(probableEvent.participants, participantId)
  if (!participant) {
    return
  }

  ensureStatExists(participant, statId)

  const stat = byId(participant.stats, statId)
  if (typeof stat?.value !== 'number') {
    return
  }

  stat.value = diff
}

export function setEventDetail(
  probableEvent: Event,
  detailId: EventDetailId,
  value: number | string | null,
): void {
  const detail = byId(probableEvent.details, detailId)
  if (detail) {
    detail.value = value
  }
}
