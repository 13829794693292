import { Container } from 'inversify'
import { computed, ref, Ref } from 'vue'

import { TeamSide } from '@collector/sportsapi-client-legacy'
import { SoccerRelationIoCBindings } from '@mobile/views/Relation/Sports/Soccer/SoccerRelationDependencies/SoccerRelationDependencies'

import { watchOnActionQueueAdded } from './PossessionSide/watchers/watchOnActionQueueAdded'
import { watchOnLiveViewSwipe } from './PossessionSide/watchers/watchOnLiveViewSwipe'

export function bindPossessionSide(ioc: Container): void {
  const mutableCurrentPossessionSide: Ref<TeamSide> = ref(TeamSide.Home)

  let unwatchLiveViewSwipe: () => void
  let unwatchActionQueueAdded: () => void

  ioc
    .bind(SoccerRelationIoCBindings.PossessionSide)
    .toDynamicValue(() => {
      const currentPossessionSide = computed(
        () => mutableCurrentPossessionSide.value,
      )
      const oppositePossessionSide = computed(() =>
        currentPossessionSide.value === TeamSide.Home
          ? TeamSide.Away
          : TeamSide.Home,
      )

      return {
        current: currentPossessionSide,
        opposite: oppositePossessionSide,
      }
    })
    .inSingletonScope()
    .onActivation(async (ctx, injectable) => {
      unwatchLiveViewSwipe = watchOnLiveViewSwipe(
        mutableCurrentPossessionSide,
        injectable,
      )
      unwatchActionQueueAdded = await watchOnActionQueueAdded(
        mutableCurrentPossessionSide,
        ctx,
      )

      return injectable
    })
    .when(() => true)
    .onDeactivation(() => {
      unwatchLiveViewSwipe()
      unwatchActionQueueAdded()
    })
}
