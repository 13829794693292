import { computed, reactive } from 'vue'

const state = reactive({
  active: false,
  text: '',
})

export const active = computed(() => state.active)
export const text = computed(() => state.text)

export function showLoader(text: string): void {
  state.active = true
  state.text = text
}

export function hideLoader(): void {
  state.active = false
}
