import { computed, ComputedRef, WritableComputedRef } from 'vue'

import {
  CommonStatId,
  Participant,
  Skin,
  TeamSide,
} from '@collector/sportsapi-client-legacy'
import * as sportsAPI from '@mobile/globalState/sportsAPI'
import { byId, getTeamSide } from '@mobile/reusables/entityUtils'
import router from '@mobile/router'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

type ParticipantSkinsComposable = {
  participantSkins: WritableComputedRef<Skin[]>
  reloadSkins: () => Promise<void>
  currentSkin: ComputedRef<Skin | null | undefined>
}

export function useParticipantSkins(
  participant: ComputedRef<Participant>,
): ParticipantSkinsComposable {
  const ioc = router.currentRoute.value.meta.ioc
  if (!ioc) {
    throw Error(
      'IoC Container has to be instantiated and injected into route meta before using sport configuration',
    )
  }

  const { homeSkins, awaySkins } = ioc.get(RelationSportCommonIoCBindings.Skins)
  const probableEvent = ioc.get(RelationIoCBindings.ProbableEvent)
  const currentSkin = computed(() => {
    const statsSkinId =
      byId(participant.value.stats, CommonStatId.SkinPattern)?.value ?? null

    if (statsSkinId) {
      return byId(participantSkins.value, statsSkinId)
    }

    return null
  })

  const participantSkins =
    getTeamSide(probableEvent.value.participants, participant.value.id) ===
    TeamSide.Home
      ? homeSkins
      : awaySkins

  async function reloadSkins(): Promise<void> {
    const response = await sportsAPI.state.client.getParticipantSkins([
      participant.value.id,
    ])

    participantSkins.value = response.api.data.skins
  }

  return {
    participantSkins,
    reloadSkins,
    currentSkin,
  }
}
