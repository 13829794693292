<script lang="ts">
import dayjs from 'dayjs'
import { computed, defineComponent, PropType } from 'vue'
import { NavigationFailure } from 'vue-router'

import { CollectorPushMessageEvent } from '@collector/sportsapi-client-legacy'
import CircleButton from '@mobile/components/CircleButton/CircleButton.vue'
import SportIcon from '@mobile/components/SportIcon/SportIcon.vue'
import * as popup from '@mobile/globalState/popup'
import router from '@mobile/router'

export default defineComponent({
  components: {
    SportIcon,
    CircleButton,
  },
  props: {
    event: {
      type: Object as PropType<CollectorPushMessageEvent>,
      required: true,
    },
  },
  setup(props) {
    const goToEvent = (): Promise<NavigationFailure | void | undefined> => {
      popup.hide()

      return router.push({
        name: 'Relation',
        params: { eventId: props.event.eventId },
      })
    }

    const startTime = computed(() =>
      dayjs(props.event.data.start_date, 'YYYY-MM-DD HH:mm').format('HH:mm'),
    )

    return {
      goToEvent,
      startTime,
    }
  },
})
</script>

<template>
  <div
    class="mt-1 flex content-between items-center rounded bg-white px-4 py-2"
    data-test-id="EventBox"
  >
    <SportIcon :sportId="event.data.sport_id" />
    <div class="px-3 font-bold">
      {{ startTime }}
    </div>
    <div class="flex flex-1 px-3 font-bold">
      {{ event.data.name }}
    </div>
    <CircleButton @click="goToEvent()">
      <FontAwesomeIcon icon="video" />
    </CircleButton>
  </div>
</template>
