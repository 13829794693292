import { onBeforeUnmount, ref, Ref, watch } from 'vue'

import * as app from '@mobile/globalState/app'

export function useDeviceOrientation(): { isPortrait: Ref<boolean> } {
  const isPortrait = ref(checkIfIsPortrait())

  function checkIfIsPortrait(): boolean {
    if (window.orientation === undefined) {
      return false
    }

    return [0, 180].includes(window.orientation as number)
  }

  function handleOrientationChange(): void {
    isPortrait.value = checkIfIsPortrait()
  }

  window.addEventListener('orientationchange', handleOrientationChange)
  onBeforeUnmount(() =>
    window.removeEventListener('orientationchange', handleOrientationChange),
  )

  watch(
    isPortrait,
    (isPortrait) => {
      if (isPortrait) app.addOverlay()
      else app.removeOverlay()
    },
    { immediate: true },
  )

  return { isPortrait }
}
