<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({})
</script>

<template>
  <div
    class="text-xxs bg-red flex h-3 items-center justify-center rounded-2xl px-1 text-white"
  >
    <slot></slot>
  </div>
</template>
