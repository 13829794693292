import { computed, ComputedRef, Ref } from 'vue'

import { BetStatus, Event } from '@collector/sportsapi-client-legacy'
import { EventHeaderConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/EventHeaderConfiguration'

export function useLiveViewBackground(
  probableEvent: Ref<Event>,
  eventHeaderConfiguration: EventHeaderConfiguration,
): ComputedRef<string | string[]> {
  return computed<string | string[]>(() => {
    const closedMarketsColor = 'darkred'
    const { bet_status, bet_corners, bet_cards } = probableEvent.value

    if (bet_status === BetStatus.Suspended) {
      return `bg-${closedMarketsColor}`
    }

    const sportHasBetCards = eventHeaderConfiguration.betMarketButtons.some(
      (button) => button.marketName === 'bet_cards',
    )
    const sportHasBetCorners = eventHeaderConfiguration.betMarketButtons.some(
      (button) => button.marketName === 'bet_corners',
    )

    const leftGradientClass =
      sportHasBetCards && bet_cards === BetStatus.Suspended
        ? `from-${closedMarketsColor}`
        : 'from-transparent'
    const rightGradientClass =
      sportHasBetCorners && bet_corners === BetStatus.Suspended
        ? `to-${closedMarketsColor}`
        : 'to-transparent'

    return [
      'bg-gradient-to-r',
      'via-transparent',
      leftGradientClass,
      rightGradientClass,
    ]
  })
}
