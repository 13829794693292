<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import Button from '@mobile/components/Button/Button.vue'
import { IncidentActionComposable } from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'

import AssignPlayersToSubstitution from './AssignPlayersToSubstitution.vue'

export default defineComponent({
  components: {
    Button,
    AssignPlayersToSubstitution,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    probableLinkedParticipant: {
      type: Object as PropType<ProbableLinkedParticipant>,
      required: true,
    },
    incidentActionSubstitutionIn: {
      type: Object as PropType<IncidentActionComposable>,
      required: true,
    },
    incidentActionSubstitutionOut: {
      type: Object as PropType<IncidentActionComposable>,
      required: true,
    },
  },
  emits: ['deletedSubstitution'],
  setup(props, { emit }) {
    function deleteSubstitution(): void {
      props.incidentActionSubstitutionOut.deleteIncident()
      props.incidentActionSubstitutionIn.deleteIncident()
      emit('deletedSubstitution', props.id)
    }

    return {
      backgroundColor: computed(
        () => props.probableLinkedParticipant.css.bgClass || 'bg-white',
      ),
      deleteSubstitution,
    }
  },
})
</script>

<template>
  <div
    class="flex space-x-4 rounded-md p-1"
    :class="backgroundColor"
  >
    <Button
      class="m-auto mx-1 h-8 w-8"
      :color="'red'"
      :size="'xs'"
      @click="deleteSubstitution"
    >
      <FontAwesomeIcon
        class="transform"
        icon="times"
      />
    </Button>
    <AssignPlayersToSubstitution
      :incidentActionSubstitutionIn
      :incidentActionSubstitutionOut
      :probableLinkedParticipant
    />
  </div>
</template>
