import { ResultIdsSet } from '../../ParticipantsDataTable/ResultIdsSet'
import { StatIdsSet } from '../../ParticipantsDataTable/StatIdsSet'
import { VerifyResultsSportFactory } from './VerifyResultsSportFactory'

export function verifyResultsDefaultFactory(): VerifyResultsSportFactory {
  function getResultsIdsSet(): ResultIdsSet {
    return new ResultIdsSet([])
  }

  function getStatIdsSet(): StatIdsSet {
    return new StatIdsSet([])
  }

  return {
    getResultsIdsSet,
    getStatIdsSet,
  }
}
