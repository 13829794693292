import { computed, ComputedRef, Ref } from 'vue'

import {
  AmericanFootballEventStatusId,
  Basketball3x3EventStatusId,
  BasketballEventStatusId,
  BeachVolleyballEventStatusId,
  CricketEventStatusId,
  Event,
  EventStatusId,
  HandballEventStatusId,
  SportId,
  VolleyballEventStatusId,
} from '@collector/sportsapi-client-legacy'

const SportStatusesMap: {
  [key in SportId]?: { notStartedStatusId: EventStatusId }
} = {
  [SportId.Handball]: { notStartedStatusId: HandballEventStatusId.NotStarted },
  [SportId.Basketball]: {
    notStartedStatusId: BasketballEventStatusId.NotStarted,
  },
  [SportId.Cricket]: { notStartedStatusId: CricketEventStatusId.NotStarted },
  [SportId.Volleyball]: {
    notStartedStatusId: VolleyballEventStatusId.NotStarted,
  },
  [SportId.BeachVolleyball]: {
    notStartedStatusId: BeachVolleyballEventStatusId.NotStarted,
  },
  [SportId.AmericanFootball]: {
    notStartedStatusId: AmericanFootballEventStatusId.NotStarted,
  },
  [SportId.Basketball3x3]: {
    notStartedStatusId: Basketball3x3EventStatusId.NotStarted,
  },
}

export function useEventStatus(probableEvent: Ref<Event>): {
  isMatchStarted: ComputedRef<boolean>
} {
  const sportStatusMap = SportStatusesMap[probableEvent.value.sport_id]

  if (!sportStatusMap) {
    throw new Error('Provided sportId is not defined for useEventStatus plugin')
  }

  const isMatchStarted = computed(
    () => probableEvent.value.status_id !== sportStatusMap.notStartedStatusId,
  )

  return { isMatchStarted }
}
