<script lang="ts">
import { cloneDeep } from 'lodash'
import { computed, defineComponent, PropType, ref } from 'vue'

import {
  CricketResultId,
  IllegalBallAttributesId,
  IllegalBallAttributesOptions,
  Result,
} from '@collector/sportsapi-client-legacy'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import UpdateIncident from '@mobile/ActionQueue/Actions/Incidents/UpdateIncident'
import Button from '@mobile/components/Button/Button.vue'
import ToggleButtonGroup, {
  ToggleButtonGroupOption,
} from '@mobile/components/ToggleButton/ToggleButtonGroup.vue'
import ToggleSwitch from '@mobile/components/ToggleSwitch/ToggleSwitch.vue'
import { byId } from '@mobile/reusables/entityUtils'
import { incidentDiff } from '@mobile/reusables/eventUtils'
import { useAttributesToggleOptions } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useAttributesToggleOptions'
import PopupControls from '@mobile/views/Relation/Shared/Popup/PopupControls.vue'
import PopupTitle from '@mobile/views/Relation/Shared/Popup/PopupTitle.vue'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import IncrementableNumber from '@mobile/views/Relation/Sports/Cricket/LiveView/IncrementableNumber/IncrementableNumber.vue'

export default defineComponent({
  components: {
    PopupControls,
    Button,
    ToggleSwitch,
    IncrementableNumber,
    ToggleButtonGroup,
    PopupTitle,
  },
  props: {
    incident: {
      type: Object as PropType<ProbableIncident>,
      required: true,
    },
    probableLinkedParticipant: {
      type: Object as PropType<ProbableLinkedParticipant>,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const isBoundary = ref(false)
    const eventInjections = useEventInjections()
    const { actionQueue, probableEvent } = eventInjections
    const { id: eventId } = probableEvent.value
    const participant = props.probableLinkedParticipant.getParticipant()
    const wickets = computed(
      () => byId(participant.value.results, CricketResultId.Wickets) as Result,
    )
    const staticLinkedIncident = ref(cloneDeep(props.incident))
    const scoreBeforeChange = computed(() => {
      const ballAttributesOption = [...IllegalBallAttributesOptions].find(
        (option) => {
          return staticLinkedIncident.value.attribute_ids.find(
            (attribute) => attribute === option.id,
          )
        },
      )

      return ballAttributesOption?.value as number
    })
    const runsOffBallValue = computed(() => {
      const illegalBallAttributesOption = IllegalBallAttributesOptions.find(
        (option) => {
          return props.incident.attribute_ids.find(
            (attribute) => attribute === option.id,
          )
        },
      )
      const defaultOption = {
        id: IllegalBallAttributesId.Zero,
        value: 0,
      }

      return illegalBallAttributesOption ?? defaultOption
    })

    const attributeInputOptions: ToggleButtonGroupOption[] =
      useAttributesToggleOptions(
        [
          IllegalBallAttributesId.Wides,
          IllegalBallAttributesId.NoBallBat,
          IllegalBallAttributesId.NoBall,
        ],
        eventInjections,
        false,
      )

    const selectedAttributeValue = computed(() => {
      return attributeInputOptions.find((option) => {
        return props.incident.attribute_ids.find(
          (attribute) => attribute === option.value,
        )
      })
    })
    const runsOffBall = ref(runsOffBallValue.value)
    const selectedAttribute = ref(selectedAttributeValue.value?.value || null)

    function updateIncident(): void {
      const diff = incidentDiff(props.incident, staticLinkedIncident.value)

      actionQueue.value.add(
        new UpdateIncident(props.incident.id, {
          incident_id: props.incident.incident_id,
          event_id: eventId,
          status_id: props.incident.event_status_id,
          attribute_ids: [
            runsOffBall.value?.id ?? null,
            Number(selectedAttribute.value),
          ],
          participant_team_id: props.incident.participant_id,
          participant_id: diff.subparticipant_id,
          participant_name: diff.subparticipant_name,
          assistant_id: diff.assistant_id,
          assistant_name: diff.assistant_name,
          minute: null,
          second: null,
        }),
      )

      emit('close')
    }

    return {
      isBoundary,
      runsOffBall,
      selectedAttribute,
      attributeInputOptions,
      updateIncident,
      IllegalBallAttributesOptions,
      scoreBeforeChange,
      wickets,
    }
  },
})
</script>

<template>
  <div class="bg-silver px-3 pt-3">
    <PopupTitle>
      <template #title>Edit illegal ball</template>
    </PopupTitle>
  </div>
  <div class="mt-1 grid space-x-4 rounded-md p-1">
    <div class="grid-gap-2 ml-3 grid w-full">
      <ToggleButtonGroup
        v-model:value="selectedAttribute"
        class="col-span-3"
        :options="attributeInputOptions"
        :type="'horizontal'"
        :undeselectable="true"
      />
    </div>
    <div class="grid-gap-2 grid w-full grid-cols-1 bg-gray-400">
      <IncrementableNumber
        v-model:value="runsOffBall"
        :scoreBeforeChange
        :wickets
        :options="IllegalBallAttributesOptions"
      />
      <div>
        <ToggleSwitch v-model:value="isBoundary">
          <span class="text-gray text-base">Is Boundary</span>
        </ToggleSwitch>
      </div>
    </div>
  </div>
  <PopupControls>
    <template #buttons>
      <div class="flex justify-end space-x-2">
        <Button
          :color="'red'"
          :size="'lg'"
          @click="$emit('close')"
        >
          Close
        </Button>
        <Button
          :color="'green'"
          :size="'lg'"
          @click="updateIncident()"
        >
          Send
        </Button>
      </div>
    </template>
  </PopupControls>
</template>
