<script lang="ts">
import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  ref,
  Ref,
  watch,
} from 'vue'

import ToggleButtonGroup, {
  ToggleButtonGroupOption,
} from '@mobile/components/ToggleButton/ToggleButtonGroup.vue'
import { IncidentActionComposable } from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

enum WhichCorner {
  Unknown,
  Top,
  Bottom,
}

export default defineComponent({
  components: { ToggleButtonGroup },
  props: {
    incidentActionComposable: {
      type: Object as PropType<IncidentActionComposable>,
      required: true,
    },
  },
  setup(props) {
    const { probableHomeParticipant, sidesSwitched } = useEventInjections()

    const actionParticipant =
      props.incidentActionComposable.getProbableLinkedParticipant()
    const whichCorner: Ref<WhichCorner> = ref(WhichCorner.Unknown)
    const whichCornerInputOptions: ComputedRef<ToggleButtonGroupOption[]> =
      computed(() => [
        {
          name: 'Unknown',
          value: WhichCorner.Unknown,
        },
        {
          name: 'Top corner',
          value: sidesSwitched.value ? WhichCorner.Bottom : WhichCorner.Top,
        },
        {
          name: 'Bottom corner',
          value: sidesSwitched.value ? WhichCorner.Top : WhichCorner.Bottom,
        },
      ])

    watch(whichCorner, (whichCorner) => {
      let x: number | null = null
      let y: number | null = null

      switch (whichCorner) {
        case WhichCorner.Unknown:
          x = null
          y = null
          break
        case WhichCorner.Top:
          x =
            actionParticipant?.getParticipant().value?.id ===
            probableHomeParticipant.value.id
              ? 100
              : 1
          y = 1
          break
        case WhichCorner.Bottom:
          x =
            actionParticipant?.getParticipant().value?.id ===
            probableHomeParticipant.value.id
              ? 100
              : 1
          y = 100
          break
      }

      props.incidentActionComposable.updateIncident({
        x_pos: x,
        y_pos: y,
      })
    })

    return {
      whichCorner,
      whichCornerInputOptions,
    }
  },
})
</script>

<template>
  <div class="flex items-start space-x-3 px-4">
    <div class="pt-2 font-bold">Which corner</div>
    <ToggleButtonGroup
      v-model:value="whichCorner"
      :options="whichCornerInputOptions"
      :type="'horizontal'"
      :undeselectable="true"
    />
  </div>
</template>
