import { interfaces } from 'inversify'
import { Ref } from 'vue'

import { TeamSide } from '@collector/sportsapi-client-legacy'
import { Action } from '@mobile/ActionQueue/Action'
import { AddIncident } from '@mobile/ActionQueue/Actions/Actions'
import { EventType } from '@mobile/ActionQueue/EventType'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

import { changePossessionSideOnNewIncident } from '../changePossessionSideOnNewIncident'

export async function watchOnActionQueueAdded(
  mutableCurrentPossessionSide: Ref<TeamSide>,
  ctx: interfaces.Context,
): Promise<() => void> {
  const [
    actionQueue,
    probableEvent,
    probableHomeParticipant,
    probableAwayParticipant,
    notDeletableProbableIncidents,
    incidentsConfiguration,
  ] = await Promise.all([
    ctx.container.getAsync(RelationIoCBindings.ActionQueue),
    ctx.container.getAsync(RelationIoCBindings.ProbableEvent),
    ctx.container.getAsync(RelationIoCBindings.ProbableHomeParticipant),
    ctx.container.getAsync(RelationIoCBindings.ProbableAwayParticipant),
    ctx.container.getAsync(RelationIoCBindings.ProbableNotDeletableIncidents),
    ctx.container.getAsync(
      RelationSportCommonIoCBindings.IncidentsConfiguration,
    ),
  ])

  function handleActionQueueAdded(action: Action<unknown>): void {
    if (!(action instanceof AddIncident)) {
      return
    }

    changePossessionSideOnNewIncident(
      action,
      probableEvent,
      mutableCurrentPossessionSide,
      probableHomeParticipant,
      probableAwayParticipant,
      actionQueue.value,
      notDeletableProbableIncidents,
      incidentsConfiguration,
    )
  }

  function stopWatcher(): void {
    actionQueue.value.off(EventType.ActionAdded, handleActionQueueAdded)
  }

  actionQueue.value.on(EventType.ActionAdded, handleActionQueueAdded)

  return stopWatcher
}
