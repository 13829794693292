import { AmericanFootballAttributeId } from './american-football/attributes/AttributeIds'
import { BasketballAttributeId } from './basketball/attributes/BasketballAttributeIds'
import { Basketball3x3AttributeId } from './basketball3x3/Basketball3x3Attribute'
import { BeachVolleyballAttributeId } from './beach-volleyball/attributes/BeachVolleyballAttribute'
import {
  BetStartAttribute,
  BetStopAttribute,
  BookingMarketsDisabledAttribute,
  BookingMarketsEnabledAttribute,
  CornerMarketsDisabledAttribute,
  CornerMarketsEnabledAttribute,
} from './common'
import { CricketAttributeId } from './cricket/attributes/CricketAttributeIds'
import { HandballAttributeId } from './handball/attributes/HandballAttributeId'
import { IceHockeyAttributeIds } from './ice-hockey/attributes/IceHockeyAttributeIds'
import { SoccerAttributeId } from './soccer/attributes/SoccerAttributeIds'
import { VolleyballAttributeId } from './volleyball/attributes/VolleyballAttribute'

export enum CommonAttributeId {
  None = 0,
}

export type IncidentAttributeId =
  | SoccerAttributeId
  | HandballAttributeId
  | VolleyballAttributeId
  | CricketAttributeId
  | BasketballAttributeId
  | BeachVolleyballAttributeId
  | Basketball3x3AttributeId
  | AmericanFootballAttributeId
  | CommonAttributeId
  | IceHockeyAttributeIds
  | BetStartAttribute
  | BetStopAttribute
  | CornerMarketsEnabledAttribute
  | CornerMarketsDisabledAttribute
  | BookingMarketsEnabledAttribute
  | BookingMarketsDisabledAttribute
