import { Container } from 'inversify'

import { TeamSide } from '@collector/sportsapi-client-legacy'

import { Probables, RelationIoCBindings } from './types'

export type ProbableLinkedParticipant = {
  getLineups():
    | Probables['probableHomeLineups']
    | Probables['probableAwayLineups']
  getParticipant():
    | Probables['probableHomeParticipant']
    | Probables['probableAwayParticipant']
  side: TeamSide
  oppositeSide: TeamSide
  css: {
    bgClass: string
    textClass: string
    gradientFrom: string
    gradientTo: string
    gradient32From: string
    gradient32To: string
    gradient32ToTrans: string
    gradient32ToWhite: string
  }
}

export function bindProbableLinkedParticipants(ioc: Container): void {
  ioc
    .bind(RelationIoCBindings.ProbableLinkedHomeParticipant)
    .toDynamicValue(async (ctx) => {
      const [probableHomeLineups, probableHomeParticipant] = await Promise.all([
        ctx.container.getAsync(RelationIoCBindings.ProbableHomeLineups),
        ctx.container.getAsync(RelationIoCBindings.ProbableHomeParticipant),
      ])

      return {
        getLineups() {
          return probableHomeLineups
        },
        getParticipant() {
          return probableHomeParticipant
        },
        side: TeamSide.Home,
        oppositeSide: TeamSide.Away,
        css: {
          bgClass: 'bg-home',
          textClass: 'text-home',
          gradientFrom: 'from-homeGradientFrom',
          gradientTo: 'to-homeGradientTo',
          gradient32From: 'bg-gradient-from-r-home-32',
          gradient32To: 'bg-gradient-to-r-home-32',
          gradient32ToTrans: 'bg-gradient-to-r-home-32-trans',
          gradient32ToWhite: 'bg-gradient-to-r-home-32-white',
        },
      }
    })
    .inSingletonScope()

  ioc
    .bind(RelationIoCBindings.ProbableLinkedAwayParticipant)
    .toDynamicValue(async (ctx) => {
      const [probableAwayLineups, probableAwayParticipant] = await Promise.all([
        ctx.container.getAsync(RelationIoCBindings.ProbableAwayLineups),
        ctx.container.getAsync(RelationIoCBindings.ProbableAwayParticipant),
      ])

      return {
        getLineups() {
          return probableAwayLineups
        },
        getParticipant() {
          return probableAwayParticipant
        },
        side: TeamSide.Away,
        oppositeSide: TeamSide.Home,
        css: {
          bgClass: 'bg-away',
          textClass: 'text-away',
          gradientFrom: 'from-awayGradientFrom',
          gradientTo: 'to-awayGradientTo',
          gradient32From: 'bg-gradient-from-r-away-32',
          gradient32To: 'bg-gradient-to-r-away-32',
          gradient32ToTrans: 'bg-gradient-to-r-away-32-trans',
          gradient32ToWhite: 'bg-gradient-to-r-away-32-white',
        },
      }
    })
    .inSingletonScope()
}
