<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { nullCoalesce } from '@collector/shared-utils'
import ParticipantLogo from '@mobile/components/ParticipantLogo/ParticipantLogo.vue'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'

import { Formation } from './Formation'

export default defineComponent({
  components: { ParticipantLogo },
  props: {
    formation: {
      type: Object as PropType<Formation>,
      required: true,
    },
    probableLinkedParticipant: {
      type: Object as PropType<ProbableLinkedParticipant>,
      required: true,
    },
    showResetButton: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['formationButtonClicked', 'resetButtonClicked'],
  setup(props) {
    return {
      participant: props.probableLinkedParticipant.getParticipant(),
      nullCoalesce,
    }
  },
})
</script>

<template>
  <div
    class="bg-gradient-to-r"
    :class="[
      probableLinkedParticipant.css.gradientFrom,
      probableLinkedParticipant.css.gradientTo,
    ]"
  >
    <div class="flex items-center justify-between px-2 py-1">
      <div class="flex items-center">
        <div class="mr-4 flex items-center space-x-3">
          <ParticipantLogo
            class="w-10"
            :participant
          />
          <div class="text-sm font-bold text-white">
            {{ participant.short_name }}
          </div>
        </div>
        <div
          class="border-l-1 flex items-center border-solid border-white px-4 text-white"
        >
          <span>Formation</span>
          <button
            class="ml-4 min-w-12 rounded-md border border-white px-3 py-1 text-sm"
            @click="$emit('formationButtonClicked')"
          >
            {{ nullCoalesce(formation.statValue, '-') }}
          </button>
        </div>
      </div>
      <button
        v-if="showResetButton"
        class="rounded-md border border-white px-3 py-1 text-sm text-white"
        @click="$emit('resetButtonClicked')"
      >
        Reset
      </button>
    </div>
  </div>
</template>
