import { cloneDeep } from 'lodash'
import { onBeforeUnmount, ref, Ref, watch } from 'vue'

import { CollectorPushMessageEvent } from '@collector/sportsapi-client-legacy'
import { AppDatabase } from '@mobile/database/AppDatabase'
import { RepositoryEmitter } from '@mobile/database/Repository'

import { updateCollectorEventPushMessage } from './useSynchronizedEvent'

export function useSynchronizedEvents(database: AppDatabase): {
  events: Ref<CollectorPushMessageEvent[] | undefined>
} {
  const events: Ref<CollectorPushMessageEvent[] | undefined> = ref(undefined)

  database.events.getAll().then((rawEvents) => {
    if (!events.value) {
      events.value = []
    }

    events.value.push(...rawEvents)
  })

  const onCreateOrUpdate:
    | RepositoryEmitter<CollectorPushMessageEvent>['created']
    | RepositoryEmitter<CollectorPushMessageEvent>['updated'] = (
    changedEvent,
  ) => {
    updateEvents(events, changedEvent)
  }
  database.events.on('created', onCreateOrUpdate)
  database.events.on('updated', onCreateOrUpdate)

  const onDelete: RepositoryEmitter<CollectorPushMessageEvent>['deleted'] = (
    deletedEvent,
  ) => {
    if (!events.value) {
      return
    }

    const eventIndex = events.value.findIndex(
      (event) => event.eventId === deletedEvent.eventId,
    )
    if (eventIndex !== -1) {
      events.value.splice(eventIndex, 1)
    }
  }
  database.events.on('deleted', onDelete)

  const unwatch = watch(events, (newVal) => {
    if (!newVal) {
      database.events.off('created', onCreateOrUpdate)
      database.events.off('updated', onCreateOrUpdate)
      database.events.off('deleted', onDelete)
      unwatch()
    }
  })

  onBeforeUnmount(() => {
    database.events.off('created', onCreateOrUpdate)
    database.events.off('updated', onCreateOrUpdate)
    database.events.off('deleted', onDelete)
  })

  return { events }
}

function updateEvents(
  events: Ref<CollectorPushMessageEvent[] | undefined>,
  newEvent: CollectorPushMessageEvent,
): void {
  if (!events.value) {
    events.value = [cloneDeep(newEvent)]
  } else {
    const event = events.value.find(
      (event) => event.eventId === newEvent.eventId,
    )
    if (event) {
      updateCollectorEventPushMessage(event, newEvent)
    } else {
      events.value.push(cloneDeep(newEvent))
    }
  }
}

export async function removeUnassignedEvent(
  database: AppDatabase,
  eventId: number,
): Promise<void> {
  await database.clearEvent(eventId)
  /* eslint-disable-next-line no-console */
  console.info(`Event: ${eventId} has been removed`)
}
