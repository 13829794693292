import { first } from 'lodash'
import { computed } from 'vue'

import {
  CricketEventDetailId,
  CricketIncidentId,
  CricketStatId,
  StatusType,
} from '@collector/sportsapi-client-legacy'
import { byId } from '@mobile/reusables/entityUtils'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import StatusButtonOverlay from '@mobile/views/Relation/Shared/StatusButtonOverlay/StatusButtonOverlay.vue'
import OverlayBatterSelection from '@mobile/views/Relation/Sports/Cricket/LiveView/Overlays/OverlayBatterSelection.vue'
import OverlayCoinToss from '@mobile/views/Relation/Sports/Cricket/LiveView/Overlays/OverlayCoinToss.vue'
import OverlayInningStartedBy from '@mobile/views/Relation/Sports/Cricket/LiveView/Overlays/OverlayInningStartedBy.vue'

import { useCricketConfiguration } from './CricketRelationDependencies/useCricketConfiguration'

/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
export function useOverlays() {
  const eventInjections = useEventInjections()
  const { activeParticipant, inactiveParticipant } = useCricketConfiguration()
  const { probableIncidents, probableEvent } = eventInjections

  const battingTeamIsSet = computed(() => {
    if (
      probableIncidents.value.find(
        (incident) => incident.incident_id === CricketIncidentId.BattingTeam,
      )
    ) {
      return true
    }

    if (
      activeParticipant.value.participant?.stats.find(
        (stat) => stat.id === CricketStatId.BattingTeam,
      )?.value === 1 ||
      inactiveParticipant.value.participant?.stats.find(
        (stat) => stat.id === CricketStatId.BattingTeam,
      )?.value === 1
    ) {
      return true
    }

    return false
  })

  const overlayComponents = computed(() => {
    return [
      {
        component: OverlayCoinToss,
        isVisible: !byId(
          probableEvent.value.details,
          CricketEventDetailId.TossWon,
        )?.value,
        props: {
          incidentId: CricketIncidentId.TossWon,
          title: 'Coin toss',
        },
      },
      {
        component: OverlayBatterSelection,
        isVisible: !battingTeamIsSet.value,
        props: {
          incidentId: CricketIncidentId.BattingTeam,
          title: 'Select batting team',
        },
      },
      {
        component: StatusButtonOverlay,
        isVisible: !probableIncidents.value.find(
          (incident) =>
            incident.incident_id === CricketIncidentId.MatchAboutToStart,
        ),
        props: { incidentId: CricketIncidentId.MatchAboutToStart },
      },
      {
        component: OverlayInningStartedBy,
        isVisible:
          probableEvent.value.status_type === StatusType.Scheduled ||
          first(probableIncidents.value)?.incident_id ===
            CricketIncidentId.InningsBreak,
        props: {
          incidentId: CricketIncidentId.FirstInningsHomeStarted,
          title: '1st inning started by',
        },
      },
    ]
  })

  const visibleOverlay = computed(() =>
    overlayComponents.value.find(({ isVisible }) => isVisible),
  )

  return {
    overlayComponents,
    visibleOverlay,
  }
}
