import {
  AmericanFootballIncidentId,
  CommonIncidentId,
} from '@collector/sportsapi-client-legacy'

export const AmericanFootballHideIncidentIds = [
  AmericanFootballIncidentId.Possession,
  AmericanFootballIncidentId.Kickoff,
  AmericanFootballIncidentId.Touchback,
  AmericanFootballIncidentId.Pass,
  AmericanFootballIncidentId.Rush,
  AmericanFootballIncidentId.Turnover,
  AmericanFootballIncidentId.Interception,
  AmericanFootballIncidentId.Touchdown,
  AmericanFootballIncidentId.PossibleTwoPointConversion,
  AmericanFootballIncidentId.TwoPointConversion,
  AmericanFootballIncidentId.FailedTwoPointConversion,
  AmericanFootballIncidentId.PossibleExtraPoint,
  AmericanFootballIncidentId.ExtraPoint,
  AmericanFootballIncidentId.ExtraPointFailed,
  AmericanFootballIncidentId.PossibleFieldGoal,
  AmericanFootballIncidentId.FieldGoal,
  AmericanFootballIncidentId.NoFieldGoal,
  AmericanFootballIncidentId.Safety,
  AmericanFootballIncidentId.Sack,
  AmericanFootballIncidentId.Fumble,
  AmericanFootballIncidentId.FumbleRecovered,
  AmericanFootballIncidentId.Penalty,
  AmericanFootballIncidentId.FairCatch,
  AmericanFootballIncidentId.OnsideKick,
  AmericanFootballIncidentId.Punt,
  AmericanFootballIncidentId.Challenge,
  AmericanFootballIncidentId.ChallengeCorrect,
  AmericanFootballIncidentId.ChallengeIncorrect,
  AmericanFootballIncidentId.Timeout,
  AmericanFootballIncidentId.FirstDown,
  AmericanFootballIncidentId.SecondDown,
  AmericanFootballIncidentId.ThirdDown,
  AmericanFootballIncidentId.FourthDown,
  AmericanFootballIncidentId.Injury,
  AmericanFootballIncidentId.DefensiveReturn,
  CommonIncidentId.Betstart,
  CommonIncidentId.Betstop,
  AmericanFootballIncidentId.InPlay,
  AmericanFootballIncidentId.MatchAboutToStart,
  AmericanFootballIncidentId.FirstQuarterStarted,
  AmericanFootballIncidentId.SecondQuarterStarted,
  AmericanFootballIncidentId.ThirdQuarterStarted,
  AmericanFootballIncidentId.FourthQuarterStarted,
  AmericanFootballIncidentId.FirstExtraTimeStarted,
  AmericanFootballIncidentId.SecondExtraTimeStarted,
  AmericanFootballIncidentId.ThirdExtraTimeStarted,
  AmericanFootballIncidentId.FourthExtraTimeStarted,
  AmericanFootballIncidentId.FifthExtraTimeStarted,
  AmericanFootballIncidentId.EndOfQuarter,
  AmericanFootballIncidentId.BreakAfterFirstQuarter,
  AmericanFootballIncidentId.BreakAfterSecondQuarter,
  AmericanFootballIncidentId.BreakAfterThirdQuarter,
  AmericanFootballIncidentId.BreakAfterFourthQuarter,
  AmericanFootballIncidentId.BreakAfterFirstExtraTime,
  AmericanFootballIncidentId.BreakAfterSecondExtraTime,
  AmericanFootballIncidentId.BreakAfterThirdExtraTime,
  AmericanFootballIncidentId.BreakAfterFourthExtraTime,
  AmericanFootballIncidentId.FirstHalfStarted,
  AmericanFootballIncidentId.SecondHalfStarted,
  AmericanFootballIncidentId.Halftime,
  AmericanFootballIncidentId.Abandoned,
  AmericanFootballIncidentId.Cancelled,
  AmericanFootballIncidentId.FinishedRegularTime,
  AmericanFootballIncidentId.FinishedAfterExtratime,
  AmericanFootballIncidentId.FinishedAwardedWin,
  AmericanFootballIncidentId.Interrupted,
  AmericanFootballIncidentId.Postponed,
  AmericanFootballIncidentId.StartDelayed,
  AmericanFootballIncidentId.TvTimeout,
  AmericanFootballIncidentId.ToFinish,
  AmericanFootballIncidentId.PlayUnderReview,
  AmericanFootballIncidentId.Flag,
  AmericanFootballIncidentId.MatchResumed,
  CommonIncidentId.TechnicalProblems,
]

export const AmericanFootballParticipantIncidentIds = [
  AmericanFootballIncidentId.Kickoff,
  AmericanFootballIncidentId.Touchback,
  AmericanFootballIncidentId.Pass,
  AmericanFootballIncidentId.Rush,
  AmericanFootballIncidentId.Turnover,
  AmericanFootballIncidentId.Interception,
  AmericanFootballIncidentId.Touchdown,
  AmericanFootballIncidentId.TwoPointConversion,
  AmericanFootballIncidentId.FailedTwoPointConversion,
  AmericanFootballIncidentId.ExtraPoint,
  AmericanFootballIncidentId.ExtraPointFailed,
  AmericanFootballIncidentId.FieldGoal,
  AmericanFootballIncidentId.NoFieldGoal,
  AmericanFootballIncidentId.Safety,
  AmericanFootballIncidentId.Sack,
  AmericanFootballIncidentId.Fumble,
  AmericanFootballIncidentId.FumbleRecovered,
  AmericanFootballIncidentId.Penalty,
  AmericanFootballIncidentId.Punt,
  AmericanFootballIncidentId.DefensiveReturn,
]

export const AmericanFootballSecondParticipantIncidentIds = [
  AmericanFootballIncidentId.Pass,
  AmericanFootballIncidentId.Interception,
  AmericanFootballIncidentId.Touchdown,
  AmericanFootballIncidentId.Sack,
  AmericanFootballIncidentId.Fumble,
  AmericanFootballIncidentId.FumbleRecovered,
]

export const AmericanFootballDetailsIncidentIds = [
  AmericanFootballIncidentId.Touchdown,
  AmericanFootballIncidentId.Sack,
  AmericanFootballIncidentId.Pass,
  AmericanFootballIncidentId.Rush,
  AmericanFootballIncidentId.Penalty,
  AmericanFootballIncidentId.Punt,
  AmericanFootballIncidentId.DefensiveReturn,
  AmericanFootballIncidentId.Possession,
  AmericanFootballIncidentId.FirstDown,
  AmericanFootballIncidentId.SecondDown,
  AmericanFootballIncidentId.ThirdDown,
  AmericanFootballIncidentId.FourthDown,
]
