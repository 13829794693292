<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, toRef } from 'vue'

import {
  IncidentActionType,
  SoccerIncidentId,
  TeamSide,
} from '@collector/sportsapi-client-legacy'
import {
  isExistingIncident,
  ProbableIncident,
} from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import Header from '@mobile/components/Header/Header.vue'
import HeaderButton from '@mobile/components/HeaderButton/HeaderButton.vue'
import HeaderButtonWithDropdown, {
  HeaderButtonDropdownItem,
} from '@mobile/components/HeaderButtonWithDropdown/HeaderButtonWithDropdown.vue'
import * as popup from '@mobile/globalState/popup'
import router from '@mobile/router'
import BetMarketButton from '@mobile/views/Relation/Shared/BetMarketButton/BetMarketButton.vue'
import * as IncidentAcronyms from '@mobile/views/Relation/Shared/EventHeader/incident.acronyms'
import NotificationBubble from '@mobile/views/Relation/Shared/NotificationBubble/NotificationBubble.vue'
import ConfirmIncidents from '@mobile/views/Relation/Shared/Popups/ConfirmIncidents/ConfirmIncidents.vue'
import EventSettings from '@mobile/views/Relation/Shared/Popups/EventSettings/EventSettings.vue'
import EventStats from '@mobile/views/Relation/Shared/Popups/EventStats/EventStats.vue'
import EventStatus from '@mobile/views/Relation/Shared/Popups/EventStatus/EventStatus.vue'
import EventTime from '@mobile/views/Relation/Shared/Popups/EventTime/EventTime.vue'
import History from '@mobile/views/Relation/Shared/Popups/History/History.vue'
import NotificationsView from '@mobile/views/Relation/Shared/Popups/Notifications/NotificationsView.vue'
import Queue from '@mobile/views/Relation/Shared/Popups/Queue/Queue.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

import EventHeaderConfirmIncidentsButton from './Buttons/EventHeaderConfirmIncidentsButton.vue'
import EventHeaderNotificationsButton from './Buttons/EventHeaderNotificationsButton.vue'
import EventHeaderQueueButton from './Buttons/EventHeaderQueueButton.vue'
import { HeaderPopupKey } from './HeaderPopupKey'

const popups = {
  [HeaderPopupKey.Queue]: Queue,
  [HeaderPopupKey.EventTime]: EventTime,
  [HeaderPopupKey.History]: History,
  [HeaderPopupKey.ConfirmIncidents]: ConfirmIncidents,
  [HeaderPopupKey.EventStatus]: EventStatus,
  [HeaderPopupKey.Notifications]: NotificationsView,
}

export default defineComponent({
  components: {
    Header,
    HeaderButton,
    HeaderButtonWithDropdown,
    BetMarketButton,
    NotificationBubble,
    EventHeaderQueueButton,
    EventHeaderConfirmIncidentsButton,
    EventHeaderNotificationsButton,
  },
  setup() {
    const showHeader = toRef(popup.state, 'showHeader')
    const {
      event,
      probableLinkedEvent,
      linkedProbableIncidents,
      probableClock,
      sidesSwitched,
    } = useEventInjections()
    const {
      eventHeaderConfiguration,
      eventClockConfiguration,
      eventStatusesConfiguration,
    } = useSportConfiguration()

    const lastIncidents = computed(() =>
      linkedProbableIncidents.value
        .filter(
          (probableIncident) =>
            probableIncident.incident.action !== IncidentActionType.DELETE,
        )
        .slice(0, 10),
    )

    const showEventTime = computed(() => {
      const { clockHidden } = eventStatusesConfiguration[
        probableLinkedEvent.value.event.status_id
      ] ?? { clockHidden: false }

      return !clockHidden && probableClock.value.time !== null
    })

    function getIncidentAcronym(probableIncident: ProbableIncident): string {
      return IncidentAcronyms.getIncidentAcronym(probableIncident)
    }

    function getParticipantAcronym(probableIncident: ProbableIncident): string {
      const participantId = probableIncident.participant_id
      const participant = event.value.data.participants.find(
        (participant) => participant.id === participantId,
      )

      return participant?.acronym ?? ''
    }

    function openPopup(key: HeaderPopupKey): void {
      const component = popups[key]

      popup.show({
        component,
        showHeader: true,
      })
    }

    const headerButtonDropdownItems: HeaderButtonDropdownItem[] = [
      {
        title: 'Change sides',
        onClick: (): void => {
          sidesSwitched.value = !sidesSwitched.value
        },
      },
      {
        title: 'Event settings',
        onClick: (): void =>
          popup.show({
            component: EventSettings,
            showHeader: true,
          }),
      },
      {
        title: 'Result & stats',
        onClick: (): void =>
          popup.show({
            component: EventStats,
            showHeader: true,
          }),
      },
      {
        title: 'Exit to events list',
        onClick: (): void => {
          router.push({ name: 'EventsList' })
        },
        icon: 'sign-out-alt',
        color: 'text-red',
      },
    ]

    onMounted(() => (document.body.style.paddingTop = '40px'))
    onUnmounted(() => (document.body.style.paddingTop = ''))

    return {
      HeaderPopupKey,
      lastIncidents,
      probableLinkedEvent,
      probableClock,
      TeamSide,
      showHeader,
      event,
      SoccerIncidentId,
      headerButtonDropdownItems,
      eventHeaderConfiguration,
      showEventTime,
      openPopup,
      isExistingIncident,
      getParticipantAcronym,
      getIncidentAcronym,
      eventClockConfiguration,
    }
  },
})
</script>

<template>
  <Header v-show="showHeader">
    <div
      class="text-gray border-silver flex cursor-pointer flex-col border-l border-solid p-2"
      @click="openPopup(HeaderPopupKey.History)"
    >
      <FontAwesomeIcon
        class="text-xs"
        icon="history"
      />
      <FontAwesomeIcon
        class="text-xs"
        icon="long-arrow-alt-right"
      />
    </div>
    <div
      class="flex h-10 w-full cursor-pointer items-center overflow-x-hidden"
      data-test-id="HistoryHeader"
      @click="openPopup(HeaderPopupKey.History)"
    >
      <div
        v-for="linkedProbableIncident in lastIncidents"
        :key="linkedProbableIncident.incident.id"
        class="px-1 uppercase"
        :class="{
          'opacity-50': !isExistingIncident(linkedProbableIncident.incident),
          'text-home': linkedProbableIncident.side === TeamSide.Home,
          'text-away': linkedProbableIncident.side === TeamSide.Away,
          'text-gray': !linkedProbableIncident.side,
        }"
      >
        <div class="text-xxs font-bold">
          {{ getParticipantAcronym(linkedProbableIncident.incident) }}
        </div>
        <div class="text-xs">
          {{ getIncidentAcronym(linkedProbableIncident.incident) }}
        </div>
      </div>
    </div>

    <div
      class="border-silver flex h-10 items-center border-l border-r border-solid"
    >
      <div class="flex space-x-1 px-2 py-1">
        <BetMarketButton
          v-for="betMarketButton in eventHeaderConfiguration.betMarketButtons"
          :key="betMarketButton.marketName"
          v-bind="betMarketButton"
        />
      </div>

      <div
        v-if="eventClockConfiguration.isEnabled"
        class="border-silver w-24 cursor-pointer border-l border-r border-solid px-2 py-1 text-center"
        @click="openPopup(HeaderPopupKey.EventTime)"
      >
        <div class="text-blue text-xxs text-center font-bold">
          {{ probableLinkedEvent.sportStatus.short_name }}
        </div>
        <div class="text-gray whitespace-nowrap text-center font-mono text-xs">
          <div :class="{ hidden: !showEventTime }">
            {{ probableClock.minuteFormatted }}:{{
              probableClock.secondFormatted
            }}
          </div>
        </div>
      </div>

      <div
        v-if="eventHeaderConfiguration.showEventStatus"
        class="border-silver w-24 border-l border-r border-solid px-2 py-1 text-center"
      >
        <div class="text-blue text-xxs text-center font-bold">
          {{ probableLinkedEvent.sportStatus.short_name }}
        </div>
      </div>
    </div>

    <div class="divide-silver divide-x-1 flex h-10 items-center">
      <HeaderButton @click="openPopup(HeaderPopupKey.EventStatus)">
        <FontAwesomeIcon icon="stopwatch" />
      </HeaderButton>
      <EventHeaderQueueButton @click="openPopup" />
      <EventHeaderConfirmIncidentsButton
        v-if="eventHeaderConfiguration.hasUnconfirmedEvents"
        @click="openPopup"
      />
      <EventHeaderNotificationsButton @click="openPopup" />
      <HeaderButtonWithDropdown
        icon="bars"
        :items="headerButtonDropdownItems"
      />
    </div>

    <NotificationBubble />
  </Header>
</template>
