import wretch, { WretchError } from 'wretch'

import { ApiErrorResponse } from './types/responses/ApiResponse'

export function isWretchError(error: unknown): error is WretchError {
  return (
    error instanceof wretch.WretchError ||
    (typeof error === 'object' &&
      error !== null &&
      'response' in error &&
      'status' in error)
  )
}

export function isIncidentDuplicationError(
  apiError: ApiErrorResponse,
): boolean {
  return apiError.api.error.status === 409
}
