<script lang="ts">
import { chain } from 'lodash'
import { computed, defineComponent, PropType, Ref, ref, watch } from 'vue'

import { SubParticipant } from '@collector/sportsapi-client-legacy'
import { UpdateSubParticipantDTO } from '@mobile/ActionQueue/Actions/Lineups/UpdateLineups'
import Button from '@mobile/components/Button/Button.vue'
import { closeAlert, showAlert } from '@mobile/globalState/alert'
import { byId } from '@mobile/reusables/entityUtils'
import PopupControls from '@mobile/views/Relation/Shared/Popup/PopupControls.vue'
import SaveInvalidLineupsAlert from '@mobile/views/Relation/Shared/Popups/Lineups/SaveInvalidLineupsAlert.vue'
import SelectedTeamMembers from '@mobile/views/Relation/Shared/Popups/Lineups/SelectedTeamMembers.vue'
import Team from '@mobile/views/Relation/Shared/Popups/Lineups/Team.vue'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'
import { useEventLineups } from '@mobile/views/Relation/useEventLineups'
import { useSeasonParticipants } from '@mobile/views/Relation/useSeasonParticipants'

import { useLineupMaximumPlayersReached } from '../../Lineups/useLineupMaximumPlayersReached'

type SaveInvalidLineupsAlertEvents = Parameters<
  Parameters<
    Exclude<(typeof SaveInvalidLineupsAlert)['setup'], undefined>
  >[1]['emit']
>[0]

export type LineupsViewParticipant = Pick<
  SubParticipant,
  'id' | 'position' | 'captain' | 'name' | 'shirt_nr'
>

export default defineComponent({
  components: {
    Team,
    SelectedTeamMembers,
    PopupControls,
    Button,
  },
  props: {
    probableLinkedParticipant: {
      type: Object as PropType<ProbableLinkedParticipant>,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const participant = props.probableLinkedParticipant.getParticipant()
    const propsLineups = props.probableLinkedParticipant.getLineups()
    const eventInjections = useEventInjections()
    const { lineupsConfiguration } = useSportConfiguration()
    const { seasonParticipants } = useSeasonParticipants(participant)
    const lineups: Ref<UpdateSubParticipantDTO[]> = ref(prepareEventLineups())
    const filteredAvailablePositions =
      lineupsConfiguration?.availablePositions.filter(
        (availableType) => availableType.parentPositionTypeName === undefined,
      )
    const availableSeasonParticipants = computed(() => {
      return seasonParticipants.value.filter(
        (seasonParticipant) => !byId(lineups.value, seasonParticipant.id),
      )
    })
    const { saveLineups } = useEventLineups(
      eventInjections,
      props.probableLinkedParticipant,
    )

    watch(seasonParticipants, () => {
      lineups.value = prepareEventLineups()
    })

    function prepareEventLineups(): UpdateSubParticipantDTO[] {
      return chain(propsLineups.value)
        .map((teamMember) => {
          const seasonParticipant = byId(
            seasonParticipants.value,
            teamMember.participant_id,
          )

          if (seasonParticipant) {
            return prepareSubParticipant(seasonParticipant, {
              name: seasonParticipant.short_name || seasonParticipant.name,
              position: String(teamMember.participant_position),
              captain: !!teamMember.participant_is_captain || undefined,
              shirt_nr: teamMember.participant_shirt_number,
              player_position: null,
            })
          } else return undefined
        })
        .compact()
        .value()
    }

    function prepareSubParticipant(
      { id, shirt_nr }: SubParticipant,
      {
        name,
        captain,
        position,
        shirt_nr: lineupShirtNumber,
        player_position,
      }: Omit<UpdateSubParticipantDTO, 'id'>,
    ): UpdateSubParticipantDTO {
      return {
        id,
        name,
        shirt_nr: lineupShirtNumber ?? shirt_nr,
        captain,
        position,
        player_position,
      }
    }

    function addTeamMember(teamMember: SubParticipant): void {
      lineups.value.push(
        prepareSubParticipant(teamMember, {
          name: teamMember.short_name || teamMember.name,
          position: teamMember.position,
          captain: false,
          shirt_nr: teamMember.shirt_nr,
          player_position: null,
        }),
      )
    }

    function getLineupMembers(typeName: string): UpdateSubParticipantDTO[] {
      const childLineupType = lineupsConfiguration.availablePositions.find(
        (lineup) =>
          lineup.parentPositionTypeName?.toLowerCase() ===
          typeName.toLowerCase(),
      )

      const lineupsFiltered = lineups.value.filter((teamMember) => {
        if (
          childLineupType &&
          teamMember.position?.toLowerCase() ===
            childLineupType.name.toLowerCase()
        ) {
          return teamMember
        }

        return teamMember.position?.toLowerCase() === typeName.toLowerCase()
      })

      return sortLineups(lineupsFiltered)
    }

    function removeTeamMember({ id }: UpdateSubParticipantDTO): void {
      const index = lineups.value.findIndex(
        (teamMember) => teamMember.id === id,
      )

      lineups.value.splice(index, 1)
    }

    function updateTeamMember(teamMember: UpdateSubParticipantDTO): void {
      const index = lineups.value.findIndex(
        (lineupMember) => teamMember.id === lineupMember.id,
      )

      if (teamMember.captain) {
        lineups.value.map((lineupMember) => (lineupMember.captain = false))
      }

      lineups.value.splice(index, 1, teamMember)
    }

    function showSaveLineupsAlert(
      preparedLineups: UpdateSubParticipantDTO[],
    ): void {
      showAlert<SaveInvalidLineupsAlertEvents>(SaveInvalidLineupsAlert, {
        confirm: () => {
          saveLineupsAction(preparedLineups)
          closeAlert()
        },
        close: () => closeAlert(),
      })
    }

    async function performLineupsSaving(): Promise<void> {
      const preparedLineups = lineups.value.map((teamMember) => {
        return filterUpdateSubParticipantDTO({
          ...teamMember,
          position: teamMember.position?.toLowerCase() ?? null,
        })
      })

      const canActionBeSend = lineupsConfiguration.availablePositions
        .filter((position) => position.maximumPlayersAmount)
        .map((position) => {
          if (
            !position.maximumPlayersAmount ||
            position.parentPositionTypeName
          ) {
            return true
          }

          return useLineupMaximumPlayersReached(lineups.value, position)
        })
        .every((condition) => !!condition)

      if (canActionBeSend) {
        saveLineupsAction(sortLineups(preparedLineups))

        return
      }

      showSaveLineupsAlert(preparedLineups)
    }

    function saveLineupsAction(
      preparedLineups: UpdateSubParticipantDTO[],
    ): void {
      saveLineups(preparedLineups)

      emit('close')
    }

    function sortLineups(
      lineups: UpdateSubParticipantDTO[],
    ): UpdateSubParticipantDTO[] {
      if (lineupsConfiguration.lineupPositionOrder.length === 0) {
        return lineups
      }

      return chain(lineups)
        .sortBy(
          (teamMember) =>
            lineupsConfiguration.lineupPositionOrder.indexOf(
              teamMember.position?.toLowerCase() ?? '',
            ) === -1,
        )
        .value()
    }

    function filterUpdateSubParticipantDTO(
      updateSubParticipantDTO: UpdateSubParticipantDTO,
    ): UpdateSubParticipantDTO {
      const lineupConfiguration = lineupsConfiguration.availablePositions.find(
        (configuration) =>
          configuration.name.toLocaleLowerCase() ===
          updateSubParticipantDTO.position,
      )
      if (!lineupConfiguration) {
        return updateSubParticipantDTO
      }

      if (!lineupConfiguration.canCaptainBeSelected) {
        delete updateSubParticipantDTO.captain
      }

      return updateSubParticipantDTO
    }

    return {
      lineups,
      addTeamMember,
      updateTeamMember,
      removeTeamMember,
      getLineupMembers,
      lineupsConfiguration,
      performLineupsSaving,
      availableSeasonParticipants,
      filteredAvailablePositions,
    }
  },
})
</script>

<template>
  <div class="flex h-full w-full flex-row">
    <div class="flex w-full flex-col overflow-x-hidden overflow-y-scroll">
      <Team
        :probableLinkedParticipant
        :availableSeasonParticipants
        :lineups
        @teamMemberAdded="addTeamMember"
      />
    </div>
    <div
      class="max-height-calc flex w-full flex-col overflow-x-hidden overflow-y-scroll pb-24"
    >
      <SelectedTeamMembers
        v-for="availableType in filteredAvailablePositions"
        :key="`type-${availableType.acronym}`"
        :title="availableType.name"
        :placeholder="`There are no players added to ${availableType.name}`"
        :probableLinkedParticipant
        :selectedTeamMembers="getLineupMembers(availableType.name)"
        @teamMemberRemoved="removeTeamMember"
        @teamMemberUpdated="updateTeamMember"
      />
    </div>
  </div>

  <PopupControls>
    <template #buttons>
      <div class="flex justify-end space-x-2">
        <Button
          :color="'red'"
          :size="'lg'"
          @click="$emit('close')"
        >
          Close
        </Button>
        <Button
          :color="'green'"
          :size="'lg'"
          @click="performLineupsSaving"
        >
          Save
        </Button>
      </div>
    </template>
  </PopupControls>
</template>

<style scoped>
.max-height-calc {
  max-height: calc(100% - 6rem);
}
</style>
