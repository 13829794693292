<script lang="ts">
import TextLoader from '@mobile/components/Loader/TextLoader.vue'
import PositionHighlighter from '@mobile/components/PositionHighlighter/PositionHighlighter.vue'
import PressIndicator from '@mobile/components/PressIndicator/PressIndicator.vue'
import RotateScreen from '@mobile/components/RotateScreen/RotateScreen.vue'
import * as alert from '@mobile/globalState/alert'
import * as app from '@mobile/globalState/app'
import {
  active as loaderActive,
  text as loaderText,
} from '@mobile/globalState/loader'
import * as sportsAPI from '@mobile/globalState/sportsAPI'
import Login from '@mobile/views/Login/Login.vue'
import { useLoginFormVisibility } from '@mobile/views/Login/useLoginFormVisibility'

import 'reflect-metadata'

import { defineComponent, toRef } from 'vue'

import { useDeviceOrientation } from '../reusables/useDeviceOrientation'

export default defineComponent({
  components: {
    PositionHighlighter,
    PressIndicator,
    RotateScreen,
    Login,
    TextLoader,
  },
  setup() {
    const { isVisible: isLoginFormVisible } = useLoginFormVisibility()
    const isDatabaseConnected = toRef(app.state, 'isDatabaseConnected')
    const isClientReady = toRef(sportsAPI.state, 'isClientReady')
    const currentAlert = toRef(alert.state, 'currentAlert')
    const { isPortrait } = useDeviceOrientation()

    return {
      loaderText,
      loaderActive,
      currentAlert,
      isLoginFormVisible,
      isDatabaseConnected,
      isClientReady,
      isPortrait,
    }
  },
})
</script>

<template>
  <div
    v-if="loaderActive || currentAlert.component"
    class="z-100 fixed inset-0 grid h-full w-full place-items-center bg-black bg-opacity-90"
  >
    <TextLoader
      v-if="loaderActive"
      :text="loaderText"
    />
    <component
      :is="currentAlert.component"
      v-else-if="currentAlert.component"
      class="min-w-120 flex min-h-40 flex-col items-center justify-center"
      v-on="currentAlert.events"
    />
  </div>
  <PositionHighlighter />
  <PressIndicator />
  <RotateScreen v-if="!isLoginFormVisible && isPortrait" />
  <Login />
  <router-view v-if="!isPortrait && isDatabaseConnected && isClientReady" />
</template>

<style lang="scss">
@import '@mobile/styles/reset.css';
@import '@mobile/styles/variables.scss';
@import '@mobile/styles/tailwind.css';

@font-face {
  font-family: softnetSPORT-Icons;
  src: url('@mobile/assets/fonts/icons-sports.eot');
  src:
    url('@mobile/assets/fonts/icons-sports.eot') format('embedded-opentype'),
    url('@mobile/assets/fonts/icons-sports.ttf') format('truetype'),
    url('@mobile/assets/fonts/icons-sports.woff') format('woff'),
    url('@mobile/assets/fonts/icons-sports.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.noselect {
  -webkit-touch-callout: none; /* Safari Touch */
  -webkit-user-select: none; /* Webkit */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Edge*/
  user-select: none; /* Future-proof*/
}
</style>
