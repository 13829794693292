<script lang="ts">
import { defineComponent } from 'vue'

import { opChain } from '@collector/shared-utils'
import { BeachVolleyballIncidentId } from '@collector/sportsapi-client-legacy'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import { useBeachVolleyballConfiguration } from '@mobile/views/Relation/Sports/BeachVolleyball/BeachVolleyballRelationDependencies/useBeachVolleyballConfiguration'

export default defineComponent({
  components: { IncidentButton },

  setup() {
    const { activeParticipant, inactiveParticipant } =
      useBeachVolleyballConfiguration()

    return {
      BeachVolleyballIncidentId,
      activeParticipant,
      inactiveParticipant,
      opChain,
    }
  },
})
</script>

<template>
  <div class="flex flex-col space-y-4">
    <IncidentButton
      class="flex-1"
      :type="'horizontal'"
      :size="'xl'"
      :color="activeParticipant.color"
      :incidentId="BeachVolleyballIncidentId.ServiceAce"
      :participantId="opChain(activeParticipant.participant, (v) => v.id)"
    />
    <IncidentButton
      class="flex-1"
      :type="'horizontal'"
      :size="'xl'"
      :color="activeParticipant.color"
      :incidentId="BeachVolleyballIncidentId.AttackPoint"
      :participantId="opChain(activeParticipant.participant, (v) => v.id)"
    />
    <IncidentButton
      class="flex-1"
      :type="'horizontal'"
      :size="'xl'"
      :color="inactiveParticipant.color"
      :incidentId="BeachVolleyballIncidentId.BlockPoint"
      :participantId="opChain(inactiveParticipant.participant, (v) => v.id)"
    />
  </div>
</template>
