import { chain } from 'lodash'
import { Ref } from 'vue'

import {
  CricketIncidentId,
  CricketResultId,
} from '@collector/sportsapi-client-legacy'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import { byId } from '@mobile/reusables/entityUtils'

import { ActiveParticipant } from './CricketRelationDependencies/ActiveParticipant'

export function prepareAdditionalInfo(
  activeParticipant: Ref<ActiveParticipant>,
  probableIncidents: Ref<ProbableIncident[]>,
): object {
  const activeParticipantId = activeParticipant.value.participant?.id
  const currentOver =
    byId(
      activeParticipant.value.participant?.results ?? [],
      CricketResultId.Overs,
    )?.value ?? 0
  const currentBall =
    byId(
      activeParticipant.value.participant?.results ?? [],
      CricketResultId.CurrentBall,
    )?.value ?? 0

  const lastCurrentOverIncident = chain(probableIncidents.value)
    .filter(
      (probableIncident) =>
        probableIncident.participant_id === activeParticipantId &&
        probableIncident.additional_info?.current_over === currentOver,
    )
    .reverse()
    .last()
    .value()

  const lastCurrentOverIncidentWithCurrentAction = chain(
    probableIncidents.value,
  )
    .filter(
      (probableIncident) =>
        probableIncident.participant_id === activeParticipantId &&
        probableIncident.additional_info?.current_over === currentOver &&
        probableIncident.additional_info.current_action !== undefined,
    )
    .reverse()
    .last()
    .value()

  const lastAdditionalInfo =
    lastCurrentOverIncidentWithCurrentAction?.additional_info ?? {
      current_action: 0,
      current_ball: currentBall,
      current_over: currentOver,
    }

  let currentAction = Math.max(
    lastAdditionalInfo.current_action || 0,
    Number(lastAdditionalInfo.current_ball) || 0,
  )

  if (
    lastCurrentOverIncident?.incident_id === CricketIncidentId.BowlerRunningIn
  ) {
    currentAction += 1
  }

  return {
    ...lastAdditionalInfo,
    current_action: currentAction,
  }
}
