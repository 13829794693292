import {
  IncidentAttribute,
  Sport,
  SportIncident,
  SportStatus,
} from '@collector/sportsapi-client-legacy'

export class CollectorSport {
  public incidents: { [key: number]: SportIncident | undefined } = {}
  public statuses: { [key: number]: SportStatus | undefined } = {}
  public attributes: { [key: number]: IncidentAttribute | undefined } = {}

  public sportsAPIEntity: Sport

  public constructor(sport: Sport) {
    this.sportsAPIEntity = sport
    this.mapIncidents()
    this.mapAttributes()
    this.mapStatuses()
  }

  private mapAttributes(): void {
    this.sportsAPIEntity.incidents
      .flatMap((incident) => incident.attributes)
      .forEach((attribute) => {
        this.attributes[attribute.id] = attribute
      })
  }

  private mapIncidents(): void {
    this.sportsAPIEntity.incidents.forEach((incident) => {
      this.incidents[incident.id] = incident
    })
  }

  private mapStatuses(): void {
    this.sportsAPIEntity.statuses.forEach((status) => {
      this.statuses[status.id] = status
    })
  }

  public getAttributeTranslation(attributeId: number): string {
    return this.attributes[attributeId]?.name ?? 'Unknown attribute'
  }

  public getIncidentTranslation(incidentId: number): string {
    return this.incidents[incidentId]?.name || 'Unknown incident'
  }
}
