import { Container } from 'inversify'
import { chain, keyBy } from 'lodash'
import { computed } from 'vue'

import {
  CollectorPushMessageEvent,
  CollectorPushMessageIncident,
  Event,
  TeamSide,
} from '@collector/sportsapi-client-legacy'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import { CollectorSport } from '@mobile/reusables/classes/CollectorSport'
import { byId, isAwayTeam, isHomeTeam } from '@mobile/reusables/entityUtils'
import {
  ProbableLinkedIncident,
  ProbableLinkedParticipant,
} from '@mobile/views/Relation/Shared/RelationDependencies/types'

import { RelationIoCBindings } from './types'

type IncidentsMap = { [id: string]: CollectorPushMessageIncident | undefined }

export function bindProbableLinkedIncidents(ioc: Container): void {
  ioc
    .bind(RelationIoCBindings.ProbableLinkedIncidents)
    .toDynamicValue(async (ctx) => {
      const [
        factualEventRef,
        factualIncidentsRef,
        probableIncidentsRef,
        sportRef,
        probableLinkedHomeParticipant,
        probableLinkedAwayParticipant,
      ] = await Promise.all([
        ctx.container.getAsync(RelationIoCBindings.FactualEvent),
        ctx.container.getAsync(RelationIoCBindings.FactualIncidents),
        ctx.container.getAsync(RelationIoCBindings.ProbableIncidents),
        ctx.container.getAsync(RelationIoCBindings.Sport),
        ctx.container.getAsync(
          RelationIoCBindings.ProbableLinkedHomeParticipant,
        ),
        ctx.container.getAsync(
          RelationIoCBindings.ProbableLinkedAwayParticipant,
        ),
      ])

      return computed(() => {
        const factualEvent = factualEventRef.value
        const factualIncidents = factualIncidentsRef.value
        const probableIncidents = probableIncidentsRef.value
        const sport = sportRef.value

        const factualIncidentsMap: IncidentsMap = keyBy(
          factualIncidents,
          (incident) => incident.data.id,
        )

        return chain(probableIncidents)
          .map((probableIncident) =>
            createLinkedProbableIncident(
              probableIncident,
              probableLinkedHomeParticipant,
              probableLinkedAwayParticipant,
              factualIncidentsMap,
              factualEvent,
              sport,
            ),
          )
          .compact()
          .value()
      })
    })
    .inSingletonScope()
}

function createLinkedProbableIncident(
  probableIncident: ProbableIncident,
  probableLinkedHomeParticipant: ProbableLinkedParticipant,
  probableLinkedAwayParticipant: ProbableLinkedParticipant,
  incidentsMap: IncidentsMap,
  event: CollectorPushMessageEvent,
  sport: CollectorSport,
): ProbableLinkedIncident | undefined {
  const sportIncident = sport.incidents[probableIncident.incident_id]
  if (!sportIncident) {
    // @TODO add DEBUG notification
    /* eslint-disable-next-line no-console */
    console.warn(
      `Couldn't attach SportIncident to ProbableLinkedIncident (${probableIncident.incident_id}, ${probableIncident.incident_name})`,
    )

    return
  }

  const sportStatus = sport.statuses[event.data.status_id]
  if (!sportStatus) {
    // @TODO add DEBUG notification
    /* eslint-disable-next-line no-console */
    console.warn(
      `Couldn't attach SportStatus to ProbableLinkedIncident (${probableIncident.incident_id}, ${probableIncident.incident_name})`,
    )

    return
  }

  // If this is incident already hanlded by SportsAPI we should find it in incidents array to attach some more info
  const { uuid, pushMessageUt } = incidentsMap[probableIncident.id] ?? {}

  const side = getIncidentTeamSide(probableIncident, event.data)

  let probableLinkedParticipant: ProbableLinkedParticipant | undefined
  if (side) {
    probableLinkedParticipant =
      side === TeamSide.Home
        ? probableLinkedHomeParticipant
        : probableLinkedAwayParticipant
  }

  return {
    incident: probableIncident,
    participant: event.data.participants.find(
      (participant) => participant.id === probableIncident.participant_id,
    ),
    probableLinkedParticipant,
    sportIncident,
    sportStatus,
    side,
    eventId: event.data.id,
    uuid,
    pushMessageUt,
  }
}

function getIncidentTeamSide(
  incident: ProbableIncident,
  event: Event,
): TeamSide | null {
  let side: TeamSide | null = null

  const participant = byId(event.participants, incident.participant_id)
  if (participant) {
    if (isHomeTeam(participant)) {
      side = TeamSide.Home
    } else if (isAwayTeam(participant)) {
      side = TeamSide.Away
    }
  }

  return side
}
