<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import { UpdateLineups } from '@mobile/ActionQueue/Actions/Actions'

import QueueAction from './QueueAction.vue'

export default defineComponent({
  components: { QueueAction },
  props: {
    action: {
      type: Object as PropType<UpdateLineups>,
      required: true,
    },
  },
  setup(props) {
    const info = computed(() =>
      JSON.stringify(props.action.updateLineupsDTO.lineups, undefined, 2),
    )

    return { info }
  },
})
</script>

<template>
  <QueueAction
    :action
    :participantId="action.updateLineupsDTO.participantId"
    :actionInformation="'Update lineups'"
  >
    <template #infoPanel>
      {{ info }}
    </template>
  </QueueAction>
</template>
