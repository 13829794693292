<script lang="ts">
import { defineComponent, PropType } from 'vue'

import {
  Confirmation,
  CricketIncidentId,
  LegalBallAttributesId,
} from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import * as popup from '@mobile/globalState/popup'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'
import { useIncidentAction } from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

import { useCricketConfiguration } from '../CricketRelationDependencies/useCricketConfiguration'
import { BallSummaryPopup } from '../Popups/BallSummary/BallSummaryPopup'
import { prepareAdditionalInfo } from '../prepareAdditionalInfo'

export default defineComponent({
  components: { Button },
  props: {
    context: {
      type: Object as PropType<PopupContext | null>,
      required: false,
    },
  },
  setup(props) {
    const eventInjections = useEventInjections()
    const { activeParticipant } = useCricketConfiguration()

    function handleClick(): void {
      const attributeIds = [
        LegalBallAttributesId.Runs,
        LegalBallAttributesId.Zero,
      ]

      const incidentActionComposable = useIncidentAction(eventInjections, {
        incident_id: CricketIncidentId.LegalBall,
        confirmation: Confirmation.ToBeDone,
        attribute_ids: attributeIds,
        additional_info: prepareAdditionalInfo(
          activeParticipant,
          eventInjections.probableIncidents,
        ),
        participant_team_id: activeParticipant.value.participant?.id,
      })

      if (props.context) {
        props.context?.openPopup(BallSummaryPopup, { incidentActionComposable })
      } else {
        popup.show({
          component: BallSummaryPopup,
          showHeader: true,
          props: { incidentActionComposable },
        })
      }
    }

    return { handleClick }
  },
})
</script>

<template>
  <Button
    :size="'sm'"
    :color="'white'"
    :withIcon="false"
    :type="'horizontal'"
    @click="handleClick"
  >
    No runs
  </Button>
</template>
