import { Action } from '@mobile/ActionQueue/Action'
import { ActionQueue } from '@mobile/ActionQueue/ActionQueue'
import { EventType } from '@mobile/ActionQueue/EventType'
import { AppNotificationType } from '@mobile/reusables/types/AppNotificationType'
import { Notifications } from '@mobile/views/Relation/Shared/RelationDependencies/types'

import { Hook } from './Hook'

export class HookQueueNotifications implements Hook {
  private onQueueError = function (
    this: HookQueueNotifications,
    action: Action<unknown>,
  ) {
    this.notifications.sendNotification({
      type: AppNotificationType.Error,
      title: 'Queue action errored',
      content:
        'Queue has errored, to continue enter the queue and resolve error manually.',
      details: `${action.error ?? 'no error provided'}. Caused by ${action.id}`,
    })
  }.bind(this)

  private onQueueStarted = function (this: HookQueueNotifications) {
    this.notifications.sendNotification({
      type: AppNotificationType.Debug,
      title: 'ActionQueue started',
      content: 'ActionQueue started',
    })
  }.bind(this)

  private onPausingQueue = function (this: HookQueueNotifications) {
    this.notifications.sendNotification({
      type: AppNotificationType.Debug,
      title: 'Pausing ActionQueue',
      content: 'Pausing ActionQueue',
    })
  }.bind(this)

  private onQueuePaused = function (this: HookQueueNotifications) {
    this.notifications.sendNotification({
      type: AppNotificationType.Debug,
      title: 'ActionQueue Paused',
      content: 'ActionQueue Paused',
    })
  }.bind(this)

  private onResendingAction = function (
    this: HookQueueNotifications,
    action: Action<unknown>,
  ) {
    this.notifications.sendNotification({
      type: AppNotificationType.Debug,
      title: 'Resending action',
      content: `Resending action with status: ${action.status}`,
    })
  }.bind(this)

  constructor(
    private readonly notifications: Notifications,
    private readonly actionQueue: ActionQueue,
  ) {}

  install(): void {
    this.actionQueue.on(EventType.ActionErrored, this.onQueueError)
    this.actionQueue.on(EventType.QueueStarted, this.onQueueStarted)
    this.actionQueue.on(EventType.PausingQueue, this.onPausingQueue)
    this.actionQueue.on(EventType.QueuePaused, this.onQueuePaused)
    this.actionQueue.on(EventType.ResendingAction, this.onResendingAction)
  }

  uninstall(): void {
    this.actionQueue.off(EventType.ActionErrored, this.onQueueError)
    this.actionQueue.off(EventType.QueueStarted, this.onQueueStarted)
    this.actionQueue.off(EventType.PausingQueue, this.onPausingQueue)
    this.actionQueue.off(EventType.QueuePaused, this.onQueuePaused)
    this.actionQueue.off(EventType.ResendingAction, this.onResendingAction)
  }
}
