export const enum BeachVolleyballEventStatusId {
  NotStarted = 1,
  Interrupted = 2,
  Cancelled = 3,
  Postponed = 5,
  Abandoned = 7,
  Finished = 11,
  FinishedAwardedWin = 12,
  FirstSet = 134,
  SecondSet = 65,
  ThirdSet = 135,
  ToFinish = 152,
  GoldenSet = 154,
  BreakAfterFirstSet = 155,
  BreakAfterSecondSet = 156,
  BreakAfterThirdSet = 157,
}
