import { Container } from 'inversify'
import { ref } from 'vue'

import { CollectorPushMessageEventsScouts } from '@collector/sportsapi-client-legacy'
import * as app from '@mobile/globalState/app'

import { RelationIoCBindings } from './types'

export async function bindEventScouts(ioc: Container): Promise<void> {
  let unbindDatabaseWatchers: () => void

  ioc
    .bind(RelationIoCBindings.EventsScouts)
    .toDynamicValue(async (ctx) => {
      const event = await ctx.container.getAsync(
        RelationIoCBindings.FactualEvent,
      )
      const scouts = await app.state.database.eventsScouts.getByEventId(
        event.value.eventId,
      )

      return ref(scouts?.data.currentScouts ?? [])
    })
    .inSingletonScope()
    .onActivation(async (ctx, eventsScouts) => {
      const onCreateOrUpdate = async ({
        eventId,
        data: { currentScouts },
      }: CollectorPushMessageEventsScouts): Promise<void> => {
        const factualEventId = (
          await ctx.container.getAsync(RelationIoCBindings.FactualEvent)
        ).value.eventId
        if (factualEventId === eventId) {
          eventsScouts.value = currentScouts
        }
      }

      app.state.database.eventsScouts.on('created', onCreateOrUpdate)
      app.state.database.eventsScouts.on('updated', onCreateOrUpdate)

      unbindDatabaseWatchers = () => {
        app.state.database.eventsScouts.off('created', onCreateOrUpdate)
        app.state.database.eventsScouts.off('updated', onCreateOrUpdate)
      }

      return eventsScouts
    })
    .when(() => true)
    .onDeactivation(async () => {
      unbindDatabaseWatchers()
    })
    .when(() => true)
}
