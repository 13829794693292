import { nextTick, Ref } from 'vue'

import { TeamSide } from '@collector/sportsapi-client-legacy'
import { eventBus, Events } from '@mobile/globalState/eventBus'
import { SwipeEvent } from '@mobile/reusables/useGestureDirective'

import { PossessionSide } from '../PossessionSide'

export function watchOnLiveViewSwipe(
  mutableCurrentPossessionSide: Ref<TeamSide>,
  possessionSide: PossessionSide,
): () => void {
  let swipeBlocked = false
  function handleLiveViewSwipe({ event }: Events['SoccerLiveViewSwipe']): void {
    const shouldSwitchPossession = [
      SwipeEvent.SwipeLeft,
      SwipeEvent.SwipeRight,
    ].includes(event)
    if (!shouldSwitchPossession || swipeBlocked) {
      return
    }

    swipeBlocked = true
    mutableCurrentPossessionSide.value = possessionSide.opposite.value
    nextTick(() => {
      swipeBlocked = false
    })
  }

  function stopWatcher(): void {
    eventBus.off('SoccerLiveViewSwipe', handleLiveViewSwipe)
  }

  eventBus.on('SoccerLiveViewSwipe', handleLiveViewSwipe)

  return stopWatcher
}
