import { Container } from 'inversify'

import {
  Basketball3x3EventStatusId,
  Basketball3x3IncidentId,
  basketball3x3StatusResultsMap,
} from '@collector/sportsapi-client-legacy'
import {
  addToEventResult,
  changeEventStatus,
} from '@mobile/ActionQueue/Actions/Event/EventAction'
import { ClockStatusAction } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/IncidentsConfiguration'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindIncidentsConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.IncidentsConfiguration)
    .toDynamicValue(() => {
      return {
        [Basketball3x3IncidentId.NotStarted]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              Basketball3x3EventStatusId.NotStarted,
            )
          },
        },
        [Basketball3x3IncidentId.StartDelayed]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              Basketball3x3EventStatusId.StartDelayed,
            )
          },
        },
        [Basketball3x3IncidentId.FinishedRegularTime]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              Basketball3x3EventStatusId.FinishedRegularTime,
            )
          },
        },
        [Basketball3x3IncidentId.WaitingForOvertime]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              Basketball3x3EventStatusId.WaitingForOvertime,
            )
          },
        },
        [Basketball3x3IncidentId.FinishedAfterOvertime]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              Basketball3x3EventStatusId.FinishedAfterOvertime,
            )
          },
        },
        [Basketball3x3IncidentId.FinishedAwardedWin]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              Basketball3x3EventStatusId.FinishedAwardedWin,
            )
          },
        },
        [Basketball3x3IncidentId.Interrupted]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              Basketball3x3EventStatusId.Interrupted,
            )
          },
        },
        [Basketball3x3IncidentId.Cancelled]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              Basketball3x3EventStatusId.Cancelled,
            )
          },
        },
        [Basketball3x3IncidentId.Abandoned]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              Basketball3x3EventStatusId.Abandoned,
            )
          },
        },
        [Basketball3x3IncidentId.ToFinish]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              Basketball3x3EventStatusId.ToFinish,
            )
          },
        },
        [Basketball3x3IncidentId.Postponed]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              Basketball3x3EventStatusId.Postponed,
            )
          },
        },
        [Basketball3x3IncidentId.OnePointShotMade]: {
          clockStatusAction: ClockStatusAction.None,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            const resultsToUpdate =
              basketball3x3StatusResultsMap[probableEvent.status_id] ?? []

            resultsToUpdate.forEach((result) => {
              addToEventResult(
                probableEvent,
                dto.participant_team_id,
                result,
                1,
              )
            })
          },
          applyDeleteIncidentResultToEvent(
            probableEvent,
            sport,
            probableIncident,
          ) {
            const resultsToUpdate =
              basketball3x3StatusResultsMap[probableEvent.status_id] ?? []

            resultsToUpdate.forEach((result) => {
              addToEventResult(
                probableEvent,
                probableIncident.participant_id,
                result,
                -1,
              )
            })
          },
        },
        [Basketball3x3IncidentId.TwoPointShotMade]: {
          clockStatusAction: ClockStatusAction.None,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            const resultsToUpdate =
              basketball3x3StatusResultsMap[probableEvent.status_id] ?? []

            resultsToUpdate.forEach((result) => {
              addToEventResult(
                probableEvent,
                dto.participant_team_id,
                result,
                2,
              )
            })
          },
          applyDeleteIncidentResultToEvent(
            probableEvent,
            sport,
            probableIncident,
          ) {
            const resultsToUpdate =
              basketball3x3StatusResultsMap[probableEvent.status_id] ?? []

            resultsToUpdate.forEach((result) => {
              addToEventResult(
                probableEvent,
                probableIncident.participant_id,
                result,
                -2,
              )
            })
          },
        },
        [Basketball3x3IncidentId.ThrowIn]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
        },
        [Basketball3x3IncidentId.Timeout]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
        },
        [Basketball3x3IncidentId.TVTimeout]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
        },
        [Basketball3x3IncidentId.PlayUnderReview]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
        },
        [Basketball3x3IncidentId.BreakDuringTheGame]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
        },
        [Basketball3x3IncidentId.Injury]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
        },
        [Basketball3x3IncidentId.InPossession]: {
          clockStatusAction: ClockStatusAction.Start,
          resetClockTime: false,
        },
        fallbackIncidentConfiguration: {
          clockStatusAction: ClockStatusAction.None,
          resetClockTime: false,
        },
      }
    })
    .inSingletonScope()
}
