<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  setup() {
    const isVisible = ref(true)

    const onClick = (): void => {
      isVisible.value = !isVisible.value
    }

    return {
      isVisible,
      onClick,
    }
  },
})
</script>

<template>
  <div>
    <div
      class="from-darkblue to-blue flex h-9 cursor-pointer items-center justify-between bg-gradient-to-r"
      @click="onClick"
    >
      <div class="pl-4 text-sm font-bold text-white">
        {{ name }}
      </div>
      <div class="pr-4 text-sm text-white">
        <FontAwesomeIcon
          v-if="isVisible"
          icon="chevron-up"
        />
        <FontAwesomeIcon
          v-if="!isVisible"
          icon="chevron-down"
        />
      </div>
    </div>
    <div
      v-show="isVisible"
      class="bg-silver p-4"
    >
      <slot></slot>
    </div>
  </div>
</template>
