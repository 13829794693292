type ManyRequired<T, K extends keyof T> = T & {
  [U in K]-?: T[U]
}

export class DTO<T extends {}> {
  constructor(public readonly inner: T) {}

  public has<K extends keyof T>(...keys: K[]): this is DTO<ManyRequired<T, K>> {
    for (const key of keys) {
      if (this.inner[key] === undefined) {
        return false
      }
    }

    return true
  }

  public isTruthy<K extends keyof T>(
    ...keys: K[]
  ): this is DTO<{ [key in K]: number | string | true | object }> {
    for (const key of keys) {
      if (!this.inner[key]) {
        return false
      }
    }

    return true
  }
}
