import UpdateEventTime from '@mobile/ActionQueue/Actions/Event/UpdateEventTime'

import { ProbableTimeAction } from './ProbableTimeAction'

export class ProbableTimeUpdateEventTimeAction extends ProbableTimeAction {
  constructor(private readonly action: UpdateEventTime) {
    super()
  }

  public isTimeSetter(): boolean {
    return typeof this.action.updateEventDTO.event.clock_time === 'number'
  }

  public getTimeToSet(): number | null {
    return typeof this.action.updateEventDTO.event.clock_time === 'number'
      ? this.action.updateEventDTO.event.clock_time * 1000
      : null
  }

  public isTimeStarter(): boolean {
    return this.action.updateEventDTO.event.clock_status === true
  }

  public isTimeStopper(): boolean {
    return this.action.updateEventDTO.event.clock_status === false
  }

  public getActionTime(): number {
    return this.action.actionTime
  }
}
