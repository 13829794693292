import router from '@mobile/router'
import { SoccerRelationIoCBindings } from '@mobile/views/Relation/Sports/Soccer/SoccerRelationDependencies/SoccerRelationDependencies'

import { PossessionSide } from './PossessionSide/PossessionSide'

export function useSoccerConfiguration(): { possession: PossessionSide } {
  const ioc = router.currentRoute.value.meta.ioc
  if (!ioc) {
    throw Error(
      'IoC Container has to be instantiated and injected into route meta before using soccer configuration',
    )
  }

  const possession = ioc.get(SoccerRelationIoCBindings.PossessionSide)

  return { possession }
}
