import { ComponentPublicInstance } from 'vue'

import { EventBus } from '@mobile/globalState/eventBus'
import { stringifyError } from '@mobile/reusables/stringifyError'
import { AppNotificationType } from '@mobile/reusables/types/AppNotificationType'
import { Notifications } from '@mobile/views/Relation/Shared/RelationDependencies/types'

import { Hook } from './Hook'

export class HookErrors implements Hook {
  private onVueError = function (
    this: HookErrors,
    errorEvent: {
      error: unknown
      instance: ComponentPublicInstance | null
      info: string
    },
  ) {
    this.notifications.sendNotification({
      type: AppNotificationType.Debug,
      title: 'Vue has errored',
      content: 'Check details for more info',
      details: {
        error: stringifyError(errorEvent.error),
        component: errorEvent.instance?.$options.name,
        info: errorEvent.info,
      },
    })
  }.bind(this)

  private onWindowError = function (this: HookErrors, errorEvent: ErrorEvent) {
    this.notifications.sendNotification({
      type: AppNotificationType.Debug,
      title: 'Application has errored',
      content: 'Check details for more info',
      details: stringifyError(errorEvent.error),
    })
  }.bind(this)

  constructor(
    private readonly notifications: Notifications,
    private readonly eventBus: EventBus,
  ) {}

  install(): void {
    this.eventBus.on('VueError', this.onVueError)
    window.addEventListener('error', this.onWindowError)
  }

  uninstall(): void {
    this.eventBus.off('VueError', this.onVueError)
    window.removeEventListener('error', this.onWindowError)
  }
}
