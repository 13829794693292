import Sentry from '@collector/shared-plugin-sentry-vue'
import * as app from '@mobile/globalState/app'
import * as sportsAPI from '@mobile/globalState/sportsAPI'
import router from '@mobile/router'

export function useLogout(): { logout: () => void } {
  function logout(): void {
    sportsAPI.logout()
    Sentry.setUser(null)
    app.addOverlay()
    router.push({ name: 'EventsList' })
  }

  return { logout }
}
