import { Container } from 'inversify'

import { HandballIncidentId } from '@collector/sportsapi-client-legacy'
import { eventBus } from '@mobile/globalState/eventBus'
import { HookErrors } from '@mobile/views/Relation/Shared/Hooks/HookErrors'
import { HookForceUpdate } from '@mobile/views/Relation/Shared/Hooks/HookForceUpdate'
import { HookQueueNotifications } from '@mobile/views/Relation/Shared/Hooks/HookQueueNotifications'
import { HookScoutMessengerConnectionStatus } from '@mobile/views/Relation/Shared/Hooks/HookScoutMessengerConnectionStatus'
import { HookSidesCheck } from '@mobile/views/Relation/Shared/Hooks/HookSidesCheck'
import { HookSystemTimeValidation } from '@mobile/views/Relation/Shared/Hooks/HookSystemTimeValidation'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

const notifyOnIncidentIds = [
  HandballIncidentId.MatchAboutToStart,
  HandballIncidentId.SecondHalfStarted,
  HandballIncidentId.FirstExtratimeFirstHalfStarted,
  HandballIncidentId.FirstExtratimeSecondHalfStarted,
  HandballIncidentId.SecondExtratimeFirstHalfStarted,
  HandballIncidentId.SecondExtratimeSecondHalfStarted,
]

export function bindRelationHooks(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.EventNotificationHooks)
    .toDynamicValue(async (ctx) => {
      const [
        notifications,
        actionQueue,
        notDeletableProbableIncidents,
        incidentsConfiguration,
      ] = await Promise.all([
        ctx.container.getAsync(RelationIoCBindings.Notifications),
        ctx.container.getAsync(RelationIoCBindings.ActionQueue),
        ctx.container.getAsync(
          RelationIoCBindings.ProbableNotDeletableIncidents,
        ),
        ctx.container.getAsync(
          RelationSportCommonIoCBindings.IncidentsConfiguration,
        ),
      ])

      const hooks = [
        new HookErrors(notifications, eventBus),
        new HookSystemTimeValidation(notifications),
        new HookScoutMessengerConnectionStatus(notifications),
        new HookQueueNotifications(notifications, actionQueue.value),
        new HookSidesCheck(
          notifications,
          actionQueue.value,
          notDeletableProbableIncidents,
          notifyOnIncidentIds,
          incidentsConfiguration,
        ),
        new HookForceUpdate(notifications),
      ]

      return hooks
    })
    .inSingletonScope()
}
