<script lang="ts">
import { defineComponent } from 'vue'

import { Participant } from '@collector/sportsapi-client-legacy'
import ParticipantComponent from '@mobile/components/Participant/Participant.vue'
import MainResult from '@mobile/views/Relation/Shared/LiveView/LiveViewHeader/MainResult.vue'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

import { useBeachVolleyballConfiguration } from '../../BeachVolleyballRelationDependencies/useBeachVolleyballConfiguration'
import SetResult from './LiveViewHeaderStats/SetResult.vue'

export default defineComponent({
  components: {
    ParticipantComponent,
    MainResult,
    SetResult,
  },
  setup() {
    const {
      probableFirstParticipant,
      probableLinkedFirstParticipant,
      probableSecondParticipant,
      probableLinkedSecondParticipant,
    } = useEventInjections()
    const { activeParticipant } = useBeachVolleyballConfiguration()

    function isParticipantActive(
      participant: ProbableLinkedParticipant,
    ): boolean {
      const { id: activeParticipantId } = activeParticipant.value
        .participant as Participant
      const { id: participantId } = participant.getParticipant().value

      return activeParticipantId === participantId
    }

    return {
      isParticipantActive,
      probableFirstParticipant,
      probableLinkedFirstParticipant,
      probableSecondParticipant,
      probableLinkedSecondParticipant,
    }
  },
})
</script>

<template>
  <div class="flex items-center space-x-2 px-2 py-1">
    <div
      class="flex h-8 w-32 items-center justify-start md:w-36 lg:w-48 xl:w-56"
    >
      <ParticipantComponent :participant="probableFirstParticipant" />
    </div>
    <div class="flex h-8 flex-1 flex-row items-center justify-between">
      <div class="flex items-center space-x-2">
        <SetResult
          :probableLinkedParticipant="probableLinkedFirstParticipant"
        />
      </div>

      <div class="flex items-center space-x-2">
        <MainResult
          :probableLinkedParticipant="probableLinkedFirstParticipant"
        />
        <MainResult
          :probableLinkedParticipant="probableLinkedSecondParticipant"
        />
      </div>

      <div class="flex items-center space-x-2">
        <SetResult
          :probableLinkedParticipant="probableLinkedSecondParticipant"
        />
      </div>
    </div>

    <div
      class="flex h-8 w-32 flex-row items-center justify-end md:w-36 lg:w-48 xl:w-56"
    >
      <ParticipantComponent
        :participant="probableSecondParticipant"
        :reverse="true"
      />
    </div>
  </div>
</template>
