import { useNavigationSeparator } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useNavigationSeparator'
import { usePopupButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/usePopupButton'
import { PopupBuilder } from '@mobile/views/Relation/Shared/Popup/builder/PopupBuilder'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'

import FreeHitPopupDetails from './FreeHitPopupDetails.vue'

export const FreeHitPopup = new PopupBuilder('Is it Free Hit?')
  .appendBody(FreeHitPopupDetails)
  .setNavigationButtons([
    ...useNavigationSeparator(5),
    usePopupButton({
      props: {
        color: 'red',
        text: 'Close',
        onClick: (context: PopupContext | null) => {
          context?.closeAllPopups()
        },
      },
    }),
  ])
  .build()
