<script lang="ts">
import { defineComponent } from 'vue'

import * as popup from '@mobile/globalState/popup'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import PopupTitle from '@mobile/views/Relation/Shared/Popup/PopupTitle.vue'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

export default defineComponent({
  components: {
    IncidentButton,
    PopupTitle,
  },
  setup() {
    const { eventStatusesList } = useSportConfiguration()

    function closePopup(): void {
      popup.hide()
    }

    return {
      eventStatusesList,
      closePopup,
    }
  },
})
</script>

<template>
  <div class="bg-silver">
    <PopupTitle>
      <template #title>Event Status</template>
    </PopupTitle>

    <div class="grid grid-cols-3 gap-2 px-2 pb-2">
      <IncidentButton
        v-for="incidentId in eventStatusesList"
        :key="incidentId"
        :type="'horizontal'"
        :incidentId
        :size="'xl'"
        :color="'white'"
        @incidentHandled="closePopup"
      />
    </div>
  </div>
</template>
