import { toRef } from 'vue'

import {
  EventsLineupsParticipant,
  SoccerEventDetailId,
  SubParticipant,
  YesNoEnum,
} from '@collector/sportsapi-client-legacy'
import { UpdateLineups } from '@mobile/ActionQueue/Actions/Actions'
import { UpdateSubParticipantDTO } from '@mobile/ActionQueue/Actions/Lineups/UpdateLineups'
import * as app from '@mobile/globalState/app'
import * as sportsAPI from '@mobile/globalState/sportsAPI'
import { byId } from '@mobile/reusables/entityUtils'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'

import { EventInjections } from './Shared/RelationDependencies/useEventInjections'

export function transformSubParticipantIntoLineupParticipant(
  subParticipant: SubParticipant,
): EventsLineupsParticipant {
  return {
    participant_id: subParticipant.id,
    participant_is_captain: !!(
      byId(subParticipant.stats || [], 701)?.value === 1
    ),
    participant_short_name: subParticipant.short_name,
    participant_name: subParticipant.short_name,
    participant_position: subParticipant.position,
    participant_shirt_number: subParticipant.shirt_nr,
    participant_team_id: Number(subParticipant.team_id),
  }
}

export function transformUpdateLineupParticipantToLineupParticipant(
  updateLineupParticipant: UpdateSubParticipantDTO,
  teamId: number,
): EventsLineupsParticipant {
  return {
    participant_id: updateLineupParticipant.id,
    participant_is_captain: updateLineupParticipant.captain || null,
    participant_name: updateLineupParticipant.name,
    participant_short_name:
      updateLineupParticipant.short_name ?? updateLineupParticipant.name,
    participant_position: updateLineupParticipant.position,
    participant_shirt_number: updateLineupParticipant.shirt_nr,
    participant_team_id: teamId,
  }
}

// TODO migrate all methods here from Lineups.vue including transformations
export function useEventLineups(
  eventInjections: EventInjections,
  probableLinkedParticipant: ProbableLinkedParticipant,
): {
  reloadEventLineups: () => Promise<void>
  saveLineups: (
    updateLineupParticipants: UpdateSubParticipantDTO[],
  ) => Promise<void>
} {
  const { probableEvent, actionQueue } = eventInjections
  const { database } = app.state
  const apiClient = toRef(sportsAPI.state, 'client')
  const { id: eventId } = probableEvent.value
  const participant = probableLinkedParticipant.getParticipant()

  async function reloadEventLineups(): Promise<void> {
    const response = await apiClient.value.getEventLineups(eventId)
    const { sub_participants } = response.api.data
    const lineups = sub_participants.map((subParticipant) => {
      return transformSubParticipantIntoLineupParticipant(subParticipant)
    })

    return database.lineups.save(eventId, participant.value.id, lineups)
  }

  async function saveLineups(
    updateLineupParticipants: UpdateSubParticipantDTO[],
  ): Promise<void> {
    const hasLineupsDetail = byId(
      probableEvent.value.details,
      SoccerEventDetailId.HasLineups,
    )
    const skipWaitingForLineupsMessage =
      hasLineupsDetail?.value !== YesNoEnum.Yes
    const participantId = participant.value.id
    const participantSide = probableLinkedParticipant.side
    const lineups = updateLineupParticipants.map((updateLineupParticipant) =>
      transformUpdateLineupParticipantToLineupParticipant(
        updateLineupParticipant,
        participant.value.id,
      ),
    )

    actionQueue.value.add(
      new UpdateLineups(
        {
          eventId,
          participantId,
          participantSide,
          lineups: updateLineupParticipants,
        },
        skipWaitingForLineupsMessage,
      ),
    )

    return database.lineups.save(eventId, participant.value.id, lineups)
  }

  return {
    reloadEventLineups,
    saveLineups,
  }
}
