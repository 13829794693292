import {
  Confirmation,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'
import { useCancellationButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useCancellationButton'
import { useCloseButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useCloseButton'
import { useConfirmationButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useConfirmationButton'
import { useNavigationSeparator } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useNavigationSeparator'
import { PopupBuilder } from '@mobile/views/Relation/Shared/Popup/builder/PopupBuilder'
import SelectedParticipant from '@mobile/views/Relation/Shared/Popup/builder/SelectedParticipant.vue'

import CornerTypeSelection from './CornerTypeSelection.vue'

export const CornerPopup = new PopupBuilder('Corner', {
  incident_id: SoccerIncidentId.Corner,
  confirmation: Confirmation.ToBeDone,
})
  .withTeamSelection()
  .setNavigationButtons([
    ...useNavigationSeparator(3),
    useCloseButton(),
    useCancellationButton(),
    useConfirmationButton(),
  ])
  .appendBody(SelectedParticipant)
  .appendBody(CornerTypeSelection)
  .build()
