import { CommonIncidentId, IncidentId } from './IncidentId'

export const CommonIncidentAcronymMap: {
  [key in CommonIncidentId]: string
} = {
  [CommonIncidentId.Betstop]: 'STOP',
  [CommonIncidentId.Betstart]: 'START',
  [CommonIncidentId.TechnicalProblems]: 'TECH',
  [CommonIncidentId.MatchResumed]: 'MRE',
}

export type IncidentAcronym = Record<IncidentId, string>
