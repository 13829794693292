<script lang="ts">
import { computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:value'],
  setup(props, context) {
    const toggle = (): void => context.emit('update:value', !props.value)

    const switcherClasses = computed(() => ({
      'toggleSwitch__switcher--checked': props.value,
    }))

    return {
      switcherClasses,
      toggle,
    }
  },
})
</script>

<template>
  <div
    class="cursor-pointer disabled:pointer-events-none disabled:opacity-50"
    :disabled
    @click="toggle()"
  >
    <div class="flex flex-row items-center">
      <div class="mr-2 select-none text-xs font-bold">
        <slot></slot>
      </div>
      <div
        class="flex h-6 w-12 items-center justify-start rounded-3xl px-0.5 transition-colors"
        :class="{ 'bg-green': value, 'bg-darkblue': !value }"
        data-test-id="ToggleSwitch"
      >
        <div
          class="flex h-5 w-5 transform select-none items-center justify-center rounded-full bg-white transition"
          :class="{ 'text-green translate-x-6': value, 'text-white': !value }"
        >
          <span v-show="value">✔</span>
        </div>
      </div>
    </div>
  </div>
</template>
