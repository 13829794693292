import { Container } from 'inversify'

import { bindActionQueue } from '@mobile/views/Relation/Shared/RelationDependencies/bindActionQueue'
import { bindClock } from '@mobile/views/Relation/Shared/RelationDependencies/bindClock'
import { bindEvent } from '@mobile/views/Relation/Shared/RelationDependencies/bindEvent'
import { bindIncidents } from '@mobile/views/Relation/Shared/RelationDependencies/bindIncidents'
import { bindLineups } from '@mobile/views/Relation/Shared/RelationDependencies/bindLineups'
import { bindNotifications } from '@mobile/views/Relation/Shared/RelationDependencies/bindNotifications'
import { bindParticipants } from '@mobile/views/Relation/Shared/RelationDependencies/bindParticipants'
import { bindProbableClock } from '@mobile/views/Relation/Shared/RelationDependencies/bindProbableClock'
import { bindProbableLinkedEvent } from '@mobile/views/Relation/Shared/RelationDependencies/bindProbableLinkedEvent'
import { bindProbableLinkedIncidents } from '@mobile/views/Relation/Shared/RelationDependencies/bindProbableLinkedIncidents'
import { bindProbableLinkedParticipants } from '@mobile/views/Relation/Shared/RelationDependencies/bindProbableLinkedParticipants'
import { bindProbables } from '@mobile/views/Relation/Shared/RelationDependencies/bindProbables'
import { bindSidesSwitched } from '@mobile/views/Relation/Shared/RelationDependencies/bindSidesSwitched'
import { bindSport } from '@mobile/views/Relation/Shared/RelationDependencies/bindSport'

import { bindEventScouts } from './bindEventScouts'

export function bindRelationDependencies(
  ioc: Container,
  eventId: number,
): void {
  bindEvent(ioc, eventId)
  bindParticipants(ioc)
  bindSport(ioc)
  bindIncidents(ioc)
  bindActionQueue(ioc)
  bindClock(ioc)
  bindLineups(ioc)
  bindProbables(ioc)
  bindProbableClock(ioc)
  bindProbableLinkedIncidents(ioc)
  bindProbableLinkedEvent(ioc)
  bindProbableLinkedParticipants(ioc)
  bindSidesSwitched(ioc)
  bindNotifications(ioc)
  bindEventScouts(ioc)
}
