<script lang="ts">
import { defineComponent, onUnmounted } from 'vue'

import { opChain } from '@collector/shared-utils'
import { HandballIncidentId } from '@collector/sportsapi-client-legacy'
import PossessionButton from '@mobile/components/PossesionButton/PossessionButton.vue'
import { useEventStatus } from '@mobile/views/Relation/Shared/EventStatus/useEventStatus'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'
import { usePitchOverlays } from '@mobile/views/Relation/Sports/Handball/LiveView/Pitch/usePitchOverlays'

import { useHandballConfiguration } from '../../HandballRelationDependencies/useHandballConfiguration'
import { useTimeoutOver } from './useTimeoutOver'

export default defineComponent({
  components: { PossessionButton },
  emits: ['sidesSwitched'],
  setup() {
    const {
      probableFirstParticipant,
      probableSecondParticipant,
      sidesSwitched,
      probableEvent,
      actionQueue,
      probableIncidents,
      notDeletableProbableIncidents,
      probableClock,
    } = useEventInjections()
    const { incidentsConfiguration } = useSportConfiguration()
    const { overlayComponents, visibleOverlay } = usePitchOverlays()
    const { isMatchStarted } = useEventStatus(probableEvent)
    const { activeParticipant } = useHandballConfiguration()

    const { cleanup: cleanupUseTimeoutOver } = useTimeoutOver(
      actionQueue.value,
      probableIncidents,
      notDeletableProbableIncidents,
      probableClock,
      incidentsConfiguration,
    )

    onUnmounted(() => {
      cleanupUseTimeoutOver()
    })

    return {
      probableFirstParticipant,
      probableSecondParticipant,
      overlayComponents,
      visibleOverlay,
      sidesSwitched,
      isMatchStarted,
      HandballIncidentId,
      activeParticipant,
      opChain,
    }
  },
})
</script>

<template>
  <div
    class="relative flex h-full justify-center"
    :class="
      !isMatchStarted
        ? 'col-span-full row-span-full'
        : 'col-start-2 col-end-6 row-start-2 row-end-5'
    "
  >
    <div class="pitch-bottom bg-handball-bottom w-full bg-no-repeat">
      <img
        ref="pitchTopRef"
        class="pointer-events-none h-full sm:w-full"
        :class="!isMatchStarted && 'h-full'"
        src="@mobile/assets/pitches/handball/top.svg"
      />
    </div>
    <div
      v-if="!visibleOverlay"
      class="absolute top-1/2 flex h-full max-h-full w-full -translate-y-1/2 transform flex-row items-center justify-center px-2"
    >
      <PossessionButton
        :participant="probableFirstParticipant"
        :activeParticipant
        :inPossessionIncidentId="HandballIncidentId.InPossession"
      />
      <PossessionButton
        :participant="probableSecondParticipant"
        :activeParticipant
        :inPossessionIncidentId="HandballIncidentId.InPossession"
      />
    </div>
    <div
      v-for="(overlayComponent, index) in overlayComponents"
      v-show="
        overlayComponent.props.incidentId ===
        opChain(visibleOverlay, (v) => v.props.incidentId)
      "
      :key="index"
    >
      <component
        :is="overlayComponent.component"
        v-bind="overlayComponent.props"
        @sidesSwitched="$emit('sidesSwitched')"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pitch-bottom {
  background-size: 100% 100%;

  @media only screen and (min-width: 1024px) {
    background-position: center center;
    background-size: 300%;
  }
}
</style>
