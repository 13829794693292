import AddIncident from '@mobile/ActionQueue/Actions/Incidents/AddIncident'
import {
  ClockStatusAction,
  IncidentConfiguration,
} from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/IncidentsConfiguration'

import { ProbableTimeAction } from './ProbableTimeAction'

export class ProbableTimeAddIncidentAction extends ProbableTimeAction {
  constructor(
    private readonly action: AddIncident,
    private readonly incidentConfiguration: IncidentConfiguration,
  ) {
    super()
  }

  public isTimeStarter(): boolean {
    return (
      this.incidentConfiguration.clockStatusAction === ClockStatusAction.Start
    )
  }

  public isTimeStopper(): boolean {
    return (
      this.incidentConfiguration.clockStatusAction === ClockStatusAction.Stop
    )
  }

  public isTimeSetter(): boolean {
    return this.incidentConfiguration.resetClockTime ?? false
  }

  public getTimeToSet(): number | null {
    return 0
  }

  public getActionTime(): number {
    return this.action.actionTime
  }
}
