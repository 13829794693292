import { Container } from 'inversify'
import { computed, Ref } from 'vue'

import {
  Basketball3x3EventDetailId,
  Basketball3x3EventStatusId,
  Basketball3x3IncidentId,
  CommonIncidentId,
  Event,
  IncidentId,
} from '@collector/sportsapi-client-legacy'
import {
  getDetailValue,
  getNumericDetailValue,
} from '@mobile/reusables/eventUtils'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { EventStatusesList } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/EventStatusesList'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

type ClockDirection = 'down' | 'up'

export function bindEventStatusesConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.EventStatusesConfiguration)
    .toDynamicValue(async (ctx) => {
      const [probableEvent] = await Promise.all([
        ctx.container.getAsync(RelationIoCBindings.ProbableEvent),
      ])
      const eventStatuses = await ctx.container.getAsync(
        RelationSportCommonIoCBindings.EventStatusesList,
      )

      return prepareEventStatusesConfiguration(
        probableEvent.value,
        eventStatuses,
      )
    })
    .inSingletonScope()
}

/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
function prepareEventStatusesConfiguration(
  event: Event,
  eventStatuses: EventStatusesList,
) {
  const periodLength = computed(
    () =>
      getNumericDetailValue(
        event,
        Basketball3x3EventDetailId.PeriodLength,
        10,
      ) * 60,
  )
  const periodLengthInMinutes = computed(() =>
    getNumericDetailValue(event, Basketball3x3EventDetailId.PeriodLength, 10),
  )
  const clockDirection = computed(() =>
    getDetailValue(event, Basketball3x3EventDetailId.TimerDirection),
  ) as Ref<ClockDirection>
  const startRegularPeriodTime =
    clockDirection.value === 'down' ? periodLength.value : 0

  const withCommonIncidentsAllowedDuringTheGame = (
    incidentsId: IncidentId[] = [],
  ): IncidentId[] => {
    return [
      ...incidentsId,
      Basketball3x3IncidentId.InPossession,
      Basketball3x3IncidentId.OnePointShotMade,
      Basketball3x3IncidentId.OnePointShotMissed,
      Basketball3x3IncidentId.TwoPointShotMade,
      Basketball3x3IncidentId.TwoPointShotMissed,
      Basketball3x3IncidentId.DefensiveRebound,
      Basketball3x3IncidentId.OffensiveRebound,
      Basketball3x3IncidentId.Block,
      Basketball3x3IncidentId.FreeThrowMissed,
      Basketball3x3IncidentId.FreeThrowIn,
      Basketball3x3IncidentId.Foul,
      Basketball3x3IncidentId.Turnover,
      Basketball3x3IncidentId.Steal,
      Basketball3x3IncidentId.Timeout,
      Basketball3x3IncidentId.Challenge,
      Basketball3x3IncidentId.BreakDuringTheGame,
      Basketball3x3IncidentId.ThrowIn,
      Basketball3x3IncidentId.FreeThrowsAwarded,
      Basketball3x3IncidentId.FoulBonus,

      // TODO: remove when proper statuses will be configured
      Basketball3x3IncidentId.NotStarted,
      Basketball3x3IncidentId.StartDelayed,
      Basketball3x3IncidentId.MatchAboutToStart,
      Basketball3x3IncidentId.FinishedRegularTime,
      Basketball3x3IncidentId.WaitingForOvertime,
      Basketball3x3IncidentId.FinishedAfterOvertime,
      Basketball3x3IncidentId.FinishedAwardedWin,
      Basketball3x3IncidentId.StartWithABall,
      Basketball3x3IncidentId.RegularTimeStarted,
      Basketball3x3IncidentId.Interrupted,
      Basketball3x3IncidentId.Postponed,
      Basketball3x3IncidentId.Cancelled,
      Basketball3x3IncidentId.Abandoned,
      Basketball3x3IncidentId.ToFinish,
      Basketball3x3IncidentId.Injury,

      CommonIncidentId.MatchResumed,
      CommonIncidentId.Betstart,
      CommonIncidentId.Betstop,
    ]
  }

  const getClockConfig = (
    clockDirection: string | number | null,
    periodLength: number,
    /* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
  ) => {
    return {
      clockDirectionDown: clockDirection === 'down',
      startTimeFromInSeconds: clockDirection === 'down' ? periodLength : 0,
      timeBoundaryInSeconds: periodLength,
      time: {
        minute: 0,
        second: 0,
      },
    }
  }

  return {
    [Basketball3x3EventStatusId.NotStarted]: {
      ...getClockConfig(clockDirection.value, periodLength.value),
      allowedIncidents: [
        Basketball3x3IncidentId.StartDelayed,
        Basketball3x3IncidentId.FinishedAwardedWin,
        Basketball3x3IncidentId.MatchAboutToStart,
        Basketball3x3IncidentId.Postponed,
        Basketball3x3IncidentId.Abandoned,
        Basketball3x3IncidentId.Cancelled,
        Basketball3x3IncidentId.StartWithABall,
        Basketball3x3IncidentId.RegularTimeStarted,
        CommonIncidentId.Betstart,
        CommonIncidentId.Betstop,
      ],
    },
    [Basketball3x3EventStatusId.RegularTime]: {
      ...getClockConfig(clockDirection.value, periodLength.value),
      allowedIncidents: withCommonIncidentsAllowedDuringTheGame(),
    },
    [Basketball3x3EventStatusId.Overtime]: {
      ...getClockConfig(clockDirection.value, periodLength.value),
      allowedIncidents: withCommonIncidentsAllowedDuringTheGame(),
    },
    [Basketball3x3EventStatusId.StartDelayed]: {
      ...getClockConfig(clockDirection.value, periodLength.value),
      allowedIncidents: [
        Basketball3x3IncidentId.NotStarted,
        Basketball3x3IncidentId.StartWithABall,
        Basketball3x3IncidentId.FinishedAwardedWin,
        Basketball3x3IncidentId.MatchAboutToStart,
        Basketball3x3IncidentId.Postponed,
        Basketball3x3IncidentId.Abandoned,
        Basketball3x3IncidentId.Cancelled,
        Basketball3x3IncidentId.RegularTimeStarted,
        CommonIncidentId.Betstart,
        CommonIncidentId.Betstop,
      ],
    },
    [Basketball3x3EventStatusId.ToFinish]: {
      time: {
        minute: 0,
        second: 0,
      },
      allowedIncidents: eventStatuses,
    },
    [Basketball3x3EventStatusId.WaitingForOvertime]: {
      time: {
        minute: 4 * periodLengthInMinutes.value,
        second: 0,
      },
      clockDirectionDown: clockDirection.value === 'down',
      allowedIncidents: [
        Basketball3x3IncidentId.Overtime,
        Basketball3x3IncidentId.Interrupted,
        CommonIncidentId.Betstart,
        CommonIncidentId.Betstop,
      ],
    },
    [Basketball3x3EventStatusId.FinishedAfterOvertime]: {
      time: {
        minute: 4 * periodLengthInMinutes.value,
        second: 0,
      },
      clockDirectionDown: clockDirection.value === 'down',
    },

    [Basketball3x3EventStatusId.FinishedAwardedWin]: {
      time: {
        minute: 0,
        second: 0,
      },
    },
    [Basketball3x3EventStatusId.FinishedRegularTime]: {
      time: {
        minute: 4 * periodLengthInMinutes.value,
        second: 0,
      },
      timeBoundaryInSeconds: periodLength.value * 4,
      clockDirectionDown: clockDirection.value === 'down',
      startTimeFromInSeconds: startRegularPeriodTime * 4,
    },
    [Basketball3x3EventStatusId.Interrupted]: {
      time: {
        minute: 0,
        second: 0,
      },
      allowedIncidents: [
        Basketball3x3IncidentId.FinishedAwardedWin,
        Basketball3x3IncidentId.Abandoned,
        Basketball3x3IncidentId.Cancelled,
        Basketball3x3IncidentId.ToFinish,
        CommonIncidentId.Betstart,
        CommonIncidentId.Betstop,
      ],
    },
    [Basketball3x3EventStatusId.Postponed]: {
      time: {
        minute: 0,
        second: 0,
      },
      allowedIncidents: [
        Basketball3x3IncidentId.FinishedAwardedWin,
        Basketball3x3IncidentId.Abandoned,
        Basketball3x3IncidentId.Cancelled,
        Basketball3x3IncidentId.NotStarted,
        CommonIncidentId.Betstart,
        CommonIncidentId.Betstop,
      ],
    },
    [Basketball3x3EventStatusId.Abandoned]: {
      time: {
        minute: 0,
        second: 0,
      },
      allowedIncidents: [
        Basketball3x3IncidentId.NotStarted,
        CommonIncidentId.Betstart,
        CommonIncidentId.Betstop,
      ],
    },
    [Basketball3x3EventStatusId.Cancelled]: {
      time: {
        minute: 0,
        second: 0,
      },
      allowedIncidents: [
        Basketball3x3IncidentId.NotStarted,
        CommonIncidentId.Betstart,
        CommonIncidentId.Betstop,
      ],
    },
  }
}
