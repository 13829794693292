import Dexie from 'dexie'

import { CollectorPushMessageIncident } from '@collector/sportsapi-client-legacy'
import { Repository } from '@mobile/database/Repository'

export class IncidentsRepository extends Repository<CollectorPushMessageIncident> {
  static readonly tableName = 'incidents'
  static readonly indexes = 'data.id, eventId, pushMessageUt, uuid'

  constructor(database: Dexie) {
    super(
      database.table<CollectorPushMessageIncident>(
        IncidentsRepository.tableName,
      ),
    )
  }

  public async save(incident: CollectorPushMessageIncident): Promise<void> {
    try {
      await this.table.put(incident)
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.log(
        `Couldn't save or update incident ${incident.data.id} [eventId: ${incident.eventId}]. Reason: ${(error as Error).message}`,
      )

      throw error
    }
  }

  public async getById(
    id: string,
  ): Promise<CollectorPushMessageIncident | undefined> {
    return await this.table.get(id)
  }

  public async getByUuid(
    uuid: string,
  ): Promise<CollectorPushMessageIncident | undefined> {
    return await this.table.where('uuid').equals(uuid).first()
  }

  public async getByEventId(
    eventId: number,
  ): Promise<CollectorPushMessageIncident[]> {
    return await this.table.where('eventId').equals(eventId).toArray()
  }

  public async delete(incident: CollectorPushMessageIncident): Promise<void> {
    await this.table.delete(incident.data.id)
  }

  public async getLast(): Promise<CollectorPushMessageIncident | undefined> {
    return await this.table.orderBy('pushMessageUt').last()
  }

  public async removeByEventId(eventId: number): Promise<number> {
    return await this.table.where('eventId').equals(eventId).delete()
  }
}
