<script lang="ts">
import { cloneDeep } from 'lodash'
import {
  Component,
  defineComponent,
  PropType,
  ref,
  Ref,
  shallowRef,
  watch,
} from 'vue'

import { nullCoalesce, opChain } from '@collector/shared-utils'
import {
  EventsLineupsParticipant,
  IncidentsQueuePostDTO,
} from '@collector/sportsapi-client-legacy'
import ToggleButtonGroup from '@mobile/components/ToggleButton/ToggleButtonGroup.vue'
import * as popup from '@mobile/globalState/popup'
import AssignPlayersToIncident from '@mobile/views/Relation/Shared/AssignPlayersToIncident/AssignPlayersToIncident.vue'
import Breadcrumbs from '@mobile/views/Relation/Shared/Popup/Breadcrumbs.vue'
import PopupButton from '@mobile/views/Relation/Shared/Popup/builder/buttons/PopupButton/PopupButton.vue'
import PopupControls from '@mobile/views/Relation/Shared/Popup/PopupControls.vue'
import StackedPopup from '@mobile/views/Relation/Shared/Popup/StackedPopup.vue'
import TeamSelect from '@mobile/views/Relation/Shared/Popup/TeamSelect.vue'
import {
  IncidentActionComposable,
  useIncidentAction,
} from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import {
  ProbableLinkedIncident,
  ProbableLinkedParticipant,
} from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

import {
  NavigationButton,
  PopupIncidentProp,
  PopupProps,
  StackedPopupProp,
} from './types'

export default defineComponent({
  components: {
    TeamSelect,
    Breadcrumbs,
    PopupControls,
    PopupButton,
    StackedPopup,
    ToggleButtonGroup,
    AssignPlayersToIncident,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    showTeamSelection: {
      type: Boolean,
      required: true,
    },
    incident: {
      type: Object as PropType<PopupIncidentProp>,
      required: false,
    },
    stackedPopups: {
      type: Array as PropType<StackedPopupProp[]>,
      default: () => [],
    },
    navigationButtons: {
      type: Array as PropType<(NavigationButton | null)[]>,
      default: () => [],
    },
    omitDetails: {
      type: Boolean,
      default: false,
    },
    staticLinkedProbableIncidents: {
      type: Array as PropType<ProbableLinkedIncident[]>,
      required: true,
    },
    incidentActionComposable: {
      type: Object as PropType<IncidentActionComposable | null>,
      default: null,
    },
  },
  emits: ['close'],
  setup(props, { slots, emit }) {
    const nextStepTriggered = ref(false)
    const openedPopup: Ref<{ popup: Component | null; props?: object }> = ref({
      popup: null,
    })
    const selectedParticipant: Ref<ProbableLinkedParticipant | null> =
      shallowRef(null)

    const eventInjections = useEventInjections()
    const { probableEvent } = eventInjections
    const incidentData: IncidentsQueuePostDTO | null = props.incident
      ? {
          status_id: probableEvent.value.status_id,
          ...props.incident,
        }
      : null

    const internalIncidentActionComposable = ref(
      cloneDeep(props.incidentActionComposable),
    )

    if (!props.showTeamSelection) {
      nextStepTriggered.value = true

      if (incidentData) {
        incidentData.participant_team_id = props.incidentActionComposable
          ?.getProbableLinkedParticipant()
          ?.getParticipant().value?.id

        internalIncidentActionComposable.value = useIncidentAction(
          eventInjections,
          incidentData,
        )
      }
    }

    watch(
      internalIncidentActionComposable,
      () => {
        applyIncidentActionComposable()
      },
      { immediate: true },
    )

    const { assignableIncidentsConfiguration } = useSportConfiguration()
    const assignableIncidentConfiguration = props.incident?.incident_id
      ? assignableIncidentsConfiguration[props.incident.incident_id]
      : null

    function applyIncidentActionComposable(): void {
      slots.default?.().forEach((item) => {
        if (!item.props) {
          item.props = {}
        }

        item.props.incidentActionComposable =
          internalIncidentActionComposable.value
        item.props.staticLinkedProbableIncidents =
          props.staticLinkedProbableIncidents
        item.props.context = {
          openPopup,
          closePopup,
          sendIncident,
          closeAllPopups,
          incidentActionComposable: internalIncidentActionComposable,
        }
      })
    }

    function setParticipant(
      probableLinkedParticipant: ProbableLinkedParticipant,
    ): void {
      selectedParticipant.value = probableLinkedParticipant

      if (incidentData) {
        incidentData.participant_team_id =
          probableLinkedParticipant.getParticipant().value.id
      }

      next()
    }

    function next(): void {
      if (incidentData) {
        internalIncidentActionComposable.value = useIncidentAction(
          eventInjections,
          incidentData,
        )
      }

      if (props.omitDetails) {
        emit('close')

        return
      }

      nextStepTriggered.value = true
    }

    function openPopup(
      component: Component,
      options?: Partial<PopupProps>,
    ): void {
      openedPopup.value.popup = component
      openedPopup.value.props = options || {}
    }

    function closeAllPopups(): void {
      popup.hide()
    }

    function sendIncident(
      incident: PopupIncidentProp,
      closePopup = true,
    ): void {
      useIncidentAction(eventInjections, incident)

      if (closePopup) {
        emit('close')
      }
    }

    function closePopup(): void {
      emit('close')
    }

    function onMainParticipantUpdate(
      participant: EventsLineupsParticipant | null,
    ): void {
      const participant_team_id =
        internalIncidentActionComposable.value?.getProbableIncident().value
          .participant_id

      /**
       * TODO: update this line after https://statscore.atlassian.net/browse/API-3653
       * Remove `participant_team_id` field
       */
      internalIncidentActionComposable.value?.updateIncident({
        participant_id: participant_team_id,
        participant_name: participant?.participant_name,
        subparticipant_id: participant?.participant_id,
        subparticipant_name: participant?.participant_name,
      })
    }

    function onSecondaryParticipantUpdate(
      participant: EventsLineupsParticipant | null,
    ): void {
      const participant_team_id =
        internalIncidentActionComposable.value?.getProbableIncident().value
          .participant_id

      /**
       * TODO: update this line after https://statscore.atlassian.net/browse/API-3653
       * Remove `participant_team_id` field
       */
      internalIncidentActionComposable.value?.updateIncident({
        participant_id: participant_team_id,
        assistant_id: participant?.participant_id,
        assistant_name: participant?.participant_name,
      })
    }

    return {
      next,
      openPopup,
      closePopup,
      openedPopup,
      sendIncident,
      setParticipant,
      closeAllPopups,
      nextStepTriggered,
      internalIncidentActionComposable,
      assignableIncidentConfiguration,
      onMainParticipantUpdate,
      onSecondaryParticipantUpdate,
      opChain,
      nullCoalesce,
    }
  },
})
</script>

<template>
  <div>
    <Breadcrumbs
      v-if="showTeamSelection"
      :name="title"
    />

    <TeamSelect
      v-if="!nextStepTriggered"
      @participantSelected="setParticipant"
      @close="$emit('close')"
    />

    <StackedPopup v-else>
      <Breadcrumbs :name="`${title}: details`" />

      <div
        class="flex flex-col overflow-y-scroll py-3"
        style="height: calc(100vh - 5rem)"
      >
        <slot></slot>

        <div
          v-if="assignableIncidentConfiguration"
          class="flex w-full px-4 py-3"
        >
          <AssignPlayersToIncident
            :assignableIncidentConfiguration
            :incident="
              opChain(internalIncidentActionComposable, (v) =>
                v.getProbableIncident(),
              )
            "
            :side="
              opChain(
                opChain(internalIncidentActionComposable, (v) =>
                  v.getProbableLinkedParticipant(),
                ),
                (v) => v.side,
              )
            "
            :incidentActionComposable="
              nullCoalesce(internalIncidentActionComposable, undefined)
            "
            selectLabelClasses="mr-3 w-12 text-base font-bold"
            @mainParticipantUpdated="onMainParticipantUpdate"
            @secondaryParticipantUpdated="onSecondaryParticipantUpdate"
          />
        </div>
        <div v-if="navigationButtons.length">
          <div class="h-20 w-full"></div>
        </div>
      </div>
      <PopupControls>
        <template #buttons>
          <div
            class="grid gap-2"
            style="grid-template-columns: repeat(auto-fit, minmax(90px, 1fr))"
          >
            <template v-for="(navigationButton, i) in navigationButtons">
              <component
                :is="opChain(navigationButton, (v) => v.component)"
                v-if="opChain(navigationButton, (v) => v.component)"
                :key="`navigation-button-${i}`"
                v-bind="opChain(navigationButton, (v) => v.props) || {}"
                :context="{
                  openPopup,
                  closePopup,
                  sendIncident,
                  closeAllPopups,
                  incidentActionComposable: internalIncidentActionComposable,
                }"
              />
              <div
                v-else
                :key="`navigation-separator-${i}`"
              ></div>
            </template>
          </div>
        </template>
      </PopupControls>
      <StackedPopup v-if="openedPopup.popup">
        <component
          :is="openedPopup.popup"
          v-bind="{
            incidentActionComposable: internalIncidentActionComposable,
            ...openedPopup.props,
          }"
          @close="openedPopup.popup = null"
        />
      </StackedPopup>
    </StackedPopup>
  </div>
</template>
