<script lang="ts">
import { defineComponent, ref } from 'vue'

import {
  CommonIncidentId,
  CricketIncidentId,
} from '@collector/sportsapi-client-legacy'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import { useLiveViewBackground } from '@mobile/views/Relation/Shared/LiveView/useLiveViewBackground'
import PocketIncidents from '@mobile/views/Relation/Shared/PocketIncidents/PocketIncidents.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

import LiveViewCenterColumn from './LiveViewCenterColumn/LiveViewCenterColumn.vue'
import LiveViewHeader from './LiveViewHeader/LiveViewHeader.vue'
import LiveViewLeftColumn from './LiveViewLeftColumn/LiveViewLeftColumn.vue'
import LiveViewRightColumn from './LiveViewRightColumn/LiveViewRightColumn.vue'

export default defineComponent({
  components: {
    LiveViewHeader,
    LiveViewLeftColumn,
    LiveViewCenterColumn,
    LiveViewRightColumn,
    PocketIncidents,
    IncidentButton,
  },
  emits: ['incidentAddedToQueue'],
  setup() {
    const { probableEvent } = useEventInjections()
    const { eventHeaderConfiguration } = useSportConfiguration()
    const liveViewBackground = useLiveViewBackground(
      probableEvent,
      eventHeaderConfiguration,
    )
    const isPocketIncidentsMenuExpanded = ref(false)

    const supportedPocketIncidents = [
      CricketIncidentId.DuckworthLewisMethods,
      CricketIncidentId.TimedOut,
      CricketIncidentId.BreakInPlay,
      CricketIncidentId.RetiredHurt,
    ]

    return {
      CommonIncidentId,
      CricketIncidentId,
      liveViewBackground,
      isPocketIncidentsMenuExpanded,
      supportedPocketIncidents,
    }
  },
})
</script>

<template>
  <div
    class="relative h-full overflow-hidden pt-1"
    :class="liveViewBackground"
  >
    <div class="flex h-full flex-col">
      <LiveViewHeader />
      <div
        class="grid h-full gap-4 px-2 pb-5 pt-1 sm:overflow-y-auto"
        style="grid-template-columns: 0.3fr 1fr 0.3fr; grid-template-rows: 100%"
      >
        <LiveViewLeftColumn />
        <LiveViewCenterColumn />
        <LiveViewRightColumn />
      </div>

      <PocketIncidents
        :isExpanded="isPocketIncidentsMenuExpanded"
        :incidentIds="supportedPocketIncidents"
        @toggle="isPocketIncidentsMenuExpanded = !isPocketIncidentsMenuExpanded"
        @incidentAddedToQueue="isPocketIncidentsMenuExpanded = false"
      >
        <IncidentButton
          :key="CricketIncidentId.ExtrasPenaltyRuns"
          class="w-full"
          :type="'horizontal'"
          :incidentId="CricketIncidentId.ExtrasPenaltyRuns"
          :size="'xl'"
          :color="'white'"
          :name="'Penalty runs'"
          @incidentHandled="$emit('incidentAddedToQueue')"
        />
      </PocketIncidents>
    </div>
  </div>
</template>
