import { Wretch } from 'wretch'

import { ApiErrorResponse } from '../types/responses/ApiResponse'
import { LoginResponse } from '../types/responses/auth/LoginResponse'
import { isWretchError } from '../utils'

export type Credentials = {
  email: string
  password: string
}

export abstract class Authorization {
  public currentNewTokenPromise: Promise<string> | null = null
  private onNewTokenCallbacks: Array<
    (token: string, client: Wretch) => unknown
  > = []
  private onTokenExpiredCallbacks: Array<() => unknown> = []
  private onAuthorizationFailCallbacks: Array<(e: Error) => unknown> = []
  private client: Wretch
  public currentJWT: string | null = null

  /**
   * @param jwtToken If you've resolved token before then you can use it on initialization
   * to not send auth request needlessly
   */
  constructor(private initJWTToken: string | null = null) {
    this.onNewToken((token) => {
      this.currentJWT = token
    })
  }

  public async init(client: Wretch): Promise<void> {
    this.client = client

    if (this.initJWTToken) {
      const initJWTToken = this.initJWTToken

      this.currentNewTokenPromise = new Promise<string>((resolve) => {
        setTimeout(async () => {
          this.currentNewTokenPromise = null
          for (const callback of this.onNewTokenCallbacks) {
            await callback(initJWTToken, this.client)
          }
          resolve(initJWTToken)
        })
      })
    } else {
      await this.authorize()
    }
  }

  abstract requestCredentials(): Promise<Credentials>

  public onTokenExpired(callback: () => unknown, prepend = false): void {
    if (prepend) {
      this.onTokenExpiredCallbacks.unshift(callback)
    } else {
      this.onTokenExpiredCallbacks.push(callback)
    }
  }

  public onAuthorizationFail(callback: (e: Error) => unknown): void {
    this.onAuthorizationFailCallbacks.push(callback)
  }

  public onNewToken(
    callback: (token: string, client: Wretch) => unknown,
    prepend = false,
  ): void {
    if (prepend) {
      this.onNewTokenCallbacks.unshift(callback)
    } else {
      this.onNewTokenCallbacks.push(callback)
    }
  }

  async authorize(): Promise<string> {
    if (this.currentNewTokenPromise) {
      return await this.currentNewTokenPromise
    }

    for (const callback of this.onTokenExpiredCallbacks) {
      await callback()
    }

    this.currentNewTokenPromise = new Promise<string>(
      // eslint-disable-next-line no-async-promise-executor
      async (resolve, reject) => {
        const credentials: Credentials = await this.requestCredentials()

        try {
          const response: LoginResponse = await this.client
            .url('/auth/login')
            .post(credentials)
            .json<LoginResponse>()

          this.currentNewTokenPromise = null
          for (const callback of this.onNewTokenCallbacks) {
            await callback(response.api.data.token, this.client)
          }
          resolve(response.api.data.token)
        } catch (e) {
          let parsedError: Error

          if (isWretchError(e) && e.text) {
            try {
              const response: ApiErrorResponse = JSON.parse(e.text)
              parsedError = Error(response.api.error.message)
            } catch (err) {
              parsedError = err as Error
            }
          } else {
            parsedError = e as Error
          }

          for (const callback of this.onAuthorizationFailCallbacks) {
            await callback(parsedError)
          }

          return reject(e)
        }
      },
    ).catch((e) => {
      if (isWretchError(e) && (e.status === 404 || e.status === 500)) {
        throw e
      }

      // eslint-disable-next-line no-console
      console.error(e)
      this.currentNewTokenPromise = null
      return this.authorize()
    })

    return this.currentNewTokenPromise
  }
}
