import { EventBus } from '@mobile/globalState/eventBus'
import { afterNextRender } from '@mobile/reusables/afterNextRender'

import { getEventCoords } from './useGestureDirective'

export function usePressTap(
  eventBus: EventBus,
  tapCallback: (e: TouchEvent | PointerEvent) => void,
  pressCallback: (e: TouchEvent) => void,
): {
  onTap: (e: TouchEvent | PointerEvent) => void
  onPress: (e: TouchEvent | PointerEvent) => void
  onPressUp: (e: TouchEvent) => void
  onPanStart: (e: TouchEvent | PointerEvent) => void
} {
  let cancelPress: () => void = () => {}
  let pressing = false
  let pressFinished = false

  function onPress(e: TouchEvent | PointerEvent): void {
    cancelPress()
    let cancelled = false
    pressFinished = false
    pressing = true

    const currentPressTimeout = window.setTimeout(() => {
      if (cancelled) {
        return
      }

      const position = getEventCoords(e)

      eventBus.emit('Press', {
        position,
        onComplete: () => {
          if (cancelled) {
            return
          }
          pressFinished = true
        },
        onCancel: () => {},
        timeMs: 250,
      })
    }, 50)

    cancelPress = () => {
      window.clearTimeout(currentPressTimeout)
      cancelled = true
      pressFinished = false
      pressing = false
      eventBus.emit('CancelPress')
    }
  }

  function onPressUp(e: TouchEvent): void {
    if (!pressing) {
      // When dev tools are open sometimes chrome sends pressup before press
      // We can fire it in next tick to solve this issue
      window.setTimeout(() => {
        onPressUp(e)
      })

      return
    }

    if (pressFinished) {
      afterNextRender(() => pressCallback(e))
    } else {
      afterNextRender(() => tapCallback(e))
    }

    cancelPress()
  }

  function onTap(e: TouchEvent | PointerEvent): void {
    afterNextRender(() => tapCallback(e))

    cancelPress()
  }

  function onPanStart(): void {
    cancelPress()
  }

  return {
    onTap,
    onPress,
    onPressUp,
    onPanStart,
  }
}
