import { computed } from 'vue'

import {
  SoccerEventDetailId,
  SoccerEventStatusId,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'
import { byId } from '@mobile/reusables/entityUtils'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import SidesSwitchOverlay from '@mobile/views/Relation/Shared/SidesSwitchOverlay/SidesSwitchOverlay.vue'
import PitchOverlayKickOff from '@mobile/views/Relation/Sports/Soccer/LiveView/Pitch/PitchOverlays/PitchOverlayKickOff.vue'
import PitchOverlayPenaltyShootout from '@mobile/views/Relation/Sports/Soccer/LiveView/Pitch/PitchOverlays/PitchOverlayPenaltyShootout.vue'

/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
export function usePitchOverlays() {
  const { probableIncidents, probableEvent } = useEventInjections()

  const overlayComponents = computed(() => {
    return [
      {
        component: SidesSwitchOverlay,
        isVisible: !probableIncidents.value.find((incident) =>
          [
            SoccerIncidentId.MatchAboutToStart,
            SoccerIncidentId.FirstHalf,
          ].includes(incident.incident_id as SoccerIncidentId),
        ),
        props: {
          incidentId: SoccerIncidentId.MatchAboutToStart,
          time: {
            minute: 0,
            second: 0,
          },
        },
      },
      {
        component: PitchOverlayKickOff,
        isVisible: !(
          byId(probableEvent.value.details, SoccerEventDetailId.KickOffTeam)
            ?.value ||
          byId(
            probableEvent.value.details,
            SoccerEventDetailId.FirstHalfStartDate,
          )?.value
        ),
        props: {
          incidentId: SoccerIncidentId.KickOff,
          time: {
            minute: 0,
            second: 0,
          },
        },
      },
      {
        component: SidesSwitchOverlay,
        isVisible: !probableIncidents.value.find(
          (incident) => incident.incident_id === SoccerIncidentId.FirstHalf,
        ),
        props: {
          incidentId: SoccerIncidentId.FirstHalf,
          time: {
            minute: 0,
            second: 0,
          },
        },
      },
      {
        component: SidesSwitchOverlay,
        isVisible:
          probableEvent.value.status_id === SoccerEventStatusId.Halftime,
        props: {
          incidentId: SoccerIncidentId.SecondHalf,
          time: {
            minute: 45,
            second: 0,
          },
        },
      },
      {
        component: SidesSwitchOverlay,
        isVisible:
          probableEvent.value.status_id ===
          SoccerEventStatusId.WaitingForExtratime,
        props: {
          incidentId: SoccerIncidentId.ExtratimeFirstHalf,
          time: {
            minute: 90,
            second: 0,
          },
        },
      },
      {
        component: SidesSwitchOverlay,
        isVisible:
          probableEvent.value.status_id ===
          SoccerEventStatusId.ExtratimeHalftime,
        props: {
          incidentId: SoccerIncidentId.ExtratimeSecondHalf,
          time: {
            minute: 105,
            second: 0,
          },
        },
      },
      {
        component: SidesSwitchOverlay,
        isVisible:
          probableEvent.value.status_id ===
          SoccerEventStatusId.WaitingForPenalty,
        props: {
          incidentId: SoccerIncidentId.PenaltyShootoutStarted,
          time: {
            minute: 120,
            second: 0,
          },
        },
      },
      {
        component: PitchOverlayPenaltyShootout,
        isVisible:
          probableEvent.value.status_id === SoccerEventStatusId.PenaltyShootout,
        props: {
          incidentId: SoccerIncidentId.Penalty,
          time: {
            minute: 120,
            second: 0,
          },
        },
      },
    ]
  })

  const visibleOverlay = computed(() =>
    overlayComponents.value.find(({ isVisible }) => isVisible),
  )

  return {
    overlayComponents,
    visibleOverlay,
  }
}
