import { Container } from 'inversify'
import { computed, Ref, ref } from 'vue'

import { Event, Skin } from '@collector/sportsapi-client-legacy'
import * as app from '@mobile/globalState/app'
import * as sportsAPI from '@mobile/globalState/sportsAPI'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

async function initSkins(
  participantId: number,
  probableEvent: Ref<Event>,
): Promise<Skin[]> {
  const { id: eventId } = probableEvent.value
  const record = await app.state.database.skins.findForParticipant(
    probableEvent.value.id,
    participantId,
  )

  if (record) {
    return record.skins
  }

  let skins: Skin[] = []

  try {
    const response = await sportsAPI.state.client.getParticipantSkins([
      participantId,
    ])

    skins = response.api.data.skins
  } catch (_error) {
    await app.state.database.skins.save(eventId, participantId, [])
  }

  return skins
}

export function bindSkins(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.Skins)
    .toDynamicValue(async (ctx) => {
      const [probableEvent, probableHomeParticipant, probableAwayParticipant] =
        await Promise.all([
          ctx.container.getAsync(RelationIoCBindings.ProbableEvent),
          ctx.container.getAsync(RelationIoCBindings.ProbableHomeParticipant),
          ctx.container.getAsync(RelationIoCBindings.ProbableAwayParticipant),
        ])

      const homeSkins: Ref<Skin[]> = ref(
        await initSkins(probableHomeParticipant.value.id, probableEvent),
      )
      const awaySkins: Ref<Skin[]> = ref(
        await initSkins(probableAwayParticipant.value.id, probableEvent),
      )

      const { id: eventId } = probableEvent.value

      return {
        homeSkins: computed({
          get() {
            return homeSkins.value
          },
          async set(value) {
            await app.state.database.skins.save(
              eventId,
              probableHomeParticipant.value.id,
              value,
            )
            homeSkins.value = value
          },
        }),
        awaySkins: computed({
          get() {
            return awaySkins.value
          },
          async set(value) {
            await app.state.database.skins.save(
              eventId,
              probableAwayParticipant.value.id,
              value,
            )
            awaySkins.value = value
          },
        }),
      }
    })
    .inSingletonScope()
}
