import { Participant } from '@collector/sportsapi-client-legacy'
import { isAwayTeam, isHomeTeam } from '@mobile/reusables/entityUtils'

import { ButtonColor } from './ButtonTypes'

export function getParticipantButtonColor(
  participant: Participant,
): ButtonColor {
  if (isHomeTeam(participant)) {
    return 'home'
  }

  if (isAwayTeam(participant)) {
    return 'away'
  }

  return 'white'
}
