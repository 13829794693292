import { first, includes } from 'lodash'
import { computed, Ref, ref } from 'vue'

import {
  CollectorPushMessageIncident,
  CricketIncidentId,
  IncidentActionType,
} from '@collector/sportsapi-client-legacy'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import * as app from '@mobile/globalState/app'
import { EventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

const relevantIncidents = [
  CricketIncidentId.LegalBall,
  CricketIncidentId.IllegalBall,
  CricketIncidentId.StartOfOver,
  CricketIncidentId.DeadBall,
  CricketIncidentId.BowlerRunningIn,
]

function filterProbableIncidents(
  probableIncidents: Ref<ProbableIncident[]>,
): Ref<ProbableIncident[]> {
  return computed(() =>
    probableIncidents.value.filter((incident) =>
      includes(relevantIncidents, incident.incident_id),
    ),
  )
}

export function resolveBowlerRunningDisabled(
  eventInjections: EventInjections,
): Ref<boolean> {
  const { probableIncidents } = eventInjections
  const filteredIncidents = filterProbableIncidents(probableIncidents)

  app.state.database.incidents.on(
    'updated',
    (incident: CollectorPushMessageIncident) =>
      (app.state.lastIncidentAction = incident.data.action),
  )

  app.state.database.incidents.on(
    'created',
    (incident: CollectorPushMessageIncident) =>
      (app.state.lastIncidentAction = incident.data.action),
  )

  return ref<boolean>(
    first(filteredIncidents.value)?.incident_id ===
      CricketIncidentId.BowlerRunningIn &&
      app.state.lastIncidentAction !== IncidentActionType.DELETE,
  )
}
