<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: [Number, null] as PropType<string | number | null>,
      required: true,
    },
    min: {
      type: Number,
      required: false,
    },
    max: {
      type: Number,
      required: false,
    },
  },
  emits: ['update:value'],
})
</script>

<template>
  <input
    v-bind="{ value, min, max }"
    class="border-darksilver h-8 rounded border border-solid bg-white text-center text-sm font-bold opacity-60"
    type="number"
    placeholder="-"
    @input="
      (ev) =>
        $emit('update:value', Number((ev.target as HTMLInputElement).value))
    "
  />
</template>
