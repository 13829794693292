import {
  CommonIncidentId,
  IceHockeyIncidentId,
} from '@collector/sportsapi-client-legacy'

import {
  IceHockeyAttributesConfiguration,
  IceHockeyDetailsConfiguration,
  IceHockeyGroupNames,
} from './IceHockeyDetailsConfiguration'
import {
  IceHockeyManualAddIncidentIds,
  IceHockeyManualAddStatusIds,
} from './IceHockeyManualAddConfiguration'

export const IceHockeyConfiguration = {
  iceHockeyGroupNames: IceHockeyGroupNames,
  iceHockeyDetailsConfiguration: IceHockeyDetailsConfiguration,
  iceHockeyAttributesConfiguration: IceHockeyAttributesConfiguration,
  iceHockeyManualAddIncidentIds: IceHockeyManualAddIncidentIds,
  iceHockeyManualAddStatusIds: IceHockeyManualAddStatusIds,
  iceHockeyHideIncidentIds: [
    IceHockeyIncidentId.StartDelayed,
    IceHockeyIncidentId.MatchAboutToStart,
    IceHockeyIncidentId.StartDelayed,
    IceHockeyIncidentId.MatchAboutToStart,
    IceHockeyIncidentId.FirstPeriodStarted,
    IceHockeyIncidentId.BreakAfterFirstPeriod,
    IceHockeyIncidentId.SecondPeriodStarted,
    IceHockeyIncidentId.BreakAfterSecondPeriod,
    IceHockeyIncidentId.ThirdPeriodStarted,
    IceHockeyIncidentId.WaitingForOvertime,
    IceHockeyIncidentId.FirstOvertimeStarted,
    IceHockeyIncidentId.BreakAfterFirstOvertime,
    IceHockeyIncidentId.SecondOvertimeStarted,
    IceHockeyIncidentId.BreakAfterSecondOvertime,
    IceHockeyIncidentId.ThirdOvertimeStarted,
    IceHockeyIncidentId.BreakAfterThirdOvertime,
    IceHockeyIncidentId.FourthOvertimeStarted,
    IceHockeyIncidentId.PenaltiesStarted,
    IceHockeyIncidentId.Finished,
    IceHockeyIncidentId.FinishedAfterOvertime,
    IceHockeyIncidentId.FinishedAfterPenalties,
    IceHockeyIncidentId.Interrupted,
    CommonIncidentId.TechnicalProblems,
    IceHockeyIncidentId.Suspension,
    IceHockeyIncidentId.PenaltyShot,
    IceHockeyIncidentId.Goal,
    IceHockeyIncidentId.SuspensionOver,
    IceHockeyIncidentId.Stoppage,
    IceHockeyIncidentId.FaceOff,
    IceHockeyIncidentId.WinningFaceOff,
    IceHockeyIncidentId.Attack,
    IceHockeyIncidentId.DelayedPenalty,
    IceHockeyIncidentId.ShotBlocked,
    IceHockeyIncidentId.ShotOffTarget,
    IceHockeyIncidentId.ShotOnTarget,
    IceHockeyIncidentId.GoalkeeperOut,
    IceHockeyIncidentId.GoalkeeperIn,
    IceHockeyIncidentId.GoalkeeperChange,
    IceHockeyIncidentId.Timeout,
    IceHockeyIncidentId.EmptyNet,
    IceHockeyIncidentId.GoalkeeperSaved,
    IceHockeyIncidentId.TimeoutOver,
    IceHockeyIncidentId.BackGoalkeeper,
    IceHockeyIncidentId.MissedPenalty,
    CommonIncidentId.Betstart,
    CommonIncidentId.Betstop,
    IceHockeyIncidentId.FaceOffCenter,
    IceHockeyIncidentId.TelevisionTimeout,
    IceHockeyIncidentId.TelevisionTimeoutOver,
    IceHockeyIncidentId.UnderReview,
    IceHockeyIncidentId.Hit,
  ],
  iceHockeyParticipantIncidentIds: [
    IceHockeyIncidentId.Suspension,
    IceHockeyIncidentId.PenaltyShot,
    IceHockeyIncidentId.MissedPenalty,
    IceHockeyIncidentId.Goal,
    IceHockeyIncidentId.WinningFaceOff,
    IceHockeyIncidentId.ShotBlocked,
    IceHockeyIncidentId.ShotOffTarget,
    IceHockeyIncidentId.ShotOnTarget,
    IceHockeyIncidentId.GoalkeeperOut,
    IceHockeyIncidentId.GoalkeeperIn,
    IceHockeyIncidentId.Hit,
  ],
  iceHockeySecondParticipantIncidentIds: [IceHockeyIncidentId.Goal],
  iceHockeySecondDetailsIncidentIds: [
    IceHockeyIncidentId.Suspension,
    IceHockeyIncidentId.SuspensionOver,
    IceHockeyIncidentId.Goal,
    IceHockeyIncidentId.Stoppage,
    IceHockeyIncidentId.ShotOnTarget,
    IceHockeyIncidentId.MissedPenalty,
  ],
}
