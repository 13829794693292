<script lang="ts">
import { defineComponent } from 'vue'

import Button from '@mobile/components/Button/Button.vue'
import { getParticipantButtonColor } from '@mobile/components/Button/ButtonUtils'
import Expand from '@mobile/components/Expand.vue'
import SummaryParticipantName from '@mobile/components/Summary/SummaryParticipantName.vue'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

import { getDetailsPanelPocketIncidents } from './utils'

export default defineComponent({
  components: {
    IncidentButton,
    Button,
    Expand,
    SummaryParticipantName,
  },
  setup() {
    const {
      probableEvent,
      probableFirstParticipant,
      probableSecondParticipant,
      sidesSwitched,
    } = useEventInjections()
    const pocketIncidents = getDetailsPanelPocketIncidents(
      probableEvent.value.sport_id,
    )

    function changeSides(): void {
      sidesSwitched.value = !sidesSwitched.value
    }

    return {
      changeSides,
      pocketIncidents,
      probableFirstParticipant,
      probableSecondParticipant,
      getParticipantButtonColor,
    }
  },
})
</script>

<template>
  <div
    v-if="pocketIncidents && pocketIncidents.length"
    class="px-4"
  >
    <Expand
      class="bg-white"
      title="VIP+ incidents"
    >
      <div class="flex items-end justify-center space-x-8">
        <div class="flex flex-1 flex-col items-end">
          <SummaryParticipantName
            class="pb-2"
            :color="getParticipantButtonColor(probableFirstParticipant)"
            direction="right"
          >
            {{ probableFirstParticipant.short_name }}
          </SummaryParticipantName>
          <div class="flex space-x-2">
            <IncidentButton
              v-for="(incidentId, key) in pocketIncidents"
              :key="`details-panel-first-participant-pocket-incident-${key}`"
              class="min-w-28"
              :incidentId
              :participantId="probableFirstParticipant.id"
              size="md"
              type="vertical"
            />
          </div>
        </div>
        <div class="flex-2 pb-3">
          <Button
            size="xs"
            color="blue"
            @click="changeSides"
          >
            <FontAwesomeIcon
              class="mx-1"
              icon="sync"
            />
          </Button>
        </div>
        <div class="flex flex-1 flex-col items-start">
          <SummaryParticipantName
            class="pb-2"
            :color="getParticipantButtonColor(probableSecondParticipant)"
            direction="left"
          >
            {{ probableSecondParticipant.short_name }}
          </SummaryParticipantName>
          <div class="flex space-x-2">
            <IncidentButton
              v-for="(incidentId, key) in pocketIncidents"
              :key="`details-panel-second-participant-pocket-incident-${key}`"
              class="min-w-28"
              :incidentId
              :participantId="probableSecondParticipant.id"
              size="md"
              type="vertical"
            />
          </div>
        </div>
      </div>
    </Expand>
  </div>
</template>
