import { Ref } from 'vue'

type ReturnType = {
  convertSportsAPIPositionToOurPosition: (
    positionValue: number,
    pitchDimensionValue: number,
  ) => number
  convertOurPositionToSportsAPIPosition: (
    positionValue: number,
    pitchDimensionValue: number,
  ) => number
}

export function useBallPositionConverters(
  sidesSwitched: Ref<boolean>,
): ReturnType {
  function convertSportsAPIPositionToOurPosition(
    positionValue: number,
    pitchDimensionValue: number,
  ): number {
    let calculatedPosition = Math.round(
      (pitchDimensionValue * positionValue) / 100,
    )

    if (sidesSwitched.value) {
      calculatedPosition = pitchDimensionValue - calculatedPosition
    }

    return calculatedPosition
  }

  function convertOurPositionToSportsAPIPosition(
    positionValue: number,
    pitchDimensionValue: number,
  ): number {
    if (sidesSwitched.value) {
      positionValue = pitchDimensionValue - positionValue
    }

    const calculatedPosition = Math.round(
      (positionValue / pitchDimensionValue) * 100,
    )

    return calculatedPosition
  }

  return {
    convertOurPositionToSportsAPIPosition,
    convertSportsAPIPositionToOurPosition,
  }
}
