import { Container, interfaces } from 'inversify'

type SI<T> = interfaces.ServiceIdentifier<T>

export async function bindAllAsSync(
  asyncIoC: Container,
  syncIoC: Container,
  dependencyMap: Record<string, SI<unknown>>,
): Promise<Container> {
  const dependencies = await resolveAllDependencies(asyncIoC, dependencyMap)
  dependencies.forEach(({ dependencySymbol, value }) => {
    syncIoC
      .bind(dependencySymbol)
      .toDynamicValue(() => value)
      .inSingletonScope()
      .onDeactivation(() => {
        asyncIoC.unbindAsync(dependencySymbol)
      })
  })

  return syncIoC
}

async function resolveAllDependencies(
  asyncIoC: Container,
  dependencyMap: Record<string, SI<unknown>>,
): Promise<
  {
    dependencySymbol: SI<unknown>
    value: unknown
  }[]
> {
  const dependencyPromises: Promise<{
    dependencySymbol: SI<unknown>
    value: unknown
  }>[] = []
  Object.values(dependencyMap).forEach((dependencySymbol) => {
    dependencyPromises.push(
      asyncIoC.getAsync(dependencySymbol).then((value) => ({
        dependencySymbol,
        value,
      })),
    )
  })

  return Promise.all(dependencyPromises)
}
