import { Container } from 'inversify'

import {
  CollectorEventTime,
  EventStatusId,
  FreeKickAttribute,
  IncidentId,
  SoccerEventStatusId,
  SoccerIncidentId,
  TeamSide,
} from '@collector/sportsapi-client-legacy'
import { AddIncident } from '@mobile/ActionQueue/Actions/Actions'
import * as popup from '@mobile/globalState/popup'
import {
  byId,
  getOppositeTeam,
  getOrThrow,
} from '@mobile/reusables/entityUtils'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { IncidentHandler } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/IncidentHandlersConfiguration'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'
import { AddedTimePopup } from '@mobile/views/Relation/Sports/Soccer/Popups/AddedTime/AddedTimePopup'
import { CornerPopup } from '@mobile/views/Relation/Sports/Soccer/Popups/Corner/CornerPopup'
import { GoalPopup } from '@mobile/views/Relation/Sports/Soccer/Popups/Goal/GoalPopup'
import { InjuryPopup } from '@mobile/views/Relation/Sports/Soccer/Popups/Injury/InjuryPopup'
import { MissedPenaltyPopup } from '@mobile/views/Relation/Sports/Soccer/Popups/MissedPenalty/MissedPenaltyPopup'
import { PenaltyPopup } from '@mobile/views/Relation/Sports/Soccer/Popups/Penalty/PenaltyPopup'
import { PenaltySavedByGoalkeeperPopup } from '@mobile/views/Relation/Sports/Soccer/Popups/PenaltySavedByGoalkeeper/PenaltySavedByGoalkeeperPopup'
import { RedCardPopup } from '@mobile/views/Relation/Sports/Soccer/Popups/RedCard/RedCardPopup'
import ShotOffTargetPopup from '@mobile/views/Relation/Sports/Soccer/Popups/ShotOffTarget/ShotOffTargetPopup.vue'
import { SubstitutionPopup } from '@mobile/views/Relation/Sports/Soccer/Popups/Substitution/SubstitutionPopup'
import { VARPopup } from '@mobile/views/Relation/Sports/Soccer/Popups/VAR/VARPopup'
import { YellowCardPopup } from '@mobile/views/Relation/Sports/Soccer/Popups/YellowCard/YellowCardPopup'

import { ballPositionInRange } from '../LiveView/Pitch/resolvePossessionIncidentId'

export function bindIncidentHandlersConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.IncidentHandlersConfiguration)
    .toDynamicValue(async (ctx) => {
      const [
        actionQueue,
        probableEvent,
        probableClock,
        probableLinkedHomeParticipant,
        eventStatusesConfiguration,
      ] = await Promise.all([
        ctx.container.getAsync(RelationIoCBindings.ActionQueue),
        ctx.container.getAsync(RelationIoCBindings.ProbableEvent),
        ctx.container.getAsync(RelationIoCBindings.ProbableClock),
        ctx.container.getAsync(
          RelationIoCBindings.ProbableLinkedHomeParticipant,
        ),
        ctx.container.getAsync(
          RelationSportCommonIoCBindings.EventStatusesConfiguration,
        ),
      ])

      const addIncident: IncidentHandler['handler'] = ({
        incidentId,
        participantId,
        status,
        position,
        time = {
          minute: probableClock.value.minute,
          second: probableClock.value.second,
        },
        attributeIds = [],
      }) => {
        actionQueue.value.add(
          new AddIncident({
            incident_id: incidentId,
            status_id: status ?? probableEvent.value.status_id,
            participant_team_id: participantId,
            x_pos: position?.x
              ? Math.min(Math.max(position.x, 1), 100)
              : undefined,
            y_pos: position?.y
              ? Math.min(Math.max(position.y, 1), 100)
              : undefined,
            ...time,
            attribute_ids: attributeIds,
          }),
        )
      }

      const isDisabledByCurrentEventStatus = (
        incidentId: IncidentId,
      ): boolean => {
        const eventStatusConfiguration =
          eventStatusesConfiguration[probableEvent.value.status_id]

        return !eventStatusConfiguration?.allowedIncidents?.includes(incidentId)
      }

      const getTimeForEventStatus = (
        eventStatusId: EventStatusId,
      ): CollectorEventTime | null => {
        return getOrThrow(eventStatusesConfiguration, eventStatusId).time
      }

      return {
        [SoccerIncidentId.NotStarted]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(SoccerEventStatusId.NotStarted),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.MatchAboutToStart]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(SoccerEventStatusId.NotStarted),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.StartDelayed]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(SoccerEventStatusId.StartDelayed),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.FinishedAwardedWin]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(
                SoccerEventStatusId.FinishedAwardedWin,
              ),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.Postponed]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(SoccerEventStatusId.Postponed),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.Cancelled]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(SoccerEventStatusId.Cancelled),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.Abandoned]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(SoccerEventStatusId.Abandoned),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.FirstHalf]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(SoccerEventStatusId.FirstHalf),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.Halftime]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.SecondHalf]: {
          handler: ({ incidentId }) => {
            addIncident({
              incidentId,
              time: getTimeForEventStatus(SoccerEventStatusId.SecondHalf),
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.FinishedRegularTime]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.WaitingForExtratime]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.ExtratimeFirstHalf]: {
          handler: ({ incidentId }) => {
            addIncident({
              incidentId,
              time: getTimeForEventStatus(
                SoccerEventStatusId.ExtratimeFirstHalf,
              ),
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.ExtratimeHalftime]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(
                SoccerEventStatusId.ExtratimeHalftime,
              ),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.ExtratimeSecondHalf]: {
          handler: ({ incidentId }) => {
            addIncident({
              incidentId,
              time: getTimeForEventStatus(
                SoccerEventStatusId.ExtratimeSecondHalf,
              ),
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.FinishedAfterExtratime]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.WaitingForPenalty]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.PenaltyShootoutStarted]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.FinishedAfterPenalties]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.Interrupted]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(SoccerEventStatusId.Interrupted),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.ToFinish]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(SoccerEventStatusId.ToFinish),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.VideoAssistantReferee]: {
          handler: () =>
            popup.show({
              component: VARPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.RedCard]: {
          handler: () =>
            popup.show({
              component: RedCardPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.YellowCard]: {
          handler: () =>
            popup.show({
              component: YellowCardPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.Goal]: {
          handler: () =>
            popup.show({
              component: GoalPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.GoalKick]: {
          handler({ participantId }) {
            addIncident({
              incidentId: SoccerIncidentId.GoalKick,
              participantId,
            })
          },
          disabled: ({ participantId }) => !participantId,
        },
        [SoccerIncidentId.FreeKick]: {
          handler({ participantId, position, status, time }) {
            const freeKickParticipant = byId(
              probableEvent.value.participants,
              participantId,
            )
            if (!freeKickParticipant) {
              throw Error("Couldn't resolve participant")
            }

            const foulParticipant = getOppositeTeam(
              probableEvent.value.participants,
              freeKickParticipant,
            )
            if (!foulParticipant) {
              throw Error("Couldn't resolve opposite participant")
            }

            const attributeIds: number[] = []
            const possession: TeamSide =
              probableLinkedHomeParticipant.getParticipant().value.id ===
              freeKickParticipant.id
                ? TeamSide.Home
                : TeamSide.Away
            if (
              position &&
              possession &&
              ballPositionInRange(possession, position.x, 0, 30)
            ) {
              attributeIds.push(FreeKickAttribute.DangerousFreeKick)
            }

            addIncident({
              incidentId: SoccerIncidentId.Foul,
              participantId: foulParticipant.id,
              status,
              time,
            })
            addIncident({
              incidentId: SoccerIncidentId.FreeKick,
              participantId: freeKickParticipant.id,
              position,
              status,
              time,
              attributeIds,
            })
          },
          disabled: ({ participantId, position }) =>
            !participantId || !position,
        },
        [SoccerIncidentId.Foul]: {
          handler({ participantId, status, time }) {
            const foulParticipant = byId(
              probableEvent.value.participants,
              participantId,
            )

            if (!foulParticipant) {
              throw Error("Couldn't resolve foul participant")
            }

            const freeKickParticipant = getOppositeTeam(
              probableEvent.value.participants,
              foulParticipant,
            )

            if (!freeKickParticipant) {
              throw Error("Couldn't resolve free kick participant")
            }

            addIncident({
              incidentId: SoccerIncidentId.Foul,
              participantId: foulParticipant.id,
              status,
              time,
            })
            addIncident({
              incidentId: SoccerIncidentId.FreeKick,
              participantId: freeKickParticipant.id,
              status,
              time,
            })
          },
          disabled: ({ participantId }) => !participantId,
        },
        [SoccerIncidentId.Offside]: {
          handler({ participantId, status, position, time }) {
            const offsideParticipant = byId(
              probableEvent.value.participants,
              participantId,
            )
            if (!offsideParticipant) {
              throw Error("Couldn't resolve participant")
            }

            const freeKickParticipant = getOppositeTeam(
              probableEvent.value.participants,
              offsideParticipant,
            )
            if (!freeKickParticipant) {
              throw Error("Couldn't resolve opposite participant")
            }

            addIncident({
              incidentId: SoccerIncidentId.Offside,
              participantId: offsideParticipant.id,
              position,
              status,
              time,
            })
            addIncident({
              incidentId: SoccerIncidentId.FreeKick,
              participantId: freeKickParticipant.id,
              position,
              status,
              time,
            })
          },
          disabled: ({ participantId, position }) =>
            !participantId || !position,
        },
        [SoccerIncidentId.ShotOffTarget]: {
          handler({ participantId, incidentId, position, status, time }) {
            if (participantId && status && time) {
              addIncident({
                incidentId,
                participantId,
                position,
                status,
                time,
              })
            } else {
              popup.show({
                component: ShotOffTargetPopup,
                showHeader: true,
                props: {
                  participantId,
                  position,
                },
              })
            }
          },
          disabled: ({ participantId, position }) =>
            !participantId || !position,
        },
        [SoccerIncidentId.ShotOnTarget]: {
          handler: addIncident,
          disabled: ({ participantId, position }) =>
            !participantId || !position,
        },
        [SoccerIncidentId.ShotBlocked]: {
          handler: addIncident,
          disabled: ({ participantId, position }) =>
            !participantId || !position,
        },
        [SoccerIncidentId.ThrowIn]: {
          handler({
            participantId,
            position: clickedPosition,
            incidentId,
            status,
            time,
          }) {
            const position = clickedPosition
              ? {
                  x: clickedPosition.x,
                  y: clickedPosition.y > 50 ? 100 : 1,
                }
              : undefined

            addIncident({
              incidentId,
              participantId,
              position,
              status,
              time,
            })
          },
          disabled: ({ participantId, position }) =>
            !participantId || !position,
        },
        [SoccerIncidentId.Penalty]: {
          handler: () =>
            popup.show({
              component: PenaltyPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.Corner]: {
          handler: () =>
            popup.show({
              component: CornerPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.AddedTime]: {
          handler: () =>
            popup.show({
              component: AddedTimePopup,
              showHeader: true,
            }),
          disabled: () => {
            return ![
              SoccerEventStatusId.FirstHalf,
              SoccerEventStatusId.SecondHalf,
              SoccerEventStatusId.ExtratimeFirstHalf,
              SoccerEventStatusId.ExtratimeSecondHalf,
            ].includes(probableEvent.value.status_id as SoccerEventStatusId)
          },
        },
        [SoccerIncidentId.Injury]: {
          handler: ({ participantId, incidentId, time, status }) => {
            if (participantId && time && status) {
              addIncident({
                participantId,
                incidentId,
                status,
                time,
              })
            } else {
              popup.show({
                component: InjuryPopup,
                showHeader: true,
              })
            }
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.SubstitutionOut]: {
          handler: ({ participantId, time, status }) => {
            if (participantId && time && status) {
              addIncident({
                participantId,
                incidentId: SoccerIncidentId.SubstitutionOut,
                status,
                time,
              })
              addIncident({
                participantId,
                incidentId: SoccerIncidentId.SubstitutionIn,
                status,
                time,
              })
            } else {
              popup.show({
                component: SubstitutionPopup,
                showHeader: true,
              })
            }
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.SubstitutionIn]: {
          handler: () =>
            popup.show({
              component: SubstitutionPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.KickOff]: {
          handler({ participantId }) {
            addIncident({
              incidentId: SoccerIncidentId.KickOff,
              participantId,
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.PenaltySavedByGoalkeeper]: {
          handler: () =>
            popup.show({
              component: PenaltySavedByGoalkeeperPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [SoccerIncidentId.MissedPenalty]: {
          handler: () =>
            popup.show({
              component: MissedPenaltyPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        fallbackHandler: {
          handler: ({ incidentId, participantId, time, status }) =>
            addIncident({
              incidentId,
              participantId,
              time,
              status,
            }),
          disabled: ({ incidentId: _incidentId }) => false,
        },
      }
    })
    .inSingletonScope()
}
