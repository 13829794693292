export enum BeachVolleyballIncidentId {
  Point = 972,
  AttackPoint = 967,
  BlockPoint = 968,
  ServiceAce = 976,
  Serve = 975,
  ServiceError = 977,
  Challenge = 990,
  ChallengeCorrect = 991,
  ChallengeIncorrect = 992,
  Timeout = 982,
  YellowCard = 983,
  SetBall = 979,
  SetWon = 981,
  MatchBall = 971,
  Interrupted = 959,
  Cancelled = 956,
  Postponed = 960,
  StartDelayed = 961,
  Abandoned = 955,
  Finished = 957,
  FinishedAwardedWin = 986,
  FirstSetStared = 950,
  SecondSetStarted = 951,
  ThirdSetStarted = 952,
  RedCard = 984,
  ToFinish = 962,
  GoldenSetStart = 953,
  SetBreak = 954,
  AttackError = 966,
  TechnicalErrors = 978,
  TechnicalTimeout = 964,
  InPossession = 2765,
}
