import { Container } from 'inversify'
import { computed } from 'vue'

import { getAwayTeam, getHomeTeam } from '@mobile/reusables/entityUtils'

import { RelationIoCBindings } from './types'

export function bindParticipants(ioc: Container): void {
  ioc
    .bind(RelationIoCBindings.FactualHomeParticipant)
    .toDynamicValue(async (ctx) => {
      const event = await ctx.container.getAsync(
        RelationIoCBindings.FactualEvent,
      )

      return computed(() => {
        const homeParticipant = getHomeTeam(event.value.data.participants)
        if (!homeParticipant) {
          throw new Error("Couldn't bind home participant")
        }

        return homeParticipant
      })
    })
    .inSingletonScope()

  ioc
    .bind(RelationIoCBindings.FactualAwayParticipant)
    .toDynamicValue(async (ctx) => {
      const event = await ctx.container.getAsync(
        RelationIoCBindings.FactualEvent,
      )

      return computed(() => {
        const awayParticipant = getAwayTeam(event.value.data.participants)
        if (!awayParticipant) {
          throw new Error("Couldn't bind away participant")
        }

        return awayParticipant
      })
    })
}
