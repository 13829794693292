import { BeachVolleyballIncidentId } from './IncidentId'

export const BeachVolleyballIncidentAcronymMap: {
  [key in BeachVolleyballIncidentId]: string
} = {
  [BeachVolleyballIncidentId.Interrupted]: 'INT',
  [BeachVolleyballIncidentId.Cancelled]: 'CAN',
  [BeachVolleyballIncidentId.Postponed]: 'POST',
  [BeachVolleyballIncidentId.StartDelayed]: 'SD',
  [BeachVolleyballIncidentId.Abandoned]: 'ABO',
  [BeachVolleyballIncidentId.Finished]: 'FIN',
  [BeachVolleyballIncidentId.FinishedAwardedWin]: 'FAW',
  [BeachVolleyballIncidentId.FirstSetStared]: '1SET',
  [BeachVolleyballIncidentId.SecondSetStarted]: '2SET',
  [BeachVolleyballIncidentId.ThirdSetStarted]: '3SET',
  [BeachVolleyballIncidentId.ServiceError]: 'SE',
  [BeachVolleyballIncidentId.AttackError]: 'AE',
  [BeachVolleyballIncidentId.TechnicalErrors]: 'TE',
  [BeachVolleyballIncidentId.Point]: 'PT',
  [BeachVolleyballIncidentId.ServiceAce]: 'SA',
  [BeachVolleyballIncidentId.AttackPoint]: 'AP',
  [BeachVolleyballIncidentId.BlockPoint]: 'BP',
  [BeachVolleyballIncidentId.Serve]: 'SER',
  [BeachVolleyballIncidentId.SetBall]: 'SETB',
  [BeachVolleyballIncidentId.SetWon]: 'SEW',
  [BeachVolleyballIncidentId.MatchBall]: 'MATCHB',
  [BeachVolleyballIncidentId.Challenge]: 'CHA',
  [BeachVolleyballIncidentId.ChallengeCorrect]: 'CHACOR',
  [BeachVolleyballIncidentId.ChallengeIncorrect]: 'CHAINC',
  [BeachVolleyballIncidentId.Timeout]: 'TO',
  [BeachVolleyballIncidentId.GoldenSetStart]: 'GOLDSET',
  [BeachVolleyballIncidentId.ToFinish]: 'TFIN',
  [BeachVolleyballIncidentId.YellowCard]: 'YC',
  [BeachVolleyballIncidentId.RedCard]: 'RC',
  [BeachVolleyballIncidentId.TechnicalTimeout]: 'TECT',
  [BeachVolleyballIncidentId.SetBreak]: 'SB',
  [BeachVolleyballIncidentId.InPossession]: 'IN',
}
