import { Container } from 'inversify'
import { ref, Ref, watch } from 'vue'

import { Action, ActionStatus } from '@mobile/ActionQueue/Action'
import { ActionQueue } from '@mobile/ActionQueue/ActionQueue'
import {
  deserializeQueueAction,
  serializeQueueAction,
} from '@mobile/ActionQueue/ActionUtils'
import { QueueActions } from '@mobile/database/QueueActionsRepository'
import * as app from '@mobile/globalState/app'

import { RelationIoCBindings } from './types'

export function bindActionQueue(ioc: Container): void {
  let unbindIndexedDBSnapshots: () => void

  ioc
    .bind(RelationIoCBindings.ActionQueue)
    .toDynamicValue(async (ctx) => {
      const event = await ctx.container.getAsync(
        RelationIoCBindings.FactualEvent,
      )
      const eventId = event.value.eventId
      const sport = await ctx.container.getAsync(RelationIoCBindings.Sport)

      const actionQueue = new ActionQueue(
        event.value.data,
        sport.value.sportsAPIEntity,
      )

      const queueActions =
        await app.state.database.queueActions.getByEventId(eventId)
      if (queueActions) {
        try {
          // @TODO test if queue restores properly and if it has any negative effects on
          // things that were triggered by manual insertion
          restoreQueue(actionQueue, queueActions)
        } catch {
          app.state.database.queueActions.removeByEventId(eventId)
        }
      }

      return ref(actionQueue) as Ref<ActionQueue>
    })
    .inSingletonScope()
    .onActivation(async (ctx, actionQueue) => {
      const event = await ctx.container.getAsync(
        RelationIoCBindings.FactualEvent,
      )
      const eventId = event.value.eventId

      unbindIndexedDBSnapshots = watch(
        actionQueue,
        () => {
          // This watch can trigger in one event loop tick trying to modify
          // same record which causes some issues with Dexie. We need to
          // make sure that each DB change is triggered in separate tick
          window.setTimeout(() => {
            app.state.database.queueActions.put({
              eventId,
              serializedActions: actionQueue.value.queue.map((action) =>
                serializeQueueAction(action),
              ),
            })
          })
        },
        { deep: true },
      )

      return actionQueue
    })
    .when(() => true)
    .onDeactivation(async () => {
      unbindIndexedDBSnapshots()
    })
    .when(() => true)
}

function restoreQueue(
  actionQueue: ActionQueue,
  queueActions: QueueActions,
): void {
  if (actionQueue && queueActions && queueActions.serializedActions.length) {
    const actions: Action<unknown>[] = []
    for (const queueAction of queueActions.serializedActions) {
      try {
        actions.push(deserializeQueueAction(queueAction))
      } catch (e) {
        /* eslint-disable-next-line no-console */
        console.info(e)
      }
    }

    const firstAction = actions[0]
    if (firstAction) {
      actionQueue.pause()

      if (firstAction.status === ActionStatus.InProgress) {
        firstAction.status = ActionStatus.Unknown
      }
    }

    actions.forEach((action) => {
      actionQueue.add(action)
    })
  }
}
