<script lang="ts">
import { defineComponent } from 'vue'

import { opChain } from '@collector/shared-utils'
import { BasketballIncidentId } from '@collector/sportsapi-client-legacy'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'

import { useBasketBallConfiguration } from '../../BasketballRelationDependencies/useBasketBallConfiguration'

export default defineComponent({
  components: { IncidentButton },
  setup() {
    const { activeParticipant, inactiveParticipant } =
      useBasketBallConfiguration()

    return {
      activeParticipant,
      inactiveParticipant,
      BasketballIncidentId,
      opChain,
    }
  },
})
</script>

<template>
  <div class="grid gap-4">
    <IncidentButton
      class="h-full"
      :type="'vertical'"
      :size="'xs'"
      :color="'white'"
      :incidentId="BasketballIncidentId.Foul"
    />
    <IncidentButton
      class="h-full"
      :type="'vertical'"
      :size="'xs'"
      :color="'white'"
      :participantId="opChain(activeParticipant.participant, (v) => v.id)"
      :incidentId="BasketballIncidentId.Turnover"
    >
      TO
    </IncidentButton>
    <IncidentButton
      class="h-full"
      :type="'vertical'"
      :size="'xs'"
      :color="'white'"
      :participantId="opChain(inactiveParticipant.participant, (v) => v.id)"
      :incidentId="BasketballIncidentId.Steal"
    />
  </div>
  <div class="grid gap-4">
    <IncidentButton
      class="col-span-2 h-full"
      :type="'horizontal'"
      :size="'xs'"
      :participantId="opChain(activeParticipant.participant, (v) => v.id)"
      :incidentId="BasketballIncidentId.TwoPointShotMade"
    >
      <div class="flex w-full items-center justify-between">
        <span>2pts</span>
        <FontAwesomeIcon
          class="text-green text-lg"
          icon="check"
        />
      </div>
    </IncidentButton>
    <IncidentButton
      class="col-span-2 h-full"
      :type="'horizontal'"
      :size="'xs'"
      :color="'white'"
      :participantId="opChain(activeParticipant.participant, (v) => v.id)"
      :incidentId="BasketballIncidentId.ThreePointShotMade"
    >
      <div class="flex w-full items-center justify-between">
        <span>3pts</span>
        <FontAwesomeIcon
          class="text-green text-lg"
          icon="check"
        />
      </div>
    </IncidentButton>
    <IncidentButton
      class="col-span-2 h-full"
      :type="'horizontal'"
      :size="'xs'"
      :color="'white'"
      :participantId="opChain(activeParticipant.participant, (v) => v.id)"
      :incidentId="BasketballIncidentId.OnePointShotMade"
    >
      <div class="flex w-full items-center justify-between">
        <span>1pt</span>
        <FontAwesomeIcon
          class="text-green text-lg"
          icon="check"
        />
      </div>
    </IncidentButton>
  </div>
</template>
