<script setup lang="ts">
import { getRandomHtmlId } from '@collector/shared-utils'

import { vAutofocus } from '../directives'

// props
interface Props {
  label: string
  rows: number
  autofocus: boolean
  placeholder?: string
}

const props = withDefaults(defineProps<Props>(), { autofocus: false })

// data
const id = getRandomHtmlId()
const text = defineModel<string>()
</script>

<template>
  <div>
    <label
      class="text-xs"
      :for="id"
    >
      {{ props.label }}
    </label>
    <!-- TODO: if component will be used in desktop, sm:,md:,lg: breakpoint classes must be extracted and passed as prop: @sm or sm: -->
    <textarea
      :id
      v-model="text"
      v-autofocus="autofocus"
      class="border-silver min-h-10 w-full resize-none border border-solid bg-white px-2 sm:max-h-24 md:max-h-24 lg:max-h-24"
      :placeholder
      :rows
    ></textarea>
  </div>
</template>
