import { VolleyballIncidentId } from './IncidentId'

export const VolleyballIncidentAcronymMap: {
  [key in VolleyballIncidentId]: string
} = {
  [VolleyballIncidentId.NotStarted]: 'NS',
  [VolleyballIncidentId.Interrupted]: 'INT',
  [VolleyballIncidentId.Cancelled]: 'CAN',
  [VolleyballIncidentId.Postponed]: 'POST',
  [VolleyballIncidentId.StartDelayed]: 'SD',
  [VolleyballIncidentId.Abandoned]: 'ABO',
  [VolleyballIncidentId.Finished]: 'FIN',
  [VolleyballIncidentId.FinishedAwardedWin]: 'FAW',
  [VolleyballIncidentId.FirstSetStared]: '1SET',
  [VolleyballIncidentId.SecondSetStarted]: '2SET',
  [VolleyballIncidentId.ThirdSetStarted]: '3SET',
  [VolleyballIncidentId.FourthSetStarted]: '4SET',
  [VolleyballIncidentId.FifthSetStarted]: '5SET',
  [VolleyballIncidentId.BreakAfterFirstSet]: '1BR',
  [VolleyballIncidentId.BreakAfterSecondSet]: '2BR',
  [VolleyballIncidentId.BreakAfterThirdSet]: '3BR',
  [VolleyballIncidentId.BreakAfterFourthSet]: '4BR',
  [VolleyballIncidentId.BreakAfterFifthSet]: '5BR',
  [VolleyballIncidentId.ServiceError]: 'SE',
  [VolleyballIncidentId.AttackError]: 'AE',
  [VolleyballIncidentId.TechnicalErrors]: 'TE',
  [VolleyballIncidentId.Point]: 'PT',
  [VolleyballIncidentId.ServiceAce]: 'SA',
  [VolleyballIncidentId.AttackPoint]: 'AP',
  [VolleyballIncidentId.BlockPoint]: 'BP',
  [VolleyballIncidentId.Serve]: 'SER',
  [VolleyballIncidentId.SetBall]: 'SETB',
  [VolleyballIncidentId.SetWon]: 'SEW',
  [VolleyballIncidentId.MatchBall]: 'MATCHB',
  [VolleyballIncidentId.Challenge]: 'CHA',
  [VolleyballIncidentId.Timeout]: 'TO',
  [VolleyballIncidentId.MatchAboutToStart]: 'MATS',
  [VolleyballIncidentId.InPossession]: 'IP',
  [VolleyballIncidentId.GoldenSetStart]: 'GOLDSET',
  [VolleyballIncidentId.ToFinish]: 'TFIN',
  [VolleyballIncidentId.RepeatAction]: 'REPACT',
  [VolleyballIncidentId.BreakDuringTheGame]: 'BDTG',
  [VolleyballIncidentId.YellowCard]: 'YC',
  [VolleyballIncidentId.RedCard]: 'RC',
  [VolleyballIncidentId.RefereeChallenge]: 'REFC',
  [VolleyballIncidentId.TechnicalTimeout]: 'TECT',
  [VolleyballIncidentId.TimeoutOver]: 'TOO',
  [VolleyballIncidentId.TechnicalTimeoutOver]: 'TTOO',
  [VolleyballIncidentId.Cards]: 'CARDS',
}
