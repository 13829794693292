import { HandballIncidentId } from './IncidentId'

export const HandballIncidentAcronymMap: {
  [key in HandballIncidentId]: string
} = {
  [HandballIncidentId.NotStarted]: 'NST',
  [HandballIncidentId.StartDelayed]: 'STDE',
  [HandballIncidentId.MatchAboutToStart]: 'MATS',
  [HandballIncidentId.FirstHalfStarted]: '1ST',
  [HandballIncidentId.Halftime]: 'HT',
  [HandballIncidentId.SecondHalfStarted]: '2ND',
  [HandballIncidentId.WaitingForExtratime]: 'WFE',
  [HandballIncidentId.FirstExtratimeFirstHalfStarted]: '1STE1ST',
  [HandballIncidentId.ExtratimeHalftime]: '1STEHT',
  [HandballIncidentId.FirstExtratimeSecondHalfStarted]: '1STE2ND',
  [HandballIncidentId.WaitingForSecondExtratime]: 'WF2NDE',
  [HandballIncidentId.SecondExtratimeFirstHalfStarted]: '2NDE1ST',
  [HandballIncidentId.SecondExtratimeHalftime]: '2NDEHT',
  [HandballIncidentId.SecondExtratimeSecondHalfStarted]: '2NDE2ND',
  [HandballIncidentId.WaitingForPenalties]: 'WFP',
  [HandballIncidentId.PenaltyShootoutStarted]: 'PSS',
  [HandballIncidentId.FinishedRegularTime]: 'FT',
  [HandballIncidentId.FinishedAfterExtratime]: 'FAE',
  [HandballIncidentId.FinishedAfterPenalties]: 'FAP',
  [HandballIncidentId.FinishedAwardedWin]: 'FAW',
  [HandballIncidentId.Interrupted]: 'INT',
  [HandballIncidentId.Postponed]: 'POS',
  [HandballIncidentId.Abandoned]: 'ABA',
  [HandballIncidentId.Cancelled]: 'CAN',
  [HandballIncidentId.ToFinish]: 'TOF',
  [HandballIncidentId.RefereeInjury]: 'RI',
  [HandballIncidentId.BreakDuringGame]: 'BDG',
  [HandballIncidentId.RefereeConsultation]: 'RC',
  [HandballIncidentId.InPossession]: 'INP',
  [HandballIncidentId.Goal]: 'GOAL',
  [HandballIncidentId.SuspensionOver]: 'SO',
  [HandballIncidentId.StartWithPossession]: 'SWP',
  [HandballIncidentId.TimeoutOver]: 'TO',
  [HandballIncidentId.MissedThrow]: 'MT',
  [HandballIncidentId.ThrowSaved]: 'TS',
  [HandballIncidentId.Woodwork]: 'SW',
  [HandballIncidentId.ShotBlocked]: 'SB',
  [HandballIncidentId.Foul]: 'FOUL',
  [HandballIncidentId.OffensiveFoul]: 'OF',
  [HandballIncidentId.Penalty]: 'PEN',
  [HandballIncidentId.PenaltyGoal]: 'PG',
  [HandballIncidentId.PenaltySaved]: 'PS',
  [HandballIncidentId.MissedPenalty]: 'MSP',
  [HandballIncidentId.GoalDisallowed]: 'GOALDISALLOWED',
  [HandballIncidentId.PassivePlay]: 'PP',
  [HandballIncidentId.Injury]: 'INJ',
  [HandballIncidentId.Timeout]: 'TO',
  [HandballIncidentId.Turnover]: 'TRN',
  [HandballIncidentId.SteppingTheLine]: 'STL',
  [HandballIncidentId.Travelling]: 'TRAV',
  [HandballIncidentId.DefenceInGoalArea]: 'DINGA',
  [HandballIncidentId.YellowCard]: 'YCAR',
  [HandballIncidentId.RedCard]: 'RCAR',
  [HandballIncidentId.BlueCard]: 'BCAR',
  [HandballIncidentId.TwoMinuteSuspension]: '2MINSUS',
  [HandballIncidentId.PlayersInPlay]: 'PINPLAY',
  [HandballIncidentId.SixPlayersInPlay]: '6PINPLAY',
  [HandballIncidentId.FivePlayersInPlay]: '5PINPLAY',
  [HandballIncidentId.FourPlayersInPlay]: '4PINPLAY',
  [HandballIncidentId.EmptyNet]: 'ENET',
  [HandballIncidentId.GoalKeeperBack]: 'GKBACK',
  [HandballIncidentId.FastAttack]: 'FT',
}
