import { Container } from 'inversify'

import {
  possessionIncidents,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'
import { eventBus } from '@mobile/globalState/eventBus'
import { HookErrors } from '@mobile/views/Relation/Shared/Hooks/HookErrors'
import { HookForceUpdate } from '@mobile/views/Relation/Shared/Hooks/HookForceUpdate'
import { HookQueueHasUnconfirmedIncident } from '@mobile/views/Relation/Shared/Hooks/HookQueueHasUnconfirmedIncident'
import { HookQueueNotifications } from '@mobile/views/Relation/Shared/Hooks/HookQueueNotifications'
import { HookScoutMessengerConnectionStatus } from '@mobile/views/Relation/Shared/Hooks/HookScoutMessengerConnectionStatus'
import { HookSidesCheck } from '@mobile/views/Relation/Shared/Hooks/HookSidesCheck'
import { HookSystemTimeValidation } from '@mobile/views/Relation/Shared/Hooks/HookSystemTimeValidation'
import { HookUnconfirmedIncidentsReminder } from '@mobile/views/Relation/Shared/Hooks/HookUnconfirmedIncidentReminder'
import { HookUnconfirmedIncidents } from '@mobile/views/Relation/Shared/Hooks/HookUnconfirmedIncidents'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

const notifyOnIncidentIds = [
  SoccerIncidentId.MatchAboutToStart,
  SoccerIncidentId.SecondHalf,
  SoccerIncidentId.ExtratimeFirstHalf,
  SoccerIncidentId.ExtratimeSecondHalf,
]

export function bindRelationHooks(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.EventNotificationHooks)
    .toDynamicValue(async (ctx) => {
      const [
        notifications,
        actionQueue,
        notDeletableProbableIncidents,
        incidentsConfiguration,
        linkedProbableIncidents,
      ] = await Promise.all([
        ctx.container.getAsync(RelationIoCBindings.Notifications),
        ctx.container.getAsync(RelationIoCBindings.ActionQueue),
        ctx.container.getAsync(
          RelationIoCBindings.ProbableNotDeletableIncidents,
        ),
        ctx.container.getAsync(
          RelationSportCommonIoCBindings.IncidentsConfiguration,
        ),
        ctx.container.getAsync(RelationIoCBindings.ProbableLinkedIncidents),
      ])

      const hooks = [
        new HookErrors(notifications, eventBus),
        new HookSystemTimeValidation(notifications),
        new HookScoutMessengerConnectionStatus(notifications),
        new HookQueueNotifications(notifications, actionQueue.value),
        new HookUnconfirmedIncidents(notifications),
        new HookSidesCheck(
          notifications,
          actionQueue.value,
          notDeletableProbableIncidents,
          notifyOnIncidentIds,
          incidentsConfiguration,
        ),
        new HookForceUpdate(notifications),
        new HookQueueHasUnconfirmedIncident(
          actionQueue.value,
          linkedProbableIncidents,
          notDeletableProbableIncidents,
          possessionIncidents,
          incidentsConfiguration,
        ),
        new HookUnconfirmedIncidentsReminder(
          actionQueue.value,
          linkedProbableIncidents,
          notDeletableProbableIncidents,
          possessionIncidents,
          incidentsConfiguration,
        ),
      ]

      return hooks
    })
    .inSingletonScope()
}
