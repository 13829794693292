import { IncidentActionType } from '@collector/sportsapi-client-legacy'
import { getCurrentEvent } from '@mobile/globalState/event'
import { getCurrentSport } from '@mobile/globalState/sport'
import * as sportsAPI from '@mobile/globalState/sportsAPI'
import { IncidentsConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/IncidentsConfiguration'

import IncidentAction, { ProbableData } from './IncidentAction'
import IncidentId from './IncidentId'
import { isActionId, isIncidentId } from './ProbableIncident'

export default class DeleteIncident extends IncidentAction {
  public readonly type = 'DeleteIncident'

  constructor(actionId: string)
  constructor(incidentId: string)
  constructor(public override readonly actionOrIncidentId: string) {
    super()

    if (isIncidentId(actionOrIncidentId)) {
      this.incidentId = actionOrIncidentId
    } else if (isActionId(actionOrIncidentId)) {
      this.dependencies.push(actionOrIncidentId)
    }
  }

  public async execImpl(): Promise<string> {
    if (!this.incidentId) {
      throw Error(
        'Previous action needs to be completed before executing this one',
      )
    }

    const event = getCurrentEvent()
    const result = await sportsAPI.state.client.deleteIncident(
      event.id,
      IncidentId.fromPushMessageFormat(this.incidentId),
    )

    return result.incident.data.id
  }

  public applyProbableResult(
    { probableEvent, probableIncidents }: ProbableData,
    incidentsConfiguration: IncidentsConfiguration,
  ): void {
    const incident = probableIncidents.find(
      (probableIncident) => probableIncident.id === this.actionOrIncidentId,
    )
    const sport = getCurrentSport()

    if (probableEvent && incident) {
      const incidentConfiguration =
        incidentsConfiguration[incident.incident_id] ??
        incidentsConfiguration.fallbackIncidentConfiguration
      incidentConfiguration.applyDeleteIncidentResultToEvent?.(
        probableEvent,
        sport,
        incident,
      )
    }

    if (incident) {
      incident.action = IncidentActionType.DELETE
    }
  }
}
