import { Event, SportId, StatId } from '@collector/sportsapi-client-legacy'
import { CollectorSport } from '@mobile/reusables/classes/CollectorSport'
import { byId } from '@mobile/reusables/entityUtils'

import { eventPreparationEventStatsCricketFactory } from './eventPreparationEventStatsCricketFactory'
import { eventPreparationEventStatsDefaultFactory } from './eventPreparationEventStatsDefaultFactory'
import { EventPreparationEventStatsSportFactory } from './types'

const factories: {
  [key: number]: (
    event: Event,
    sport: CollectorSport,
  ) => EventPreparationEventStatsSportFactory | undefined
} = { [SportId.Cricket]: eventPreparationEventStatsCricketFactory }

export function getStatName(sport: CollectorSport, statId: StatId): string {
  return byId(sport.sportsAPIEntity.stats.team, statId)?.name ?? 'Unknown'
}

export function useEventPreparationEventStatsFactory(
  event: Event,
  sport: CollectorSport,
): EventPreparationEventStatsSportFactory {
  if (factories?.[sport.sportsAPIEntity.id] === undefined) {
    return eventPreparationEventStatsDefaultFactory()
  }

  return (
    factories[sport.sportsAPIEntity.id](event, sport) ??
    eventPreparationEventStatsDefaultFactory()
  )
}
