import { Basketball3x3IncidentId } from './IncidentId'

export const Basketball3x3IncidentAcronymMap: {
  [key in Basketball3x3IncidentId]: string
} = {
  [Basketball3x3IncidentId.NotStarted]: 'NST',
  [Basketball3x3IncidentId.StartDelayed]: 'SD',
  [Basketball3x3IncidentId.MatchAboutToStart]: 'MTS',
  [Basketball3x3IncidentId.FinishedRegularTime]: 'FRT',
  [Basketball3x3IncidentId.WaitingForOvertime]: 'WFO',
  [Basketball3x3IncidentId.FinishedAfterOvertime]: 'FAO',
  [Basketball3x3IncidentId.FinishedAwardedWin]: 'FAW',
  [Basketball3x3IncidentId.Interrupted]: 'INT',
  [Basketball3x3IncidentId.Postponed]: 'POS',
  [Basketball3x3IncidentId.Cancelled]: 'CAN',
  [Basketball3x3IncidentId.Abandoned]: 'ABD',
  [Basketball3x3IncidentId.ToFinish]: 'FIN',
  [Basketball3x3IncidentId.TwoPointShotMissed]: 'MTP',
  [Basketball3x3IncidentId.OnePointShotMissed]: 'MOP',
  [Basketball3x3IncidentId.OnePointShotMade]: 'OPSM',
  [Basketball3x3IncidentId.TwoPointShotMade]: 'TPSM',
  [Basketball3x3IncidentId.Foul]: 'FOU',
  [Basketball3x3IncidentId.Block]: 'BLK',
  [Basketball3x3IncidentId.Steal]: 'STL',
  [Basketball3x3IncidentId.DefensiveRebound]: 'DR',
  [Basketball3x3IncidentId.OffensiveRebound]: 'OF',
  [Basketball3x3IncidentId.Turnover]: 'TO',
  [Basketball3x3IncidentId.ThrowIn]: 'TI',
  [Basketball3x3IncidentId.FreeThrowsAwarded]: 'FTA',
  [Basketball3x3IncidentId.FoulBonus]: 'FB',
  [Basketball3x3IncidentId.InPossession]: 'IP',
  [Basketball3x3IncidentId.Timeout]: 'TI',
  [Basketball3x3IncidentId.TimeoutOver]: 'TO',
  [Basketball3x3IncidentId.JumpBall]: 'JB',
  [Basketball3x3IncidentId.PlayUnderReview]: 'PUR',
  [Basketball3x3IncidentId.Injury]: 'INJ',
  [Basketball3x3IncidentId.RefereeInjury]: 'RINJ',
  [Basketball3x3IncidentId.RegularTimeStarted]: 'RTS',
  [Basketball3x3IncidentId.Overtime]: 'OVT',
  [Basketball3x3IncidentId.TVTimeout]: 'TVT',
  [Basketball3x3IncidentId.TVTimeoutOver]: 'TVTO',
  [Basketball3x3IncidentId.BreakDuringTheGame]: 'BDG',
  [Basketball3x3IncidentId.CoinFlip]: 'CF',
  [Basketball3x3IncidentId.FreeThrowIn]: 'FTI',
  [Basketball3x3IncidentId.FreeThrowMissed]: 'FTM',
  [Basketball3x3IncidentId.Challenge]: 'CHAL',
  [Basketball3x3IncidentId.StartWithABall]: 'SWB',
}
