<script lang="ts">
import dayjs from 'dayjs'
import { groupBy, sortBy } from 'lodash'
import { computed, defineComponent, ref } from 'vue'

import { CoverageType, SportId } from '@collector/sportsapi-client-legacy'
import Tabs from '@mobile/components/Tabs/Tabs.vue'
import { useEventsScoutsAssignationMap } from '@mobile/database/useEventsScoutsAssignationMap'
import * as app from '@mobile/globalState/app'
import * as scoutMessenger from '@mobile/globalState/scoutMessenger'
import * as sportsAPI from '@mobile/globalState/sportsAPI'
import { useSynchronizedEvents } from '@mobile/reusables/useSynchronizedEvents'
import { useLogout } from '@mobile/views/Login/useLogout'

import Event from './Event.vue'
import EventsListHeader from './EventsListHeader.vue'

enum EventTypeTabs {
  MyEvents = 'My events',
  FromVenue = 'From venue',
}

export default defineComponent({
  name: 'EventsList',
  components: {
    Event,
    EventsListHeader,
    Tabs,
  },
  setup() {
    const { logout } = useLogout()
    const { events: synchronizedEvents } = useSynchronizedEvents(
      app.state.database,
    )
    const eventTypesTabs = ref<EventTypeTabs[]>([
      EventTypeTabs.MyEvents,
      EventTypeTabs.FromVenue,
    ])
    const currentEventTypeTab = ref(EventTypeTabs.MyEvents)
    const assignedScouts = useEventsScoutsAssignationMap(app.state.database)

    const events = computed(() => {
      return synchronizedEvents.value?.filter(({ data }) => {
        const { sport_id: sportId, coverage_type: coverageType } = data
        const eventsScouts = assignedScouts.value[data.id]

        const isAssignedToEvent = eventsScouts?.includes(
          sportsAPI.state.authInfo?.id ?? 0,
        )

        const sportFilter = [
          SportId.Soccer,
          SportId.Handball,
          SportId.Cricket,
          SportId.Volleyball,
          SportId.Basketball,
          SportId.BeachVolleyball,
          SportId.AmericanFootball,
          SportId.Basketball3x3,
          SportId.IceHockey,
        ].includes(sportId)
        const coverageFilter =
          currentEventTypeTab.value === EventTypeTabs.MyEvents
            ? true
            : coverageType === CoverageType.FromVenue

        return isAssignedToEvent && sportFilter && coverageFilter
      })
    })
    const groupedEvents = computed(() =>
      groupBy(
        sortBy(events.value, (event) => event.data.start_date).reverse(),
        (event) =>
          dayjs(event.data.start_date, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD'),
      ),
    )
    const isSynchronizing = computed(
      () => !scoutMessenger.state.isInitialSynchronizationFinished,
    )

    const onLogoutClick = (): void => {
      logout()
      currentEventTypeTab.value = EventTypeTabs.MyEvents
    }

    return {
      events,
      groupedEvents,
      isSynchronizing,
      eventTypesTabs,
      currentEventTypeTab,
      onLogoutClick,
    }
  },
})
</script>

<template>
  <div class="bg-silver min-h-full w-full">
    <div class="sticky top-0 z-50 w-full">
      <EventsListHeader :onLogoutClick />
      <div class="mx-4 mt-3 flex">
        <Tabs
          v-model:currentTab="currentEventTypeTab"
          :tabs="eventTypesTabs"
        />
      </div>
    </div>
    <template v-if="events && events.length">
      <div class="pb-4 pt-2">
        <div
          v-for="(dayGroup, day) in groupedEvents"
          :key="day"
          class="mx-2 mt-1 px-2 pt-1"
          :class="{ 'opacity-70': isSynchronizing }"
        >
          <div
            class="bg-gradient-darkBlue-to-blue py-1 pl-2 pr-0 text-xs font-bold uppercase text-white"
          >
            {{ day }}
          </div>
          <Event
            v-for="event in dayGroup"
            :key="event.eventId"
            :event
          />
        </div>
      </div>
    </template>
    <div
      v-else-if="events && events.length === 0"
      class="text-gray mx-2 mt-1 p-2 text-sm"
    >
      There are no events that are assigned to you
    </div>
  </div>
</template>
