<script lang="ts">
import { computed, defineComponent, PropType, Ref, ref } from 'vue'

import { opChain } from '@collector/shared-utils'
import { Action, ActionStatus } from '@mobile/ActionQueue/Action'
import { QueueActionType } from '@mobile/views/Relation/Shared/Popups/Queue/Actions/QueueTypes'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

enum PanelType {
  Error = 'error',
  Info = 'info',
}

export default defineComponent({
  props: {
    action: {
      type: Object as PropType<Action<unknown>>,
      required: true,
    },
    actionType: {
      type: String as PropType<QueueActionType>,
      default: QueueActionType.Update,
    },
    participantId: {
      type: Number as PropType<number | null>,
      required: false,
    },
    actionInformation: {
      type: String as PropType<string | null>,
      required: false,
    },
  },
  setup(props) {
    const { actionQueue, getProbableLinkedParticipantById } =
      useEventInjections()
    const openedPanel: Ref<PanelType | null> = ref(null)

    const sendEnabled = computed(
      () =>
        [
          ActionStatus.Errored,
          ActionStatus.Unknown,
          ActionStatus.Pending,
        ].includes(props.action.status) &&
        actionQueue.value.queue[0] === props.action,
    )
    const removeEnabled = computed(
      () => props.action.status !== ActionStatus.InProgress,
    )
    const linkedProbableParticipant = computed(() =>
      getProbableLinkedParticipantById(Number(props.participantId)),
    )

    function openPanel(panelType: PanelType): void {
      openedPanel.value = panelType === openedPanel.value ? null : panelType
    }

    async function sendAction(): Promise<void> {
      if (!sendEnabled.value) {
        return
      }

      await actionQueue.value.exec(props.action)
    }

    function removeAction(): void {
      if (!removeEnabled.value) {
        return
      }

      actionQueue.value.remove(props.action, true)
    }

    function getDisplayErrorMessage(error?: string): string | undefined {
      if (!error) {
        return error
      }

      try {
        const jsonError = JSON.parse(error)

        if (jsonError?.api?.error?.message) {
          return jsonError?.api?.error?.message
        }

        return JSON.stringify(jsonError, undefined, 2)
      } catch (_err) {
        return error
      }
    }

    const error = computed(() => getDisplayErrorMessage(props.action.error))

    return {
      error,
      openPanel,
      sendAction,
      PanelType,
      sendEnabled,
      openedPanel,
      removeAction,
      ActionStatus,
      removeEnabled,
      QueueActionType,
      linkedProbableParticipant,
      opChain,
    }
  },
})
</script>

<template>
  <div
    class="flex h-10 justify-between rounded-md bg-white p-1"
    :class="opChain(linkedProbableParticipant, (v) => v.css.gradient32To)"
    data-test-id="QueueListElement"
  >
    <div
      class="m-auto flex h-8 w-8 items-center justify-center rounded-md bg-white opacity-60"
    >
      <FontAwesomeIcon
        class="cursor-pointer"
        icon="grip-lines"
      />
    </div>
    <div
      class="text-darkgray mx-auto flex flex-1 flex-col justify-around pl-2 align-middle"
    >
      <div
        v-if="linkedProbableParticipant"
        class="text-xs opacity-60"
      >
        {{ linkedProbableParticipant.getParticipant().value.name }}
      </div>
      <div
        v-if="actionInformation"
        class="text-sm font-semibold"
      >
        {{ actionInformation }}
      </div>
    </div>
    <div class="w-28">
      <div class="text-xs">Action:</div>
      <div class="text-sm font-semibold">
        {{ actionType }}
      </div>
    </div>
    <div class="text-darkgray w-20 pr-6">
      <div class="text-xs">Status:</div>
      <div
        class="text-sm font-semibold"
        :class="{
          'text-red': action.status === ActionStatus.Errored,
          'text-yellow': action.status === ActionStatus.Unknown,
        }"
      >
        {{ action.status }}
      </div>
    </div>
    <div class="flex w-48 justify-end space-x-2 pl-2 text-sm text-white">
      <div
        v-if="action.error"
        class="bg-warning flex h-8 w-8 cursor-pointer flex-col justify-center rounded-md p-1 px-2"
        @click="openPanel(PanelType.Error)"
      >
        <FontAwesomeIcon
          class="mx-auto mt-1 block text-xs"
          icon="exclamation-triangle"
        />
        <FontAwesomeIcon
          v-if="openedPanel === PanelType.Error"
          class="mx-auto block origin-center text-xs"
          icon="angle-up"
        />
        <FontAwesomeIcon
          v-else
          class="mx-auto block origin-center text-xs"
          icon="angle-down"
        />
      </div>
      <div
        class="bg-blue flex h-8 w-8 cursor-pointer flex-col justify-center rounded-md p-1 px-2"
        data-test-id="MessageInfoButton"
        @click="openPanel(PanelType.Info)"
      >
        <FontAwesomeIcon
          class="mx-auto mt-1 block text-xs"
          icon="info-circle"
        />
        <FontAwesomeIcon
          v-if="openedPanel === PanelType.Info"
          class="mx-auto block origin-center text-xs"
          icon="angle-up"
        />
        <FontAwesomeIcon
          v-else
          class="mx-auto block origin-center text-xs"
          icon="angle-down"
        />
      </div>
      <div
        class="bg-green flex h-8 w-8 cursor-pointer items-center justify-center rounded-md px-2"
        :class="{
          'opacity-40': !sendEnabled,
        }"
        @click="sendAction()"
      >
        <FontAwesomeIcon icon="paper-plane" />
      </div>
      <div
        class="bg-red flex h-8 w-8 cursor-pointer items-center justify-center rounded-md px-2"
        :class="{
          'opacity-40': !removeEnabled,
        }"
        @click="removeAction()"
      >
        <FontAwesomeIcon icon="times" />
      </div>
    </div>
  </div>
  <div v-if="openedPanel === PanelType.Error">
    <div class="bg-lightgray whitespace-pre break-all p-2 text-sm">
      {{ error }}
    </div>
  </div>
  <div
    v-if="openedPanel === PanelType.Info"
    class="bg-lightgray whitespace-pre p-2 text-xs"
    data-test-id="MessageBody"
  >
    <slot name="infoPanel"></slot>
  </div>
</template>
