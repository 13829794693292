<script lang="ts">
import { isEqual, uniqBy } from 'lodash'
import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  reactive,
  SetupContext,
  watch,
} from 'vue'

import { opChain } from '@collector/shared-utils'
import ParticipantLogo from '@mobile/components/ParticipantLogo/ParticipantLogo.vue'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'

import ChoiceButton from './ChoiceButton.vue'

type Props = {
  value: string[] | number[] | null
  options: ChoiceButtonGroupOption[]
  multiselect: boolean
}
type ChoiceButtonModels = {
  [value: string]: boolean
}

function useFilteredOptions(
  props: Props,
): ComputedRef<ChoiceButtonGroupOption[]> {
  return computed(() => uniqBy(props.options, (option) => option.value))
}

function useCheckedModels(
  filteredOptions: ComputedRef<ChoiceButtonGroupOption[]>,
  props: Props,
): ChoiceButtonModels {
  const choiceButtonModels = reactive<ChoiceButtonModels>({})
  const updateCheckedModels = (options: ChoiceButtonGroupOption[]): void => {
    options.forEach((option) => {
      choiceButtonModels[String(option.value)] = isEqual(
        props.value,
        String(option.value),
      )
    })
  }

  updateCheckedModels(filteredOptions.value)

  watch(
    () => props.options,
    () => updateCheckedModels(filteredOptions.value),
  )

  return choiceButtonModels
}

function useUpdateValueAfterUserInteraction(
  choiceButtonModels: ChoiceButtonModels,
  context: SetupContext<'update:value'[]>,
  multiselect: boolean,
) {
  return (forValue: string | number | null): void => {
    if (!multiselect) {
      for (const prop in choiceButtonModels) {
        choiceButtonModels[prop] = String(prop) === String(forValue)
      }
    } else {
      choiceButtonModels[String(forValue)] = choiceButtonModels[
        String(forValue)
      ]
        ? true
        : false
    }

    const emitValues = Object.entries(choiceButtonModels)
      .filter(([, value]) => value)
      .map(([key]) => key)

    context.emit('update:value', emitValues)
  }
}

function useOutsideValueMutationsHandler(
  props: Props,
  choiceButtonModels: ChoiceButtonModels,
): void {
  watch(
    () => props.value,
    (newValue) => {
      if (!props.multiselect) {
        for (const prop in choiceButtonModels)
          choiceButtonModels[prop] = String(prop) === String(newValue)
      } else {
        choiceButtonModels[String(newValue)] = choiceButtonModels[
          String(newValue)
        ]
          ? true
          : false
      }
    },
  )
}

export type ChoiceButtonGroupOption = {
  name: string
  value: string | number | null
  disabled?: boolean
  participant?: ProbableLinkedParticipant
}

export default defineComponent({
  components: {
    ChoiceButton,
    ParticipantLogo,
  },
  props: {
    value: {
      type: null as unknown as PropType<string[] | number[] | null>,
      required: true,
    },
    options: {
      type: Array as PropType<ChoiceButtonGroupOption[]>,
      required: true,
    },
    undeselectable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String as PropType<'horizontal' | 'vertical'>,
      default: 'vertical',
    },
    withCheckmark: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:value'],
  setup(props, context) {
    const filteredOptions = useFilteredOptions(props)
    const choiceButtonModels = useCheckedModels(filteredOptions, props)
    const updateValueAfterUserInteraction = useUpdateValueAfterUserInteraction(
      choiceButtonModels,
      context,
      props.multiselect,
    )
    useOutsideValueMutationsHandler(props, choiceButtonModels)

    function getClasses(): string[] {
      switch (props.type) {
        case 'vertical':
          return ['flex-col w-full']
        case 'horizontal':
          return ['flex-wrap']
        default:
          return []
      }
    }

    function getChoiceButtonClasses(): string[] {
      switch (props.type) {
        case 'vertical':
          return ['first:mt-0 mt-4']
        case 'horizontal':
          return ['mb-2 mr-2']
        default:
          return []
      }
    }

    return {
      getClasses,
      filteredOptions,
      choiceButtonModels,
      getChoiceButtonClasses,
      updateValueAfterUserInteraction,
      opChain,
    }
  },
})
</script>

<template>
  <div
    class="flex"
    :class="getClasses()"
  >
    <ChoiceButton
      v-for="(option, index) in filteredOptions"
      :key="option.value || index"
      v-model:value="choiceButtonModels[option.value || '']"
      v-bind="{ withCheckmark }"
      :class="getChoiceButtonClasses()"
      :disabled="option.disabled"
      :undeselectable
      @toggle="updateValueAfterUserInteraction(option.value)"
    >
      <div class="flex flex-row items-center">
        <ParticipantLogo
          v-if="option.participant"
          class="mr-1 h-7 w-7"
          :participant="option.participant.getParticipant().value"
        />
        <span :class="[opChain(option.participant, (v) => v.css.textClass)]">
          {{ option.name }}
        </span>
      </div>
    </ChoiceButton>
  </div>
</template>
