<script lang="ts">
import { defineComponent } from 'vue'

import { opChain } from '@collector/shared-utils'
import { useOverlays } from '@mobile/views/Relation/Sports/Cricket/useOverlays'

export default defineComponent({
  components: {},
  setup() {
    const { visibleOverlay, overlayComponents } = useOverlays()

    return {
      visibleOverlay,
      overlayComponents,
      opChain,
    }
  },
})
</script>

<template>
  <div
    v-for="(overlayComponent, index) in overlayComponents"
    v-show="
      overlayComponent.props.incidentId ===
      opChain(visibleOverlay, (v) => v.props.incidentId)
    "
    :key="index"
    class="absolute mt-4"
    :style="{ width: '100%', height: '90%' }"
  >
    <div class="flex h-full w-full flex-col place-items-center space-y-4">
      <div
        v-if="overlayComponent.props.title"
        class="bg-blue grid h-10 w-56 place-content-center rounded font-bold text-white"
      >
        {{ overlayComponent.props.title }}
      </div>
      <div
        class="flex w-full flex-1 justify-center space-x-4"
        data-test-id="TeamSelectionButtons"
      >
        <component
          :is="overlayComponent.component"
          v-bind="overlayComponent.props"
        />
      </div>
    </div>
  </div>
</template>
