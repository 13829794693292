import { Event, SportId } from '@collector/sportsapi-client-legacy'
import { CollectorSport } from '@mobile/reusables/classes/CollectorSport'

import { eventPreparationTeamDetailsAmericanFootballFactory } from './eventPreparationTeamDetailsAmericanFootballFactory'
import { eventPreparationTeamDetailsBasketball3x3Factory } from './eventPreparationTeamDetailsBasketball3x3Factory'
import { eventPreparationTeamDetailsBasketballFactory } from './eventPreparationTeamDetailsBasketballFactory'
import { eventPreparationTeamDetailsBeachVolleyballFactory } from './eventPreparationTeamDetailsBeachVolleyballFactory'
import { eventPreparationTeamDetailsCricketFactory } from './eventPreparationTeamDetailsCricketFactory'
import { eventPreparationTeamDetailsDefaultFactory } from './eventPreparationTeamDetailsDefaultFactory'
import { eventPreparationTeamDetailsHandballFactory } from './eventPreparationTeamDetailsHandballFactory'
import { eventPreparationTeamDetailsSoccerFactory } from './eventPreparationTeamDetailsSoccerFactory'
import { eventPreparationTeamDetailsVolleyballFactory } from './eventPreparationTeamDetailsVolleyballFactory'
import { EventPreparationTeamDetailsSportFactory } from './types'

const factories: {
  [key: number]: (
    event: Event,
    sport: CollectorSport,
  ) => EventPreparationTeamDetailsSportFactory | undefined
} = {
  [SportId.Soccer]: eventPreparationTeamDetailsSoccerFactory,
  [SportId.Handball]: eventPreparationTeamDetailsHandballFactory,
  [SportId.Basketball]: eventPreparationTeamDetailsBasketballFactory,
  [SportId.Cricket]: eventPreparationTeamDetailsCricketFactory,
  [SportId.Volleyball]: eventPreparationTeamDetailsVolleyballFactory,
  [SportId.BeachVolleyball]: eventPreparationTeamDetailsBeachVolleyballFactory,
  [SportId.AmericanFootball]:
    eventPreparationTeamDetailsAmericanFootballFactory,
  [SportId.Basketball3x3]: eventPreparationTeamDetailsBasketball3x3Factory,
}

export function useEventPreparationTeamDetailsFactory(
  event: Event,
  sport: CollectorSport,
): EventPreparationTeamDetailsSportFactory {
  return (
    factories[sport.sportsAPIEntity.id](event, sport) ??
    eventPreparationTeamDetailsDefaultFactory()
  )
}
