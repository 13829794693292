import { computed, ComputedRef, Ref } from 'vue'

import {
  Event,
  IncidentId,
  Participant,
  SoccerIncidentId,
  TeamSide,
} from '@collector/sportsapi-client-legacy'
import AddIncident from '@mobile/ActionQueue/Actions/Incidents/AddIncident'
import UpdateIncident from '@mobile/ActionQueue/Actions/Incidents/UpdateIncident'
import { BallPosition } from '@mobile/reusables/types/position/BallPosition'
import {
  Clock,
  ProbableLinkedIncident,
} from '@mobile/views/Relation/Shared/RelationDependencies/types'

import { resolvePossessionIncidentId } from './resolvePossessionIncidentId'

export const positionableIncidents: IncidentId[] = [
  SoccerIncidentId.GoalKick,
  SoccerIncidentId.FreeKick,
  SoccerIncidentId.DangerousFreeKick,
  SoccerIncidentId.ThrowIn,
  SoccerIncidentId.ShotOnTarget,
  SoccerIncidentId.ShotOffTarget,
]

/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
export function useBallPossessionPlugin(
  linkedProbableIncidents: ComputedRef<ProbableLinkedIncident[]>,
) {
  const positionableIncidentIfLast = computed(() => {
    let lastPositionableIncident: ProbableLinkedIncident | undefined
    const lastLinkedProbableIncident = linkedProbableIncidents.value[0] as
      | ProbableLinkedIncident
      | undefined

    if (
      lastLinkedProbableIncident &&
      positionableIncidents.includes(
        lastLinkedProbableIncident.incident.incident_id,
      )
    ) {
      lastPositionableIncident = lastLinkedProbableIncident
    }

    return lastPositionableIncident
  })

  const incidentWithPositionIfLast = computed(() => {
    const lastLinkedProbableIncident = linkedProbableIncidents.value[0] as
      | ProbableLinkedIncident
      | undefined

    if (
      lastLinkedProbableIncident &&
      lastLinkedProbableIncident.incident.x_pos !== null &&
      lastLinkedProbableIncident.incident.y_pos !== null
    ) {
      return lastLinkedProbableIncident
    }

    return undefined
  })

  const lastIncident = computed<ProbableLinkedIncident | undefined>(
    () => linkedProbableIncidents.value[0],
  )

  function addOrUpdatePossessionIncident(
    probableClock: Ref<Clock>,
    { x, y }: BallPosition,
    possessionSide: TeamSide,
    lastIncident: ComputedRef<ProbableLinkedIncident | undefined>,
    probableHomeParticipant: ComputedRef<Participant | undefined>,
    probableAwayParticipant: ComputedRef<Participant | undefined>,
    probableEvent: Ref<Event>,
  ): AddIncident | UpdateIncident {
    const incidentId = resolvePossessionIncidentId(possessionSide, x)

    const time = {
      minute: probableClock.value.minute,
      second: probableClock.value.second,
    }
    const participant =
      possessionSide === TeamSide.Away
        ? probableAwayParticipant
        : probableHomeParticipant
    const shouldUpdate =
      lastIncident.value?.incident.incident_id === incidentId &&
      lastIncident.value.incident.participant_id === participant.value?.id

    if (lastIncident.value && shouldUpdate) {
      return new UpdateIncident(lastIncident.value.incident.id, {
        event_id: probableEvent.value.id,
        incident_id: lastIncident.value.incident.incident_id,
        status_id: lastIncident.value.incident.event_status_id,
        participant_team_id: lastIncident.value.incident.participant_id,
        x_pos: x,
        y_pos: y,
      })
    } else {
      return new AddIncident({
        incident_id: incidentId,
        status_id: probableEvent.value.status_id,
        participant_team_id: participant.value?.id,
        x_pos: x,
        y_pos: y,
        ...time,
      })
    }
  }

  function updatePositionFor(
    probableLastAddedIncident: ProbableLinkedIncident,
    position: BallPosition,
    probableEvent: Ref<Event>,
  ): UpdateIncident {
    return new UpdateIncident(probableLastAddedIncident.incident.id, {
      incident_id: probableLastAddedIncident.incident.incident_id,
      event_id: probableEvent.value.id,
      status_id: probableLastAddedIncident.incident.event_status_id,
      participant_team_id: probableLastAddedIncident.incident.participant_id,
      x_pos: position.x,
      y_pos: position.y,
    })
  }

  return {
    lastIncident,
    incidentWithPositionIfLast,
    positionableIncidentIfLast,
    addOrUpdatePossessionIncident,
    updatePositionFor,
  }
}
