import { interfaces } from 'inversify'
import { Handler } from 'mitt'
import { ComputedRef, DeepReadonly, Ref } from 'vue'

import {
  CollectorPushMessageEvent,
  CollectorPushMessageIncident,
  Event,
  EventScout,
  EventsLineupsParticipant,
  Participant,
  SportIncident,
  SportStatus,
  TeamSide,
} from '@collector/sportsapi-client-legacy'
import { ActionQueue } from '@mobile/ActionQueue/ActionQueue'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import { CollectorSport } from '@mobile/reusables/classes/CollectorSport'
import { AppNotification } from '@mobile/reusables/types/AppNotification'
import { AppNotificationType } from '@mobile/reusables/types/AppNotificationType'

export type Clock = {
  time: Event['clock_time']
  minute: Event['clock_time']
  second: Event['clock_time']
  minuteFormatted: string
  secondFormatted: string
  status: Event['clock_status']
}

export type Notifications = {
  currentFilters: ComputedRef<AppNotificationType[]>
  unfilteredNotifications: ComputedRef<AppNotification[]>
  filteredNotifications: ComputedRef<AppNotification[]>
  applyFilters(filterTypes: AppNotificationType[]): void
  onNewNotification(
    cb: Handler<{
      notification: DeepReadonly<AppNotification>
      isVisible: boolean
    }>,
  ): void
  offNewNotification(
    cb: Handler<{
      notification: DeepReadonly<AppNotification>
      isVisible: boolean
    }>,
  ): void
  sendNotification(
    notificationData: Pick<
      AppNotification,
      'title' | 'type' | 'content' | 'details'
    >,
  ): void
}

type SI<T> = interfaces.ServiceIdentifier<T>

export type Probables = {
  probableEvent: Ref<Event>
  probableIncidents: Ref<ProbableIncident[]>
  probableNotDeletableIncidents: Ref<ProbableIncident[]>
  probableCurrentIncidents: Ref<ProbableIncident[]>
  probableHomeLineups: Ref<EventsLineupsParticipant[]>
  probableAwayLineups: Ref<EventsLineupsParticipant[]>
  probableSelectableHomeLineups: Ref<EventsLineupsParticipant[]>
  probableSelectableAwayLineups: Ref<EventsLineupsParticipant[]>
  probableHomeParticipant: ComputedRef<Participant>
  probableAwayParticipant: ComputedRef<Participant>
}

export type ProbableLinkedEvent = {
  sportStatus: SportStatus
  event: Event
}

export type ProbableLinkedIncident = {
  incident: ProbableIncident
  participant?: Participant
  probableLinkedParticipant?: ProbableLinkedParticipant
  sportStatus: SportStatus
  sportIncident: SportIncident
  side: TeamSide | null
  eventId: number
  uuid?: string
  pushMessageUt?: number
}

export type ProbableLinkedParticipant = {
  getLineups():
    | Probables['probableHomeLineups']
    | Probables['probableAwayLineups']
  getParticipant():
    | Probables['probableHomeParticipant']
    | Probables['probableAwayParticipant']
  side: TeamSide
  oppositeSide: TeamSide
  css: {
    bgClass: string
    textClass: string
    gradientFrom: string
    gradientTo: string
    gradient32From: string
    gradient32To: string
    gradient32ToTrans: string
    gradient32ToWhite: string
  }
}

export type SidesSwitched = {
  sidesSwitched: Ref<boolean>
  probableLinkedFirstParticipant: ComputedRef<ProbableLinkedParticipant>
  probableFirstParticipant: ComputedRef<Participant>
  probableLinkedSecondParticipant: ComputedRef<ProbableLinkedParticipant>
  probableSecondParticipant: ComputedRef<Participant>
}

type Lineups = {
  home: Ref<EventsLineupsParticipant[]>
  away: Ref<EventsLineupsParticipant[]>
}

interface RelationIoCBindingsInterface {
  Sport: SI<ComputedRef<CollectorSport>>
  ActionQueue: SI<Ref<ActionQueue>>
  SidesSwitched: SI<SidesSwitched>
  Notifications: SI<Notifications>

  FactualEvent: SI<Ref<CollectorPushMessageEvent>>
  FactualHomeParticipant: SI<ComputedRef<Participant>>
  FactualAwayParticipant: SI<ComputedRef<Participant>>
  FactualLineups: SI<Lineups>
  FactualHomeLineups: SI<Ref<EventsLineupsParticipant[]>>
  FactualAwayLineups: SI<Ref<EventsLineupsParticipant[]>>
  FactualIncidents: SI<Ref<CollectorPushMessageIncident[]>>
  FactualClock: SI<Ref<Clock>>

  Probables: SI<Probables>
  ProbableEvent: SI<Probables['probableEvent']>
  ProbableHomeLineups: SI<Probables['probableHomeLineups']>
  ProbableAwayLineups: SI<Probables['probableAwayLineups']>
  ProbableSelectableHomeLineups: SI<Probables['probableSelectableHomeLineups']>
  ProbableSelectableAwayLineups: SI<Probables['probableSelectableAwayLineups']>
  ProbableIncidents: SI<Probables['probableIncidents']>
  ProbableNotDeletableIncidents: SI<Probables['probableNotDeletableIncidents']>
  ProbableCurrentIncidents: SI<Probables['probableCurrentIncidents']>
  ProbableHomeParticipant: SI<Probables['probableHomeParticipant']>
  ProbableAwayParticipant: SI<Probables['probableAwayParticipant']>
  ProbableClock: SI<Ref<Clock>>
  ProbableLinkedEvent: SI<ComputedRef<ProbableLinkedEvent>>
  ProbableLinkedIncidents: SI<ComputedRef<ProbableLinkedIncident[]>>

  ProbableLinkedHomeParticipant: SI<ProbableLinkedParticipant>
  ProbableLinkedAwayParticipant: SI<ProbableLinkedParticipant>
  EventsScouts: SI<Ref<EventScout[]>>
}

export const RelationIoCBindings: RelationIoCBindingsInterface = {
  Sport: Symbol('Sport'),
  ActionQueue: Symbol('ActionQueue'),
  SidesSwitched: Symbol('SidesSwitched'),
  Notifications: Symbol('Notifications'),

  FactualEvent: Symbol('FactualEvent'),
  FactualHomeParticipant: Symbol('FactualHomeParticipant'),
  FactualAwayParticipant: Symbol('FactualAwayParticipant'),
  FactualLineups: Symbol('FactualLineups'),
  FactualHomeLineups: Symbol('FactualHomeLineups'),
  FactualAwayLineups: Symbol('FactualAwayLineups'),
  FactualIncidents: Symbol('FactualIncidents'),
  FactualClock: Symbol('FactualClock'),

  Probables: Symbol('Probables'),
  ProbableEvent: Symbol('ProbableEvent'),
  ProbableHomeLineups: Symbol('ProbableHomeLineups'),
  ProbableAwayLineups: Symbol('ProbableAwayLineups'),
  ProbableSelectableHomeLineups: Symbol('ProbableSelectableHomeLineups'),
  ProbableSelectableAwayLineups: Symbol('ProbableSelectableAwayLineups'),
  ProbableIncidents: Symbol('ProbableIncidents'),
  ProbableNotDeletableIncidents: Symbol('ProbableNotDeletableIncidents'),
  ProbableCurrentIncidents: Symbol('ProbableCurrentIncidents'),
  ProbableHomeParticipant: Symbol('ProbableHomeParticipant'),
  ProbableAwayParticipant: Symbol('ProbableAwayParticipant'),
  ProbableClock: Symbol('ProbableClock'),
  ProbableLinkedEvent: Symbol('ProbableLinkedEvent'),
  ProbableLinkedIncidents: Symbol('ProbableLinkedIncidents'),
  ProbableLinkedHomeParticipant: Symbol('ProbableLinkedHomeParticipant'),
  ProbableLinkedAwayParticipant: Symbol('ProbableLinkedAwayParticipant'),
  EventsScouts: Symbol('EventScouts'),
}
