import { Container } from 'inversify'

import { BasketballIncidentId } from '@collector/sportsapi-client-legacy'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindAssignableIncidentConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.AssignableIncidentsConfiguration)
    .toDynamicValue(() => {
      return {
        [BasketballIncidentId.OnePointShotMade]: {
          participants: {
            main: 'Player',
            secondary: 'Assist',
          },
          participantsConfiguration: {
            secondary: {
              loadOppositeTeam: false,
              optional: true,
            },
          },
        },
        [BasketballIncidentId.TwoPointShotMade]: {
          participants: {
            main: 'Player',
            secondary: 'Assist',
          },
          participantsConfiguration: {
            secondary: {
              loadOppositeTeam: false,
              optional: true,
            },
          },
        },
        [BasketballIncidentId.ThreePointShotMade]: {
          participants: {
            main: 'Player',
            secondary: 'Assist',
          },
          participantsConfiguration: {
            secondary: {
              loadOppositeTeam: false,
              optional: true,
            },
          },
        },
        [BasketballIncidentId.ThrowIn]: { participants: { main: 'Player' } },
        [BasketballIncidentId.OnePointShotMissed]: {
          participants: { main: 'Player' },
        },
        [BasketballIncidentId.ThreePointShotMissed]: {
          participants: { main: 'Player' },
        },
        [BasketballIncidentId.TwoPointShotMissed]: {
          participants: { main: 'Player' },
        },
        [BasketballIncidentId.FreeThrowsAwarded]: {
          participants: { main: 'Player' },
        },
        [BasketballIncidentId.OffensiveRebound]: {
          participants: { main: 'Player' },
        },
        [BasketballIncidentId.DefensiveRebound]: {
          participants: { main: 'Player' },
        },
        [BasketballIncidentId.Foul]: { participants: { main: 'Player' } },
        [BasketballIncidentId.Turnover]: { participants: { main: 'Player' } },
        [BasketballIncidentId.Steal]: {
          participants: {
            main: 'Player',
            secondary: 'Turnover',
          },
          participantsConfiguration: { secondary: { loadOppositeTeam: true } },
        },
        [BasketballIncidentId.Block]: { participants: { main: 'Player' } },
      }
    })
    .inSingletonScope()
}
