import mitt from 'mitt'
import { ComponentPublicInstance } from 'vue'

import { SwipeEvent } from '@mobile/reusables/useGestureDirective'

export type Events = {
  HighlightPosition: { x: number; y: number; reversed?: boolean }
  Press: {
    position: { x: number; y: number }
    timeMs: number
    onComplete: () => void
    onCancel: () => void
  }
  CancelPress: undefined
  QueueHasUnconfirmedIncidents: { triggeredByReminderIncident: boolean }
  UnconfirmedIncidentsReminder: undefined
  AllIncidentsConfirmed: undefined
  SoccerLiveViewSwipe: { event: SwipeEvent }
  VueError: {
    error: unknown
    instance: ComponentPublicInstance | null
    info: string
  }
}

export const eventBus = mitt<Events>()

export type EventBus = typeof eventBus
