import { liveQuery, Subscription } from 'dexie'
import { onMounted, onUnmounted, ref, Ref } from 'vue'

import { AppDatabase } from './AppDatabase'

export function useEventsScoutsAssignationMap(database: AppDatabase): Ref<{
  [key: string]: number[] | undefined
}> {
  const assignedScouts: Ref<{ [key: string]: number[] | undefined }> = ref({})
  const eventsScoutsObservable = liveQuery(() => database.eventsScouts.getAll())
  let eventsScoutsSubscription: Subscription

  onMounted(() => {
    eventsScoutsSubscription = eventsScoutsObservable.subscribe({
      next: (result) => {
        result.map((message) => {
          assignedScouts.value[message.eventId] = message.data.currentScoutIds
        })
      },
    })
  })

  onUnmounted(() => {
    eventsScoutsSubscription.unsubscribe()
  })

  return assignedScouts
}
