import { Confirmation } from '@collector/sportsapi-client-legacy'

import { NavigationButton, PopupContext } from '../types'
import { PopupButtonProps } from './PopupButton/PopupButton'
import PopupButton from './PopupButton/PopupButton.vue'

export function useCancellationButton(
  options?: Partial<PopupButtonProps>,
): NavigationButton {
  return {
    component: PopupButton,
    props: {
      text: options?.text ?? 'Cancelled',
      attributes: options?.attributes ?? {},
      color: 'red',
      onClick: (context: PopupContext | null) => {
        context?.incidentActionComposable?.updateIncident({
          confirmation: Confirmation.Cancelled,
        })
        if (options?.onClick) options.onClick(context)
        else context?.closePopup()
      },
    },
  }
}
