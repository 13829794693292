<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { SoccerIncidentId } from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import ParticipantLogo from '@mobile/components/ParticipantLogo/ParticipantLogo.vue'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: {
    Button,
    ParticipantLogo,
    IncidentButton,
  },
  props: {
    incidentId: {
      type: Number,
      required: true,
    },
    time: Object as PropType<{ minute: number; second: number }>,
  },
  emits: ['sidesSwitched'],
  setup() {
    const { probableAwayParticipant, probableHomeParticipant, sidesSwitched } =
      useEventInjections()

    return {
      probableAwayParticipant,
      probableHomeParticipant,
      SoccerIncidentId,
      sidesSwitched,
    }
  },
})
</script>

<template>
  <div class="absolute left-1/2 -translate-x-1/2 -translate-y-1/2 transform">
    <Button
      class="font-bold"
      :color="'blue'"
      :size="'xs'"
    >
      Kickoff
    </Button>
  </div>

  <div
    class="flex h-full flex-row justify-between space-x-8 p-8"
    :class="{
      'flex-row-reverse space-x-reverse': sidesSwitched,
    }"
  >
    <IncidentButton
      class="bg-home flex h-full w-full flex-1 flex-col rounded bg-opacity-80"
      type="horizontal"
      :participantId="probableHomeParticipant.id"
      :incidentId="SoccerIncidentId.KickOff"
      :color="'home'"
      :size="'sm'"
      :withIcon="false"
    >
      <div class="m-auto text-center">
        <ParticipantLogo
          class="h-20 w-20"
          :participant="probableHomeParticipant"
        />
        <div class="text-center">
          {{ probableHomeParticipant.short_name }}
        </div>
      </div>
    </IncidentButton>

    <IncidentButton
      class="bg-away flex h-full w-full flex-1 flex-col rounded bg-opacity-80"
      type="horizontal"
      :participantId="probableAwayParticipant.id"
      :incidentId="SoccerIncidentId.KickOff"
      :color="'away'"
      :size="'sm'"
      :withIcon="false"
    >
      <div class="m-auto text-center">
        <ParticipantLogo
          class="h-20 w-20"
          :participant="probableAwayParticipant"
        />
        <div class="w-full text-center">
          {{ probableAwayParticipant.short_name }}
        </div>
      </div>
    </IncidentButton>
  </div>
</template>
