import { watch, WatchStopHandle } from 'vue'

import * as scoutMessenger from '@mobile/globalState/scoutMessenger'
import { AppNotificationType } from '@mobile/reusables/types/AppNotificationType'
import { Notifications } from '@mobile/views/Relation/Shared/RelationDependencies/types'

import { Hook } from './Hook'

export class HookScoutMessengerConnectionStatus implements Hook {
  private unwatch?: WatchStopHandle

  constructor(private readonly notifications: Notifications) {}

  install(): void {
    this.unwatch = watch(
      () => scoutMessenger.state.isConnected,
      () => {
        if (scoutMessenger.state.isConnected) {
          this.notifications.sendNotification({
            type: AppNotificationType.Info,
            title: 'Connection has been established',
            content:
              'Connection with scout messenger has been reestablished successfully',
          })
        } else {
          this.notifications.sendNotification({
            type: AppNotificationType.Info,
            title: 'Connection has been lost',
            content: 'Connection with scout messenger has been lost',
          })
        }
      },
    )
  }
  uninstall(): void {
    this.unwatch?.()
  }
}
