<script lang="ts">
import { defineComponent, PropType } from 'vue'

import {
  CricketIncidentId,
  IllegalBallAttributesId,
} from '@collector/sportsapi-client-legacy'
import { ToggleButtonGroupOption } from '@mobile/components/ToggleButton/ToggleButtonGroup.vue'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'

import SelectNumberOfPopup from '../SelectNumberOf/SelectNumberOfPopup.vue'

export default defineComponent({
  components: { SelectNumberOfPopup },
  props: {
    context: {
      type: Object as PropType<PopupContext | null>,
      required: false,
    },
  },
  setup() {
    const incidentId: CricketIncidentId.IllegalBall =
      CricketIncidentId.IllegalBall
    const coreAttributeId = IllegalBallAttributesId.NoBallBat
    const options: ToggleButtonGroupOption[] = [
      {
        name: 'NBB +1',
        value: IllegalBallAttributesId.Two,
      },
      {
        name: 'NBB +2',
        value: IllegalBallAttributesId.Three,
      },
      {
        name: 'NBB +3',
        value: IllegalBallAttributesId.Four,
      },
      {
        name: 'NBB +4',
        value: IllegalBallAttributesId.Five,
      },
      {
        name: 'NBB +5',
        value: IllegalBallAttributesId.Six,
      },
      {
        name: 'NBB +6',
        value: IllegalBallAttributesId.Seven,
      },
      {
        name: 'NBB +7',
        value: IllegalBallAttributesId.Eight,
      },
      {
        name: 'NBB +8',
        value: IllegalBallAttributesId.Nine,
      },
    ]

    return {
      options,
      incidentId,
      coreAttributeId,
    }
  },
})
</script>

<template>
  <div class="h-full w-full">
    <SelectNumberOfPopup
      v-bind="{ options, context, coreAttributeId, incidentId }"
      :title="'Select number of No ball + bat Runs'"
    />
  </div>
</template>
