<script lang="ts">
import { defineComponent, PropType, ref, watch } from 'vue'

import { opChain } from '@collector/shared-utils'
import { byId } from '@mobile/reusables/entityUtils'

import { Formation, FormationPlayer } from './Formation'

export default defineComponent({
  props: {
    formation: {
      type: Object as PropType<Formation>,
      required: true,
    },
    selectedFormationPlayer: {
      type: Object as PropType<FormationPlayer>,
      required: false,
    },
  },
  emits: ['addFormationPlayerClicked', 'removeFormationPlayerClicked'],
  setup(props) {
    const selectedPlayersRefs = ref<HTMLDivElement[]>([])

    watch(
      () => props.selectedFormationPlayer,
      (player) => {
        const selectedPlayerRef = byId(
          selectedPlayersRefs.value,
          `selected-player-${player?.participant_id}`,
        )

        if (selectedPlayerRef) {
          selectedPlayerRef.scrollIntoView({ behavior: 'smooth' })
        }
      },
    )

    return { selectedPlayersRefs, opChain }
  },
})
</script>

<template>
  <div
    class="max-h-3/4 text-lightblack ml-3 flex flex-1 flex-col overflow-y-scroll"
  >
    <div
      v-for="(notSelectedPlayer, index) in formation.notSelectedPlayers"
      :key="`not-selected-player-${index}`"
      class="mb-0.5 flex w-full items-center bg-white px-2 py-3 text-sm"
      :class="
        !opChain(selectedFormationPlayer, (v) => v.participant_id)
          ? 'cursor-pointer'
          : ''
      "
      @click="$emit('addFormationPlayerClicked', notSelectedPlayer)"
    >
      <span>{{ notSelectedPlayer.participant_shirt_number }}</span>
      <span class="ml-2">
        {{
          opChain(notSelectedPlayer, (v) => v.short_name) ||
          opChain(notSelectedPlayer, (v) => v.participant_name)
        }}
      </span>
    </div>
    <div
      v-for="(selectedPlayer, index) in formation.selectedPlayers"
      :id="`selected-player-${selectedPlayer.participant_id}`"
      :key="`selected-player-${index}`"
      ref="selectedPlayersRefs"
      class="mb-0.5 flex w-full items-center bg-white px-2 py-3 text-sm font-bold"
      :class="
        opChain(selectedFormationPlayer, (v) => v.participant_id) ===
        selectedPlayer.participant_id
          ? 'from-tropicalblue bg-gradient-to-r to-white'
          : ''
      "
    >
      <span>{{ selectedPlayer.participant_shirt_number }}</span>
      <span class="ml-2">
        {{
          opChain(selectedPlayer, (v) => v.short_name) ||
          opChain(selectedPlayer, (v) => v.participant_name)
        }}
      </span>
      <FontAwesomeIcon
        v-if="
          opChain(selectedFormationPlayer, (v) => v.participant_id) ===
          selectedPlayer.participant_id
        "
        class="text-red ml-auto cursor-pointer text-xl"
        icon="times"
        @click="$emit('removeFormationPlayerClicked', selectedFormationPlayer)"
      />
    </div>
  </div>
</template>
