import { Container } from 'inversify'
import { cloneDeep } from 'lodash'
import { ref } from 'vue'

import {
  CollectorPushMessageIncident,
  NotUuidThatWasDispatchedByIncidentsQueue,
} from '@collector/sportsapi-client-legacy'
import { RepositoryEmitter } from '@mobile/database/Repository'
import * as app from '@mobile/globalState/app'
import * as scoutMessenger from '@mobile/globalState/scoutMessenger'
import { updateProps } from '@mobile/reusables/entityUtils'

import { RelationIoCBindings } from './types'

export function bindIncidents(ioc: Container): void {
  let unbindDatabaseWatchers: () => void
  let eventId: number

  ioc
    .bind(RelationIoCBindings.FactualIncidents)
    .toDynamicValue(async (ctx) => {
      const event = await ctx.container.getAsync(
        RelationIoCBindings.FactualEvent,
      )
      eventId = event.value.eventId

      const incidents = await app.state.database.incidents.getByEventId(
        event.value.eventId,
      )

      return ref(incidents)
    })
    .inSingletonScope()
    .onActivation(async (ctx, incidents) => {
      const onCreateOrUpdate:
        | RepositoryEmitter<CollectorPushMessageIncident>['created']
        | RepositoryEmitter<CollectorPushMessageIncident>['updated'] = async (
        changedIncident,
      ) => {
        const incidentsValue = incidents.value
        if (incidentsValue && changedIncident.eventId === eventId) {
          scoutMessenger.state.messageConfirmator.onUuid(
            changedIncident.uuid,
            () => {
              updateCollectorIncidentPushMessage(
                incidentsValue,
                changedIncident,
              )
            },
            (err) => {
              // Confirm event update that wasn't dispatched by incidents queue
              if (err instanceof NotUuidThatWasDispatchedByIncidentsQueue) {
                updateCollectorIncidentPushMessage(
                  incidentsValue,
                  changedIncident,
                )
              }
            },
            false,
          )
        }
      }
      app.state.database.incidents.on('created', onCreateOrUpdate)
      app.state.database.incidents.on('updated', onCreateOrUpdate)

      const onDelete: RepositoryEmitter<CollectorPushMessageIncident>['deleted'] =
        (deletedIncident) => {
          if (incidents.value && deletedIncident.eventId === eventId) {
            deleteCollectorIncidentPushMessage(incidents.value, deletedIncident)
          }
        }
      app.state.database.incidents.on('deleted', onDelete)

      unbindDatabaseWatchers = () => {
        app.state.database.incidents.off('created', onCreateOrUpdate)
        app.state.database.incidents.off('updated', onCreateOrUpdate)
        app.state.database.incidents.off('deleted', onDelete)
      }

      return incidents
    })
    .when(() => true)
    .onDeactivation(async () => {
      unbindDatabaseWatchers()
    })
    .when(() => true)
}

function updateCollectorIncidentPushMessage(
  targets: CollectorPushMessageIncident[],
  updateData: CollectorPushMessageIncident,
): void {
  const target = targets.find((target) => target.data.id === updateData.data.id)
  if (target) {
    updateProps(target, updateData, ['pushMessageUt', 'uuid'])
    updateProps(target.data, updateData.data, [
      'action',
      'incident_id',
      'incident_name',
      'attribute_ids',
      'additional_info',
      'confirmation',
      'participant_id',
      'participant_name',
      'subparticipant_id',
      'subparticipant_name',
      'assistant_id',
      'assistant_name',
      'info',
      'important',
      'important_for_trader',
      'add_data',
      'event_time',
      'event_status_id',
      'event_status_name',
      'properties',
      'x_pos',
      'y_pos',
    ])
  } else {
    targets.unshift(cloneDeep(updateData))
  }
}

function deleteCollectorIncidentPushMessage(
  targets: CollectorPushMessageIncident[],
  deleteData: CollectorPushMessageIncident,
): void {
  const targetIndex = targets.findIndex(
    (target) => target.data.id === deleteData.data.id,
  )

  if (targetIndex !== -1) {
    targets.splice(targetIndex, 1)
  }
}
