import { useNavigationSeparator } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useNavigationSeparator'
import { usePopupButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/usePopupButton'
import { PopupBuilder } from '@mobile/views/Relation/Shared/Popup/builder/PopupBuilder'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'

import WidePopupDetails from './WidePopupDetails.vue'

export const WidePopup = new PopupBuilder('Is it wide?')
  .appendBody(WidePopupDetails)
  .setNavigationButtons([
    ...useNavigationSeparator(5),
    usePopupButton({
      props: {
        color: 'red',
        text: 'Close',
        onClick: (context: PopupContext | null) => {
          context?.closeAllPopups()
        },
      },
    }),
  ])
  .build()
