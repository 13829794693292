import { computed } from 'vue'

import {
  Basketball3x3EventDetailId,
  Basketball3x3IncidentId,
} from '@collector/sportsapi-client-legacy'
import { byId } from '@mobile/reusables/entityUtils'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import SidesSwitchOverlay from '@mobile/views/Relation/Shared/SidesSwitchOverlay/SidesSwitchOverlay.vue'
import StatusButtonOverlay from '@mobile/views/Relation/Shared/StatusButtonOverlay/StatusButtonOverlay.vue'
import StartWithPossessionOverlay from '@mobile/views/Relation/Sports/Basketball3x3/LiveView/Pitch/PitchOverlays/StartWithPossessionOverlay.vue'

/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
export function usePitchOverlays() {
  const { probableCurrentIncidents, probableEvent } = useEventInjections()

  const overlayComponents = computed(() => {
    return [
      {
        component: SidesSwitchOverlay,
        isVisible: !probableCurrentIncidents.value.find(
          (incident) =>
            incident.incident_id === Basketball3x3IncidentId.MatchAboutToStart,
        ),
        props: { incidentId: Basketball3x3IncidentId.MatchAboutToStart },
      },
      {
        component: StatusButtonOverlay,
        isVisible: !probableCurrentIncidents.value.find(
          (incident) =>
            incident.incident_id === Basketball3x3IncidentId.RegularTimeStarted,
        ),
        props: { incidentId: Basketball3x3IncidentId.RegularTimeStarted },
      },
      {
        component: StartWithPossessionOverlay,
        isVisible:
          probableCurrentIncidents.value.find(
            (incident) =>
              incident.incident_id ===
              Basketball3x3IncidentId.RegularTimeStarted,
          ) &&
          !byId(
            probableEvent.value.details,
            Basketball3x3EventDetailId.StartWithABall,
          )?.value,
        props: { incidentId: Basketball3x3IncidentId.InPossession },
      },
    ]
  })

  const visibleOverlay = computed(() =>
    overlayComponents.value.find(({ isVisible }) => isVisible),
  )

  return {
    overlayComponents,
    visibleOverlay,
  }
}
