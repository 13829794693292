import { Container } from 'inversify'

import {
  Basketball3x3LineupPositions,
  ParticipantSubType,
} from '@collector/sportsapi-client-legacy'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindLineupsConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.LineupsConfiguration)
    .toDynamicValue(() => {
      return {
        availablePositions: [
          {
            name: 'Coach',
            acronym: 'COA',
            canCaptainBeSelected: false,
            canNumberBeAssigned: false,
            subtypes: [
              ParticipantSubType.Athlete,
              ParticipantSubType.AthleteCoach,
              ParticipantSubType.Coach,
            ],
            button: { color: 'gray' },
          },
          {
            name: 'Player',
            acronym: 'PL',
            canCaptainBeSelected: true,
            canNumberBeAssigned: true,
            subtypes: [
              ParticipantSubType.Athlete,
              ParticipantSubType.AthleteCoach,
            ],
            button: { color: 'green' },
            maximumPlayersAmount: 11,
          },
        ],
        lineupPositionOrder: [
          Basketball3x3LineupPositions.Player,
          Basketball3x3LineupPositions.Coach,
        ],
      }
    })
    .inSingletonScope()
}
