<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import { opChain } from '@collector/shared-utils'
import DeleteIncident from '@mobile/ActionQueue/Actions/Incidents/DeleteIncident'
import { resolveProbableIncident } from '@mobile/ActionQueue/ActionUtils'
import QueueAction from '@mobile/views/Relation/Shared/Popups/Queue/Actions/QueueAction.vue'
import { QueueActionType } from '@mobile/views/Relation/Shared/Popups/Queue/Actions/QueueTypes'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

export default defineComponent({
  components: { QueueAction },
  props: {
    action: {
      type: Object as PropType<DeleteIncident>,
      required: true,
    },
  },
  setup(props) {
    const { actionQueue, notDeletableProbableIncidents } = useEventInjections()
    const { incidentsConfiguration } = useSportConfiguration()

    const probableIncident = computed(() =>
      resolveProbableIncident(
        props.action,
        actionQueue.value,
        notDeletableProbableIncidents.value,
        incidentsConfiguration,
      ),
    )
    const info = computed(() =>
      JSON.stringify(props.action.actionOrIncidentId, undefined, 2),
    )

    return {
      QueueActionType,
      info,
      probableIncident,
      opChain,
    }
  },
})
</script>

<template>
  <QueueAction
    :participantId="opChain(probableIncident, (v) => v.participant_id)"
    :actionType="QueueActionType.Delete"
    :action
    :actionInformation="opChain(probableIncident, (v) => v.incident_name)"
  >
    <template #infoPanel>
      {{ info }}
    </template>
  </QueueAction>
</template>
