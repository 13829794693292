import {
  BeachVolleyballCardsAttributeIds,
  BeachVolleyballIncidentId,
} from '@collector/sportsapi-client-legacy'
import { PopupBuilder } from '@mobile/views/Relation/Shared/Popup/builder/PopupBuilder'

export const YellowCardPopup = new PopupBuilder('YellowCard', {
  incident_id: BeachVolleyballIncidentId.YellowCard,
  attribute_id: [BeachVolleyballCardsAttributeIds.YellowCard],
})
  .withTeamSelection()
  .omitDetailsView()
  .build()
