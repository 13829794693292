import { reactive, shallowReactive } from 'vue'

import { ApiClient, AuthInfoObject } from '@collector/sportsapi-client-legacy'

const state = shallowReactive({
  client: null as unknown as ApiClient,
  isClientReady: false,
  isAuthorizing: false,
  authInfo: null as AuthInfoObject | null,
  tokenExpired: false,
})

function setClient(sportsAPIClient: ApiClient): void {
  state.client = sportsAPIClient
}

function setClientAsReady(): void {
  state.isClientReady = true
}

function setClientAsNotReady(): void {
  state.isClientReady = false
}

function setAuthInfo(authInfo: AuthInfoObject): void {
  state.tokenExpired = false
  state.authInfo = reactive(authInfo)
}

function setAsIsAuthorizing(): void {
  state.isAuthorizing = true
}

function setAsNotAuthorizing(): void {
  state.isAuthorizing = false
}

function expireToken(): void {
  state.tokenExpired = true
}

function logout(): void {
  state.authInfo = null
  state.client.reauthorize()
}

export {
  state,
  setClient,
  setAsIsAuthorizing,
  setAsNotAuthorizing,
  setClientAsReady,
  setClientAsNotReady,
  setAuthInfo,
  expireToken,
  logout,
}
