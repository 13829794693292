<script lang="ts">
import { computed, ComputedRef, defineComponent, PropType } from 'vue'

import {
  Basketball3x3IncidentId,
  Basketball3x3StatId,
  Participant,
} from '@collector/sportsapi-client-legacy'
import { AddIncident } from '@mobile/ActionQueue/Actions/Actions'
import Button from '@mobile/components/Button/Button.vue'
import { byId } from '@mobile/reusables/entityUtils'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

export default defineComponent({
  components: { Button },
  props: {
    participant: {
      type: Object as PropType<Participant>,
      required: true,
    },
  },
  setup(props) {
    const { actionQueue, probableEvent, probableClock } = useEventInjections()
    const { incidentHandlersConfiguration } = useSportConfiguration()

    const isActive: ComputedRef<boolean> = computed(() => {
      return !!byId(props.participant.stats, Basketball3x3StatId.FoulBonus)
        ?.value
    })

    const isDisabled = computed(() => {
      const { disabled } =
        incidentHandlersConfiguration[Basketball3x3IncidentId.FoulBonus] ??
        incidentHandlersConfiguration.fallbackHandler

      return disabled({ incidentId: Basketball3x3IncidentId.FoulBonus })
    })

    function updateFoulBonusStat(): void {
      actionQueue.value.add(
        new AddIncident({
          incident_id: Basketball3x3IncidentId.FoulBonus,
          status_id: probableEvent.value.status_id,
          participant_team_id: props.participant.id,
          minute: probableClock.value.minute,
          second: probableClock.value.second,
        }),
      )
    }

    return {
      Basketball3x3IncidentId,
      updateFoulBonusStat,
      isActive,
      isDisabled,
    }
  },
})
</script>

<template>
  <Button
    size="xs"
    :color="isActive ? 'red' : 'white'"
    :disabled="isDisabled"
    @click="updateFoulBonusStat()"
  >
    FB
  </Button>
</template>
