import { Container } from 'inversify'

import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindEventClockConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.EventClockConfiguration)
    .toDynamicValue(() => {
      return { isEnabled: false }
    })
    .inSingletonScope()
}
