<script lang="ts">
import { defineComponent } from 'vue'

import HeaderButton from '@mobile/components/HeaderButton/HeaderButton.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: { HeaderButton },
  setup() {
    const { event } = useEventInjections()
    const eventId = event.value.data.id

    function goToTracker(): void {
      window.open(
        `${import.meta.env.VITE_RELATION_PREVIEW}?event=${eventId}&configId=150&lang=en&timeZone=Local`,
        '_blank',
      )
    }

    return { goToTracker }
  },
})
</script>

<template>
  <HeaderButton @click="goToTracker">Tracker</HeaderButton>
</template>
