<script lang="ts">
import { computed, defineComponent } from 'vue'

import { opChain } from '@collector/shared-utils'
import {
  CommonIncidentId,
  CricketIncidentId,
} from '@collector/sportsapi-client-legacy'
import BetMarketButton from '@mobile/views/Relation/Shared/BetMarketButton/BetMarketButton.vue'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useCricketConfiguration } from '@mobile/views/Relation/Sports/Cricket/CricketRelationDependencies/useCricketConfiguration'

import EndOfOverButton from '../../Buttons/EndOfOverButton.vue'
import { resolveStartOverIncidentEnabled } from '../resolveStartOverIncident'

export default defineComponent({
  components: {
    IncidentButton,
    BetMarketButton,
    EndOfOverButton,
  },
  setup() {
    const eventInjections = useEventInjections()
    const { activeParticipant } = useCricketConfiguration()
    const shouldStartOfOverButtonBeVisible =
      resolveStartOverIncidentEnabled(eventInjections)
    const shouldEndOfOverButtonBeVisible = computed(
      () => !shouldStartOfOverButtonBeVisible.value,
    )

    return {
      CricketIncidentId,
      CommonIncidentId,
      activeParticipant,
      shouldStartOfOverButtonBeVisible,
      shouldEndOfOverButtonBeVisible,
      opChain,
    }
  },
})
</script>

<template>
  <div class="grid content-between gap-4">
    <EndOfOverButton v-if="shouldEndOfOverButtonBeVisible" />
    <IncidentButton
      v-else-if="shouldStartOfOverButtonBeVisible"
      class="w-full"
      :type="'horizontal'"
      :incidentId="CricketIncidentId.StartOfOver"
      :participantId="opChain(activeParticipant.participant, (v) => v.id)"
      :size="'xl'"
      :color="'white'"
    />
    <BetMarketButton
      class="w-full flex-grow"
      :size="'xl'"
      :withIcon="true"
      :openMarketsIncident="CommonIncidentId.Betstart"
      :closeMarketsIncident="CommonIncidentId.Betstop"
      marketName="bet_status"
    />
  </div>
</template>
