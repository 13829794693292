<script lang="ts">
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash'
import {
  computed,
  ComputedRef,
  CSSProperties,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  Ref,
  ref,
  watch,
} from 'vue'

import { hasIncidentMasterAttribute } from '@collector/shared-ui-domain'
import { Incident } from '@collector/sportsapi-client'
import {
  Confirmation,
  IncidentActionType,
} from '@collector/sportsapi-client-legacy'
import {
  hasIncidentEmergencyCategory,
  isIncidentDeletable,
  isIncidentEditable,
} from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import Button from '@mobile/components/Button/Button.vue'
import DetailsButton from '@mobile/views/Relation/DetailsPanel/Buttons/DetailsButton.vue'
import { useSportOptions } from '@mobile/views/Relation/DetailsPanel/Sports/composables/useSportOptions'
import { useEventRelationSettings } from '@mobile/views/Relation/DetailsPanel/Sports/useEventRelationSettings'
import HistoryItemTableRowIncTimeUpdate from '@mobile/views/Relation/DetailsPanel/Table/HistoryItemTableRowIncTimeUpdate.vue'
import IncidentName from '@mobile/views/Relation/DetailsPanel/Table/IncidentName.vue'
import PlayerSelect from '@mobile/views/Relation/DetailsPanel/Table/PlayerSelect.vue'
import { useHistoryIncidents } from '@mobile/views/Relation/DetailsPanel/Table/useHistoryIncidents'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { onClickOutside } from '@vueuse/core'

import HistoryItemTableRowAttributes from './HistoryItemTableRowAttributes.vue'

export default defineComponent({
  components: {
    Button,
    PlayerSelect,
    HistoryItemTableRowAttributes,
    IncidentName,
    DetailsButton,
    HistoryItemTableRowIncTimeUpdate,
  },
  props: {
    linkedIncident: {
      type: Object as PropType<ProbableLinkedIncident>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    docked: {
      type: Boolean,
      default: false,
    },
    position: { type: Object as PropType<{ top: number; left: number }> },
  },
  emits: [
    'openIncidentsDeleteModal',
    'openIncidentsDetailModal',
    'pinIncident',
  ],
  setup(props) {
    const eventInjections = useEventInjections()
    const { sport } = eventInjections
    const sportOptionsComposable = useSportOptions(sport)
    const { toggleIncidentPinState, isIncidentPinned, selectIncident } =
      useHistoryIncidents(eventInjections)
    const { participantsIncidents, detailsIncidents } =
      useEventRelationSettings(eventInjections, sportOptionsComposable)
    const isDeletable = computed(() =>
      isIncidentDeletable(props.linkedIncident.incident, sport),
    )
    const time = computed(() => {
      const [minute, second] = props.linkedIncident.incident.event_time
        ?.split(':')
        .map(Number) ?? [0, 0]

      return {
        minute,
        second,
      }
    })
    const showAssignParticipantsSelects = computed(() => {
      return participantsIncidents.value.includes(
        props.linkedIncident.incident.incident_id,
      )
    })
    const showDetailsButton = computed(() => {
      const hasConfiguration =
        !!sportOptionsComposable.detailsConfiguration[
          props.linkedIncident.incident.incident_id
        ]

      return (
        hasConfiguration &&
        detailsIncidents.value.includes(
          props.linkedIncident.incident.incident_id,
        )
      )
    })
    const showAttributesCheckboxes = computed(() =>
      detailsIncidents.value.includes(
        props.linkedIncident.incident.incident_id,
      ),
    )

    const target: Ref<HTMLElement | null> = ref(null)
    const classes: ComputedRef<string[]> = computed(() => {
      const css = props.linkedIncident.probableLinkedParticipant?.css
      const defaultBackgroundColor = getDefaultBackgroundColor()
      const backgroundColor = css?.gradient32ToWhite
        ? [css?.gradient32ToWhite, 'hover:bg-gray hover:bg-opacity-5']
        : [defaultBackgroundColor]

      if (props.docked) {
        return [...backgroundColor, 'absolute', 'z-10', 'docked']
      }

      return [
        ...backgroundColor,
        isIncidentEditable(props.linkedIncident.incident, sport)
          ? 'cursor-pointer'
          : 'cursor-default',
      ]
    })

    const styleAbsolutePosition: ComputedRef<
      Pick<CSSProperties, 'top' | 'left'> | {}
    > = computed(() => {
      if (!props.position) {
        return {}
      }

      return {
        top: `${props.position.top}px`,
        left: `${props.position.left}px`,
      }
    })

    const staticLinkedIncident = ref(cloneDeep(props.linkedIncident))
    const isEditable = computed(() =>
      isIncidentEditable(props.linkedIncident.incident, sport),
    )

    let clickOutsideCancellationHandler: ReturnType<
      typeof onClickOutside
    > | null = null

    function getDefaultBackgroundColor(): string {
      switch (true) {
        case hasIncidentEmergencyCategory(props.linkedIncident.incident, sport):
        case hasIncidentMasterAttribute(
          props.linkedIncident.incident as unknown as Incident,
        ):
          return 'bg-dimmedred-20'
        default:
          return 'bg-white'
      }
    }

    function isIncidentDeleted(): boolean {
      return props.linkedIncident.incident.action === IncidentActionType.DELETE
    }

    function formatDate(date: number | undefined): string {
      if (!date) {
        return '-- : -- : --'
      }

      return dayjs(Number(date) * 1000).format('HH:mm:ss')
    }

    onMounted(() => {
      watch(
        () => props.docked,
        (docked) => {
          if (docked) {
            const ignore = ['#incidentDetailsModal', '#incidentDeleteModal']

            clickOutsideCancellationHandler = onClickOutside(
              target,
              () => selectIncident(null),
              { ignore },
            )
          } else {
            clickOutsideCancellationHandler?.()
          }
        },
        { immediate: true },
      )
    })

    onBeforeUnmount(() => {
      clickOutsideCancellationHandler?.()
    })

    return {
      target,
      sport,
      formatDate,
      isDeletable,
      classes,
      styleAbsolutePosition,
      staticLinkedIncident,
      isIncidentPinned,
      isIncidentDeletable,
      isIncidentDeleted,
      isEditable,
      showDetailsButton,
      toggleIncidentPinState,
      showAssignParticipantsSelects,
      showAttributesCheckboxes,
      time,
      Confirmation,
    }
  },
})
</script>

<template>
  <div
    ref="target"
    class="group table-row w-full"
    :class="classes"
    :style="styleAbsolutePosition"
  >
    <div class="table-cell p-2 align-middle">
      <button
        v-if="isEditable"
        class="hover:text-blue h-full w-full group-hover:opacity-100"
        :class="{ 'opacity-0': !isIncidentPinned(linkedIncident) }"
        @click.stop="toggleIncidentPinState(linkedIncident)"
      >
        <div style="width: 20px">
          <FontAwesomeIcon icon="thumbtack" />
        </div>
      </button>
    </div>
    <div
      class="table-cell p-2 align-middle"
      :class="{ 'line-through': isIncidentDeleted() }"
    >
      <div
        class="whitespace-nowrap"
        style="width: 100px"
      >
        {{ formatDate(linkedIncident.incident.ut) }}
      </div>
    </div>
    <div
      class="table-cell p-2 align-middle"
      :class="{ 'line-through': isIncidentDeleted() }"
      data-test-id="DetailsPanelEventStatusRow"
    >
      <div style="width: 100px">
        {{ linkedIncident.incident.event_status_name }}
      </div>
    </div>
    <div
      class="table-cell p-2 align-middle"
      :class="{ 'line-through': isIncidentDeleted() }"
      data-test-id="DetailsPanelEventTimeRow"
    >
      <HistoryItemTableRowIncTimeUpdate
        :docked
        :linkedIncident
      />
    </div>
    <div class="table-cell p-2 align-middle">
      <FontAwesomeIcon
        v-if="linkedIncident.incident.confirmation === Confirmation.Confirmed"
        class="text-green mx-1"
        icon="check"
      />
      <FontAwesomeIcon
        v-else
        class="text-red mx-1"
        :class="{
          'opacity-0':
            linkedIncident.incident.confirmation !== Confirmation.Cancelled,
        }"
        icon="times"
      />
    </div>
    <div
      class="table-cell p-2 align-middle"
      :class="{ 'line-through': isIncidentDeleted() }"
      style="width: 100%"
    >
      <div class="flex space-x-2 align-middle">
        <div
          class="flex w-1/3 space-x-2"
          data-test-id="DetailsPanelIncidentRow"
        >
          <div class="flex h-full w-full items-center justify-between">
            <IncidentName
              v-bind="{
                linkedIncident,
                showParticipants: true,
                showAttributes: true,
              }"
            />
            <div class="grid content-center">
              <Button
                v-if="isDeletable"
                class="px-3 opacity-0 group-hover:opacity-100"
                :color="'red'"
                :size="'xs'"
                @click="$emit('openIncidentsDeleteModal', linkedIncident)"
              >
                <FontAwesomeIcon :icon="'trash'" />
              </Button>
            </div>
          </div>
        </div>
        <div class="grid w-2/3 content-center px-4">
          <PlayerSelect
            v-if="showAssignParticipantsSelects"
            v-bind="{ linkedIncident, time }"
            :disabled="isIncidentDeleted()"
          />
          <HistoryItemTableRowAttributes
            v-else-if="showAttributesCheckboxes && docked"
            :linkedIncident
          />
        </div>
      </div>
    </div>
    <div class="table-cell p-2 align-middle">
      <div style="width: 100px">
        <DetailsButton
          v-if="showDetailsButton"
          v-bind="{ linkedIncident }"
          @click="$emit('openIncidentsDetailModal', linkedIncident)"
        />
      </div>
    </div>
  </div>
</template>
