import { Container } from 'inversify'

import { VolleyballIncidentId } from '@collector/sportsapi-client-legacy'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindEventStatusesList(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.EventStatusesList)
    .toDynamicValue(() => {
      return [
        VolleyballIncidentId.NotStarted,
        VolleyballIncidentId.StartDelayed,
        VolleyballIncidentId.MatchAboutToStart,
        VolleyballIncidentId.Finished,
        VolleyballIncidentId.Cancelled,
        VolleyballIncidentId.Abandoned,
        VolleyballIncidentId.Interrupted,
        VolleyballIncidentId.Postponed,
        VolleyballIncidentId.FirstSetStared,
        VolleyballIncidentId.BreakAfterFirstSet,
        VolleyballIncidentId.SecondSetStarted,
        VolleyballIncidentId.BreakAfterSecondSet,
        VolleyballIncidentId.ThirdSetStarted,
        VolleyballIncidentId.BreakAfterThirdSet,
        VolleyballIncidentId.FourthSetStarted,
        VolleyballIncidentId.BreakAfterFourthSet,
        VolleyballIncidentId.FifthSetStarted,
        VolleyballIncidentId.BreakAfterFifthSet,
        VolleyballIncidentId.GoldenSetStart,
        VolleyballIncidentId.FinishedAwardedWin,
        VolleyballIncidentId.ToFinish,
      ]
    })
    .inSingletonScope()
}
