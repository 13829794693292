import { computed, ComputedRef, watch } from 'vue'

import * as app from '@mobile/globalState/app'
import * as sportsAPI from '@mobile/globalState/sportsAPI'

export function useLoginFormVisibility(): { isVisible: ComputedRef<boolean> } {
  const isVisible = computed(
    () => !sportsAPI.state.authInfo || sportsAPI.state.tokenExpired,
  )

  watch(isVisible, () => {
    if (isVisible.value) {
      app.addOverlay()
    } else {
      app.removeOverlay()
    }
  })

  if (isVisible.value) {
    app.addOverlay()
  }

  return { isVisible }
}
