import { Event } from '@collector/sportsapi-client-legacy'

let currentEvent: null | Event = null

export function setCurrentEvent(event: Event): void {
  currentEvent = event
}

export function getCurrentEvent(): Event {
  if (currentEvent === null) throw Error('currentEvent is null')

  return currentEvent
}
