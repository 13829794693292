export const enum WebSocketServerEvent {
  Event = 'collector:event',
  Incident = 'collector:incident',
  Error = 'collector:error',
  SynchronizationFinished = 'collector:synchronization_finished',
  EventsScouts = 'collector:events_scouts',
  EventsLineups = 'collector:events_lineups',
  ScoutMessengerInfo = 'collector:scout_messenger_info',
}

export const enum WebSocketClientEvent {
  Subscribe = 'collector:subscribe',
  StartRelatingEvent = 'collector:start_relating_event',
  StopRelatingEvent = 'collector:stop_relating_event',
  SyncEvent = 'collector:sync_event',
  JoinRelation = 'collector:join_relation',
}
