<script lang="ts">
import { defineComponent } from 'vue'

import Alert from '@mobile/components/Alert/Alert.vue'
import Button from '@mobile/components/Button/Button.vue'
import { swRegistration, updateSW } from '@mobile/registerServiceWorker'

import { showLoader } from '../../globalState/loader'

export default defineComponent({
  components: {
    Alert,
    Button,
  },
  setup() {
    async function reloadApplication(): Promise<void> {
      showLoader('ScoutsCollector is updating. Please wait...')
      await swRegistration?.update()
      localStorage.setItem('appWasRecentlyUpdated', String(true))
      await updateSW()
    }

    return { reloadApplication }
  },
})
</script>

<template>
  <Alert>
    <strong>
      Your application is outdated and requires update to function properly.
      <br />
      Confirming this dialog or restarting the application will update it to new
      version.
    </strong>
    <template #controls>
      <Button
        class="mx-2"
        color="red"
        size="sm"
        @click="reloadApplication"
      >
        I understand
      </Button>
    </template>
  </Alert>
</template>
