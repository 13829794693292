<script lang="ts">
import { defineComponent, PropType } from 'vue'

import {
  CricketIncidentId,
  YesNoEnum,
} from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'
import { useIncidentAction } from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

import { useCricketConfiguration } from '../../CricketRelationDependencies/useCricketConfiguration'

export default defineComponent({
  components: { Button },
  props: {
    context: {
      type: Object as PropType<PopupContext | null>,
      required: false,
    },
  },
  setup(props) {
    const eventInjections = useEventInjections()
    const { activeParticipant } = useCricketConfiguration()

    function proceed(optionSelected: YesNoEnum): void {
      switch (optionSelected) {
        case YesNoEnum.Yes:
          proceedYesOption()
          break
        case YesNoEnum.No:
          props.context?.closeAllPopups()
          break
      }
    }

    function proceedYesOption(): void {
      useIncidentAction(eventInjections, {
        incident_id: CricketIncidentId.FreeHit,
        participant_team_id: activeParticipant.value.participant?.id,
      })

      props.context?.closeAllPopups()
    }

    return {
      proceed,
      YesNoEnum,
    }
  },
})
</script>

<template>
  <div class="grid h-full w-full place-items-center">
    <div class="flex flex-col">
      <div class="text-cloudygray pb-2 text-center font-bold">Free Hit?</div>
      <div class="flex space-x-2">
        <Button
          class="w-20"
          :size="'md'"
          :color="'green'"
          @click="proceed(YesNoEnum.Yes)"
        >
          Yes
        </Button>
        <Button
          class="w-20"
          :size="'md'"
          :color="'red'"
          @click="proceed(YesNoEnum.No)"
        >
          No
        </Button>
      </div>
    </div>
  </div>
</template>
