<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import { ButtonColor, ButtonSize } from './ButtonTypes'

export default defineComponent({
  props: {
    size: {
      type: String as PropType<ButtonSize>,
      required: true,
    },
    color: {
      type: String as PropType<ButtonColor>,
      required: true,
    },
    invertedColor: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const sizeClasses = computed(
      () =>
        ({
          xxs: ['h-7 p-2'],
          xs: ['h-9 p-2'],
          sm: ['h-11 p-2'],
          md: ['h-14 p-2'],
          lg: ['h-16 py-2 px-3'],
          xl: ['h-20 py-2 px-3'],
          xxl: ['h-24 py-2 px-3'],
        })[props.size],
    )

    const fontClasses = computed(
      () =>
        ({
          xxs: ['text-xxs leading-none'],
          xs: [],
          sm: [],
          md: [],
          lg: [],
          xl: [],
          xxl: [],
        })[props.size],
    )

    const bgClasses = computed(() => {
      const [backgroundColor, textColor] = {
        custom: ['white', null],
        white: ['white', 'gray'],
        green: ['green', 'white'],
        red: ['red', 'white'],
        blue: ['blue', 'white'],
        yellow: ['yellow', 'white'],
        home: ['home', 'white'],
        away: ['away', 'white'],
        darkblue: ['darkblue', 'white'],
        orange: ['orange', 'white'],
        gray: ['cloudygray', 'white'],
      }[props.color]

      return props.invertedColor
        ? [textColor ? `bg-${textColor}` : null, `text-${backgroundColor}`]
        : [`bg-${backgroundColor}`, textColor ? `text-${textColor}` : null]
    })

    const selectedClasses = computed(() =>
      props.selected ? ['text-blue', 'border', 'border-blue'] : [],
    )

    const classes = computed(() => [
      ...sizeClasses.value,
      ...bgClasses.value,
      ...selectedClasses.value,
      ...fontClasses.value,
    ])

    return { classes }
  },
})
</script>

<template>
  <button
    class="pointer-events-auto flex transform cursor-pointer items-center justify-center rounded-md text-sm font-bold transition hover:shadow active:shadow disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50"
    :class="classes"
    :disabled
  >
    <slot></slot>
  </button>
</template>
