import { onMounted, onUnmounted } from 'vue'

import { ScoutMessengerConnection } from '@mobile/ScoutMessengerConnection/ScoutMessengerConnection'

export function useWebSocketRelationStarter(
  scoutMessengerConnection: ScoutMessengerConnection,
  eventId: number,
): void {
  const onSocketConnect = function (): void {
    scoutMessengerConnection.startRelatingEvent(eventId)
  }

  onMounted(() => {
    scoutMessengerConnection.startRelatingEvent(eventId)
    scoutMessengerConnection.on('connected', onSocketConnect)
  })

  onUnmounted(() => {
    scoutMessengerConnection.off('connected', onSocketConnect)
    scoutMessengerConnection.stopRelatingEvent()
  })
}
