import { IconName } from '@fortawesome/fontawesome-svg-core'

import { AppNotificationType } from './types/AppNotificationType'

export const notificationColors: ReadonlyMap<AppNotificationType, string> =
  new Map([
    [AppNotificationType.Info, 'info'],
    [AppNotificationType.Error, 'error'],
    [AppNotificationType.Warning, 'warning'],
    [AppNotificationType.Debug, 'gray'],
  ])

export const notificationIcons: ReadonlyMap<AppNotificationType, IconName> =
  new Map([
    [AppNotificationType.Info, 'info-circle'],
    [AppNotificationType.Error, 'exclamation-circle'],
    [AppNotificationType.Warning, 'exclamation-triangle'],
    [AppNotificationType.Debug, 'code'],
  ])
