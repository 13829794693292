import { ApiError } from '../basic/ApiError'
import { Event } from '../basic/Event'
import { Incident } from '../basic/Incident'
import { PushMessageType } from '../websocket/WebsocketResponse'
import { EventsLineups } from './EventsLineups'
import { EventsScouts } from './EventsScouts'

export type CollectorPushMessage<Type = PushMessageType, DataType = unknown> = {
  eventId: number
  uuid: string
  type: Type
  pushMessageUt: number
  source: number
  data: DataType
}

export type CollectorPushMessageEvent = CollectorPushMessage<
  PushMessageType.Event,
  Event
>
export type CollectorPushMessageIncident = CollectorPushMessage<
  PushMessageType.Incident,
  Incident
>
export type CollectorPushMessageError = CollectorPushMessage<
  PushMessageType.Error,
  ApiError
>
export type CollectorPushMessageEventsScouts = CollectorPushMessage<
  PushMessageType.EventsScouts,
  EventsScouts
>
export type CollectorPushMessageEventsLineups = CollectorPushMessage<
  PushMessageType.EventsLineups,
  EventsLineups
>

export function isCollectorPushMessageEventsScouts(
  message: CollectorPushMessage,
): message is CollectorPushMessageEventsScouts {
  return message.type === PushMessageType.EventsScouts
}

export function isCollectorPushMessageEventsLineups(
  message: CollectorPushMessage,
): message is CollectorPushMessageEventsLineups {
  return message.type === PushMessageType.EventsLineups
}

export function isCollectorPushMessageEvent(
  message: CollectorPushMessage,
): message is CollectorPushMessageEvent {
  return message.type === PushMessageType.Event
}

export function isCollectorPushMessageIncident(
  message: CollectorPushMessage,
): message is CollectorPushMessageIncident {
  return message.type === PushMessageType.Incident
}

export function isCollectorPushMessageError(
  message: CollectorPushMessage,
): message is CollectorPushMessageError {
  return message.type === PushMessageType.Error
}
