import { Container } from 'inversify'
import { cloneDeep } from 'lodash'

import {
  Basketball3x3EventDetailId,
  Basketball3x3EventStatusId,
  Basketball3x3IncidentId,
  CollectorEventTime,
  Detail,
  EventStatusId,
  IncidentId,
} from '@collector/sportsapi-client-legacy'
import { AddIncident, UpdateEvent } from '@mobile/ActionQueue/Actions/Actions'
import * as popup from '@mobile/globalState/popup'
import { byId, getOrThrow, getTeamSide } from '@mobile/reusables/entityUtils'
import { resolveUpdateEventDetailsObjectFromEventDetailsDiff } from '@mobile/reusables/eventUtils'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { IncidentHandler } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/IncidentHandlersConfiguration'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'
import { ChallengePopup } from '@mobile/views/Relation/Sports/Basketball3x3/Popups/Challenge/ChallengePopup'
import { InjuryPopup } from '@mobile/views/Relation/Sports/Basketball3x3/Popups/Injury/InjuryPopup'
import { TimeoutPopup } from '@mobile/views/Relation/Sports/Basketball3x3/Popups/Timeout/TimeoutPopup'

import { FoulPopup } from '../Popups/Foul/FoulPopup'
import { FreeThrowsAwardedPopup } from '../Popups/FreeThrowsAwarded/FreeThrowsAwardedPopup'
import { ThrowInPopup } from '../Popups/ThrowIn/ThrowInPopup'
import { Basketball3x3RelationIoCBindings } from './Basketball3x3RelationDependencies'

export function bindIncidentHandlersConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.IncidentHandlersConfiguration)
    .toDynamicValue(async (ctx) => {
      const [actionQueue, probableEvent, probableClock, sport] =
        await Promise.all([
          await ctx.container.getAsync(RelationIoCBindings.ActionQueue),
          await ctx.container.getAsync(RelationIoCBindings.ProbableEvent),
          await ctx.container.getAsync(RelationIoCBindings.ProbableClock),
          await ctx.container.getAsync(RelationIoCBindings.Sport),
        ])
      const eventStatusesConfiguration = await ctx.container.getAsync(
        RelationSportCommonIoCBindings.EventStatusesConfiguration,
      )
      const activeParticipant = await ctx.container.getAsync(
        Basketball3x3RelationIoCBindings.ActiveParticipant,
      )

      const addIncident: IncidentHandler['handler'] = ({
        incidentId,
        participantId,
        time = {
          minute: probableClock.value.minute,
          second: probableClock.value.second,
        },
        attributeIds = [],
      }) => {
        actionQueue.value.add(
          new AddIncident({
            incident_id: incidentId,
            status_id: probableEvent.value.status_id,
            participant_team_id: participantId,
            ...time,
            attribute_ids: attributeIds,
          }),
        )
      }

      function setEventDetail(detailId: number, value: Detail['value']): void {
        actionQueue.value.add(
          new UpdateEvent({
            details: resolveUpdateEventDetailsObjectFromEventDetailsDiff(
              cloneDeep(probableEvent.value.details),
              [
                {
                  id: Basketball3x3EventDetailId.StartWithABall,
                  value,
                },
              ],
              sport.value.sportsAPIEntity,
            ),
            event: {},
          }),
        )
      }

      const isDisabledByCurrentEventStatus = (
        incidentId: IncidentId,
      ): boolean => {
        const eventStatusConfiguration =
          eventStatusesConfiguration[probableEvent.value.status_id]

        return !eventStatusConfiguration?.allowedIncidents?.includes(incidentId)
      }

      const getTimeForEventStatus = (
        eventStatusId: EventStatusId,
      ): CollectorEventTime => {
        const configuration = getOrThrow(
          eventStatusesConfiguration,
          eventStatusId,
        )

        return (
          configuration.time ?? {
            minute: 0,
            second: 0,
          }
        )
      }

      return {
        [Basketball3x3IncidentId.NotStarted]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(
                Basketball3x3EventStatusId.NotStarted,
              ),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [Basketball3x3IncidentId.StartDelayed]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(
                Basketball3x3EventStatusId.StartDelayed,
              ),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [Basketball3x3IncidentId.TVTimeout]: {
          handler: () =>
            addIncident({ incidentId: Basketball3x3IncidentId.TVTimeout }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [Basketball3x3IncidentId.PlayUnderReview]: {
          handler: () =>
            addIncident({
              incidentId: Basketball3x3IncidentId.PlayUnderReview,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [Basketball3x3IncidentId.BreakDuringTheGame]: {
          handler: () =>
            addIncident({
              incidentId: Basketball3x3IncidentId.BreakDuringTheGame,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [Basketball3x3IncidentId.Foul]: {
          handler: () =>
            popup.show({
              component: FoulPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [Basketball3x3IncidentId.Timeout]: {
          handler: () =>
            popup.show({
              component: TimeoutPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [Basketball3x3IncidentId.Challenge]: {
          handler: () =>
            popup.show({
              component: ChallengePopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [Basketball3x3IncidentId.FreeThrowsAwarded]: {
          handler: () =>
            popup.show({
              component: FreeThrowsAwardedPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [Basketball3x3IncidentId.ThrowIn]: {
          handler: () =>
            popup.show({
              component: ThrowInPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [Basketball3x3IncidentId.Injury]: {
          handler: () =>
            popup.show({
              component: InjuryPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [Basketball3x3IncidentId.InPossession]: {
          handler: ({ participantId }) => {
            const startWithABallDetail = byId(
              probableEvent.value.details,
              Basketball3x3EventDetailId.StartWithABall,
            )

            if (startWithABallDetail?.value === null && participantId) {
              const side = getTeamSide(
                probableEvent.value.participants,
                participantId,
              )

              setEventDetail(Basketball3x3EventDetailId.StartWithABall, side)
            }

            addIncident({
              incidentId: Basketball3x3IncidentId.InPossession,
              participantId: activeParticipant.value.participant?.id,
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [Basketball3x3IncidentId.TwoPointShotMade]: {
          handler: () =>
            addIncident({
              incidentId: Basketball3x3IncidentId.TwoPointShotMade,
              participantId: activeParticipant.value.participant?.id,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [Basketball3x3IncidentId.FinishedAfterOvertime]: {
          handler: () => {
            addIncident({
              incidentId: Basketball3x3IncidentId.FinishedAfterOvertime,
              time: null,
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [Basketball3x3IncidentId.FinishedRegularTime]: {
          handler: () => {
            addIncident({
              incidentId: Basketball3x3IncidentId.FinishedRegularTime,
              time: getTimeForEventStatus(
                Basketball3x3EventStatusId.FinishedRegularTime,
              ),
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [Basketball3x3IncidentId.FinishedAwardedWin]: {
          handler: () => {
            addIncident({
              incidentId: Basketball3x3IncidentId.FinishedAwardedWin,
              time: getTimeForEventStatus(
                Basketball3x3EventStatusId.FinishedAwardedWin,
              ),
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [Basketball3x3IncidentId.WaitingForOvertime]: {
          handler: () => {
            addIncident({
              incidentId: Basketball3x3IncidentId.WaitingForOvertime,
              time: getTimeForEventStatus(
                Basketball3x3EventStatusId.WaitingForOvertime,
              ),
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        fallbackHandler: {
          handler: ({ incidentId, participantId }) =>
            addIncident({
              incidentId,
              participantId,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
      }
    })
    .inSingletonScope()
}
