<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

import ChangeSidesButton from './ChangeSidesButton.vue'

export default defineComponent({
  components: {
    IncidentButton,
    ChangeSidesButton,
  },
  props: {
    incidentId: {
      type: Number,
      required: true,
    },
    time: {
      type: Object as PropType<{ minute: number; second: number }>,
      required: false,
      default: () => ({
        minute: 0,
        second: 0,
      }),
    },
  },
  emits: ['sidesSwitched'],
  setup(props) {
    const { sport } = useEventInjections()

    const incidentName = computed(() => {
      const sportIncident = sport.value.sportsAPIEntity.incidents.find(
        (incident) => incident.id === props.incidentId,
      )

      return sportIncident?.name ?? `Unknown(${props.incidentId})`
    })

    return { incidentName }
  },
})
</script>

<template>
  <div
    class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
  >
    <IncidentButton
      class="mb-5 h-auto w-44"
      :incidentId
      :color="'white'"
      :size="'md'"
      :type="'vertical'"
      :withIcon="false"
    >
      Send {{ incidentName }}
    </IncidentButton>
    <ChangeSidesButton />
  </div>
</template>
