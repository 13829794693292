<script lang="ts">
import { defineComponent, ref } from 'vue'

import {
  CommonIncidentId,
  HandballIncidentId,
} from '@collector/sportsapi-client-legacy'
import { useLiveViewBackground } from '@mobile/views/Relation/Shared/LiveView/useLiveViewBackground'
import PocketIncidents from '@mobile/views/Relation/Shared/PocketIncidents/PocketIncidents.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

import LiveViewCenterColumn from './LiveViewCenterColumn/LiveViewCenterColumn.vue'
import LiveViewHeader from './LiveViewHeader/LiveViewHeader.vue'
import LiveViewLeftColumn from './LiveViewLeftColumn/LiveViewLeftColumn.vue'
import LiveViewRightColumn from './LiveViewRightColumn/LiveViewRightColumn.vue'

export default defineComponent({
  components: {
    PocketIncidents,
    LiveViewHeader,
    LiveViewLeftColumn,
    LiveViewCenterColumn,
    LiveViewRightColumn,
  },
  setup() {
    const { probableEvent } = useEventInjections()
    const { eventHeaderConfiguration } = useSportConfiguration()
    const liveViewBackground = useLiveViewBackground(
      probableEvent,
      eventHeaderConfiguration,
    )
    const isPocketIncidentsMenuExpanded = ref(false)

    function showPocketIncidentsMenu(): void {
      isPocketIncidentsMenuExpanded.value = true
    }

    return {
      CommonIncidentId,
      HandballIncidentId,
      liveViewBackground,
      isPocketIncidentsMenuExpanded,
      showPocketIncidentsMenu,
    }
  },
})
</script>

<template>
  <div
    v-gesture:swipeup="showPocketIncidentsMenu"
    class="relative h-full overflow-hidden pt-1"
    :class="liveViewBackground"
  >
    <div class="flex h-full flex-col">
      <LiveViewHeader />
      <div
        class="grid h-full gap-4 px-2 pb-5 pt-1 sm:overflow-y-auto"
        style="grid-template-columns: 0.3fr 1fr 0.3fr; grid-template-rows: 100%"
      >
        <LiveViewLeftColumn />
        <LiveViewCenterColumn />
        <LiveViewRightColumn />
      </div>
    </div>
    <PocketIncidents
      :isExpanded="isPocketIncidentsMenuExpanded"
      :incidentIds="[
        HandballIncidentId.BlueCard,
        HandballIncidentId.Injury,
        HandballIncidentId.Timeout,
        HandballIncidentId.TimeoutOver,
        HandballIncidentId.RefereeConsultation,
        HandballIncidentId.BreakDuringGame,
      ]"
      @toggle="isPocketIncidentsMenuExpanded = !isPocketIncidentsMenuExpanded"
      @incidentAddedToQueue="isPocketIncidentsMenuExpanded = false"
    />
  </div>
</template>
