<script lang="ts">
import { cloneDeep } from 'lodash'
import { computed, defineComponent, PropType } from 'vue'

import { hasIncidentMasterAttribute } from '@collector/shared-ui-domain'
import { opChain } from '@collector/shared-utils'
import { Incident } from '@collector/sportsapi-client'
import {
  IncidentActionType,
  TeamSide,
} from '@collector/sportsapi-client-legacy'
import {
  hasIncidentEmergencyCategory,
  isIncidentEditable,
} from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import Button from '@mobile/components/Button/Button.vue'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'

import { useEventInjections } from '../../../RelationDependencies/useEventInjections'
import IncidentName from './IncidentName.vue'

export default defineComponent({
  components: {
    Button,
    IncidentName,
  },
  props: {
    linkedIncident: {
      type: Object as PropType<ProbableLinkedIncident>,
      required: true,
    },
  },
  emits: ['toggleEdit', 'toggleDelete'],
  setup(props) {
    const time = computed(() => {
      const [minute, second] = props.linkedIncident.incident.event_time?.split(
        ':',
      ) ?? ['00', '00']

      return {
        minute,
        second,
      }
    })
    const isDeleted = computed(
      () => props.linkedIncident.incident.action === IncidentActionType.DELETE,
    )
    const { probableHomeLineups, probableAwayLineups, sport } =
      useEventInjections()
    const staticLinkedIncident = computed(() => cloneDeep(props.linkedIncident))

    // TODO: fix type casting in this method
    function getRowBackground(): string | undefined {
      switch (true) {
        case hasIncidentEmergencyCategory(
          staticLinkedIncident.value.incident,
          sport,
        ):
        case hasIncidentMasterAttribute(
          staticLinkedIncident.value.incident as unknown as Incident,
        ):
          return 'bg-dimmedred-20'
        default:
          return opChain(
            props.linkedIncident.probableLinkedParticipant,
            (v) => v.css.gradient32To,
          )
      }
    }

    function getAttributesNames(attributeIds: number[]): string {
      return staticLinkedIncident.value.sportIncident.attributes
        .filter(({ id }) => attributeIds.includes(id))
        .map(({ name }) => name)
        .join(', ')
    }

    function getAssistParticipantName(
      side: TeamSide | null,
      participantId: number | null | undefined,
    ): string {
      const lineups =
        side === TeamSide.Home ? probableHomeLineups : probableAwayLineups

      const participant = lineups.value.find(
        ({ participant_id }) => participant_id === participantId,
      )

      return `${participant?.participant_shirt_number !== null ? participant?.participant_shirt_number + ' - ' : ''} ${
        participant?.participant_name
      }`
    }

    function canBeEdited(): boolean {
      return isIncidentEditable(staticLinkedIncident.value.incident, sport)
    }

    return {
      time,
      TeamSide,
      canBeEdited,
      getAttributesNames,
      getAssistParticipantName,
      staticLinkedIncident,
      isDeleted,
      getRowBackground,
      opChain,
    }
  },
})
</script>

<template>
  <div class="flex w-full flex-col rounded">
    <div class="bg-gray flex space-x-4 rounded-t p-1 text-white">
      <div class="text-xxs flex">
        <div class="mr-1 font-bold">Time:</div>
        <div>{{ linkedIncident.incident.event_time }}</div>
      </div>
      <div class="text-xxs flex">
        <div class="mr-1 font-bold">Status:</div>
        <div>{{ linkedIncident.incident.event_status_name }}</div>
      </div>
      <div
        v-if="linkedIncident.incident.subparticipant_name"
        class="text-xxs flex"
      >
        <div class="mr-1 font-bold">Participant:</div>
        <div>{{ linkedIncident.incident.subparticipant_name }}</div>
      </div>
      <div
        v-if="
          opChain(
            linkedIncident.incident.additional_info,
            (v) => v.assistant_id,
          )
        "
        class="text-xxs flex"
      >
        <div class="mr-1 font-bold">Assist:</div>
        <div>
          {{
            getAssistParticipantName(
              linkedIncident.side,
              opChain(
                linkedIncident.incident.additional_info,
                (v) => v.assistant_id,
              ),
            )
          }}
        </div>
      </div>
      <div class="text-xxs flex">
        <div
          v-if="linkedIncident.incident.info"
          class="mr-1 font-bold"
        >
          Info
        </div>
        <div>{{ linkedIncident.incident.info }}</div>
      </div>
    </div>
    <div
      class="flex h-12 items-center rounded-bl rounded-br px-2"
      :class="getRowBackground()"
      data-test-id="HistoryListElement"
    >
      <div
        class="text-gray flex w-20 items-center justify-between text-xs font-bold"
        :class="{ 'line-through': isDeleted }"
      >
        <div
          class="h-full w-8 px-1 py-2 text-center"
          data-test-id="HistoryListElementMinute"
        >
          {{ time.minute }}
        </div>
        :
        <div class="h-full w-8 px-1 py-2 text-center">
          {{ time.second }}
        </div>
      </div>

      <IncidentName
        :class="{ 'line-through': isDeleted }"
        :linkedIncident
        :showParticipants="false"
      />

      <div
        v-if="canBeEdited()"
        class="flex justify-end"
      >
        <Button
          class="w-10 text-center"
          :color="'blue'"
          :size="'xs'"
          @click="$emit('toggleEdit')"
        >
          <FontAwesomeIcon icon="edit" />
        </Button>
        <Button
          class="ml-2 w-10 text-center"
          :color="'red'"
          :size="'xs'"
          @click="$emit('toggleDelete')"
        >
          <FontAwesomeIcon icon="times" />
        </Button>
      </div>
    </div>
  </div>
</template>
