<script lang="ts">
import { compact } from 'lodash'
import { defineComponent, PropType, Ref, ref, watch } from 'vue'

import {
  CommonAttributeId,
  IncidentAttributeId,
} from '@collector/sportsapi-client-legacy'
import ChoiceButtonGroup, {
  ChoiceButtonGroupOption,
} from '@mobile/components/ChoiceButton/ChoiceButtonGroup.vue'
import { useAttributesChoiceOptions } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useAttributesChoiceOptions'
import { IncidentActionComposable } from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: { ChoiceButtonGroup },
  props: {
    title: {
      type: String,
      required: true,
    },
    attributes: {
      type: Array as PropType<IncidentAttributeId[]>,
      required: true,
    },
    updateIncident: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    incidentActionComposable: {
      type: Object as PropType<IncidentActionComposable>,
    },
    withNoneAttribute: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['attributeSelected'],
  setup(props, { emit }) {
    // TODO: add proper types when `ChoiceButtonGroup` will emit proper value type (it only emits strings now)
    const selectedAttributes: Ref<string[] | string> = ref(
      props.multiselect
        ? [String(CommonAttributeId.None)]
        : String(CommonAttributeId.None),
    )
    const attributeInputOptions: ChoiceButtonGroupOption[] =
      useAttributesChoiceOptions(
        props.attributes,
        useEventInjections(),
        props.withNoneAttribute,
      )

    watch(selectedAttributes, (selectedAttributes) => {
      const attribute_ids = compact([selectedAttributes].flat().map(Number))

      props.incidentActionComposable?.updateIncident({ attribute_ids })

      emit('attributeSelected', selectedAttributes)
    })

    return {
      selectedAttributes,
      attributeInputOptions,
    }
  },
})
</script>

<template>
  <div class="flex items-center space-x-3 px-4">
    <div class="font-bold">
      {{ title }}
    </div>
    <ChoiceButtonGroup
      v-model:value="selectedAttributes as string[]"
      :options="attributeInputOptions"
      :type="'horizontal'"
      :undeselectable="false"
      :multiselect
    />
  </div>
</template>
