import { computed } from 'vue'

import {
  HandballEventStatusId,
  HandballIncidentId,
} from '@collector/sportsapi-client-legacy'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import SidesSwitchOverlay from '@mobile/views/Relation/Shared/SidesSwitchOverlay/SidesSwitchOverlay.vue'
import StartWithPossessionOverlay from '@mobile/views/Relation/Sports/Handball/LiveView/Pitch/PitchOverlays/StartWithPossessionOverlay.vue'

/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
export function usePitchOverlays() {
  const { probableIncidents, probableEvent } = useEventInjections()

  const overlayComponents = computed(() => {
    return [
      {
        component: SidesSwitchOverlay,
        isVisible: !probableIncidents.value.find(
          (incident) =>
            incident.incident_id === HandballIncidentId.MatchAboutToStart,
        ),
        props: {
          incidentId: HandballIncidentId.MatchAboutToStart,
          time: {
            minute: 0,
            second: 0,
          },
        },
      },
      {
        component: StartWithPossessionOverlay,
        isVisible: !probableIncidents.value.find(
          (incident) =>
            incident.incident_id === HandballIncidentId.StartWithPossession,
        ),
        props: {
          incidentId: HandballIncidentId.StartWithPossession,
          time: {
            minute: 0,
            second: 0,
          },
        },
      },
      {
        component: SidesSwitchOverlay,
        isVisible:
          probableEvent.value.status_id === HandballEventStatusId.NotStarted,
        props: {
          incidentId: HandballIncidentId.FirstHalfStarted,
          time: {
            minute: 0,
            second: 0,
          },
        },
      },
      {
        component: SidesSwitchOverlay,
        isVisible:
          probableEvent.value.status_id === HandballEventStatusId.Halftime,
        props: {
          incidentId: HandballIncidentId.SecondHalfStarted,
          time: {
            minute: 30,
            second: 0,
          },
        },
      },
      {
        component: SidesSwitchOverlay,
        isVisible:
          probableEvent.value.status_id ===
          HandballEventStatusId.WaitingForExtratime,
        props: {
          incidentId: HandballIncidentId.FirstExtratimeFirstHalfStarted,
          time: {
            minute: 60,
            second: 0,
          },
        },
      },
      {
        component: SidesSwitchOverlay,
        isVisible:
          probableEvent.value.status_id ===
          HandballEventStatusId.ExtratimeHalftime,
        props: {
          incidentId: HandballIncidentId.FirstExtratimeSecondHalfStarted,
          time: {
            minute: 65,
            second: 0,
          },
        },
      },
      {
        component: SidesSwitchOverlay,
        isVisible:
          probableEvent.value.status_id ===
          HandballEventStatusId.WaitingForSecondExtratime,
        props: {
          incidentId: HandballIncidentId.SecondExtratimeFirstHalfStarted,
          time: {
            minute: 70,
            second: 0,
          },
        },
      },
      {
        component: SidesSwitchOverlay,
        isVisible:
          probableEvent.value.status_id ===
          HandballEventStatusId.SecondExtratimeHalftime,
        props: {
          incidentId: HandballIncidentId.SecondExtratimeSecondHalfStarted,
          time: {
            minute: 75,
            second: 0,
          },
        },
      },
    ]
  })

  const visibleOverlay = computed(() =>
    overlayComponents.value.find(({ isVisible }) => isVisible),
  )

  return {
    overlayComponents,
    visibleOverlay,
  }
}
