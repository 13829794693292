import { computed } from 'vue'

import {
  BeachVolleyballEventStatusId,
  BeachVolleyballIncidentId,
} from '@collector/sportsapi-client-legacy'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import SidesSwitchOverlay from '@mobile/views/Relation/Shared/SidesSwitchOverlay/SidesSwitchOverlay.vue'

/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
export function usePitchOverlays() {
  const { probableEvent } = useEventInjections()

  const overlayComponents = computed(() => {
    return [
      {
        component: SidesSwitchOverlay,
        isVisible:
          probableEvent.value.status_id ===
          BeachVolleyballEventStatusId.NotStarted,
        props: { incidentId: BeachVolleyballIncidentId.FirstSetStared },
      },
      {
        component: SidesSwitchOverlay,
        isVisible:
          probableEvent.value.status_id ===
          BeachVolleyballEventStatusId.BreakAfterFirstSet,
        props: { incidentId: BeachVolleyballIncidentId.SecondSetStarted },
      },
      {
        component: SidesSwitchOverlay,
        isVisible:
          probableEvent.value.status_id ===
          BeachVolleyballEventStatusId.BreakAfterSecondSet,
        props: { incidentId: BeachVolleyballIncidentId.ThirdSetStarted },
      },
    ]
  })

  const visibleOverlay = computed(() =>
    overlayComponents.value.find(({ isVisible }) => isVisible),
  )

  return {
    overlayComponents,
    visibleOverlay,
  }
}
