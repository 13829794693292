import { onMounted, onUnmounted } from 'vue'

import {
  CollectorPushMessageEvent,
  CollectorPushMessageEventsLineups,
  CollectorPushMessageIncident,
} from '@collector/sportsapi-client-legacy'
import { AppNotificationType } from '@mobile/reusables/types/AppNotificationType'
import { ScoutMessengerConnection } from '@mobile/ScoutMessengerConnection/ScoutMessengerConnection'

import { EventInjections } from './Shared/RelationDependencies/useEventInjections'

export function useDebugNotifications(
  eventInjections: EventInjections,
  scoutMessengerConnection: ScoutMessengerConnection,
): void {
  const { notifications } = eventInjections

  function handleIncidentData(incident: CollectorPushMessageIncident): void {
    notifications.sendNotification({
      title: 'New incident data',
      content: JSON.stringify(incident),
      type: AppNotificationType.Debug,
    })
  }

  function handleEventData(event: CollectorPushMessageEvent): void {
    notifications.sendNotification({
      title: 'New event data',
      content: JSON.stringify(event),
      type: AppNotificationType.Debug,
    })
  }

  function handleEventsLineupsData(
    eventsLineups: CollectorPushMessageEventsLineups,
  ): void {
    notifications.sendNotification({
      title: 'New events lineups data',
      content: JSON.stringify(eventsLineups),
      type: AppNotificationType.Debug,
    })
  }

  onMounted(() => {
    scoutMessengerConnection.on('incident', handleIncidentData)
    scoutMessengerConnection.on('event', handleEventData)
    scoutMessengerConnection.on('eventsLineups', handleEventsLineupsData)
  })

  onUnmounted(() => {
    scoutMessengerConnection.off('incident', handleIncidentData)
    scoutMessengerConnection.off('event', handleEventData)
    scoutMessengerConnection.off('eventsLineups', handleEventsLineupsData)
  })
}
