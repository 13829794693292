import { Container } from 'inversify'

import {
  AmericanFootballEventStatusId,
  AmericanFootballIncidentId,
} from '@collector/sportsapi-client-legacy'
import { changeEventStatus } from '@mobile/ActionQueue/Actions/Event/EventAction'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindIncidentsConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.IncidentsConfiguration)
    .toDynamicValue(() => {
      return {
        [AmericanFootballIncidentId.FinishedRegularTime]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              AmericanFootballEventStatusId.Finished,
            )
          },
        },
        [AmericanFootballIncidentId.Interrupted]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              AmericanFootballEventStatusId.Interrupted,
            )
          },
        },
        [AmericanFootballIncidentId.Cancelled]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              AmericanFootballEventStatusId.Cancelled,
            )
          },
        },
        [AmericanFootballIncidentId.Postponed]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              AmericanFootballEventStatusId.Postponed,
            )
          },
        },
        [AmericanFootballIncidentId.Abandoned]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              AmericanFootballEventStatusId.Abandoned,
            )
          },
        },
        fallbackIncidentConfiguration: {},
      }
    })
    .inSingletonScope()
}
