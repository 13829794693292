import {
  ClockStatus,
  Event,
  UpdateEventObject,
} from '@collector/sportsapi-client-legacy'
import EventAction from '@mobile/ActionQueue/Actions/Event/EventAction'
import { getCurrentEvent } from '@mobile/globalState/event'
import * as sportsAPI from '@mobile/globalState/sportsAPI'

type UpdateEventTimeDTO = {
  event: Pick<UpdateEventObject, 'clock_time' | 'clock_status'>
}

export default class UpdateEventTime extends EventAction {
  public readonly type = 'UpdateEventTime'

  constructor(
    public readonly updateEventDTO: UpdateEventTimeDTO,
    private compensateForRunningTime: boolean,
  ) {
    super()
  }

  public async execImpl(): Promise<void> {
    this.checkForScoutMessengerConnectionOrThrow()

    let clockTime = this.updateEventDTO.event.clock_time ?? undefined
    if (this.compensateForRunningTime && clockTime !== undefined) {
      clockTime += this.resolveRunningTimeCompensation()
    }

    const event = getCurrentEvent()

    await this.confirmEventUpdateRequest(
      event.id,
      sportsAPI.state.client.updateEvent(event.id, {
        details: {},
        event: {
          clock_time: clockTime,
          clock_status: this.updateEventDTO.event.clock_status,
        },
      }),
    )
  }

  public resolveRunningTimeCompensation(): number {
    // Not the most accurate way of compensating for latency but
    // still should be better than nothing
    const latencyMsCompensation = 100

    const currentTimestamp = this.fromMillisecondsToSeconds(
      Date.now() + latencyMsCompensation,
    )
    const timestampOfActionCreation = this.fromMillisecondsToSeconds(
      this.actionTime + latencyMsCompensation,
    )
    const secondsSinceActionCreation =
      currentTimestamp - timestampOfActionCreation

    return secondsSinceActionCreation
  }

  private fromMillisecondsToSeconds(ms: number): number {
    return Math.round(ms / 1000)
  }

  public applyProbableResult(probableEvent: Event): void {
    if (this.updateEventDTO.event.clock_time !== undefined) {
      probableEvent.clock_time = this.updateEventDTO.event.clock_time
    }

    if (this.updateEventDTO.event.clock_status !== undefined) {
      if (this.updateEventDTO.event.clock_status === true) {
        probableEvent.clock_status = ClockStatus.Running
      } else if (this.updateEventDTO.event.clock_status === false) {
        probableEvent.clock_status = ClockStatus.Stopped
      }
    }
  }
}
