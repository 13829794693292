import {
  IceHockeyIncidentId,
  IncidentId,
  SoccerIncidentId,
  SportId,
} from '@collector/sportsapi-client-legacy'

type PartialRecord<K extends keyof never, T> = {
  [P in K]?: T
}

type DetailsPanelPocketIncidentsMapElement = IncidentId[] | undefined

const detailsPanelPocketIncidentsMap: PartialRecord<
  SportId,
  DetailsPanelPocketIncidentsMapElement
> = {
  [SportId.Soccer]: [
    SoccerIncidentId.Tackle,
    SoccerIncidentId.Interception,
    SoccerIncidentId.BallSkills,
    SoccerIncidentId.LooseBall,
  ],
  [SportId.IceHockey]: [IceHockeyIncidentId.Hit],
}

export function getDetailsPanelPocketIncidents(
  sportId: SportId,
): DetailsPanelPocketIncidentsMapElement {
  return detailsPanelPocketIncidentsMap[sportId]
}
