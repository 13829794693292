export enum AmericanFootballIncidentAttributeNames {
  PassIncomplete = 'Pass incomplete',
  PassComplete = 'Pass complete',
  Fumble = 'Fumble',
  Interception = 'Interception',
  Kickoff = 'Kickoff',
  Punt = 'Punt',
  Passing = 'Passing',
  Rushing = 'Rushing',
  InterceptionReturn = 'Interception return',
  FumbleRecovery = 'Fumble recovery',
  PuntReturn = 'Punt return',
  KickoffReturn = 'Kickoff return',
  Assisted = 'Assisted',
  NotAssisted = 'Not assisted',
}
