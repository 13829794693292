<script lang="ts">
import { defineComponent } from 'vue'

import { HandballIncidentId } from '@collector/sportsapi-client-legacy'
import { useEventStatus } from '@mobile/views/Relation/Shared/EventStatus/useEventStatus'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useHandballConfiguration } from '@mobile/views/Relation/Sports/Handball/HandballRelationDependencies/useHandballConfiguration'

import Pitch from '../Pitch/Pitch.vue'
import EventTime from './EventTime.vue'

export default defineComponent({
  components: {
    IncidentButton,
    Pitch,
    EventTime,
  },
  setup() {
    const { probableEvent } = useEventInjections()
    const { isMatchStarted } = useEventStatus(probableEvent)
    const { activeParticipant } = useHandballConfiguration()

    return {
      HandballIncidentId,
      isMatchStarted,
      activeParticipant,
    }
  },
})
</script>

<template>
  <div
    class="grid grid-cols-6 gap-2"
    style="grid-template-rows: auto 1fr auto"
  >
    <div
      v-if="isMatchStarted"
      class="col-span-full flex items-end justify-center"
    >
      <EventTime />
    </div>
    <div
      v-if="isMatchStarted"
      class="col-start-1 col-end-2 row-start-2 row-end-5 grid gap-3"
    >
      <IncidentButton
        class="h-full"
        :type="'vertical'"
        :size="'xxs'"
        :color="'white'"
        :incidentId="HandballIncidentId.Foul"
      />
      <IncidentButton
        class="h-full"
        :type="'vertical'"
        :size="'xxs'"
        :color="activeParticipant.color"
        :incidentId="HandballIncidentId.Turnover"
      />
    </div>
    <Pitch />
    <div
      v-if="isMatchStarted"
      class="col-start-6 col-end-7 row-start-2 row-end-5 grid gap-3"
    >
      <IncidentButton
        class="h-full"
        :type="'vertical'"
        :size="'xxs'"
        :color="activeParticipant.color"
        :incidentId="HandballIncidentId.ThrowSaved"
      />
      <IncidentButton
        class="h-full"
        :type="'vertical'"
        :size="'xxs'"
        :color="activeParticipant.color"
        :incidentId="HandballIncidentId.MissedThrow"
      />
    </div>
    <div
      v-if="isMatchStarted"
      class="col-span-full row-start-5 grid grid-cols-8 gap-3 lg:mt-2"
    >
      <div class="col-span-2"></div>
      <IncidentButton
        class="text-xxs col-span-2 lg:h-full"
        :type="'horizontal'"
        size="xs"
        :color="'white'"
        :incidentId="HandballIncidentId.RedCard"
      />
      <IncidentButton
        class="text-xxs col-span-2 lg:h-full"
        :type="'horizontal'"
        size="xs"
        :color="'white'"
        :incidentId="HandballIncidentId.YellowCard"
      />
      <div class="col-span-1"></div>
    </div>
  </div>
</template>
