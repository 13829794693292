<script lang="ts">
import { defineComponent } from 'vue'

import * as popup from '@mobile/globalState/popup'

export default defineComponent({
  props: {
    hideIcon: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { hidePopup: popup.hide }
  },
})
</script>

<template>
  <div
    class="text-gray flex items-center justify-between px-3 py-4 text-base font-bold"
  >
    <div
      v-if="!hideIcon"
      class="flex items-center"
    >
      <FontAwesomeIcon
        class="text-blue cursor-pointer text-xl"
        icon="chevron-left"
        @click="hidePopup()"
      />
      <div
        class="border-gray mx-2 h-5 w-px border-l border-solid opacity-20"
      ></div>
      <slot name="title"></slot>
    </div>
    <slot name="controls"></slot>
  </div>
</template>
