<script lang="ts">
import { defineComponent } from 'vue'

import { Basketball3x3IncidentId } from '@collector/sportsapi-client-legacy'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'

import Pitch from '../Pitch/Pitch.vue'
import EventTime from './EventTime.vue'

export default defineComponent({
  components: {
    Pitch,
    EventTime,
    IncidentButton,
  },
  setup() {
    return { Basketball3x3IncidentId }
  },
})
</script>

<template>
  <div
    class="grid grid-cols-6 gap-1"
    style="grid-template-rows: auto 1fr auto"
  >
    <div class="col-span-full flex items-end justify-center">
      <EventTime />
    </div>
    <Pitch />
    <div
      class="col-span-full col-start-2 col-end-6 row-start-6 row-end-6 grid grid-cols-2 gap-3"
    >
      <IncidentButton
        class="h-full"
        :type="'vertical'"
        :size="'xxs'"
        :incidentId="Basketball3x3IncidentId.ThrowIn"
      />
      <IncidentButton
        class="h-full"
        :type="'vertical'"
        :size="'xxs'"
        :incidentId="Basketball3x3IncidentId.FreeThrowsAwarded"
      >
        FT awarded
      </IncidentButton>
    </div>
  </div>
</template>
