import { CommonResultId } from '../common/CommonResultId'
import { EventStatusId } from '../EventStatusId'
import { ResultId } from '../ResultId'
import { Basketball3x3ResultId } from './Basketball3x3ResultId'
import { Basketball3x3EventStatusId } from './EventStatusId'

export const basketball3x3StatusResultsMap: {
  [key in EventStatusId]?: ResultId[]
} = {
  [Basketball3x3EventStatusId.RegularTime]: [
    CommonResultId.Result,
    CommonResultId.RegularTime,
    Basketball3x3ResultId.RegularTime,
  ],
}
