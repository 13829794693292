<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { opChain } from '@collector/shared-utils'
import { CricketIncidentId } from '@collector/sportsapi-client-legacy'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'
import { IncidentActionComposable } from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

import DecisionReviewButton from '../../Buttons/DecisionReviewButton.vue'
import ExtrasButton from '../../Buttons/ExtrasButton.vue'
import NoRunsButton from '../../Buttons/NoRunsButton.vue'
import OutButton from '../../Buttons/OutButton.vue'
import RunsButton from '../../Buttons/RunsButton.vue'

export default defineComponent({
  components: {
    NoRunsButton,
    ExtrasButton,
    RunsButton,
    IncidentButton,
    OutButton,
    DecisionReviewButton,
  },
  props: {
    incidentActionComposable: {
      type: Object as PropType<IncidentActionComposable>,
      required: true,
    },
    staticLinkedProbableIncidents: {
      type: Array as PropType<ProbableLinkedIncident[]>,
      required: true,
    },
    context: {
      type: Object as PropType<PopupContext | null>,
      required: false,
    },
  },
  setup() {
    const { sport } = useEventInjections()

    function getIncidentName(incidentId: CricketIncidentId): string {
      return sport.value.getIncidentTranslation(incidentId)
    }

    return {
      CricketIncidentId,
      getIncidentName,
      close,
      opChain,
    }
  },
})
</script>

<template>
  <div class="grid grid-cols-3 grid-rows-3 gap-4 px-4">
    <OutButton
      v-bind="{
        context,
        incidentActionComposable,
        staticLinkedProbableIncidents,
      }"
    />
    <NoRunsButton v-bind="{ context }" />
    <RunsButton v-bind="{ context }" />
    <ExtrasButton v-bind="{ context }" />
    <IncidentButton
      :size="'sm'"
      :color="'white'"
      :withIcon="false"
      :type="'vertical'"
      :incidentId="CricketIncidentId.DeadBall"
      @incidentHandled="opChain(context, (v) => v.closeAllPopups())"
    />

    <DecisionReviewButton v-bind="{ context }" />
  </div>
</template>
