export enum CricketEventDetailId {
  WeatherConditions = 8,
  PitchConditions = 36,
  NumberOfInnings = 149,
  StartWithPossessionTeam = 229,
  EventFormat = 236,
  TossWon = 238,
  RaisedTarget = 241,
  RaisedOvers = 242,
  MaximumOversPerBowler = 237,
}
