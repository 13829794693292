<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { nullCoalesce } from '@collector/shared-utils'
import { AttributeOption } from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'

export default defineComponent({
  components: { Button },
  props: {
    value: {
      type: Object,
      required: true,
    },
    scoreBeforeChange: {
      type: Number,
      required: true,
    },
    wickets: {
      type: Object,
      required: true,
    },
    options: {
      type: Array as PropType<AttributeOption[]>,
      required: true,
    },
  },
  emits: ['update:value'],
  setup(props, context) {
    function add(): void {
      context.emit(
        'update:value',
        props.options.find(
          (option: AttributeOption) => option.value === props.value.value + 1,
        ),
      )
    }

    function subtract(): void {
      context.emit(
        'update:value',
        props.options.find(
          (option: AttributeOption) => option.value === props.value.value - 1,
        ),
      )
    }

    return {
      add,
      subtract,
      nullCoalesce,
    }
  },
})
</script>

<template>
  <div class="grid grid-cols-3">
    <div class="text-gray flex items-center text-base font-bold">
      Score changes
    </div>
    <div class="col-span-2 grid grid-cols-3 justify-items-center">
      <Button
        class="mb-2 w-12 text-xl"
        color="white"
        size="md"
        :disabled="value.value === 0"
        @click="subtract"
      >
        -
      </Button>
      <div class="text-center text-xl font-bold">
        <div>
          <span class="text-cloudygray">{{ scoreBeforeChange }}</span>
          <span class="text-darksilver px-4">></span>
          <span class="text-info">{{ value.value }}</span>
        </div>
        <div>
          <span class="text-cloudygray">
            {{ scoreBeforeChange }}/{{ nullCoalesce(wickets.value, 0) }}
          </span>
          <span class="text-darksilver px-4">></span>
          <span class="text-info">
            {{ value.value }}/{{ nullCoalesce(wickets.value, 0) }}
          </span>
        </div>
      </div>
      <Button
        class="mb-2 w-12 text-xl"
        color="white"
        size="md"
        :disabled="value.value === 9"
        @click="add"
      >
        +
      </Button>
    </div>
  </div>
</template>
