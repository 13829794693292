import Dexie from 'dexie'

import { SubParticipant } from '@collector/sportsapi-client-legacy'

import { Repository } from './Repository'

export type SeasonParticipantRecord = {
  eventId: number
  participantId: number
  participants: SubParticipant[]
}

export class SeasonParticipantsRepository extends Repository<SeasonParticipantRecord> {
  static readonly tableName = 'seasonParticipants'
  static readonly indexes = '[eventId+participantId], eventId'

  constructor(database: Dexie) {
    super(
      database.table<SeasonParticipantRecord>(
        SeasonParticipantsRepository.tableName,
      ),
    )
  }

  public async removeByEventId(eventId: number): Promise<number> {
    return this.table.where({ eventId }).delete()
  }

  public async findForParticipant(
    eventId: number,
    participantId: number,
  ): Promise<SeasonParticipantRecord | undefined> {
    return this.table
      .where({
        participantId,
        eventId,
      })
      .last()
  }

  public async findForEvent(
    eventId: number,
  ): Promise<SeasonParticipantRecord[]> {
    return this.table.where({ eventId }).toArray()
  }

  public async save(
    eventId: number,
    participantId: number,
    participants: SubParticipant[],
  ): Promise<SeasonParticipantRecord | undefined> {
    await this.table
      .where({
        participantId,
        eventId,
      })
      .delete()
    await this.table.put({
      eventId,
      participantId,
      participants,
    })

    return this.findForParticipant(eventId, participantId)
  }
}
