<script lang="ts">
import { computed, defineComponent } from 'vue'

import {
  SoccerEventStatusId,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: { IncidentButton },
  setup() {
    const { probableEvent } = useEventInjections()

    const penaltyIncidentId = computed(() => {
      return probableEvent.value.status_id ===
        SoccerEventStatusId.PenaltyShootout
        ? SoccerIncidentId.MissedPenalty
        : SoccerIncidentId.Penalty
    })

    return {
      probableEvent,
      penaltyIncidentId,
      SoccerIncidentId,
    }
  },
})
</script>

<template>
  <IncidentButton
    class="w-full flex-grow"
    :type="'horizontal'"
    :size="'xs'"
    :color="'white'"
    :incidentId="penaltyIncidentId"
  />
</template>
