<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { opChain } from '@collector/shared-utils'
import { BeachVolleyballIncidentId } from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import * as popup from '@mobile/globalState/popup'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'
import PopupControls from '@mobile/views/Relation/Shared/Popup/PopupControls.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: {
    PopupControls,
    Button,
  },
  props: {
    context: {
      type: Object as PropType<PopupContext | null>,
      required: false,
    },
  },
  emits: ['close'],
  setup(props) {
    const eventInjections = useEventInjections()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const incidentId = popup.state.props?.incidentId as
      | BeachVolleyballIncidentId
      | undefined

    function proceed(): void {
      props.context?.sendIncident(
        { incident_id: incidentId as BeachVolleyballIncidentId },
        true,
      )
    }

    function getIncidentName(): string {
      if (typeof incidentId === 'undefined') {
        return ''
      }

      const incidentTranslation =
        eventInjections.sport.value.getIncidentTranslation(incidentId)

      return incidentTranslation.replace(/\bstarted\b/g, '') + '?'
    }

    return {
      getIncidentName,
      proceed,
      opChain,
    }
  },
})
</script>

<template>
  <div>
    <div class="grid place-items-center px-4 py-6">
      <div class="text-cloudygray py-2 text-center text-sm font-bold">
        Do you want to start {{ getIncidentName() }}
      </div>
    </div>

    <PopupControls>
      <template #buttons>
        <div
          class="grid gap-2"
          style="grid-template-columns: repeat(auto-fit, minmax(90px, 1fr))"
        >
          <div
            v-for="i in 3"
            :key="`item-${i}`"
          ></div>
          <Button
            :size="'md'"
            :color="'red'"
            @click="opChain(context, (v) => v.closeAllPopups())"
          >
            Cancel
          </Button>
          <Button
            :size="'md'"
            :color="'green'"
            @click="proceed()"
          >
            Send
          </Button>
        </div>
      </template>
    </PopupControls>
  </div>
</template>
