export enum LegalBallAttributesId {
  Runs = 594,
  Byes = 595,
  LegByes = 596,
  Wicket = 597,
  Zero = 598,
  One = 599,
  Two = 600,
  Three = 601,
  Four = 602,
  Five = 603,
  Six = 604,
  Seven = 605,
  Eight = 606,
  Nine = 607,
  BoundaryFour = 641,
  BoundarySix = 642,
}
