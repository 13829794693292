import { eventBus } from '@mobile/globalState/eventBus'
import { AppNotificationType } from '@mobile/reusables/types/AppNotificationType'
import { Notifications } from '@mobile/views/Relation/Shared/RelationDependencies/types'

import { Hook } from './Hook'

export class HookUnconfirmedIncidents implements Hook {
  constructor(private readonly notifications: Notifications) {}

  public install(): void {
    eventBus.on('UnconfirmedIncidentsReminder', this.notify)
  }

  public uninstall(): void {
    eventBus.off('UnconfirmedIncidentsReminder', this.notify)
  }

  private notify = (): void => {
    this.notifications.sendNotification({
      type: AppNotificationType.Warning,
      title: 'Incidents are waiting for confirmation',
      content: 'There are incidents that are waiting for confirmation',
    })
  }
}
