<script lang="ts">
import { cloneDeep } from 'lodash'
import { defineComponent, reactive, Ref, ref, shallowRef } from 'vue'

import { Participant } from '@collector/sportsapi-client-legacy'
import { UpdateEventParticipants } from '@mobile/ActionQueue/Actions/Actions'
import UpdateEvent from '@mobile/ActionQueue/Actions/Event/UpdateEvent'
import * as popup from '@mobile/globalState/popup'
import {
  participantDiff,
  resolveUpdateEventDetailsObjectFromEventDetailsDiff,
  resolveUpdateEventObjectFromEventDiff,
} from '@mobile/reusables/eventUtils'
import PopupControls from '@mobile/views/Relation/Shared/Popup/PopupControls.vue'
import StackedPopup from '@mobile/views/Relation/Shared/Popup/StackedPopup.vue'
import EventPreparationEventDetails from '@mobile/views/Relation/Shared/Popups/EventPreparation/EventPreparationEventDetails.vue'
import EventPreparationTeamDetails from '@mobile/views/Relation/Shared/Popups/EventPreparation/EventPreparationTeamDetails.vue'
import EventHeaderToggle from '@mobile/views/Relation/Shared/Popups/EventSettings/EventHeaderToggle.vue'
import Skins from '@mobile/views/Relation/Shared/Popups/Skins/Skins.vue'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import Formations from '@mobile/views/Relation/Sports/Soccer/Popups/Formation/Formation.vue'

import { EventPreparationChildPopupType } from '../EventPreparation/EventPreparationChildPopupType'
import EventPreparationEventStats from '../EventPreparation/EventPreparationEventStats.vue'
import Lineups from '../Lineups/Lineups.vue'

export default defineComponent({
  components: {
    EventHeaderToggle,
    EventPreparationTeamDetails,
    PopupControls,
    EventPreparationEventDetails,
    EventPreparationEventStats,
    Skins,
    StackedPopup,
    Lineups,
    Formations,
  },
  setup() {
    const {
      probableEvent,
      actionQueue,
      sport,
      probableLinkedFirstParticipant,
      probableLinkedSecondParticipant,
    } = useEventInjections()
    const probableHomeParticipant =
      probableLinkedFirstParticipant.value.getParticipant()
    const probableAwayParticipant =
      probableLinkedSecondParticipant.value.getParticipant()
    const staticHomeParticipant = reactive(
      cloneDeep(probableHomeParticipant.value),
    )
    const staticAwayParticipant = reactive(
      cloneDeep(probableAwayParticipant.value),
    )

    const preparationEventDetailsData = ref(cloneDeep(probableEvent.value))

    const openedPopup: Ref<EventPreparationChildPopupType | null> = ref(null)
    const selectedParticipant: Ref<ProbableLinkedParticipant | null> =
      shallowRef(null)

    const save = (): void => {
      actionQueue.value.add(
        new UpdateEvent({
          event: resolveUpdateEventObjectFromEventDiff(
            probableEvent.value,
            preparationEventDetailsData.value,
          ),
          details: resolveUpdateEventDetailsObjectFromEventDetailsDiff(
            probableEvent.value.details,
            preparationEventDetailsData.value.details,
            sport.value.sportsAPIEntity,
          ),
        }),
      )

      actionQueue.value.add(
        new UpdateEventParticipants(
          {
            data: [
              participantDiff(
                staticHomeParticipant,
                probableHomeParticipant.value,
              ),
              participantDiff(
                staticAwayParticipant,
                probableAwayParticipant.value,
              ),
            ],
          },
          true,
        ),
      )

      popup.hide()
    }

    const cancel = (): void => {
      popup.hide()
    }

    function handleOpenPopup({
      popupType,
      probableLinkedParticipant,
    }: {
      popupType: EventPreparationChildPopupType
      probableLinkedParticipant: ProbableLinkedParticipant
    }): void {
      openedPopup.value = popupType
      selectedParticipant.value = probableLinkedParticipant
    }

    function isPopupOpened(popupType: EventPreparationChildPopupType): boolean {
      return openedPopup.value === popupType
    }

    function closeChildPopup(): void {
      openedPopup.value = null
    }

    function participantStatsUpdated(participant: Participant): void {
      if (participant.id === probableHomeParticipant.value.id) {
        probableHomeParticipant.value.stats = participant.stats
      } else if (participant.id === probableAwayParticipant.value.id) {
        probableAwayParticipant.value.stats = participant.stats
      }
    }

    return {
      save,
      cancel,
      isPopupOpened,
      closeChildPopup,
      handleOpenPopup,
      selectedParticipant,
      preparationEventDetailsData,
      EventPreparationChildPopupType,
      probableLinkedFirstParticipant,
      probableLinkedSecondParticipant,
      participantStatsUpdated,
    }
  },
})
</script>

<template>
  <div>
    <EventHeaderToggle :name="'Team details'">
      <EventPreparationTeamDetails
        :event="preparationEventDetailsData"
        @openPopup="handleOpenPopup"
      />
    </EventHeaderToggle>
    <EventHeaderToggle :name="'Event Info'">
      <EventPreparationEventDetails :event="preparationEventDetailsData" />
    </EventHeaderToggle>

    <EventHeaderToggle :name="'Event Stats'">
      <EventPreparationEventStats
        :event="preparationEventDetailsData"
        @participantStatsUpdated="participantStatsUpdated"
      />
    </EventHeaderToggle>

    <PopupControls
      @confirm="save()"
      @cancel="cancel()"
    />

    <template v-if="selectedParticipant">
      <StackedPopup v-if="isPopupOpened(EventPreparationChildPopupType.Skins)">
        <Skins
          :probableLinkedParticipant="selectedParticipant"
          @close="closeChildPopup"
        />
      </StackedPopup>
      <StackedPopup
        v-if="isPopupOpened(EventPreparationChildPopupType.Lineups)"
      >
        <Lineups
          :probableLinkedParticipant="selectedParticipant"
          @close="closeChildPopup"
        />
      </StackedPopup>
      <StackedPopup
        v-if="isPopupOpened(EventPreparationChildPopupType.Formations)"
      >
        <Formations
          :probableLinkedParticipant="selectedParticipant"
          @close="closeChildPopup"
        />
      </StackedPopup>
    </template>
  </div>
</template>
