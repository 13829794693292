import {
  BasketballIncidentAttributeNames,
  BasketballIncidentId,
  DetailType,
} from '@collector/sportsapi-client-legacy'
import { SportDetailsConfiguration } from '@mobile/views/Relation/DetailsPanel/Sports/composables/useSportOptions/types'

export enum BasketballGroupNames {
  ShootType = 'Shoot type',
}

const ShootTypeAttributes = [
  BasketballIncidentAttributeNames.JumpShot,
  BasketballIncidentAttributeNames.Layup,
  BasketballIncidentAttributeNames.Dunk,
  BasketballIncidentAttributeNames.Hook,
]

export const BasketballDetailsConfiguration: SportDetailsConfiguration<BasketballIncidentId> =
  {
    [BasketballIncidentId.TwoPointShotMade]: [
      {
        name: BasketballGroupNames.ShootType,
        attributes: ShootTypeAttributes,
        cols: 4,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
    ],
    [BasketballIncidentId.TwoPointShotMissed]: [
      {
        name: BasketballGroupNames.ShootType,
        attributes: ShootTypeAttributes,
        cols: 4,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
    ],
    [BasketballIncidentId.ThreePointShotMade]: [
      {
        name: BasketballGroupNames.ShootType,
        attributes: [BasketballIncidentAttributeNames.JumpShot],
        cols: 1,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
    ],
    [BasketballIncidentId.ThreePointShotMissed]: [
      {
        name: BasketballGroupNames.ShootType,
        attributes: [BasketballIncidentAttributeNames.JumpShot],
        cols: 1,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
    ],
  }
export const BasketballAttributesConfiguration = {
  [BasketballIncidentId.TwoPointShotMade]: ShootTypeAttributes,
  [BasketballIncidentId.TwoPointShotMissed]: ShootTypeAttributes,
  [BasketballIncidentId.ThreePointShotMade]: [
    BasketballIncidentAttributeNames.JumpShot,
  ],
  [BasketballIncidentId.ThreePointShotMissed]: [
    BasketballIncidentAttributeNames.JumpShot,
  ],
}
