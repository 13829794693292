export const enum HandballEventStatusId {
  NotStarted = 1,
  Interrupted = 2,
  Cancelled = 3,
  Postponed = 5,
  StartDelayed = 6,
  Abandoned = 7,
  Halftime = 9,
  Finished = 11,
  FinishedAwardedWin = 12,
  FinishedAfterPenalties = 13,
  FinishedAfterExtratime = 14,
  Extratime = 15,
  FirstHalf = 33,
  SecondHalf = 34,
  ExtratimeFirstHalf = 35,
  ExtratimeSecondHalf = 36,
  ExtratimeHalftime = 37,
  WaitingForExtratime = 48,
  PenaltyShootout = 141,
  ToFinish = 152,
  SecondExtratimeFirstHalf = 269,
  SecondExtratimeHalftime = 270,
  SecondExtratimeSecondHalf = 271,
  FinishedAfterSecondExtratime = 332,
  WaitingForSecondExtratime = 333,
  WaitingForPenalties = 334,
}

export const handballBreakEventStatuses = Object.seal([
  HandballEventStatusId.Halftime,
  HandballEventStatusId.WaitingForExtratime,
  HandballEventStatusId.ExtratimeHalftime,
  HandballEventStatusId.WaitingForSecondExtratime,
  HandballEventStatusId.SecondExtratimeHalftime,
] as HandballEventStatusId[])
