import {
  EventPreparationTeamDetail,
  EventPreparationTeamDetailsSportFactory,
} from './types'

export function eventPreparationTeamDetailsCricketFactory(): EventPreparationTeamDetailsSportFactory {
  function getDetails(): EventPreparationTeamDetail {
    return {
      buttons: {
        lineups: {
          name: 'Lineups',
          icon: 'users',
          disabled: false,
        },
        skins: {
          name: 'Skins',
          icon: 'tshirt',
          disabled: true,
        },
      },
      hasLineupsSelect: {
        show: true,
        name: 'Has lineups',
        disabled: false,
      },
    }
  }

  return { getDetails }
}
