<script lang="ts">
import { cloneDeep, find } from 'lodash'
import { defineComponent, PropType } from 'vue'

import { nullCoalesce, opChain } from '@collector/shared-utils'
import {
  CricketIncidentId,
  CricketResultId,
  IllegalBallAttributesId,
  IllegalBallAttributesOptions,
  LegalBallAttributesId,
  LegalBallAttributesOptions,
  Result,
} from '@collector/sportsapi-client-legacy'
import { byId } from '@mobile/reusables/entityUtils'
import { IncidentActionComposable } from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

import { useCricketConfiguration } from '../../CricketRelationDependencies/useCricketConfiguration'

export default defineComponent({
  props: {
    incidentActionComposable: {
      type: Object as PropType<IncidentActionComposable>,
      required: true,
    },
    staticLinkedProbableIncidents: {
      type: Array as PropType<ProbableLinkedIncident[]>,
      required: true,
    },
  },
  setup(props) {
    const probableIncident =
      props.incidentActionComposable.getProbableIncident().value
    const { activeParticipant } = useCricketConfiguration()
    const { linkedProbableIncidents } = useEventInjections()
    const isWide = probableIncident.attribute_ids.includes(
      IllegalBallAttributesId.Wides,
    )
    const isBoundaryFour = probableIncident.attribute_ids.includes(
      LegalBallAttributesId.BoundaryFour,
    )
    const isBoundarySix = probableIncident.attribute_ids.includes(
      LegalBallAttributesId.BoundarySix,
    )
    const { participant } = cloneDeep(activeParticipant.value)
    const localLinkedProbableIncidents = cloneDeep(
      linkedProbableIncidents.value,
    )

    function getInningRuns(): Result | null {
      return (
        byId(
          activeParticipant.value.participant?.results ?? [],
          CricketResultId.FirstInningRuns,
        ) ?? null
      )
    }

    function getWicketsResult(): Result | null {
      return byId(participant?.results ?? [], CricketResultId.Wickets) ?? null
    }

    function getScoreChange(): number | undefined {
      const ballAttributesOption = [
        ...LegalBallAttributesOptions,
        ...IllegalBallAttributesOptions,
      ].find((option) => {
        return probableIncident.attribute_ids.find(
          (attribute) => attribute === option.id,
        )
      })

      if (isBoundaryFour) {
        return 4
      }

      if (isBoundarySix) {
        return 6
      }

      return isWide
        ? (ballAttributesOption?.value ?? 1)
        : ballAttributesOption?.value
    }

    function hasOut(): boolean {
      const lastIncident = find(localLinkedProbableIncidents, (incident) => {
        return incident?.incident?.id !== probableIncident.id
      })

      return lastIncident?.incident?.incident_id === CricketIncidentId.Out
    }

    return {
      activeParticipant,
      getScoreChange,
      hasOut,
      getInningRuns,
      getWicketsResult,
      opChain,
      nullCoalesce,
    }
  },
})
</script>

<template>
  <div class="grid h-full w-full place-content-center">
    <div class="w-64">
      <div
        class="flex justify-between bg-white px-4 py-2"
        data-test-id="BallSummaryScoreChanges"
      >
        <div>Score changes</div>
        <div :class="[`text-${activeParticipant.side}`, 'font-bold']">
          {{ getScoreChange() }}
        </div>
      </div>
      <div class="flex justify-between bg-white px-4 py-2">
        <div>Short runs</div>
        <div :class="[`text-${activeParticipant.side}`, 'font-bold']">0</div>
      </div>
      <div class="flex justify-between bg-white px-4 py-2">
        <div>Wickets</div>
        <div
          v-if="!hasOut()"
          :class="[`text-${activeParticipant.side}`, 'font-bold']"
        >
          0
        </div>
        <div
          v-else
          :class="[`text-${activeParticipant.side}`, 'font-bold']"
        >
          1
        </div>
      </div>
      <div class="flex justify-between bg-white px-4 py-2">
        <div>Result</div>
        <div :class="[`text-${activeParticipant.side}`, 'font-bold']">
          {{
            nullCoalesce(
              opChain(getInningRuns(), (v) => v.value),
              '-',
            )
          }}
          /
          {{
            nullCoalesce(
              opChain(getWicketsResult(), (v) => v.value),
              '-',
            )
          }}
        </div>
      </div>
    </div>
  </div>
</template>
