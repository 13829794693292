<script lang="ts">
import { ComputedRef, defineComponent, PropType } from 'vue'

import { Participant } from '@collector/sportsapi-client-legacy'
import ParticipantButton from '@mobile/components/ParticipantButton/ParticipantButton.vue'
import { IncidentActionComposable } from '@mobile/views/Relation/Shared/Popup/useIncidentAction'

export default defineComponent({
  components: { ParticipantButton },
  props: {
    incidentActionComposable: {
      type: Object as PropType<IncidentActionComposable>,
      required: true,
    },
  },
  setup(props) {
    const probableLinkedParticipant = props.incidentActionComposable
      .getProbableLinkedParticipant()
      ?.getParticipant() as ComputedRef<Participant>

    return { probableLinkedParticipant }
  },
})
</script>

<template>
  <div class="px-4">
    <ParticipantButton :participant="probableLinkedParticipant" />
  </div>
</template>
