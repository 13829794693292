import { Container } from 'inversify'

import {
  Confirmation,
  SoccerEventDetailId,
  SoccerEventStatusId,
  SoccerIncidentId,
  SoccerStatId,
  soccerStatusResultsMap,
} from '@collector/sportsapi-client-legacy'
import {
  addToEventResult,
  addToEventStat,
  changeEventStatus,
  setEventDetail,
} from '@mobile/ActionQueue/Actions/Event/EventAction'
import { byId, isAwayTeam, isHomeTeam } from '@mobile/reusables/entityUtils'
import { ClockStatusAction } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/IncidentsConfiguration'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindIncidentsConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.IncidentsConfiguration)
    .toDynamicValue(() => {
      return {
        [SoccerIncidentId.NotStarted]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              SoccerEventStatusId.NotStarted,
            )
          },
        },
        [SoccerIncidentId.StartDelayed]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              SoccerEventStatusId.StartDelayed,
            )
          },
        },
        [SoccerIncidentId.FinishedAwardedWin]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              SoccerEventStatusId.FinishedAwardedWin,
            )
          },
        },
        [SoccerIncidentId.Postponed]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              SoccerEventStatusId.Postponed,
            )
          },
        },
        [SoccerIncidentId.Cancelled]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              SoccerEventStatusId.Cancelled,
            )
          },
        },
        [SoccerIncidentId.Abandoned]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              SoccerEventStatusId.Abandoned,
            )
          },
        },
        [SoccerIncidentId.FirstHalf]: {
          clockStatusAction: ClockStatusAction.Start,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              SoccerEventStatusId.FirstHalf,
            )
          },
        },
        [SoccerIncidentId.Halftime]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              SoccerEventStatusId.Halftime,
            )
          },
        },
        [SoccerIncidentId.SecondHalf]: {
          clockStatusAction: ClockStatusAction.Start,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              SoccerEventStatusId.SecondHalf,
            )
          },
        },
        [SoccerIncidentId.FinishedRegularTime]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              SoccerEventStatusId.FinishedRegularTime,
            )
          },
        },
        [SoccerIncidentId.WaitingForExtratime]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              SoccerEventStatusId.WaitingForExtratime,
            )
          },
        },
        [SoccerIncidentId.ExtratimeFirstHalf]: {
          clockStatusAction: ClockStatusAction.Start,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              SoccerEventStatusId.ExtratimeFirstHalf,
            )
          },
        },
        [SoccerIncidentId.ExtratimeHalftime]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              SoccerEventStatusId.ExtratimeHalftime,
            )
          },
        },
        [SoccerIncidentId.ExtratimeSecondHalf]: {
          clockStatusAction: ClockStatusAction.Start,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              SoccerEventStatusId.ExtratimeSecondHalf,
            )
          },
        },
        [SoccerIncidentId.FinishedAfterExtratime]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              SoccerEventStatusId.FinishedAfterExtratime,
            )
          },
        },
        [SoccerIncidentId.WaitingForPenalty]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              SoccerEventStatusId.WaitingForPenalty,
            )
          },
        },
        [SoccerIncidentId.PenaltyShootout]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              SoccerEventStatusId.PenaltyShootout,
            )
          },
        },
        [SoccerIncidentId.FinishedAfterPenalties]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              SoccerEventStatusId.FinishedAfterPenalties,
            )
          },
        },
        [SoccerIncidentId.Corner]: {
          clockStatusAction: ClockStatusAction.None,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            addToEventStat(
              probableEvent,
              dto.participant_team_id,
              SoccerStatId.Corners,
              1,
            )
          },
          applyUpdateIncidentResultToEvent(
            probableEvent,
            sport,
            dto,
            probableIncident,
          ) {
            if (
              dto.confirmation === Confirmation.Confirmed &&
              probableIncident.confirmation === Confirmation.Cancelled
            ) {
              addToEventStat(
                probableEvent,
                dto.participant_team_id,
                SoccerStatId.Corners,
                1,
              )
            }

            if (
              dto.confirmation === Confirmation.Cancelled &&
              probableIncident.confirmation !== dto.confirmation
            ) {
              addToEventStat(
                probableEvent,
                dto.participant_team_id,
                SoccerStatId.Corners,
                -1,
              )
            }
          },
          applyDeleteIncidentResultToEvent(
            probableEvent,
            sport,
            probableIncident,
          ) {
            if (probableIncident.confirmation !== Confirmation.Cancelled) {
              addToEventStat(
                probableEvent,
                probableIncident.participant_id,
                SoccerStatId.Corners,
                -1,
              )
            }
          },
        },
        [SoccerIncidentId.YellowCard]: {
          clockStatusAction: ClockStatusAction.None,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            addToEventStat(
              probableEvent,
              dto.participant_team_id,
              SoccerStatId.YellowCards,
              1,
            )
          },
          applyUpdateIncidentResultToEvent(
            probableEvent,
            sport,
            dto,
            probableIncident,
          ) {
            if (
              dto.confirmation === Confirmation.Confirmed &&
              probableIncident.confirmation === Confirmation.Cancelled
            ) {
              addToEventStat(
                probableEvent,
                dto.participant_team_id,
                SoccerStatId.YellowCards,
                1,
              )
            }

            if (
              dto.confirmation === Confirmation.Cancelled &&
              probableIncident.confirmation !== dto.confirmation
            ) {
              addToEventStat(
                probableEvent,
                dto.participant_team_id,
                SoccerStatId.YellowCards,
                -1,
              )
            }
          },
          applyDeleteIncidentResultToEvent(
            probableEvent,
            sport,
            probableIncident,
          ) {
            if (probableIncident.confirmation !== Confirmation.Cancelled) {
              addToEventStat(
                probableEvent,
                probableIncident.participant_id,
                SoccerStatId.YellowCards,
                -1,
              )
            }
          },
        },
        [SoccerIncidentId.RedCard]: {
          clockStatusAction: ClockStatusAction.None,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            addToEventStat(
              probableEvent,
              dto.participant_team_id,
              SoccerStatId.RedCards,
              1,
            )
          },
          applyUpdateIncidentResultToEvent(
            probableEvent,
            sport,
            dto,
            probableIncident,
          ) {
            if (
              dto.confirmation === Confirmation.Confirmed &&
              probableIncident.confirmation === Confirmation.Cancelled
            ) {
              addToEventStat(
                probableEvent,
                dto.participant_team_id,
                SoccerStatId.RedCards,
                1,
              )
            }

            if (
              dto.confirmation === Confirmation.Cancelled &&
              probableIncident.confirmation !== dto.confirmation
            ) {
              addToEventStat(
                probableEvent,
                dto.participant_team_id,
                SoccerStatId.RedCards,
                -1,
              )
            }
          },
          applyDeleteIncidentResultToEvent(
            probableEvent,
            sport,
            probableIncident,
          ) {
            if (probableIncident.confirmation !== Confirmation.Cancelled) {
              addToEventStat(
                probableEvent,
                probableIncident.participant_id,
                SoccerStatId.RedCards,
                -1,
              )
            }
          },
        },
        [SoccerIncidentId.Goal]: {
          clockStatusAction: ClockStatusAction.None,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            const resultsToUpdate =
              soccerStatusResultsMap[probableEvent.status_id] ?? []
            resultsToUpdate.forEach((result) => {
              addToEventResult(
                probableEvent,
                dto.participant_team_id,
                result,
                1,
              )
            })
          },
          applyUpdateIncidentResultToEvent(
            probableEvent,
            sport,
            dto,
            probableIncident,
          ) {
            const resultsToUpdate =
              soccerStatusResultsMap[probableEvent.status_id] ?? []

            if (
              dto.confirmation === Confirmation.Confirmed &&
              probableIncident.confirmation === Confirmation.Cancelled
            ) {
              resultsToUpdate.forEach((result) => {
                addToEventResult(
                  probableEvent,
                  dto.participant_team_id,
                  result,
                  1,
                )
              })
            }

            if (
              dto.confirmation === Confirmation.Cancelled &&
              probableIncident.confirmation !== dto.confirmation
            ) {
              resultsToUpdate.forEach((result) => {
                addToEventResult(
                  probableEvent,
                  dto.participant_team_id,
                  result,
                  -1,
                )
              })
            }
          },
          applyDeleteIncidentResultToEvent(
            probableEvent,
            sport,
            probableIncident,
          ) {
            if (probableIncident.confirmation !== Confirmation.Cancelled) {
              const resultsToUpdate =
                soccerStatusResultsMap[probableEvent.status_id] ?? []
              resultsToUpdate.forEach((result) => {
                addToEventResult(
                  probableEvent,
                  probableIncident.participant_id,
                  result,
                  -1,
                )
              })
            }
          },
        },
        [SoccerIncidentId.KickOff]: {
          clockStatusAction: ClockStatusAction.None,
          resetClockTime: false,
          applyAddIncidentResultToEvent(
            probableEvent,
            sport,
            dto,
            probableIncident,
          ) {
            const participant = byId(
              probableEvent.participants,
              probableIncident.participant_id,
            )
            if (!participant) {
              return
            }

            let kickOffSide = ''
            if (isHomeTeam(participant)) {
              kickOffSide = 'home'
            } else if (isAwayTeam(participant)) {
              kickOffSide = 'away'
            }

            setEventDetail(
              probableEvent,
              SoccerEventDetailId.KickOffTeam,
              kickOffSide,
            )
          },
          applyDeleteIncidentResultToEvent(probableEvent) {
            setEventDetail(probableEvent, SoccerEventDetailId.KickOffTeam, '')
          },
        },
        fallbackIncidentConfiguration: {
          clockStatusAction: ClockStatusAction.None,
          resetClockTime: false,
        },
      }
    })
    .inSingletonScope()
}
