import { Container } from 'inversify'

import {
  IncidentId,
  VolleyballCardsAttributeIds,
  VolleyballIncidentId,
} from '@collector/sportsapi-client-legacy'
import { AddIncident } from '@mobile/ActionQueue/Actions/Actions'
import * as popup from '@mobile/globalState/popup'
import { byId, getOppositeTeam } from '@mobile/reusables/entityUtils'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { IncidentHandler } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/IncidentHandlersConfiguration'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

import { ChallengePopup } from '../Popups/Challenge/ChallengePopup'
import { ConfirmSetStartPopup } from '../Popups/ConfirmSetStart/ConfirmSetStartPopup'
import { MatchBallPopup } from '../Popups/MatchBall/MatchBallPopup'
import { PointPopup } from '../Popups/Point/PointPopup'
import { RedCardPopup } from '../Popups/RedCardPopup'
import { RefereeChallengePopup } from '../Popups/RefereeChallenge/RefereeChallengePopup'
import { SetBallPopup } from '../Popups/SetBall/SetBallPopup'
import { SetWonPopup } from '../Popups/SetWon/SetWonPopup'
import { TechnicalErrorsPopup } from '../Popups/TechnicalError/TechnicalErrorsPopup'
import { TimeoutsPopup } from '../Popups/Timeouts/TimeoutsPopup'
import { YellowCardPopup } from '../Popups/YellowCardPopup'

export function bindIncidentHandlersConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.IncidentHandlersConfiguration)
    .toDynamicValue(async (ctx) => {
      const [actionQueue, probableEvent, probableClock] = await Promise.all([
        await ctx.container.getAsync(RelationIoCBindings.ActionQueue),
        await ctx.container.getAsync(RelationIoCBindings.ProbableEvent),
        await ctx.container.getAsync(RelationIoCBindings.ProbableClock),
        await ctx.container.getAsync(RelationIoCBindings.ProbableIncidents),
        await ctx.container.getAsync(
          RelationIoCBindings.ProbableLinkedHomeParticipant,
        ),
      ])
      const eventStatusesConfiguration = ctx.container.get(
        RelationSportCommonIoCBindings.EventStatusesConfiguration,
      )

      const addIncident: IncidentHandler['handler'] = ({
        incidentId,
        participantId,
        time = {
          minute: probableClock.value.minute,
          second: probableClock.value.second,
        },
      }) => {
        actionQueue.value.add(
          new AddIncident({
            incident_id: incidentId,
            status_id: probableEvent.value.status_id,
            participant_team_id: participantId,
            ...time,
          }),
        )
      }

      const isDisabledByCurrentEventStatus = (
        incidentId: IncidentId,
      ): boolean => {
        const eventStatusConfiguration =
          eventStatusesConfiguration[probableEvent.value.status_id]

        return !eventStatusConfiguration?.allowedIncidents?.includes(incidentId)
      }

      return {
        [VolleyballIncidentId.NotStarted]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.MatchAboutToStart]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.Abandoned]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.Cancelled]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.Finished]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.FinishedAwardedWin]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.Interrupted]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.ToFinish]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.Postponed]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.StartDelayed]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.FirstSetStared]: {
          handler: ({ incidentId }) =>
            popup.show({
              component: ConfirmSetStartPopup,
              showHeader: true,
              props: { incidentId },
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.SecondSetStarted]: {
          handler: ({ incidentId }) =>
            popup.show({
              component: ConfirmSetStartPopup,
              showHeader: true,
              props: { incidentId },
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.ThirdSetStarted]: {
          handler: ({ incidentId }) =>
            popup.show({
              component: ConfirmSetStartPopup,
              showHeader: true,
              props: { incidentId },
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.FourthSetStarted]: {
          handler: ({ incidentId }) =>
            popup.show({
              component: ConfirmSetStartPopup,
              showHeader: true,
              props: { incidentId },
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.FifthSetStarted]: {
          handler: ({ incidentId }) =>
            popup.show({
              component: ConfirmSetStartPopup,
              showHeader: true,
              props: { incidentId },
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.GoldenSetStart]: {
          handler: ({ incidentId }) =>
            popup.show({
              component: ConfirmSetStartPopup,
              showHeader: true,
              props: { incidentId },
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.BreakAfterFirstSet]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.BreakAfterSecondSet]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.BreakAfterThirdSet]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.BreakAfterFourthSet]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.BreakAfterFifthSet]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.BreakDuringTheGame]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.RepeatAction]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.YellowCard]: {
          handler: () =>
            popup.show({
              component: YellowCardPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.RedCard]: {
          handler: () =>
            popup.show({
              component: RedCardPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.SetBall]: {
          handler: () =>
            popup.show({
              component: SetBallPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.MatchBall]: {
          handler: () =>
            popup.show({
              component: MatchBallPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.SetWon]: {
          handler: () =>
            popup.show({
              component: SetWonPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.TechnicalErrors]: {
          handler: () =>
            popup.show({
              component: TechnicalErrorsPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.Point]: {
          handler: () =>
            popup.show({
              component: PointPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.Challenge]: {
          handler: () =>
            popup.show({
              component: ChallengePopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.Timeout]: {
          handler: () =>
            popup.show({
              component: TimeoutsPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.RefereeChallenge]: {
          handler: () =>
            popup.show({
              component: RefereeChallengePopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.ServiceError]: {
          handler: ({ incidentId, participantId }) =>
            addIncident({
              incidentId,
              participantId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.AttackError]: {
          handler: ({ incidentId, participantId }) =>
            addIncident({
              incidentId,
              participantId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.Serve]: {
          handler: ({ incidentId, participantId }) =>
            addIncident({
              incidentId,
              participantId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.ServiceAce]: {
          handler: ({ incidentId, participantId }) =>
            addIncident({
              incidentId,
              participantId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.AttackPoint]: {
          handler: ({ incidentId, participantId }) =>
            addIncident({
              incidentId,
              participantId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.BlockPoint]: {
          handler: ({ incidentId, participantId }) =>
            addIncident({
              incidentId,
              participantId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [VolleyballIncidentId.Cards]: {
          handler: ({ attributeIds, participantId }) => {
            if (
              attributeIds &&
              attributeIds.includes(VolleyballCardsAttributeIds.RedCard)
            ) {
              const foulParticipant = byId(
                probableEvent.value.participants,
                participantId,
              )
              if (!foulParticipant) {
                throw Error("Couldn't resolve participant")
              }
              const oppositeTeam = getOppositeTeam(
                probableEvent.value.participants,
                foulParticipant,
              )
              if (!oppositeTeam) {
                throw Error("Couldn't resolve opposite participant")
              }
              addIncident({
                incidentId: VolleyballIncidentId.Point,
                participantId: oppositeTeam.id,
              })
            }
            addIncident({
              incidentId: VolleyballIncidentId.Cards,
              attributeIds,
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        fallbackHandler: {
          handler: addIncident,
          disabled: () => false,
        },
      }
    })
    .inSingletonScope()
}
