import { Container } from 'inversify'

import { Basketball3x3IncidentId } from '@collector/sportsapi-client-legacy'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindAssignableIncidentConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.AssignableIncidentsConfiguration)
    .toDynamicValue(() => {
      return {
        [Basketball3x3IncidentId.OnePointShotMade]: {
          participants: {
            main: 'Player',
            secondary: 'Assist',
          },
        },
        [Basketball3x3IncidentId.TwoPointShotMade]: {
          participants: {
            main: 'Player',
            secondary: 'Assist',
          },
        },
        [Basketball3x3IncidentId.ThrowIn]: { participants: { main: 'Player' } },
        [Basketball3x3IncidentId.OnePointShotMissed]: {
          participants: { main: 'Player' },
        },
        [Basketball3x3IncidentId.TwoPointShotMissed]: {
          participants: { main: 'Player' },
        },
        [Basketball3x3IncidentId.FreeThrowsAwarded]: {
          participants: { main: 'Player' },
        },
        [Basketball3x3IncidentId.OffensiveRebound]: {
          participants: { main: 'Player' },
        },
        [Basketball3x3IncidentId.DefensiveRebound]: {
          participants: { main: 'Player' },
        },
        [Basketball3x3IncidentId.Foul]: { participants: { main: 'Player' } },
        [Basketball3x3IncidentId.Turnover]: {
          participants: { main: 'Player' },
        },
        [Basketball3x3IncidentId.Steal]: {
          participants: {
            main: 'Player',
            secondary: 'Turnover',
          },
          participantsConfiguration: { secondary: { loadOppositeTeam: true } },
        },
        [Basketball3x3IncidentId.Block]: { participants: { main: 'Player' } },
      }
    })
    .inSingletonScope()
}
