import Dexie from 'dexie'

import { CollectorPushMessageEventsScouts } from '@collector/sportsapi-client-legacy'

import { Repository } from './Repository'

export class EventScoutsRepository extends Repository<CollectorPushMessageEventsScouts> {
  static readonly tableName = 'eventsScouts'
  static readonly indexes = 'data.id, eventId, pushMessageUt, uuid'

  constructor(database: Dexie) {
    super(
      database.table<CollectorPushMessageEventsScouts>(
        EventScoutsRepository.tableName,
      ),
    )
  }

  public async getByEventId(
    eventId: number,
  ): Promise<CollectorPushMessageEventsScouts | null> {
    return (await this.table.where('eventId').equals(eventId).last()) ?? null
  }

  public async getLast(): Promise<
    CollectorPushMessageEventsScouts | undefined
  > {
    return this.table.orderBy('pushMessageUt').last()
  }

  public async removeByEventId(eventId: number): Promise<number> {
    return await this.table.where('eventId').equals(eventId).delete()
  }
}
