import Dexie from 'dexie'
import { groupBy, uniq } from 'lodash'

import {
  EventsLineupsParticipant,
  LineupsRecord,
} from '@collector/sportsapi-client-legacy'

import { Repository } from './Repository'

export type EventLineupsMap = { [key: string]: EventsLineupsParticipant[] }

export class LineupsRepository extends Repository<LineupsRecord> {
  static readonly tableName = 'lineups'
  static readonly indexes = '[eventId+participantId], eventId, participantId'

  constructor(private database: Dexie) {
    super(database.table<LineupsRecord>(LineupsRepository.tableName))
  }

  public async save(
    eventId: number,
    participantId: number,
    lineups: EventsLineupsParticipant[],
  ): Promise<void> {
    return this.database.transaction('rw?', this.table, () =>
      this.table.put({
        eventId,
        participantId,
        lineups,
      }),
    )
  }

  public async clearParticipantsLineups(
    participantIds: number[],
  ): Promise<number> {
    return this.database.transaction('rw?', this.table, () =>
      this.table
        .where('participantId')
        .anyOf(participantIds)
        .modify({ lineups: [] }),
    )
  }

  public async findForParticipant(
    eventId: number,
    participantId: number,
  ): Promise<EventsLineupsParticipant[] | undefined> {
    const record = await this.table
      .where({
        eventId,
        participantId,
      })
      .last()

    return record?.lineups
  }

  public async findForEvent(eventId: number): Promise<EventLineupsMap> {
    const databaseRecords = await this.table.where({ eventId }).toArray()
    const groupedRecords = groupBy(
      databaseRecords.map((record) => record.lineups).flat(),
      'participant_team_id',
    )

    return groupedRecords
  }

  public async findEventParticipants(eventId: number): Promise<number[]> {
    const records = await this.table.where({ eventId }).toArray()

    return uniq(records.map((record) => record.participantId))
  }

  public async removeByEventId(eventId: number): Promise<number> {
    return this.database.transaction('rw?', this.table, () =>
      this.table.where({ eventId }).delete(),
    )
  }
}
