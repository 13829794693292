<script lang="ts">
import { uniqBy } from 'lodash'
import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  reactive,
  SetupContext,
  watch,
} from 'vue'

import { opChain } from '@collector/shared-utils'
import ParticipantLogo from '@mobile/components/ParticipantLogo/ParticipantLogo.vue'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'

import ToggleButton from './ToggleButton.vue'

type Props = {
  value: string | number | null
  options: ToggleButtonGroupOption[]
}
type ToggleButtonModels = {
  [value: string]: boolean
}

function useFilteredOptions(
  props: Props,
): ComputedRef<ToggleButtonGroupOption[]> {
  return computed(() => uniqBy(props.options, (option) => option.value))
}

function useCheckedModels(
  filteredOptions: ComputedRef<ToggleButtonGroupOption[]>,
  props: Props,
): ToggleButtonModels {
  const toggleButtonModels = reactive<ToggleButtonModels>({})
  const updateCheckedModels = (options: ToggleButtonGroupOption[]): void => {
    for (const prop in toggleButtonModels) {
      delete toggleButtonModels[prop]
    }

    options.forEach((option) => {
      toggleButtonModels[String(option.value)] = props.value === option.value
    })
  }

  updateCheckedModels(filteredOptions.value)

  watch(
    () => props.options,
    () => updateCheckedModels(filteredOptions.value),
  )

  return toggleButtonModels
}

function useUpdateValueAfterUserInteraction(
  toggleButtonModels: ToggleButtonModels,
  context: SetupContext<'update:value'[]>,
) {
  return (forValue: string | number | null, newValue: boolean) => {
    for (const prop in toggleButtonModels) {
      if (prop !== forValue) {
        toggleButtonModels[prop] = false
      }
    }

    context.emit('update:value', newValue ? forValue : '')
  }
}

function useOutsideValueMutationsHandler(
  props: Props,
  toggleButtonModels: ToggleButtonModels,
): void {
  watch(
    () => props.value,
    (newValue) => {
      for (const prop in toggleButtonModels) {
        toggleButtonModels[prop] = String(prop) === String(newValue)
      }
    },
  )
}

export type ToggleButtonGroupOption = {
  name: string
  value: string | number | null
  disabled?: boolean
  participant?: ProbableLinkedParticipant
}

export default defineComponent({
  components: {
    ToggleButton,
    ParticipantLogo,
  },
  props: {
    value: {
      type: null as unknown as PropType<string | number | null>,
      required: true,
    },
    options: {
      type: Array as PropType<ToggleButtonGroupOption[]>,
      required: true,
    },
    undeselectable: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String as PropType<'horizontal' | 'vertical' | 'grid'>,
      default: 'vertical',
    },
    withCheckmark: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:value'],
  setup(props, context) {
    const filteredOptions = useFilteredOptions(props)
    const toggleButtonModels = useCheckedModels(filteredOptions, props)
    const updateValueAfterUserInteraction = useUpdateValueAfterUserInteraction(
      toggleButtonModels,
      context,
    )
    useOutsideValueMutationsHandler(props, toggleButtonModels)

    function getClasses(): string[] {
      switch (props.type) {
        case 'vertical':
          return ['flex flex-col w-full']
        case 'horizontal':
          return ['flex flex-wrap']
        case 'grid':
          return ['grid grid-flow-col gap-3']
        default:
          return []
      }
    }

    function getToggleButtonClasses(): string[] {
      switch (props.type) {
        case 'vertical':
          return ['first:mt-0 mt-4']
        case 'horizontal':
          return ['mb-2 mr-2']
        default:
          return []
      }
    }

    return {
      getClasses,
      filteredOptions,
      toggleButtonModels,
      getToggleButtonClasses,
      updateValueAfterUserInteraction,
      opChain,
    }
  },
})
</script>

<template>
  <div :class="getClasses()">
    <ToggleButton
      v-for="(option, index) in filteredOptions"
      :key="option.value || index"
      v-model:value="toggleButtonModels[option.value || '']"
      v-bind="{ withCheckmark }"
      :class="getToggleButtonClasses()"
      :disabled="option.disabled"
      :undeselectable
      :bordered
      @toggle="updateValueAfterUserInteraction(option.value, $event)"
    >
      <div class="flex flex-row items-center">
        <ParticipantLogo
          v-if="option.participant"
          class="mr-1 h-7 w-7"
          :participant="option.participant.getParticipant().value"
        />
        <span :class="[opChain(option.participant, (v) => v.css.textClass)]">
          {{ option.name }}
        </span>
      </div>
    </ToggleButton>
  </div>
</template>
