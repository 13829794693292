import { cloneDeep, flatten, keyBy, times } from 'lodash'
import { computed, onMounted, Ref, ref } from 'vue'

import {
  EventsLineupsParticipant,
  SoccerStatId,
  SubParticipantPosition,
} from '@collector/sportsapi-client-legacy'
import {
  UpdateEventParticipants,
  UpdateLineups,
} from '@mobile/ActionQueue/Actions/Actions'
import { UpdateSubParticipantDTO } from '@mobile/ActionQueue/Actions/Lineups/UpdateLineups'
import { byId } from '@mobile/reusables/entityUtils'
import { participantDiff } from '@mobile/reusables/eventUtils'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useEventSeasonParticipants } from '@mobile/views/Relation/useEventSeasonParticipants'

import {
  Formation,
  FormationPlayer,
  Formations,
  maximumPlayersInFormation,
} from './Formation'

/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
export function useParticipantFormation(
  probableLinkedParticipant: ProbableLinkedParticipant,
  probableLineup: Ref<EventsLineupsParticipant[]>,
) {
  const probableParticipant = probableLinkedParticipant.getParticipant()

  const { actionQueue, probableEvent } = useEventInjections()

  const { id: eventId } = probableEvent.value
  const { id: participantId } = probableParticipant.value
  const { eventSeasonParticipants } = useEventSeasonParticipants()

  const probableLineupWithShortNames = ref(cloneDeep(probableLineup.value))
  const formation = ref<Formation>({
    lines: [],
    notSelectedPlayers: [],
    selectedPlayers: [],
    statValue: null,
  })
  const isFormationLoading = ref(true)

  onMounted(() => {
    probableLineupWithShortNames.value = probableLineupWithShortNames.value.map(
      (lineupPlayer) => {
        const eventSeasonParticipant = byId(
          eventSeasonParticipants.value,
          lineupPlayer.participant_id,
        )

        return eventSeasonParticipant
          ? {
              ...lineupPlayer,
              short_name: eventSeasonParticipant.short_name,
            }
          : lineupPlayer
      },
    )

    formation.value = buildFormation(
      byId(probableParticipant.value.stats, SoccerStatId.Formation)?.value,
    )
    isFormationLoading.value = false
  })

  function buildFormation(
    formationStatValue: string | number | null | undefined,
  ): Formation {
    const probableLineupsByPlayerPosition = keyBy(
      probableLineupWithShortNames.value,
      (item) => item.player_position,
    )

    const formationPlayers = times(maximumPlayersInFormation).map((index) => {
      const playerPosition = index + 1

      return (
        probableLineupsByPlayerPosition[playerPosition] ?? {
          player_position: playerPosition,
        }
      )
    }) as FormationPlayer[]

    const selectedFormationPlayers = formationPlayers.filter(
      (formationPlayer: FormationPlayer) => !!formationPlayer.participant_id,
    ) as EventsLineupsParticipant[]

    const selectedFormationPlayersByParticipantId = keyBy(
      selectedFormationPlayers,
      ({ participant_id }) => participant_id,
    )

    const notSelectedFormationPlayers =
      probableLineupWithShortNames.value.filter(
        ({ participant_id, participant_position }) => {
          if (participant_id === null) return false

          return (
            !selectedFormationPlayersByParticipantId[participant_id] &&
            [
              SubParticipantPosition.FirstLineup,
              SubParticipantPosition.Goalkeeper,
            ].includes(participant_position as SubParticipantPosition)
          )
        },
      )

    const formationName = formationStatValue ? `1-${formationStatValue}` : '-'
    const formationLines = formationName
      .split('-')
      .map(Number)
      .map((formationLine) => formationPlayers.splice(0, formationLine))

    return {
      statValue: formationStatValue ? String(formationStatValue) : null,
      lines: formationLines,
      selectedPlayers: selectedFormationPlayers,
      notSelectedPlayers: notSelectedFormationPlayers,
    }
  }

  function changeFormation(selectedFormation: Formations): void {
    formation.value.statValue = selectedFormation
    formation.value = buildFormation(selectedFormation)
    resetPlayerPositions()
  }

  function addPlayerToFormation(
    formationPlayer: EventsLineupsParticipant,
  ): void {
    formation.value.notSelectedPlayers =
      formation.value.notSelectedPlayers.filter(
        ({ participant_id }) =>
          participant_id !== formationPlayer.participant_id,
      )

    formation.value.selectedPlayers.push(formationPlayer)

    formation.value.lines = formation.value.lines.map((line) =>
      line.map((player) =>
        player.player_position === formationPlayer.player_position
          ? formationPlayer
          : player,
      ),
    )
  }

  function removePlayerFromFormation(
    formationPlayer: EventsLineupsParticipant,
  ): void {
    formation.value.selectedPlayers = formation.value.selectedPlayers.filter(
      ({ participant_id }) => participant_id !== formationPlayer.participant_id,
    )

    formation.value.notSelectedPlayers.push(formationPlayer)

    formation.value.lines = formation.value.lines.map((line) => {
      return line.map((player) => {
        return player.player_position === formationPlayer.player_position
          ? { player_position: formationPlayer.player_position }
          : player
      })
    })
  }

  function resetPlayerPositions(): void {
    formation.value.notSelectedPlayers = [
      ...formation.value.notSelectedPlayers,
      ...formation.value.selectedPlayers,
    ]
    formation.value.selectedPlayers = []

    formation.value.lines = formation.value?.lines.map((line) =>
      line.map((player) => ({ player_position: player.player_position })),
    )
  }

  function saveFormation(): void {
    const updateParticipantAction = createUpdateParticipantAction()
    const updateLineupsAction = createUpdateLineupsAction()

    if (updateParticipantAction) {
      actionQueue.value.add(updateParticipantAction)
      updateLineupsAction.dependencies = [updateParticipantAction.id]
    }

    actionQueue.value.add(updateLineupsAction)
  }

  function createUpdateParticipantAction():
    | UpdateEventParticipants
    | undefined {
    const participantToUpdate = cloneDeep(probableParticipant.value)

    const formationStatIndex = participantToUpdate?.stats.findIndex(
      (stat) => stat.id === SoccerStatId.Formation,
    )
    if (formationStatIndex !== -1) {
      participantToUpdate.stats.splice(formationStatIndex, 1, {
        id: SoccerStatId.Formation,
        value: formation.value.statValue,
      })

      return new UpdateEventParticipants({
        data: [participantDiff(probableParticipant.value, participantToUpdate)],
      })
    }
    return undefined
  }

  function createUpdateLineupsAction(): UpdateLineups {
    const formationPlayers = flatten(formation.value?.lines)

    const lineups = probableLineupWithShortNames.value.map((subParticipant) => {
      const playerPosition =
        formationPlayers.find(
          ({ participant_id }) =>
            participant_id === subParticipant.participant_id,
        )?.player_position ?? null

      return transformFormationPlayerToUpdateSubParticipantDTO(
        subParticipant,
        playerPosition,
      )
    })

    return new UpdateLineups(
      {
        eventId,
        participantId,
        participantSide: probableLinkedParticipant.side,
        lineups,
      },
      lineups.length === 0,
    )
  }

  function transformFormationPlayerToUpdateSubParticipantDTO(
    formationPlayer: EventsLineupsParticipant,
    playerPosition: number | null,
  ): UpdateSubParticipantDTO {
    return {
      position: formationPlayer.participant_position,
      captain: formationPlayer.participant_is_captain,
      id: formationPlayer.participant_id,
      name: formationPlayer.participant_name,
      shirt_nr: formationPlayer.participant_shirt_number,
      player_position: playerPosition,
    }
  }

  return {
    formation: computed(() => formation.value),
    isFormationLoading: computed(() => isFormationLoading.value),
    changeFormation,
    resetPlayerPositions,
    addPlayerToFormation,
    removePlayerFromFormation,
    saveFormation,
  }
}
