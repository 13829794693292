import { Sport } from '@collector/sportsapi-client-legacy'

let currentSport: null | Sport = null

export function setCurrentSport(sport: Sport): void {
  currentSport = sport
}

export function getCurrentSport(): Sport {
  if (currentSport === null) throw Error('currentSport is null')

  return currentSport
}
