<script lang="ts">
import { computed, defineComponent, PropType, Ref, ref } from 'vue'

import { opChain } from '@collector/shared-utils'
import { VolleyballRefereeChallengeAttribute } from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'
import PopupControls from '@mobile/views/Relation/Shared/Popup/PopupControls.vue'
import { IncidentActionComposable } from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import History from '@mobile/views/Relation/Shared/Popups/History/History.vue'

export default defineComponent({
  components: {
    PopupControls,
    Button,
  },
  props: {
    context: {
      type: Object as PropType<PopupContext | null>,
      required: false,
    },
    incidentActionComposable: {
      type: Object as PropType<IncidentActionComposable | null>,
      default: null,
    },
  },
  setup(props) {
    const selectedAttribute: Ref<VolleyballRefereeChallengeAttribute | null> =
      ref(null)
    const processable = computed(() => !!selectedAttribute.value)

    function proceed(): void {
      if (!selectedAttribute.value) {
        return
      }

      props.incidentActionComposable?.updateIncident({
        attribute_ids: [selectedAttribute.value],
      })

      if (
        selectedAttribute.value ===
        VolleyballRefereeChallengeAttribute.CallStands
      ) {
        props.context?.openPopup(History)
      } else {
        props.context?.closeAllPopups()
      }
    }

    return {
      selectedAttribute,
      VolleyballRefereeChallengeAttribute,
      processable,
      proceed,
      opChain,
    }
  },
})
</script>

<template>
  <div>
    <div class="grid place-items-center px-4 py-6">
      <div class="text-cloudygray py-2 text-center text-sm font-bold">
        Referee challenge
      </div>
      <div class="flex space-x-2">
        <Button
          class="w-20"
          :class="{
            'opacity-50':
              selectedAttribute !==
              VolleyballRefereeChallengeAttribute.CallStands,
          }"
          :color="'green'"
          :size="'md'"
          @click="
            selectedAttribute = VolleyballRefereeChallengeAttribute.CallStands
          "
        >
          <FontAwesomeIcon
            :size="'lg'"
            :icon="'check'"
          />
        </Button>

        <Button
          class="w-20"
          :class="{
            'opacity-50':
              selectedAttribute !==
              VolleyballRefereeChallengeAttribute.CallOverturned,
          }"
          :color="'red'"
          :size="'md'"
          @click="
            selectedAttribute =
              VolleyballRefereeChallengeAttribute.CallOverturned
          "
        >
          <FontAwesomeIcon
            :size="'lg'"
            :icon="'times'"
          />
        </Button>
      </div>
    </div>

    <PopupControls>
      <template #buttons>
        <div
          class="grid gap-2"
          style="grid-template-columns: repeat(auto-fit, minmax(90px, 1fr))"
        >
          <div
            v-for="i in 3"
            :key="`item-${i}`"
          ></div>
          <Button
            :size="'md'"
            :color="'red'"
            @click="opChain(context, (v) => v.closeAllPopups())"
          >
            Cancel
          </Button>
          <Button
            :size="'md'"
            :color="'green'"
            :disabled="!processable"
            @click="proceed()"
          >
            Send
          </Button>
        </div>
      </template>
    </PopupControls>
  </div>
</template>
