<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from 'vue'

import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export enum RelationIconType {
  Incidents = 'incidents',
  Stats = 'stats',
}

export default defineComponent({
  props: {
    iconId: {
      type: Number,
      required: true,
    },
    size: {
      type: Number,
      required: true,
    },
    type: {
      type: String as PropType<RelationIconType>,
      required: true,
    },
  },

  setup(props) {
    const { sport } = useEventInjections()

    const placeholderUrl =
      'https://lmp-s3-cdn.statscore.com/LSP/clients/default/template1/icons/info.svg'
    const iconURL = ref(getIconURL())

    const iconStyles = computed(() => ({
      width: `${props.size}px`,
      height: `${props.size}px`,
    }))

    function getIconURL(): string {
      const sportName = sport.value.sportsAPIEntity.name
        .toUpperCase()
        .replace(/\s+/g, '_')

      return `https://lmp-s3-cdn.statscore.com/LSP/clients/default/template1/icons/${props.type}/${sportName}/${props.iconId}.svg`
    }

    function changeToPlaceholderIcon(): void {
      if (iconURL.value !== placeholderUrl) {
        iconURL.value = placeholderUrl
      }
    }

    watch(
      () => props.iconId,
      () => (iconURL.value = getIconURL()),
    )

    return {
      iconURL,
      iconStyles,
      changeToPlaceholderIcon,
    }
  },
})
</script>

<template>
  <img
    :style="iconStyles"
    :src="iconURL"
    @error="changeToPlaceholderIcon"
  />
</template>
