import { computed, ref } from 'vue'

import { ClockStatus } from '@collector/sportsapi-client-legacy'
import UpdateEventTime from '@mobile/ActionQueue/Actions/Event/UpdateEventTime'
import { getOrThrow } from '@mobile/reusables/entityUtils'
import { convertEventClockStatusToDTOClockStatus } from '@mobile/reusables/eventUtils'
import {
  extractTime,
  formatTime,
  getCurrentGamePartStartTime,
  isTimeInEventStatusBoundary,
} from '@mobile/views/Relation/Shared/RelationDependencies/bindClock'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
export function useTimeChange() {
  const { probableClock, probableEvent, actionQueue } = useEventInjections()
  const { eventStatusesConfiguration } = useSportConfiguration()

  const eventStatusConfiguration = getOrThrow(
    eventStatusesConfiguration,
    probableEvent.value.status_id,
  )

  const clockTimeChange = ref(0)

  const clockTimeChangeFormatted = computed(() => {
    const { minute, second } = extractTime(clockTimeChange.value)

    return {
      minute: formatTime(minute),
      second: formatTime(second),
    }
  })

  const isTimeChangeable = computed(() => probableClock.value.time !== null)

  const isTimeStopped = computed(
    () => probableClock.value.status === ClockStatus.Stopped,
  )

  const clockTimeAfterChange = computed(() => {
    if (probableClock.value.time === null) {
      return null
    }

    const clockTimeAfterChange =
      probableClock.value.time + clockTimeChange.value

    if (clockTimeAfterChange < 0) {
      return 0
    }

    if (
      typeof eventStatusConfiguration.timeBoundaryInSeconds === 'number' &&
      !isTimeInEventStatusBoundary(
        clockTimeAfterChange,
        eventStatusConfiguration,
      )
    ) {
      return eventStatusConfiguration.timeBoundaryInSeconds
    }

    return clockTimeAfterChange
  })

  const clockTimeAfterChangeFormatted = computed(() => {
    if (clockTimeAfterChange.value === null) {
      return {
        minute: null,
        second: null,
      }
    }

    let clockTimeWithPreviousParts =
      clockTimeAfterChange.value +
      getCurrentGamePartStartTime(eventStatusConfiguration)
    if (
      eventStatusConfiguration.clockDirectionDown &&
      eventStatusConfiguration.startTimeFromInSeconds
    ) {
      clockTimeWithPreviousParts =
        eventStatusConfiguration.startTimeFromInSeconds -
        clockTimeWithPreviousParts
    }
    const time = extractTime(clockTimeWithPreviousParts)

    return {
      minute: formatTime(time.minute),
      second: formatTime(time.second),
    }
  })

  const isTimeSaveable = computed<boolean>(
    () =>
      isTimeChangeable.value &&
      clockTimeChange.value !== 0 &&
      convertEventClockStatusToDTOClockStatus(
        probableEvent.value.clock_status,
      ) !== undefined,
  )

  function addTime(seconds: number): void {
    clockTimeChange.value += seconds
  }

  function saveClockTime(): void {
    if (isTimeSaveable.value) {
      actionQueue.value.add(
        new UpdateEventTime(
          {
            event: {
              clock_status: convertEventClockStatusToDTOClockStatus(
                probableEvent.value.clock_status,
              ),
              clock_time: clockTimeAfterChange.value,
            },
          },
          true,
        ),
      )

      cancelClockChange()
    }
  }

  function pauseTime(): void {
    actionQueue.value.add(
      new UpdateEventTime(
        {
          event: {
            clock_time: probableClock.value.time,
            clock_status: false,
          },
        },
        false,
      ),
    )
  }

  function startTime(): void {
    actionQueue.value.add(
      new UpdateEventTime(
        {
          event: {
            clock_time: probableClock.value.time,
            clock_status: true,
          },
        },
        false,
      ),
    )
  }

  function cancelClockChange(): void {
    clockTimeChange.value = 0
  }

  return {
    addTime,
    startTime,
    pauseTime,
    isTimeStopped,
    saveClockTime,
    isTimeSaveable,
    clockTimeChange,
    isTimeChangeable,
    cancelClockChange,
    clockTimeAfterChange,
    clockTimeChangeFormatted,
    clockTimeAfterChangeFormatted,
  }
}
