<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import { opChain } from '@collector/shared-utils'
import { Confirmation, TeamSide } from '@collector/sportsapi-client-legacy'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import RelationIcon, {
  RelationIconType,
} from '@mobile/views/Relation/Shared/RelationIcon/RelationIcon.vue'

export default defineComponent({
  components: { RelationIcon },
  props: {
    probableLinkedIncident: {
      type: Object as PropType<ProbableLinkedIncident>,
      required: true,
    },
  },
  setup(props) {
    const confirmation = computed(
      () => props.probableLinkedIncident.incident.confirmation,
    )
    const { probableEvent } = useEventInjections()

    function getAttributesNames(attributeIds: number[]): string {
      return props.probableLinkedIncident.sportIncident.attributes
        .filter(({ id }) => attributeIds.includes(id))
        .map(({ name }) => name)
        .join(', ')
    }

    function hasAttributes(incident: ProbableIncident): boolean {
      return !!getAttributesNames(incident.attribute_ids).length
    }

    return {
      confirmation,
      TeamSide,
      Confirmation,
      getAttributesNames,
      hasAttributes,
      sportId: probableEvent.value.sport_id,
      RelationIconType,
      opChain,
    }
  },
})
</script>

<template>
  <div
    class="flex items-center border border-solid px-4 py-1 shadow-xl"
    :class="{
      'justify-between': probableLinkedIncident.side === TeamSide.Away,
      'border-green':
        probableLinkedIncident.incident.confirmation === Confirmation.Confirmed,
      'border-red':
        probableLinkedIncident.incident.confirmation === Confirmation.Cancelled,
    }"
  >
    <div
      v-show="confirmation === Confirmation.Cancelled"
      class="text-red flex flex-col justify-between px-2 text-center text-sm font-semibold"
    >
      Canceled
    </div>
    <div class="flex flex-1 overflow-hidden">
      <div
        class="flex flex-1 items-center overflow-hidden px-2"
        :class="{
          'flex-row-reverse text-right':
            probableLinkedIncident.side === TeamSide.Away,
        }"
      >
        <div class="m-auto w-10">
          <RelationIcon
            v-show="probableLinkedIncident.side"
            class="m-auto"
            :iconId="probableLinkedIncident.incident.incident_id"
            :type="RelationIconType.Incidents"
            :size="16"
          />
        </div>
        <div class="flex flex-1 flex-col overflow-hidden px-2">
          <div
            class="block truncate text-sm font-bold"
            :class="{
              'flex-1 overflow-hidden text-left':
                probableLinkedIncident.side === TeamSide.Home,
              'text-right': probableLinkedIncident.side === TeamSide.Away,
            }"
          >
            <div
              :class="
                opChain(
                  probableLinkedIncident.probableLinkedParticipant,
                  (v) => v.css.textClass,
                )
              "
            >
              {{
                opChain(probableLinkedIncident.participant, (v) => v.short_name)
              }}
            </div>
          </div>
          <div
            class="flex flex-1 overflow-hidden"
            :class="{
              'flex-1 flex-row-reverse overflow-hidden':
                probableLinkedIncident.side === TeamSide.Away,
            }"
          >
            <div class="text-blue align-baseline text-sm">
              {{ probableLinkedIncident.incident.event_time }}
            </div>
            <div class="w-full whitespace-nowrap px-1 align-baseline text-sm">
              <div class="font-bold">
                {{ probableLinkedIncident.incident.incident_name }}
              </div>
              <div
                v-if="hasAttributes(probableLinkedIncident.incident)"
                class="text-xs"
              >
                {{
                  getAttributesNames(
                    probableLinkedIncident.incident.attribute_ids,
                  )
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="confirmation === Confirmation.Confirmed"
      class="text-green flex flex-col justify-between px-2 text-center text-sm font-semibold"
    >
      Confirmed
    </div>
  </div>
</template>
