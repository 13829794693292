import { Container } from 'inversify'

import { HandballIncidentId } from '@collector/sportsapi-client-legacy'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindEventStatusesList(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.EventStatusesList)
    .toDynamicValue(() => {
      return [
        HandballIncidentId.NotStarted,
        HandballIncidentId.StartDelayed,
        HandballIncidentId.FirstHalfStarted,
        HandballIncidentId.Halftime,
        HandballIncidentId.SecondHalfStarted,
        HandballIncidentId.WaitingForExtratime,
        HandballIncidentId.FirstExtratimeFirstHalfStarted,
        HandballIncidentId.ExtratimeHalftime,
        HandballIncidentId.FirstExtratimeSecondHalfStarted,
        HandballIncidentId.WaitingForSecondExtratime,
        HandballIncidentId.SecondExtratimeFirstHalfStarted,
        HandballIncidentId.SecondExtratimeHalftime,
        HandballIncidentId.SecondExtratimeSecondHalfStarted,
        HandballIncidentId.WaitingForPenalties,
        HandballIncidentId.PenaltyShootoutStarted,
        HandballIncidentId.Interrupted,
        HandballIncidentId.Postponed,
        HandballIncidentId.Cancelled,
        HandballIncidentId.FinishedRegularTime,
        HandballIncidentId.FinishedAfterExtratime,
        HandballIncidentId.FinishedAfterPenalties,
        HandballIncidentId.FinishedAwardedWin,
        HandballIncidentId.Abandoned,
      ]
    })
    .inSingletonScope()
}
