<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    pulse: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<'md' | 'lg'>,
      default: 'md',
    },
  },
  emits: ['click'],
})
</script>

<template>
  <button
    class="text-darkblue active:bg-blue relative flex h-10 cursor-pointer items-center justify-center p-4 text-center active:text-white"
    :class="{ 'error-pulse': pulse, 'hover:bg-blue hover:text-white': !pulse }"
    @click="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<style scoped>
.error-pulse {
  animation: error-pulse-animation 3s infinite;
}

@keyframes error-pulse-animation {
  0% {
    @apply bg-transparent;
    @apply text-darkblue;
  }
  50% {
    @apply bg-error;
    @apply text-white;
  }
  100% {
    @apply bg-transparent;
    @apply text-darkblue;
  }
}
</style>
