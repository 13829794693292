import { ConcreteComponent, ExtractPropTypes, h, VNode } from 'vue'

import BasicPopup from './BasicPopup.vue'
import { NavigationButton, PopupIncidentProp, PopupProps } from './types'

export class PopupBuilder {
  private props: PopupProps = {
    title: '',
    showTeamSelection: false,
    omitDetails: false,
    stackedPopups: [],
    navigationButtons: [],
    incidentActionComposable: null,
    staticLinkedProbableIncidents: [],
  }
  private bodyComponents: VNode[] = []

  constructor(title: string, incidentData?: PopupIncidentProp) {
    this.props.title = title
    this.props.incident = incidentData
  }

  public withTeamSelection(): PopupBuilder {
    this.props.showTeamSelection = true

    return this
  }

  public omitDetailsView(): PopupBuilder {
    this.props.omitDetails = true

    return this
  }

  public appendBody<T = ConcreteComponent>(
    component: T,
    props?: ExtractPropTypes<T>,
  ): PopupBuilder {
    this.bodyComponents.push(h(component as ConcreteComponent, props))

    return this
  }

  public setNavigationButtons(
    navigationButtons: (NavigationButton | null)[],
  ): PopupBuilder {
    this.props.navigationButtons = navigationButtons

    return this
  }

  public build(): VNode {
    return h(BasicPopup, this.props, { default: () => this.bodyComponents })
  }
}
