import {
  AmericanFootballAcronymMap,
  Basketball3x3IncidentAcronymMap,
  BasketballIncidentAcronymMap,
  BeachVolleyballIncidentAcronymMap,
  CommonIncidentAcronymMap,
  CricketIncidentAcronymMap,
  HandballIncidentAcronymMap,
  Incident,
  IncidentAcronym,
  SoccerIncidentAcronymMap,
  VolleyballIncidentAcronymMap,
} from '@collector/sportsapi-client-legacy'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TODO: fix
const incidentAcronymMap: IncidentAcronym = {
  ...SoccerIncidentAcronymMap,
  ...HandballIncidentAcronymMap,
  ...BasketballIncidentAcronymMap,
  ...CricketIncidentAcronymMap,
  ...VolleyballIncidentAcronymMap,
  ...BeachVolleyballIncidentAcronymMap,
  ...CommonIncidentAcronymMap,
  ...AmericanFootballAcronymMap,
  ...Basketball3x3IncidentAcronymMap,
}

export const getIncidentAcronym = (
  incident: Incident | ProbableIncident,
): string => {
  const acronym = incidentAcronymMap[incident.incident_id]

  if (!acronym) {
    /* eslint-disable-next-line no-console */
    console.warn(
      `Cannot find incident acronym map for ${incident.incident_name}(${incident.incident_id}).`,
    )

    return 'UNK'
  }

  return acronym
}
