import { Container } from 'inversify'

import {
  BeachVolleyballIncidentId,
  IncidentId,
} from '@collector/sportsapi-client-legacy'
import { AddIncident } from '@mobile/ActionQueue/Actions/Actions'
import * as popup from '@mobile/globalState/popup'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { IncidentHandler } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/IncidentHandlersConfiguration'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

import { ChallengePopup } from '../Popups/Challenge/ChallengePopup'
import { ConfirmSetStartPopup } from '../Popups/ConfirmSetStart/ConfirmSetStartPopup'
import { MatchBallPopup } from '../Popups/MatchBall/MatchBallPopup'
import { PointPopup } from '../Popups/Point/PointPopup'
import { RedCardPopup } from '../Popups/RedCardPopup'
import { SetBallPopup } from '../Popups/SetBall/SetBallPopup'
import { SetWonPopup } from '../Popups/SetWon/SetWonPopup'
import { TechnicalErrorsPopup } from '../Popups/TechnicalError/TechnicalErrorsPopup'
import { TimeoutsPopup } from '../Popups/Timeouts/TimeoutsPopup'
import { YellowCardPopup } from '../Popups/YellowCardPopup'

export function bindIncidentHandlersConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.IncidentHandlersConfiguration)
    .toDynamicValue(async (ctx) => {
      const [actionQueue, probableEvent, probableClock] = await Promise.all([
        await ctx.container.getAsync(RelationIoCBindings.ActionQueue),
        await ctx.container.getAsync(RelationIoCBindings.ProbableEvent),
        await ctx.container.getAsync(RelationIoCBindings.ProbableClock),
        await ctx.container.getAsync(RelationIoCBindings.ProbableIncidents),
        await ctx.container.getAsync(
          RelationIoCBindings.ProbableLinkedHomeParticipant,
        ),
      ])
      const eventStatusesConfiguration = ctx.container.get(
        RelationSportCommonIoCBindings.EventStatusesConfiguration,
      )
      const addIncident: IncidentHandler['handler'] = ({
        incidentId,
        participantId,
        time = {
          minute: probableClock.value.minute,
          second: probableClock.value.second,
        },
      }) => {
        actionQueue.value.add(
          new AddIncident({
            incident_id: incidentId,
            status_id: probableEvent.value.status_id,
            participant_team_id: participantId,
            ...time,
          }),
        )
      }

      const isDisabledByCurrentEventStatus = (
        incidentId: IncidentId,
      ): boolean => {
        const eventStatusConfiguration =
          eventStatusesConfiguration[probableEvent.value.status_id]

        return !eventStatusConfiguration?.allowedIncidents?.includes(incidentId)
      }

      return {
        [BeachVolleyballIncidentId.Abandoned]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BeachVolleyballIncidentId.Cancelled]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BeachVolleyballIncidentId.Finished]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BeachVolleyballIncidentId.FinishedAwardedWin]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BeachVolleyballIncidentId.Interrupted]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BeachVolleyballIncidentId.Postponed]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BeachVolleyballIncidentId.StartDelayed]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BeachVolleyballIncidentId.FirstSetStared]: {
          handler: ({ incidentId }) =>
            popup.show({
              component: ConfirmSetStartPopup,
              showHeader: true,
              props: { incidentId },
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BeachVolleyballIncidentId.SecondSetStarted]: {
          handler: ({ incidentId }) =>
            popup.show({
              component: ConfirmSetStartPopup,
              showHeader: true,
              props: { incidentId },
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BeachVolleyballIncidentId.ThirdSetStarted]: {
          handler: ({ incidentId }) =>
            popup.show({
              component: ConfirmSetStartPopup,
              showHeader: true,
              props: { incidentId },
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BeachVolleyballIncidentId.GoldenSetStart]: {
          handler: ({ incidentId }) =>
            popup.show({
              component: ConfirmSetStartPopup,
              showHeader: true,
              props: { incidentId },
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BeachVolleyballIncidentId.YellowCard]: {
          handler: () =>
            popup.show({
              component: YellowCardPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BeachVolleyballIncidentId.RedCard]: {
          handler: () =>
            popup.show({
              component: RedCardPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BeachVolleyballIncidentId.SetBall]: {
          handler: () =>
            popup.show({
              component: SetBallPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BeachVolleyballIncidentId.MatchBall]: {
          handler: () =>
            popup.show({
              component: MatchBallPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BeachVolleyballIncidentId.SetWon]: {
          handler: () =>
            popup.show({
              component: SetWonPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BeachVolleyballIncidentId.TechnicalErrors]: {
          handler: () =>
            popup.show({
              component: TechnicalErrorsPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BeachVolleyballIncidentId.Point]: {
          handler: () =>
            popup.show({
              component: PointPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BeachVolleyballIncidentId.Challenge]: {
          handler: () =>
            popup.show({
              component: ChallengePopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BeachVolleyballIncidentId.Timeout]: {
          handler: () =>
            popup.show({
              component: TimeoutsPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BeachVolleyballIncidentId.ServiceError]: {
          handler: ({ incidentId, participantId }) =>
            addIncident({
              incidentId,
              participantId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BeachVolleyballIncidentId.AttackError]: {
          handler: ({ incidentId, participantId }) =>
            addIncident({
              incidentId,
              participantId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BeachVolleyballIncidentId.Serve]: {
          handler: ({ incidentId, participantId }) =>
            addIncident({
              incidentId,
              participantId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BeachVolleyballIncidentId.ServiceAce]: {
          handler: ({ incidentId, participantId }) =>
            addIncident({
              incidentId,
              participantId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BeachVolleyballIncidentId.AttackPoint]: {
          handler: ({ incidentId, participantId }) =>
            addIncident({
              incidentId,
              participantId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [BeachVolleyballIncidentId.BlockPoint]: {
          handler: ({ incidentId, participantId }) =>
            addIncident({
              incidentId,
              participantId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        fallbackHandler: {
          handler: addIncident,
          disabled: () => false,
        },
      }
    })
    .inSingletonScope()
}
