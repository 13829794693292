<script lang="ts">
import { defineComponent, ref } from 'vue'

import { eventBus } from '@mobile/globalState/eventBus'
import { afterNextRender } from '@mobile/reusables/afterNextRender'

type Animation = {
  resolve: () => void
  reject: () => void
  isRejected: boolean
}

export default defineComponent({
  setup() {
    const mainEl = ref<null | HTMLElement>(null)

    const showIndicator = ref(false)
    const position = ref({
      x: 0,
      y: 0,
    })
    const circleAnimationStyles = ref(getDefaultAnimationStyles(0))
    function getDefaultAnimationStyles(timeMs: number): {
      strokeDasharray: string
      strokeDashoffset: string
      transition: string
    } {
      return {
        strokeDasharray: '305%',
        strokeDashoffset: '156%',
        transition: `stroke-dashoffset ${timeMs}ms linear`,
      }
    }

    let animation: null | Animation

    eventBus.on('Press', (event) => {
      const circle = mainEl.value?.querySelector('circle')
      if (!circle) {
        event.onComplete()

        return
      }

      let thisAnimation: Animation | null = null

      const onTransitionEnd = (): void => {
        thisAnimation?.resolve()
        showIndicator.value = false
      }
      circle.addEventListener('transitionend', onTransitionEnd)

      new Promise<void>((resolve, reject) => {
        function clearCirclePerimeter(): void {
          circleAnimationStyles.value = getDefaultAnimationStyles(event.timeMs)
        }
        function fillCirclePerimeter(): void {
          circleAnimationStyles.value.strokeDashoffset = '0%'
        }

        animation?.reject()

        thisAnimation = {
          resolve() {
            event.onComplete()
            eventBus.emit('HighlightPosition', {
              ...event.position,
              reversed: true,
            })
            resolve()
          },
          reject() {
            this.isRejected = true
            reject()
          },
          isRejected: false,
        }
        animation = thisAnimation

        showIndicator.value = true
        position.value = event.position
        clearCirclePerimeter()

        afterNextRender(() => {
          if (thisAnimation?.isRejected === false) {
            fillCirclePerimeter()
          }
        })
      })
        .catch((err) => {
          showIndicator.value = false
          event.onCancel()

          return err
        })
        .finally(() => {
          circle.removeEventListener('transitionend', onTransitionEnd)
        })
    })

    eventBus.on('CancelPress', () => {
      animation?.reject()
    })

    return {
      mainEl,
      showIndicator,
      circleAnimationStyles,
      position,
    }
  },
})
</script>

<template>
  <div ref="mainEl">
    <svg
      v-show="showIndicator"
      class="pointer-events-none absolute z-50 -ml-12 -mt-20 h-14 w-24 overflow-hidden"
      :style="{ left: `${position.x}px`, top: `${position.y}px` }"
    >
      <circle
        class="text-blue stroke-current"
        r="38px"
        cx="48px"
        cy="56px"
        stroke-width="8px"
        fill="transparent"
        :style="circleAnimationStyles"
      />
    </svg>
  </div>
</template>
