<script lang="ts">
import { defineComponent } from 'vue'

import { HandballIncidentId } from '@collector/sportsapi-client-legacy'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import { useHandballConfiguration } from '@mobile/views/Relation/Sports/Handball/HandballRelationDependencies/useHandballConfiguration'

import EmptyNet from './EmptyNet.vue'

export default defineComponent({
  components: {
    IncidentButton,
    EmptyNet,
  },
  setup() {
    const { activeParticipant } = useHandballConfiguration()

    return {
      HandballIncidentId,
      activeParticipant,
    }
  },
})
</script>

<template>
  <div class="grid grid-cols-2 gap-4">
    <EmptyNet />
    <IncidentButton
      class="col-span-2 h-full"
      :type="'horizontal'"
      :size="'xxs'"
      :color="activeParticipant.color"
      :incidentId="HandballIncidentId.Goal"
    />
    <IncidentButton
      class="col-span-2 h-full"
      :type="'horizontal'"
      :size="'xxs'"
      :color="'white'"
      :incidentId="HandballIncidentId.Penalty"
    />
  </div>
</template>
