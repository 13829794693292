<script lang="ts">
import { cloneDeep } from 'lodash'
import {
  computed,
  defineComponent,
  nextTick,
  reactive,
  ref,
  Ref,
  shallowRef,
} from 'vue'

import { Participant, RelationStatus } from '@collector/sportsapi-client-legacy'
import { UpdateEventParticipants } from '@mobile/ActionQueue/Actions/Actions'
import UpdateEvent from '@mobile/ActionQueue/Actions/Event/UpdateEvent'
import * as popup from '@mobile/globalState/popup'
import {
  participantDiff,
  resolveUpdateEventDetailsObjectFromEventDetailsDiff,
  resolveUpdateEventObjectFromEventDiff,
} from '@mobile/reusables/eventUtils'
import router from '@mobile/router'
import PopupControls from '@mobile/views/Relation/Shared/Popup/PopupControls.vue'
import StackedPopup from '@mobile/views/Relation/Shared/Popup/StackedPopup.vue'
import Lineups from '@mobile/views/Relation/Shared/Popups/Lineups/Lineups.vue'
import Skins from '@mobile/views/Relation/Shared/Popups/Skins/Skins.vue'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import Formations from '@mobile/views/Relation/Sports/Soccer/Popups/Formation/Formation.vue'

import { EventPreparationChildPopupType } from './EventPreparationChildPopupType'
import EventPreparationEventDetails from './EventPreparationEventDetails.vue'
import EventPreparationEventStats from './EventPreparationEventStats.vue'
import EventPreparationHeader from './EventPreparationHeader.vue'
import EventPreparationRelationStatus from './EventPreparationRelationStatus.vue'
import EventPreparationTeamDetails from './EventPreparationTeamDetails.vue'

enum EventPreparationIndex {
  EventPreparationRelationStatus = 0,
  EventPreparationEventDetails = 1,
  EventPreparationTeamDetails = 2,
}

export default defineComponent({
  components: {
    EventPreparationRelationStatus,
    EventPreparationEventDetails,
    EventPreparationTeamDetails,
    EventPreparationEventStats,
    EventPreparationHeader,
    PopupControls,
    StackedPopup,
    Skins,
    Lineups,
    Formations,
  },
  setup() {
    const {
      actionQueue,
      probableEvent,
      sport,
      event,
      probableLinkedFirstParticipant,
      probableLinkedSecondParticipant,
    } = useEventInjections()
    const sliderElement = ref<HTMLElement | null>(null)
    const sliderPosition = ref(0)
    const sliderElementsCount = 3
    const maximumSliderPosition = sliderElementsCount - 1
    const eventName = computed(() => event.value.data.name)
    const isLastStep = computed(
      () => sliderPosition.value === maximumSliderPosition,
    )

    const preparationRelationStatusData = ref(cloneDeep(probableEvent.value))
    const preparationEventDetailsData = ref(cloneDeep(probableEvent.value))
    const preparationTeamDetails = ref(cloneDeep(probableEvent.value))

    const openedPopup: Ref<EventPreparationChildPopupType | null> = ref(null)
    const selectedParticipant: Ref<ProbableLinkedParticipant | null> =
      shallowRef(null)
    const probableHomeParticipant =
      probableLinkedFirstParticipant.value.getParticipant()
    const probableAwayParticipant =
      probableLinkedSecondParticipant.value.getParticipant()
    const staticHomeParticipant = reactive(
      cloneDeep(probableHomeParticipant.value),
    )
    const staticAwayParticipant = reactive(
      cloneDeep(probableAwayParticipant.value),
    )

    const save = (slider: number): void => {
      switch (slider) {
        case EventPreparationIndex.EventPreparationRelationStatus:
          actionQueue.value.add(
            new UpdateEvent({
              event: resolveUpdateEventObjectFromEventDiff(
                cloneDeep(probableEvent.value),
                preparationRelationStatusData.value,
              ),
              details: {},
            }),
          )
          break
        case EventPreparationIndex.EventPreparationEventDetails:
          actionQueue.value.add(
            new UpdateEvent({
              details: resolveUpdateEventDetailsObjectFromEventDetailsDiff(
                cloneDeep(probableEvent.value.details),
                preparationEventDetailsData.value.details,
                sport.value.sportsAPIEntity,
              ),
              event: resolveUpdateEventObjectFromEventDiff(
                cloneDeep(probableEvent.value),
                preparationEventDetailsData.value,
              ),
            }),
          )
          actionQueue.value.add(
            new UpdateEventParticipants({
              data: [
                participantDiff(
                  staticHomeParticipant,
                  probableHomeParticipant.value,
                ),
                participantDiff(
                  staticAwayParticipant,
                  probableAwayParticipant.value,
                ),
              ],
            }),
          )
          break
        case EventPreparationIndex.EventPreparationTeamDetails:
          actionQueue.value.add(
            new UpdateEvent({
              details: resolveUpdateEventDetailsObjectFromEventDetailsDiff(
                cloneDeep(probableEvent.value.details),
                preparationTeamDetails.value.details,
                sport.value.sportsAPIEntity,
              ),
              event: resolveUpdateEventObjectFromEventDiff(
                cloneDeep(probableEvent.value),
                preparationTeamDetails.value,
              ),
            }),
          )
          break
      }

      nextTick(() => {
        preparationRelationStatusData.value = cloneDeep(probableEvent.value)
        preparationEventDetailsData.value = cloneDeep(probableEvent.value)
        preparationTeamDetails.value = cloneDeep(probableEvent.value)
      })
    }

    function next(): void {
      save(sliderPosition.value)
      sliderPosition.value++

      if (sliderPosition.value > maximumSliderPosition) {
        if (probableEvent.value.relation_status === RelationStatus.NotStarted) {
          sliderPosition.value = 0

          return
        }

        popup.hide()
      }
    }

    function close(): void {
      popup.hide()
      router.replace({ name: 'EventsList' })
    }

    function isSliderAtPosition(position: number): boolean {
      return sliderPosition.value === position
    }

    function handleOpenPopup({
      popupType,
      probableLinkedParticipant,
    }: {
      popupType: EventPreparationChildPopupType
      probableLinkedParticipant: ProbableLinkedParticipant
    }): void {
      openedPopup.value = popupType
      selectedParticipant.value = probableLinkedParticipant
    }

    function isPopupOpened(popupType: EventPreparationChildPopupType): boolean {
      return openedPopup.value === popupType
    }

    function closeChildPopup(): void {
      openedPopup.value = null
    }

    function participantStatsUpdated(participant: Participant): void {
      if (participant.id === probableHomeParticipant.value.id) {
        probableHomeParticipant.value.stats = participant.stats
      } else if (participant.id === probableAwayParticipant.value.id) {
        probableAwayParticipant.value.stats = participant.stats
      }
    }

    return {
      sliderElement,
      sliderPosition,
      close,
      next,
      isSliderAtPosition,
      EventPreparationRelationStatus,
      EventPreparationEventDetails,
      EventPreparationTeamDetails,
      EventPreparationEventStats,
      preparationEventDetailsData,
      preparationRelationStatusData,
      preparationTeamDetails,
      sliderElementsCount,
      eventName,
      isLastStep,
      handleOpenPopup,
      closeChildPopup,
      isPopupOpened,
      selectedParticipant,
      EventPreparationChildPopupType,
      participantStatsUpdated,
    }
  },
})
</script>

<template>
  <div
    ref="sliderElement"
    class="bg-silver"
  >
    <EventPreparationHeader :eventName />

    <div class="inline-grid w-full grid-cols-3 gap-1 px-4 pb-2">
      <div
        v-for="i in sliderElementsCount"
        :key="`slider-${i}`"
        class="h-1 rounded bg-white"
        :class="{ 'bg-green': i <= sliderPosition + 1 }"
        @click="sliderPosition = i - 1"
      ></div>
    </div>
    <div class="flex w-full">
      <div
        class="slider-item min-w-full px-4 pb-0 pt-4 transition-transform"
        :class="{
          'max-h-none': isSliderAtPosition(0),
          'slider-item-inactive': !isSliderAtPosition(0),
        }"
        :style="{ '--sliderPosition': sliderPosition }"
      >
        <EventPreparationRelationStatus
          :event="preparationRelationStatusData"
        />
      </div>
      <div
        class="slider-item min-w-full px-4 pb-0 pt-4 transition-transform"
        :class="{
          'max-h-none': isSliderAtPosition(1),
          'slider-item-inactive': !isSliderAtPosition(1),
        }"
        :style="{ '--sliderPosition': sliderPosition }"
      >
        <EventPreparationEventDetails
          :event="preparationEventDetailsData"
          :setFullHeight="false"
        />
        <EventPreparationEventStats
          :event="preparationEventDetailsData"
          :setFullHeight="false"
          @participantStatsUpdated="participantStatsUpdated"
        />
      </div>
      <div
        class="slider-item min-w-full px-4 pb-0 pt-4 transition-transform"
        :class="{
          'max-h-none': isSliderAtPosition(2),
          'slider-item-inactive': !isSliderAtPosition(2),
        }"
        :style="{ '--sliderPosition': sliderPosition }"
      >
        <EventPreparationTeamDetails
          :event="preparationTeamDetails"
          @openPopup="handleOpenPopup"
        />
      </div>
    </div>
    <PopupControls
      :confirmButtonText="
        isLastStep ? 'Go to event relation' : 'Save & continue'
      "
      @confirm="next()"
      @cancel="close()"
    />

    <template v-if="selectedParticipant">
      <StackedPopup v-if="isPopupOpened(EventPreparationChildPopupType.Skins)">
        <Skins
          :probableLinkedParticipant="selectedParticipant"
          @close="closeChildPopup"
        />
      </StackedPopup>
      <StackedPopup
        v-if="isPopupOpened(EventPreparationChildPopupType.Lineups)"
      >
        <Lineups
          :probableLinkedParticipant="selectedParticipant"
          @close="closeChildPopup"
        />
      </StackedPopup>
      <StackedPopup
        v-if="isPopupOpened(EventPreparationChildPopupType.Formations)"
      >
        <Formations
          :probableLinkedParticipant="selectedParticipant"
          @close="closeChildPopup"
        />
      </StackedPopup>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.slider-item {
  transform: translateX(calc(-100% * var(--sliderPosition)));
}

.slider-item-inactive {
  // 100vh - popup controls - popup header
  max-height: calc(100vh - 154px);
}
</style>
