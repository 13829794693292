import { Container } from 'inversify'
import { Component } from 'vue'

import { SportId } from '@collector/sportsapi-client-legacy'
import AmericanFootballRelation, {
  bindAmericanFootballRelationDependencies,
} from '@mobile/views/Relation/Sports/AmericanFootball/AmericanFootballRelation.vue'
import BasketballRelation, {
  bindBasketballRelationDependencies,
} from '@mobile/views/Relation/Sports/Basketball/BasketballRelation.vue'
import Basketball3x3Relation, {
  bindBasketball3x3RelationDependencies,
} from '@mobile/views/Relation/Sports/Basketball3x3/Basketball3x3Relation.vue'
import BeachVolleyballRelation, {
  bindBeachVolleyballRelationDependencies,
} from '@mobile/views/Relation/Sports/BeachVolleyball/BeachVolleyballRelation.vue'
import CricketRelation, {
  bindCricketRelationDependencies,
} from '@mobile/views/Relation/Sports/Cricket/CricketRelation.vue'
import HandballRelation, {
  bindHandballRelationDependencies,
} from '@mobile/views/Relation/Sports/Handball/HandballRelation.vue'
import IceHockeyRelation, {
  bindIceHockeyRelationDependencies,
} from '@mobile/views/Relation/Sports/IceHockey/IceHockeyRelation.vue'
import SoccerRelation, {
  bindSoccerRelationDependencies,
} from '@mobile/views/Relation/Sports/Soccer/SoccerRelation.vue'
import VolleyballRelation, {
  bindVolleyballRelationDependencies,
} from '@mobile/views/Relation/Sports/Volleyball/VolleyballRelation.vue'

export type Relation = {
  component: Component
  bindSportRelationDependencies(asyncIoC: Container): Record<string, unknown>
}

const sportRelations: {
  [sportId in SportId]?: Relation
} = {
  [SportId.Soccer]: {
    component: SoccerRelation,
    bindSportRelationDependencies: bindSoccerRelationDependencies,
  },
  [SportId.Handball]: {
    component: HandballRelation,
    bindSportRelationDependencies: bindHandballRelationDependencies,
  },
  [SportId.Basketball]: {
    component: BasketballRelation,
    bindSportRelationDependencies: bindBasketballRelationDependencies,
  },
  [SportId.Cricket]: {
    component: CricketRelation,
    bindSportRelationDependencies: bindCricketRelationDependencies,
  },
  [SportId.Volleyball]: {
    component: VolleyballRelation,
    bindSportRelationDependencies: bindVolleyballRelationDependencies,
  },
  [SportId.BeachVolleyball]: {
    component: BeachVolleyballRelation,
    bindSportRelationDependencies: bindBeachVolleyballRelationDependencies,
  },
  [SportId.AmericanFootball]: {
    component: AmericanFootballRelation,
    bindSportRelationDependencies: bindAmericanFootballRelationDependencies,
  },
  [SportId.Basketball3x3]: {
    component: Basketball3x3Relation,
    bindSportRelationDependencies: bindBasketball3x3RelationDependencies,
  },
  [SportId.IceHockey]: {
    component: IceHockeyRelation,
    bindSportRelationDependencies: bindIceHockeyRelationDependencies,
  },
}

export function getSportRelation(sportId: SportId): Relation {
  const relation = sportRelations[sportId]
  if (!relation) {
    throw new Error(`Relation for this sport (${sportId}) is not supported`)
  }

  return relation
}
