import { Container } from 'inversify'

import {
  Confirmation,
  CricketEventStatusId,
  CricketIncidentId,
  CricketResultId,
  CricketStatId,
  IllegalBallAttributesOptions,
  LegalBallAttributesOptions,
  PenaltyRunsAttributesId,
} from '@collector/sportsapi-client-legacy'
import {
  addToEventResult,
  addToEventStat,
  changeEventStatus,
} from '@mobile/ActionQueue/Actions/Event/EventAction'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

import {
  adjustCurrentActionStat,
  resolveIncidentAdditionalInfo,
} from './resolveIncidentAdditionalInfo'

export function bindIncidentsConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.IncidentsConfiguration)
    .toDynamicValue(() => {
      return {
        [CricketIncidentId.NotStarted]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              CricketEventStatusId.NotStarted,
            )
          },
        },
        [CricketIncidentId.StartDelayed]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              CricketEventStatusId.StartDelayed,
            )
          },
        },
        [CricketIncidentId.Live]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(probableEvent, sport, CricketEventStatusId.Live)
          },
        },
        [CricketIncidentId.SuperOverHomeStarted]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              CricketEventStatusId.SuperOverHome,
            )
          },
        },
        [CricketIncidentId.SuperOverAwayStarted]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              CricketEventStatusId.SuperOverAway,
            )
          },
        },
        [CricketIncidentId.InningsBreak]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              CricketEventStatusId.InningsBreak,
            )
          },
        },
        [CricketIncidentId.AfterDayOne]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              CricketEventStatusId.AfterDayOne,
            )
          },
        },
        [CricketIncidentId.AfterDayTwo]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              CricketEventStatusId.AfterDayTwo,
            )
          },
        },
        [CricketIncidentId.AfterDayThree]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              CricketEventStatusId.AfterDayThree,
            )
          },
        },
        [CricketIncidentId.AfterDayFour]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              CricketEventStatusId.AfterDayFour,
            )
          },
        },
        [CricketIncidentId.Finished]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              CricketEventStatusId.Finished,
            )
          },
        },
        [CricketIncidentId.FinishedAfterSuperOver]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              CricketEventStatusId.FinishedAfterSuperOver,
            )
          },
        },
        [CricketIncidentId.FinishedRegularTime]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              CricketEventStatusId.Finished,
            )
          },
        },
        [CricketIncidentId.Interrupted]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              CricketEventStatusId.Interrupted,
            )
          },
        },
        [CricketIncidentId.Cancelled]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              CricketEventStatusId.Cancelled,
            )
          },
        },
        [CricketIncidentId.Postponed]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              CricketEventStatusId.Postponed,
            )
          },
        },
        [CricketIncidentId.Abandoned]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              CricketEventStatusId.Abandoned,
            )
          },
        },
        [CricketIncidentId.ToFinish]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              CricketEventStatusId.ToFinish,
            )
          },
        },
        [CricketIncidentId.LegalBall]: {
          applyAddIncidentResultToEvent(
            probableEvent,
            sport,
            dto,
            probableIncident,
            probableIncidents,
          ) {
            const ballAttributeValue = findBallAttributeValue(probableIncident)
            addToEventResult(
              probableEvent,
              dto.participant_team_id,
              CricketResultId.CurrentBall,
              1,
            )
            if (ballAttributeValue) {
              addToEventResult(
                probableEvent,
                dto.participant_team_id,
                CricketResultId.FirstInningRuns,
                ballAttributeValue,
              )
            }

            probableIncident.additional_info = resolveIncidentAdditionalInfo(
              probableEvent,
              Number(dto.participant_team_id),
              probableIncidents,
            )
          },
          applyDeleteIncidentResultToEvent(
            probableEvent,
            sport,
            probableIncident,
          ) {
            if (probableIncident.confirmation !== Confirmation.Cancelled) {
              addToEventResult(
                probableEvent,
                probableIncident.participant_id,
                CricketResultId.CurrentBall,
                -1,
              )
              const ballAttributeValue =
                findBallAttributeValue(probableIncident)
              if (ballAttributeValue) {
                addToEventResult(
                  probableEvent,
                  probableIncident.participant_id,
                  CricketResultId.FirstInningRuns,
                  -ballAttributeValue,
                )
              }
            }
          },
        },
        [CricketIncidentId.IllegalBall]: {
          applyAddIncidentResultToEvent(
            probableEvent,
            sport,
            dto,
            probableIncident,
            probableIncidents,
          ) {
            const ballAttributeValue = findBallAttributeValue(probableIncident)
            if (ballAttributeValue) {
              addToEventResult(
                probableEvent,
                dto.participant_team_id,
                CricketResultId.FirstInningRuns,
                ballAttributeValue,
              )
            }

            probableIncident.additional_info = resolveIncidentAdditionalInfo(
              probableEvent,
              Number(dto.participant_team_id),
              probableIncidents,
            )
          },
          applyDeleteIncidentResultToEvent(
            probableEvent,
            sport,
            probableIncident,
          ) {
            if (probableIncident.confirmation !== Confirmation.Cancelled) {
              const ballAttributeValue =
                findBallAttributeValue(probableIncident)
              if (ballAttributeValue) {
                addToEventResult(
                  probableEvent,
                  probableIncident.participant_id,
                  CricketResultId.FirstInningRuns,
                  -ballAttributeValue,
                )
              }
            }
          },
        },
        [CricketIncidentId.ExtrasPenaltyRuns]: {
          applyAddIncidentResultToEvent(
            probableEvent,
            sport,
            dto,
            probableIncident,
          ) {
            if (
              probableIncident.attribute_ids.includes(
                PenaltyRunsAttributesId.Five,
              )
            ) {
              addToEventResult(
                probableEvent,
                dto.participant_team_id,
                CricketResultId.FirstInningRuns,
                5,
              )
            }
          },
          applyDeleteIncidentResultToEvent(
            probableEvent,
            sport,
            probableIncident,
          ) {
            if (
              probableIncident.confirmation !== Confirmation.Cancelled &&
              probableIncident.attribute_ids.includes(
                PenaltyRunsAttributesId.Five,
              )
            ) {
              addToEventResult(
                probableEvent,
                probableIncident.participant_id,
                CricketResultId.FirstInningRuns,
                -5,
              )
            }
          },
        },
        [CricketIncidentId.EndOfOver]: {
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            addToEventResult(
              probableEvent,
              dto.participant_team_id,
              CricketResultId.CurrentBall,
              0,
              true,
            )
            addToEventResult(
              probableEvent,
              dto.participant_team_id,
              CricketResultId.Overs,
              1,
            )
            addToEventStat(
              probableEvent,
              dto.participant_team_id,
              CricketStatId.CurrentAction,
              0,
              true,
            )
          },
        },
        [CricketIncidentId.FirstInningsHomeStarted]: {
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            addToEventResult(
              probableEvent,
              dto.participant_team_id,
              CricketResultId.CurrentBall,
              0,
              true,
            )
            addToEventResult(
              probableEvent,
              dto.participant_team_id,
              CricketResultId.Wickets,
              0,
              true,
            )
          },
        },
        [CricketIncidentId.FirstInningsAwayStarted]: {
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            addToEventResult(
              probableEvent,
              dto.participant_team_id,
              CricketResultId.CurrentBall,
              0,
              true,
            )
            addToEventResult(
              probableEvent,
              dto.participant_team_id,
              CricketResultId.Wickets,
              0,
              true,
            )
          },
        },
        [CricketIncidentId.Out]: {
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            addToEventResult(
              probableEvent,
              dto.participant_team_id,
              CricketResultId.Wickets,
              1,
            )
            addToEventStat(
              probableEvent,
              dto.participant_team_id,
              CricketStatId.CurrentAction,
              1,
            )
          },
          applyDeleteIncidentResultToEvent(
            probableEvent,
            sport,
            probableIncident,
          ) {
            if (probableIncident.confirmation !== Confirmation.Cancelled) {
              addToEventStat(
                probableEvent,
                probableIncident.participant_id,
                CricketStatId.CurrentAction,
                -1,
              )
              addToEventResult(
                probableEvent,
                probableIncident.participant_id,
                CricketResultId.Wickets,
                -1,
              )
            }
          },
        },
        [CricketIncidentId.BowlerRunningIn]: {
          applyAddIncidentResultToEvent(
            probableEvent,
            sport,
            dto,
            probableIncident,
            probableIncidents,
          ) {
            adjustCurrentActionStat(
              probableEvent,
              Number(dto.participant_team_id),
              probableIncidents,
            )
            addToEventStat(
              probableEvent,
              probableIncident.participant_id,
              CricketStatId.CurrentAction,
              1,
            )

            probableIncident.additional_info = resolveIncidentAdditionalInfo(
              probableEvent,
              Number(dto.participant_team_id),
              probableIncidents,
            )
          },
          applyDeleteIncidentResultToEvent(
            probableEvent,
            sport,
            probableIncident,
          ) {
            if (probableIncident.confirmation !== Confirmation.Cancelled) {
              addToEventStat(
                probableEvent,
                probableIncident.participant_id,
                CricketStatId.CurrentAction,
                -1,
              )
            }
          },
        },
        fallbackIncidentConfiguration: {},
      }
    })
    .inSingletonScope()
}

function findBallAttributeValue(
  probableIncident: ProbableIncident,
): number | undefined {
  const ballAttributesOption = [
    ...LegalBallAttributesOptions,
    ...IllegalBallAttributesOptions,
  ].find((option) => {
    return probableIncident.attribute_ids.find(
      (attribute) => attribute === option.id,
    )
  })

  return ballAttributesOption?.value
}
