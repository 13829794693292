<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'

import MainResult from './MainResult.vue'
import OverResult from './OverResult.vue'

export default defineComponent({
  components: {
    MainResult,
    OverResult,
  },
  props: {
    probableLinkedParticipant: {
      type: Object as PropType<ProbableLinkedParticipant>,
      required: true,
    },
  },
})
</script>

<template>
  <MainResult v-bind="{ probableLinkedParticipant }" />
  <OverResult v-bind="{ probableLinkedParticipant }" />
</template>
