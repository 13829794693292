<script lang="ts">
import dayjs from 'dayjs'
import { computed, defineComponent, PropType, ref } from 'vue'

import {
  notificationColors,
  notificationIcons,
} from '@mobile/reusables/notifications'
import { AppNotification } from '@mobile/reusables/types/AppNotification'

export default defineComponent({
  props: {
    notification: {
      type: Object as PropType<AppNotification>,
      required: true,
    },
  },
  emits: ['canceled'],
  setup(props) {
    const formattedDate = computed(() => {
      const date = props.notification.date

      return dayjs(date).format('HH:mm:ss')
    })
    const backgroundClass = computed(
      () =>
        `from-${notificationColors.get(props.notification.type) ?? 'white'}`,
    )
    const notificationIcon = computed(() =>
      notificationIcons.get(props.notification.type),
    )
    const titleColor = computed(
      () =>
        `text-${notificationColors.get(props.notification.type) ?? 'black'}`,
    )

    const areDetailsVisible = ref(false)
    function toggleDetails(): void {
      areDetailsVisible.value = !areDetailsVisible.value
    }

    return {
      titleColor,
      formattedDate,
      backgroundClass,
      notificationIcon,
      areDetailsVisible,
      toggleDetails,
    }
  },
})
</script>

<template>
  <div>
    <div
      class="relative overflow-hidden rounded-md bg-white text-sm"
      @click="notification.markAsRead()"
    >
      <div
        class="absolute inset-0 bg-gradient-to-r via-white to-white opacity-20"
        :class="backgroundClass"
      ></div>
      <div class="relative">
        <div class="flex justify-between p-3 text-xs">
          <div
            class="flex items-center space-x-2"
            :class="titleColor"
          >
            <FontAwesomeIcon
              class="fa-lg"
              :icon="notificationIcon"
            />
            <div
              v-if="!notification.read"
              class="font-bold text-black"
            >
              UNREAD
            </div>
            <div class="font-semibold">
              {{ notification.title }}
            </div>
          </div>
          <div class="flex items-center space-x-3">
            <div
              class="text-black"
              :class="{ 'font-bold': !notification.read }"
            >
              {{ formattedDate }}
            </div>
            <div
              v-if="!notification.read"
              class="bg-blue h-3 w-3 rounded-full"
            ></div>
          </div>
        </div>
        <div class="text-cloudygray block px-3 pb-3">
          {{ notification.content }}
          <div
            v-if="notification.details"
            class="mt-4"
          >
            <a
              class="text-darkblue"
              href="#"
              @click.prevent="toggleDetails()"
            >
              <span v-if="!areDetailsVisible">Show details</span>
              <span v-else>Hide details</span>
            </a>

            <div
              v-if="areDetailsVisible"
              class="whitespace-pre text-xs"
            >
              {{ JSON.stringify(notification.details, undefined, 2) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
