import { Container } from 'inversify'

import { Basketball3x3IncidentId } from '@collector/sportsapi-client-legacy'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindEventStatusesList(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.EventStatusesList)
    .toDynamicValue(() => {
      return [
        Basketball3x3IncidentId.NotStarted,
        Basketball3x3IncidentId.StartDelayed,
        Basketball3x3IncidentId.MatchAboutToStart,
        Basketball3x3IncidentId.FinishedRegularTime,
        Basketball3x3IncidentId.WaitingForOvertime,
        Basketball3x3IncidentId.FinishedAfterOvertime,
        Basketball3x3IncidentId.FinishedAwardedWin,
        Basketball3x3IncidentId.RegularTimeStarted,
        Basketball3x3IncidentId.Interrupted,
        Basketball3x3IncidentId.Postponed,
        Basketball3x3IncidentId.Cancelled,
        Basketball3x3IncidentId.Abandoned,
        Basketball3x3IncidentId.ToFinish,
      ]
    })
    .inSingletonScope()
}
