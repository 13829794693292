import { Container } from 'inversify'

import { CricketEventStatusId } from '@collector/sportsapi-client-legacy'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindEventStatusesConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.EventStatusesConfiguration)
    .toDynamicValue(() => {
      return {
        [CricketEventStatusId.NotStarted]: { time: null },
        [CricketEventStatusId.StartDelayed]: { time: null },
        [CricketEventStatusId.FinishedAwardedWin]: { time: null },
        [CricketEventStatusId.Postponed]: { time: null },
        [CricketEventStatusId.Cancelled]: { time: null },
        [CricketEventStatusId.Abandoned]: { time: null },
        [CricketEventStatusId.SuperOverHome]: { time: null },
        [CricketEventStatusId.SuperOverAway]: { time: null },
        [CricketEventStatusId.Live]: { time: null },
        [CricketEventStatusId.FirstInningsHome]: { time: null },
        [CricketEventStatusId.FirstInningsAway]: { time: null },
        [CricketEventStatusId.InningsBreak]: { time: null },
        [CricketEventStatusId.AfterDayOne]: { time: null },
        [CricketEventStatusId.AfterDayTwo]: { time: null },
        [CricketEventStatusId.AfterDayThree]: { time: null },
        [CricketEventStatusId.AfterDayFour]: { time: null },
        [CricketEventStatusId.Finished]: { time: null },
        [CricketEventStatusId.FinishedAfterSuperOver]: { time: null },
        [CricketEventStatusId.Interrupted]: { time: null },
        [CricketEventStatusId.ToFinish]: { time: null },
      }
    })
    .inSingletonScope()
}
