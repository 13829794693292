import Dexie from 'dexie'

import { Repository } from '@mobile/database/Repository'
import { EventSettings } from '@mobile/reusables/types/EventSettings'

export enum EventSettingsType {
  MOBILE = 'mobile',
  DETAILS_PANEL = 'details_panel',
  PROTOCOL = 'protocol',
}

export type EventSettingsRecord = {
  eventId: number
  settings: EventSettings
  type: string
}

export class EventSettingsRepository extends Repository<EventSettingsRecord> {
  static readonly tableName = 'eventSettings'
  static readonly indexes = 'eventId'

  constructor(database: Dexie) {
    super(
      database.table<EventSettingsRecord>(EventSettingsRepository.tableName),
    )
  }

  public async getByEventId(
    eventId: number,
  ): Promise<EventSettingsRecord | undefined> {
    return this.table.get(eventId)
  }

  public async removeByEventId(eventId: number): Promise<void> {
    await this.table.delete(eventId)
  }
}
