import {
  Event,
  Participant,
  UpdateEventParticipantsDTO,
  UpdateEventParticipantsObject,
} from '@collector/sportsapi-client-legacy'
import { getCurrentEvent } from '@mobile/globalState/event'
import * as sportsAPI from '@mobile/globalState/sportsAPI'

import EventAction from './EventAction'

export default class UpdateEventParticipants extends EventAction {
  public readonly type = 'UpdateEventParticipants'

  constructor(
    public readonly updateEventParticipantsDTO: UpdateEventParticipantsDTO,
    private readonly skipWaiting = false,
  ) {
    super()
  }

  public async execImpl(): Promise<void> {
    this.checkForScoutMessengerConnectionOrThrow()

    const participants = this.updateEventParticipantsDTO.data.filter(
      (participant) =>
        (participant.results && participant.results.length > 0) ||
        (participant.stats && participant.stats.length > 0),
    )
    if (participants.length > 0) {
      const event = getCurrentEvent()

      await this.confirmEventUpdateRequest(
        event.id,
        sportsAPI.state.client.updateEventParticipants(
          event.id,
          this.updateEventParticipantsDTO,
        ),
        this.skipWaiting,
      )
    }
  }

  public applyProbableResult(probableEvent: Event): void {
    this.updateEventParticipantsDTO.data
      .flatMap((participantDTO) => {
        const participant = probableEvent.participants.find(
          (participant) => participant.id === participantDTO.id,
        )

        return participant
          ? [
              {
                participantDTO,
                participant,
              },
            ]
          : []
      })
      .forEach((pairedParticipants) => {
        this.applyProbableResultToParticipantStats(
          pairedParticipants.participant,
          pairedParticipants.participantDTO,
        )
        this.applyProbableResultToParticipantResults(
          pairedParticipants.participant,
          pairedParticipants.participantDTO,
        )
      })
  }

  private applyProbableResultToParticipantStats(
    participant: Participant,
    participantDTO: UpdateEventParticipantsObject,
  ): void {
    participantDTO.stats
      ?.flatMap((statDTO) => {
        const stat = participant.stats.find((stat) => stat.id === statDTO.id)

        return stat
          ? [
              {
                statDTO,
                stat,
              },
            ]
          : []
      })
      .forEach((pairedStats) => {
        pairedStats.stat.value = pairedStats.statDTO.value
      })
  }

  private applyProbableResultToParticipantResults(
    participant: Participant,
    participantDTO: UpdateEventParticipantsObject,
  ): void {
    participantDTO.results
      ?.flatMap((resultDTO) => {
        const result = participant.results.find(
          (result) => result.id === resultDTO.id,
        )

        return result
          ? [
              {
                resultDTO,
                result,
              },
            ]
          : []
      })
      .forEach((pairedResults) => {
        pairedResults.result.value = pairedResults.resultDTO.value
      })
  }
}
