import { Container } from 'inversify'

import {
  IncidentId,
  SoccerEventDetailId,
  SoccerEventStatusId,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'
import { getNumericDetailValue } from '@mobile/reusables/eventUtils'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindEventStatusesConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.EventStatusesConfiguration)
    .toDynamicValue(async (ctx) => {
      const event = await ctx.container.getAsync(
        RelationIoCBindings.ProbableEvent,
      )
      const periodLength = getNumericDetailValue(
        event.value,
        SoccerEventDetailId.PeriodLength,
        45,
      )
      const extraPeriodLength = getNumericDetailValue(
        event.value,
        SoccerEventDetailId.ExtraPeriodLength,
        15,
      )

      const withCommonAllowedIncidents = (
        incidentsId: IncidentId[] = [],
      ): IncidentId[] => {
        const commonAllowedStatusesIncidents: SoccerIncidentId[] = [
          SoccerIncidentId.NotStarted,
          SoccerIncidentId.FinishedAwardedWin,
          SoccerIncidentId.Interrupted,
          SoccerIncidentId.Postponed,
          SoccerIncidentId.Cancelled,
          SoccerIncidentId.Abandoned,
          SoccerIncidentId.ToFinish,
          SoccerIncidentId.Injury,
        ]

        return [...commonAllowedStatusesIncidents, ...incidentsId]
      }

      const withCommonIncidentsAllowedDuringTheBreak = (
        incidentsId: IncidentId[] = [],
      ): IncidentId[] => {
        return [
          ...withCommonAllowedIncidents(),
          SoccerIncidentId.YellowCard,
          SoccerIncidentId.RedCard,
          SoccerIncidentId.SubstitutionIn,
          SoccerIncidentId.SubstitutionOut,
          ...incidentsId,
        ]
      }

      const withCommonIncidentsAllowedDuringThePenalties = (
        incidentsId: IncidentId[] = [],
      ): IncidentId[] => {
        return [
          ...withCommonAllowedIncidents(),
          SoccerIncidentId.YellowCard,
          SoccerIncidentId.RedCard,
          SoccerIncidentId.Goal,
          SoccerIncidentId.Penalty,
          SoccerIncidentId.SubstitutionIn,
          SoccerIncidentId.SubstitutionOut,
          SoccerIncidentId.VideoAssistantReferee,
          SoccerIncidentId.PenaltySavedByGoalkeeper,
          SoccerIncidentId.MissedPenalty,
          ...incidentsId,
        ]
      }

      const withCommonIncidentsAllowedDuringTheGame = (
        incidentsId: IncidentId[] = [],
      ): IncidentId[] => {
        return [
          ...withCommonAllowedIncidents(),
          SoccerIncidentId.YellowCard,
          SoccerIncidentId.RedCard,
          SoccerIncidentId.Corner,
          SoccerIncidentId.Goal,
          SoccerIncidentId.Penalty,
          SoccerIncidentId.SubstitutionIn,
          SoccerIncidentId.SubstitutionOut,
          SoccerIncidentId.VideoAssistantReferee,
          SoccerIncidentId.PenaltySavedByGoalkeeper,
          SoccerIncidentId.MissedPenalty,
          ...incidentsId,
        ]
      }

      return {
        [SoccerEventStatusId.NotStarted]: {
          time: {
            minute: 0,
            second: 0,
          },
          clockHidden: true,
          allowedIncidents: withCommonAllowedIncidents([
            SoccerIncidentId.MatchAboutToStart,
            SoccerIncidentId.StartDelayed,
            SoccerIncidentId.KickOff,
            SoccerIncidentId.FirstHalf,
          ]),
        },
        [SoccerEventStatusId.StartDelayed]: {
          time: {
            minute: 0,
            second: 0,
          },
          clockHidden: true,
          allowedIncidents: withCommonAllowedIncidents([
            SoccerIncidentId.MatchAboutToStart,
            SoccerIncidentId.KickOff,
            SoccerIncidentId.FirstHalf,
          ]),
        },
        [SoccerEventStatusId.FirstHalf]: {
          time: {
            minute: 0,
            second: 0,
          },
          allowedIncidents: withCommonIncidentsAllowedDuringTheGame([
            SoccerIncidentId.Halftime,
            SoccerIncidentId.AddedTime,
          ]),
        },
        [SoccerEventStatusId.Halftime]: {
          time: {
            minute: periodLength,
            second: 0,
          },
          clockHidden: true,
          allowedIncidents: withCommonIncidentsAllowedDuringTheBreak([
            SoccerIncidentId.SecondHalf,
          ]),
        },
        [SoccerEventStatusId.SecondHalf]: {
          time: {
            minute: periodLength,
            second: 0,
          },
          allowedIncidents: withCommonIncidentsAllowedDuringTheGame([
            SoccerIncidentId.WaitingForExtratime,
            SoccerIncidentId.WaitingForPenalty,
            SoccerIncidentId.FinishedRegularTime,
            SoccerIncidentId.AddedTime,
          ]),
        },
        [SoccerEventStatusId.WaitingForExtratime]: {
          time: {
            minute: periodLength * 2,
            second: 0,
          },
          clockHidden: true,
          allowedIncidents: withCommonIncidentsAllowedDuringTheBreak([
            SoccerIncidentId.ExtratimeFirstHalf,
          ]),
        },
        [SoccerEventStatusId.ExtratimeFirstHalf]: {
          time: {
            minute: periodLength * 2,
            second: 0,
          },
          allowedIncidents: withCommonIncidentsAllowedDuringTheGame([
            SoccerIncidentId.ExtratimeHalftime,
            SoccerIncidentId.AddedTime,
          ]),
        },
        [SoccerEventStatusId.ExtratimeHalftime]: {
          time: {
            minute: periodLength * 2 + extraPeriodLength,
            second: 0,
          },
          clockHidden: true,
          allowedIncidents: withCommonIncidentsAllowedDuringTheBreak([
            SoccerIncidentId.ExtratimeSecondHalf,
          ]),
        },
        [SoccerEventStatusId.ExtratimeSecondHalf]: {
          time: {
            minute: periodLength * 2 + extraPeriodLength,
            second: 0,
          },
          allowedIncidents: withCommonIncidentsAllowedDuringTheGame([
            SoccerIncidentId.WaitingForPenalty,
            SoccerIncidentId.FinishedAfterExtratime,
            SoccerIncidentId.AddedTime,
          ]),
        },
        [SoccerEventStatusId.WaitingForPenalty]: {
          time: {
            minute: periodLength * 2 + extraPeriodLength * 2,
            second: 0,
          },
          clockHidden: true,
          allowedIncidents: withCommonIncidentsAllowedDuringTheBreak([
            SoccerIncidentId.PenaltyShootoutStarted,
          ]),
        },
        [SoccerEventStatusId.PenaltyShootout]: {
          time: {
            minute: periodLength * 2 + extraPeriodLength * 2,
            second: 0,
          },
          clockHidden: true,
          allowedIncidents: withCommonIncidentsAllowedDuringThePenalties([
            SoccerIncidentId.FinishedAfterPenalties,
          ]),
        },
        [SoccerEventStatusId.FinishedRegularTime]: {
          time: {
            minute: periodLength,
            second: 0,
          },
          clockHidden: true,
        },
        [SoccerEventStatusId.FinishedAfterExtratime]: {
          time: {
            minute: periodLength * 2 + extraPeriodLength,
            second: 0,
          },
          clockHidden: true,
        },
        [SoccerEventStatusId.FinishedAfterPenalties]: {
          time: {
            minute: 0,
            second: 0,
          },
          clockHidden: true,
        },
        [SoccerEventStatusId.FinishedAwardedWin]: {
          time: {
            minute: 0,
            second: 0,
          },
          clockHidden: true,
        },
        [SoccerEventStatusId.Interrupted]: {
          time: {
            minute: 0,
            second: 0,
          },
        },
        [SoccerEventStatusId.Postponed]: {
          time: {
            minute: 0,
            second: 0,
          },
        },
        [SoccerEventStatusId.Cancelled]: {
          time: {
            minute: 0,
            second: 0,
          },
        },
        [SoccerEventStatusId.Abandoned]: {
          time: {
            minute: 0,
            second: 0,
          },
        },
        [SoccerEventStatusId.ToFinish]: {
          time: {
            minute: 0,
            second: 0,
          },
        },
      }
    })
    .inSingletonScope()
}
