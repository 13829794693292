<script setup lang="ts">
import { ref } from 'vue'

import { TextArea } from '@collector/shared-ui'

// emits
type Emits = {
  close: []
  confirm: [message: string]
}

const emit = defineEmits<Emits>()

// data
const message = ref('')

// methods
function onClose(): void {
  emit('close')
}

function onConfirm(): void {
  emit('confirm', message.value)
  message.value = ''
}
</script>

<template>
  <div class="fixed inset-0 z-30 h-screen w-screen bg-gray-500/50">
    <div class="grid h-full w-full place-content-around overflow-y-auto p-4">
      <div
        class="text-gray rounded-md bg-white p-6 shadow-md"
        dir="auto"
      >
        <div class="bg-white">
          <div class="text-sm">
            <div>
              Send us information about the error or problem you encountered
              during the coverage.
            </div>
            <div>
              Indicate what the problem was, at what minute of the match it
              occurred and what incident it was related to.
            </div>
          </div>
          <div class="pb-4">
            <div
              class="mt-5 h-full w-full"
              type="text"
            >
              <TextArea
                v-model="message"
                class="mt-5 h-full w-full"
                placeholder="Feedback"
                :autofocus="true"
                :rows="6"
                type="text"
                label="Description (optional)"
              />
            </div>
          </div>
        </div>
        <div class="w-full">
          <div class="grid grid-cols-10 gap-4">
            <button
              class="transform text-sm font-bold transition"
              type="button"
              @click="onClose"
            >
              <div
                class="text-gray place-content-center rounded-md border border-white bg-white px-4 py-3"
                dir="auto"
              >
                Cancel
              </div>
            </button>
            <div class="col-span-8"></div>
            <button
              class="transform text-sm font-bold transition"
              type="button"
              @click="onConfirm"
            >
              <div
                class="bg-green place-content-center rounded-md px-4 py-3 text-white"
                dir="auto"
              >
                Send
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
