import { interfaces } from 'inversify'
import { Ref } from 'vue'

import { ActiveParticipant } from './ActiveParticipant'

type SI<T> = interfaces.ServiceIdentifier<T>

interface BeachVolleyballRelationIoCBindingsInterface {
  ActiveParticipant: SI<Ref<ActiveParticipant>>
}

export const BeachVolleyballRelationIoCBindings: BeachVolleyballRelationIoCBindingsInterface =
  { ActiveParticipant: Symbol('ActiveParticipant') }
