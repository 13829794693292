import { IllegalBallAttributesId } from './IllegalBallAttributesId'

export const IllegalBallAttributesOptions = [
  {
    id: IllegalBallAttributesId.Zero,
    value: 0,
  },
  {
    id: IllegalBallAttributesId.One,
    value: 1,
  },
  {
    id: IllegalBallAttributesId.Two,
    value: 2,
  },
  {
    id: IllegalBallAttributesId.Three,
    value: 3,
  },
  {
    id: IllegalBallAttributesId.Four,
    value: 4,
  },
  {
    id: IllegalBallAttributesId.Five,
    value: 5,
  },
  {
    id: IllegalBallAttributesId.Six,
    value: 6,
  },
  {
    id: IllegalBallAttributesId.Seven,
    value: 7,
  },
  {
    id: IllegalBallAttributesId.Eight,
    value: 8,
  },
  {
    id: IllegalBallAttributesId.Nine,
    value: 9,
  },
]
