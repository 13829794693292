import {
  EventPreparationEventDetail,
  EventPreparationEventDetailsSportFactory,
} from './types'

export function eventPreparationEventDetailsDefaultFactory(): EventPreparationEventDetailsSportFactory {
  function getDetails(): EventPreparationEventDetail[] {
    return []
  }

  return { getDetails }
}
