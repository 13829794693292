import {
  CommonResultId,
  CricketResultId,
  CricketStatId,
} from '@collector/sportsapi-client-legacy'

import { ResultIdsSet } from '../../ParticipantsDataTable/ResultIdsSet'
import { StatIdsSet } from '../../ParticipantsDataTable/StatIdsSet'
import { VerifyResultsSportFactory } from './VerifyResultsSportFactory'

export function verifyResultsCricketFactory(): VerifyResultsSportFactory {
  function getResultsIdsSet(): ResultIdsSet {
    return new ResultIdsSet([
      CommonResultId.Result,
      CommonResultId.RegularTime,
      CricketResultId.Wickets,
      CricketResultId.Overs,
      CricketResultId.CurrentBall,
    ])
  }

  function getStatIdsSet(): StatIdsSet {
    return new StatIdsSet([CricketStatId.BattingTeam])
  }

  return {
    getResultsIdsSet,
    getStatIdsSet,
  }
}
