export enum Basketball3x3IncidentId {
  NotStarted = 2787,
  MatchAboutToStart = 2788,
  StartDelayed = 2789,
  RegularTimeStarted = 2790,
  WaitingForOvertime = 2791,
  Overtime = 2792,
  FinishedAfterOvertime = 2793,
  FinishedAwardedWin = 2794,
  Interrupted = 2795,
  Postponed = 2796,
  Abandoned = 2797,
  FinishedRegularTime = 2798,
  Cancelled = 2799,
  ToFinish = 2800,
  TVTimeout = 2801,
  TVTimeoutOver = 2802,
  PlayUnderReview = 2803,
  RefereeInjury = 2804,
  BreakDuringTheGame = 2805,
  JumpBall = 2806,
  CoinFlip = 2807,
  InPossession = 2766,
  OnePointShotMade = 2767,
  OnePointShotMissed = 2768,
  TwoPointShotMade = 2769,
  TwoPointShotMissed = 2770,
  FreeThrowIn = 2771,
  FreeThrowMissed = 2772,
  OffensiveRebound = 2773,
  DefensiveRebound = 2774,
  Foul = 2775,
  FoulBonus = 2776,
  ThrowIn = 2777,
  Steal = 2778,
  TimeoutOver = 2779,
  Turnover = 2780,
  Timeout = 2781,
  Block = 2782,
  Injury = 2783,
  FreeThrowsAwarded = 2784,
  Challenge = 2785,
  StartWithABall = 2786,
}
