<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import {
  CricketIncidentId,
  CricketResultId,
} from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import * as popup from '@mobile/globalState/popup'
import { byId } from '@mobile/reusables/entityUtils'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'
import RelationIcon, {
  RelationIconType,
} from '@mobile/views/Relation/Shared/RelationIcon/RelationIcon.vue'

import { useCricketConfiguration } from '../CricketRelationDependencies/useCricketConfiguration'
import { EndOfOverPopup } from '../Popups/EndOfOver/EndOfOverPopup'

export default defineComponent({
  components: {
    Button,
    RelationIcon,
  },
  props: {
    context: {
      type: Object as PropType<PopupContext | null>,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { activeParticipant } = useCricketConfiguration()

    const currentBall = computed(() => {
      if (!activeParticipant.value.participant) {
        return null
      }

      return byId(
        activeParticipant.value.participant?.results,
        CricketResultId.CurrentBall,
      )
    })

    const ballReminder = computed(() => Number(currentBall?.value?.value) >= 6)

    function handleClick(): void {
      if (props.context) {
        props.context?.openPopup(EndOfOverPopup)
      } else {
        popup.show({
          component: EndOfOverPopup,
          showHeader: true,
        })
      }
    }

    return {
      handleClick,
      RelationIconType,
      CricketIncidentId,
      currentBall,
      ballReminder,
    }
  },
})
</script>

<template>
  <Button
    class="w-full text-left"
    :class="{ 'animate-pulse text-white': ballReminder }"
    :color="ballReminder ? 'blue' : 'white'"
    :size="'xl'"
    :type="'horizontal'"
    :withIcon="false"
    :disabled
    @click="handleClick"
  >
    <div class="flex flex-1 items-center">
      <RelationIcon
        :size="13"
        :iconId="CricketIncidentId.EndOfOver"
        :type="RelationIconType.Incidents"
      />
      <div class="mb-0.5 mt-px flex items-center text-left font-bold">
        <slot>End Of Over</slot>
      </div>
    </div>
  </Button>
</template>
