import Dexie from 'dexie'

import { Skin } from '@collector/sportsapi-client-legacy'

import { Repository } from './Repository'

export type SkinRecord = {
  eventId: number
  participantId: number
  skins: Skin[]
}

export class SkinsRepository extends Repository<SkinRecord> {
  static readonly tableName = 'skins'
  static readonly indexes = '++id, [eventId+participantId]'

  constructor(database: Dexie) {
    super(database.table<SkinRecord>(SkinsRepository.tableName))
  }

  public async findForParticipant(
    eventId: number,
    participantId: number,
  ): Promise<SkinRecord | undefined> {
    return this.table
      .where({
        participantId,
        eventId,
      })
      .last()
  }

  public async save(
    eventId: number,
    participantId: number,
    skins: Skin[],
  ): Promise<SkinRecord> {
    await this.table
      .where({
        participantId,
        eventId,
      })
      .delete()
    await this.table.put({
      eventId,
      participantId,
      skins,
    })

    return this.findForParticipant(
      eventId,
      participantId,
    ) as Promise<SkinRecord>
  }

  public async removeByEventId(eventId: number): Promise<number> {
    return this.table.where({ eventId }).delete()
  }
}
