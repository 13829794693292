import {
  CommonResultId,
  VolleyballResultId,
  VolleyballStatId,
} from '@collector/sportsapi-client-legacy'

import { ResultIdsSet } from '../../ParticipantsDataTable/ResultIdsSet'
import { StatIdsSet } from '../../ParticipantsDataTable/StatIdsSet'
import { VerifyResultsSportFactory } from './VerifyResultsSportFactory'

export function verifyResultsVolleyballFactory(): VerifyResultsSportFactory {
  function getResultsIdsSet(): ResultIdsSet {
    return new ResultIdsSet([
      CommonResultId.Result,
      VolleyballResultId.FirstSet,
      VolleyballResultId.SecondSet,
      VolleyballResultId.ThirdSet,
      VolleyballResultId.FourthSet,
      VolleyballResultId.FifthSet,
      VolleyballResultId.GoldenSet,
    ])
  }

  function getStatIdsSet(): StatIdsSet {
    return new StatIdsSet([
      VolleyballStatId.ServiceAces,
      VolleyballStatId.ServiceErrors,
    ])
  }

  return {
    getResultsIdsSet,
    getStatIdsSet,
  }
}
