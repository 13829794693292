import { Container } from 'inversify'

import {
  CollectorEventTime,
  CricketEventStatusId,
  CricketIncidentId,
  cricketInningsStatuses,
  EventStatusId,
} from '@collector/sportsapi-client-legacy'
import { AddIncident } from '@mobile/ActionQueue/Actions/Actions'
import IncidentId from '@mobile/ActionQueue/Actions/Incidents/IncidentId'
import * as popup from '@mobile/globalState/popup'
import { getOrThrow } from '@mobile/reusables/entityUtils'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { IncidentHandler } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/IncidentHandlersConfiguration'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'
import { CricketRelationIoCBindings } from '@mobile/views/Relation/Sports/Cricket/CricketRelationDependencies/CricketRelationDependencies'

import { BreakInPlayPopup } from '../Popups/BreakInPlay/BreakInPlayPopup'
import DuckworthLewisMethodPopup from '../Popups/DuckworthLewisMethod/DuckworthLewisMethodPopup.vue'
import { PenaltyPopup } from '../Popups/Penalty/PenaltyPopup'
import { PenaltyRunsPopup } from '../Popups/PenaltyRuns/PenaltyRunsPopup'
import { PossibleBoundaryPopup } from '../Popups/PossibleBoundary/PossibleBoundaryPopup'
import { PossibleWicketPopup } from '../Popups/PossibleWicket/PossibleWicketPopup'
import { prepareAdditionalInfo } from '../prepareAdditionalInfo'
import { DecisionReviewPopup } from './../Popups/DecisionReview/DecisionReviewPopup'

export function bindIncidentHandlersConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.IncidentHandlersConfiguration)
    .toDynamicValue(async (ctx) => {
      const [actionQueue, probableEvent, probableClock, probableIncidents] =
        await Promise.all([
          await ctx.container.getAsync(RelationIoCBindings.ActionQueue),
          await ctx.container.getAsync(RelationIoCBindings.ProbableEvent),
          await ctx.container.getAsync(RelationIoCBindings.ProbableClock),
          await ctx.container.getAsync(RelationIoCBindings.ProbableIncidents),
          await ctx.container.getAsync(
            RelationIoCBindings.ProbableLinkedHomeParticipant,
          ),
        ])
      const eventStatusesConfiguration = ctx.container.get(
        RelationSportCommonIoCBindings.EventStatusesConfiguration,
      )
      const activeParticipant = await ctx.container.getAsync(
        CricketRelationIoCBindings.ActiveParticipant,
      )

      const addIncident: IncidentHandler['handler'] = ({
        incidentId,
        participantId,
        additionalInfo,
        time = {
          minute: probableClock.value.minute,
          second: probableClock.value.second,
        },
      }) => {
        actionQueue.value.add(
          new AddIncident({
            incident_id: incidentId,
            status_id: probableEvent.value.status_id,
            participant_team_id: participantId,
            additional_info: additionalInfo,
            ...time,
          }),
        )
      }

      const isDisabledByCurrentEventStatus = (
        _incidentId: IncidentId,
      ): boolean => {
        // At the moment, all incidents must be by enabled always.
        return false

        // const statusIncidentMap: Array<StatusIncidentMap> = [
        //   { status: CricketEventStatusId.NotStarted, incident: CricketIncidentId.NotStarted },
        //   { status: CricketEventStatusId.Interrupted, incident: CricketIncidentId.StartDelayed },
        //   { status: CricketEventStatusId.Finished, incident: CricketIncidentId.FinishedRegularTime },
        // ];

        // const indexOfCurrentStatus = statusIncidentMap.findIndex((element) => element.status === probableEvent.value.status_id);
        // const indexOfSearchedIncident = statusIncidentMap.findIndex((element) => element.incident === incidentId);

        // return indexOfCurrentStatus >= indexOfSearchedIncident;
      }

      const getTimeForEventStatus = (
        eventStatusId: EventStatusId,
      ): CollectorEventTime | null => {
        return getOrThrow(eventStatusesConfiguration, eventStatusId).time
      }

      return {
        [CricketIncidentId.NotStarted]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [CricketIncidentId.StartDelayed]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(CricketEventStatusId.StartDelayed),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [CricketIncidentId.Live]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(CricketEventStatusId.Live),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [CricketIncidentId.FirstInningsHomeStarted]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(
                CricketEventStatusId.FirstInningsHome,
              ),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [CricketIncidentId.FirstInningsAwayStarted]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(
                CricketEventStatusId.FirstInningsAway,
              ),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [CricketIncidentId.SuperOverHomeStarted]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(CricketEventStatusId.SuperOverHome),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [CricketIncidentId.SuperOverAwayStarted]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(CricketEventStatusId.SuperOverAway),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [CricketIncidentId.InningsBreak]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(CricketEventStatusId.InningsBreak),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [CricketIncidentId.AfterDayOne]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(CricketEventStatusId.AfterDayOne),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [CricketIncidentId.AfterDayTwo]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(CricketEventStatusId.AfterDayTwo),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [CricketIncidentId.AfterDayThree]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(CricketEventStatusId.AfterDayThree),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [CricketIncidentId.AfterDayFour]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(CricketEventStatusId.AfterDayFour),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [CricketIncidentId.Finished]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(CricketEventStatusId.Finished),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [CricketIncidentId.FinishedAfterSuperOver]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(
                CricketEventStatusId.FinishedAfterSuperOver,
              ),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [CricketIncidentId.Cancelled]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(CricketEventStatusId.Cancelled),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [CricketIncidentId.Abandoned]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(CricketEventStatusId.Abandoned),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [CricketIncidentId.Interrupted]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(CricketEventStatusId.Interrupted),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [CricketIncidentId.Postponed]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(CricketEventStatusId.Postponed),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [CricketIncidentId.ToFinish]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(CricketEventStatusId.ToFinish),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [CricketIncidentId.FinishedRegularTime]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(CricketEventStatusId.Finished),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [CricketIncidentId.DuckworthLewisMethods]: {
          handler: ({ incidentId }) => {
            addIncident({
              incidentId,
              participantId: activeParticipant.value.participant?.id,
            })
            popup.show({
              component: DuckworthLewisMethodPopup,
              showHeader: true,
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [CricketIncidentId.TimedOut]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              participantId: activeParticipant.value.participant?.id,
              time: null,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [CricketIncidentId.Penalty]: {
          handler: () =>
            popup.show({
              component: PenaltyPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [CricketIncidentId.ExtrasPenaltyRuns]: {
          handler: () =>
            popup.show({
              component: PenaltyRunsPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [CricketIncidentId.BreakInPlay]: {
          handler: () =>
            popup.show({
              component: BreakInPlayPopup,
              showHeader: true,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [CricketIncidentId.DeadBall]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              participantId: activeParticipant.value.participant?.id,
            }),
          disabled: () => false,
        },
        [CricketIncidentId.DecisionReview]: {
          handler: () =>
            popup.show({
              component: DecisionReviewPopup,
              showHeader: true,
            }),
          disabled: () => false,
        },
        [CricketIncidentId.PossibleWicket]: {
          handler: ({ incidentId }) => {
            const additionalInfo = prepareAdditionalInfo(
              activeParticipant,
              probableIncidents,
            )

            addIncident({
              incidentId,
              participantId: activeParticipant.value.participant?.id,
              additionalInfo,
            })

            popup.show({
              component: PossibleWicketPopup,
              showHeader: true,
            })
          },
          disabled: () => false,
        },
        [CricketIncidentId.PossibleBoundary]: {
          handler: ({ incidentId }) => {
            const additionalInfo = prepareAdditionalInfo(
              activeParticipant,
              probableIncidents,
            )

            addIncident({
              incidentId,
              participantId: activeParticipant.value.participant?.id,
              additionalInfo,
            })

            popup.show({
              component: PossibleBoundaryPopup,
              showHeader: true,
            })
          },
          disabled: () => false,
        },
        [CricketIncidentId.RetiredHurt]: {
          handler: ({ incidentId }) => {
            addIncident({
              incidentId,
              participantId: activeParticipant.value.participant?.id,
            })
          },
          disabled: () => false,
        },
        [CricketIncidentId.StartOfOver]: {
          handler: ({ incidentId }) => {
            addIncident({
              incidentId,
              participantId: activeParticipant.value.participant?.id,
            })
          },
          disabled: () => {
            return !cricketInningsStatuses.includes(
              probableEvent.value.status_id as CricketEventStatusId,
            )
          },
        },
        [CricketIncidentId.EndOfOver]: {
          handler: ({ incidentId }) => {
            addIncident({
              incidentId,
              participantId: activeParticipant.value.participant?.id,
            })
          },
          disabled: () => false,
        },
        fallbackHandler: {
          handler: addIncident,
          disabled: () => false,
        },
      }
    })
    .inSingletonScope()
}
