import { CricketIncidentId } from './IncidentId'

export const CricketIncidentAcronymMap: {
  [key in CricketIncidentId]: string
} = {
  [CricketIncidentId.NotStarted]: 'NS',
  [CricketIncidentId.StartDelayed]: 'STDE',
  [CricketIncidentId.FinishedRegularTime]: 'FT',
  [CricketIncidentId.Live]: 'LIVE',
  [CricketIncidentId.Interrupted]: 'INT',
  [CricketIncidentId.Postponed]: 'POS',
  [CricketIncidentId.Abandoned]: 'ABA',
  [CricketIncidentId.Cancelled]: 'CAN',
  [CricketIncidentId.ToFinish]: 'TOF',
  [CricketIncidentId.FirstInningsHomeStarted]: '1STH',
  [CricketIncidentId.FirstInningsAwayStarted]: '1STA',
  [CricketIncidentId.AfterDayOne]: 'AFT1',
  [CricketIncidentId.AfterDayTwo]: 'AFT2',
  [CricketIncidentId.AfterDayThree]: 'AFT3',
  [CricketIncidentId.AfterDayFour]: 'AFT4',
  [CricketIncidentId.InningsBreak]: 'IB',
  [CricketIncidentId.SuperOverInningsBreak]: 'SOIB',
  [CricketIncidentId.SuperOverHomeStarted]: 'SOH',
  [CricketIncidentId.SuperOverAwayStarted]: 'SOA',
  [CricketIncidentId.Finished]: 'FIN',
  [CricketIncidentId.FinishedAfterSuperOver]: 'FASO',
  [CricketIncidentId.PossibleBoundary]: 'POSB',
  [CricketIncidentId.BoundaryFourRuns]: 'BOU4',
  [CricketIncidentId.BoundarySixRuns]: 'BOU6',
  [CricketIncidentId.PossibleWicket]: 'POSW',
  [CricketIncidentId.DeadBall]: 'DEAD',
  [CricketIncidentId.FreeHit]: 'FREE',
  [CricketIncidentId.OneShort]: 'ONES',
  [CricketIncidentId.BatsmanComingIn]: 'BATC',
  [CricketIncidentId.BowlerChange]: 'BOWC',
  [CricketIncidentId.BatsmanRetired]: 'BATR',
  [CricketIncidentId.DuckworthLewisMethods]: 'DUCK',
  [CricketIncidentId.EndOfOver]: 'ENDO',
  [CricketIncidentId.StartOfOver]: 'STAO',
  [CricketIncidentId.DecisionReview]: 'DEC',
  [CricketIncidentId.MatchAboutToStart]: 'MATS',
  [CricketIncidentId.BowlerRunningIn]: 'BOWR',
  [CricketIncidentId.NoRuns]: 'NORUN',
  [CricketIncidentId.Runs]: 'RUNS',
  [CricketIncidentId.RetiredHurt]: 'RETH',
  [CricketIncidentId.ExtrasWides]: 'EXW',
  [CricketIncidentId.ExtrasPenaltyRuns]: 'EXPR',
  [CricketIncidentId.ExtrasNoBall]: 'EXNB',
  [CricketIncidentId.ExtrasNoBallBat]: 'EXNBB',
  [CricketIncidentId.ExtrasLegByes]: 'EXLB',
  [CricketIncidentId.ExtrasByes]: 'EXB',
  [CricketIncidentId.DismissalLBW]: 'DISL',
  [CricketIncidentId.DismissalCaughtDisc]: 'DISC',
  [CricketIncidentId.DismissalBowled]: 'DISB',
  [CricketIncidentId.DismissalRunOut]: 'DISR',
  [CricketIncidentId.DismissalStumped]: 'DISS',
  [CricketIncidentId.DismissalHitWicket]: 'DISHW',
  [CricketIncidentId.DismissalHandledTheBall]: 'DISHA',
  [CricketIncidentId.DismissalObstructingTheField]: 'DISOB',
  [CricketIncidentId.DismissalHitTheBallTwice]: 'DISH2',
  [CricketIncidentId.TossWon]: 'TOSW',
  [CricketIncidentId.BattingTeam]: 'BATT',
  [CricketIncidentId.LegalBall]: 'LEG',
  [CricketIncidentId.IllegalBall]: 'ILLEG',
  [CricketIncidentId.TimedOut]: 'TO',
  [CricketIncidentId.BreakInPlay]: 'BIP',
  [CricketIncidentId.Penalty]: 'PEN',
  [CricketIncidentId.Out]: 'OUT',
  [CricketIncidentId.Extras]: 'EXTR',
}
