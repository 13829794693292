<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import UpdateEvent from '@mobile/ActionQueue/Actions/Event/UpdateEvent'
import QueueAction from '@mobile/views/Relation/Shared/Popups/Queue/Actions/QueueAction.vue'
import { QueueActionType } from '@mobile/views/Relation/Shared/Popups/Queue/Actions/QueueTypes'

export default defineComponent({
  components: { QueueAction },
  props: {
    action: {
      type: Object as PropType<UpdateEvent>,
      required: true,
    },
  },
  setup(props) {
    const info = computed(() =>
      JSON.stringify(props.action.updateEventDTO, undefined, 2),
    )

    return {
      QueueActionType,
      info,
    }
  },
})
</script>

<template>
  <QueueAction
    :action
    :actionInformation="'Event'"
  >
    <template #infoPanel>
      {{ info }}
    </template>
  </QueueAction>
</template>
