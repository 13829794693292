<script lang="ts">
import { defineComponent, PropType } from 'vue'

import {
  Event,
  relationStatuses,
  SportDetailInputType,
} from '@collector/sportsapi-client-legacy'
import InputText from '@mobile/components/InputText/InputText.vue'
import Select from '@mobile/components/Select/Select.vue'
import { byId } from '@mobile/reusables/entityUtils'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

import { useEventPreparationEventDetailsFactory } from './factories/EventPreparationEventDetailsFactory/useEventPreparationEventDetailsFactory'

export default defineComponent({
  components: {
    Select,
    InputText,
  },
  props: {
    event: {
      type: Object as PropType<Event>,
      required: true,
    },
    setFullHeight: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { sport } = useEventInjections()
    const details = useEventPreparationEventDetailsFactory(
      props.event,
      sport.value,
    ).getDetails()

    function getCurrentEventDetailValue(detailId: number): string {
      return String(byId(props.event.details, detailId)?.value ?? '')
    }

    function updateEventDetail(detailId: number, newValue: string): void {
      const eventDetail = byId(props.event.details, detailId)

      if (eventDetail) {
        eventDetail.value = newValue
      }
    }

    return {
      details,
      SportDetailInputType,
      relationStatuses,
      getCurrentEventDetailValue,
      updateEventDetail,
    }
  },
})
</script>

<template>
  <div :class="setFullHeight ? 'h-full pb-3' : ''">
    <div
      v-for="(detail, index) in details"
      :key="`detail-${index}`"
      class="flex w-full items-center pb-3"
    >
      <div class="text-gray w-1/4 text-left text-xs font-bold">
        {{ detail.name }}
      </div>
      <div class="w-3/4">
        <template
          v-if="detail.type === SportDetailInputType.Select && detail.items"
        >
          <Select
            v-if="detail.fieldName"
            v-model="
              // eslint-disable-next-line vue/no-mutating-props
              event[String(detail.fieldName) as keyof Event]
            "
            v-bind="detail.selectAttributes"
            label="name"
            :options="detail.items"
            @change="detail.onChange"
          />
          <Select
            v-else
            v-bind="detail.selectAttributes"
            :value="getCurrentEventDetailValue(Number(detail.id))"
            label="name"
            :options="detail.items"
            @update:value="updateEventDetail(Number(detail.id), $event)"
            @change="detail.onChange"
          />
        </template>
        <InputText
          v-else-if="detail.type === SportDetailInputType.Number"
          :value="getCurrentEventDetailValue(Number(detail.id))"
          @update:value="updateEventDetail(Number(detail.id), $event)"
        />
      </div>
    </div>
  </div>
</template>
