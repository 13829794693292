<script lang="ts">
import { computed, defineComponent, PropType, Ref, ref } from 'vue'

import { BeachVolleyballIncidentId } from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'
import PopupControls from '@mobile/views/Relation/Shared/Popup/PopupControls.vue'
import TeamSelect from '@mobile/views/Relation/Shared/Popup/TeamSelect.vue'
import { useIncidentAction } from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: {
    TeamSelect,
    Button,
    PopupControls,
  },
  props: {
    context: {
      type: Object as PropType<PopupContext | null>,
      required: false,
    },
  },
  emits: ['close'],
  setup(props) {
    const eventInjections = useEventInjections()
    const { sport } = useEventInjections()
    const selectedParticipant: Ref<ProbableLinkedParticipant | null> = ref(null)
    const selectedIncident: Ref<BeachVolleyballIncidentId | null> = ref(
      BeachVolleyballIncidentId.Challenge,
    )

    function closeAllPopups(): void {
      props.context?.closeAllPopups()
    }

    function selectParticipant(participant: ProbableLinkedParticipant): void {
      selectedParticipant.value = participant
    }

    function proceed(): void {
      if (!selectedIncident.value) return

      useIncidentAction(eventInjections, {
        incident_id: selectedIncident.value,
        participant_team_id:
          selectedParticipant.value?.getParticipant().value.id,
      })

      if (selectedIncident.value !== BeachVolleyballIncidentId.Challenge)
        closeAllPopups()

      selectedIncident.value = null
    }

    const shouldTeamSelect = computed(
      () => selectedIncident.value === BeachVolleyballIncidentId.Challenge,
    )
    const processable = computed(() =>
      shouldTeamSelect.value
        ? !!selectedParticipant.value
        : selectedIncident.value !== null,
    )

    return {
      BeachVolleyballIncidentId,
      closeAllPopups,
      sport,
      selectedParticipant,
      selectParticipant,
      selectedIncident,
      processable,
      proceed,
      shouldTeamSelect,
    }
  },
})
</script>

<template>
  <div>
    <TeamSelect
      v-if="shouldTeamSelect"
      :withButtons="false"
      :defaultParticipant="selectedParticipant"
      @participantSelected="selectParticipant"
    />

    <div v-else>
      <div class="grid place-items-center px-4 py-6">
        <div class="text-cloudygray py-2 text-center text-sm font-bold">
          Challenge
        </div>
        <div class="flex space-x-2">
          <Button
            class="w-20"
            :class="{
              'opacity-50':
                selectedIncident !== BeachVolleyballIncidentId.ChallengeCorrect,
            }"
            color="green"
            size="md"
            @click="
              selectedIncident = BeachVolleyballIncidentId.ChallengeCorrect
            "
          >
            <FontAwesomeIcon
              size="lg"
              icon="check"
            />
          </Button>

          <Button
            class="w-20"
            :class="{
              'opacity-50':
                selectedIncident !==
                BeachVolleyballIncidentId.ChallengeIncorrect,
            }"
            color="red"
            size="md"
            @click="
              selectedIncident = BeachVolleyballIncidentId.ChallengeIncorrect
            "
          >
            <FontAwesomeIcon
              size="lg"
              icon="times"
            />
          </Button>
        </div>
      </div>
    </div>

    <PopupControls>
      <template #buttons>
        <div
          class="grid gap-2"
          style="grid-template-columns: repeat(auto-fit, minmax(90px, 1fr))"
        >
          <div
            v-for="i in 3"
            :key="`item-${i}`"
          ></div>
          <Button
            size="md"
            color="red"
            @click="closeAllPopups"
          >
            Cancel
          </Button>
          <Button
            size="md"
            color="green"
            :disabled="!processable"
            @click="proceed"
          >
            Send
          </Button>
        </div>
      </template>
    </PopupControls>
  </div>
</template>
