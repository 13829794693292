<script lang="ts">
import { cloneDeep } from 'lodash'
import { defineComponent, PropType } from 'vue'

import { CricketIncidentId } from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'
import {
  IncidentStorePayload,
  useIncidentAction,
} from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

import { useCricketConfiguration } from '../../CricketRelationDependencies/useCricketConfiguration'
import { prepareAdditionalInfo } from '../../prepareAdditionalInfo'
import { WicketOutcomePopup } from '../WicketOutcome/WicketOutcomePopup'

export default defineComponent({
  components: { Button },
  props: {
    context: {
      type: Object as PropType<PopupContext | null>,
      required: false,
    },
  },
  setup(props) {
    const eventInjections = useEventInjections()
    const { probableIncidents } = eventInjections
    const { activeParticipant } = useCricketConfiguration()
    const staticLinkedProbableIncidents = cloneDeep(
      eventInjections.linkedProbableIncidents.value,
    )

    function proceed(incidentId: CricketIncidentId): void {
      const additionalInfo = prepareAdditionalInfo(
        activeParticipant,
        probableIncidents,
      )
      const payload: IncidentStorePayload = {
        incident_id: incidentId,
        participant_team_id: activeParticipant.value.participant?.id,
        additional_info: additionalInfo,
      }

      const incidentActionComposable = useIncidentAction(
        eventInjections,
        payload,
      )

      props.context?.openPopup(WicketOutcomePopup, {
        incidentActionComposable,
        staticLinkedProbableIncidents,
      })
    }

    function getIncidentName(incidentId: CricketIncidentId): string {
      return eventInjections.sport.value.getIncidentTranslation(incidentId)
    }

    return {
      CricketIncidentId,
      proceed,
      getIncidentName,
    }
  },
})
</script>

<template>
  <div>
    <div class="grid grid-cols-3 grid-rows-3 gap-4 px-4">
      <Button
        :size="'sm'"
        :color="'white'"
        :withIcon="false"
        :type="'horizontal'"
        @click="proceed(CricketIncidentId.DismissalLBW)"
      >
        LBW
      </Button>
      <Button
        :size="'sm'"
        :color="'white'"
        :withIcon="false"
        :type="'horizontal'"
        @click="proceed(CricketIncidentId.DismissalCaughtDisc)"
      >
        Caught
      </Button>
      <Button
        :size="'sm'"
        :color="'white'"
        :withIcon="false"
        :type="'horizontal'"
        @click="proceed(CricketIncidentId.DismissalBowled)"
      >
        Bowled
      </Button>
      <Button
        :size="'sm'"
        :color="'white'"
        :withIcon="false"
        :type="'horizontal'"
        @click="proceed(CricketIncidentId.DismissalRunOut)"
      >
        Run out
      </Button>
      <Button
        :size="'sm'"
        :color="'white'"
        :withIcon="false"
        :type="'horizontal'"
        @click="proceed(CricketIncidentId.DismissalStumped)"
      >
        Stumped
      </Button>
      <Button
        :size="'sm'"
        :color="'white'"
        :withIcon="false"
        :type="'horizontal'"
        @click="proceed(CricketIncidentId.DismissalHitWicket)"
      >
        Hit wicket
      </Button>
      <Button
        :size="'sm'"
        :color="'white'"
        :withIcon="false"
        :type="'horizontal'"
        @click="proceed(CricketIncidentId.DismissalHandledTheBall)"
      >
        Handled the ball
      </Button>
      <Button
        :size="'sm'"
        :color="'white'"
        :withIcon="false"
        :type="'horizontal'"
        @click="proceed(CricketIncidentId.DismissalObstructingTheField)"
      >
        Obstructing the Field
      </Button>
      <Button
        :size="'sm'"
        :color="'white'"
        :withIcon="false"
        :type="'horizontal'"
        @click="proceed(CricketIncidentId.DismissalHitTheBallTwice)"
      >
        Hit the ball twice
      </Button>
    </div>
  </div>
</template>
