<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    label: String,
    fieldId: {
      type: String,
      required: true,
    },
    checked: { type: Boolean },
  },
  emits: ['update:checked'],
})
</script>

<template>
  <div>
    <input
      :id="fieldId"
      class="mr-2"
      type="checkbox"
      :checked
      @input="
        (event) =>
          $emit('update:checked', (event.target as HTMLInputElement).checked)
      "
    />
    <label
      class="cursor-pointer select-none text-gray-800"
      :for="fieldId"
    >
      {{ label }}
    </label>
  </div>
</template>
