import { Component } from 'vue'

import { NavigationButton } from '../types'
import { PopupButtonProps } from './PopupButton/PopupButton'
import PopupButton from './PopupButton/PopupButton.vue'

export function usePopupButton<T = PopupButtonProps>(config: {
  component?: Component
  props?: Partial<T>
}): NavigationButton {
  return {
    component: PopupButton,
    props: {},
    ...config,
  }
}
