<script setup lang="ts">
import { ref } from 'vue'

import Popup from './Popup.vue'

// emits
type Emits = {
  submit: [message: string]
}

const emit = defineEmits<Emits>()

// data
const opened = ref(false)

// methods
function onFeedbackSubmit(message: string): void {
  emit('submit', message)
  opened.value = false
}

function showPopup(): void {
  opened.value = true
}
</script>

<template>
  <button
    id="send-feedback-button"
    class="bg-blue place-content-center rounded-md px-4 py-3 text-sm font-bold text-white"
    type="button"
    @click="showPopup"
  >
    Send feedback
  </button>

  <Popup
    v-if="opened"
    @close="opened = false"
    @confirm="onFeedbackSubmit"
  />
</template>
