import dayjs from 'dayjs'
import { cloneDeep } from 'lodash'

import {
  Event,
  Sport,
  UpdateEventDTO,
  UpdateEventObject,
} from '@collector/sportsapi-client-legacy'
import { getCurrentEvent } from '@mobile/globalState/event'
import { getCurrentSport } from '@mobile/globalState/sport'
import * as sportsAPI from '@mobile/globalState/sportsAPI'

import EventAction, { changeEventStatus } from './EventAction'

export type UpdateEventDTOWithoutTime = {
  event: Omit<UpdateEventObject, 'clock_time' | 'clock_status'>
  details?: UpdateEventDTO['details']
}

export default class UpdateEvent extends EventAction {
  public readonly type = 'UpdateEvent'

  constructor(public readonly updateEventDTO: UpdateEventDTOWithoutTime) {
    super()
  }

  public async execImpl(): Promise<void> {
    if (this.isDTOEmpty()) {
      return
    }

    this.checkForScoutMessengerConnectionOrThrow()

    const UTCUpdateEventDTO: UpdateEventDTO = cloneDeep(this.updateEventDTO)
    if (UTCUpdateEventDTO.event?.start_date) {
      UTCUpdateEventDTO.event.start_date = dayjs(
        UTCUpdateEventDTO.event.start_date,
        'YYYY-MM-DD HH:mm',
      )
        .add(new Date().getTimezoneOffset(), 'minutes')
        .format('YYYY-MM-DD HH:mm')
    }

    const event = getCurrentEvent()

    await this.confirmEventUpdateRequest(
      event.id,
      sportsAPI.state.client.updateEvent(event.id, UTCUpdateEventDTO),
    )
  }

  public applyProbableResult(probableEvent: Event): void {
    this.applyProbableResultToEventProperties(probableEvent, getCurrentSport())
    this.applyProbableResultToEventDetails(probableEvent)
  }

  private isDTOEmpty(): boolean {
    const { event, details } = this.updateEventDTO

    return !Object.keys({
      ...event,
      ...details,
    }).length
  }

  private applyProbableResultToEventProperties(
    probableEvent: Event,
    sport: Sport,
  ): void {
    if (this.updateEventDTO.event.event_status_id) {
      changeEventStatus(
        probableEvent,
        sport,
        this.updateEventDTO.event.event_status_id,
      )
    }

    if (this.updateEventDTO.event.coverage_type !== undefined) {
      probableEvent.coverage_type = this.updateEventDTO.event.coverage_type
    }

    if (this.updateEventDTO.event.scout_status !== undefined) {
      probableEvent.relation_status = this.updateEventDTO.event.scout_status
    }

    if (this.updateEventDTO.event.is_result_verified !== undefined) {
      probableEvent.verified_result =
        this.updateEventDTO.event.is_result_verified
    }

    if (this.updateEventDTO.event.start_date !== undefined) {
      probableEvent.start_date = this.updateEventDTO.event.start_date
    }

    if (this.updateEventDTO.event.neutral_venue !== undefined) {
      probableEvent.neutral_venue = this.updateEventDTO.event.neutral_venue
    }

    if (this.updateEventDTO.event.scoutsfeed !== undefined) {
      probableEvent.scoutsfeed = this.updateEventDTO.event.scoutsfeed
    }

    if (this.updateEventDTO.event.referee_id !== undefined) {
      probableEvent.referee_id = this.updateEventDTO.event.referee_id
    }
  }

  private applyProbableResultToEventDetails(probableEvent: Event): void {
    Object.entries(this.updateEventDTO.details || {})
      .flatMap((detailDTO) => {
        const detail = probableEvent.details.find(
          (detail) => detail.id === Number(detailDTO[0]),
        )

        return detail
          ? [
              {
                detailDTO: {
                  id: detailDTO[0],
                  value: detailDTO[1],
                },
                detail,
              },
            ]
          : []
      })
      .forEach((pairedDetails) => {
        pairedDetails.detail.value = pairedDetails.detailDTO.value
      })
  }
}
