import { Container } from 'inversify'

import {
  AmericanFootballIncidentId,
  PassAttribute,
  SackAttribute,
  TouchdownAttribute,
} from '@collector/sportsapi-client-legacy'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindAssignableIncidentConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.AssignableIncidentsConfiguration)
    .toDynamicValue(() => {
      return {
        [AmericanFootballIncidentId.Kickoff]: {
          participants: { main: 'Player' },
        },
        [AmericanFootballIncidentId.Touchback]: {
          participants: { main: 'Player' },
        },
        [AmericanFootballIncidentId.Pass]: {
          participants: {
            main: 'Passer',
            secondary: 'Receiver',
          },
          participantsConfiguration: {
            secondary: {
              loadOppositeTeam: false,
              optional: (probableIncident) =>
                probableIncident.attribute_ids.includes(
                  PassAttribute.PassIncomplete,
                ),
              fulfilledWhen: (probableIncident) => {
                return (
                  probableIncident.attribute_ids.includes(
                    PassAttribute.PassIncomplete,
                  ) || !!probableIncident.additional_info?.assistant_id
                )
              },
            },
          },
        },
        [AmericanFootballIncidentId.Rush]: { participants: { main: 'Player' } },
        [AmericanFootballIncidentId.Turnover]: {
          participants: { main: 'Player' },
        },
        [AmericanFootballIncidentId.Interception]: {
          participants: {
            main: 'Intercepted by',
            secondary: 'Passer',
          },
          participantsConfiguration: { secondary: { loadOppositeTeam: true } },
        },
        [AmericanFootballIncidentId.Touchdown]: {
          participants: {
            main: 'Scorer',

            secondary: 'Passer',
          },
          participantsConfiguration: {
            secondary: {
              loadOppositeTeam: false,
              optional: (probableIncident) => {
                return !probableIncident.attribute_ids.includes(
                  TouchdownAttribute.Passing,
                )
              },
            },
          },
        },
        [AmericanFootballIncidentId.TwoPointConversion]: {
          participants: { main: 'Player' },
        },
        [AmericanFootballIncidentId.FailedTwoPointConversion]: {
          participants: { main: 'Player' },
        },
        [AmericanFootballIncidentId.ExtraPoint]: {
          participants: { main: 'Player' },
        },
        [AmericanFootballIncidentId.ExtraPointFailed]: {
          participants: { main: 'Player' },
        },
        [AmericanFootballIncidentId.FieldGoal]: {
          participants: { main: 'Player' },
        },
        [AmericanFootballIncidentId.NoFieldGoal]: {
          participants: { main: 'Player' },
        },
        [AmericanFootballIncidentId.Safety]: {
          participants: { main: 'Player' },
        },
        [AmericanFootballIncidentId.Sack]: {
          participants: {
            main: 'Sacked by',
            secondary: '2nd (if needed)',
          },
          participantsConfiguration: {
            secondary: {
              loadOppositeTeam: false,
              optional: (probableIncident) => {
                if (probableIncident.attribute_ids.length === 0) {
                  return false
                }

                return !probableIncident.attribute_ids.includes(
                  SackAttribute.Assisted,
                )
              },
              fulfilledWhen: (probableIncident) => {
                if (probableIncident.attribute_ids.length === 0) {
                  return false
                } else if (
                  probableIncident.attribute_ids.includes(
                    SackAttribute.Assisted,
                  )
                ) {
                  return probableIncident.assistant_id !== null
                }

                return true
              },
            },
          },
        },
        [AmericanFootballIncidentId.Fumble]: {
          participants: {
            main: 'Dropped by',
            secondary: 'Forced by\n(if needed)',
          },
          participantsConfiguration: {
            secondary: {
              optional: true,
              loadOppositeTeam: true,
            },
          },
        },
        [AmericanFootballIncidentId.FumbleRecovered]: {
          participants: {
            main: 'Player',
            secondary: 'Lost by\n(if needed)',
          },
          participantsConfiguration: {
            secondary: {
              optional: true,
              loadOppositeTeam: true,
            },
          },
        },
        [AmericanFootballIncidentId.Penalty]: {
          participants: { main: 'Player' },
        },
        [AmericanFootballIncidentId.Punt]: { participants: { main: 'Player' } },
        [AmericanFootballIncidentId.DefensiveReturn]: {
          participants: { main: 'Player' },
        },
      }
    })
    .inSingletonScope()
}
