import {
  BasketballIncidentId,
  CommonIncidentId,
} from '@collector/sportsapi-client-legacy'

export const BasketballHideIncidentIds = [
  BasketballIncidentId.MatchAboutToStart,
  BasketballIncidentId.JumpBall,
  BasketballIncidentId.StartDelayed,
  BasketballIncidentId.FirstQuarterStarted,
  BasketballIncidentId.SecondQuarterStarted,
  BasketballIncidentId.ThirdQuarterStarted,
  BasketballIncidentId.FourthQuarterStarted,
  BasketballIncidentId.WaitingForOvertime,
  BasketballIncidentId.FirstOvertimeStarted,
  BasketballIncidentId.SecondOvertimeStarted,
  BasketballIncidentId.BreakAfterFirstOvertime,
  BasketballIncidentId.BreakAfterSecondOvertime,
  BasketballIncidentId.BreakAfterThirdOvertime,
  BasketballIncidentId.BreakAfterFourthOvertime,
  BasketballIncidentId.Interrupted,
  BasketballIncidentId.FinishedRegularTime,
  BasketballIncidentId.FinishedAfterOvertime,
  CommonIncidentId.TechnicalProblems,
  CommonIncidentId.Betstart,
  CommonIncidentId.Betstop,
  BasketballIncidentId.PlayUnderReview,
  BasketballIncidentId.TvTimeout,
  BasketballIncidentId.TvTimeoutOver,
  BasketballIncidentId.Timeout,
  BasketballIncidentId.TimeoutOver,
  BasketballIncidentId.OnePointShotMade,
  BasketballIncidentId.TwoPointShotMade,
  BasketballIncidentId.ThreePointShotMade,
  BasketballIncidentId.OnePointShotMissed,
  BasketballIncidentId.TwoPointShotMissed,
  BasketballIncidentId.ThreePointShotMissed,
  BasketballIncidentId.FreeThrowsAwarded,
  BasketballIncidentId.OffensiveRebound,
  BasketballIncidentId.DefensiveRebound,
  BasketballIncidentId.Foul,
  BasketballIncidentId.FoulBonus,
  BasketballIncidentId.Turnover,
  BasketballIncidentId.Steal,
  BasketballIncidentId.Block,
  BasketballIncidentId.ThrowIn,
  BasketballIncidentId.Injury,
  BasketballIncidentId.InPossession,
  BasketballIncidentId.RefereeInjury,
  BasketballIncidentId.BreakDuringGame,
]

export const BasketballParticipantIncidentIds = [
  BasketballIncidentId.OnePointShotMade,
  BasketballIncidentId.TwoPointShotMade,
  BasketballIncidentId.ThreePointShotMade,
  BasketballIncidentId.OnePointShotMissed,
  BasketballIncidentId.TwoPointShotMissed,
  BasketballIncidentId.ThreePointShotMissed,
  BasketballIncidentId.FreeThrowsAwarded,
  BasketballIncidentId.OffensiveRebound,
  BasketballIncidentId.DefensiveRebound,
  BasketballIncidentId.Foul,
  BasketballIncidentId.Turnover,
  BasketballIncidentId.Steal,
  BasketballIncidentId.Block,
]

export const BasketballSecondParticipantIncidentIds = [
  BasketballIncidentId.Steal,
]

export const BasketballDetailsIncidentIds = [
  BasketballIncidentId.TwoPointShotMade,
  BasketballIncidentId.ThreePointShotMade,
  BasketballIncidentId.TwoPointShotMissed,
  BasketballIncidentId.ThreePointShotMissed,
]
