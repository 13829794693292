import { hasIncidentMasterAttribute } from '@collector/shared-ui-domain'
import { Incident as SportsAPIClientIncident } from '@collector/sportsapi-client'
import {
  Confirmation,
  Incident,
  IncidentActionType,
} from '@collector/sportsapi-client-legacy'
import { EventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export type ProbableIncident = Pick<
  Incident,
  | 'id'
  | 'incident_id'
  | 'incident_name'
  | 'event_time'
  | 'event_status_id'
  | 'event_status_name'
  | 'participant_id'
  | 'participant_name'
  | 'participant_team_id'
  | 'assistant_id'
  | 'attribute_ids'
  | 'confirmation'
  | 'properties'
  | 'x_pos'
  | 'y_pos'
> &
  Partial<Incident>

export function hasIncidentEmergencyCategory(
  incident: ProbableIncident,
  sport: EventInjections['sport'],
): boolean {
  const incidentConfigruation = sport.value.incidents[incident.incident_id]

  return incidentConfigruation
    ? incidentConfigruation.category === 'emergency'
    : false
}

export function isIncidentEditable(
  incident: ProbableIncident,
  sport: EventInjections['sport'],
): boolean {
  if (
    // TODO: unify `Incident` type
    hasIncidentMasterAttribute(incident as unknown as SportsAPIClientIncident)
  ) {
    return false
  }

  if (hasIncidentEmergencyCategory(incident, sport)) {
    return false
  }

  if (incident.action === IncidentActionType.DELETE) {
    return false
  }

  return true
}

export function isIncidentDeletable(
  incident: ProbableIncident,
  sport: EventInjections['sport'],
): boolean {
  if (
    // TODO: unify `Incident` type
    hasIncidentMasterAttribute(incident as unknown as SportsAPIClientIncident)
  ) {
    return false
  }

  if (hasIncidentEmergencyCategory(incident, sport)) {
    return false
  }

  if (incident.action === IncidentActionType.DELETE) {
    return false
  }

  const confirmation = incident.confirmation ?? Confirmation.Confirmed

  return ![Confirmation.Cancelled, Confirmation.ToBeDone].includes(confirmation)
}

export function isActionId(id: string): boolean {
  return typeof id === 'string' && id.startsWith('collector:')
}

export function isIncidentId(id: unknown): boolean {
  return typeof id === 'string' && !id.startsWith('collector:')
}

export function isExistingIncident(
  incident: ProbableIncident,
): incident is Incident {
  return isIncidentId(incident.id)
}
