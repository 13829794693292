<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'

import { afterNextRender } from '@mobile/reusables/afterNextRender'

export default defineComponent({
  props: {
    reversed: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['animationCompleted'],
  setup(props, { emit }) {
    const rippleElement = ref<HTMLElement | null>(null)
    const transitionStarted = ref(false)

    onMounted(() => {
      if (!rippleElement.value) {
        emit('animationCompleted')

        return
      }

      rippleElement.value.addEventListener('transitionend', () => {
        emit('animationCompleted')
      })

      // To trigger transition we need to let it render first and then we can scale it
      afterNextRender(() => (transitionStarted.value = true))
    })

    return {
      rippleElement,
      transitionStarted,
    }
  },
})
</script>

<template>
  <div
    ref="rippleElement"
    class="border-blue -mx-2 -my-2 h-4 w-4 transform rounded-full border border-solid bg-transparent transition-all duration-700"
    :class="{
      'scale-0 opacity-100': !transitionStarted && !reversed,
      'scale-500 opacity-0': transitionStarted && !reversed,
      'scale-500 rev opacity-0': !transitionStarted && reversed,
      'rev scale-0 opacity-50': transitionStarted && reversed,
    }"
  ></div>
</template>
