<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import {
  BetStatus,
  HandballStatId,
  Participant,
} from '@collector/sportsapi-client-legacy'
import { byId, getOppositeTeam } from '@mobile/reusables/entityUtils'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  props: {
    participant: {
      type: Object as PropType<Participant>,
      required: true,
    },
  },
  setup(props) {
    const { probableEvent } = useEventInjections()
    const betStatus = computed(() => probableEvent.value.bet_status)
    const oppositeParticipant = computed(
      () =>
        getOppositeTeam(
          probableEvent.value.participants,
          props.participant,
        ) as Participant,
    )
    const participantPlayersInPlay = computed(() =>
      Number(
        byId(props.participant.stats, HandballStatId.PlayersInPlay)?.value,
      ),
    )
    const oppositeParticipantPlayersInPlay = computed(() =>
      Number(
        byId(oppositeParticipant.value.stats, HandballStatId.PlayersInPlay)
          ?.value,
      ),
    )

    const isPowerPlay = computed(
      () =>
        participantPlayersInPlay.value > oppositeParticipantPlayersInPlay.value,
    )

    const playersDiff = computed(() =>
      Math.abs(
        participantPlayersInPlay.value - oppositeParticipantPlayersInPlay.value,
      ),
    )

    const fontClasses = computed(() => {
      if (isPowerPlay.value) {
        return ['text-green']
      }

      return betStatus.value === BetStatus.Suspended
        ? ['text-white', 'text-opacity-40']
        : ['text-lightblack', 'text-opacity-40']
    })

    return {
      playersDiff,
      isPowerPlay,
      fontClasses,
    }
  },
})
</script>

<template>
  <div
    class="text-xs font-bold"
    :class="fontClasses"
  >
    PP{{ isPowerPlay ? `x${playersDiff}` : '' }}
  </div>
</template>
