<script lang="ts">
import { defineComponent } from 'vue'

import { VolleyballIncidentId } from '@collector/sportsapi-client-legacy'
import { useEventStatus } from '@mobile/views/Relation/Shared/EventStatus/useEventStatus'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

import Pitch from '../Pitch/Pitch.vue'

export default defineComponent({
  components: {
    IncidentButton,
    Pitch,
  },
  setup() {
    const {
      probableEvent,
      probableFirstParticipant,
      probableSecondParticipant,
    } = useEventInjections()
    const { isMatchStarted } = useEventStatus(probableEvent)

    return {
      VolleyballIncidentId,
      isMatchStarted,
      probableFirstParticipant,
      probableSecondParticipant,
    }
  },
})
</script>

<template>
  <div
    class="grid grid-cols-6 gap-2"
    style="grid-template-rows: auto 1fr auto"
  >
    <div
      v-if="isMatchStarted"
      class="col-span-full flex items-end justify-between"
    >
      <IncidentButton
        class="col-span-2 h-full"
        :type="'horizontal'"
        :size="'lg'"
        :color="'white'"
        :incidentId="VolleyballIncidentId.SetBall"
      />
      <IncidentButton
        class="col-span-2 h-full"
        :type="'horizontal'"
        :size="'lg'"
        :color="'white'"
        :incidentId="VolleyballIncidentId.SetWon"
      />
      <IncidentButton
        class="col-span-2 h-full"
        :type="'horizontal'"
        :size="'lg'"
        :color="'white'"
        :incidentId="VolleyballIncidentId.MatchBall"
      />
    </div>
    <div
      v-if="isMatchStarted"
      class="col-start-1 col-end-2 row-start-2 row-end-5 grid gap-3"
    >
      <IncidentButton
        class="col-span-2 h-full"
        :type="'vertical'"
        :size="'lg'"
        :participantId="probableFirstParticipant.id"
        :incidentId="VolleyballIncidentId.Serve"
      />
    </div>
    <Pitch />
    <div
      v-if="isMatchStarted"
      class="col-start-6 col-end-7 row-start-2 row-end-5 grid gap-3"
    >
      <IncidentButton
        class="col-span-2 h-full"
        :type="'vertical'"
        :size="'lg'"
        :participantId="probableSecondParticipant.id"
        :incidentId="VolleyballIncidentId.Serve"
      />
    </div>
    <div
      v-if="isMatchStarted"
      class="col-span-full flex items-end justify-center lg:mt-2"
    >
      <IncidentButton
        class="col-span-2 mx-2 h-full"
        :type="'horizontal'"
        :size="'lg'"
        :color="'white'"
        :incidentId="VolleyballIncidentId.Challenge"
      />
      <IncidentButton
        class="col-span-2 mx-2 h-full"
        :type="'horizontal'"
        :size="'lg'"
        :color="'white'"
        :incidentId="VolleyballIncidentId.Timeout"
      />
    </div>
  </div>
</template>
