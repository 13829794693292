import { computed, shallowReactive, watch } from 'vue'

import {
  IncidentActionType,
  Sport,
  SportId,
} from '@collector/sportsapi-client-legacy'
import { AppDatabase } from '@mobile/database/AppDatabase'
import { CollectorSport } from '@mobile/reusables/classes/CollectorSport'

type SportList = {
  [key in SportId]?: CollectorSport
}

const state = shallowReactive({
  isOnline: navigator.onLine,
  ready: false,
  wasRecentlyUpdated: localStorage.getItem('appWasRecentlyUpdated') === 'true',
  database: null as unknown as AppDatabase,
  isDatabaseConnected: false,
  sports: shallowReactive({} as SportList),
  bodyOverflowIndex: 0,
  lastIncidentAction: null as unknown as IncidentActionType,
})

window.addEventListener('online', () => {
  state.isOnline = true
})

window.addEventListener('offline', () => {
  state.isOnline = false
})

const isOnline = computed(() => state.isOnline)

watch(
  () => state.bodyOverflowIndex,
  (bodyOverflowIndex) => {
    document.body.style.overflow = bodyOverflowIndex > 0 ? 'hidden' : ''
  },
)

function setAppAsReady(): void {
  state.ready = true
}

function setDatabaseAsConnected(): void {
  state.isDatabaseConnected = true
}

function setDatabaseAsDisconnected(): void {
  state.isDatabaseConnected = false
}

function setAppAsUnready(): void {
  state.ready = false
}

function setDatabase(database: AppDatabase): void {
  state.database = database
}

function addOverlay(): void {
  state.bodyOverflowIndex++
}

function removeOverlay(): void {
  state.bodyOverflowIndex--
}

function getSport(sportId: SportId): CollectorSport | undefined {
  return state.sports[sportId]
}

function putSport(sport: Sport): void {
  state.sports[sport.id] = new CollectorSport(sport)
}

export {
  state,
  isOnline,
  setAppAsReady,
  setAppAsUnready,
  setDatabaseAsConnected,
  setDatabaseAsDisconnected,
  addOverlay,
  removeOverlay,
  setDatabase,
  getSport,
  putSport,
  SportList,
}
