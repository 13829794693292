<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { SportId } from '@collector/sportsapi-client-legacy'

export default defineComponent({
  props: {
    size: {
      type: String as PropType<'small' | 'medium' | 'large'>,
      default: 'small',
    },
    sportId: {
      type: Number as PropType<SportId>,
      required: true,
    },
  },
})
</script>

<template>
  <i
    class="sport-icon"
    :class="`sport-icon-${sportId}`"
  ></i>
</template>

<style scoped lang="scss">
.sport-icon {
  font-family: softnetSPORT-Icons;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sport-icon-77:before {
  content: '\e900';
}

.sport-icon-78:before {
  content: '\e901';
}

.sport-icon-76:before {
  content: '\e902';
}

.sport-icon-20:before {
  content: '\31';
}

.sport-icon-8:before {
  content: '\32';
}

.sport-icon-45:before {
  content: '\33';
}

.sport-icon-29:before {
  content: '\34';
}

.sport-icon-9:before {
  content: '\35';
}

.sport-icon-7:before {
  content: '\36';
}

.sport-icon-1:before {
  content: '\37';
}

.sport-icon-18:before {
  content: '\38';
}

.sport-icon-12:before {
  content: '\39';
}

.sport-icon-25:before {
  content: '\30';
}

.sport-icon-43:before {
  content: '\61';
}

.sport-icon-27:before {
  content: '\62';
}

.sport-icon-24:before {
  content: '\63';
}

.sport-icon-28:before {
  content: '\64';
}

.sport-icon-13:before {
  content: '\65';
}

.sport-icon-17:before {
  content: '\66';
}

.sport-icon-39:before {
  content: '\67';
}

.sport-icon-36:before {
  content: '\68';
}

.sport-icon-10:before {
  content: '\69';
}

.sport-icon-16:before {
  content: '\6a';
}

.sport-icon-41:before {
  content: '\6b';
}

.sport-icon-42:before {
  content: '\6c';
}

.sport-icon-32:before {
  content: '\6d';
}

.sport-icon-6:before {
  content: '\6e';
}

.sport-icon-30:before {
  content: '\6f';
}

.sport-icon-3:before {
  content: '\70';
}

.sport-icon-23:before {
  content: '\71';
}

.sport-icon-44:before {
  content: '\72';
}

.sport-icon-38:before {
  content: '\73';
}

.sport-icon-37:before {
  content: '\74';
}

.sport-icon-22:before {
  content: '\75';
}

.sport-icon-35:before {
  content: '\76';
}

.sport-icon-porsche_supercup:before {
  content: '\77';
}

.sport-icon-15:before {
  content: '\78';
}

.sport-icon-11:before {
  content: '\79';
}

.sport-icon-14:before {
  content: '\7a';
}

.sport-icon-31:before {
  content: '\41';
}

.sport-icon-5:before {
  content: '\42';
}

.sport-icon-21:before {
  content: '\43';
}

.sport-icon-26:before {
  content: '\44';
}

.sport-icon-4:before {
  content: '\45';
}

.sport-icon-46:before {
  content: '\e903';
}

.sport-icon-2:before {
  content: '\46';
}

.sport-icon-19:before {
  content: '\e904';
}

.sport-icon-33:before {
  content: '\47';
}
</style>
