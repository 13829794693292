import { Component, reactive, shallowRef } from 'vue'

const state = reactive({
  isVisible: false,
  showHeader: true,
  props: {} as Record<string, unknown> | object,
  component: null as Component | null,
  breadcrumbs: [] as string[],
})

type ShowParams = {
  component: Component
  showHeader: boolean
  props?: object
}
function show({ component, showHeader, props }: ShowParams): void {
  state.isVisible = true
  state.component = shallowRef(component)
  state.showHeader = showHeader
  state.props = props ?? {}
}

function hide(): void {
  state.showHeader = true
  state.isVisible = false
  state.component = null
  state.props = {}
}

function pushBreadcrumb(name: string): void {
  state.breadcrumbs.push(name)
}

function popBreadcrumb(): void {
  state.breadcrumbs.pop()
}

export { state, show, ShowParams, hide, pushBreadcrumb, popBreadcrumb }
