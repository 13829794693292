import { interfaces } from 'inversify'
import { Ref } from 'vue'

import { ActiveParticipant } from './ActiveParticipant'

type SI<T> = interfaces.ServiceIdentifier<T>

interface Basketball3x3RelationIoCBindingsInterface {
  ActiveParticipant: SI<Ref<ActiveParticipant>>
}

export const Basketball3x3RelationIoCBindings: Basketball3x3RelationIoCBindingsInterface =
  { ActiveParticipant: Symbol('ActiveParticipant') }
