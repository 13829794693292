<script lang="ts">
import { defineComponent } from 'vue'

import Alert from '@mobile/components/Alert/Alert.vue'
import Button from '@mobile/components/Button/Button.vue'

enum LeaveRelationConfirmationEvents {
  Confirm = 'confirm',
  Close = 'close',
}

export default defineComponent({
  components: {
    Alert,
    Button,
  },
  emits: [
    LeaveRelationConfirmationEvents.Confirm,
    LeaveRelationConfirmationEvents.Close,
  ],
  setup() {
    return { LeaveRelationConfirmationEvents }
  },
})
</script>

<template>
  <Alert>
    <strong>Are you sure you want to leave this event?</strong>
    <template #controls>
      <Button
        class="mx-2"
        color="blue"
        :invertedColor="true"
        size="sm"
        @click="$emit(LeaveRelationConfirmationEvents.Close)"
      >
        No! Take me back
      </Button>
      <Button
        class="mx-2"
        color="blue"
        size="sm"
        @click="$emit(LeaveRelationConfirmationEvents.Confirm)"
      >
        Yes! Take me out
      </Button>
    </template>
  </Alert>
</template>
