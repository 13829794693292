import { Container } from 'inversify'

import {
  BeachVolleyballEventStatusId,
  BeachVolleyballIncidentId,
  beachVolleyballStatusResultsMap,
} from '@collector/sportsapi-client-legacy'
import {
  addToEventResult,
  changeEventStatus,
} from '@mobile/ActionQueue/Actions/Event/EventAction'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindIncidentsConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.IncidentsConfiguration)
    .toDynamicValue(() => {
      return {
        [BeachVolleyballIncidentId.Finished]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BeachVolleyballEventStatusId.Finished,
            )
          },
        },
        [BeachVolleyballIncidentId.Interrupted]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BeachVolleyballEventStatusId.Interrupted,
            )
          },
        },
        [BeachVolleyballIncidentId.Cancelled]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BeachVolleyballEventStatusId.Cancelled,
            )
          },
        },
        [BeachVolleyballIncidentId.Postponed]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BeachVolleyballEventStatusId.Postponed,
            )
          },
        },
        [BeachVolleyballIncidentId.Abandoned]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BeachVolleyballEventStatusId.Abandoned,
            )
          },
        },
        [BeachVolleyballIncidentId.FirstSetStared]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BeachVolleyballEventStatusId.FirstSet,
            )
          },
        },
        [BeachVolleyballIncidentId.SecondSetStarted]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BeachVolleyballEventStatusId.SecondSet,
            )
          },
        },
        [BeachVolleyballIncidentId.ThirdSetStarted]: {
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BeachVolleyballEventStatusId.ThirdSet,
            )
          },
        },
        [BeachVolleyballIncidentId.Point]: {
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            const resultsToUpdate =
              beachVolleyballStatusResultsMap[probableEvent.status_id] ?? []
            resultsToUpdate.forEach((result) => {
              addToEventResult(
                probableEvent,
                dto.participant_team_id,
                result,
                1,
              )
            })
          },
        },
        [BeachVolleyballIncidentId.AttackPoint]: {
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            const resultsToUpdate =
              beachVolleyballStatusResultsMap[probableEvent.status_id] ?? []
            resultsToUpdate.forEach((result) => {
              addToEventResult(
                probableEvent,
                dto.participant_team_id,
                result,
                1,
              )
            })
          },
        },

        [BeachVolleyballIncidentId.BlockPoint]: {
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            const resultsToUpdate =
              beachVolleyballStatusResultsMap[probableEvent.status_id] ?? []
            resultsToUpdate.forEach((result) => {
              addToEventResult(
                probableEvent,
                dto.participant_team_id,
                result,
                1,
              )
            })
          },
        },
        fallbackIncidentConfiguration: {},
      }
    })
    .inSingletonScope()
}
