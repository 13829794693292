import { CommonResultId } from '../common/CommonResultId'
import { EventStatusId } from '../EventStatusId'
import { ResultId } from '../ResultId'
import { SoccerEventStatusId } from './EventStatusId'
import { SoccerResultId } from './SoccerResultId'

export const soccerStatusResultsMap: {
  [key in EventStatusId]?: ResultId[]
} = {
  [SoccerEventStatusId.FirstHalf]: [
    CommonResultId.Result,
    CommonResultId.RegularTime,
    SoccerResultId.FirstHalf,
  ],
  [SoccerEventStatusId.SecondHalf]: [
    CommonResultId.Result,
    CommonResultId.RegularTime,
    SoccerResultId.SecondHalf,
  ],
  [SoccerEventStatusId.ExtratimeFirstHalf]: [
    CommonResultId.Result,
    SoccerResultId.ExtratimeFirstHalf,
    CommonResultId.Result,
  ],
  [SoccerEventStatusId.ExtratimeSecondHalf]: [
    CommonResultId.Result,
    SoccerResultId.ExtratimeSecondHalf,
    CommonResultId.Result,
  ],
  [SoccerEventStatusId.PenaltyShootout]: [CommonResultId.Penalty],
}
