import { Container } from 'inversify'
import { computed } from 'vue'

import {
  BasketballEventDetailId,
  BasketballEventStatusId,
  BasketballIncidentId,
  CommonIncidentId,
  Event,
  IncidentId,
} from '@collector/sportsapi-client-legacy'
import {
  getDetailValue,
  getNumericDetailValue,
} from '@mobile/reusables/eventUtils'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { EventStatusesList } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/EventStatusesList'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindEventStatusesConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.EventStatusesConfiguration)
    .toDynamicValue(async (ctx) => {
      const [probableEvent] = await Promise.all([
        ctx.container.getAsync(RelationIoCBindings.ProbableEvent),
      ])
      const eventStatuses = await ctx.container.getAsync(
        RelationSportCommonIoCBindings.EventStatusesList,
      )

      return prepareEventStatusesConfiguration(
        probableEvent.value,
        eventStatuses,
      )
    })
    .inSingletonScope()
}

/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
function prepareEventStatusesConfiguration(
  event: Event,
  eventStatuses: EventStatusesList,
) {
  const periodLength = computed(
    () =>
      getNumericDetailValue(event, BasketballEventDetailId.PeriodLength, 10) *
      60,
  )
  const periodLengthInMinutes = computed(() =>
    getNumericDetailValue(event, BasketballEventDetailId.PeriodLength, 10),
  )
  const extraPeriodLength = computed(
    () =>
      getNumericDetailValue(
        event,
        BasketballEventDetailId.ExtraPeriodLength,
        5,
      ) * 60,
  )
  const extraPeriodLengthInMinutes = computed(() =>
    getNumericDetailValue(event, BasketballEventDetailId.ExtraPeriodLength, 5),
  )
  const numberOfExtraPeriods = computed(() =>
    getNumericDetailValue(
      event,
      BasketballEventDetailId.NumberOfExtraPeriods,
      5,
    ),
  )
  const clockDirection = computed(() =>
    getDetailValue(event, BasketballEventDetailId.TimerDirection),
  )
  const startRegularPeriodTime =
    clockDirection.value === 'down' ? periodLength.value : 0
  const startExtraPeriodTime =
    clockDirection.value === 'down' ? extraPeriodLength.value : 0

  const withCommonIncidentsAllowedDuringTheGame = (
    incidentsId: IncidentId[] = [],
  ): IncidentId[] => {
    return [
      ...incidentsId,
      BasketballIncidentId.TwoPointShotMade,
      BasketballIncidentId.ThreePointShotMade,
      BasketballIncidentId.OnePointShotMade,
      BasketballIncidentId.TwoPointShotMissed,
      BasketballIncidentId.ThreePointShotMissed,
      BasketballIncidentId.OnePointShotMissed,
      BasketballIncidentId.DefensiveRebound,
      BasketballIncidentId.OffensiveRebound,
      BasketballIncidentId.Block,
      BasketballIncidentId.Foul,
      BasketballIncidentId.ThrowIn,
      BasketballIncidentId.FreeThrowsAwarded,
      BasketballIncidentId.Steal,
      BasketballIncidentId.Turnover,
      BasketballIncidentId.InPossession,
      BasketballIncidentId.FoulBonus,
      CommonIncidentId.MatchResumed,
      CommonIncidentId.Betstart,
      CommonIncidentId.Betstop,
    ]
  }

  return {
    [BasketballEventStatusId.NotStarted]: {
      time: {
        minute: 0,
        second: 0,
      },
      clockHidden: true,
      allowedIncidents: [
        BasketballIncidentId.FirstQuarterStarted,
        BasketballIncidentId.StartDelayed,
        BasketballIncidentId.FirstHalfStarted,
        BasketballIncidentId.FinishedAwardedWin,
        BasketballIncidentId.MatchAboutToStart,
        BasketballIncidentId.Postponed,
        BasketballIncidentId.Abandoned,
        BasketballIncidentId.Cancelled,
        CommonIncidentId.Betstart,
        CommonIncidentId.Betstop,
      ],
    },
    [BasketballEventStatusId.StartDelayed]: {
      time: {
        minute: 0,
        second: 0,
      },
      clockHidden: true,
      allowedIncidents: [
        BasketballIncidentId.NotStarted,
        BasketballIncidentId.FirstQuarterStarted,
        BasketballIncidentId.FirstHalfStarted,
        BasketballIncidentId.FinishedAwardedWin,
        BasketballIncidentId.MatchAboutToStart,
        BasketballIncidentId.Postponed,
        BasketballIncidentId.Abandoned,
        BasketballIncidentId.Cancelled,
        CommonIncidentId.Betstart,
        CommonIncidentId.Betstop,
      ],
    },
    [BasketballEventStatusId.ToFinish]: {
      time: {
        minute: 0,
        second: 0,
      },
      clockHidden: true,
      allowedIncidents: eventStatuses,
    },
    [BasketballEventStatusId.FirstQuarter]: {
      time: {
        minute: 0,
        second: 0,
      },
      timeBoundaryInSeconds: periodLength.value,
      clockDirectionDown: clockDirection.value === 'down',
      startTimeFromInSeconds: startRegularPeriodTime,
      allowedIncidents: withCommonIncidentsAllowedDuringTheGame([
        BasketballIncidentId.BreakAfterFirstQuarter,
        BasketballIncidentId.JumpBall,
        BasketballIncidentId.Interrupted,
      ]),
    },
    [BasketballEventStatusId.BreakAfterFirstQuarter]: {
      time: {
        minute: periodLengthInMinutes.value,
        second: 0,
      },
      timeBoundaryInSeconds: periodLength.value * 2,
      clockDirectionDown: clockDirection.value === 'down',
      startTimeFromInSeconds: startRegularPeriodTime,
      clockHidden: true,
      allowedIncidents: [
        BasketballIncidentId.SecondQuarterStarted,
        BasketballIncidentId.Interrupted,
        CommonIncidentId.Betstart,
        CommonIncidentId.Betstop,
      ],
    },
    [BasketballEventStatusId.SecondQuarter]: {
      time: {
        minute: periodLengthInMinutes.value,
        second: 0,
      },
      timeBoundaryInSeconds: periodLength.value * 2,
      clockDirectionDown: clockDirection.value === 'down',
      startTimeFromInSeconds: startRegularPeriodTime * 2,
      allowedIncidents: withCommonIncidentsAllowedDuringTheGame([
        BasketballIncidentId.BreakAfterSecondQuarter,
        BasketballIncidentId.Interrupted,
      ]),
    },
    [BasketballEventStatusId.BreakAfterSecondQuarter]: {
      time: {
        minute: 2 * periodLengthInMinutes.value,
        second: 0,
      },
      clockDirectionDown: clockDirection.value === 'down',
      startTimeFromInSeconds: startRegularPeriodTime,
      clockHidden: true,
      allowedIncidents: [
        BasketballIncidentId.ThirdQuarterStarted,
        BasketballIncidentId.Interrupted,
        CommonIncidentId.Betstart,
        CommonIncidentId.Betstop,
      ],
    },
    [BasketballEventStatusId.ThirdQuarter]: {
      time: {
        minute: 2 * periodLengthInMinutes.value,
        second: 0,
      },
      timeBoundaryInSeconds: periodLength.value * 3,
      clockDirectionDown: clockDirection.value === 'down',
      startTimeFromInSeconds: startRegularPeriodTime * 3,
      allowedIncidents: withCommonIncidentsAllowedDuringTheGame([
        BasketballIncidentId.BreakAfterThirdQuarter,
        BasketballIncidentId.Interrupted,
      ]),
    },
    [BasketballEventStatusId.BreakAfterThirdQuarter]: {
      time: {
        minute: 3 * periodLengthInMinutes.value,
        second: 0,
      },
      clockDirectionDown: clockDirection.value === 'down',
      startTimeFromInSeconds: startRegularPeriodTime,
      clockHidden: true,
      allowedIncidents: [
        BasketballIncidentId.FourthQuarterStarted,
        BasketballIncidentId.Interrupted,
        CommonIncidentId.Betstart,
        CommonIncidentId.Betstop,
      ],
    },
    [BasketballEventStatusId.FourthQuarter]: {
      time: {
        minute: 3 * periodLengthInMinutes.value,
        second: 0,
      },
      timeBoundaryInSeconds: periodLength.value * 4,
      clockDirectionDown: clockDirection.value === 'down',
      startTimeFromInSeconds: startRegularPeriodTime * 4,
      allowedIncidents: withCommonIncidentsAllowedDuringTheGame([
        BasketballIncidentId.FinishedRegularTime,
        BasketballIncidentId.WaitingForOvertime,
        BasketballIncidentId.Interrupted,
      ]),
    },
    [BasketballEventStatusId.BreakAfterFourthQuarter]: {
      time: {
        minute: 4 * periodLengthInMinutes.value,
        second: 0,
      },
      timeBoundaryInSeconds:
        clockDirection.value === 'down' ? 0 : periodLength.value,
      clockHidden: true,
      allowedIncidents: [
        BasketballIncidentId.WaitingForOvertime,
        BasketballIncidentId.FinishedAwardedWin,
        BasketballIncidentId.FinishedRegularTime,
        CommonIncidentId.Betstart,
        CommonIncidentId.Betstop,
      ],
    },
    [BasketballEventStatusId.WaitingForOvertime]: {
      time: {
        minute: 4 * periodLengthInMinutes.value,
        second: 0,
      },
      timeBoundaryInSeconds: periodLength.value * 4 + extraPeriodLength.value,
      clockDirectionDown: clockDirection.value === 'down',
      startTimeFromInSeconds: startRegularPeriodTime * 4 + startExtraPeriodTime,
      clockHidden: true,
      allowedIncidents: [
        BasketballIncidentId.FirstOvertimeStarted,
        BasketballIncidentId.Interrupted,
        CommonIncidentId.Betstart,
        CommonIncidentId.Betstop,
      ],
    },
    [BasketballEventStatusId.FirstOvertime]: {
      time: {
        minute: 4 * periodLengthInMinutes.value,
        second: 0,
      },
      timeBoundaryInSeconds: periodLength.value * 4 + extraPeriodLength.value,
      clockDirectionDown: clockDirection.value === 'down',
      startTimeFromInSeconds: startRegularPeriodTime * 4 + startExtraPeriodTime,
      allowedIncidents: withCommonIncidentsAllowedDuringTheGame([
        BasketballIncidentId.BreakAfterFirstOvertime,
        BasketballIncidentId.FinishedAfterOvertime,
        BasketballIncidentId.Interrupted,
      ]),
    },
    [BasketballEventStatusId.BreakAfterFirstOvertime]: {
      time: {
        minute:
          4 * periodLengthInMinutes.value + extraPeriodLengthInMinutes.value,
        second: 0,
      },
      timeBoundaryInSeconds:
        periodLength.value * 4 + extraPeriodLength.value * 2,
      clockDirectionDown: clockDirection.value === 'down',
      startTimeFromInSeconds: startRegularPeriodTime + startExtraPeriodTime,
      clockHidden: true,
      allowedIncidents: [
        BasketballIncidentId.SecondOvertimeStarted,
        BasketballIncidentId.Interrupted,
        CommonIncidentId.Betstart,
        CommonIncidentId.Betstop,
      ],
    },
    [BasketballEventStatusId.SecondOvertime]: {
      time: {
        minute:
          4 * periodLengthInMinutes.value + extraPeriodLengthInMinutes.value,
        second: 0,
      },
      timeBoundaryInSeconds:
        periodLength.value * 4 + extraPeriodLength.value * 2,
      clockDirectionDown: clockDirection.value === 'down',
      startTimeFromInSeconds:
        startRegularPeriodTime * 4 + startExtraPeriodTime * 2,
      allowedIncidents: withCommonIncidentsAllowedDuringTheGame([
        BasketballIncidentId.BreakAfterSecondOvertime,
        BasketballIncidentId.FinishedAfterOvertime,
        BasketballIncidentId.Interrupted,
      ]),
    },
    [BasketballEventStatusId.BreakAfterSecondOvertime]: {
      time: {
        minute:
          4 * periodLengthInMinutes.value +
          2 * extraPeriodLengthInMinutes.value,
        second: 0,
      },
      timeBoundaryInSeconds:
        periodLength.value * 4 + extraPeriodLength.value * 3,
      clockDirectionDown: clockDirection.value === 'down',
      startTimeFromInSeconds: startRegularPeriodTime + startExtraPeriodTime,
      clockHidden: true,
      allowedIncidents: [
        BasketballIncidentId.ThirdOvertimeStarted,
        BasketballIncidentId.Interrupted,
        CommonIncidentId.Betstart,
        CommonIncidentId.Betstop,
      ],
    },
    [BasketballEventStatusId.ThirdOvertime]: {
      time: {
        minute:
          4 * periodLengthInMinutes.value +
          2 * extraPeriodLengthInMinutes.value,
        second: 0,
      },
      timeBoundaryInSeconds:
        periodLength.value * 4 + extraPeriodLength.value * 3,
      clockDirectionDown: clockDirection.value === 'down',
      startTimeFromInSeconds:
        startRegularPeriodTime * 4 + startExtraPeriodTime * 3,
      allowedIncidents: withCommonIncidentsAllowedDuringTheGame([
        BasketballIncidentId.BreakAfterThirdOvertime,
        BasketballIncidentId.FinishedAfterOvertime,
        BasketballIncidentId.Interrupted,
      ]),
    },
    [BasketballEventStatusId.BreakAfterThirdOvertime]: {
      time: {
        minute:
          4 * periodLengthInMinutes.value +
          3 * extraPeriodLengthInMinutes.value,
        second: 0,
      },
      timeBoundaryInSeconds:
        periodLength.value * 4 + extraPeriodLength.value * 4,
      clockDirectionDown: clockDirection.value === 'down',
      startTimeFromInSeconds: startRegularPeriodTime + startExtraPeriodTime,
      clockHidden: true,
      allowedIncidents: [
        BasketballIncidentId.FourthOvertimeStarted,
        BasketballIncidentId.Interrupted,
        CommonIncidentId.Betstart,
        CommonIncidentId.Betstop,
      ],
    },
    [BasketballEventStatusId.FourthOvertime]: {
      time: {
        minute:
          4 * periodLengthInMinutes.value +
          3 * extraPeriodLengthInMinutes.value,
        second: 0,
      },
      timeBoundaryInSeconds:
        periodLength.value * 4 + extraPeriodLength.value * 4,
      clockDirectionDown: clockDirection.value === 'down',
      startTimeFromInSeconds:
        startRegularPeriodTime * 4 + startExtraPeriodTime * 4,
      allowedIncidents: withCommonIncidentsAllowedDuringTheGame([
        BasketballIncidentId.BreakAfterFourthOvertime,
        BasketballIncidentId.FinishedAfterOvertime,
        BasketballIncidentId.Interrupted,
      ]),
    },
    [BasketballEventStatusId.BreakAfterFourthOvertime]: {
      time: {
        minute:
          4 * periodLengthInMinutes.value +
          4 * extraPeriodLengthInMinutes.value,
        second: 0,
      },
      timeBoundaryInSeconds:
        periodLength.value * 4 + extraPeriodLength.value * 5,
      clockDirectionDown: clockDirection.value === 'down',
      startTimeFromInSeconds: startRegularPeriodTime + startExtraPeriodTime,
      clockHidden: true,
      allowedIncidents: [
        BasketballIncidentId.FifthOvertimeStarted,
        BasketballIncidentId.Interrupted,
        CommonIncidentId.Betstart,
        CommonIncidentId.Betstop,
      ],
    },
    [BasketballEventStatusId.FifthOvertime]: {
      time: {
        minute:
          4 * periodLengthInMinutes.value +
          4 * extraPeriodLengthInMinutes.value,
        second: 0,
      },
      timeBoundaryInSeconds:
        periodLength.value * 4 + extraPeriodLength.value * 5,
      clockDirectionDown: clockDirection.value === 'down',
      startTimeFromInSeconds:
        startRegularPeriodTime * 4 + startExtraPeriodTime * 5,
      allowedIncidents: withCommonIncidentsAllowedDuringTheGame([
        BasketballIncidentId.FinishedAfterOvertime,
        BasketballIncidentId.Interrupted,
      ]),
    },
    [BasketballEventStatusId.FirstHalf]: {
      time: {
        minute: 0,
        second: 0,
      },
      allowedIncidents: withCommonIncidentsAllowedDuringTheGame([
        BasketballIncidentId.BreakAfterFirstHalf,
        BasketballIncidentId.Interrupted,
      ]),
    },
    [BasketballEventStatusId.BreakAfterFirstHalf]: {
      time: {
        minute: 0,
        second: 0,
      },
      clockHidden: true,
      allowedIncidents: [
        BasketballIncidentId.SecondHalfStarted,
        BasketballIncidentId.Interrupted,
        CommonIncidentId.Betstart,
        CommonIncidentId.Betstop,
      ],
    },
    [BasketballEventStatusId.SecondHalf]: {
      time: {
        minute: 0,
        second: 0,
      },
      allowedIncidents: withCommonIncidentsAllowedDuringTheGame([
        BasketballIncidentId.FinishedRegularTime,
        BasketballIncidentId.WaitingForOvertime,
        BasketballIncidentId.Interrupted,
      ]),
    },
    [BasketballEventStatusId.FinishedAfterOvertime]: {
      time: {
        minute:
          4 * periodLengthInMinutes.value +
          numberOfExtraPeriods.value * extraPeriodLengthInMinutes.value,
        second: 0,
      },
      clockHidden: true,
      timeBoundaryInSeconds:
        4 * periodLengthInMinutes.value +
        numberOfExtraPeriods.value * extraPeriodLengthInMinutes.value,
      clockDirectionDown: clockDirection.value === 'down',
      startTimeFromInSeconds:
        4 * periodLengthInMinutes.value +
        numberOfExtraPeriods.value * extraPeriodLengthInMinutes.value,
    },

    [BasketballEventStatusId.FinishedAwardedWin]: {
      time: {
        minute: 0,
        second: 0,
      },
      clockHidden: true,
    },
    [BasketballEventStatusId.FinishedRegularTime]: {
      time: {
        minute: 4 * periodLengthInMinutes.value,
        second: 0,
      },
      timeBoundaryInSeconds: periodLength.value * 4,
      clockDirectionDown: clockDirection.value === 'down',
      startTimeFromInSeconds: startRegularPeriodTime * 4,
      clockHidden: true,
    },
    [BasketballEventStatusId.Interrupted]: {
      time: {
        minute: 0,
        second: 0,
      },
      allowedIncidents: [
        BasketballIncidentId.FinishedAwardedWin,
        BasketballIncidentId.Abandoned,
        BasketballIncidentId.Cancelled,
        BasketballIncidentId.ToFinish,
        CommonIncidentId.Betstart,
        CommonIncidentId.Betstop,
      ],
    },
    [BasketballEventStatusId.Postponed]: {
      time: {
        minute: 0,
        second: 0,
      },
      allowedIncidents: [
        BasketballIncidentId.FinishedAwardedWin,
        BasketballIncidentId.Abandoned,
        BasketballIncidentId.Cancelled,
        BasketballIncidentId.NotStarted,
        CommonIncidentId.Betstart,
        CommonIncidentId.Betstop,
      ],
    },
    [BasketballEventStatusId.Abandoned]: {
      time: {
        minute: 0,
        second: 0,
      },
      allowedIncidents: [
        BasketballIncidentId.NotStarted,
        CommonIncidentId.Betstart,
        CommonIncidentId.Betstop,
      ],
    },
    [BasketballEventStatusId.Cancelled]: {
      time: {
        minute: 0,
        second: 0,
      },
      allowedIncidents: [
        BasketballIncidentId.NotStarted,
        CommonIncidentId.Betstart,
        CommonIncidentId.Betstop,
      ],
    },
  }
}
