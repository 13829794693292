import Dexie from 'dexie'

import { Repository } from '@mobile/database/Repository'

export type QueueActions = {
  eventId: number
  serializedActions: Record<string, unknown>[]
}

export class QueueActionsRepository extends Repository<QueueActions> {
  static readonly tableName = 'queueActions'
  static readonly indexes = 'eventId'

  constructor(database: Dexie) {
    super(database.table<QueueActions>(QueueActionsRepository.tableName))
  }

  public async getByEventId(
    eventId: number,
  ): Promise<QueueActions | undefined> {
    return this.table.get(eventId)
  }

  public async removeByEventId(eventId: number): Promise<void> {
    await this.table.delete(eventId)
  }
}
