<script lang="ts">
import { cloneDeep } from 'lodash'
import { computed, defineComponent, PropType } from 'vue'

import { nullCoalesce, opChain } from '@collector/shared-utils'
import {
  CommonIncidentId,
  Event,
  SoccerEventDetailId,
  YesNoEnum,
} from '@collector/sportsapi-client-legacy'
import { UpdateEvent } from '@mobile/ActionQueue/Actions/Actions'
import Button from '@mobile/components/Button/Button.vue'
import ParticipantComponent from '@mobile/components/Participant/Participant.vue'
import Select from '@mobile/components/Select/Select.vue'
import { byId } from '@mobile/reusables/entityUtils'
import { resolveUpdateEventDetailsObjectFromEventDetailsDiff } from '@mobile/reusables/eventUtils'
import { skinPatterns } from '@mobile/reusables/skinPatterns'
import BetMarketButton from '@mobile/views/Relation/Shared/BetMarketButton/BetMarketButton.vue'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useParticipantSkins } from '@mobile/views/Relation/useParticipantSkins'

import { EventPreparationChildPopupType } from './EventPreparationChildPopupType'
import { EventPreparationTeamDetail } from './factories/EventPreparationTeamDetailsFactory/types'
import { useEventPreparationTeamDetailsFactory } from './factories/EventPreparationTeamDetailsFactory/useEventPreparationTeamDetailsFactory'

export default defineComponent({
  components: {
    Button,
    ParticipantComponent,
    Select,
    BetMarketButton,
  },
  props: {
    event: {
      type: Object as PropType<Event>,
      required: true,
    },
  },
  emits: ['openPopup'],
  setup(props, { emit }) {
    const {
      sport,
      probableLinkedHomeParticipant,
      probableLinkedAwayParticipant,
      probableEvent,
      actionQueue,
    } = useEventInjections()

    const hasLineupsSportDetail = byId(
      sport.value.sportsAPIEntity.details,
      SoccerEventDetailId.HasLineups,
    )
    const hasLineupsEventDetail = computed(() =>
      byId(props.event.details, SoccerEventDetailId.HasLineups),
    )
    const hasLineupsSelectOptions = computed(() =>
      hasLineupsSportDetail
        ? hasLineupsSportDetail.possible_values.map(({ value, key }) => ({
            name: key.toString(),
            value,
          }))
        : [],
    )

    const hasFormationsSportDetail = byId(
      sport.value.sportsAPIEntity.details,
      SoccerEventDetailId.HasFormations,
    )
    const hasFormationsEventDetail = computed(() =>
      byId(props.event.details, SoccerEventDetailId.HasFormations),
    )
    const hasFormationSelectOptions = computed(() =>
      hasFormationsSportDetail
        ? hasFormationsSportDetail.possible_values.map(({ value, key }) => ({
            name: key.toString(),
            value,
          }))
        : [],
    )
    const showFormationSelect = !!hasFormationsSportDetail
    const showFormationButton = computed(
      () =>
        !!(
          hasFormationsSportDetail &&
          hasFormationsEventDetail.value?.value !== YesNoEnum.No
        ),
    )
    const details: EventPreparationTeamDetail =
      useEventPreparationTeamDetailsFactory(
        props.event,
        sport.value,
      ).getDetails()

    const { currentSkin: currentHomeParticipantSkin } = useParticipantSkins(
      probableLinkedHomeParticipant.getParticipant(),
    )
    const { currentSkin: currentAwayParticipantSkin } = useParticipantSkins(
      probableLinkedAwayParticipant.getParticipant(),
    )

    function openChildPopup(
      popupType: EventPreparationChildPopupType,
      probableLinkedParticipant: ProbableLinkedParticipant,
    ): void {
      emit('openPopup', {
        popupType,
        probableLinkedParticipant,
      })
    }

    function updateDetailValue(): void {
      actionQueue.value.add(
        new UpdateEvent({
          details: resolveUpdateEventDetailsObjectFromEventDetailsDiff(
            cloneDeep(probableEvent.value.details),
            props.event.details,
            sport.value.sportsAPIEntity,
          ),
          event: {},
        }),
      )
    }

    return {
      details,
      CommonIncidentId,
      probableEvent,
      probableLinkedHomeParticipant,
      probableLinkedAwayParticipant,
      hasLineupsEventDetail,
      hasFormationsEventDetail,
      hasLineupsSelectOptions,
      hasFormationSelectOptions,
      EventPreparationChildPopupType,
      currentHomeParticipantSkin,
      currentAwayParticipantSkin,
      skinPatterns,
      showFormationSelect,
      showFormationButton,
      openChildPopup,
      updateDetailValue,
      opChain,
      nullCoalesce,
    }
  },
})
</script>

<template>
  <div class="w-full">
    <div class="mb-5 flex w-full items-center justify-between">
      <div class="flex items-center">
        <ParticipantComponent
          :participant="probableLinkedHomeParticipant.getParticipant().value"
        />
      </div>
      <div class="flex items-center">
        <ParticipantComponent
          :participant="probableLinkedAwayParticipant.getParticipant().value"
          :reverse="true"
        />
      </div>
    </div>

    <div class="flex flex-col flex-wrap space-y-2">
      <div class="flex w-full flex-row items-center justify-between">
        <div class="flex flex-col self-start">
          <Button
            v-if="details.buttons.lineups"
            class="w-32 p-5 font-bold"
            :disabled="details.buttons.lineups.disabled"
            :size="'md'"
            :color="'blue'"
            @click="
              openChildPopup(
                EventPreparationChildPopupType.Lineups,
                probableLinkedHomeParticipant,
              )
            "
          >
            <FontAwesomeIcon
              class="mr-3"
              :icon="details.buttons.lineups.icon"
            />
            {{ details.buttons.lineups.name }}
          </Button>
          <Button
            v-if="details.buttons.skins"
            class="my-3 w-28 space-x-3 p-5 font-bold"
            :disabled="details.buttons.skins.disabled"
            :size="'md'"
            :color="'blue'"
            @click="
              openChildPopup(
                EventPreparationChildPopupType.Skins,
                probableLinkedHomeParticipant,
              )
            "
          >
            <div
              v-if="currentHomeParticipantSkin"
              class="h-6 w-6"
            >
              <component
                :is="
                  nullCoalesce(
                    skinPatterns.get(currentHomeParticipantSkin.pattern_id),
                    skinPatterns.get(1),
                  )
                "
                :firstColor="`#${currentHomeParticipantSkin.first_color}`"
                :secondColor="`#${currentHomeParticipantSkin.second_color}`"
                :thirdColor="`#${currentHomeParticipantSkin.third_color}`"
              />
            </div>
            <FontAwesomeIcon
              v-else
              :icon="details.buttons.skins.icon"
            />
            <div>{{ details.buttons.skins.name }}</div>
          </Button>
          <Button
            v-if="details.buttons.formation"
            class="w-36 space-x-3 p-5 font-bold"
            :size="'md'"
            :color="'blue'"
            :disabled="
              !showFormationButton || details.buttons.formation.disabled
            "
            @click="
              openChildPopup(
                EventPreparationChildPopupType.Formations,
                probableLinkedHomeParticipant,
              )
            "
          >
            <FontAwesomeIcon :icon="details.buttons.formation.icon" />
            <div>{{ details.buttons.formation.name }}</div>
          </Button>
        </div>
        <div class="relative -top-5 flex flex-col items-center">
          <div>
            <label
              class="text-darkgray mb-2 text-sm font-bold"
              for="has-lineups-select"
            >
              {{ details.hasLineupsSelect.name }}
            </label>
            <Select
              v-if="
                details.hasLineupsSelect.show &&
                hasLineupsEventDetail &&
                hasLineupsEventDetail.value
              "
              v-model:value="hasLineupsEventDetail.value"
              class="mb-5 w-36"
              :disabled="details.hasLineupsSelect.disabled"
              label="name"
              :options="hasLineupsSelectOptions"
              @update:value="updateDetailValue"
            />
          </div>
          <div v-if="showFormationSelect">
            <label
              class="text-darkgray mb-2 text-sm font-bold"
              for="has-lineups-select"
            >
              Has formation
            </label>
            <Select
              v-if="hasFormationsEventDetail && hasFormationsEventDetail.value"
              v-model:value="hasFormationsEventDetail.value"
              class="mb-5 w-36"
              label="name"
              :options="hasFormationSelectOptions"
              @update:value="updateDetailValue"
            />
          </div>
          <BetMarketButton
            :size="'md'"
            :openMarketsIncident="CommonIncidentId.Betstart"
            :closeMarketsIncident="CommonIncidentId.Betstop"
            marketName="bet_status"
          />
        </div>
        <div class="flex flex-col self-start">
          <Button
            v-if="details.buttons.lineups"
            class="w-32 self-end p-5 font-bold"
            :disabled="details.buttons.lineups.disabled"
            :size="'md'"
            :color="'blue'"
            @click="
              openChildPopup(
                EventPreparationChildPopupType.Lineups,
                probableLinkedAwayParticipant,
              )
            "
          >
            <FontAwesomeIcon
              class="mr-3"
              :icon="details.buttons.lineups.icon"
            />
            {{ details.buttons.lineups.name }}
          </Button>
          <Button
            v-if="details.buttons.skins"
            class="my-3 w-28 space-x-3 self-end p-5 font-bold"
            :disabled="details.buttons.skins.disabled"
            :size="'md'"
            :color="'blue'"
            @click="
              openChildPopup(
                EventPreparationChildPopupType.Skins,
                probableLinkedAwayParticipant,
              )
            "
          >
            <div
              v-if="currentAwayParticipantSkin"
              class="h-6 w-6"
            >
              <component
                :is="
                  nullCoalesce(
                    skinPatterns.get(currentAwayParticipantSkin.pattern_id),
                    skinPatterns.get(1),
                  )
                "
                :firstColor="`#${currentAwayParticipantSkin.first_color}`"
                :secondColor="`#${currentAwayParticipantSkin.second_color}`"
                :thirdColor="`#${currentAwayParticipantSkin.third_color}`"
              />
            </div>
            <FontAwesomeIcon
              v-else
              :icon="details.buttons.skins.icon"
            />
            <div>{{ details.buttons.skins.name }}</div>
          </Button>
          <Button
            v-if="details.buttons.formation"
            class="w-36 space-x-3 self-end p-5 font-bold"
            :size="'md'"
            :color="'blue'"
            :disabled="
              !showFormationButton || details.buttons.formation.disabled
            "
            @click="
              openChildPopup(
                EventPreparationChildPopupType.Formations,
                probableLinkedAwayParticipant,
              )
            "
          >
            <FontAwesomeIcon :icon="details.buttons.formation.icon" />
            <div>{{ details.buttons.formation.name }}</div>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
