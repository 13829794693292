import { Container } from 'inversify'
import { computed } from 'vue'

import * as app from '@mobile/globalState/app'

import { RelationIoCBindings } from './types'

export function bindSport(ioc: Container): void {
  ioc
    .bind(RelationIoCBindings.Sport)
    .toDynamicValue(async (ctx) => {
      const event = await ctx.container.getAsync(
        RelationIoCBindings.FactualEvent,
      )

      return computed(() => {
        const sport = app.getSport(event.value.data.sport_id)

        if (!sport) {
          throw Error("Couldn't resolve sport")
        }

        return sport
      })
    })
    .inSingletonScope()
}
