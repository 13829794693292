import { Container } from 'inversify'

import {
  CollectorEventTime,
  EventStatusId,
  HandballEventStatusId,
  HandballIncidentId,
} from '@collector/sportsapi-client-legacy'
import { AddIncident } from '@mobile/ActionQueue/Actions/Actions'
import IncidentId from '@mobile/ActionQueue/Actions/Incidents/IncidentId'
import * as popup from '@mobile/globalState/popup'
import { getOrThrow } from '@mobile/reusables/entityUtils'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import {
  IncidentHandler,
  StatusIncidentMap,
} from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/IncidentHandlersConfiguration'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'
import { HandballRelationIoCBindings } from '@mobile/views/Relation/Sports/Handball/HandballRelationDependencies/HandballRelationDependencies'
import { BlueCardPopup } from '@mobile/views/Relation/Sports/Handball/Popups/BlueCardPopup'
import FoulPopup from '@mobile/views/Relation/Sports/Handball/Popups/FoulPopup.vue'
import { InjuryPopup } from '@mobile/views/Relation/Sports/Handball/Popups/InjuryPopup'
import { PenaltyPopup } from '@mobile/views/Relation/Sports/Handball/Popups/PenaltyPopup'
import { RedCardPopup } from '@mobile/views/Relation/Sports/Handball/Popups/RedCardPopup'
import { SuspensionOverPopup } from '@mobile/views/Relation/Sports/Handball/Popups/SuspensionOver'
import { SuspensionPopup } from '@mobile/views/Relation/Sports/Handball/Popups/SuspensionPopup'
import { TimeoutPopup } from '@mobile/views/Relation/Sports/Handball/Popups/TimeoutPopup'
import { YellowCardPopup } from '@mobile/views/Relation/Sports/Handball/Popups/YellowCardPopup'

export function bindIncidentHandlersConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.IncidentHandlersConfiguration)
    .toDynamicValue(async (ctx) => {
      const [actionQueue, probableEvent, probableClock, probableIncidents] =
        await Promise.all([
          await ctx.container.getAsync(RelationIoCBindings.ActionQueue),
          await ctx.container.getAsync(RelationIoCBindings.ProbableEvent),
          await ctx.container.getAsync(RelationIoCBindings.ProbableClock),
          await ctx.container.getAsync(RelationIoCBindings.ProbableIncidents),
        ])
      const eventStatusesConfiguration = await ctx.container.getAsync(
        RelationSportCommonIoCBindings.EventStatusesConfiguration,
      )
      const activeParticipant = await ctx.container.getAsync(
        HandballRelationIoCBindings.ActiveParticipant,
      )

      const addIncident: IncidentHandler['handler'] = ({
        incidentId,
        participantId,
        time = {
          minute: probableClock.value.minute,
          second: probableClock.value.second,
        },
      }) => {
        actionQueue.value.add(
          new AddIncident({
            incident_id: incidentId,
            status_id: probableEvent.value.status_id,
            participant_team_id: participantId,
            ...time,
          }),
        )
      }

      const isDisabledByCurrentEventStatus = (
        incidentId: IncidentId,
      ): boolean => {
        const statusIncidentMap: Array<StatusIncidentMap> = [
          {
            status: HandballEventStatusId.NotStarted,
            incident: HandballIncidentId.NotStarted,
          },
          {
            status: HandballEventStatusId.NotStarted,
            incident: HandballIncidentId.MatchAboutToStart,
          },
          {
            status: HandballEventStatusId.Interrupted,
            incident: HandballIncidentId.StartDelayed,
          },
          {
            status: HandballEventStatusId.FirstHalf,
            incident: HandballIncidentId.FirstHalfStarted,
          },
          {
            status: HandballEventStatusId.Halftime,
            incident: HandballIncidentId.Halftime,
          },
          {
            status: HandballEventStatusId.SecondHalf,
            incident: HandballIncidentId.SecondHalfStarted,
          },
          {
            status: HandballEventStatusId.Finished,
            incident: HandballIncidentId.FinishedRegularTime,
          },
          {
            status: HandballEventStatusId.WaitingForExtratime,
            incident: HandballIncidentId.WaitingForExtratime,
          },
          {
            status: HandballEventStatusId.ExtratimeFirstHalf,
            incident: HandballIncidentId.FirstExtratimeFirstHalfStarted,
          },
          {
            status: HandballEventStatusId.ExtratimeHalftime,
            incident: HandballIncidentId.ExtratimeHalftime,
          },
          {
            status: HandballEventStatusId.ExtratimeSecondHalf,
            incident: HandballIncidentId.FirstExtratimeSecondHalfStarted,
          },
          {
            status: HandballEventStatusId.WaitingForSecondExtratime,
            incident: HandballIncidentId.WaitingForSecondExtratime,
          },
          {
            status: HandballEventStatusId.SecondExtratimeFirstHalf,
            incident: HandballIncidentId.SecondExtratimeFirstHalfStarted,
          },
          {
            status: HandballEventStatusId.SecondExtratimeHalftime,
            incident: HandballIncidentId.SecondExtratimeHalftime,
          },
          {
            status: HandballEventStatusId.SecondExtratimeSecondHalf,
            incident: HandballIncidentId.SecondExtratimeSecondHalfStarted,
          },
          {
            status: HandballEventStatusId.WaitingForPenalties,
            incident: HandballIncidentId.WaitingForPenalties,
          },
          {
            status: HandballEventStatusId.PenaltyShootout,
            incident: HandballIncidentId.PenaltyShootoutStarted,
          },
          {
            status: HandballEventStatusId.FinishedAfterExtratime,
            incident: HandballIncidentId.FinishedAfterExtratime,
          },
          {
            status: HandballEventStatusId.FinishedAfterPenalties,
            incident: HandballIncidentId.FinishedAfterPenalties,
          },
          {
            status: HandballEventStatusId.FinishedAwardedWin,
            incident: HandballIncidentId.FinishedAwardedWin,
          },
        ]

        const indexOfCurrentStatus = statusIncidentMap.findIndex(
          (element) => element.status === probableEvent.value.status_id,
        )
        const indexOfSearchedIncident = statusIncidentMap.findIndex(
          (element) => element.incident === incidentId,
        )

        return indexOfCurrentStatus >= indexOfSearchedIncident
      }

      const getTimeForEventStatus = (
        eventStatusId: EventStatusId,
      ): CollectorEventTime | null => {
        return getOrThrow(eventStatusesConfiguration, eventStatusId).time
      }

      const countTimeoutsIncidents = (): number[] => {
        return probableIncidents.value.reduce(
          (
            [numberOfTimeoutIncidents, numberOfTimeoutOverIncidents],
            { incident_id },
          ) => {
            if (incident_id === HandballIncidentId.Timeout)
              numberOfTimeoutIncidents += 1
            if (incident_id === HandballIncidentId.TimeoutOver)
              numberOfTimeoutOverIncidents += 1

            return [numberOfTimeoutIncidents, numberOfTimeoutOverIncidents]
          },
          [0, 0],
        )
      }

      return {
        [HandballIncidentId.NotStarted]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(HandballEventStatusId.NotStarted),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [HandballIncidentId.StartDelayed]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(HandballEventStatusId.StartDelayed),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [HandballIncidentId.StartWithPossession]: {
          handler: ({ participantId }) => {
            addIncident({
              incidentId: HandballIncidentId.StartWithPossession,
              participantId,
            })
          },
          disabled: () => false,
        },
        [HandballIncidentId.FirstHalfStarted]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(HandballEventStatusId.FirstHalf),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [HandballIncidentId.Halftime]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(HandballEventStatusId.Halftime),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [HandballIncidentId.SecondHalfStarted]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(HandballEventStatusId.SecondHalf),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [HandballIncidentId.WaitingForExtratime]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(
                HandballEventStatusId.WaitingForExtratime,
              ),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [HandballIncidentId.FirstExtratimeFirstHalfStarted]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(
                HandballEventStatusId.ExtratimeFirstHalf,
              ),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [HandballIncidentId.ExtratimeHalftime]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(
                HandballEventStatusId.ExtratimeHalftime,
              ),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [HandballIncidentId.FirstExtratimeSecondHalfStarted]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(
                HandballEventStatusId.ExtratimeSecondHalf,
              ),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [HandballIncidentId.WaitingForSecondExtratime]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(
                HandballEventStatusId.WaitingForSecondExtratime,
              ),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [HandballIncidentId.SecondExtratimeFirstHalfStarted]: {
          handler: ({ incidentId }) => {
            addIncident({
              incidentId,
              time: getTimeForEventStatus(
                HandballEventStatusId.SecondExtratimeFirstHalf,
              ),
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [HandballIncidentId.SecondExtratimeHalftime]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(
                HandballEventStatusId.SecondExtratimeHalftime,
              ),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [HandballIncidentId.SecondExtratimeSecondHalfStarted]: {
          handler: ({ incidentId }) => {
            addIncident({
              incidentId,
              time: getTimeForEventStatus(
                HandballEventStatusId.ExtratimeSecondHalf,
              ),
            })
          },
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [HandballIncidentId.WaitingForPenalties]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(
                HandballEventStatusId.WaitingForPenalties,
              ),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [HandballIncidentId.PenaltyShootoutStarted]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(
                HandballEventStatusId.PenaltyShootout,
              ),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [HandballIncidentId.ToFinish]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(HandballEventStatusId.ToFinish),
            }),
          disabled: () => false,
        },
        [HandballIncidentId.Interrupted]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(HandballEventStatusId.Interrupted),
            }),
          disabled: () => false,
        },
        [HandballIncidentId.Postponed]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(HandballEventStatusId.Postponed),
            }),
          disabled: () => false,
        },
        [HandballIncidentId.Cancelled]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(HandballEventStatusId.Cancelled),
            }),
          disabled: () => false,
        },
        [HandballIncidentId.FinishedRegularTime]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(HandballEventStatusId.Finished),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [HandballIncidentId.FinishedAfterExtratime]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(
                HandballEventStatusId.FinishedAfterExtratime,
              ),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [HandballIncidentId.FinishedAfterPenalties]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(
                HandballEventStatusId.FinishedAfterPenalties,
              ),
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
        [HandballIncidentId.FinishedAwardedWin]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(
                HandballEventStatusId.FinishedAwardedWin,
              ),
            }),
          disabled: () => false,
        },
        [HandballIncidentId.Abandoned]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              time: getTimeForEventStatus(HandballEventStatusId.Abandoned),
            }),
          disabled: () => false,
        },
        [HandballIncidentId.Timeout]: {
          handler: () =>
            popup.show({
              component: TimeoutPopup,
              showHeader: true,
            }),
          disabled: () => {
            const [numberOfTimeoutIncidents, numberOfTimeoutOverIncidents] =
              countTimeoutsIncidents()

            return numberOfTimeoutIncidents > numberOfTimeoutOverIncidents
          },
        },
        [HandballIncidentId.TimeoutOver]: {
          handler: ({ incidentId }) => {
            const timeoutIncident = probableIncidents.value.find(
              ({ incident_id }) => incident_id === HandballIncidentId.Timeout,
            )
            if (!timeoutIncident || !timeoutIncident.participant_id) {
              return
            }
            addIncident({
              incidentId,
              participantId: timeoutIncident.participant_id,
            })
          },
          disabled: () => {
            const [numberOfTimeoutIncidents, numberOfTimeoutOverIncidents] =
              countTimeoutsIncidents()

            return numberOfTimeoutOverIncidents >= numberOfTimeoutIncidents
          },
        },
        [HandballIncidentId.TwoMinuteSuspension]: {
          handler: () =>
            popup.show({
              component: SuspensionPopup,
              showHeader: true,
            }),
          disabled: () => false,
        },
        [HandballIncidentId.SuspensionOver]: {
          handler: () =>
            popup.show({
              component: SuspensionOverPopup,
              showHeader: true,
            }),
          disabled: () => false,
        },
        [HandballIncidentId.Injury]: {
          handler: () =>
            popup.show({
              component: InjuryPopup,
              showHeader: true,
            }),
          disabled: () => false,
        },
        [HandballIncidentId.YellowCard]: {
          handler: () =>
            popup.show({
              component: YellowCardPopup,
              showHeader: true,
            }),
          disabled: () => false,
        },
        [HandballIncidentId.RedCard]: {
          handler: () =>
            popup.show({
              component: RedCardPopup,
              showHeader: true,
            }),
          disabled: () => false,
        },
        [HandballIncidentId.BlueCard]: {
          handler: () =>
            popup.show({
              component: BlueCardPopup,
              showHeader: true,
            }),
          disabled: () => false,
        },
        [HandballIncidentId.RefereeConsultation]: {
          handler: ({ incidentId }) => addIncident({ incidentId }),
          disabled: () => false,
        },
        [HandballIncidentId.BreakDuringGame]: {
          handler: ({ incidentId }) => addIncident({ incidentId }),
          disabled: () => false,
        },
        [HandballIncidentId.Penalty]: {
          handler: () =>
            popup.show({
              component: PenaltyPopup,
              showHeader: true,
            }),
          disabled: () => false,
        },
        [HandballIncidentId.ThrowSaved]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              participantId: activeParticipant.value.participant?.id,
            }),
          disabled: () => !activeParticipant.value.participant,
        },
        [HandballIncidentId.MissedThrow]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              participantId: activeParticipant.value.participant?.id,
            }),
          disabled: () => !activeParticipant.value.participant,
        },
        [HandballIncidentId.Turnover]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              participantId: activeParticipant.value.participant?.id,
            }),
          disabled: () => !activeParticipant.value.participant,
        },
        [HandballIncidentId.Goal]: {
          handler: ({ incidentId }) =>
            addIncident({
              incidentId,
              participantId: activeParticipant.value.participant?.id,
            }),
          disabled: () => !activeParticipant.value.participant,
        },
        [HandballIncidentId.Foul]: {
          handler: () =>
            popup.show({
              component: FoulPopup,
              showHeader: true,
            }),
          disabled: () => false,
        },
        fallbackHandler: {
          handler: addIncident,
          disabled: () => false,
        },
      }
    })
    .inSingletonScope()
}
