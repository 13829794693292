import {
  CricketIncidentId,
  IllegalBallAttributesId,
} from '@collector/sportsapi-client-legacy'
import { useCancellationButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useCancellationButton'
import { useConfirmationButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useConfirmationButton'
import { useNavigationSeparator } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useNavigationSeparator'
import { PopupBuilder } from '@mobile/views/Relation/Shared/Popup/builder/PopupBuilder'

import { FreeHitPopup } from '../FreeHit/FreeHitPopup'
import BallSummaryPopupDetails from './BallSummaryPopupDetails.vue'

const noBallAttributes = [
  IllegalBallAttributesId.NoBall,
  IllegalBallAttributesId.NoBallBat,
]

export const BallSummaryPopup = new PopupBuilder('Ball summary')
  .appendBody(BallSummaryPopupDetails)
  .setNavigationButtons([
    ...useNavigationSeparator(3),
    useCancellationButton({ onClick: (context) => context?.closeAllPopups() }),
    useConfirmationButton({
      onClick: (context) => {
        const probableIncident =
          context?.incidentActionComposable.getProbableIncident()

        if (!probableIncident?.value) {
          return context?.closeAllPopups()
        }

        const {
          incident_id: previousIncidentId,
          attribute_ids: previousIncidentAttributes,
        } = probableIncident.value
        const isNoBallIncident =
          previousIncidentId === CricketIncidentId.IllegalBall &&
          previousIncidentAttributes.some((attribute) =>
            noBallAttributes.includes(attribute as IllegalBallAttributesId),
          )

        if (isNoBallIncident) {
          return context?.openPopup(FreeHitPopup)
        }

        context?.closeAllPopups()
      },
    }),
  ])
  .build()
