<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { IncidentId } from '@collector/sportsapi-client-legacy'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'

export default defineComponent({
  components: { IncidentButton },
  props: {
    isExpanded: {
      type: Boolean,
      required: true,
    },
    incidentIds: {
      type: Array as PropType<Array<IncidentId>>,
      required: true,
    },
  },
  emits: ['toggle', 'incidentAddedToQueue'],
})
</script>

<template>
  <div
    class="h-68 bg-silver absolute bottom-0 w-full transform transition-transform"
    :class="{ 'translate-y-4': isExpanded, 'translate-y-64': !isExpanded }"
  >
    <div
      class="bg-gradient-darkBlue-to-blue flex justify-between align-middle"
      :class="{ 'h-8': isExpanded, 'h-4': !isExpanded }"
      @click="$emit('toggle')"
    >
      <FontAwesomeIcon
        class="m-auto flex-1 text-white"
        :icon="isExpanded ? 'angle-down' : 'angle-up'"
      />
    </div>
    <div class="h-safe grid grid-cols-3 gap-2 overflow-y-scroll p-2">
      <IncidentButton
        v-for="incidentId in incidentIds"
        :key="incidentId"
        class="w-full"
        :type="'horizontal'"
        :incidentId
        :size="'xl'"
        :color="'white'"
        @incidentHandled="$emit('incidentAddedToQueue')"
      />
      <slot></slot>
    </div>
  </div>
</template>
