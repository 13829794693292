<script lang="ts">
import { defineComponent, PropType, Ref, ref } from 'vue'

import { opChain } from '@collector/shared-utils'
import {
  Confirmation,
  CricketIncidentId,
} from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import ToggleButtonGroup, {
  ToggleButtonGroupOption,
} from '@mobile/components/ToggleButton/ToggleButtonGroup.vue'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'
import PopupControls from '@mobile/views/Relation/Shared/Popup/PopupControls.vue'
import { useIncidentAction } from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

import { useCricketConfiguration } from '../../CricketRelationDependencies/useCricketConfiguration'
import { prepareAdditionalInfo } from '../../prepareAdditionalInfo'
import { BallSummaryPopup } from '../BallSummary/BallSummaryPopup'

export default defineComponent({
  components: {
    ToggleButtonGroup,
    PopupControls,
    Button,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Array as PropType<ToggleButtonGroupOption[]>,
      required: true,
    },
    coreAttributeId: {
      type: Number,
      required: true,
    },
    incidentId: {
      type: Number as PropType<
        CricketIncidentId.LegalBall | CricketIncidentId.IllegalBall
      >,
      required: true,
    },
    context: {
      type: Object as PropType<PopupContext | null>,
      required: false,
    },
    additionalOptions: {
      type: Array as PropType<ToggleButtonGroupOption[]>,
      default: () => [],
      required: false,
    },
  },
  setup(props) {
    const eventInjections = useEventInjections()
    const { probableIncidents } = eventInjections
    const { activeParticipant } = useCricketConfiguration()
    const selectedNumberOf: Ref<number | null> = ref(null)

    function send(): void {
      if (!selectedNumberOf.value) {
        return
      }

      const attributes = props.additionalOptions.some(
        (el) => el.value === selectedNumberOf.value,
      )
        ? [selectedNumberOf.value]
        : [props.coreAttributeId, selectedNumberOf.value]
      const additionalInfo = prepareAdditionalInfo(
        activeParticipant,
        probableIncidents,
      )

      const incidentActionComposable = useIncidentAction(eventInjections, {
        confirmation: Confirmation.ToBeDone,
        incident_id: props.incidentId,
        attribute_ids: attributes,
        participant_team_id: activeParticipant.value.participant?.id,
        additional_info: additionalInfo,
      })

      props.context?.openPopup(BallSummaryPopup, { incidentActionComposable })
    }

    return {
      selectedNumberOf,
      send,
      opChain,
    }
  },
})
</script>

<template>
  <div class="grid h-full w-full place-items-center pb-16">
    <div class="grid place-items-center gap-2">
      <div class="text-cloudygray text-center font-bold">
        {{ title }}
      </div>
      <ToggleButtonGroup
        v-model:value="selectedNumberOf"
        v-bind="{
          withCheckmark: false,
          options,
          type: 'horizontal',
          undeselectable: true,
        }"
        @click="send"
      />

      <ToggleButtonGroup
        v-model:value="selectedNumberOf"
        v-bind="{
          withCheckmark: false,
          options: additionalOptions,
          type: 'horizontal',
          undeselectable: true,
        }"
        @click="send"
      />
    </div>
  </div>

  <PopupControls>
    <template #buttons>
      <div class="flex justify-end space-x-2">
        <Button
          :color="'red'"
          :size="'lg'"
          @click="opChain(context, (v) => v.closeAllPopups())"
        >
          Close
        </Button>
      </div>
    </template>
  </PopupControls>
</template>
