import { isEqual, sortBy } from 'lodash'

import {
  ClockStatus,
  Detail,
  Event,
  EventDetailId,
  Participant,
  Result,
  Sport,
  Stat,
  TeamSide,
  UpdateEventDetailsObject,
  UpdateEventObject,
  UpdateEventParticipantsObject,
} from '@collector/sportsapi-client-legacy'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import { byId } from '@mobile/reusables/entityUtils'

const mapDetailValueToKey = (
  detail: Detail,
  sport: Sport,
): string | number | null => {
  const sportDetail = byId(sport.details, detail.id)
  const possibleSportDetailValue = sportDetail?.possible_values.find(
    (possibleValue) => possibleValue.value === detail.value,
  )

  return possibleSportDetailValue?.key ?? detail.value
}

export const resolveUpdateEventObjectFromEventDiff = (
  oldEvent: Event,
  newEvent: Event,
): UpdateEventObject => {
  const event: UpdateEventObject = {}

  if (oldEvent.status_id !== newEvent.status_id) {
    event.event_status_id = newEvent.status_id
  }
  if (oldEvent.relation_status !== newEvent.relation_status) {
    event.scout_status = newEvent.relation_status
  }
  if (oldEvent.verified_result !== newEvent.verified_result) {
    event.is_result_verified = newEvent.verified_result
  }
  if (oldEvent.coverage_type !== newEvent.coverage_type) {
    event.coverage_type = newEvent.coverage_type
  }
  if (oldEvent.clock_time !== newEvent.clock_time) {
    event.clock_time = newEvent.clock_time
  }
  if (oldEvent.start_date !== newEvent.start_date) {
    event.start_date = newEvent.start_date
  }
  if (oldEvent.neutral_venue !== newEvent.neutral_venue) {
    event.neutral_venue = newEvent.neutral_venue
  }
  if (oldEvent.scoutsfeed !== newEvent.scoutsfeed) {
    event.scoutsfeed = newEvent.scoutsfeed
  }
  if (oldEvent.name !== newEvent.name) {
    event.name = newEvent.name
  }
  if (oldEvent.ft_only !== newEvent.ft_only) {
    event.ft_only = newEvent.ft_only
  }
  if (oldEvent.clock_status !== newEvent.clock_status) {
    event.clock_status = convertEventClockStatusToDTOClockStatus(
      newEvent.clock_status,
    )
  }
  if (oldEvent.referee_id !== newEvent.referee_id) {
    event.referee_id = newEvent.referee_id
  }

  return event
}

export function convertEventClockStatusToDTOClockStatus(
  eventClockStatus: ClockStatus | null,
): boolean | undefined {
  let dtoClockStatus: boolean | undefined = undefined

  if (eventClockStatus === ClockStatus.Running) {
    dtoClockStatus = true
  } else if (eventClockStatus === ClockStatus.Stopped) {
    dtoClockStatus = false
  }

  return dtoClockStatus
}

export const incidentDiff = (
  oldIncident: ProbableIncident,
  newIncident: Partial<ProbableIncident>,
): Partial<ProbableIncident> => {
  const incident: Partial<ProbableIncident> = {}

  if (
    newIncident.confirmation !== undefined &&
    oldIncident.confirmation !== newIncident.confirmation
  ) {
    incident.confirmation = newIncident.confirmation || incident.confirmation
  }

  if (
    newIncident.incident_name !== undefined &&
    oldIncident.incident_name !== newIncident.incident_name
  ) {
    incident.incident_name = newIncident.incident_name
  }

  if (
    newIncident.participant_id !== undefined &&
    oldIncident.participant_id !== newIncident.participant_id
  ) {
    incident.participant_id = newIncident.participant_id
  }

  if (
    newIncident.participant_name !== undefined &&
    oldIncident.participant_name !== newIncident.participant_name
  ) {
    incident.participant_name = newIncident.participant_name
  }

  if (
    newIncident.subparticipant_id !== undefined &&
    oldIncident.subparticipant_id !== newIncident.subparticipant_id
  ) {
    incident.subparticipant_id = newIncident.subparticipant_id
  }

  if (
    newIncident.assistant_id !== undefined &&
    oldIncident.assistant_id !== newIncident.assistant_id
  ) {
    incident.assistant_id = newIncident.assistant_id
  }

  if (
    newIncident.secondary_assistant_id !== undefined &&
    oldIncident.secondary_assistant_id !== newIncident.secondary_assistant_id
  ) {
    incident.secondary_assistant_id = newIncident.secondary_assistant_id
  }

  if (
    newIncident.subparticipant_name !== undefined &&
    oldIncident.subparticipant_name !== newIncident.subparticipant_name
  ) {
    incident.subparticipant_name = newIncident.subparticipant_name
  }

  if (
    newIncident.assistant_name !== undefined &&
    oldIncident.assistant_name !== newIncident.assistant_name
  ) {
    incident.assistant_name = newIncident.assistant_name
  }

  if (
    newIncident.secondary_assistant_name !== undefined &&
    oldIncident.secondary_assistant_name !==
      newIncident.secondary_assistant_name
  ) {
    incident.secondary_assistant_name = newIncident.secondary_assistant_name
  }

  if (newIncident.info !== undefined && oldIncident.info !== newIncident.info) {
    incident.info = newIncident.info
  }

  if (
    newIncident.important !== undefined &&
    oldIncident.important !== newIncident.important
  ) {
    incident.important = newIncident.important
  }

  if (
    newIncident.important_for_trader !== undefined &&
    oldIncident.important_for_trader !== newIncident.important_for_trader
  ) {
    incident.important_for_trader = newIncident.important_for_trader
  }

  if (
    newIncident.event_time !== undefined &&
    oldIncident.event_time !== newIncident.event_time
  ) {
    incident.event_time = newIncident.event_time
  }

  if (
    newIncident.event_status_id !== undefined &&
    oldIncident.event_status_id !== newIncident.event_status_id
  ) {
    incident.event_status_id = newIncident.event_status_id
  }

  if (
    newIncident.event_status_name !== undefined &&
    oldIncident.event_status_name !== newIncident.event_status_name
  ) {
    incident.event_status_name = newIncident.event_status_name
  }

  if (
    newIncident.game_break !== undefined &&
    oldIncident.game_break !== newIncident.game_break
  ) {
    incident.game_break = newIncident.game_break
  }

  if (
    newIncident.x_pos !== undefined &&
    oldIncident.x_pos !== newIncident.x_pos
  ) {
    incident.x_pos = newIncident.x_pos
  }

  if (
    newIncident.y_pos !== undefined &&
    oldIncident.y_pos !== newIncident.y_pos
  ) {
    incident.y_pos = newIncident.y_pos
  }

  if (
    newIncident.goalkeeper_id !== undefined &&
    oldIncident.goalkeeper_id !== newIncident.goalkeeper_id
  ) {
    incident.goalkeeper_id = newIncident.goalkeeper_id
  }

  if (
    newIncident.attribute_ids !== undefined &&
    !isEqual(
      sortBy(oldIncident.attribute_ids),
      sortBy(newIncident.attribute_ids),
    )
  ) {
    incident.attribute_ids = newIncident.attribute_ids
  }

  // TODO: remove this line when Team API will fix update paylods
  incident.properties = newIncident.properties

  return incident
}

export const resolveUpdateEventDetailsObjectFromEventDetailsDiff = (
  oldDetails: Detail[],
  newDetails: Detail[],
  sport: Sport,
): UpdateEventDetailsObject => {
  const details: UpdateEventDetailsObject = {}

  oldDetails.forEach((detail) => {
    const newEventDetail = newDetails.find((d) => d.id === detail.id)

    /* eslint-disable-next-line eqeqeq */
    if (newEventDetail && detail.value != newEventDetail.value) {
      details[detail.id] = mapDetailValueToKey(newEventDetail, sport)
    }
  })

  return details
}

export function diffById<T extends Result | Stat>(
  oldStats: T[],
  newStats: T[],
): T[] {
  return oldStats.flatMap((oldStat) => {
    const newParticipantResult = byId(newStats, oldStat.id)

    if (!newParticipantResult || oldStat.value === newParticipantResult.value) {
      return []
    }

    return [newParticipantResult]
  })
}

export function participantDiff(
  oldParticipant: Participant,
  newParticipant: Participant,
): UpdateEventParticipantsObject {
  const results = diffById(oldParticipant.results, newParticipant.results)
  const stats = diffById(oldParticipant.stats, newParticipant.stats)

  return {
    id: oldParticipant.id,
    results,
    stats,
    subparticipants: [],
  }
}

export function getDetailValue(
  event: Event,
  eventDetailId: EventDetailId,
): string | number | null {
  return (
    event.details.find((detail) => detail.id === eventDetailId)?.value || null
  )
}

export function getNumericDetailValue(
  event: Event,
  eventDetailId: EventDetailId,
  defaultValue: number,
): number {
  const periodLength = event.details.find(
    (detail) => detail.id === eventDetailId,
  )?.value
  if (periodLength) {
    return typeof periodLength !== 'number'
      ? Number(periodLength)
      : periodLength
  }

  return defaultValue
}

export function getTeamSideFromEventDetail(
  details: Detail[],
  detailId: EventDetailId,
): TeamSide {
  return (byId(details, detailId)?.value as TeamSide) ?? TeamSide.Home
}

export function getOppositeTeamSideFromEventDetail(
  details: Detail[],
  detailId: EventDetailId,
): TeamSide {
  const startingSide = getTeamSideFromEventDetail(details, detailId)

  return startingSide === TeamSide.Home ? TeamSide.Away : TeamSide.Home
}
