import { Container } from 'inversify'

import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindEventHeaderConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.EventHeaderConfiguration)
    .toDynamicValue(() => {
      return { betMarketButtons: [], hasUnconfirmedEvents: true }
    })
    .inSingletonScope()
}
