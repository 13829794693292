<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { CricketIncidentId } from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

import { useCricketConfiguration } from '../../CricketRelationDependencies/useCricketConfiguration'

export default defineComponent({
  components: { Button },
  props: {
    context: {
      type: Object as PropType<PopupContext | null>,
      required: false,
    },
  },
  setup(props) {
    const { activeParticipant } = useCricketConfiguration()

    const { incidentHandlersConfiguration } = useSportConfiguration()
    const incidentId = CricketIncidentId.EndOfOver
    const incidentConfiguration =
      incidentHandlersConfiguration[incidentId] ??
      incidentHandlersConfiguration.fallbackHandler

    function closeAllPopups(): void {
      props.context?.closeAllPopups()
    }

    function sendOverIncident(): void {
      incidentConfiguration.handler({
        incidentId,
        participantId: activeParticipant.value.participant?.id,
      })

      props.context?.closeAllPopups()
    }

    return {
      closeAllPopups,
      sendOverIncident,
    }
  },
})
</script>

<template>
  <div class="grid h-full w-full place-items-center">
    <div class="flex flex-col">
      <div class="text-cloudygray pb-2 text-center font-bold">
        Do you want to end this over?
      </div>
      <div class="flex justify-center space-x-2">
        <Button
          class="w-20"
          :size="'md'"
          :color="'red'"
          @click="closeAllPopups()"
        >
          No
        </Button>
        <Button
          class="w-20"
          :size="'md'"
          :color="'green'"
          @click="sendOverIncident()"
        >
          Yes
        </Button>
      </div>
    </div>
  </div>
</template>
