import { Container } from 'inversify'

import {
  HandballEventStatusId,
  HandballGoalAttribute,
  HandballIncidentId,
  HandballStatId,
  handballStatusResultsMap,
} from '@collector/sportsapi-client-legacy'
import {
  addToEventResult,
  addToEventStat,
  changeEventStatus,
} from '@mobile/ActionQueue/Actions/Event/EventAction'
import { ClockStatusAction } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/IncidentsConfiguration'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindIncidentsConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.IncidentsConfiguration)
    .toDynamicValue(() => {
      return {
        [HandballIncidentId.NotStarted]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              HandballEventStatusId.NotStarted,
            )
          },
        },
        [HandballIncidentId.StartDelayed]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              HandballEventStatusId.StartDelayed,
            )
          },
        },
        [HandballIncidentId.Interrupted]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              HandballEventStatusId.Interrupted,
            )
          },
        },
        [HandballIncidentId.Abandoned]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              HandballEventStatusId.Abandoned,
            )
          },
        },
        [HandballIncidentId.Postponed]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              HandballEventStatusId.Postponed,
            )
          },
        },
        [HandballIncidentId.Cancelled]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              HandballEventStatusId.Cancelled,
            )
          },
        },
        [HandballIncidentId.ToFinish]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              HandballEventStatusId.ToFinish,
            )
          },
        },
        [HandballIncidentId.FirstHalfStarted]: {
          clockStatusAction: ClockStatusAction.Start,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              HandballEventStatusId.FirstHalf,
            )
          },
        },
        [HandballIncidentId.Halftime]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              HandballEventStatusId.Halftime,
            )
          },
        },
        [HandballIncidentId.SecondHalfStarted]: {
          clockStatusAction: ClockStatusAction.Start,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              HandballEventStatusId.SecondHalf,
            )
          },
        },
        [HandballIncidentId.FinishedRegularTime]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              HandballEventStatusId.Finished,
            )
          },
        },
        [HandballIncidentId.WaitingForExtratime]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              HandballEventStatusId.WaitingForExtratime,
            )
          },
        },
        [HandballIncidentId.FirstExtratimeFirstHalfStarted]: {
          clockStatusAction: ClockStatusAction.Start,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              HandballEventStatusId.ExtratimeFirstHalf,
            )
          },
        },
        [HandballIncidentId.ExtratimeHalftime]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              HandballEventStatusId.ExtratimeHalftime,
            )
          },
        },
        [HandballIncidentId.FirstExtratimeSecondHalfStarted]: {
          clockStatusAction: ClockStatusAction.Start,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              HandballEventStatusId.ExtratimeSecondHalf,
            )
          },
        },
        [HandballIncidentId.WaitingForSecondExtratime]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              HandballEventStatusId.WaitingForSecondExtratime,
            )
          },
        },
        [HandballIncidentId.SecondExtratimeFirstHalfStarted]: {
          clockStatusAction: ClockStatusAction.Start,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              HandballEventStatusId.SecondExtratimeFirstHalf,
            )
          },
        },
        [HandballIncidentId.SecondExtratimeHalftime]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              HandballEventStatusId.SecondExtratimeHalftime,
            )
          },
        },
        [HandballIncidentId.SecondExtratimeSecondHalfStarted]: {
          clockStatusAction: ClockStatusAction.Start,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              HandballEventStatusId.SecondExtratimeSecondHalf,
            )
          },
        },
        [HandballIncidentId.WaitingForPenalties]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              HandballEventStatusId.WaitingForPenalties,
            )
          },
        },
        [HandballIncidentId.PenaltyShootoutStarted]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              HandballEventStatusId.PenaltyShootout,
            )
          },
        },
        [HandballIncidentId.FinishedAfterExtratime]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              HandballEventStatusId.FinishedAfterExtratime,
            )
          },
        },
        [HandballIncidentId.FinishedAfterPenalties]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              HandballEventStatusId.FinishedAfterPenalties,
            )
          },
        },
        [HandballIncidentId.FinishedAwardedWin]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              HandballEventStatusId.FinishedAwardedWin,
            )
          },
        },
        [HandballIncidentId.Timeout]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
        },
        [HandballIncidentId.TimeoutOver]: {
          clockStatusAction: ClockStatusAction.Start,
          resetClockTime: false,
        },
        [HandballIncidentId.Injury]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
        },
        [HandballIncidentId.TwoMinuteSuspension]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(
            probableEvent,
            sport,
            dto,
            probableIncident,
          ) {
            addToEventStat(
              probableEvent,
              probableIncident.participant_id,
              HandballStatId.TwoMinutesSuspensions,
              1,
            )
          },
          applyDeleteIncidentResultToEvent(
            probableEvent,
            sport,
            probableIncident,
          ) {
            addToEventStat(
              probableEvent,
              probableIncident.participant_id,
              HandballStatId.TwoMinutesSuspensions,
              -1,
            )
          },
        },
        [HandballIncidentId.YellowCard]: {
          clockStatusAction: ClockStatusAction.None,
          resetClockTime: false,
          applyAddIncidentResultToEvent(
            probableEvent,
            sport,
            dto,
            probableIncident,
          ) {
            addToEventStat(
              probableEvent,
              probableIncident.participant_id,
              HandballStatId.YellowCards,
              1,
            )
          },
          applyDeleteIncidentResultToEvent(
            probableEvent,
            sport,
            probableIncident,
          ) {
            addToEventStat(
              probableEvent,
              probableIncident.participant_id,
              HandballStatId.YellowCards,
              -1,
            )
          },
        },
        [HandballIncidentId.RedCard]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(
            probableEvent,
            sport,
            dto,
            probableIncident,
          ) {
            addToEventStat(
              probableEvent,
              probableIncident.participant_id,
              HandballStatId.RedCards,
              1,
            )
          },
          applyDeleteIncidentResultToEvent(
            probableEvent,
            sport,
            probableIncident,
          ) {
            addToEventStat(
              probableEvent,
              probableIncident.participant_id,
              HandballStatId.RedCards,
              -1,
            )
          },
        },
        [HandballIncidentId.BlueCard]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(
            probableEvent,
            sport,
            dto,
            probableIncident,
          ) {
            addToEventStat(
              probableEvent,
              probableIncident.participant_id,
              HandballStatId.BlueCards,
              1,
            )
          },
          applyDeleteIncidentResultToEvent(
            probableEvent,
            sport,
            probableIncident,
          ) {
            addToEventStat(
              probableEvent,
              probableIncident.participant_id,
              HandballStatId.BlueCards,
              -1,
            )
          },
        },
        [HandballIncidentId.RefereeConsultation]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
        },
        [HandballIncidentId.BreakDuringGame]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
        },
        [HandballIncidentId.Penalty]: {
          clockStatusAction: ClockStatusAction.None,
          resetClockTime: false,
          applyAddIncidentResultToEvent(
            probableEvent,
            sport,
            dto,
            probableIncident,
          ) {
            addToEventStat(
              probableEvent,
              probableIncident.participant_id,
              HandballStatId.Penalties,
              1,
            )
          },
          applyDeleteIncidentResultToEvent(
            probableEvent,
            sport,
            probableIncident,
          ) {
            addToEventStat(
              probableEvent,
              probableIncident.participant_id,
              HandballStatId.Penalties,
              -1,
            )
          },
        },
        [HandballIncidentId.Goal]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(
            probableEvent,
            sport,
            dto,
            probableIncident,
          ) {
            const shouldIncrementPenaltyGoalStat =
              probableIncident.event_status_id !==
                HandballEventStatusId.PenaltyShootout &&
              probableIncident.attribute_ids.includes(
                HandballGoalAttribute.PenaltyGoal,
              )

            if (shouldIncrementPenaltyGoalStat) {
              addToEventStat(
                probableEvent,
                probableIncident.participant_id,
                HandballStatId.PenaltyGoals,
                1,
              )
            }

            const resultsToUpdate =
              handballStatusResultsMap[probableEvent.status_id] ?? []
            resultsToUpdate.forEach((result) => {
              addToEventResult(
                probableEvent,
                dto.participant_team_id,
                result,
                1,
              )
            })
          },
          applyUpdateIncidentResultToEvent(
            probableEvent,
            sport,
            dto,
            probableIncident,
          ) {
            if (
              probableIncident.event_status_id !==
              HandballEventStatusId.PenaltyShootout
            ) {
              addToEventStat(
                probableEvent,
                probableIncident.participant_id,
                HandballStatId.PenaltyGoals,
                dto.attribute_ids?.includes(HandballGoalAttribute.PenaltyGoal)
                  ? 1
                  : -1,
              )
            }
          },
          applyDeleteIncidentResultToEvent(
            probableEvent,
            sport,
            probableIncident,
          ) {
            const shouldDecrementPenaltyGoalStat =
              probableIncident.event_status_id !==
                HandballEventStatusId.PenaltyShootout &&
              probableIncident.attribute_ids.includes(
                HandballGoalAttribute.PenaltyGoal,
              )

            if (shouldDecrementPenaltyGoalStat) {
              addToEventStat(
                probableEvent,
                probableIncident.participant_id,
                HandballStatId.PenaltyGoals,
                -1,
              )
            }

            const resultsToUpdate =
              handballStatusResultsMap[probableEvent.status_id] ?? []
            resultsToUpdate.forEach((result) => {
              addToEventResult(
                probableEvent,
                probableIncident.participant_id,
                result,
                -1,
              )
            })
          },
        },
        fallbackIncidentConfiguration: {
          clockStatusAction: ClockStatusAction.None,
          resetClockTime: false,
        },
      }
    })
    .inSingletonScope()
}
