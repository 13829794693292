<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import { nullCoalesce } from '@collector/shared-utils'

import { FormationPlayer } from '../Formation'

export default defineComponent({
  props: {
    formationPlayer: {
      type: Object as PropType<FormationPlayer>,
      required: true,
    },
    formationPlayerSize: {
      type: Number,
      required: true,
    },
    selectedFormationPlayer: {
      type: Object as PropType<FormationPlayer>,
      required: false,
    },
  },
  emits: ['click'],
  setup(props) {
    const formationPlayerClasses = computed(() =>
      isFormationPlayerSelected()
        ? getSelectedFormationPlayerClasses()
        : getNotSelectedFormationPlayerClasses(),
    )

    function getSelectedFormationPlayerClasses(): string[] {
      return [
        'ring-4',
        ...(isFormationPositionTaken(props.selectedFormationPlayer)
          ? ['bg-blue', 'ring-white']
          : ['bg-white', 'ring-yellow']),
      ]
    }

    function getNotSelectedFormationPlayerClasses(): string[] {
      return isFormationPositionTaken(props.formationPlayer)
        ? ['bg-green', 'ring-2', 'ring-yellow']
        : ['bg-white', 'ring-2', 'ring-yellow']
    }

    function isFormationPlayerSelected(): boolean {
      return (
        props.selectedFormationPlayer?.player_position ===
        props.formationPlayer.player_position
      )
    }

    function isFormationPositionTaken(
      formationPlayer?: FormationPlayer,
    ): boolean {
      return !!formationPlayer?.participant_id
    }

    return { formationPlayerClasses, nullCoalesce }
  },
})
</script>

<template>
  <div class="mb-5 flex-col items-center justify-center text-center text-white">
    <button
      class="max-h-10 min-h-4 min-w-4 max-w-10 cursor-pointer rounded-full"
      :class="formationPlayerClasses"
      :style="{
        width: `${formationPlayerSize}px`,
        height: `${formationPlayerSize}px`,
      }"
      @click="$emit('click', formationPlayer)"
    >
      {{
        formationPlayer.participant_id
          ? formationPlayer.participant_shirt_number
          : ''
      }}
    </button>

    <div class="w-full truncate text-xs font-bold">
      {{
        nullCoalesce(
          formationPlayer.short_name || formationPlayer.participant_name,
          '---',
        )
      }}
    </div>
  </div>
</template>
