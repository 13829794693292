<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'

import { SoccerIncidentId } from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import InputText from '@mobile/components/InputText/InputText.vue'
import { useIncidentAction } from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: {
    Button,
    InputText,
  },
  props: {
    probableLinkedParticipant: {
      type: [Object, null] as PropType<ProbableLinkedParticipant | null>,
      required: true,
    },
  },
  emits: ['substitutionAdded'],
  setup(props, { emit }) {
    const participant = computed(
      () => props.probableLinkedParticipant?.getParticipant().value || null,
    )
    const eventInjections = useEventInjections()
    const playerOut = ref('')
    const playerIn = ref('')
    const backgroundColor = computed(
      () => props.probableLinkedParticipant?.css.bgClass || 'bg-white',
    )

    function addSubstitution(): void {
      if (!participant.value || !props.probableLinkedParticipant) {
        return
      }

      const incidentActionSubstitutionOut = useIncidentAction(eventInjections, {
        incident_id: SoccerIncidentId.SubstitutionOut,
        participant_team_id: participant.value.id,
      })
      const incidentActionSubstitutionIn = useIncidentAction(eventInjections, {
        incident_id: SoccerIncidentId.SubstitutionIn,
        participant_team_id: participant.value.id,
      })

      emit('substitutionAdded', {
        probableLinkedParticipant: props.probableLinkedParticipant,
        incidentActionSubstitutionOut,
        incidentActionSubstitutionIn,
      })
    }

    return {
      playerOut,
      playerIn,
      backgroundColor,
      addSubstitution,
    }
  },
})
</script>

<template>
  <div
    class="flex space-x-4 rounded-md p-1"
    :class="backgroundColor"
  >
    <Button
      class="m-auto mx-1 h-8 w-8"
      :color="'green'"
      :size="'xs'"
      :disabled="!probableLinkedParticipant"
      @click="addSubstitution"
    >
      <FontAwesomeIcon
        class="rotate-45 transform"
        icon="times"
      />
    </Button>

    <div class="flex flex-1 space-x-6">
      <div class="flex w-1/2 items-center">
        <div class="mr-3 w-12 font-bold">Out</div>
        <InputText
          v-model:value="playerIn"
          class="flex-1"
          :placeholder="'Select # od Player name'"
          disabled
        />
      </div>
      <div class="flex w-1/2 items-center">
        <div class="mr-3 w-12 font-bold">In</div>
        <InputText
          v-model:value="playerOut"
          class="flex-1"
          :placeholder="'Select # od Player name'"
          disabled
        />
      </div>
    </div>
  </div>
</template>
