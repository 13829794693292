import * as Sentry from '@sentry/vue'

export function captureErrorWithTransactionName(
  message: string,
  transactionName: string,
  error: unknown,
): void {
  Sentry.withScope((scope: Sentry.Scope) => {
    scope.setExtra('payload', error)
    scope.setTransactionName(transactionName)
    Sentry.captureMessage(message, 'fatal')
  })
}
