<script lang="ts">
import { defineComponent, PropType } from 'vue'

import {
  AdditionalInfo,
  Confirmation,
  CricketIncidentId,
  LegalBallAttributesId,
} from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'
import {
  IncidentActionComposable,
  useIncidentAction,
} from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

import { useCricketConfiguration } from '../CricketRelationDependencies/useCricketConfiguration'
import { BallSummaryPopup } from '../Popups/BallSummary/BallSummaryPopup'
import { NoBallPopup } from '../Popups/NoBall/NoBallPopup'
import { RunsOffBallPopup } from '../Popups/RunsOffBall/RunsOffBallPopup'
import { WidePopup } from '../Popups/Wide/WidePopup'

export default defineComponent({
  components: { Button },
  props: {
    incidentActionComposable: {
      type: Object as PropType<IncidentActionComposable>,
      required: true,
    },
    staticLinkedProbableIncidents: {
      type: Array as PropType<ProbableLinkedIncident[]>,
      required: true,
    },
    context: {
      type: Object as PropType<PopupContext | null>,
      required: false,
    },
  },
  setup(props) {
    const eventInjections = useEventInjections()
    const { activeParticipant } = useCricketConfiguration()
    const probableIncident =
      props.incidentActionComposable.getProbableIncident()

    function proceed(): void {
      const currentAction =
        probableIncident.value.additional_info?.current_action ?? 1
      const additionalInfo = {
        ...probableIncident.value.additional_info,
        current_action: currentAction,
      }
      useIncidentAction(eventInjections, {
        incident_id: CricketIncidentId.Out,
        additional_info: additionalInfo,
        participant_team_id: activeParticipant.value.participant?.id,
      })

      switch (probableIncident.value.incident_id) {
        case CricketIncidentId.DismissalHandledTheBall:
          props.context?.openPopup(NoBallPopup)
          break
        case CricketIncidentId.DismissalStumped:
        case CricketIncidentId.DismissalHitWicket:
          props.context?.openPopup(WidePopup)
          break
        case CricketIncidentId.DismissalRunOut:
        case CricketIncidentId.DismissalObstructingTheField:
          props.context?.openPopup(RunsOffBallPopup)
          break
        default:
          useDefaultOutIncident(additionalInfo)
      }
    }

    function useDefaultOutIncident(additionalInfo: AdditionalInfo): void {
      const incidentActionComposable = useIncidentAction(eventInjections, {
        incident_id: CricketIncidentId.LegalBall,
        confirmation: Confirmation.ToBeDone,
        attribute_ids: [LegalBallAttributesId.Runs, LegalBallAttributesId.Zero],
        additional_info: additionalInfo,
        participant_team_id: activeParticipant.value.participant?.id,
      })

      props.context?.openPopup(BallSummaryPopup, {
        incidentActionComposable,
        staticLinkedProbableIncidents: props.staticLinkedProbableIncidents,
      })
    }

    return { proceed }
  },
})
</script>

<template>
  <Button
    :size="'sm'"
    :color="'white'"
    :withIcon="false"
    :type="'horizontal'"
    @click="proceed()"
  >
    Out
  </Button>
</template>
