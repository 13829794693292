<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    currentTab: {
      type: String,
      required: true,
    },
    tabs: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  emits: ['update:currentTab'],
  setup(props, context) {
    const firstIndex = 0
    const lastIndex = computed(() => props.tabs.length - 1)
    const setCurrentTab = (tabName: string): void =>
      context.emit('update:currentTab', tabName)
    const resolveTabClasses = (index: number, tab: string): string[] => {
      const classes: string[] = []

      if (index === firstIndex) {
        classes.push('rounded-tl rounded-bl')
      }

      if (index === lastIndex.value) {
        classes.push('rounded-tr rounded-br')
      }

      if (props.currentTab === tab) {
        classes.push('bg-blue', 'shadow')
      } else {
        classes.push('bg-darkblue')
      }

      return classes
    }

    return {
      setCurrentTab,
      resolveTabClasses,
      firstIndex,
      lastIndex,
    }
  },
})
</script>

<template>
  <div class="w-full">
    <div class="flex h-9">
      <div
        v-for="(tab, index) in tabs"
        :key="tab"
        class="flex w-full flex-1 cursor-pointer items-center justify-center text-sm font-bold text-white transition hover:shadow active:shadow"
        :class="resolveTabClasses(index, tab)"
        @click="setCurrentTab(tab)"
      >
        {{ tab }}
      </div>
    </div>

    <div>
      <slot :currentTab></slot>
    </div>
  </div>
</template>
