export const enum IceHockeyEventStatusId {
  NotStarted = 1,
  Interrupted = 2,
  Cancelled = 3,
  Postponed = 5,
  StartDelayed = 6,
  FirstPeriod = 41,
  BreakAfterFirstPeriod = 335,
  SecondPeriod = 43,
  BreakAfterSecondPeriod = 336,
  ThirdPeriod = 44,
  WaitingForOvertime = 48,
  FirstOvertime = 338,
  BreakAfterFirstOvertime = 339,
  SecondOvertime = 340,
  BreakAfterSecondOvertime = 341,
  ThirdOvertime = 342,
  BreakAfterThirdOvertime = 343,
  FourthOvertime = 344,
  PenaltyShootout = 141,
  Finished = 11,
  FinishedAfterOvertime = 140,
  FinishedAfterPenalties = 13,
  FinishedAwardedWin = 12,
  ToFinish = 152,
}
