<script lang="ts">
import { computed, defineComponent } from 'vue'

import {
  HandballIncidentId,
  Participant,
} from '@collector/sportsapi-client-legacy'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: { IncidentButton },
  setup() {
    const {
      probableFirstParticipant,
      probableSecondParticipant,
      probableIncidents,
    } = useEventInjections()
    const incidents = computed(() => {
      return probableIncidents.value.reduce<
        Array<ProbableIncident[] | ProbableIncident[]>
      >(
        ([emptyNetIncidents, goalKeeperBackIncidents], incident) => {
          if (incident.incident_id === HandballIncidentId.EmptyNet)
            emptyNetIncidents.push(incident)
          if (incident.incident_id === HandballIncidentId.GoalKeeperBack)
            goalKeeperBackIncidents.push(incident)

          return [emptyNetIncidents, goalKeeperBackIncidents]
        },
        [[], []],
      )
    })

    const isNetEmpty = computed<boolean>(() => {
      const [emptyNetIncidents, goalKeeperBackIncidents] = incidents.value

      return emptyNetIncidents.length > goalKeeperBackIncidents.length
    })

    function isButtonDisabledFor(participant: Participant): boolean {
      const [emptyNetIncidents] = incidents.value
      const lastEmptyNetIncident = emptyNetIncidents[0]

      return (
        isNetEmpty.value &&
        lastEmptyNetIncident.participant_id !== participant.id
      )
    }

    function getIncidentIdFor(participant: Participant): HandballIncidentId {
      const [emptyNetIncidents] = incidents.value
      const lastEmptyNetIncident = emptyNetIncidents[0]

      return isNetEmpty.value &&
        lastEmptyNetIncident.participant_id === participant.id
        ? HandballIncidentId.GoalKeeperBack
        : HandballIncidentId.EmptyNet
    }

    return {
      probableFirstParticipant,
      probableSecondParticipant,
      isButtonDisabledFor,
      getIncidentIdFor,
    }
  },
})
</script>

<template>
  <IncidentButton
    class="h-full"
    :type="'vertical'"
    :size="'xxs'"
    :participantId="probableFirstParticipant.id"
    :incidentId="getIncidentIdFor(probableFirstParticipant)"
    :disabled="isButtonDisabledFor(probableFirstParticipant)"
  />
  <IncidentButton
    class="h-full"
    :type="'vertical'"
    :size="'xxs'"
    :participantId="probableSecondParticipant.id"
    :incidentId="getIncidentIdFor(probableSecondParticipant)"
    :disabled="isButtonDisabledFor(probableSecondParticipant)"
  />
</template>
