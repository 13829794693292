import {
  Authorization,
  WebsocketMessageConfirmator,
} from '@collector/sportsapi-client-legacy'
import InvalidApplicationVersionAlert from '@mobile/components/Alert/InvalidApplicationVersionAlert.vue'
import { AppDatabase } from '@mobile/database/AppDatabase'
import { showAlert } from '@mobile/globalState/alert'
import * as scoutMessenger from '@mobile/globalState/scoutMessenger'
import * as sportsAPI from '@mobile/globalState/sportsAPI'
import { ScoutMessengerConnection } from '@mobile/ScoutMessengerConnection/ScoutMessengerConnection'

export async function getHighestPushMessageUt(
  database: AppDatabase,
): Promise<number> {
  const event = await database.events.getLast()
  const incident = await database.incidents.getLast()
  const error = await database.errors.getLast()
  const eventsScouts = await database.eventsScouts.getLast()

  const pushMessagesUts = [
    event?.pushMessageUt ?? 0,
    incident?.pushMessageUt ?? 0,
    error?.pushMessageUt ?? 0,
    eventsScouts?.pushMessageUt ?? 0,
  ]

  return Math.max(...pushMessagesUts)
}

async function connectToSocket(
  socket: ScoutMessengerConnection,
  token: string,
  database: AppDatabase,
): Promise<void> {
  if (!database.isOpen()) {
    socket.close()

    return
  }
  socket.connect(token, await getHighestPushMessageUt(database))
}

export function useScoutMessengerSocket(
  database: AppDatabase,
  authorization: Authorization,
): {
  socket: ScoutMessengerConnection
} {
  const messageConfirmator = new WebsocketMessageConfirmator(10000, 10000)
  const socket = new ScoutMessengerConnection()

  if (authorization.currentJWT) {
    connectToSocket(socket, authorization.currentJWT, database)
  }

  authorization.onNewToken(async (token) => {
    if (token !== authorization.currentJWT && !socket.isConnected()) {
      connectToSocket(socket, token, database)
    }
  })

  authorization.onTokenExpired(() => {
    socket.close()
  })

  database.events.on('anything', (event) => {
    messageConfirmator.receiveEvent(event)
  })

  database.lineups.on('anything', (lineup) => {
    messageConfirmator.receiveLineup(lineup)
  })

  database.incidents.on('anything', (incident) => {
    messageConfirmator.receiveIncident(incident)
  })

  database.errors.on('anything', (error) => {
    messageConfirmator.receiveError(error)
  })

  socket.on('connected', () => {
    scoutMessenger.setAsConnected()
  })

  socket.on('authorizationFailed', () => {
    socket.close()
    scoutMessenger.setAsNotConnected()
    authorization.authorize()
  })

  socket.on('disconnected', () => {
    scoutMessenger.setAsNotConnected()
  })

  socket.on('synchronizationStarted', () => {
    scoutMessenger.setInitialSynchronizationAsStarted()
  })

  socket.on('synchronizationFinished', () => {
    scoutMessenger.setInitialSynchronizationAsFinished()
  })

  socket.on('invalidScoutMessengerVersion', () => {
    showAlert(InvalidApplicationVersionAlert)
  })

  scoutMessenger.setConnection(socket)
  scoutMessenger.setMessageConfirmator(messageConfirmator)
  sportsAPI.state.client.setMessageConfirmator(messageConfirmator)

  return { socket }
}
