import mitt from 'mitt'
import { createApp } from 'vue'

import Sentry, {
  getDefaultIntegrations,
} from '@collector/shared-plugin-sentry-vue'
import { SportsApiEndpointsWithoutLoginPattern } from '@collector/sportsapi-types'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAlignJustify,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faBan,
  faBars,
  faBell,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronUp,
  faClipboardCheck,
  faClipboardList,
  faCode,
  faEdit,
  faExchangeAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faFileDownload,
  faGripLines,
  faHistory,
  faInfo,
  faInfoCircle,
  faLongArrowAltRight,
  faPaperPlane,
  faPause,
  faPlay,
  faQuestionCircle,
  faSave,
  faSearch,
  faShare,
  faSignOutAlt,
  faSort,
  faSpinner,
  faStopwatch,
  faSync,
  faThList,
  faThumbtack,
  faTimes,
  faTimesCircle,
  faTrash,
  faTshirt,
  faUserPlus,
  faUsers,
  faUserSecret,
  faVideo,
  faWifi,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { eventBus } from '@mobile/globalState/eventBus'
import { emitterInjectKey } from '@mobile/provide-inject'

import './registerServiceWorker'

import { useGestureDirective } from './reusables/useGestureDirective'
import router from './router'
import App from './views/App.vue'

// eslint-disable-next-line no-console
console.log(`appVersion = ${import.meta.env.VITE_RELEASE_NAME}`)

const emitter = mitt()

library.add(
  faClipboardList,
  faHistory,
  faLongArrowAltRight,
  faUserSecret,
  faQuestionCircle,
  faClipboardCheck,
  faAlignJustify,
  faTimes,
  faUsers,
  faTshirt,
  faChevronUp,
  faChevronDown,
  faSearch,
  faChevronLeft,
  faSave,
  faInfo,
  faUserPlus,
  faPause,
  faVideo,
  faSpinner,
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faWifi,
  faSort,
  faPaperPlane,
  faTimesCircle,
  faAngleDown,
  faExclamationCircle,
  faExclamationTriangle,
  faGripLines,
  faAngleUp,
  faSync,
  faInfoCircle,
  faExchangeAlt,
  faEdit,
  faBan,
  faStopwatch,
  faBars,
  faBell,
  faThList,
  faInfoCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faCode,
  faSignOutAlt,
  faCheck,
  faPlay,
  faCheckCircle,
  faShare,
  faFileDownload,
  faThumbtack,
  faTrash,
)

async function bootstrap(): Promise<void> {
  const app = createApp(App)
    .directive('gesture', useGestureDirective())
    .component('FontAwesomeIcon', FontAwesomeIcon)
    .provide(emitterInjectKey, emitter)

  let sameErrorEligibleTimeout = 0
  let sameErrorEligible = true
  let prevError: unknown

  app.config.errorHandler = (error, instance, info) => {
    // When component errors out in vue template, vue tends to throw same exception multiple times
    // To keep logs clean we ignore same consecutive errors when those errors are sent in same tick
    if (!sameErrorEligible && isSameError(error, prevError)) {
      return
    }

    prevError = error
    sameErrorEligible = false
    window.clearTimeout(sameErrorEligibleTimeout)
    sameErrorEligibleTimeout = window.setTimeout(() => {
      sameErrorEligible = true
    })

    /* eslint-disable-next-line no-console */
    console.error(error, instance, info)
    eventBus.emit('VueError', {
      error,
      instance,
      info,
    })

    Sentry.captureException(error)
  }

  Sentry.init({
    app,
    enabled: import.meta.env.VITE_SENTRY_ENABLED,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: import.meta.env.VITE_RELEASE_NAME,
    integrations: getDefaultIntegrations({
      router,
      failedRequestStatusCodes: [400, [402, 599]],
      failedRequestTargets: [SportsApiEndpointsWithoutLoginPattern],
      networkDetailAllowUrlsPattern: [SportsApiEndpointsWithoutLoginPattern],
    }),
    urlsWithTrackedPayload: SportsApiEndpointsWithoutLoginPattern,
  })

  app.use(router)
  app.mount('#app')
}

function isSameError(
  error: unknown,
  prevError: unknown,
): boolean | '' | undefined {
  return (
    error instanceof Error &&
    error.stack &&
    prevError instanceof Error &&
    prevError.stack &&
    error.stack === prevError.stack &&
    error.toString() === prevError.toString()
  )
}

bootstrap()
