import { DecisionReviewAttributesId } from './attributes/DecisionReviewAttributesId'
import { IllegalBallAttributesId } from './attributes/IllegalBallAttributesId'
import { LegalBallAttributesId } from './attributes/LegalBallAttributesId'
import { PenaltyRunsAttributesId } from './attributes/PenaltyRunsAttributesId'

export const LegalIllegalBallAttributesMap: {
  [key in
    | LegalBallAttributesId
    | IllegalBallAttributesId
    | PenaltyRunsAttributesId
    | DecisionReviewAttributesId]: string
} = {
  [LegalBallAttributesId.Runs]: '',
  [LegalBallAttributesId.Byes]: 'B',
  [LegalBallAttributesId.LegByes]: 'LB',
  [LegalBallAttributesId.Wicket]: 'W',
  [LegalBallAttributesId.Zero]: '0',
  [LegalBallAttributesId.One]: '1',
  [LegalBallAttributesId.Two]: '2',
  [LegalBallAttributesId.Three]: '3',
  [LegalBallAttributesId.Four]: '4',
  [LegalBallAttributesId.Five]: '5',
  [LegalBallAttributesId.Six]: '6',
  [LegalBallAttributesId.Seven]: '7',
  [LegalBallAttributesId.Eight]: '8',
  [LegalBallAttributesId.Nine]: '9',
  [LegalBallAttributesId.BoundaryFour]: '4',
  [LegalBallAttributesId.BoundarySix]: '6',
  [IllegalBallAttributesId.Wides]: 'WD',
  [IllegalBallAttributesId.NoBall]: 'NB',
  [IllegalBallAttributesId.NoBallBat]: 'NBB',
  [IllegalBallAttributesId.Wicket]: 'W',
  [IllegalBallAttributesId.Zero]: '0',
  [IllegalBallAttributesId.One]: '1',
  [IllegalBallAttributesId.Two]: '2',
  [IllegalBallAttributesId.Three]: '3',
  [IllegalBallAttributesId.Four]: '4',
  [IllegalBallAttributesId.Five]: '5',
  [IllegalBallAttributesId.Six]: '6',
  [IllegalBallAttributesId.Seven]: '7',
  [IllegalBallAttributesId.Eight]: '8',
  [IllegalBallAttributesId.Nine]: '9',
  [PenaltyRunsAttributesId.Five]: '5',
  [DecisionReviewAttributesId.Lost]: '',
  [DecisionReviewAttributesId.Retained]: '',
}
