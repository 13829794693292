import { Confirmation } from '../enums/Confirmation'
import { IncidentAttributeId } from '../enums/IncidentAttributeId'
import { IncidentId } from '../enums/IncidentId'
import { YesNoEnum } from '../enums/YesNoEnum'
import { IncidentActionType } from './IncidentActionType'

export type AdditionalInfo = Partial<{
  current_ball: number
  current_over: number
  current_action: number
  goalkeeper_id: number
  assistant_id: number | null
  assistant_name: string
  secondary_assistant_id: number | null
  secondary_assistant_name: string
}>

export interface Property {
  short_name: string
  value: string | number | null
}

export interface Incident {
  id: string
  action: IncidentActionType
  incident_id: IncidentId
  incident_name: string
  category: string
  display_info: string | null
  attribute_ids: IncidentAttributeId[]
  additional_info: AdditionalInfo
  confirmation: Confirmation | null
  participant_id: number | null
  participant_team_id: number | null // TODO: property doesn't exist in API response - remove
  participant_name: string | null
  subparticipant_id: number | null
  assistant_id: number | null
  assistant_name: string
  secondary_assistant_id?: number | null
  secondary_assistant_name?: string
  subparticipant_name: string
  info: string
  important: YesNoEnum
  important_for_trader: YesNoEnum
  add_data: unknown
  event_time: string | null
  event_status_id: number
  event_status_name: string
  game_break: YesNoEnum
  goalkeeper_id: number
  properties?: Property[]
  x_pos: number | null
  y_pos: number | null
  ct: number
  ut: number
}

export function cloneIncident(incident: Incident): Incident {
  return {
    id: incident.id,
    action: incident.action,
    incident_id: incident.incident_id,
    incident_name: incident.incident_name,
    category: incident.category,
    attribute_ids: [...incident.attribute_ids],
    additional_info: { ...(incident.additional_info || undefined) },
    confirmation: incident.confirmation,
    display_info: incident.display_info,
    participant_id: incident.participant_id,
    participant_name: incident.participant_name,
    participant_team_id: incident.participant_team_id,
    subparticipant_id: incident.subparticipant_id,
    subparticipant_name: incident.subparticipant_name,
    info: incident.info,
    important: incident.important,
    important_for_trader: incident.important_for_trader,
    add_data: incident.add_data,
    event_time: incident.event_time,
    event_status_id: incident.event_status_id,
    event_status_name: incident.event_status_name,
    game_break: incident.game_break,
    x_pos: incident.x_pos,
    y_pos: incident.y_pos,
    ct: incident.ct,
    ut: incident.ut,
    goalkeeper_id: incident.goalkeeper_id,
    assistant_id: incident.additional_info.assistant_id ?? null,
    assistant_name: incident.assistant_name,
    secondary_assistant_id:
      incident.additional_info.secondary_assistant_id ?? null,
    secondary_assistant_name: incident.secondary_assistant_name,
    properties: incident.properties || [],
  }
}
