<script lang="ts">
import { computed, defineComponent, ref } from 'vue'

import { YesNoEnum } from '@collector/sportsapi-client-legacy'
import Pagination from '@mobile/components/Pagination/Pagination.vue'
import Select from '@mobile/components/Select/Select.vue'
import ToggleSwitch from '@mobile/components/ToggleSwitch/ToggleSwitch.vue'
import * as popup from '@mobile/globalState/popup'
import PopupTitle from '@mobile/views/Relation/Shared/Popup/PopupTitle.vue'
import HistoryItem from '@mobile/views/Relation/Shared/Popups/History/HistoryItem/HistoryItem.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: {
    ToggleSwitch,
    Select,
    HistoryItem,
    PopupTitle,
    Pagination,
  },
  setup() {
    const { event, linkedProbableIncidents, sport } = useEventInjections()

    const incidentTypesOptions = sport.value.sportsAPIEntity.incidents
      .map((incident) => ({
        name: incident.name,
        value: incident.id,
      }))
      .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
    const incidentTypes = ref<number[]>([])

    const onlyImportant = ref(false)

    const filteredIncidents = computed(() => {
      let incidents = linkedProbableIncidents.value

      if (onlyImportant.value) {
        incidents = incidents.filter(
          /* eslint-disable-next-line eqeqeq */
          (incident) => incident.incident.important == YesNoEnum.Yes,
        )
      }

      if (incidentTypes.value.length > 0) {
        incidents = incidents.filter((incident) =>
          incidentTypes.value.includes(incident.incident.incident_id),
        )
      }

      return incidents
    })

    const currentPage = ref(1)
    const perPage = 10
    const filteredPaginatedIncidents = computed(() => {
      const startIndex = (currentPage.value - 1) * perPage
      const endIndex = startIndex + perPage

      return filteredIncidents.value.slice(startIndex, endIndex)
    })

    const hidePopup = popup.hide

    return {
      event,
      incidentTypesOptions,
      incidentTypes,
      onlyImportant,
      filteredIncidents,
      filteredPaginatedIncidents,
      currentPage,
      perPage,
      hidePopup,
    }
  },
})
</script>

<template>
  <div v-if="event">
    <PopupTitle>
      <template #title>History</template>

      <template #controls>
        <div class="flex h-10 max-h-10 w-full items-center justify-end">
          <div class="flex items-center">
            <ToggleSwitch v-model:value="onlyImportant">
              Only important
            </ToggleSwitch>
          </div>
          <div class="ml-10 flex items-center">
            <div class="text-gray mr-2 text-xs font-bold">Incident type</div>
            <Select
              v-model="incidentTypes"
              class="w-52"
              :options="incidentTypesOptions"
              mode="multiple"
              valueProp="value"
              label="name"
              placeholder="Any"
            />
          </div>
        </div>
      </template>
    </PopupTitle>

    <div class="space-y-1 px-3">
      <HistoryItem
        v-for="incident of filteredPaginatedIncidents"
        :key="incident.incident.id"
        :linkedIncident="incident"
      />
      <div
        v-if="filteredPaginatedIncidents.length === 0"
        class="text-center text-xl"
      >
        No incidents found
      </div>
      <Pagination
        v-model:currentPage="currentPage"
        class="items-center justify-center py-6"
        :perPage
        :total="filteredIncidents.length"
        :currentPageTotal="filteredPaginatedIncidents.length"
      />
    </div>
  </div>
</template>
