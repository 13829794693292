<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue'

import { Confirmation } from '@collector/sportsapi-client-legacy'
import Badge from '@mobile/components/Badge/Badge.vue'
import HeaderButton from '@mobile/components/HeaderButton/HeaderButton.vue'
import { eventBus } from '@mobile/globalState/eventBus'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

import { HeaderPopupKey } from '../HeaderPopupKey'

export default defineComponent({
  components: {
    HeaderButton,
    Badge,
  },
  emits: ['click'],
  setup() {
    const { linkedProbableIncidents } = useEventInjections()
    const numberOfUnconfirmedIncidents = computed(
      () =>
        linkedProbableIncidents.value.filter(
          ({ incident }) => incident.confirmation === Confirmation.ToBeDone,
        ).length,
    )
    const pulse = ref(false)
    const onAllIncidentsConfirmed = (): boolean => (pulse.value = false)

    const onQueueHasUnconfirmedIncidents = (payload: {
      triggeredByReminderIncident: boolean
    }): void => {
      pulse.value = payload.triggeredByReminderIncident
    }

    onMounted(() => {
      eventBus.on('AllIncidentsConfirmed', onAllIncidentsConfirmed)
      eventBus.on(
        'QueueHasUnconfirmedIncidents',
        onQueueHasUnconfirmedIncidents,
      )
    })
    onUnmounted(() => {
      eventBus.off('AllIncidentsConfirmed', onAllIncidentsConfirmed)
      eventBus.off(
        'QueueHasUnconfirmedIncidents',
        onQueueHasUnconfirmedIncidents,
      )
    })

    return {
      pulse,
      HeaderPopupKey,
      numberOfUnconfirmedIncidents,
    }
  },
})
</script>

<template>
  <HeaderButton
    v-bind="{ pulse }"
    @click="$emit('click', HeaderPopupKey.ConfirmIncidents)"
  >
    <FontAwesomeIcon icon="clipboard-check" />
    <Badge
      v-if="numberOfUnconfirmedIncidents > 0"
      class="absolute left-6 top-5"
    >
      {{ numberOfUnconfirmedIncidents }}
    </Badge>
  </HeaderButton>
</template>
