import { IncidentAttributeId } from '@collector/sportsapi-client-legacy'
import { ChoiceButtonGroupOption } from '@mobile/components/ChoiceButton/ChoiceButtonGroup.vue'
import { EventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export function useAttributesChoiceOptions(
  attributes: IncidentAttributeId[],
  eventInjections: EventInjections,
  withNoneOption?: boolean,
): ChoiceButtonGroupOption[] {
  const { sport } = eventInjections

  const attributesChoiceOptions = attributes.map((attribute) => ({
    name: sport.value.getAttributeTranslation(Number(attribute)),
    value: Number(attribute),
  }))

  return withNoneOption
    ? [
        ...attributesChoiceOptions,
        {
          name: 'None',
          value: 0,
        },
      ]
    : attributesChoiceOptions
}
