<script lang="ts">
import { computed, defineComponent, watch, watchEffect } from 'vue'

import Sentry from '@collector/shared-plugin-sentry-vue'
import BackgroundStadium from '@mobile/components/BackgroundStadium/BackgroundStadium.vue'
import Button from '@mobile/components/Button/Button.vue'
import InputText from '@mobile/components/InputText/InputText.vue'
import Logo from '@mobile/components/Logo/Logo.vue'
import LogoHorizontal from '@mobile/components/Logo/LogoHorizontal.vue'
import * as app from '@mobile/globalState/app'
import * as sportsAPI from '@mobile/globalState/sportsAPI'
import { useDeviceOrientation } from '@mobile/reusables/useDeviceOrientation'

import { useLoginFormVisibility } from './useLoginFormVisibility'
import { useScoutMessengerIndexedDBSynchronization } from './useScoutMessengerIndexedDBSynchronization'
import { useScoutMessengerSocket } from './useScoutMessengerSocket'
import { useSportsAPILoginForm } from './useSportsAPILoginForm'
import { useSportsSynchronizer } from './useSportsSynchronizer'

export default defineComponent({
  components: {
    InputText,
    Button,
    Logo,
    LogoHorizontal,
    BackgroundStadium,
  },
  setup() {
    const { isVisible } = useLoginFormVisibility()
    const { isPortrait } = useDeviceOrientation()
    const { email, password, login, authorization, error } =
      useSportsAPILoginForm()

    const showOverlay = computed(() => sportsAPI.state.isAuthorizing)

    watch(
      [() => sportsAPI.state.isClientReady, () => sportsAPI.state.authInfo],
      ([isClientReady, authInfo]) => {
        if (isClientReady && authInfo) {
          Sentry.setUser({ id: authInfo.id })
          const { socket } = useScoutMessengerSocket(
            app.state.database,
            authorization,
          )
          useScoutMessengerIndexedDBSynchronization(app.state.database, socket)
          const { sportsSynchronized } = useSportsSynchronizer(
            sportsAPI.state.client,
            app.state.database,
          )

          watchEffect(() => {
            if (!!sportsAPI.state.authInfo && sportsSynchronized.value) {
              app.setAppAsReady()
            }
          })
        }
      },
    )

    return {
      isVisible,
      isPortrait,
      showOverlay,
      email,
      password,
      login,
      error,
    }
  },
})
</script>

<template>
  <div
    v-if="isVisible"
    class="z-75 bg-neutralLight relative h-screen w-screen overflow-hidden"
  >
    <BackgroundStadium />
    <div
      class="absolute top-0 flex h-full w-full flex-col items-center justify-center py-8"
      :class="{ 'opacity-40': showOverlay }"
    >
      <div class="z-10 flex h-full w-full flex-col items-center justify-center">
        <div class="flex w-full justify-center">
          <Logo
            v-if="isPortrait"
            class="w-80"
          />
          <LogoHorizontal
            v-if="!isPortrait"
            class="w-80"
          />
        </div>
        <div class="text-red flex h-4 w-full flex-col items-center text-sm">
          {{ error }}
        </div>

        <div class="z-10 w-80">
          <div class="mt-1 text-xs">E-mail</div>
          <InputText
            v-model:value="email"
            type="email"
            data-test-id="EmailInput"
          />
        </div>

        <div class="z-10 w-80">
          <div class="mt-1 text-xs">Password</div>
          <InputText
            v-model:value="password"
            type="password"
            data-test-id="PasswordInput"
          />
        </div>

        <div class="z-10 w-80">
          <div class="mt-4 text-xs">
            <Button
              class="w-80 text-center font-bold"
              :color="'green'"
              :size="'sm'"
              data-test-id="LoginButton"
              @click="login()"
            >
              LOGIN
            </Button>
          </div>
        </div>
      </div>
      <div class="align-bottom">
        <img
          src="@mobile/assets/statscore.svg"
          alt="STATSCORE Logo"
        />
      </div>
    </div>

    <div
      v-if="showOverlay"
      class="absolute flex h-full w-full items-center justify-center text-lg font-bold uppercase"
    >
      <span class="bg-blue mr-4 h-10 w-10 animate-ping rounded-full"></span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.stadium {
  overflow: hidden;
  width: 980px;
  height: 620px;
}

.track {
  width: 100%;
  height: 100%;

  transform: translate(50%, 50%);
  border-radius: 200px;
}

.lane {
  position: absolute;
  width: 90%;
  height: 90%;
  transform: translate(-50%, -50%);
  border-radius: 200px;
}

.lane1 {
  width: 97%;
  height: 69%;
}

.lane2 {
  width: 91%;
  height: 60%;
}

.lane3 {
  width: 84%;
  height: 49%;
}

.lane4 {
  width: 77%;
  height: 39%;
}

.lane5 {
  width: 70%;
  height: 29%;
}
</style>
