import { get } from 'lodash'
import { toRef } from 'vue'

import {
  getIncidentAttributeId,
  GroupNames,
} from '@mobile/views/Relation/DetailsPanel/Sports/composables/useSportOptions'
import {
  DetailBaseConfigAttributes,
  DetailConfiguration,
  SupportedIncidentId,
} from '@mobile/views/Relation/DetailsPanel/Sports/composables/useSportOptions/types'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import {
  AssignableIncidentConfiguration,
  AssignableIncidentsConfiguration,
} from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/AssignableIncidentsConfiguration'

export function getEmptyIncidents(
  probableLinkedIncidents: ProbableLinkedIncident[],
  secondParticipantIncidents: SupportedIncidentId[],
  detailsConfiguration: Record<number, DetailConfiguration[]>,
  assignableIncidentsConfiguration: AssignableIncidentsConfiguration,
): ProbableLinkedIncident[] {
  return probableLinkedIncidents.filter((probableLinkedIncident) => {
    const incident = probableLinkedIncident.incident
    const assignableIncidentConfiguration = get(
      assignableIncidentsConfiguration,
      incident.incident_id,
      null,
    )
    const incidentDetailsConfiguration: DetailConfiguration[] =
      detailsConfiguration[incident.incident_id]

    if (incidentDetailsConfiguration) {
      const importantDetailsConditions = getImportantDetailsConditions(
        incidentDetailsConfiguration,
        probableLinkedIncident,
      )
      const detailsGoalkeeper = incidentDetailsConfiguration.find(
        (detail) => detail.name === GroupNames.Goalkeeper,
      )

      if (
        detailsGoalkeeper &&
        hasGoalkeeperAndNotEveryDetailsConditions(
          importantDetailsConditions,
          probableLinkedIncident,
          detailsGoalkeeper,
        )
      ) {
        return true
      }

      if (
        !detailsGoalkeeper &&
        hasNotGoalkeeperAndNotEveryDetailsConditions(importantDetailsConditions)
      ) {
        return true
      }

      if (importantDetailsConditions.length === 0) {
        return true
      }
    }

    if (!assignableIncidentConfiguration) {
      return false
    }

    if (
      hasFirstParticipant(
        assignableIncidentConfiguration,
        probableLinkedIncident,
      )
    ) {
      return true
    }

    if (
      hasParticipant(
        'secondary',
        'assistant_id',
        assignableIncidentConfiguration,
        probableLinkedIncident,
      )
    ) {
      return true
    }

    if (
      hasParticipant(
        'ternary',
        'secondary_assistant_id',
        assignableIncidentConfiguration,
        probableLinkedIncident,
      )
    ) {
      return true
    }

    return false
  })
}

function hasGoalkeeperAndNotEveryDetailsConditions(
  importantDetailsConditions: boolean[],
  probableLinkedIncident: ProbableLinkedIncident,
  detailConfiguration: DetailConfiguration,
): boolean {
  if (importantDetailsConditions.every((condition: boolean) => condition)) {
    return !detailConfiguration.fulfilledWhen(
      [],
      probableLinkedIncident.incident,
    )
  }

  return true
}

function hasNotGoalkeeperAndNotEveryDetailsConditions(
  importantDetailsConditions: boolean[],
): boolean {
  if (!importantDetailsConditions.every((condition) => condition)) {
    return true
  }

  return false
}

function hasFirstParticipant(
  assignableIncidentConfiguration: AssignableIncidentConfiguration | null,
  probableLinkedIncident: ProbableLinkedIncident,
): boolean {
  return (
    !!assignableIncidentConfiguration?.participants?.main &&
    !probableLinkedIncident.incident.subparticipant_id
  )
}

function hasParticipant(
  key: 'ternary' | 'secondary',
  incidentKey: 'assistant_id' | 'secondary_assistant_id',
  assignableIncidentConfiguration: AssignableIncidentConfiguration | null,
  probableLinkedIncident: ProbableLinkedIncident,
): boolean {
  const incident = toRef(probableLinkedIncident, 'incident')

  if (assignableIncidentConfiguration?.participants?.[key]) {
    const isParticipantAvailable =
      assignableIncidentConfiguration.participantsConfiguration?.[
        key
      ]?.availableWhen?.(incident) ?? true
    let isParticipantRequired = false

    if (
      assignableIncidentConfiguration.participantsConfiguration?.[key]
        ?.optional instanceof Function
    ) {
      isParticipantRequired =
        !assignableIncidentConfiguration.participantsConfiguration?.[
          key
        ]?.optional(probableLinkedIncident.incident)
    } else {
      isParticipantRequired =
        !assignableIncidentConfiguration.participantsConfiguration?.[key]
          ?.optional
    }

    return (
      isParticipantAvailable &&
      isParticipantRequired &&
      !incident.value[incidentKey]
    )
  } else {
    return false
  }
}

function getImportantDetailsConditions(
  incidentDetailsConfiguration: DetailConfiguration[],
  probableLinkedIncident: ProbableLinkedIncident,
): boolean[] {
  if (!incidentDetailsConfiguration) {
    return []
  }

  let propertyConditions: boolean[] = []

  if (probableLinkedIncident.incident.properties) {
    propertyConditions = incidentDetailsConfiguration.map((detail) => {
      if (detail.important) {
        return detail.fulfilledWhen(
          probableLinkedIncident.incident.attribute_ids,
          probableLinkedIncident.incident,
        )
      } else {
        return true
      }
    })
  }

  const attributeConditions = incidentDetailsConfiguration
    .filter(
      (detail) =>
        detail.important &&
        detail.name !== GroupNames.Goalkeeper &&
        'attributes' in detail &&
        detail.attributes.length,
    )
    .map((detail) =>
      (
        detail as DetailConfiguration & DetailBaseConfigAttributes
      ).attributes.map((attribute) =>
        getIncidentAttributeId(probableLinkedIncident, attribute),
      ),
    )
    .map((detailIds) =>
      probableLinkedIncident.incident.attribute_ids.some(
        (r) => detailIds.indexOf(r) >= 0,
      ),
    )

  return [...propertyConditions, ...attributeConditions]
}
