import { computed, onMounted, onUnmounted, ref, Ref } from 'vue'

export function clearableRef<T>(value: T, delay: number): Ref<T | null> {
  const reference = ref(value) as Ref<T | null>
  let timeout: number | null = null

  function startTimeout(): void {
    if (timeout) {
      window.clearTimeout(timeout)
    }

    timeout = window.setTimeout(() => {
      reference.value = null
    }, delay)
  }

  onMounted(startTimeout)

  onUnmounted(() => {
    if (timeout) {
      window.clearTimeout(timeout)
    }
  })

  return computed({
    get: () => reference.value,
    set: (value) => {
      reference.value = value
      startTimeout()
    },
  })
}
