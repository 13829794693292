import {
  BeachVolleyballResultId,
  BeachVolleyballStatId,
  CommonResultId,
} from '@collector/sportsapi-client-legacy'

import { ResultIdsSet } from '../../ParticipantsDataTable/ResultIdsSet'
import { StatIdsSet } from '../../ParticipantsDataTable/StatIdsSet'
import { VerifyResultsSportFactory } from './VerifyResultsSportFactory'

export function verifyResultsBeachVolleyballFactory(): VerifyResultsSportFactory {
  function getResultsIdsSet(): ResultIdsSet {
    return new ResultIdsSet([
      CommonResultId.Result,
      BeachVolleyballResultId.FirstSet,
      BeachVolleyballResultId.SecondSet,
      BeachVolleyballResultId.ThirdSet,
      BeachVolleyballResultId.GoldenSet,
    ])
  }

  function getStatIdsSet(): StatIdsSet {
    return new StatIdsSet([
      BeachVolleyballStatId.ServiceAces,
      BeachVolleyballStatId.ServiceErrors,
    ])
  }

  return {
    getResultsIdsSet,
    getStatIdsSet,
  }
}
