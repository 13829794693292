<script setup lang="ts">
import { computed } from 'vue'

import {
  CommonResultId,
  Participant,
  Result,
} from '@collector/sportsapi-client-legacy'
import { byId } from '@mobile/reusables/entityUtils'
import SummaryParticipant from '@mobile/views/Relation/DetailsPanel/components/Summary/SummaryParticipant.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

const {
  probableHomeParticipant,
  probableAwayParticipant,
  probableEvent,
  probableClock,
  sport,
} = useEventInjections()
const { eventStatusesConfiguration } = useSportConfiguration()
const statusName = computed(
  () => sport.value.statuses[probableEvent.value.status_id]?.name ?? 'N/A',
)
const mainResult = computed(
  () =>
    `${getResultValue(probableHomeParticipant.value)} : ${getResultValue(probableAwayParticipant.value)}`,
)
const showEventTime = computed(() => {
  const { clockHidden } = eventStatusesConfiguration[
    probableEvent.value.status_id
  ] ?? { clockHidden: false }

  return !clockHidden && probableClock.value.time !== null
})

function getResultValue(
  participant: Participant,
): NonNullable<Result['value']> {
  return byId(participant.results, CommonResultId.Result)?.value ?? '-'
}
</script>

<template>
  <div class="flex space-x-8 py-4">
    <div class="flex-1 space-y-4">
      <SummaryParticipant
        direction="right"
        :participant="probableHomeParticipant"
      />
    </div>
    <div class="flex flex-col justify-between space-y-2">
      <div class="text-nowrap text-center text-4xl font-bold">
        {{ mainResult }}
      </div>

      <div class="flex justify-center space-x-2">
        <div class="bg-darksilver flex rounded-md px-2 py-1">
          <div>{{ statusName }}</div>
          <div
            class="text-sky-500"
            :class="{ hidden: !showEventTime }"
          >
            , {{ probableClock.minuteFormatted }}:{{
              probableClock.secondFormatted
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="flex-1 space-y-4">
      <SummaryParticipant
        direction="left"
        :participant="probableAwayParticipant"
      />
    </div>
  </div>
</template>
