import * as app from '@mobile/globalState/app'
import { AppNotificationType } from '@mobile/reusables/types/AppNotificationType'
import { Notifications } from '@mobile/views/Relation/Shared/RelationDependencies/types'

import { Hook } from './Hook'

export class HookForceUpdate implements Hook {
  constructor(private readonly notifications: Notifications) {}

  install(): void {
    if (app.state.wasRecentlyUpdated) {
      this.notifications.sendNotification({
        type: AppNotificationType.Info,
        title: 'Application update',
        content:
          'Force update was successful. Your application is now up to date.',
      })

      localStorage.removeItem('appWasRecentlyUpdated')
    }
  }

  uninstall(): void {
    return
  }
}
