import { VolleyballIncidentId } from '@collector/sportsapi-client-legacy'
import { PopupBuilder } from '@mobile/views/Relation/Shared/Popup/builder/PopupBuilder'

import RefereeChallengePopupDetails from './RefereeChallengePopupDetails.vue'

export const RefereeChallengePopup = new PopupBuilder('Referee challenge', {
  incident_id: VolleyballIncidentId.RefereeChallenge,
})
  .appendBody(RefereeChallengePopupDetails)
  .build()
