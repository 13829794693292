<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { HandballIncidentId } from '@collector/sportsapi-client-legacy'
import ParticipantLogo from '@mobile/components/ParticipantLogo/ParticipantLogo.vue'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: {
    IncidentButton,
    ParticipantLogo,
  },
  props: {
    incidentId: {
      type: Number,
      required: true,
    },
    time: Object as PropType<{ minute: number; second: number }>,
  },
  setup() {
    const { probableAwayParticipant, probableHomeParticipant, sidesSwitched } =
      useEventInjections()

    return {
      probableAwayParticipant,
      probableHomeParticipant,
      HandballIncidentId,
      sidesSwitched,
    }
  },
})
</script>

<template>
  <div class="absolute left-1/2 h-full w-full -translate-x-1/2 transform">
    <div
      class="from-darkblue to-blue top-2 mx-auto w-56 bg-gradient-to-r py-1 text-center text-white"
    >
      <span class="relative top-0 block text-sm font-bold">
        Start with possession
      </span>
    </div>
    <div class="flex h-full items-center justify-center">
      <div
        class="max-h-3/4 relative mx-auto flex h-full w-3/4 transform flex-row items-center justify-between space-x-8"
        :class="{
          'flex-row-reverse space-x-reverse': sidesSwitched,
        }"
      >
        <IncidentButton
          class="bg-home h-3/4 w-1/2 self-start overflow-x-hidden overflow-ellipsis whitespace-nowrap rounded bg-opacity-80"
          type="vertical"
          :participantId="probableHomeParticipant.id"
          :incidentId="HandballIncidentId.StartWithPossession"
          :color="'home'"
          :size="'sm'"
          :withIcon="false"
        >
          <div class="m-auto flex flex-col items-center text-center">
            <ParticipantLogo
              class="h-20 w-20"
              :participant="probableHomeParticipant"
            />
            <span
              class="overflow-x-hidden overflow-ellipsis whitespace-nowrap text-center sm:w-20 lg:w-3/4"
            >
              {{ probableHomeParticipant.short_name }}
            </span>
          </div>
        </IncidentButton>

        <IncidentButton
          class="bg-away h-3/4 w-1/2 self-start overflow-x-hidden overflow-ellipsis whitespace-nowrap rounded bg-opacity-80"
          type="vertical"
          :participantId="probableAwayParticipant.id"
          :incidentId="HandballIncidentId.StartWithPossession"
          :color="'away'"
          :size="'sm'"
          :withIcon="false"
        >
          <div class="m-auto flex flex-col items-center text-center">
            <ParticipantLogo
              class="h-20 w-20"
              :participant="probableAwayParticipant"
            />
            <span
              class="overflow-x-hidden overflow-ellipsis whitespace-nowrap text-center sm:w-20 lg:w-3/4"
            >
              {{ probableAwayParticipant.short_name }}
            </span>
          </div>
        </IncidentButton>
      </div>
    </div>
  </div>
</template>
