<script lang="ts">
import { computed, defineComponent } from 'vue'

import { ActionStatus } from '@mobile/ActionQueue/Action'
import { ActionQueueStatus } from '@mobile/ActionQueue/ActionQueue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  setup() {
    const { actionQueue } = useEventInjections()

    const isQueueErrored = computed(() => {
      return (
        actionQueue.value.queue.length > 0 &&
        [ActionStatus.Errored, ActionStatus.Unknown].includes(
          actionQueue.value.queue[0].status,
        )
      )
    })
    const isQueueWorking = computed(() => {
      return actionQueue.value.status === ActionQueueStatus.Working
    })
    const isQueuePaused = computed(() => {
      return [ActionQueueStatus.Paused, ActionQueueStatus.Pausing].includes(
        actionQueue.value.status,
      )
    })

    return {
      isQueueErrored,
      isQueueWorking,
      isQueuePaused,
    }
  },
})
</script>

<template>
  <div class="flex flex-col items-center justify-center">
    <template v-if="isQueueErrored || isQueuePaused">
      <div class="mb-3">
        <FontAwesomeIcon
          class="text-yellow mx-auto text-3xl"
          icon="exclamation-triangle"
        />
      </div>
      <div class="text-yellow text-center font-bold">
        <span v-if="isQueueErrored">
          Resolve error in the queue to continue event verification process
        </span>
        <span v-else-if="isQueuePaused">
          Start the queue to continue event verification process
        </span>
      </div>
    </template>

    <template v-else>
      <div class="mb-3">
        <FontAwesomeIcon
          class="text-blue mx-auto animate-spin text-3xl"
          icon="spinner"
        />
      </div>
      <div class="text-blue text-center font-bold">
        Please wait until we process your changes
      </div>
    </template>
  </div>
</template>
