<script lang="ts">
import { defineComponent } from 'vue'

import { generateUniqueId } from '../../uniqueId'

export default defineComponent({
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  emits: ['update:value'],
  setup(props, context) {
    const contenteditable = import.meta.env.VITE_APP_MODE === 'e2e'
    const uniqueId: string = generateUniqueId()

    const onInput = (event: Event): void => {
      context.emit('update:value', (event.target as HTMLInputElement).value)
    }

    return {
      uniqueId,
      onInput,
      contenteditable,
    }
  },
})
</script>

<template>
  <div
    class="disabled:opacity-50"
    :disabled
    :contenteditable
  >
    <div class="flex flex-row">
      <label
        class="border-silver flex h-10 select-none items-center self-center rounded-bl rounded-tl border-b border-l border-t border-solid bg-white px-2"
        :for="uniqueId"
      >
        <slot name="prepend"></slot>
      </label>
      <input
        :id="uniqueId"
        class="border-silver h-10 w-full border-b border-t border-solid bg-white text-xs"
        :type
        :value
        :readonly="disabled"
        :placeholder
        @input="onInput"
      />
      <label
        class="border-silver flex h-10 select-none items-center self-center rounded-br rounded-tr border-b border-r border-t border-solid bg-white px-2"
        :for="uniqueId"
      >
        <slot name="append"></slot>
      </label>
    </div>
  </div>
</template>
