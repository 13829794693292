import { computed, Ref } from 'vue'

import { getOppositeTeam, getTeamSide } from '@mobile/reusables/entityUtils'
import router from '@mobile/router'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { CricketRelationIoCBindings } from '@mobile/views/Relation/Sports/Cricket/CricketRelationDependencies/CricketRelationDependencies'

import { ActiveParticipant } from './ActiveParticipant'

export function useCricketConfiguration(): {
  activeParticipant: Ref<ActiveParticipant>
  inactiveParticipant: Ref<ActiveParticipant>
} {
  const ioc = router.currentRoute.value.meta.ioc
  if (!ioc) {
    throw Error(
      'IoC Container has to be instantiated and injected into route meta before using cricket configuration',
    )
  }

  const activeParticipant = ioc.get(
    CricketRelationIoCBindings.ActiveParticipant,
  )
  const inactiveParticipant = getInactiveParticipant(activeParticipant)

  return {
    activeParticipant,
    inactiveParticipant,
  }
}

function getInactiveParticipant(
  activeParticipant: Ref<ActiveParticipant>,
): Ref<ActiveParticipant> {
  const { probableEvent } = useEventInjections()

  const inactiveParticipant = computed(() =>
    getOppositeTeam(
      probableEvent.value.participants,
      activeParticipant.value.participant!,
    ),
  )
  const inactiveParticipantSide = computed(() =>
    getTeamSide(
      probableEvent.value.participants,
      inactiveParticipant.value?.id ?? 0,
    ),
  )

  return computed(() => ({
    participant: inactiveParticipant.value ?? null,
    side: inactiveParticipantSide.value,
    color: inactiveParticipantSide.value,
  }))
}
