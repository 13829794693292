<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    undeselectable: {
      type: Boolean,
      default: false,
    },
    withCheckmark: {
      type: Boolean,
      default: true,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:value', 'toggle'],
  setup(props, context) {
    const toggle = (): void => {
      if (props.undeselectable && props.value) {
        return
      }

      const newValue = !props.value
      context.emit('update:value', newValue)
      context.emit('toggle', newValue)
    }

    return { toggle }
  },
})
</script>

<template>
  <button
    class="text-gray relative h-12 cursor-pointer rounded bg-white px-3 text-sm transition hover:shadow disabled:pointer-events-none disabled:opacity-50"
    :class="[{ shadow: value }, bordered ? 'border' : 'border-none']"
    @click="toggle()"
  >
    <div class="flex flex-row">
      <div class="flex w-full flex-1">
        <span
          class="absolute"
          :class="{ 'text-blue font-bold': value }"
        >
          <slot></slot>
        </span>

        <!-- Trick to make button stay same width after adding bold to the font -->
        <span class="invisible font-bold"><slot></slot></span>
      </div>
      <div
        v-if="withCheckmark"
        class="ml-9 flex max-w-sm items-center"
        :class="{ invisible: !value, 'text-blue': value }"
      >
        ✔
      </div>
    </div>
  </button>
</template>
