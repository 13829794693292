<script lang="ts">
import { Container } from 'inversify'
import { computed, defineComponent } from 'vue'

import { StatusType } from '@collector/sportsapi-client-legacy'
import EventHeader from '@mobile/views/Relation/Shared/EventHeader/EventHeader.vue'
import Popup from '@mobile/views/Relation/Shared/Popup/Popup.vue'
import { bindSeasonParticipants } from '@mobile/views/Relation/Shared/RelationDependencies/bindSeasonParticipants'
import { bindSkins } from '@mobile/views/Relation/Shared/RelationDependencies/bindSkins'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import VerifyResults from '@mobile/views/Relation/Shared/VerifyResults/VerifyResults.vue'
import LiveView from '@mobile/views/Relation/Sports/Volleyball/LiveView/LiveView.vue'
import { bindActiveParticipant } from '@mobile/views/Relation/Sports/Volleyball/VolleyballRelationDependencies/bindActiveParticipant'
import { bindAssignableIncidentConfiguration } from '@mobile/views/Relation/Sports/Volleyball/VolleyballRelationDependencies/bindAssignableIncidentConfiguration'
import { bindEventClockConfiguration } from '@mobile/views/Relation/Sports/Volleyball/VolleyballRelationDependencies/bindEventClockConfiguration'
import { bindEventHeaderConfiguration } from '@mobile/views/Relation/Sports/Volleyball/VolleyballRelationDependencies/bindEventHeaderConfiguration'
import { bindEventStatusesConfiguration } from '@mobile/views/Relation/Sports/Volleyball/VolleyballRelationDependencies/bindEventStatusesConfiguration'
import { bindEventStatusesList } from '@mobile/views/Relation/Sports/Volleyball/VolleyballRelationDependencies/bindEventStatusesList'
import { bindIncidentHandlersConfiguration } from '@mobile/views/Relation/Sports/Volleyball/VolleyballRelationDependencies/bindIncidentHandlersConfiguration'
import { bindIncidentsConfiguration } from '@mobile/views/Relation/Sports/Volleyball/VolleyballRelationDependencies/bindIncidentsConfiguration'
import { bindLineupsConfiguration } from '@mobile/views/Relation/Sports/Volleyball/VolleyballRelationDependencies/bindLineupsConfiguration'
import { bindRelationHooks } from '@mobile/views/Relation/Sports/Volleyball/VolleyballRelationDependencies/bindRelationHooks'
import { VolleyballRelationIoCBindings } from '@mobile/views/Relation/Sports/Volleyball/VolleyballRelationDependencies/VolleyballRelationDependencies'

export default defineComponent({
  components: {
    VerifyResults,
    EventHeader,
    LiveView,
    Popup,
  },
  setup() {
    const { probableEvent } = useEventInjections()

    const displayVerifyResults = computed(() => {
      const probableRelationStatus = probableEvent.value?.status_type

      return probableRelationStatus === StatusType.Finished
    })

    return { displayVerifyResults }
  },
})

export function bindVolleyballRelationDependencies(
  ioc: Container,
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
): Record<any, any> {
  bindEventStatusesConfiguration(ioc)
  bindIncidentHandlersConfiguration(ioc)
  bindEventStatusesList(ioc)
  bindIncidentsConfiguration(ioc)
  bindSeasonParticipants(ioc)
  bindLineupsConfiguration(ioc)
  bindAssignableIncidentConfiguration(ioc)
  bindActiveParticipant(ioc)
  bindRelationHooks(ioc)
  bindEventHeaderConfiguration(ioc)
  bindEventClockConfiguration(ioc)

  bindSkins(ioc)

  return VolleyballRelationIoCBindings
}
</script>

<template>
  <Popup />
  <EventHeader />
  <VerifyResults v-if="displayVerifyResults" />
  <LiveView v-else />
</template>
