import { UpdateSubParticipantDTO } from '@mobile/ActionQueue/Actions/Lineups/UpdateLineups'

import { AvailablePositionType } from '../RelationSportCommonDependencies/LineupsConfiguration'
import { useSportConfiguration } from '../RelationSportCommonDependencies/useSportConfiguration'
import { LineupsConfiguration } from './../RelationSportCommonDependencies/LineupsConfiguration'

export function useLineupMaximumPlayersReached(
  lineups: UpdateSubParticipantDTO[],
  availableType: AvailablePositionType,
): boolean {
  const { lineupsConfiguration } = useSportConfiguration()

  const [limitedPosition] =
    lineupsConfiguration.availablePositions.filter(
      (config) =>
        config.maximumPlayersAmount && config.name === availableType.name,
    ) ?? []
  if (!limitedPosition) {
    return false
  }
  const limitedPositionAssignedPlayers = lineups.filter((member) =>
    [
      getChildAvailablePositionType(
        lineupsConfiguration,
        availableType,
      )?.name.toLocaleLowerCase(),
      limitedPosition.name.toLowerCase(),
    ].includes(member.position?.toLowerCase()),
  )
  const maximumPlayersAmount = availableType.maximumPlayersAmount ?? 0

  return (
    isSelectedPositionLimited(availableType.name, limitedPosition.name) &&
    isPositionLimitReached(limitedPositionAssignedPlayers, maximumPlayersAmount)
  )
}

function isSelectedPositionLimited(
  limitedPosition: string,
  selectedPosition: string,
): boolean {
  return limitedPosition === selectedPosition
}

function isPositionLimitReached(
  limitedPositionAssignedPlayers: UpdateSubParticipantDTO[],
  maximumPlayersAmount: number,
): boolean {
  return limitedPositionAssignedPlayers.length >= maximumPlayersAmount
}

function getChildAvailablePositionType(
  lineupsConfiguration: LineupsConfiguration,
  availableType: AvailablePositionType,
): AvailablePositionType | null {
  return (
    lineupsConfiguration.availablePositions.find(
      (lineup) =>
        lineup.parentPositionTypeName?.toLowerCase() ===
        availableType.name.toLowerCase(),
    ) ?? null
  )
}
