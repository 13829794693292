<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({})
</script>

<template>
  <div class="rounded-md bg-white px-4 py-2 shadow-lg">
    <div class="py-5">
      <slot></slot>
    </div>

    <div class="flex w-full justify-center pb-2 pt-3">
      <slot name="controls"></slot>
    </div>
  </div>
</template>
