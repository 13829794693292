import { compact } from 'lodash'
import { Directive, DirectiveBinding } from 'vue'

export enum SwipeEvent {
  SwipeLeft = 'swipeleft',
  SwipeRight = 'swiperight',
  SwipeUp = 'swipeup',
  SwipeDown = 'swipedown',
}

export enum PressEvent {
  Tap = 'tap',
  Press = 'press',
  PressUp = 'pressup',
}

export enum PanEvent {
  PanStart = 'panstart',
}

type GestureEvent = SwipeEvent | PressEvent | PanEvent

type GestureManagerHandler = {
  eventName: GestureEvent
  handler: (event: TouchEvent | PointerEvent) => void
}

function isMobile(): boolean {
  return 'ontouchstart' in document.documentElement
}

interface CustomNode extends Node {
  gestureManager?: GestureManager
}

function isTouchEvent(event: TouchEvent | PointerEvent): event is TouchEvent {
  return (event as TouchEvent).changedTouches !== undefined
}

function isPointerEvent(
  event: TouchEvent | PointerEvent,
): event is PointerEvent {
  return (
    (event as PointerEvent).clientX !== undefined &&
    (event as PointerEvent).clientY !== undefined
  )
}

export function getEventCoords(event: TouchEvent | PointerEvent): {
  x: number
  y: number
} {
  if (isPointerEvent(event)) {
    const { clientX, clientY } = event

    return {
      x: clientX,
      y: clientY,
    }
  } else if (isTouchEvent(event)) {
    const { clientX, clientY } = event.changedTouches[0]

    return {
      x: clientX,
      y: clientY,
    }
  } else {
    return {
      x: 0,
      y: 0,
    }
  }
}

class GestureManager {
  private handlers: GestureManagerHandler[] = []

  private touchStartHandler: (event: TouchEvent | PointerEvent) => void
  private touchMoveHandler: (event: TouchEvent | PointerEvent) => void
  private touchEndHandler: (event: TouchEvent | PointerEvent) => void

  private swipeThreshold = 100
  private pressThreshold = 300

  private coordsStart: { x: number; y: number } = {
    x: 0,
    y: 0,
  }
  private coordsStop: { x: number; y: number } = {
    x: 0,
    y: 0,
  }
  private pressing = false
  private pressTimeout = 0
  private isPointerDown = false

  constructor(private element: CustomNode) {
    ;(this.element as HTMLElement).classList.add('noselect')

    this.touchStartHandler = this.handleTouchStart.bind(this)
    this.touchMoveHandler = this.handleTouchMove.bind(this)
    this.touchEndHandler = this.handleTouchEnd.bind(this)

    if (isMobile()) {
      this.element.addEventListener(
        'touchstart',
        this.touchStartHandler as EventListener,
      )
      this.element.addEventListener(
        'touchmove',
        this.touchMoveHandler as EventListener,
      )
      this.element.addEventListener(
        'touchend',
        this.touchEndHandler as EventListener,
      )
      this.element.addEventListener('contextmenu', this.handleContextMenu)
    } else {
      this.element.addEventListener(
        'pointerdown',
        this.touchStartHandler as EventListener,
      )
      this.element.addEventListener(
        'pointermove',
        this.touchMoveHandler as EventListener,
      )
      this.element.addEventListener(
        'pointerup',
        this.touchEndHandler as EventListener,
      )
      this.element.addEventListener('contextmenu', this.handleContextMenu)
    }
  }

  public on(
    eventName: GestureEvent,
    handler: (event: TouchEvent | PointerEvent) => void,
  ): void {
    this.handlers.push({
      eventName,
      handler,
    })
  }

  private getSwipeEvents(): (SwipeEvent | null)[] {
    const { abs } = Math
    const diffX = Number(this.coordsStart?.x) - Number(this.coordsStop?.x)
    const diffY = Number(this.coordsStart?.y) - Number(this.coordsStop?.y)

    const swipeEventHorizontal =
      diffX === 0 || abs(diffX) < this.swipeThreshold
        ? null
        : diffX < 0
          ? SwipeEvent.SwipeRight
          : SwipeEvent.SwipeLeft
    const swipeEventVertical =
      diffY === 0 || abs(diffY) < this.swipeThreshold
        ? null
        : diffY < 0
          ? SwipeEvent.SwipeDown
          : SwipeEvent.SwipeUp

    return [swipeEventHorizontal, swipeEventVertical]
  }

  private getPressEvent(): PressEvent | null {
    const xDiff = Math.abs(this.coordsStart.x - this.coordsStop.x)
    const yDiff = Math.abs(this.coordsStart.y - this.coordsStop.y)
    if (xDiff > 5 && yDiff > 5) {
      return null
    }

    return this.pressing ? PressEvent.PressUp : PressEvent.Tap
  }

  private handleContextMenu(event: Event): void {
    event.preventDefault()
  }

  private handleTouchStart(event: TouchEvent | PointerEvent): void {
    if (event.target === this.element) {
      event.preventDefault()
    }

    this.coordsStart = getEventCoords(event)
    this.coordsStop = getEventCoords(event)
    this.isPointerDown = true
    this.pressTimeout = window.setTimeout(() => {
      this.pressing = true
      this.executeHandlers([PressEvent.Press], event)
    }, this.pressThreshold)
  }

  private handleTouchEnd(event: TouchEvent | PointerEvent): void {
    if (event.target === this.element) {
      event.preventDefault()
    }

    this.coordsStop = getEventCoords(event)

    const swipeEvents = compact(this.getSwipeEvents())
    const pressEvents = swipeEvents.length ? [] : [this.getPressEvent()]

    const triggerableEvents = compact([...swipeEvents, ...pressEvents])

    this.executeHandlers(triggerableEvents, event)
    this.clear()

    this.isPointerDown = false
  }

  private handleTouchMove(event: TouchEvent | PointerEvent): void {
    if (event.target === this.element) {
      event.preventDefault()
    }

    if (this.isPointerDown || this.pressing) {
      return
    }

    this.clear()
    this.executeHandlers([PanEvent.PanStart], event)
  }

  private clear(): void {
    window.clearTimeout(this.pressTimeout)
    this.pressTimeout = 0
    this.pressing = false
  }

  private executeHandlers(
    eventNames: GestureEvent[],
    event: TouchEvent | PointerEvent,
  ): void {
    this.handlers.forEach((handler) => {
      const shouldTriggerHandler = eventNames.some(
        (event) => event === handler.eventName,
      )

      if (shouldTriggerHandler) {
        handler.handler(event)
      }
    })
  }

  public destroy(): void {
    ;(this.element as HTMLElement).classList.remove('noselect')

    this.element.removeEventListener(
      'touchstart',
      this.touchStartHandler as EventListener,
    )
    this.element.removeEventListener(
      'touchmove',
      this.touchMoveHandler as EventListener,
    )
    this.element.removeEventListener(
      'touchend',
      this.touchEndHandler as EventListener,
    )

    this.element.removeEventListener(
      'pointerdown',
      this.touchStartHandler as EventListener,
    )
    this.element.removeEventListener(
      'pointermove',
      this.touchMoveHandler as EventListener,
    )
    this.element.removeEventListener(
      'pointerup',
      this.touchEndHandler as EventListener,
    )

    this.element.removeEventListener('contextmenu', this.handleContextMenu)
  }
}

export function useGestureDirective(): Directive {
  return {
    beforeMount(element: CustomNode, binding: DirectiveBinding): void {
      if (!element.gestureManager) {
        element.gestureManager = new GestureManager(element)
      }

      element.gestureManager.on(binding.arg as GestureEvent, binding.value)
    },
    beforeUnmount(element: CustomNode): void {
      element.gestureManager?.destroy()
    },
  }
}
