import router from '@mobile/router'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'

import { RelationIoCBindings } from './types'

/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
export function useEventInjections() {
  const ioc = router.currentRoute.value.meta.ioc

  if (!ioc) {
    throw Error(
      'IoC Container has to be instantiated and injected into route meta before using event injections',
    )
  }

  const sport = ioc.get(RelationIoCBindings.Sport)
  const event = ioc.get(RelationIoCBindings.FactualEvent)
  const clock = ioc.get(RelationIoCBindings.FactualClock)
  const probableClock = ioc.get(RelationIoCBindings.ProbableClock)
  const incidents = ioc.get(RelationIoCBindings.FactualIncidents)
  const actionQueue = ioc.get(RelationIoCBindings.ActionQueue)
  const probableEvent = ioc.get(RelationIoCBindings.ProbableEvent)
  const probableIncidents = ioc.get(RelationIoCBindings.ProbableIncidents)
  const notDeletableProbableIncidents = ioc.get(
    RelationIoCBindings.ProbableNotDeletableIncidents,
  )
  const probableCurrentIncidents = ioc.get(
    RelationIoCBindings.ProbableCurrentIncidents,
  )
  const linkedProbableIncidents = ioc.get(
    RelationIoCBindings.ProbableLinkedIncidents,
  )
  const probableLinkedEvent = ioc.get(RelationIoCBindings.ProbableLinkedEvent)
  const probableLinkedHomeParticipant = ioc.get(
    RelationIoCBindings.ProbableLinkedHomeParticipant,
  )
  const probableLinkedAwayParticipant = ioc.get(
    RelationIoCBindings.ProbableLinkedAwayParticipant,
  )
  const getProbableLinkedParticipantById = (
    participantId: number,
  ): ProbableLinkedParticipant | undefined =>
    [probableLinkedHomeParticipant, probableLinkedAwayParticipant].find(
      (probableLinkedParticipant) =>
        probableLinkedParticipant.getParticipant().value.id === participantId,
    )
  const homeParticipant = ioc.get(RelationIoCBindings.FactualHomeParticipant)
  const awayParticipant = ioc.get(RelationIoCBindings.FactualAwayParticipant)
  const probableHomeParticipant = ioc.get(
    RelationIoCBindings.ProbableHomeParticipant,
  )
  const probableAwayParticipant = ioc.get(
    RelationIoCBindings.ProbableAwayParticipant,
  )
  const probableHomeLineups = ioc.get(RelationIoCBindings.ProbableHomeLineups)
  const probableAwayLineups = ioc.get(RelationIoCBindings.ProbableAwayLineups)
  const probableSelectableHomeLineups = ioc.get(
    RelationIoCBindings.ProbableSelectableHomeLineups,
  )
  const probableSelectableAwayLineups = ioc.get(
    RelationIoCBindings.ProbableSelectableAwayLineups,
  )
  const notifications = ioc.get(RelationIoCBindings.Notifications)
  const {
    sidesSwitched,
    probableLinkedFirstParticipant,
    probableFirstParticipant,
    probableLinkedSecondParticipant,
    probableSecondParticipant,
  } = ioc.get(RelationIoCBindings.SidesSwitched)
  const eventsScouts = ioc.get(RelationIoCBindings.EventsScouts)

  return {
    sport,
    event,
    clock,
    probableClock,
    incidents,
    actionQueue,
    probableEvent,
    probableIncidents,
    notDeletableProbableIncidents,
    probableCurrentIncidents,
    linkedProbableIncidents,
    probableLinkedEvent,
    homeParticipant,
    awayParticipant,
    probableHomeParticipant,
    probableAwayParticipant,
    probableLinkedHomeParticipant,
    probableLinkedAwayParticipant,
    getProbableLinkedParticipantById,
    probableHomeLineups,
    probableAwayLineups,
    probableSelectableHomeLineups,
    probableSelectableAwayLineups,
    probableLinkedFirstParticipant,
    probableFirstParticipant,
    probableLinkedSecondParticipant,
    probableSecondParticipant,
    sidesSwitched,
    notifications,
    eventsScouts,
  }
}

export type EventInjections = ReturnType<typeof useEventInjections>
