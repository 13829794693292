<script lang="ts">
import { defineComponent } from 'vue'

import Button from '@mobile/components/Button/Button.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: { Button },
  setup() {
    const { sidesSwitched } = useEventInjections()

    return { switchSides: () => (sidesSwitched.value = !sidesSwitched.value) }
  },
})
</script>

<template>
  <Button
    class="mt-2 block h-auto w-44 font-bold"
    :color="'blue'"
    :size="'lg'"
    @click="switchSides"
  >
    <FontAwesomeIcon
      class="text-md mr-2"
      icon="exchange-alt"
    />
    Change Sides
  </Button>
</template>
