import {
  Confirmation,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'
import { useCancellationButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useCancellationButton'
import { useCloseButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useCloseButton'
import { useConfirmationButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useConfirmationButton'
import { usePopupButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/usePopupButton'
import { PopupBuilder } from '@mobile/views/Relation/Shared/Popup/builder/PopupBuilder'
import SelectedParticipant from '@mobile/views/Relation/Shared/Popup/builder/SelectedParticipant.vue'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'

import { RedCardPopup } from '../RedCard/RedCardPopup'
import { VARPopup } from '../VAR/VARPopup'
import { YellowCardPopup } from '../YellowCard/YellowCardPopup'
import { ConfirmedPenaltyPopup } from './ConfirmedPenaltyPopup'

export const PenaltyPopup = new PopupBuilder('Penalty', {
  incident_id: SoccerIncidentId.Penalty,
  confirmation: Confirmation.ToBeDone,
})
  .withTeamSelection()
  .setNavigationButtons([
    usePopupButton({
      props: {
        text: 'VAR',
        color: 'blue',
        onClick: (context: PopupContext | null) => context?.openPopup(VARPopup),
        attributes: { 'data-cy': 'penaltyDetailsVARButton' },
      },
    }),
    usePopupButton({
      props: {
        text: 'Red card',
        color: 'blue',
        onClick: (context: PopupContext | null) =>
          context?.openPopup(RedCardPopup),
        attributes: { 'data-cy': 'penaltyDetailsRedCardButton' },
      },
    }),
    usePopupButton({
      props: {
        text: 'Yellow card',
        color: 'blue',
        onClick: (context: PopupContext | null) =>
          context?.openPopup(YellowCardPopup),
        attributes: { 'data-cy': 'penaltyDetailsYellowCardButton' },
      },
    }),
    useCloseButton(),
    useCancellationButton(),
    useConfirmationButton({
      onClick: (context: PopupContext | null) =>
        context?.openPopup(ConfirmedPenaltyPopup),
    }),
  ])
  .appendBody(SelectedParticipant)
  .build()
