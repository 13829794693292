import { Ref, ref } from 'vue'

import { ApiClient, SportId } from '@collector/sportsapi-client-legacy'
import { AppDatabase } from '@mobile/database/AppDatabase'
import * as app from '@mobile/globalState/app'

// @TODO Handle errors + compatibility with offline mode, save in vuex, synchronize with IndexedDB
export function useSportsSynchronizer(
  apiClient: ApiClient,
  database: AppDatabase,
): { sportsSynchronized: Ref<boolean> } {
  const sportsSynchronized = ref(false)
  const sportIds: SportId[] = [
    SportId.Soccer,
    SportId.Handball,
    SportId.Cricket,
    SportId.Volleyball,
    SportId.Basketball,
    SportId.BeachVolleyball,
    SportId.AmericanFootball,
    SportId.Basketball3x3,
    SportId.IceHockey,
  ]

  Promise.all(sportIds.map((sportId) => apiClient.getSport(sportId)))
    .then(async (sportResponses) => {
      for (const sportResponse of sportResponses) {
        const sport = sportResponse.api.data.sport

        await database.sports.put(sport)
        app.putSport(sport)
      }
    })
    .catch(async (error) => {
      /* eslint-disable-next-line no-console */
      console.error(error)

      for (const sportId of sportIds) {
        const sport = await database.sports.getBySportId(sportId)

        if (sport) {
          app.putSport(sport)
        }
      }
    })
    .finally(() => {
      sportsSynchronized.value = true
    })

  return { sportsSynchronized }
}
