export enum CricketIncidentId {
  FinishedRegularTime = 281,
  NotStarted = 801,
  Interrupted = 802,
  Cancelled = 803,
  Postponed = 805,
  StartDelayed = 806,
  Abandoned = 807,
  Live = 818,
  AfterDayOne = 819,
  AfterDayTwo = 820,
  AfterDayThree = 821,
  AfterDayFour = 822,
  ToFinish = 830,
  FirstInningsHomeStarted = 1701,
  FirstInningsAwayStarted = 1702,
  SuperOverHomeStarted = 1705,
  SuperOverAwayStarted = 1706,
  InningsBreak = 1708,
  SuperOverInningsBreak = 1713,
  Finished = 1715,
  FinishedAfterSuperOver = 1716,
  RetiredHurt = 1717,
  BowlerRunningIn = 1756,
  NoRuns = 1759,
  Runs = 1760,
  PossibleBoundary = 1767,
  BoundaryFourRuns = 1768,
  BoundarySixRuns = 1769,
  PossibleWicket = 1770,
  DeadBall = 1773,
  FreeHit = 1774,
  OneShort = 1775,
  ExtrasWides = 1776,
  ExtrasPenaltyRuns = 1781,
  BatsmanComingIn = 1784,
  DecisionReview = 1783,
  BowlerChange = 1785,
  BatsmanRetired = 1786,
  DuckworthLewisMethods = 1788,
  EndOfOver = 1791,
  StartOfOver = 1792,
  MatchAboutToStart = 2739,
  ExtrasNoBall = 2740,
  ExtrasNoBallBat = 2741,
  ExtrasLegByes = 2742,
  ExtrasByes = 2743,
  DismissalLBW = 2744,
  DismissalCaughtDisc = 2745,
  DismissalBowled = 2746,
  DismissalRunOut = 2747,
  DismissalStumped = 2748,
  DismissalHitWicket = 2749,
  DismissalHandledTheBall = 2750,
  DismissalObstructingTheField = 2751,
  DismissalHitTheBallTwice = 2752,
  TossWon = 2759,
  BattingTeam = 2760,
  LegalBall = 2755,
  IllegalBall = 2756,
  Out = 2754,
  TimedOut = 2757,
  Penalty = 2758,
  BreakInPlay = 2761,
  Extras = 9999,
}
