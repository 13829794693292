<script lang="ts">
import { computed, defineComponent } from 'vue'

import Badge from '@mobile/components/Badge/Badge.vue'
import HeaderButton from '@mobile/components/HeaderButton/HeaderButton.vue'

import { useEventInjections } from '../../RelationDependencies/useEventInjections'
import { HeaderPopupKey } from '../HeaderPopupKey'

export default defineComponent({
  components: {
    Badge,
    HeaderButton,
  },
  emits: ['click'],
  setup() {
    const { notifications } = useEventInjections()
    const notReadNotificationsCount = computed(
      () =>
        notifications.filteredNotifications.value.filter(
          (notification) => !notification.read,
        ).length,
    )

    return {
      notReadNotificationsCount,
      HeaderPopupKey,
    }
  },
})
</script>

<template>
  <HeaderButton @click="$emit('click', HeaderPopupKey.Notifications)">
    <FontAwesomeIcon icon="bell" />
    <Badge
      v-if="notReadNotificationsCount"
      class="absolute left-6 top-5"
    >
      {{ notReadNotificationsCount }}
    </Badge>
  </HeaderButton>
</template>
