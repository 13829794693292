<script lang="ts">
import { cloneDeep, sortBy } from 'lodash'
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  Ref,
  ref,
  watch,
} from 'vue'

import { opChain } from '@collector/shared-utils'
import {
  DetailType,
  EventsLineupsParticipant,
  SubParticipantPosition,
} from '@collector/sportsapi-client-legacy'
import UpdateIncident from '@mobile/ActionQueue/Actions/Incidents/UpdateIncident'
import Button from '@mobile/components/Button/Button.vue'
import Select from '@mobile/components/Select/Select.vue'
import { getOppositeTeam, getTeamSide } from '@mobile/reusables/entityUtils'
import {
  enforceMinMax,
  getIncidentAttributeId,
  getPropertyIndex,
  GroupNames,
  useSportOptions,
} from '@mobile/views/Relation/DetailsPanel/Sports/composables/useSportOptions'
import {
  DetailButtonsConfiguration,
  DetailConfiguration,
  DetailSelectConfiguration,
  ListOption,
} from '@mobile/views/Relation/DetailsPanel/Sports/composables/useSportOptions/types'
import { useAttributesSelection } from '@mobile/views/Relation/DetailsPanel/Sports/useAttributesSelection'
import { getFilteredSelectOptions } from '@mobile/views/Relation/DetailsPanel/Table/partcipantsSearchFilter'
import { useLineupsSelectOptions } from '@mobile/views/Relation/Shared/Lineups/useLineupsSelectOption'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

import Modal from './Modal.vue'
import ModalButtons from './ModalButtons.vue'
import ModalTitle from './ModalTitle.vue'

export default defineComponent({
  components: {
    Button,
    Modal,
    ModalTitle,
    ModalButtons,
    Select,
  },
  props: {
    linkedIncident: {
      type: Object as PropType<ProbableLinkedIncident>,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { actionQueue, probableEvent, sport, event } = useEventInjections()
    const { id: event_id } = probableEvent.value
    const participantSearchValue: Ref<string> = ref('')
    const { detailsConfiguration } = useSportOptions(sport)
    const { assignableIncidentsConfiguration } = useSportConfiguration()
    const oppositeTeamSide = computed(() => {
      if (props.linkedIncident.participant) {
        return getTeamSide(
          event.value.data.participants,
          getOppositeTeam(
            event.value.data.participants,
            props.linkedIncident.participant,
          )?.id ?? 0,
        )
      }

      return undefined
    })
    const assignableIncidentConfiguration = props.linkedIncident.incident
      ?.incident_id
      ? assignableIncidentsConfiguration[
          props.linkedIncident.incident.incident_id
        ]
      : null
    const shouldLoadOppositeTeam =
      props.linkedIncident.incident.attribute_ids?.some(
        (attribute) =>
          !!assignableIncidentConfiguration?.attributesConfiguration?.[
            attribute
          ]?.loadOppositeTeamWhenSelected,
      )
    const teamSide = computed(() => props.linkedIncident.side || undefined)
    const { oppositeLineupsParticipants } = useLineupsSelectOptions(
      ref(props.linkedIncident.incident),
      shouldLoadOppositeTeam ? oppositeTeamSide.value : teamSide.value,
    )
    const selectedGoalkeeper = ref(
      props.linkedIncident.incident.additional_info?.goalkeeper_id,
    )
    const selectedAttributes = ref([
      ...props.linkedIncident.incident.attribute_ids,
    ])
    const incidentDetailsConfiguration: Ref<DetailConfiguration[]> = computed(
      () => detailsConfiguration[props.linkedIncident.incident.incident_id],
    )
    const inputValues = ref(cloneDeep(props.linkedIncident.incident.properties))

    watch(props.linkedIncident.incident, (incident) => {
      inputValues.value = incident.properties
    })

    onMounted(() => {
      const detailTypeInput = incidentDetailsConfiguration.value.find(
        (detail) => detail.type === DetailType.Input,
      )

      if (!inputValues.value?.length && detailTypeInput) {
        inputValues.value?.push({
          short_name: detailTypeInput?.name || '',
          value: null,
        })
      }
    })

    const time = computed(() => {
      const [minute, second] = props.linkedIncident.incident.event_time?.split(
        ':',
      ) ?? ['00', '00']

      return {
        minute,
        second,
      }
    })

    const showGoalkeepeerSelect = computed(() =>
      incidentDetailsConfiguration.value.some(
        (detail) => detail.name === GroupNames.Goalkeeper,
      ),
    )

    const filteredSelectOptions = computed(() =>
      getFilteredSelectOptions(
        sortParticipantsByGoalkeeper(oppositeLineupsParticipants.value),
        participantSearchValue.value,
      ),
    )

    function sortParticipantsByGoalkeeper(
      participants: EventsLineupsParticipant[],
    ): EventsLineupsParticipant[] {
      return sortBy(participants, [
        (participant) => participant.participant_name !== 'None',
        (participant) =>
          participant.participant_position !==
          SubParticipantPosition.Goalkeeper,
        (participant) => participant.participant_shirt_number,
      ])
    }

    function onParticipantSearchChange(value: string): void {
      participantSearchValue.value = value
    }

    function isSelectedAttribute(attributeId: number): boolean {
      return selectedAttributes.value.includes(attributeId)
    }

    function isAttributeDisabled(name: string): boolean {
      const activeWith = incidentDetailsConfiguration.value
        .filter(
          (config) =>
            config.type === DetailType.Button &&
            config.activeWhen &&
            config.attributes.some((attributeName) => attributeName === name),
        )
        .map((x) => x.activeWhen && x.activeWhen(selectedAttributes.value))

      if (!activeWith || activeWith.length === 0) return false

      return activeWith.some((x) => x === false)
    }

    function clearAttributesGroup(
      config: DetailButtonsConfiguration | DetailSelectConfiguration,
    ): void {
      config.attributes.forEach((attribute) => {
        const attributeId = getIncidentAttributeId(
          props.linkedIncident,
          attribute,
        )
        const attributeIndex = selectedAttributes.value.indexOf(attributeId)

        if (attributeIndex !== -1) {
          selectedAttributes.value.splice(attributeIndex, 1)
        }
      })
    }

    function addAttribute(
      linkedIncident: ProbableLinkedIncident,
      attributeId: number,
      config: DetailButtonsConfiguration | DetailSelectConfiguration,
    ): void {
      const configuration = incidentDetailsConfiguration.value.find(
        (x) => x.activeWith === attributeId,
      ) as DetailButtonsConfiguration | undefined
      const attributesIds = (configuration?.attributes || []).map((a) =>
        getIncidentAttributeId(linkedIncident, a),
      )
      const {
        isOnlyOneAttributeSelected,
        isAttributeSelected,
        filteredAttributes,
      } = useAttributesSelection(
        linkedIncident,
        attributeId,
        config.attributes,
        selectedAttributes.value,
        attributesIds,
      )

      if (config.multiple) {
        selectedAttributes.value = selectedAttributes.value.includes(
          attributeId,
        )
          ? selectedAttributes.value.filter(
              (element) => element !== attributeId,
            )
          : [...selectedAttributes.value, attributeId]
      } else {
        // TODO: refactor this on next refactoring task
        if (config.type === DetailType.Button) {
          if (isOnlyOneAttributeSelected) {
            if (isAttributeSelected) {
              selectedAttributes.value = filteredAttributes
            }
          } else if (!isAttributeSelected) {
            selectedAttributes.value.push(attributeId)
          } else {
            selectedAttributes.value = filteredAttributes
          }
        } else {
          clearAttributesGroup(config)

          selectedAttributes.value.push(attributeId)
        }
      }
    }

    function getSelectDetailGroupValue(
      options: ListOption[],
    ): number | undefined {
      return selectedAttributes.value.find((attributeId) =>
        options.some(
          (option) =>
            getIncidentAttributeId(props.linkedIncident, option.name) ===
            attributeId,
        ),
      )
    }

    function updateIncident(): void {
      actionQueue.value.add(
        new UpdateIncident(props.linkedIncident.incident.id, {
          incident_id: props.linkedIncident.incident.incident_id,
          event_id,
          status_id: props.linkedIncident.incident.event_status_id,
          attribute_ids: selectedAttributes.value,
          participant_team_id: props.linkedIncident.incident.participant_id,
          participant_id: props.linkedIncident.incident.subparticipant_id,
          participant_name: props.linkedIncident.incident.subparticipant_id
            ? props.linkedIncident.incident.subparticipant_name
            : '',
          assistant_id:
            props.linkedIncident.incident.additional_info?.assistant_id,
          assistant_name:
            props.linkedIncident.incident.additional_info?.assistant_name,
          secondary_assistant_id:
            props.linkedIncident.incident.additional_info
              ?.secondary_assistant_id,
          secondary_assistant_name:
            props.linkedIncident.incident.additional_info
              ?.secondary_assistant_name,
          goalkeeper_id: selectedGoalkeeper.value ?? undefined,
          properties: inputValues.value?.map((p) => ({
            short_name: p.short_name.toLowerCase(),
            value: p.value?.toLocaleString().length ? p.value : null,
          })),
          minute: Number(time.value.minute),
          second: Number(time.value.second),
        }),
      )
      emit('close')
    }

    return {
      selectedAttributes,
      clearAttributesGroup,
      updateIncident,
      time,
      incidentDetailsConfiguration,
      isAttributeDisabled,
      getIncidentAttributeId,
      addAttribute,
      isSelectedAttribute,
      selectedGoalkeeper,
      showGoalkeepeerSelect,
      onParticipantSearchChange,
      filteredSelectOptions,
      DetailType,
      getPropertyIndex,
      inputValues,
      enforceMinMax,
      getSelectDetailGroupValue,
      opChain,
    }
  },
})
</script>

<template>
  <Modal
    id="incidentDetailsModal"
    @close="$emit('close')"
  >
    <ModalTitle title="Incident details" />
    <div
      class="bg-lightgray border-gray mb-3 rounded border border-solid px-3 py-3"
    >
      <span class="pr-4">{{ time.minute }}:{{ time.second }}</span>
      <span class="pr-4">{{ linkedIncident.incident.event_status_name }}</span>
      <span class="pr-1 font-bold">
        {{ linkedIncident.incident.participant_name }}
      </span>
      <span class="pr-1 font-bold">-</span>
      <span class="pr-1 font-bold">
        {{ linkedIncident.incident.incident_name }}
      </span>
    </div>
    <div class="flex flex-col justify-between">
      <div
        v-for="(config, index) in incidentDetailsConfiguration"
        :key="index"
      >
        <div class="pb-2 text-center font-bold">
          {{ config.name }}
        </div>
        <div v-if="config.type === DetailType.Input">
          <input
            v-if="
              inputValues &&
              opChain(
                inputValues,
                (v) => v[getPropertyIndex(inputValues, config.name)],
              )
            "
            v-model="
              inputValues[getPropertyIndex(inputValues, config.name)].value
            "
            class="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
            type="number"
            :placeholder="config.placeholder"
            :min="config.min"
            :max="config.max"
            @keyup="
              enforceMinMax(
                inputValues,
                linkedIncident.incident.properties,
                config,
              )
            "
          />
        </div>
        <div v-if="config.type === DetailType.Select">
          <Select
            :value="
              getSelectDetailGroupValue(config.optionsFactory(linkedIncident))
            "
            :options="[
              { name: 'Not selected', value: null },
              ...config.optionsFactory(linkedIncident),
            ]"
            label="name"
            :mode="'single'"
            @update:value="
              $event
                ? addAttribute(linkedIncident, $event, config)
                : clearAttributesGroup(config)
            "
          />
        </div>
        <div
          v-if="config.type === DetailType.Button"
          :class="`grid gap-2 grid-cols-${config.cols} mb-3`"
        >
          <Button
            v-for="attribute in config.attributes"
            :key="attribute"
            :color="
              isSelectedAttribute(
                getIncidentAttributeId(linkedIncident, attribute),
              )
                ? 'blue'
                : 'white'
            "
            :size="'lg'"
            :disabled="isAttributeDisabled(attribute)"
            @click="
              addAttribute(
                linkedIncident,
                getIncidentAttributeId(linkedIncident, attribute),
                config,
              )
            "
          >
            {{ attribute }}
          </Button>
        </div>
      </div>
      <div v-if="showGoalkeepeerSelect">
        <Select
          v-model="selectedGoalkeeper"
          class="flex-1"
          label="name"
          :mode="'single'"
          :options="filteredSelectOptions"
          searchable
          trackBy="name"
          @searchChange="onParticipantSearchChange"
        />
      </div>
      <ModalButtons>
        <Button
          class="mr-2"
          color="red"
          :size="'lg'"
          @click="$emit('close')"
        >
          Close
        </Button>
        <Button
          class="px-8"
          color="green"
          :size="'lg'"
          @click="updateIncident"
        >
          Save
        </Button>
      </ModalButtons>
    </div>
  </Modal>
</template>
