<script lang="ts">
import { cloneDeep } from 'lodash'
import { computed, defineComponent, onBeforeUnmount, ref } from 'vue'

import {
  CommonResultId,
  ResultObject,
  YesNoEnum,
} from '@collector/sportsapi-client-legacy'
import { UpdateEventParticipants } from '@mobile/ActionQueue/Actions/Actions'
import UpdateEvent from '@mobile/ActionQueue/Actions/Event/UpdateEvent'
import Button from '@mobile/components/Button/Button.vue'
import ToggleButtonGroup, {
  ToggleButtonGroupOption,
} from '@mobile/components/ToggleButton/ToggleButtonGroup.vue'
import { participantDiff } from '@mobile/reusables/eventUtils'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import ParticipantsDataTable from '@mobile/views/Relation/Shared/VerifyResults/ParticipantsDataTable/ParticipantsDataTable.vue'

import { useVerifyResultsFactory } from '../factories/VerifyResultsFactory/useVerifyResultsFactory'

enum WinnerValue {
  Home = 'home',
  Draw = 'draw',
  Away = 'away',
}

enum ProgressValue {
  Home = 'home',
  Away = 'away',
  NotApplicable = 'not_applicable',
}

export default defineComponent({
  components: {
    ParticipantsDataTable,
    Button,
    ToggleButtonGroup,
  },
  setup() {
    const {
      actionQueue,
      probableEvent,
      homeParticipant,
      probableLinkedHomeParticipant,
      awayParticipant,
      probableLinkedAwayParticipant,
      probableHomeParticipant,
      probableAwayParticipant,
    } = useEventInjections()

    const isVerified = computed(
      () => probableEvent.value.verified_result === YesNoEnum.Yes,
    )
    const staticHomeParticipant = cloneDeep(probableHomeParticipant.value)
    const staticAwayParticipant = cloneDeep(probableAwayParticipant.value)

    const verifyResultsFactory = useVerifyResultsFactory(
      probableEvent.value.sport_id,
    )

    function resolveWinnerAndProgressUpdateAction(): UpdateEventParticipants {
      const homeWinnerResult: ResultObject = {
        id: CommonResultId.Winner,
        value: 0,
      }
      const awayWinnerResult: ResultObject = {
        id: CommonResultId.Winner,
        value: 0,
      }
      if (winnerValue.value === WinnerValue.Home) {
        homeWinnerResult.value = 1
      } else if (winnerValue.value === WinnerValue.Away) {
        awayWinnerResult.value = 1
      }

      const homeProgressResult: ResultObject = {
        id: CommonResultId.Progress,
        value: 0,
      }
      const awayProgressResult: ResultObject = {
        id: CommonResultId.Progress,
        value: 0,
      }
      if (progressValue.value === ProgressValue.Home) {
        homeProgressResult.value = 1
      } else if (progressValue.value === ProgressValue.Away) {
        awayProgressResult.value = 1
      }

      staticHomeParticipant.results = [homeWinnerResult, homeProgressResult]
      staticAwayParticipant.results = [awayWinnerResult, awayProgressResult]

      return new UpdateEventParticipants({
        data: [
          participantDiff(probableHomeParticipant.value, staticHomeParticipant),
          participantDiff(probableAwayParticipant.value, staticAwayParticipant),
        ],
      })
    }
    function verifyEvent(): void {
      actionQueue.value.add(resolveWinnerAndProgressUpdateAction())
      actionQueue.value.add(
        new UpdateEvent({
          event: { is_result_verified: YesNoEnum.Yes },
          details: {},
        }),
      )
    }

    const verificationForm = ref<HTMLElement | null>(null)
    function onScroll(): void {
      window.requestAnimationFrame(() => {
        if (verificationForm.value) {
          verificationForm.value.style.transform = `translateY(${window.scrollY}px)`
        }
      })
    }
    window.document.addEventListener('scroll', onScroll)
    onBeforeUnmount(() => {
      window.document.removeEventListener('scroll', onScroll)
    })

    const winnerValue = ref<WinnerValue | null>(null)
    const winnerOptions = ref<ToggleButtonGroupOption[]>([
      {
        name: homeParticipant.value.acronym,
        value: WinnerValue.Home,
        participant: probableLinkedHomeParticipant,
      },
      {
        name: 'Draw',
        value: WinnerValue.Draw,
      },
      {
        name: awayParticipant.value.acronym,
        value: WinnerValue.Away,
        participant: probableLinkedAwayParticipant,
      },
    ])

    const progressValue = ref<ProgressValue | null>(null)
    const progressOptions = ref<ToggleButtonGroupOption[]>([
      {
        name: homeParticipant.value.acronym,
        value: ProgressValue.Home,
        participant: probableLinkedHomeParticipant,
      },
      {
        name: 'N/A',
        value: ProgressValue.NotApplicable,
      },
      {
        name: awayParticipant.value.acronym,
        value: ProgressValue.Away,
        participant: probableLinkedAwayParticipant,
      },
    ])

    const isFormFilled = computed(
      () => winnerValue.value && progressValue.value,
    )

    const resultIds = verifyResultsFactory.getResultsIdsSet()
    const statIds = verifyResultsFactory.getStatIdsSet()

    return {
      verificationForm,
      verifyEvent,
      resultIds,
      statIds,
      isVerified,
      winnerValue,
      winnerOptions,
      progressValue,
      progressOptions,
      isFormFilled,
    }
  },
})
</script>

<template>
  <div class="bg-silver p-4">
    <div class="flex flex-row items-start justify-between space-x-4">
      <div class="flex w-full flex-col">
        <ParticipantsDataTable
          class="mb-4"
          :ids="resultIds"
        />
        <ParticipantsDataTable
          class="mb-4"
          :ids="statIds"
        />
      </div>

      <div class="min-w-104 flex">
        <div
          ref="verificationForm"
          class="w-104 absolute"
        >
          <template v-if="!isVerified">
            <div class="mb-3 flex flex-col">
              <div class="text-gray mb-2 text-base font-bold">Winner</div>
              <ToggleButtonGroup
                v-model:value="winnerValue"
                :type="'horizontal'"
                :options="winnerOptions"
              />
            </div>

            <div class="flex flex-col">
              <div class="text-gray mb-2 text-base font-bold">Progress</div>
              <ToggleButtonGroup
                v-model:value="progressValue"
                :type="'horizontal'"
                :options="progressOptions"
              />
            </div>
          </template>
          <div
            v-else
            class="bg-green p-4 text-center text-2xl text-white"
          >
            <FontAwesomeIcon icon="check-circle" />
            Event verified
          </div>
        </div>

        <div
          v-if="!isVerified"
          class="fixed bottom-4 right-4 mt-5 flex h-auto justify-end space-x-5"
        >
          <Button
            class="verifyResults__buttonVerify"
            :disabled="!isFormFilled"
            :size="'xl'"
            :color="'green'"
            @click="verifyEvent()"
          >
            Verify event
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
