<script setup lang="ts">
import { computed, ref } from 'vue'

import { Icon } from '@collector/shared-ui'

// props
interface Props {
  title: string
}

defineProps<Props>()

// data
const expanded = ref(false)

// computed
const icon = computed(() => (expanded.value ? 'chevron-up' : 'chevron-down'))

defineExpose({ expanded })
</script>

<template>
  <div class="rounded-md bg-slate-100/70 px-4 py-3 shadow-md">
    <button
      class="flex w-full items-center justify-between font-bold"
      @click="expanded = !expanded"
    >
      <div>{{ title }}</div>
      <Icon :icon />
    </button>

    <div
      v-show="expanded"
      class="mt-4"
    >
      <slot></slot>
    </div>
  </div>
</template>
