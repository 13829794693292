<script lang="ts">
import { defineComponent, ref } from 'vue'

import {
  BasketballIncidentId,
  CommonIncidentId,
} from '@collector/sportsapi-client-legacy'
import { useLiveViewBackground } from '@mobile/views/Relation/Shared/LiveView/useLiveViewBackground'
import PocketIncidents from '@mobile/views/Relation/Shared/PocketIncidents/PocketIncidents.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

import LiveViewCenterColumn from '../LiveView/LiveViewCenterColumn/LiveViewCenterColumn.vue'
import LiveViewHeader from '../LiveView/LiveViewHeader/LiveViewHeader.vue'
import LiveViewLeftColumn from '../LiveView/LiveViewLeftColumn/LiveViewLeftColumn.vue'
import LiveViewRightColumn from '../LiveView/LiveViewRightColumn/LiveViewRightColumn.vue'

export default defineComponent({
  components: {
    LiveViewLeftColumn,
    LiveViewCenterColumn,
    LiveViewRightColumn,
    LiveViewHeader,
    PocketIncidents,
  },
  setup() {
    const {
      probableFirstParticipant,
      probableLinkedFirstParticipant,
      probableSecondParticipant,
      probableLinkedSecondParticipant,
      probableEvent,
    } = useEventInjections()
    const isPocketIncidentsMenuExpanded = ref(false)
    const { eventHeaderConfiguration } = useSportConfiguration()
    const liveViewBackground = useLiveViewBackground(
      probableEvent,
      eventHeaderConfiguration,
    )

    function expandPocketIncidentsMenu(): void {
      isPocketIncidentsMenuExpanded.value = true
    }

    return {
      CommonIncidentId,
      BasketballIncidentId,
      probableEvent,
      probableFirstParticipant,
      probableLinkedFirstParticipant,
      probableSecondParticipant,
      probableLinkedSecondParticipant,
      isPocketIncidentsMenuExpanded,
      expandPocketIncidentsMenu,
      liveViewBackground,
    }
  },
})
</script>

<template>
  <div
    class="relative h-full overflow-hidden pt-1"
    :class="liveViewBackground"
  >
    <div class="flex h-full flex-col">
      <LiveViewHeader />
      <div
        class="grid h-full gap-4 px-2 pb-5 pt-1 sm:overflow-y-auto"
        style="
          grid-template-columns: 0.4fr 0.2fr 1fr 0.2fr 0.4fr;
          grid-template-rows: 100%;
        "
      >
        <LiveViewLeftColumn />
        <LiveViewCenterColumn />
        <LiveViewRightColumn />
      </div>

      <PocketIncidents
        :isExpanded="isPocketIncidentsMenuExpanded"
        :incidentIds="[
          BasketballIncidentId.Timeout,
          BasketballIncidentId.TvTimeout,
          BasketballIncidentId.PlayUnderReview,
          BasketballIncidentId.BreakDuringGame,
          BasketballIncidentId.Injury,
          CommonIncidentId.MatchResumed,
        ]"
        @toggle="isPocketIncidentsMenuExpanded = !isPocketIncidentsMenuExpanded"
        @incidentAddedToQueue="isPocketIncidentsMenuExpanded = false"
      />
    </div>
  </div>
</template>
