<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { opChain } from '@collector/shared-utils'
import { Confirmation, TeamSide } from '@collector/sportsapi-client-legacy'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import UpdateIncident from '@mobile/ActionQueue/Actions/Incidents/UpdateIncident'
import Button from '@mobile/components/Button/Button.vue'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import RelationIcon, {
  RelationIconType,
} from '@mobile/views/Relation/Shared/RelationIcon/RelationIcon.vue'

export default defineComponent({
  components: {
    Button,
    RelationIcon,
  },
  props: {
    probableLinkedIncident: {
      type: Object as PropType<ProbableLinkedIncident>,
      required: true,
    },
  },
  setup(props) {
    const { probableEvent, sport, actionQueue } = useEventInjections()

    function updateIncidentConfirmation(confirmation: Confirmation): void {
      actionQueue.value.add(
        new UpdateIncident(props.probableLinkedIncident.incident.id, {
          event_id: probableEvent.value.id,
          status_id: props.probableLinkedIncident.incident.event_status_id,
          incident_id: props.probableLinkedIncident.incident.incident_id,
          participant_team_id:
            props.probableLinkedIncident.incident.participant_id,
          confirmation,
        }),
      )
    }

    function getAttributesNames(attributeIds: number[]): string {
      return props.probableLinkedIncident.sportIncident.attributes
        .filter(({ id }) => attributeIds.includes(id))
        .map(({ name }) => name)
        .join(', ')
    }

    function hasAttributes(incident: ProbableIncident): boolean {
      return !!getAttributesNames(incident.attribute_ids).length
    }

    return {
      Confirmation,
      updateIncidentConfirmation,
      getAttributesNames,
      hasAttributes,
      sport,
      TeamSide,
      RelationIconType,
      opChain,
    }
  },
})
</script>

<template>
  <div class="flex w-full">
    <Button
      class="w-1/5 rounded-r-none font-semibold"
      :size="'lg'"
      :color="'red'"
      :disabled="false"
      @click="updateIncidentConfirmation(Confirmation.Cancelled)"
    >
      Cancel
    </Button>
    <div
      class="flex flex-1 items-center bg-white"
      :class="{
        'justify-start': probableLinkedIncident.side === TeamSide.Home,
        'justify-end': probableLinkedIncident.side === TeamSide.Away,
        'justify-center': !probableLinkedIncident.side,
      }"
    >
      <div
        class="flex items-center space-x-4 px-4"
        :class="{
          'flex-row-reverse space-x-reverse':
            probableLinkedIncident.side === TeamSide.Away,
        }"
      >
        <RelationIcon
          class="w-16"
          :class="{ hidden: !probableLinkedIncident.side }"
          :iconId="probableLinkedIncident.incident.incident_id"
          :size="16"
          :type="RelationIconType.Incidents"
        />
        <div class="w-full">
          <div
            class="text-sm font-bold"
            :class="{
              'text-left': probableLinkedIncident.side === TeamSide.Home,
              'text-right': probableLinkedIncident.side === TeamSide.Away,
            }"
          >
            <div
              :class="
                opChain(
                  probableLinkedIncident.probableLinkedParticipant,
                  (v) => v.css.textClass,
                )
              "
            >
              {{
                opChain(probableLinkedIncident.participant, (v) => v.short_name)
              }}
            </div>
          </div>
          <div
            class="flex justify-center"
            :class="{
              'flex-row-reverse': probableLinkedIncident.side === TeamSide.Away,
              'flex-col': !probableLinkedIncident.side,
            }"
          >
            <div class="text-blue align-baseline">
              {{ probableLinkedIncident.incident.event_time }}
            </div>
            <div
              class="text-bold px-2 align-baseline text-sm"
              :class="{
                'ml-auto': probableLinkedIncident.side === TeamSide.Away,
                'mr-auto': probableLinkedIncident.side === TeamSide.Home,
              }"
            >
              <div class="font-bold">
                {{ probableLinkedIncident.incident.incident_name }}
              </div>
              <div
                v-if="hasAttributes(probableLinkedIncident.incident)"
                class="text-xs"
              >
                {{
                  getAttributesNames(
                    probableLinkedIncident.incident.attribute_ids,
                  )
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Button
      class="w-1/5 rounded-l-none font-semibold"
      :size="'lg'"
      :color="'green'"
      :disabled="false"
      @click="updateIncidentConfirmation(Confirmation.Confirmed)"
    >
      Confirm
    </Button>
  </div>
</template>
