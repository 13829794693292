import Dexie from 'dexie'

import { CollectorPushMessageError } from '@collector/sportsapi-client-legacy'
import { Repository } from '@mobile/database/Repository'

export class ErrorsRepository extends Repository<CollectorPushMessageError> {
  static readonly tableName = 'errors'
  static readonly indexes = 'uuid, eventId, pushMessageUt'

  constructor(database: Dexie) {
    super(database.table<CollectorPushMessageError>(ErrorsRepository.tableName))
  }

  public async getByUuid(
    uuid: string,
  ): Promise<CollectorPushMessageError | undefined> {
    return await this.table.get(uuid)
  }

  public async getLast(): Promise<CollectorPushMessageError | undefined> {
    return await this.table.orderBy('pushMessageUt').last()
  }

  public async removeByEventId(eventId: number): Promise<number> {
    return await this.table.where('eventId').equals(eventId).delete()
  }
}
