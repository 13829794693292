<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({})
</script>

<template>
  <div
    class="fixed top-0 z-30 flex h-10 w-full items-center justify-between bg-white"
  >
    <slot></slot>
  </div>
</template>
