<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import { CommonResultId } from '@collector/sportsapi-client-legacy'
import { byId } from '@mobile/reusables/entityUtils'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'

export default defineComponent({
  props: {
    probableLinkedParticipant: {
      type: Object as PropType<ProbableLinkedParticipant>,
      required: true,
    },
  },
  setup(props) {
    const mainResult = computed(
      () =>
        byId(
          props.probableLinkedParticipant.getParticipant().value.results,
          CommonResultId.Result,
        )?.value ?? '-',
    )

    return { mainResult }
  },
})
</script>

<template>
  <div
    class="shadow-condensed flex h-7 items-center justify-center rounded p-1 text-xl font-bold text-white"
    :class="probableLinkedParticipant.css.bgClass"
  >
    {{ mainResult }}
  </div>
</template>
