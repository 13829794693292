import { Container } from 'inversify'

import { BeachVolleyballIncidentId } from '@collector/sportsapi-client-legacy'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindEventStatusesList(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.EventStatusesList)
    .toDynamicValue(() => {
      return [
        BeachVolleyballIncidentId.StartDelayed,
        BeachVolleyballIncidentId.Finished,
        BeachVolleyballIncidentId.Cancelled,
        BeachVolleyballIncidentId.Abandoned,
        BeachVolleyballIncidentId.Interrupted,
        BeachVolleyballIncidentId.Postponed,
        BeachVolleyballIncidentId.FirstSetStared,
        BeachVolleyballIncidentId.SecondSetStarted,
        BeachVolleyballIncidentId.ThirdSetStarted,
        BeachVolleyballIncidentId.GoldenSetStart,
        BeachVolleyballIncidentId.FinishedAwardedWin,
        BeachVolleyballIncidentId.ToFinish,
        BeachVolleyballIncidentId.SetBreak,
      ]
    })
    .inSingletonScope()
}
