import { BasketballIncidentId } from './IncidentId'

export const BasketballIncidentAcronymMap: {
  [key in BasketballIncidentId]: string
} = {
  [BasketballIncidentId.NotStarted]: 'NST',
  [BasketballIncidentId.StartDelayed]: 'SD',
  [BasketballIncidentId.MatchAboutToStart]: 'MTS',
  [BasketballIncidentId.FirstQuarterStarted]: 'FQS',
  [BasketballIncidentId.BreakAfter]: 'BR',
  [BasketballIncidentId.BreakAfterFirstQuarter]: 'BFQ',
  [BasketballIncidentId.SecondQuarterStarted]: 'SQS',
  [BasketballIncidentId.BreakAfterSecondQuarter]: 'BSQ',
  [BasketballIncidentId.ThirdQuarterStarted]: 'TQS',
  [BasketballIncidentId.BreakAfterThirdQuarter]: 'BTQ',
  [BasketballIncidentId.FourthQuarterStarted]: 'FQS',
  [BasketballIncidentId.FinishedRegularTime]: 'FRT',
  [BasketballIncidentId.WaitingForOvertime]: 'WFO',
  [BasketballIncidentId.FirstOvertimeStarted]: 'FOS',
  [BasketballIncidentId.BreakAfterFirstOvertime]: 'BFO',
  [BasketballIncidentId.SecondOvertimeStarted]: 'SOS',
  [BasketballIncidentId.BreakAfterSecondOvertime]: 'BSO',
  [BasketballIncidentId.ThirdOvertimeStarted]: 'TOS',
  [BasketballIncidentId.BreakAfterThirdOvertime]: 'BTO',
  [BasketballIncidentId.FourthOvertimeStarted]: 'FOS',
  [BasketballIncidentId.BreakAfterFourthOvertime]: 'BFO',
  [BasketballIncidentId.FifthOvertimeStarted]: 'FifOS',
  [BasketballIncidentId.FirstHalfStarted]: 'FHS',
  [BasketballIncidentId.BreakAfterFirstHalf]: 'BFH',
  [BasketballIncidentId.SecondHalfStarted]: 'SHS',
  [BasketballIncidentId.FinishedAfterOvertime]: 'FAO',
  [BasketballIncidentId.FinishedAwardedWin]: 'FAW',
  [BasketballIncidentId.Interrupted]: 'INT',
  [BasketballIncidentId.Postponed]: 'POS',
  [BasketballIncidentId.Cancelled]: 'CAN',
  [BasketballIncidentId.Abandoned]: 'ABD',
  [BasketballIncidentId.ToFinish]: 'FIN',
  [BasketballIncidentId.TwoPointShotMissed]: 'MTP',
  [BasketballIncidentId.ThreePointShotMissed]: 'MTHP',
  [BasketballIncidentId.OnePointShotMissed]: 'MOP',
  [BasketballIncidentId.OnePointShotMade]: 'OPSM',
  [BasketballIncidentId.TwoPointShotMade]: 'TPSM',
  [BasketballIncidentId.ThreePointShotMade]: 'THPSM',
  [BasketballIncidentId.Foul]: 'FOU',
  [BasketballIncidentId.Block]: 'BLK',
  [BasketballIncidentId.Steal]: 'STL',
  [BasketballIncidentId.DefensiveRebound]: 'DR',
  [BasketballIncidentId.OffensiveRebound]: 'OF',
  [BasketballIncidentId.Turnover]: 'TO',
  [BasketballIncidentId.ThrowIn]: 'TI',
  [BasketballIncidentId.FreeThrowsAwarded]: 'FTA',
  [BasketballIncidentId.FoulBonus]: 'FB',
  [BasketballIncidentId.InPossession]: 'IP',
  [BasketballIncidentId.Timeout]: 'TI',
  [BasketballIncidentId.TimeoutOver]: 'TO',
  [BasketballIncidentId.JumpBall]: 'JB',
  [BasketballIncidentId.TvTimeout]: 'TvTI',
  [BasketballIncidentId.TvTimeoutOver]: 'TvTIO',
  [BasketballIncidentId.BreakDuringGame]: 'BDG',
  [BasketballIncidentId.PlayUnderReview]: 'PUR',
  [BasketballIncidentId.Injury]: 'INJ',
  [BasketballIncidentId.RefereeInjury]: 'RINJ',
}
