import { Authorization, Credentials } from '@collector/sportsapi-client-legacy'

export class ApplicationAuthorization extends Authorization {
  public fillCredentials: (credentials: Credentials) => void = () => {}

  async requestCredentials(): Promise<Credentials> {
    return await new Promise<Credentials>((resolve) => {
      this.fillCredentials = resolve
    })
  }
}
