import { LegalBallAttributesId } from './LegalBallAttributesId'

export const LegalBallAttributesOptions = [
  {
    id: LegalBallAttributesId.Zero,
    value: 0,
  },
  {
    id: LegalBallAttributesId.One,
    value: 1,
  },
  {
    id: LegalBallAttributesId.Two,
    value: 2,
  },
  {
    id: LegalBallAttributesId.Three,
    value: 3,
  },
  {
    id: LegalBallAttributesId.Four,
    value: 4,
  },
  {
    id: LegalBallAttributesId.Five,
    value: 5,
  },
  {
    id: LegalBallAttributesId.Six,
    value: 6,
  },
  {
    id: LegalBallAttributesId.Seven,
    value: 7,
  },
  {
    id: LegalBallAttributesId.Eight,
    value: 8,
  },
  {
    id: LegalBallAttributesId.Nine,
    value: 9,
  },
]
