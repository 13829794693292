<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import DeleteIncident from '@mobile/ActionQueue/Actions/Incidents/DeleteIncident'
import Button from '@mobile/components/Button/Button.vue'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

import Modal from './Modal.vue'
import ModalButtons from './ModalButtons.vue'
import ModalTitle from './ModalTitle.vue'

export default defineComponent({
  components: {
    Modal,
    ModalTitle,
    ModalButtons,
    Button,
  },
  props: {
    linkedIncident: {
      type: Object as PropType<ProbableLinkedIncident>,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { actionQueue } = useEventInjections()
    const time = computed(() => {
      const [minute, second] = props.linkedIncident.incident.event_time?.split(
        ':',
      ) ?? ['00', '00']

      return {
        minute,
        second,
      }
    })

    function deleteIncident(): void {
      actionQueue.value.add(
        new DeleteIncident(props.linkedIncident.incident.id),
      )
      emit('close')
    }

    return {
      deleteIncident,
      time,
    }
  },
})
</script>

<template>
  <Modal
    id="incidentDeleteModal"
    @close="$emit('close')"
  >
    <ModalTitle title="Incident delete" />
    <span>Are you sure you want to delete:</span>
    <span>{{ time.minute }}:{{ time.second }}:</span>
    <span v-if="linkedIncident.incident.participant_name">
      {{ linkedIncident.incident.participant_name }} -
    </span>
    <span>{{ linkedIncident.incident.incident_name }}</span>
    <ModalButtons>
      <Button
        class="mr-2"
        color="red"
        :size="'lg'"
        @click="$emit('close')"
      >
        No, cancel
      </Button>
      <Button
        color="green"
        :size="'lg'"
        @click="deleteIncident"
      >
        Yes, delete
      </Button>
    </ModalButtons>
  </Modal>
</template>
