import {
  CricketStatId,
  SportStatInputType,
} from '@collector/sportsapi-client-legacy'

import {
  EventPreparationEventStat,
  EventPreparationEventStatsSportFactory,
} from './types'

export function eventPreparationEventStatsCricketFactory(): EventPreparationEventStatsSportFactory {
  function getStats(): EventPreparationEventStat[] {
    return [
      {
        id: CricketStatId.BattingTeam,
        name: 'Batting Team',
        type: SportStatInputType.SelectParticipant,
      },
    ]
  }

  return { getStats }
}
