import {
  BasketballEventDetailId,
  Event,
  relationStatuses,
  SportDetailInputType,
} from '@collector/sportsapi-client-legacy'
import { SelectOption } from '@mobile/components/Select/types/SelectOption'
import { CollectorSport } from '@mobile/reusables/classes/CollectorSport'

import {
  EventPreparationEventDetail,
  EventPreparationEventDetailsSportFactory,
} from './types'
import { getDetailName, getDetailSelectItems } from './utils'

export function eventPreparationEventDetailsBasketballFactory(
  event: Event,
  sport: CollectorSport,
): EventPreparationEventDetailsSportFactory {
  function getDetails(): EventPreparationEventDetail[] {
    return [
      {
        name: 'Relation status',
        fieldName: 'relation_status',
        type: SportDetailInputType.Select,
        items: relationStatuses,
      },
      {
        id: BasketballEventDetailId.PeriodLength,
        name: getDetailName(sport, BasketballEventDetailId.PeriodLength),
        type: SportDetailInputType.Number,
      },
      {
        id: BasketballEventDetailId.ExtraPeriodLength,
        name: getDetailName(sport, BasketballEventDetailId.ExtraPeriodLength),
        type: SportDetailInputType.Number,
      },
      {
        id: BasketballEventDetailId.NumberOfPeriods,
        name: getDetailName(sport, BasketballEventDetailId.NumberOfPeriods),
        type: SportDetailInputType.Number,
      },
      {
        id: BasketballEventDetailId.NumberOfExtraPeriods,
        name: getDetailName(
          sport,
          BasketballEventDetailId.NumberOfExtraPeriods,
        ),
        type: SportDetailInputType.Number,
      },
      {
        id: BasketballEventDetailId.PossessionTimeLimit,
        name: getDetailName(sport, BasketballEventDetailId.PossessionTimeLimit),
        type: SportDetailInputType.Number,
      },
      {
        id: BasketballEventDetailId.TimerDirection,
        name: getDetailName(sport, BasketballEventDetailId.TimerDirection),
        type: SportDetailInputType.Select,
        items: getDetailSelectItems(
          event,
          sport,
          BasketballEventDetailId.TimerDirection,
        ),
      },
      {
        name: 'Event status',
        fieldName: 'status_id',
        type: SportDetailInputType.Select,
        items: getEventStatusesSelectItems(),
      },
    ]
  }

  function getEventStatusesSelectItems(): SelectOption[] {
    return sport.sportsAPIEntity.statuses.map((status) => ({
      name: status.name,
      value: status.id,
    }))
  }

  return { getDetails }
}
