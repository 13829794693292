<script setup lang="ts">
import { computed } from 'vue'

import { SportIcon, SportIconName } from '@collector/shared-ui-domain'
import { SoccerStatId } from '@collector/sportsapi-client-legacy'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

// props
interface Props {
  statId: number
  value: number | string
  reversed: boolean
}

const props = defineProps<Props>()

// inject
const { sport } = useEventInjections()

// computed
const direction = computed(() =>
  props.reversed ? 'flex-row-reverse space-x-reverse' : 'flex-row',
)

const sportName = sport.value.sportsAPIEntity.name
  .toUpperCase()
  .replaceAll(' ', '')
</script>

<template>
  <div :class="[direction, 'flex items-center space-x-1']">
    <div>{{ props.value }}</div>
    <SportIcon
      :name="
        `stats/${sportName}/${props.statId as SoccerStatId}.svg` as SportIconName
      "
    />
  </div>
</template>
