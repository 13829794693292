import { Container } from 'inversify'
import { computed, ref, watch } from 'vue'

import { TeamSide } from '@collector/sportsapi-client-legacy'
import { EventSettingsType } from '@mobile/database/EventSettingsRepository'
import * as app from '@mobile/globalState/app'

import { RelationIoCBindings } from './types'

export function bindSidesSwitched(ioc: Container): void {
  let unwatchRefs: () => void

  ioc
    .bind(RelationIoCBindings.SidesSwitched)
    .toDynamicValue(async (ctx) => {
      const [
        event,
        probableLinkedHomeParticipant,
        probableLinkedAwayParticipant,
      ] = await Promise.all([
        ctx.container.getAsync(RelationIoCBindings.FactualEvent),
        ctx.container.getAsync(
          RelationIoCBindings.ProbableLinkedHomeParticipant,
        ),
        ctx.container.getAsync(
          RelationIoCBindings.ProbableLinkedAwayParticipant,
        ),
      ])

      const eventSettings =
        await app.state.database.eventSettingsRepository.getByEventId(
          event.value.eventId,
        )

      const sidesSwitched = ref(eventSettings?.settings.sidesSwitched ?? false)

      const probableLinkedFirstParticipant = computed(() =>
        sidesSwitched.value
          ? probableLinkedAwayParticipant
          : probableLinkedHomeParticipant,
      )
      const probableFirstParticipant = computed(
        () => probableLinkedFirstParticipant.value.getParticipant().value,
      )

      const probableLinkedSecondParticipant = computed(() =>
        sidesSwitched.value
          ? probableLinkedHomeParticipant
          : probableLinkedAwayParticipant,
      )
      const probableSecondParticipant = computed(
        () => probableLinkedSecondParticipant.value.getParticipant().value,
      )

      return {
        sidesSwitched,
        probableLinkedFirstParticipant,
        probableFirstParticipant,
        probableLinkedSecondParticipant,
        probableSecondParticipant,
      }
    })
    .inSingletonScope()
    .onActivation(async (ctx, injectable) => {
      const event = await ctx.container.getAsync(
        RelationIoCBindings.FactualEvent,
      )
      const eventId = event.value.eventId

      unwatchRefs = watch(injectable.sidesSwitched, async () => {
        const eventSettings =
          await app.state.database.eventSettingsRepository.getByEventId(
            event.value.eventId,
          )
        app.state.database.eventSettingsRepository.put({
          eventId,
          type: EventSettingsType.MOBILE,
          settings: {
            possessionSide:
              eventSettings?.settings.possessionSide ?? TeamSide.Home,
            sidesSwitched: injectable.sidesSwitched.value,
          },
        })
      })

      return injectable
    })
    .when(() => true)
    .onDeactivation(async () => {
      unwatchRefs()
    })
    .when(() => true)
}
