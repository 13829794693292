import { Container } from 'inversify'

import { ClockStatusAction } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/IncidentsConfiguration'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindIncidentsConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.IncidentsConfiguration)
    .toDynamicValue(() => {
      return {
        fallbackIncidentConfiguration: {
          clockStatusAction: ClockStatusAction.None,
          resetClockTime: false,
        },
      }
    })
    .inSingletonScope()
}
