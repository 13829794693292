<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import {
  isBetMarketUpdateEnabled,
  translateBetMarketKeyToBetMarketType,
} from '@collector/shared-ui-domain'
import { BetStatus, IncidentId } from '@collector/sportsapi-client-legacy'
import { ButtonColor, ButtonSize } from '@mobile/components/Button/ButtonTypes'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: { IncidentButton },
  props: {
    marketName: {
      type: String as PropType<'bet_status' | 'bet_cards' | 'bet_corners'>,
      required: true,
    },
    openMarketsIncident: {
      type: Number as PropType<IncidentId>,
      required: true,
    },
    closeMarketsIncident: {
      type: Number as PropType<IncidentId>,
      required: true,
    },
    openMarketsText: {
      type: String,
      default: 'Open markets',
    },
    closeMarketsText: {
      type: String,
      default: 'Close markets',
    },
    size: {
      type: String as PropType<ButtonSize>,
      default: 'md',
    },
    withIcon: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String as PropType<'vertical' | 'horizontal'>,
      default: 'horizontal',
    },
  },
  setup(props) {
    const { probableEvent } = useEventInjections()

    const disabled = computed(() => {
      const betMarketType = translateBetMarketKeyToBetMarketType(
        props.marketName,
      )

      return !isBetMarketUpdateEnabled(probableEvent.value, betMarketType)
    })
    const isActive = computed(
      () => probableEvent.value[props.marketName] === BetStatus.Active,
    )
    const buttonColor = computed<ButtonColor>(() =>
      isActive.value ? 'green' : 'red',
    )
    const incidentId = computed(() =>
      isActive.value ? props.closeMarketsIncident : props.openMarketsIncident,
    )

    return {
      isActive,
      incidentId,
      buttonColor,
      disabled,
    }
  },
})
</script>

<template>
  <IncidentButton
    :size
    :incidentId
    :color="buttonColor"
    :withIcon
    :type
    :disabled
  >
    <slot>{{ isActive ? closeMarketsText : openMarketsText }}</slot>
  </IncidentButton>
</template>
