<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'

import { opChain } from '@collector/shared-utils'
import { UpdateSubParticipantDTO } from '@mobile/ActionQueue/Actions/Lineups/UpdateLineups'
import Button from '@mobile/components/Button/Button.vue'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

export default defineComponent({
  components: { Button },
  props: {
    title: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    probableLinkedParticipant: {
      type: Object as PropType<ProbableLinkedParticipant>,
      required: true,
    },
    selectedTeamMembers: {
      type: Array as PropType<UpdateSubParticipantDTO[]>,
      required: true,
    },
  },
  emits: ['teamMemberUpdated', 'teamMemberRemoved'],
  setup(props) {
    const isExpanded = ref(true)
    const { lineupsConfiguration } = useSportConfiguration()
    const showPlaceholder = computed(
      () => props.selectedTeamMembers.length === 0,
    )
    const configuration = lineupsConfiguration.availablePositions.find(
      (position) => position.name === props.title,
    )
    const canCaptainBeSelected = configuration?.canCaptainBeSelected ?? false
    const canNumberBeAssigned = configuration?.canNumberBeAssigned ?? false

    function toggle(): void {
      isExpanded.value = !isExpanded.value
    }

    return {
      canCaptainBeSelected,
      isExpanded,
      showPlaceholder,
      toggle,
      canNumberBeAssigned,
      opChain,
    }
  },
})
</script>

<template>
  <div
    class="flex cursor-pointer flex-row items-center justify-between bg-gradient-to-r px-3 py-1 font-bold text-white"
    :class="[
      probableLinkedParticipant.css.gradientFrom,
      probableLinkedParticipant.css.gradientTo,
    ]"
    @click="toggle()"
  >
    <h1>{{ title }} ({{ selectedTeamMembers.length }})</h1>
    <FontAwesomeIcon
      class="mr-3"
      :icon="isExpanded ? 'angle-up' : 'angle-down'"
    />
  </div>
  <table
    v-if="isExpanded"
    class="my-1 table-auto border-separate border-spacing-x-1 text-left"
  >
    <thead class="text-sm">
      <tr>
        <th class="text-gray w-2 px-6 py-1 text-center font-bold">#</th>
        <th
          v-if="canCaptainBeSelected"
          class="text-gray w-2 px-2 py-1 font-bold"
        >
          Captain
        </th>
        <th class="text-gray px-2 py-1 font-bold">Player</th>
      </tr>
    </thead>
    <tbody class="text-sm">
      <tr
        v-for="(selectedTeamMember, index) in selectedTeamMembers"
        :key="selectedTeamMember.id || index"
        class="text-gray bg-white"
      >
        <td class="w-2 px-2 py-1 align-middle">
          <input
            class="border-silver w-10 rounded border border-solid p-1 text-center"
            :value="selectedTeamMember.shirt_nr"
            :disabled="!canNumberBeAssigned"
            type="text"
            placeholder="___"
            pattern="[0-9]+"
            inputmode="numeric"
            @input="
              (e) =>
                $emit('teamMemberUpdated', {
                  ...selectedTeamMember,
                  shirt_nr: opChain(
                    e.target as HTMLInputElement,
                    (v) => v.value,
                  ),
                })
            "
          />
        </td>
        <td
          v-if="canCaptainBeSelected"
          class="w-2 px-2 py-1 align-middle"
        >
          <Button
            class="border-green m-auto w-10 border border-solid"
            :size="'xxs'"
            color="green"
            :invertedColor="!selectedTeamMember.captain"
            @click="
              $emit('teamMemberUpdated', {
                ...selectedTeamMember,
                captain: !selectedTeamMember.captain,
              })
            "
          >
            <span class="text-lg font-bold">C</span>
          </Button>
        </td>
        <td class="break-all px-2 py-1 align-middle">
          {{ selectedTeamMember.name }}
        </td>
        <td class="flex flex-row justify-end px-2 py-1 align-middle">
          <Button
            class="mr-2 w-12 font-bold"
            :size="'xs'"
            :color="'red'"
            @click="$emit('teamMemberRemoved', selectedTeamMember)"
          >
            <FontAwesomeIcon icon="times" />
          </Button>
        </td>
      </tr>
      <tr
        v-if="showPlaceholder"
        class="text-gray bg-white"
      >
        <td
          class="text-cloudygray px-2 py-4 text-opacity-50"
          colspan="3"
        >
          {{ placeholder }}
        </td>
      </tr>
    </tbody>
  </table>
</template>
