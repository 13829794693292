<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  watch,
} from 'vue'

import { UpdateIncident } from '@mobile/ActionQueue/Actions/Actions'
import { isIncidentEditable } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import Button from '@mobile/components/Button/Button.vue'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

type UpdateTime = {
  minute: string
  second: string
}

export default defineComponent({
  components: { Button },
  props: {
    linkedIncident: {
      type: Object as PropType<ProbableLinkedIncident>,
      required: true,
    },
    docked: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { actionQueue, probableEvent, sport } = useEventInjections()
    const currentTime = computed<UpdateTime>(() => {
      const [minute, second] = props.linkedIncident.incident.event_time?.split(
        ':',
      ) ?? ['00', '00']

      return {
        minute,
        second,
      }
    })
    const updateTime = ref<UpdateTime>({
      minute: currentTime.value.minute,
      second: currentTime.value.second,
    })

    const editable = ref(false)
    const minuteInputElement = ref<HTMLInputElement | null>(null)
    const secondInputElement = ref<HTMLInputElement | null>(null)
    const isEditable = computed(() =>
      isIncidentEditable(props.linkedIncident.incident, sport),
    )

    watch(
      () => props.docked,
      (docked) => {
        if (docked === false) {
          editable.value = false
        }
      },
    )

    watch(editable, (editable) => {
      if (editable) {
        updateTime.value.minute = currentTime.value.minute
        updateTime.value.second = currentTime.value.second
      }
    })

    onMounted(() => {
      minuteInputElement.value?.addEventListener('focus', selectOnFocus)
      secondInputElement.value?.addEventListener('focus', selectOnFocus)
    })

    onUnmounted(() => {
      minuteInputElement.value?.removeEventListener('focus', selectOnFocus)
      secondInputElement.value?.removeEventListener('focus', selectOnFocus)
    })

    async function selectOnFocus(this: HTMLInputElement): Promise<void> {
      await nextTick()

      this.select()
    }

    async function edit(): Promise<void> {
      if (isEditable.value) {
        editable.value = true

        await nextTick()

        minuteInputElement.value?.focus()
      }
    }

    function updateIncidentTime(): void {
      const action = new UpdateIncident(props.linkedIncident.incident.id, {
        event_id: probableEvent.value.id,
        status_id: probableEvent.value.status_id,
        incident_id: props.linkedIncident.incident.incident_id,
        minute: Number(updateTime.value.minute),
        second: Number(updateTime.value.second),
      })

      actionQueue.value.add(action)

      editable.value = false
    }

    return {
      edit,
      editable,
      updateTime,
      currentTime,
      isEditable,
      minuteInputElement,
      secondInputElement,
      updateIncidentTime,
    }
  },
})
</script>

<template>
  <div
    v-show="editable"
    style="width: 150px"
  >
    <form
      class="flex items-center space-x-1.5"
      @submit.prevent="updateIncidentTime"
    >
      <input
        ref="minuteInputElement"
        v-model="updateTime.minute"
        class="text-gray border-darksilver h-full w-10 rounded border border-solid px-1 py-2.5 text-center text-xs font-bold"
        min="0"
        type="number"
      />
      <div>:</div>
      <input
        ref="secondInputElement"
        v-model="updateTime.second"
        class="text-gray border-darksilver h-full w-10 rounded border border-solid px-1 py-2.5 text-center text-xs font-bold"
        min="0"
        type="number"
      />
      <Button
        class="w-10"
        color="green"
        size="xs"
        type="submit"
      >
        <FontAwesomeIcon icon="save" />
      </Button>
    </form>
  </div>
  <div
    v-if="!editable"
    class="flex items-center space-x-2"
    :class="{ 'hover:text-blue group cursor-pointer': isEditable }"
    style="width: 90px"
    @click="edit"
  >
    <div>{{ currentTime.minute }}:{{ currentTime.second }}</div>
    <FontAwesomeIcon
      v-if="isEditable"
      class="mb-1 opacity-0 group-hover:opacity-100"
      icon="edit"
    />
  </div>
</template>

<style scoped>
/* TODO: make it as Tailwind directive or plugin */
/* TODO: refactor other number inputs inside `Application` using this technique */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
