import { Container } from 'inversify'

import { ParticipantSubType } from '@collector/sportsapi-client-legacy'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindLineupsConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.LineupsConfiguration)
    .toDynamicValue(() => {
      return {
        availablePositions: [
          {
            name: 'Player',
            acronym: 'PLA',
            canCaptainBeSelected: false,
            canNumberBeAssigned: true,
            subtypes: [
              ParticipantSubType.Athlete,
              ParticipantSubType.AthleteCoach,
            ],
            button: { color: 'green' },
          },
          {
            name: 'Coach',
            acronym: 'COA',
            canCaptainBeSelected: false,
            canNumberBeAssigned: false,
            subtypes: [
              ParticipantSubType.Athlete,
              ParticipantSubType.AthleteCoach,
              ParticipantSubType.Coach,
            ],
            button: { color: 'gray' },
          },
        ],
        lineupPositionOrder: [],
      }
    })
    .inSingletonScope()
}
