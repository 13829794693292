<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    eventName: {
      type: String,
      required: true,
    },
  },
})
</script>

<template>
  <div
    class="from-darkblue to-blue flex h-9 items-center justify-between bg-gradient-to-r"
  >
    <div class="flex items-center px-4 py-2 text-white">
      <FontAwesomeIcon icon="align-justify" />
    </div>
    <div class="text-xs font-bold text-white">
      {{ eventName }}
    </div>
    <div class="flex items-center px-4 py-2 text-white">
      <FontAwesomeIcon icon="question-circle" />
    </div>
  </div>
</template>
