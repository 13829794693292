laye
<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import { opChain } from '@collector/shared-utils'
import { IncidentActionType } from '@collector/sportsapi-client-legacy'
import DeleteIncident from '@mobile/ActionQueue/Actions/Incidents/DeleteIncident'
import { isIncidentDeletable } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import Button from '@mobile/components/Button/Button.vue'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: { Button },
  props: {
    linkedIncident: {
      type: Object as PropType<ProbableLinkedIncident>,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { actionQueue, sport } = useEventInjections()

    const deleteIncident = (): void => {
      actionQueue.value.add(
        new DeleteIncident(props.linkedIncident.incident.id),
      )
      // eslint-disable-next-line vue/no-mutating-props
      props.linkedIncident.incident.action = IncidentActionType.DELETE
      emit('close')
    }

    const isDeletable = computed(() =>
      isIncidentDeletable(props.linkedIncident.incident, sport),
    )

    return {
      isDeletable,
      deleteIncident,
      isIncidentDeletable,
      opChain,
    }
  },
})
</script>

<template>
  <div class="flex w-full flex-col rounded">
    <div class="bg-red flex justify-between rounded-t p-0.5 text-white">
      <div class="header-property header-property-time">
        <div class="mr-1 font-bold">Time</div>
        <div>{{ linkedIncident.incident.event_time }}</div>
      </div>
      <div class="header-property header-property-status">
        <div class="mr-1 font-bold">Status</div>
        <div>{{ linkedIncident.incident.event_status_name }}</div>
      </div>
      <div class="header-property header-property-participant">
        <div class="font-bold">
          <div>{{ linkedIncident.incident.participant_name }}</div>
        </div>
        <div class="pl-2">|</div>
        <div class="pl-2">
          <div>{{ linkedIncident.incident.incident_name }}</div>
        </div>
      </div>
    </div>

    <div
      class="flex h-12 items-center rounded-bl rounded-br bg-white px-2"
      :class="
        opChain(
          linkedIncident.probableLinkedParticipant,
          (v) => v.css.gradient32To,
        )
      "
    >
      <div
        class="m-auto flex w-full flex-grow items-center justify-around text-xs font-bold"
        data-test-id="HistoryDeleteConfirmationText"
      >
        <span v-if="isDeletable">
          Are you sure, you want to delete this incident?
        </span>
        <!-- prettier-ignore -->
        <span v-else>
          You cannot remove incident with confirmation status
          <span class="text-blue">To Be Declared</span>.
          <br />
          You need to
          <span class="text-green">confirm</span>
          or
          <span class="text-red">cancel</span>
          this incident first.
        </span>
      </div>

      <div
        v-if="isDeletable"
        class="flex justify-end"
      >
        <Button
          class="text-xxs w-10 text-center font-bold"
          :color="'red'"
          :size="'xs'"
          @click="$emit('close')"
        >
          NO
        </Button>
        <Button
          class="text-xxs ml-2 w-10 text-center font-bold"
          :color="'green'"
          :size="'xs'"
          @click="deleteIncident()"
        >
          YES
        </Button>
      </div>
      <div v-else>
        <Button
          class="text-xxs ml-2 w-10 text-center font-bold"
          :color="'blue'"
          :size="'xs'"
          @click="$emit('close')"
        >
          OK
        </Button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use 'sass:math';
@import '@mobile/styles/variables.scss';
@import '@mobile/styles/mixins.scss';

.header-property {
  $timeWidth: 70px;
  $marginBetweenProperties: 6px;
  $countOfAllProperties: 3;
  $countOfDynamicProperties: 2;

  @mixin dynamicPropertyWidth($percentWidth) {
    $timeOffset: math.div($timeWidth, $countOfDynamicProperties);
    $marginOffset: math.div(
      $marginBetweenProperties * $countOfAllProperties * 2,
      $countOfDynamicProperties
    );

    @include staticWidth(
      calc(#{$percentWidth} - #{$timeOffset} - #{$marginOffset})
    );
  }

  display: flex;
  margin: 0 $marginBetweenProperties;
  overflow: hidden;
  font-size: 10px;
  white-space: nowrap;
  align-items: center;

  &-time {
    @include staticWidth($timeWidth);
  }

  &-status {
    @include dynamicPropertyWidth(26%);
  }

  &-participant {
    @include dynamicPropertyWidth(74%);
  }
}
</style>
