<script lang="ts">
import ResizeObserver from 'resize-observer-polyfill'
import { defineComponent, onBeforeUnmount, PropType, ref, watch } from 'vue'

import { Formation, FormationPlayer, maximumPlayersInLine } from '../Formation'
import FormationPlayerComponent from './FormationPlayer.vue'

export default defineComponent({
  components: { FormationPlayerComponent },
  props: {
    formation: {
      type: Object as PropType<Formation>,
      required: true,
    },
    selectedFormationPlayer: {
      type: Object as PropType<FormationPlayer>,
      required: false,
    },
  },
  emits: ['formationPlayerSelected'],
  setup() {
    const formationWrapperElement = ref<null | HTMLDivElement>(null)
    const pitchElement = ref<null | HTMLImageElement>(null)
    const formationPlayerHeightInPixels = ref(20)
    const showLoader = ref(true)

    const pitchElementObserver = new ResizeObserver(() => {
      if (formationWrapperElement.value && pitchElement.value) {
        showLoader.value = true
        setFormationWrapperSize(
          pitchElement.value.width,
          pitchElement.value.height,
        )
        setFormationPlayerHeight(pitchElement.value.height)
        showLoader.value = false
      }
    })

    function setFormationWrapperSize(
      pitchWidth: number,
      pitchHeight: number,
    ): void {
      if (formationWrapperElement.value) {
        formationWrapperElement.value.style.width = `${pitchWidth}px`
        formationWrapperElement.value.style.height = `${pitchHeight}px`
      }
    }

    function setFormationPlayerHeight(pitchHeight: number): void {
      const offset = 8
      const scale = 0.85

      formationPlayerHeightInPixels.value =
        Math.floor(pitchHeight / maximumPlayersInLine - offset) * scale
    }

    watch(pitchElement, (ref) => {
      if (ref) {
        pitchElementObserver.disconnect()
        pitchElementObserver.observe(ref)
      }
    })

    onBeforeUnmount(() => {
      pitchElementObserver.disconnect()
    })

    return {
      pitchElement,
      formationWrapperElement,
      formationPlayerHeightInPixels,
      showLoader,
    }
  },
})
</script>

<template>
  <div
    class="bg-soccer-bottom relative flex flex-1 items-center justify-center overflow-hidden bg-cover"
  >
    <img
      ref="pitchElement"
      class="object-fit pointer-events-none h-auto max-h-full p-2"
      src="@mobile/assets/pitches/soccer/top.svg"
    />
    <div
      ref="formationWrapperElement"
      class="absolute flex items-center"
      :class="showLoader ? 'invisible' : 'visible'"
    >
      <div
        v-for="(formationLine, formationLineIndex) in formation.lines"
        :key="`formation-line-${formationLineIndex + 1}`"
        class="relative top-2 flex h-full flex-1 flex-col-reverse justify-around"
      >
        <FormationPlayerComponent
          v-for="(formationPlayer, formationLinePlayerIndex) in formationLine"
          :key="`formation-line-${formationLineIndex + 1}-player-${formationLinePlayerIndex}`"
          :formationPlayer
          :selectedFormationPlayer
          :formationPlayerSize="formationPlayerHeightInPixels"
          @click="$emit('formationPlayerSelected', formationPlayer)"
        />
      </div>
    </div>
    <FontAwesomeIcon
      v-if="showLoader"
      class="text-blue absolute mx-auto animate-spin text-3xl"
      icon="spinner"
    />
  </div>
</template>
