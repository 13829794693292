import {
  EventPreparationEventStat,
  EventPreparationEventStatsSportFactory,
} from './types'

export function eventPreparationEventStatsDefaultFactory(): EventPreparationEventStatsSportFactory {
  function getStats(): EventPreparationEventStat[] {
    return []
  }

  return { getStats }
}
