import { AmericanFootballIncidentId } from '@collector/sportsapi-client-legacy'

export const AmericanFootballManualAddIncidentIds = [
  AmericanFootballIncidentId.Kickoff,
  AmericanFootballIncidentId.Touchback,
  AmericanFootballIncidentId.Pass,
  AmericanFootballIncidentId.Rush,
  AmericanFootballIncidentId.Turnover,
  AmericanFootballIncidentId.Interception,
  AmericanFootballIncidentId.Sack,
  AmericanFootballIncidentId.Fumble,
  AmericanFootballIncidentId.FumbleRecovered,
  AmericanFootballIncidentId.Penalty,
  AmericanFootballIncidentId.Punt,
  AmericanFootballIncidentId.DefensiveReturn,
]

export const AmericanFootballManualAddIncidentSubstitutionIds = []
