import { interfaces } from 'inversify'

import { PossessionSide } from './PossessionSide/PossessionSide'

type SI<T> = interfaces.ServiceIdentifier<T>

interface SoccerRelationIoCBindingsInterface {
  PossessionSide: SI<PossessionSide>
}

export const SoccerRelationIoCBindings: SoccerRelationIoCBindingsInterface = {
  PossessionSide: Symbol('PossessionSide'),
}
