<script lang="ts">
import { computed, defineComponent } from 'vue'

import { BasketballEventStatusId } from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import { useTimeChange } from '@mobile/views/Relation/Shared/Popups/EventTime/useTimeChange'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: { Button },
  setup() {
    const { probableClock, probableEvent } = useEventInjections()
    const {
      clockTimeAfterChangeFormatted,
      addTime,
      startTime,
      pauseTime,
      cancelClockChange,
      isTimeChangeable,
      isTimeSaveable,
      saveClockTime,
      isTimeStopped,
    } = useTimeChange()
    const statusesAllowedToChangeTime = [
      BasketballEventStatusId.FirstQuarter,
      BasketballEventStatusId.SecondQuarter,
      BasketballEventStatusId.ThirdQuarter,
      BasketballEventStatusId.FourthQuarter,
      BasketballEventStatusId.FirstOvertime,
      BasketballEventStatusId.SecondOvertime,
      BasketballEventStatusId.ThirdOvertime,
      BasketballEventStatusId.FourthOvertime,
      BasketballEventStatusId.FifthOvertime,
      BasketballEventStatusId.FirstHalf,
      BasketballEventStatusId.SecondHalf,
    ]

    const canChangeTime = computed(() =>
      statusesAllowedToChangeTime.includes(
        probableEvent.value.status_id as BasketballEventStatusId,
      ),
    )

    const time = computed(() => {
      return {
        minute: isTimeSaveable.value
          ? clockTimeAfterChangeFormatted.value.minute
          : probableClock.value.minuteFormatted,
        second: isTimeSaveable.value
          ? clockTimeAfterChangeFormatted.value.second
          : probableClock.value.secondFormatted,
      }
    })

    return {
      time,
      addTime,
      startTime,
      pauseTime,
      isTimeStopped,
      saveClockTime,
      isTimeSaveable,
      isTimeChangeable,
      cancelClockChange,
      canChangeTime,
    }
  },
})
</script>

<template>
  <div class="flex space-x-2">
    <Button
      class="w-12"
      :color="'white'"
      size="xxs"
      :disabled="!isTimeSaveable"
      @click="cancelClockChange"
    >
      X
    </Button>
    <Button
      class="w-12"
      :color="'white'"
      size="xxs"
      :disabled="!isTimeChangeable || !canChangeTime"
      @click="addTime(-1)"
    >
      -1
    </Button>
    <Button
      class="w-22 flex flex-col"
      :color="'white'"
      size="xxs"
      :disabled="!canChangeTime"
    >
      <span>{{ time.minute }}:{{ time.second }}</span>
      <button @click="isTimeStopped ? startTime() : pauseTime()">
        <FontAwesomeIcon
          v-if="!isTimeStopped"
          icon="pause"
        />
        <FontAwesomeIcon
          v-else
          icon="play"
        />
      </button>
    </Button>
    <Button
      class="w-12"
      :color="'white'"
      size="xxs"
      :disabled="!isTimeChangeable || !canChangeTime"
      @click="addTime(1)"
    >
      +1
    </Button>
    <Button
      class="w-12"
      :color="isTimeSaveable ? 'green' : 'white'"
      size="xxs"
      :disabled="!isTimeSaveable"
      @click="saveClockTime"
    >
      <FontAwesomeIcon icon="save" />
    </Button>
  </div>
</template>
