<script lang="ts">
import { computed, defineComponent } from 'vue'

import { Confirmation } from '@collector/sportsapi-client-legacy'
import PopupTitle from '@mobile/views/Relation/Shared/Popup/PopupTitle.vue'
import ConfirmedIncident from '@mobile/views/Relation/Shared/Popups/ConfirmIncidents/ConfirmedIncident/ConfirmedIncident.vue'
import ConfirmIncident from '@mobile/views/Relation/Shared/Popups/ConfirmIncidents/ConfirmIncident/ConfirmIncident.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: {
    ConfirmedIncident,
    ConfirmIncident,
    PopupTitle,
  },
  emits: ['close'],
  setup() {
    const { linkedProbableIncidents } = useEventInjections()

    const notConfirmedIncidents = computed(() =>
      linkedProbableIncidents.value.filter(
        (probableIncident) =>
          probableIncident.incident.confirmation === Confirmation.ToBeDone,
      ),
    )

    const confirmedIncidents = computed(() =>
      linkedProbableIncidents.value.filter(
        (probableIncident) =>
          probableIncident.incident.confirmation &&
          [Confirmation.Confirmed, Confirmation.Cancelled].includes(
            probableIncident.incident.confirmation,
          ),
      ),
    )

    return {
      confirmedIncidents,
      notConfirmedIncidents,
    }
  },
})
</script>

<template>
  <div>
    <PopupTitle>
      <template #title>Confirm incidents</template>
    </PopupTitle>
    <div>
      <div
        v-for="probableLinkedIncident in notConfirmedIncidents"
        :key="probableLinkedIncident.incident.id"
        class="flex flex-row items-stretch pb-2 pl-4 pr-4"
      >
        <ConfirmIncident v-bind="{ probableLinkedIncident }" />
      </div>
      <div
        v-for="probableLinkedIncident in confirmedIncidents"
        :key="probableLinkedIncident.incident.id"
        class="pl-4 pr-4 pt-2"
      >
        <ConfirmedIncident v-bind="{ probableLinkedIncident }" />
      </div>
    </div>
  </div>
</template>
