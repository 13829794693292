<script lang="ts">
import { chain } from 'lodash'
import { computed, defineComponent, PropType } from 'vue'

import {
  Confirmation,
  SoccerEventStatusId,
  SoccerIncidentId,
  SoccerResultId,
} from '@collector/sportsapi-client-legacy'
import ParticipantLogo from '@mobile/components/ParticipantLogo/ParticipantLogo.vue'
import { byId } from '@mobile/reusables/entityUtils'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: { ParticipantLogo },
  props: {
    incidentId: {
      type: Number,
      required: true,
    },
    time: Object as PropType<{ minute: number; second: number }>,
  },
  emits: ['sidesSwitched'],
  setup() {
    const {
      probableAwayParticipant,
      probableHomeParticipant,
      sidesSwitched,
      probableIncidents,
    } = useEventInjections()

    const penaltyResult = computed(
      () =>
        `${byId(probableHomeParticipant.value.results, SoccerResultId.Penalty)?.value ?? '0'} : ${
          byId(probableAwayParticipant.value.results, SoccerResultId.Penalty)
            ?.value ?? '0'
        }`,
    )

    const homePenaltyIncidents = computed(() =>
      chain(probableIncidents.value)
        .filter(
          (incident) =>
            (incident.event_status_id === SoccerEventStatusId.PenaltyShootout &&
              incident.participant_id === probableHomeParticipant.value.id &&
              incident.incident_id === SoccerIncidentId.Goal &&
              incident.confirmation !== Confirmation.Cancelled) ||
            (incident.event_status_id === SoccerEventStatusId.PenaltyShootout &&
              incident.participant_id === probableHomeParticipant.value.id &&
              incident.incident_id === SoccerIncidentId.MissedPenalty &&
              incident.confirmation !== Confirmation.Cancelled),
        )
        .orderBy('ut', 'asc')
        .value(),
    )

    const awayPenaltyIncidents = computed(() =>
      chain(probableIncidents.value)
        .filter(
          (incident) =>
            (incident.event_status_id === SoccerEventStatusId.PenaltyShootout &&
              incident.participant_id === probableAwayParticipant.value.id &&
              incident.incident_id === SoccerIncidentId.Goal &&
              incident.confirmation !== Confirmation.Cancelled) ||
            (incident.event_status_id === SoccerEventStatusId.PenaltyShootout &&
              incident.participant_id === probableAwayParticipant.value.id &&
              incident.incident_id === SoccerIncidentId.MissedPenalty &&
              incident.confirmation !== Confirmation.Cancelled),
        )
        .orderBy('ut', 'asc')
        .value(),
    )

    return {
      probableAwayParticipant,
      probableHomeParticipant,
      SoccerIncidentId,
      sidesSwitched,
      homePenaltyIncidents,
      awayPenaltyIncidents,
      penaltyResult,
    }
  },
})
</script>

<template>
  <div class="h-3/5 translate-y-1/3 transform rounded bg-white p-4">
    <h2 class="text-gray text-center font-bold uppercase">Penalty shootout</h2>
    <div class="flex h-full flex-col">
      <div class="mt-4 flex justify-between">
        <div class="flex items-center justify-center">
          <ParticipantLogo
            class="h-8 w-8"
            :participant="probableHomeParticipant"
          />
          <div class="text-gray text-center font-bold uppercase">
            {{ probableHomeParticipant.acronym }}
          </div>
        </div>
        <div
          class="text-gray flex items-center justify-center text-2xl font-bold"
        >
          {{ penaltyResult }}
        </div>
        <div class="flex items-center justify-center">
          <ParticipantLogo
            class="h-8 w-8"
            :participant="probableAwayParticipant"
          />
          <div class="text-gray text-center font-bold uppercase">
            {{ probableAwayParticipant.acronym }}
          </div>
        </div>
      </div>
      <div class="mt-2 flex justify-between">
        <div class="flex flex-col items-start justify-center">
          <div
            v-if="!homePenaltyIncidents.length"
            class="mt-2 flex"
          >
            <div
              v-for="index2 in 5"
              :key="index2"
              class="bg-lightgray mr-2 h-3 w-4 rounded"
            ></div>
          </div>
          <div
            v-for="index in Math.ceil(homePenaltyIncidents.length / 5)"
            v-else
            :key="index"
            class="mt-2 flex"
          >
            <div
              v-for="penaltyIncident in homePenaltyIncidents.slice(
                (index - 1) * 5,
                index * 5,
              )"
              :key="penaltyIncident.id"
            >
              <div
                v-if="
                  penaltyIncident.incident_id === SoccerIncidentId.MissedPenalty
                "
                class="bg-red mr-2 h-3 w-4 rounded"
              ></div>
              <div
                v-else
                class="bg-green mr-2 h-3 w-4 rounded"
              ></div>
            </div>
            <div
              v-if="index * 5 - homePenaltyIncidents.length > 0"
              class="flex"
            >
              <div
                v-for="index2 in index * 5 - homePenaltyIncidents.length"
                :key="index2"
                class="bg-lightgray mr-2 h-3 w-4 rounded"
              ></div>
            </div>
          </div>
        </div>
        <div class="flex flex-col items-end justify-center">
          <div
            v-if="!awayPenaltyIncidents.length"
            class="mt-2 flex"
          >
            <div
              v-for="index2 in 5"
              :key="index2"
              class="bg-lightgray mr-2 h-3 w-4 rounded"
            ></div>
          </div>
          <div
            v-for="index in Math.ceil(awayPenaltyIncidents.length / 5)"
            v-else
            :key="index"
            class="mt-2 flex"
          >
            <div
              v-for="penaltyIncident in awayPenaltyIncidents.slice(
                (index - 1) * 5,
                index * 5,
              )"
              :key="penaltyIncident.id"
            >
              <div
                v-if="
                  penaltyIncident.incident_id === SoccerIncidentId.MissedPenalty
                "
                class="bg-red mr-2 h-3 w-4 rounded"
              ></div>
              <div
                v-else
                class="bg-green mr-2 h-3 w-4 rounded"
              ></div>
            </div>
            <div
              v-if="index * 5 - awayPenaltyIncidents.length > 0"
              class="flex"
            >
              <div
                v-for="index2 in index * 5 - awayPenaltyIncidents.length"
                :key="index2"
                class="bg-lightgray mr-2 h-3 w-4 rounded"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
