<script lang="ts">
import { defineComponent, onMounted, ref, toRefs, watch } from 'vue'

import Button from '@mobile/components/Button/Button.vue'
import * as app from '@mobile/globalState/app'
import * as popup from '@mobile/globalState/popup'
import PopupControls from '@mobile/views/Relation/Shared/Popup/PopupControls.vue'

export default defineComponent({
  components: {
    Button,
    PopupControls,
  },
  setup() {
    const { component, isVisible, showHeader, props } = toRefs(popup.state)
    const isMounted = ref(false)

    onMounted(() => {
      isMounted.value = true
    })

    watch(
      () => isVisible.value,
      (isVisible) => {
        if (isVisible) {
          app.addOverlay()
        } else {
          app.removeOverlay()
        }
      },
    )
    if (isVisible.value) {
      app.addOverlay()
    }

    function handleFinished(): void {
      popup.hide()
    }

    return {
      isMounted,
      handleFinished,
      hide: popup.hide,
      component,
      isVisible,
      showHeader,
      props,
    }
  },
})
</script>

<template>
  <div
    v-show="isVisible"
    id="popup"
    class="absolute inset-0 z-20"
    :class="{ 'top-10': showHeader, 'top-0': !showHeader }"
  >
    <div
      id="popup-content"
      class="bg-silver h-full overflow-x-hidden"
    >
      <component
        :is="component"
        v-if="isMounted && component"
        v-bind="props"
        @close="handleFinished"
      />
      <div id="popup-controls-padding"></div>
    </div>
  </div>
</template>
