import { onBeforeUnmount, ref, Ref, watch } from 'vue'

import {
  CollectorPushMessageEvent,
  NotUuidThatWasDispatchedByIncidentsQueue,
  WebsocketMessageConfirmator,
} from '@collector/sportsapi-client-legacy'
import { AppDatabase } from '@mobile/database/AppDatabase'
import { RepositoryEmitter } from '@mobile/database/Repository'

import { updatePairs, updatePairsByKey, updateProps } from './entityUtils'

export function useSynchronizedEvent(
  messageConfirmator: WebsocketMessageConfirmator,
  database: AppDatabase,
  eventId: number,
): { event: Ref<CollectorPushMessageEvent | null | undefined> } {
  const event: Ref<CollectorPushMessageEvent | null | undefined> =
    ref(undefined)
  database.events.getByEventId(eventId).then((rawEvent) => {
    event.value = rawEvent ?? null
  })

  const onCreateOrUpdate:
    | RepositoryEmitter<CollectorPushMessageEvent>['created']
    | RepositoryEmitter<CollectorPushMessageEvent>['updated'] = async (
    changedEvent,
  ) => {
    const eventValue = event.value
    if (eventValue && eventValue.eventId === changedEvent.eventId) {
      messageConfirmator.onUuid(
        changedEvent.uuid,
        () => {
          updateCollectorEventPushMessage(eventValue, changedEvent)
        },
        (err: Error) => {
          // Confirm event update that wasn't dispatched by incidents queue
          if (err instanceof NotUuidThatWasDispatchedByIncidentsQueue) {
            updateCollectorEventPushMessage(eventValue, changedEvent)
          }
        },
        false,
      )
    }
  }
  database.events.on('created', onCreateOrUpdate)
  database.events.on('updated', onCreateOrUpdate)

  const onDelete: RepositoryEmitter<CollectorPushMessageEvent>['deleted'] = (
    deletedEvent,
  ) => {
    if (event.value && event.value.eventId === deletedEvent.eventId) {
      event.value = null
    }
  }
  database.events.on('deleted', onDelete)

  const unwatch = watch(event, (newVal) => {
    if (!newVal) {
      database.events.off('created', onCreateOrUpdate)
      database.events.off('updated', onCreateOrUpdate)
      database.events.off('deleted', onDelete)
      unwatch()
    }
  })

  onBeforeUnmount(() => {
    unwatch()
    database.events.off('created', onCreateOrUpdate)
    database.events.off('updated', onCreateOrUpdate)
    database.events.off('deleted', onDelete)
  })

  return { event }
}

export function updateCollectorEventPushMessage(
  target: CollectorPushMessageEvent,
  updateData: CollectorPushMessageEvent,
): void {
  if (target.pushMessageUt > updateData.pushMessageUt) {
    return
  }

  // @TODO Add 'source' after types update
  updateProps(target, updateData, ['pushMessageUt', 'uuid'])
  updateProps(target.data, updateData.data, [
    'name',
    'sport_id',
    'status_id',
    'status_type',
    'bet_status',
    'bet_corners',
    'bet_cards',
    'relation_status',
    'verified_result',
    'scoutsfeed',
    'clock_time',
    'clock_status',
    'referee_id',
    'coverage_type',
    'betting',
  ])
  updatePairsByKey(
    'name',
    target.data.betting?.bet_statuses ?? [],
    updateData.data.betting?.bet_statuses ?? [],
    (targetBettingStatuses, updateBettingStatuses) => {
      updateProps(targetBettingStatuses, updateBettingStatuses, ['value'])
    },
  )
  updatePairs(
    target.data.details,
    updateData.data.details,
    (targetDetail, updateDataDetail) =>
      updateProps(targetDetail, updateDataDetail, ['value']),
  )
  updatePairs(
    target.data.participants,
    updateData.data.participants,
    (targetParticipant, updateDataParticipant) => {
      updateProps(targetParticipant, updateDataParticipant, [
        'counter',
        'name',
        'short_name',
        'acronym',
        'area_id',
        'area_code',
        'area_name',
        'ut',
      ])
      updatePairs(
        targetParticipant.stats,
        updateDataParticipant.stats,
        (targetStat, updateDataStat) =>
          updateProps(targetStat, updateDataStat, ['value']),
      )
      updatePairs(
        targetParticipant.results,
        updateDataParticipant.results,
        (targetResult, updateDataResult) =>
          updateProps(targetResult, updateDataResult, ['value']),
      )
    },
  )
}
