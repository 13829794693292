export enum HandballEventDetailId {
  // TODO: change common detail ids to `CommonEventDetailId`
  ExtraPeriodStartDate = 15,
  PeriodLength = 16,
  NumberOfPeriods = 17,
  FirstHalfStartDate = 19,
  SecondHalfStartDate = 20,
  ExtraPeriodLength = 50,
  TimerDirection = 124,
  Attendance = 141,
  PenaltyShootoutStartDate = 150,
  FirstExtraFirstHalfStartDate = 152,
  FirstExtraSecondHalfStartDate = 153,
  SecondExtraFirstHalfStartDate = 154,
  SecondExtraSecondHalfStartDate = 155,
  ExtraPeriodType = 157,
  HasLineups = 205,
  StartWithPossessionTeam = 229,
  PenaltyShootoutStartingTeam = 230,
}
