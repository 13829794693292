import { Event, SportId } from '@collector/sportsapi-client-legacy'
import { CollectorSport } from '@mobile/reusables/classes/CollectorSport'

import { eventPreparationEventDetailsAmericanFootballFactory } from './eventPreparationEventDetailsAmericanFootballFactory'
import { eventPreparationEventDetailsBasketball3x3Factory } from './eventPreparationEventDetailsBasketball3x3Factory'
import { eventPreparationEventDetailsBasketballFactory } from './eventPreparationEventDetailsBasketballFactory'
import { eventPreparationEventDetailsBeachVolleyballFactory } from './eventPreparationEventDetailsBeachVolleyballFactory'
import { eventPreparationEventDetailsCricketFactory } from './eventPreparationEventDetailsCricketFactory'
import { eventPreparationEventDetailsDefaultFactory } from './eventPreparationEventDetailsDefaultFactory'
import { eventPreparationEventDetailsHandballFactory } from './eventPreparationEventDetailsHandballFactory'
import { eventPreparationEventDetailsSoccerFactory } from './eventPreparationEventDetailsSoccerFactory'
import { eventPreparationEventDetailsVolleyballFactory } from './eventPreparationEventDetailsVolleyballFactory'
import { EventPreparationEventDetailsSportFactory } from './types'

const factories: {
  [key: number]: (
    event: Event,
    sport: CollectorSport,
  ) => EventPreparationEventDetailsSportFactory | undefined
} = {
  [SportId.Soccer]: eventPreparationEventDetailsSoccerFactory,
  [SportId.Handball]: eventPreparationEventDetailsHandballFactory,
  [SportId.Basketball]: eventPreparationEventDetailsBasketballFactory,
  [SportId.Cricket]: eventPreparationEventDetailsCricketFactory,
  [SportId.Volleyball]: eventPreparationEventDetailsVolleyballFactory,
  [SportId.BeachVolleyball]: eventPreparationEventDetailsBeachVolleyballFactory,
  [SportId.AmericanFootball]:
    eventPreparationEventDetailsAmericanFootballFactory,
  [SportId.Basketball3x3]: eventPreparationEventDetailsBasketball3x3Factory,
}

export function useEventPreparationEventDetailsFactory(
  event: Event,
  sport: CollectorSport,
): EventPreparationEventDetailsSportFactory {
  return (
    factories[sport.sportsAPIEntity.id](event, sport) ??
    eventPreparationEventDetailsDefaultFactory()
  )
}
