export const enum CricketEventStatusId {
  NotStarted = 1,
  Interrupted = 2,
  Cancelled = 3,
  Postponed = 5,
  StartDelayed = 6,
  Abandoned = 7,
  Finished = 11,
  FinishedAwardedWin = 12,
  Live = 18,
  AfterDayOne = 66,
  AfterDayTwo = 67,
  AfterDayThree = 68,
  AfterDayFour = 69,
  ToFinish = 152,
  FinishedAfterSuperOver = 246,
  FirstInningsHome = 247,
  FirstInningsAway = 249,
  SuperOverHome = 255,
  SuperOverBreak = 256,
  SuperOverAway = 257,
  InningsBreak = 385,
}

export const cricketInningsStatuses = [
  CricketEventStatusId.FirstInningsHome,
  CricketEventStatusId.FirstInningsAway,
  CricketEventStatusId.SuperOverHome,
  CricketEventStatusId.SuperOverAway,
]

export const cricketBreakEventStatuses = Object.seal(
  [] as CricketEventStatusId[],
)
