export enum IceHockeyIncidentAttributeNames {
  BlockedByTeammate = 'Blocked by teammate',
  ThreePlayers = '3 players',
  FourPlayers = '4 players',
  FivePlayers = '5 players',
  MinorTwoMinutes = 'Minor 2 min',
  MinorFiveMinutes = 'Minor 5 min',
  MisconductTenMinutes = 'Misconduct 10 min',
  GameMisconductTwentyMinutes = 'Game misconduct 20 min',
  MatchTwentyFiveMinutes = 'Match 25 min',
  Boarding = 'Boarding',
  Interference = 'Interference',
  Elbowing = 'Elbowing',
  Hooking = 'Hooking',
  Holding = 'Holding',
  HoldingTheStick = 'Holding the Stick',
  HighSticking = 'High Sticking',
  AbuseOfOfficials = 'Abuse of Officials',
  CrossChecking = 'Cross-checking',
  Roughing = 'Roughing',
  Charging = 'Charging',
  UnsportsmanlikeConduct = 'Unsportsmanlike Conduct',
  TooManyMen = 'Too Many Men',
  Fighting = 'Fighting',
  DelayOfGame = 'Delay of Game',
  Other = 'Other',
  NoPowerPlay = 'No power play',
  PenaltyGoal = 'Penalty goal',
  PowerPlay = 'Power play',
  Shorthanded = 'Shorthanded',
  EmptyNet = 'Empty net',
  EvenStrength = 'Even strength',
  HighDangerRangeGoal = 'High danger range goal',
  MidRangeGoal = 'Mid-range goal',
  LongRangeGoal = 'Long-range goal',
  BehindTheNet = 'Behind the net',
  BehindTheBlueLine = 'Behind the blue line',
  UpperLeftGoals = 'Upper left goals',
  UpperMiddleGoals = 'Upper middle goals',
  UpperRightGoals = 'Upper right goals',
  BottomLeftGoals = 'Bottom left goals',
  BottomMiddleGoals = 'Bottom middle goals',
  BottomRightGoals = 'Bottom right goals',
  PuckOut = 'Puck out',
  Fight = 'Fight',
  Icing = 'Icing',
  Offside = 'Offside',
  GoalieStopped = 'Goalie Stopped',
  ShiftedGoal = 'Shifted goal',
  Foul = 'Foul',
  Injury = 'Injury',
  PenaltySaved = 'Penalty saved',
}
