export const enum AmericanFootballEventStatusId {
  NotStarted = 1,
  StartDelayed = 6,
  Postponed = 5,
  FirstQuarter = 8,
  FirstHalf = 33,
  BreakAfterFirstQuarter = 212,
  SecondQuarter = 10,
  SecondHalf = 34,
  BreakAfterSecondQuarter = 213,
  Halftime = 9,
  ThirdQuarter = 19,
  BreakAfterThirdQuarter = 214,
  FourthQuarter = 20,
  BreakAfterFourthQuarter = 215,
  FirstExtraTime = 216,
  BreakAfterFirstExtraTime = 221,
  SecondExtraTime = 217,
  BreakAfterSecondExtraTime = 222,
  ThirdExtraTime = 218,
  BreakAfterThirdExtraTime = 223,
  FourthExtraTime = 219,
  BreakAfterFourthExtraTime = 224,
  FifthExtraTime = 220,
  Interrupted = 2,
  Abandoned = 7,
  Finished = 11,
  FinishedAfterExtraTime = 14,
  FinishedAwardedWin = 12,
  Cancelled = 3,
  ToFinish = 152,
}
