import { Component, shallowReactive } from 'vue'

export const state = shallowReactive({
  currentAlert: {
    component: null as Component | null,
    events: {} as Record<string, () => void>,
  },
})

export function showAlert<T extends string = string>(
  component: Component,
  events?: Record<T, () => void>,
): void {
  state.currentAlert = {
    component,
    events: events ?? {},
  }
}

export function closeAlert(): void {
  state.currentAlert = {
    component: null,
    events: {},
  }
}
