<script lang="ts">
import { computed, defineComponent } from 'vue'

import { CricketIncidentId } from '@collector/sportsapi-client-legacy'
import { useEventStatus } from '@mobile/views/Relation/Shared/EventStatus/useEventStatus'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import LegalIllegalBallHistory from '@mobile/views/Relation/Sports/Cricket/LiveView/LegalIllegalBallHistory/LegalIllegalBallHistory.vue'
import { useOverlays } from '@mobile/views/Relation/Sports/Cricket/useOverlays'

import ExtrasButton from '../../Buttons/ExtrasButton.vue'
import NoRunsButton from '../../Buttons/NoRunsButton.vue'
import RunsButton from '../../Buttons/RunsButton.vue'
import Overlay from '../Overlays/Overlay.vue'
import { resolveBowlerRunningIncidentRequired } from '../resolveBowlerRunningIncidentRequired'
import { resolveStartOverIncidentEnabled } from '../resolveStartOverIncident'

export default defineComponent({
  components: {
    IncidentButton,
    NoRunsButton,
    RunsButton,
    ExtrasButton,
    LegalIllegalBallHistory,
    Overlay,
  },
  setup() {
    const eventInjections = useEventInjections()
    const { isMatchStarted } = useEventStatus(eventInjections.probableEvent)
    const { visibleOverlay, overlayComponents } = useOverlays()
    const incidentButtonIsDisabled = computed(() => {
      const startOverIncidentEnabled =
        resolveStartOverIncidentEnabled(eventInjections)
      const runningBowlerInRequired =
        resolveBowlerRunningIncidentRequired(eventInjections)

      return startOverIncidentEnabled.value || runningBowlerInRequired.value
    })

    return {
      isMatchStarted,
      CricketIncidentId,
      visibleOverlay,
      overlayComponents,
      incidentButtonIsDisabled,
    }
  },
})
</script>

<template>
  <div
    class="relative grid grid-cols-6 content-between"
    style="grid-template-rows: auto 1fr auto"
  >
    <LegalIllegalBallHistory :class="{ invisible: visibleOverlay }" />
    <div
      v-if="isMatchStarted && !visibleOverlay"
      class="col-span-full grid grid-cols-3 grid-rows-2 gap-2"
    >
      <NoRunsButton
        class="h-full w-full"
        :size="'xl'"
        :disabled="incidentButtonIsDisabled"
      />
      <RunsButton
        class="h-full w-full"
        :size="'xl'"
        :disabled="incidentButtonIsDisabled"
      />
      <ExtrasButton
        class="h-full w-full"
        :size="'xl'"
        :disabled="incidentButtonIsDisabled"
      />
      <IncidentButton
        class="h-full w-full"
        :type="'vertical'"
        :withIcon="false"
        :incidentId="CricketIncidentId.PossibleWicket"
        :size="'xl'"
        :color="'white'"
        :disabled="incidentButtonIsDisabled"
      />
      <IncidentButton
        class="h-full w-full"
        :type="'vertical'"
        :withIcon="false"
        :incidentId="CricketIncidentId.PossibleBoundary"
        :size="'xl'"
        :color="'white'"
        :disabled="incidentButtonIsDisabled"
      />
      <IncidentButton
        class="h-full w-full"
        :withIcon="false"
        :type="'vertical'"
        :incidentId="CricketIncidentId.DeadBall"
        :size="'xl'"
        :color="'white'"
        :disabled="incidentButtonIsDisabled"
      />
    </div>

    <Overlay />
  </div>
</template>
