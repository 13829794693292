import {
  Event,
  Participant,
  ParticipantsRequestDTO,
  ParticipantSubType,
} from '@collector/sportsapi-client-legacy'
import * as app from '@mobile/globalState/app'
import * as sportsAPI from '@mobile/globalState/sportsAPI'

let currentSelectedReferee: Participant | null = null

type Result = {
  setCurrentSelectedReferee: (participant: Participant | null) => void
  getCurrentReferee: () => Promise<Participant | undefined>
  searchReferees: (refereeName: string) => Promise<Participant[]>
}

export function useEventReferee(probableEvent: Event): Result {
  const database = app.state.database

  async function getCurrentReferee(): Promise<Participant | undefined> {
    if (!probableEvent.referee_id) {
      return
    }

    if (currentSelectedReferee?.id === probableEvent.referee_id) {
      database.referees.put({
        eventId: probableEvent.id,
        referee: currentSelectedReferee,
      })

      return currentSelectedReferee
    }

    const refereeRecord = await database.referees.find(probableEvent.id)
    if (refereeRecord?.referee.id === probableEvent.referee_id) {
      return refereeRecord.referee
    }

    const queryOptions: ParticipantsRequestDTO = {
      sport_id: probableEvent.sport_id,
      id: probableEvent.referee_id,
      subtype: ParticipantSubType.Referee,
    }

    const response = await sportsAPI.state.client.getParticipants(queryOptions)
    const referee = response.api.data.participants[0]

    database.referees.put({
      eventId: probableEvent.id,
      referee,
    })

    return referee
  }

  async function searchReferees(refereeName: string): Promise<Participant[]> {
    const queryOptions: ParticipantsRequestDTO = {
      sport_id: probableEvent.sport_id,
      subtype: ParticipantSubType.Referee,
      full_name: refereeName,
    }

    const response = await sportsAPI.state.client.getParticipants(queryOptions)

    return response.api.data.participants
  }

  function setCurrentSelectedReferee(participant: Participant | null): void {
    currentSelectedReferee = participant
  }

  return {
    setCurrentSelectedReferee,
    getCurrentReferee,
    searchReferees,
  }
}
