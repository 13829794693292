import { AmericanFootballEventStatusId } from '@collector/sportsapi-client-legacy'

export const AmericanFootballManualAddStatusIds = [
  AmericanFootballEventStatusId.FirstQuarter,
  AmericanFootballEventStatusId.SecondQuarter,
  AmericanFootballEventStatusId.ThirdQuarter,
  AmericanFootballEventStatusId.FourthQuarter,
  AmericanFootballEventStatusId.FirstExtraTime,
  AmericanFootballEventStatusId.SecondExtraTime,
  AmericanFootballEventStatusId.ThirdExtraTime,
  AmericanFootballEventStatusId.FourthExtraTime,
  AmericanFootballEventStatusId.FifthExtraTime,
]
