<script setup lang="ts">
import { onUnmounted } from 'vue'

import ToggleSwitch from '@mobile/components/ToggleSwitch/ToggleSwitch.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

import { useSportOptions } from '../../Sports/composables/useSportOptions'
import { useEventRelationSettings } from '../../Sports/useEventRelationSettings'

// inject
const eventInjections = useEventInjections()
const { sport } = eventInjections
const sportOptionsComposable = useSportOptions(sport)
const { filterKeyPasses } = useEventRelationSettings(
  eventInjections,
  sportOptionsComposable,
)

onUnmounted(() => {
  filterKeyPasses.value = false
})
</script>

<template>
  <div class="flex flex-col">
    <span class="pb-1 text-xs">Key passes</span>
    <ToggleSwitch
      v-model:value="filterKeyPasses"
      class="-ml-2 py-2"
    />
  </div>
</template>
