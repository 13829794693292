<script lang="ts">
import { computed, defineComponent } from 'vue'

import { ActionQueueStatus } from '@mobile/ActionQueue/ActionQueue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import Processing from '@mobile/views/Relation/Shared/VerifyResults/ProcessingDialog/ProcessingDialog.vue'
import Verification from '@mobile/views/Relation/Shared/VerifyResults/VerificationForm/VerificationForm.vue'

export default defineComponent({
  components: {
    Processing,
    Verification,
  },
  setup() {
    const { actionQueue } = useEventInjections()

    const isProcessing = computed(() => {
      const isQueuePaused = [
        ActionQueueStatus.Paused,
        ActionQueueStatus.Pausing,
      ].includes(actionQueue.value.status)

      return actionQueue.value.queue.length || isQueuePaused
    })

    return { isProcessing }
  },
})
</script>

<template>
  <Processing
    v-if="isProcessing"
    class="mx-8 pt-12"
  />
  <Verification v-else />
</template>
