<script lang="ts">
import { computed, defineComponent } from 'vue'

import { useSportOptions } from '@mobile/views/Relation/DetailsPanel/Sports/composables/useSportOptions'
import { useEventRelationSettings } from '@mobile/views/Relation/DetailsPanel/Sports/useEventRelationSettings'
import HistoryItemTableRow from '@mobile/views/Relation/DetailsPanel/Table/HistoryItemTableRow.vue'
import Table from '@mobile/views/Relation/DetailsPanel/Table/Table.vue'
import { useHistoryIncidents } from '@mobile/views/Relation/DetailsPanel/Table/useHistoryIncidents'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: {
    Table,
    HistoryItemTableRow,
  },
  emits: ['openIncidentsDetailModal', 'openIncidentsDeleteModal'],
  setup() {
    const eventInjections = useEventInjections()
    const sportOptionsComposable = useSportOptions(eventInjections.sport)
    const { linkedProbableIncidents } = eventInjections
    const { pinnedIncidentIds } = useHistoryIncidents(eventInjections)
    const { enableFilters, hideIncidents } = useEventRelationSettings(
      eventInjections,
      sportOptionsComposable,
    )

    const pinnedIncidents = computed(() => {
      let incidents = linkedProbableIncidents.value

      if (hideIncidents.value.length && enableFilters.value) {
        incidents = incidents
          .filter((incident) => {
            return hideIncidents.value.every((hideIncident) => {
              return hideIncident !== incident.incident.incident_id
            })
          })
          .filter((probableIncident) => {
            return pinnedIncidentIds.value.some(
              (incidentId) => incidentId === probableIncident.incident.id,
            )
          })
      }

      return incidents.filter((probableIncident) => {
        return pinnedIncidentIds.value.some(
          (incidentId) => incidentId === probableIncident.incident.id,
        )
      })
    })

    return { pinnedIncidents }
  },
})
</script>

<template>
  <Table>
    <HistoryItemTableRow
      v-for="linkedIncident of pinnedIncidents"
      :key="`pinned-incident-${linkedIncident.incident.id}`"
      v-bind="{ linkedIncident, detailsButtonColor: 'red', isDeleted: false }"
      @openIncidentsDetailModal="
        (linkedIncident) => $emit('openIncidentsDetailModal', linkedIncident)
      "
      @openIncidentsDeleteModal="
        (linkedIncident) => $emit('openIncidentsDeleteModal', linkedIncident)
      "
    />
  </Table>
</template>
