import { Container } from 'inversify'
import { difference } from 'lodash'

import {
  BasketballEventStatusId,
  BasketballFoulAttribute,
  BasketballIncidentId,
  BasketballStatId,
  basketballStatusResultsMap,
} from '@collector/sportsapi-client-legacy'
import {
  addToEventResult,
  addToEventStat,
  changeEventStatus,
  setEventStat,
} from '@mobile/ActionQueue/Actions/Event/EventAction'
import { ClockStatusAction } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/IncidentsConfiguration'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindIncidentsConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.IncidentsConfiguration)
    .toDynamicValue(() => {
      return {
        [BasketballIncidentId.NotStarted]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.NotStarted,
            )
          },
        },
        [BasketballIncidentId.StartDelayed]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.StartDelayed,
            )
          },
        },
        [BasketballIncidentId.FirstQuarterStarted]: {
          clockStatusAction: ClockStatusAction.Start,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.FirstQuarter,
            )
          },
        },
        [BasketballIncidentId.SecondQuarterStarted]: {
          clockStatusAction: ClockStatusAction.Start,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.SecondQuarter,
            )
          },
        },
        [BasketballIncidentId.ThirdQuarterStarted]: {
          clockStatusAction: ClockStatusAction.Start,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.ThirdQuarter,
            )
          },
        },
        [BasketballIncidentId.FourthQuarterStarted]: {
          clockStatusAction: ClockStatusAction.Start,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.FourthQuarter,
            )
          },
        },
        [BasketballIncidentId.FinishedRegularTime]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.FinishedRegularTime,
            )
          },
        },
        [BasketballIncidentId.BreakAfterFirstQuarter]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.BreakAfterFirstQuarter,
            )
          },
        },
        [BasketballIncidentId.BreakAfterSecondQuarter]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.BreakAfterSecondQuarter,
            )
          },
        },
        [BasketballIncidentId.BreakAfterThirdQuarter]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.BreakAfterThirdQuarter,
            )
          },
        },
        [BasketballIncidentId.WaitingForOvertime]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.WaitingForOvertime,
            )
          },
        },
        [BasketballIncidentId.FirstOvertimeStarted]: {
          clockStatusAction: ClockStatusAction.Start,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.FirstOvertime,
            )
          },
        },
        [BasketballIncidentId.BreakAfterFirstOvertime]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.BreakAfterFirstOvertime,
            )
          },
        },
        [BasketballIncidentId.SecondOvertimeStarted]: {
          clockStatusAction: ClockStatusAction.Start,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.SecondOvertime,
            )
          },
        },
        [BasketballIncidentId.BreakAfterSecondOvertime]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.BreakAfterSecondOvertime,
            )
          },
        },
        [BasketballIncidentId.ThirdOvertimeStarted]: {
          clockStatusAction: ClockStatusAction.Start,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.ThirdOvertime,
            )
          },
        },
        [BasketballIncidentId.BreakAfterThirdOvertime]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.BreakAfterThirdOvertime,
            )
          },
        },
        [BasketballIncidentId.FourthOvertimeStarted]: {
          clockStatusAction: ClockStatusAction.Start,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.FourthOvertime,
            )
          },
        },
        [BasketballIncidentId.BreakAfterFourthOvertime]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.BreakAfterFourthOvertime,
            )
          },
        },
        [BasketballIncidentId.FifthOvertimeStarted]: {
          clockStatusAction: ClockStatusAction.Start,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.FifthOvertime,
            )
          },
        },
        [BasketballIncidentId.FirstHalfStarted]: {
          clockStatusAction: ClockStatusAction.Start,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.FirstHalf,
            )
          },
        },
        [BasketballIncidentId.BreakAfterFirstHalf]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.BreakAfterFirstHalf,
            )
          },
        },
        [BasketballIncidentId.SecondHalfStarted]: {
          clockStatusAction: ClockStatusAction.Start,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.SecondHalf,
            )
          },
        },
        [BasketballIncidentId.FinishedAfterOvertime]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.FinishedAfterOvertime,
            )
          },
        },
        [BasketballIncidentId.FinishedAwardedWin]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.FinishedAwardedWin,
            )
          },
        },
        [BasketballIncidentId.Interrupted]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.Interrupted,
            )
          },
        },
        [BasketballIncidentId.Cancelled]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.Cancelled,
            )
          },
        },
        [BasketballIncidentId.Abandoned]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.Abandoned,
            )
          },
        },
        [BasketballIncidentId.ToFinish]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.ToFinish,
            )
          },
        },
        [BasketballIncidentId.Postponed]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport) {
            changeEventStatus(
              probableEvent,
              sport,
              BasketballEventStatusId.Postponed,
            )
          },
        },
        [BasketballIncidentId.OnePointShotMade]: {
          clockStatusAction: ClockStatusAction.None,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            const resultsToUpdate =
              basketballStatusResultsMap[probableEvent.status_id] ?? []

            resultsToUpdate.forEach((result) => {
              addToEventResult(
                probableEvent,
                dto.participant_team_id,
                result,
                1,
              )
            })
          },
          applyDeleteIncidentResultToEvent(
            probableEvent,
            sport,
            probableIncident,
          ) {
            const resultsToUpdate =
              basketballStatusResultsMap[probableEvent.status_id] ?? []

            resultsToUpdate.forEach((result) => {
              addToEventResult(
                probableEvent,
                probableIncident.participant_id,
                result,
                -1,
              )
            })
          },
        },
        [BasketballIncidentId.TwoPointShotMade]: {
          clockStatusAction: ClockStatusAction.None,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            const resultsToUpdate =
              basketballStatusResultsMap[probableEvent.status_id] ?? []

            resultsToUpdate.forEach((result) => {
              addToEventResult(
                probableEvent,
                dto.participant_team_id,
                result,
                2,
              )
            })
          },
          applyDeleteIncidentResultToEvent(
            probableEvent,
            sport,
            probableIncident,
          ) {
            const resultsToUpdate =
              basketballStatusResultsMap[probableEvent.status_id] ?? []

            resultsToUpdate.forEach((result) => {
              addToEventResult(
                probableEvent,
                probableIncident.participant_id,
                result,
                -2,
              )
            })
          },
        },
        [BasketballIncidentId.ThreePointShotMade]: {
          clockStatusAction: ClockStatusAction.None,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            const resultsToUpdate =
              basketballStatusResultsMap[probableEvent.status_id] ?? []

            resultsToUpdate.forEach((result) => {
              addToEventResult(
                probableEvent,
                dto.participant_team_id,
                result,
                3,
              )
            })
          },
          applyDeleteIncidentResultToEvent(
            probableEvent,
            sport,
            probableIncident,
          ) {
            const resultsToUpdate =
              basketballStatusResultsMap[probableEvent.status_id] ?? []

            resultsToUpdate.forEach((result) => {
              addToEventResult(
                probableEvent,
                probableIncident.participant_id,
                result,
                -3,
              )
            })
          },
        },
        [BasketballIncidentId.FoulBonus]: {
          clockStatusAction: ClockStatusAction.None,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            setEventStat(
              probableEvent,
              dto.participant_team_id,
              BasketballStatId.FoulBonus,
              1,
            )
          },
          applyDeleteIncidentResultToEvent(
            probableEvent,
            sport,
            probableIncident,
          ) {
            setEventStat(
              probableEvent,
              probableIncident.participant_id,
              BasketballStatId.FoulBonus,
              0,
            )
          },
        },
        [BasketballIncidentId.Foul]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
          applyAddIncidentResultToEvent(probableEvent, sport, dto) {
            addToEventStat(
              probableEvent,
              dto.participant_team_id,
              BasketballStatId.CurrentQuarterFouls,
              1,
            )
          },
          applyUpdateIncidentResultToEvent(probableEvent, sport, dto) {
            const attributes = dto.attribute_ids
            const shouldDecreaseValue = !difference(attributes, [
              BasketballFoulAttribute.Technical,
              BasketballFoulAttribute.Offensive,
            ]).length

            if (shouldDecreaseValue) {
              addToEventStat(
                probableEvent,
                dto.participant_team_id,
                BasketballStatId.CurrentQuarterFouls,
                -1,
              )
            }
          },
          applyDeleteIncidentResultToEvent(
            probableEvent,
            sport,
            probableIncident,
          ) {
            const attributes = probableIncident.attribute_ids
            const shouldDecreaseValue =
              attributes.length === 0 ||
              attributes.includes(BasketballFoulAttribute.Unsportsmanlike)

            if (shouldDecreaseValue) {
              addToEventStat(
                probableEvent,
                probableIncident.participant_id,
                BasketballStatId.CurrentQuarterFouls,
                -1,
              )
            }
          },
        },
        [BasketballIncidentId.ThrowIn]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
        },
        [BasketballIncidentId.Timeout]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
        },
        [BasketballIncidentId.TvTimeout]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
        },
        [BasketballIncidentId.PlayUnderReview]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
        },
        [BasketballIncidentId.BreakDuringGame]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
        },
        [BasketballIncidentId.Injury]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: false,
        },
        [BasketballIncidentId.BreakAfter]: {
          clockStatusAction: ClockStatusAction.Stop,
          resetClockTime: true,
        },
        [BasketballIncidentId.InPossession]: {
          clockStatusAction: ClockStatusAction.Start,
          resetClockTime: false,
        },
        fallbackIncidentConfiguration: {
          clockStatusAction: ClockStatusAction.None,
          resetClockTime: false,
        },
      }
    })
    .inSingletonScope()
}
