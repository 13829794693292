<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { EventsLineupsParticipant } from '@collector/sportsapi-client-legacy'
import { useLineupsSelectOptions } from '@mobile/views/Relation/Shared/Lineups/useLineupsSelectOption'
import { IncidentActionComposable } from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import SelectPlayer from '@mobile/views/Relation/Shared/SelectPlayer/SelectPlayer.vue'

export default defineComponent({
  components: { SelectPlayer },
  props: {
    incidentActionSubstitutionIn: {
      type: Object as PropType<IncidentActionComposable>,
      required: true,
    },
    incidentActionSubstitutionOut: {
      type: Object as PropType<IncidentActionComposable>,
      required: true,
    },
    probableLinkedParticipant: {
      type: Object as PropType<ProbableLinkedParticipant>,
      required: true,
    },
  },
  setup(props) {
    const {
      selectedMainParticipant,
      selectedSecondaryParticipant,
      mainLineupsParticipants,
      secondaryLineupsParticipants,
      lineupsSelectOptions,
      updateMainParticipant,
      updateSecondaryParticipant,
    } = useLineupsSelectOptions(
      props.incidentActionSubstitutionIn.getProbableIncident(),
      props.probableLinkedParticipant.side,
    )

    function assignPlayerToSubstitutionOut(
      participant: EventsLineupsParticipant | null,
    ): void {
      updateMainParticipant(participant?.participant_id)
      props.incidentActionSubstitutionOut.updateIncident({
        participant_team_id: participant?.participant_team_id,
        subparticipant_id: participant?.participant_id,
        subparticipant_name: participant?.participant_name,
      })
    }

    function assignPlayerToSubstitutionIn(
      participant: EventsLineupsParticipant | null,
    ): void {
      updateSecondaryParticipant(participant?.participant_id)
      props.incidentActionSubstitutionIn.updateIncident({
        participant_team_id: participant?.participant_team_id,
        subparticipant_id: participant?.participant_id,
        subparticipant_name: participant?.participant_name,
      })
    }

    return {
      selectedMainParticipant,
      selectedSecondaryParticipant,
      mainLineupsParticipants,
      secondaryLineupsParticipants,
      lineupsSelectOptions,
      assignPlayerToSubstitutionOut,
      assignPlayerToSubstitutionIn,
    }
  },
})
</script>

<template>
  <div class="flex flex-1 space-x-6">
    <div class="flex w-full">
      <SelectPlayer
        :selectedPlayer="selectedMainParticipant"
        :lineupsSelectOptions
        :players="mainLineupsParticipants"
        :selectLabel="{ text: 'Out', classes: 'text-base font-bold w-12 mr-3' }"
        @selectedPlayerChanged="assignPlayerToSubstitutionOut"
      />
      <div class="mx-3"></div>
      <SelectPlayer
        :selectedPlayer="selectedSecondaryParticipant"
        :lineupsSelectOptions
        :players="secondaryLineupsParticipants"
        :selectLabel="{ text: 'In', classes: 'text-base font-bold w-12 mr-3' }"
        @selectedPlayerChanged="assignPlayerToSubstitutionIn"
      />
    </div>
  </div>
</template>
