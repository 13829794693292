import { ComputedRef, Ref } from 'vue'

import {
  Participant,
  SubParticipant,
  TeamSide,
} from '@collector/sportsapi-client-legacy'
import * as sportsAPI from '@mobile/globalState/sportsAPI'
import { getTeamSide } from '@mobile/reusables/entityUtils'
import router from '@mobile/router'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

type SeasonParticipantsComposable = {
  seasonParticipants: Ref<SubParticipant[]>
  reloadSeasonParticipants: () => Promise<void>
}

export function useSeasonParticipants(
  participant: ComputedRef<Participant>,
): SeasonParticipantsComposable {
  const ioc = router.currentRoute.value.meta.ioc
  if (!ioc) {
    throw Error(
      'IoC Container has to be instantiated and injected into route meta before using sport configuration',
    )
  }

  const { homeSeasonParticipants, awaySeasonParticipants } = ioc.get(
    RelationSportCommonIoCBindings.SeasonParticipants,
  )
  const probableEvent = ioc.get(RelationIoCBindings.ProbableEvent)

  const seasonParticipants =
    getTeamSide(probableEvent.value.participants, participant.value.id) ===
    TeamSide.Home
      ? homeSeasonParticipants
      : awaySeasonParticipants

  async function reloadSeasonParticipants(): Promise<void> {
    const response = await sportsAPI.state.client.getSeasonParticipants(
      participant.value.id,
      probableEvent.value.season_id,
    )

    seasonParticipants.value = response.api.data.participants
  }

  return {
    seasonParticipants,
    reloadSeasonParticipants,
  }
}
