export const enum Basketball3x3EventStatusId {
  NotStarted = 1,
  Interrupted = 2,
  Cancelled = 3,
  Postponed = 5,
  StartDelayed = 6,
  Abandoned = 7,
  Overtime = 139,
  FinishedAfterOvertime = 140,
  FinishedAwardedWin = 12,
  FinishedRegularTime = 11,
  WaitingForOvertime = 138,
  ToFinish = 152,
  RegularTime = 386,
}
