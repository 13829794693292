import { shallowReactive } from 'vue'
import wretch from 'wretch'

import {
  CurrentTimeResponse,
  ScoutMessengerClient,
  WebsocketMessageConfirmator,
} from '@collector/sportsapi-client-legacy'
import { ScoutMessengerConnection } from '@mobile/ScoutMessengerConnection/ScoutMessengerConnection'

const state = shallowReactive({
  client: null as unknown as ScoutMessengerClient,
  connection: null as unknown as ScoutMessengerConnection,
  isConnected: false,
  isInitialSynchronizationFinished: true,
  messageConfirmator: null as unknown as WebsocketMessageConfirmator,
})

function setClient(client: ScoutMessengerClient): void {
  state.client = client
}

function setConnection(socket: ScoutMessengerConnection): void {
  state.connection = socket
}

function setAsConnected(): void {
  state.isConnected = true
}

function setAsNotConnected(): void {
  state.isConnected = false
}

function setInitialSynchronizationAsStarted(): void {
  state.isInitialSynchronizationFinished = false
}

function setInitialSynchronizationAsFinished(): void {
  state.isInitialSynchronizationFinished = true
}

function setMessageConfirmator(
  messageConfirmator: WebsocketMessageConfirmator,
): void {
  state.messageConfirmator = messageConfirmator
}

async function validateSystemTime(): Promise<boolean> {
  const scoutMessengerURL = import.meta.env
    .VITE_SCOUT_MESSENGER_CONNECTION_SOCKET_URL
  const now = new Date().getTime()
  const response = await wretch(scoutMessengerURL)
    .url('/system/time/current')
    .get()
    .json<CurrentTimeResponse>()
  const requestTime = new Date().getTime() - now
  const systemTime = response.time + requestTime
  const hasValidTime =
    Math.abs(systemTime - new Date().getTime()) <
    Number(import.meta.env.VITE_MAXIMUM_TIME_DESYNC_MS)

  return hasValidTime
}

export {
  state,
  setClient,
  setConnection,
  setAsConnected,
  setAsNotConnected,
  setMessageConfirmator,
  setInitialSynchronizationAsStarted,
  setInitialSynchronizationAsFinished,
  validateSystemTime,
}
