import { get } from 'lodash'

import {
  IncidentsQueuePatchDTO,
  Participant,
} from '@collector/sportsapi-client-legacy'
import { DTO } from '@mobile/ActionQueue/DTO'
import { getCurrentEvent } from '@mobile/globalState/event'
import { getCurrentSport } from '@mobile/globalState/sport'
import * as sportsAPI from '@mobile/globalState/sportsAPI'
import { byId } from '@mobile/reusables/entityUtils'
import { IncidentsConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/IncidentsConfiguration'

import IncidentAction, { ProbableData } from './IncidentAction'
import IncidentId from './IncidentId'
import { isActionId, isIncidentId } from './ProbableIncident'

export default class UpdateIncident extends IncidentAction {
  public readonly type = 'UpdateIncident'

  constructor(
    incidentId: string,
    incidentsQueuePatchDTO: IncidentsQueuePatchDTO,
  )
  constructor(actionId: string, incidentsQueuePatchDTO: IncidentsQueuePatchDTO)
  constructor(
    public override readonly actionOrIncidentId: string,
    public readonly incidentsQueuePatchDTO: IncidentsQueuePatchDTO,
  ) {
    super()

    if (isIncidentId(actionOrIncidentId)) {
      this.incidentId = actionOrIncidentId
    } else if (isActionId(actionOrIncidentId)) {
      this.dependencies.push(actionOrIncidentId)
    }
  }

  public async execImpl(): Promise<string> {
    if (!this.incidentId) {
      throw Error(
        'Previous action needs to be completed before executing this one',
      )
    }

    const event = getCurrentEvent()
    const result = await sportsAPI.state.client.patchIncident(
      event.id,
      IncidentId.fromPushMessageFormat(this.incidentId),
      this.incidentsQueuePatchDTO,
    )

    return result.incident.data.id
  }

  /**
   *
   * @param param0
   * @param actionQueue
   * @param incidentsConfiguration
   * @param updateFirst When trying to compute probable incident from previous probable incidents
   *                    we pass single incident array which possibly can have parent id that won't match this action.
   *                    As a non breaking workaround we can update first element from the incidents array if this
   *                    param is true
   */
  public applyProbableResult(
    { probableEvent, probableIncidents }: ProbableData,
    incidentsConfiguration: IncidentsConfiguration,
    updateFirst = false,
  ): void {
    const sport = getCurrentSport()
    const incident = updateFirst
      ? probableIncidents[0]
      : probableIncidents.find(
          (probableIncident) => probableIncident.id === this.actionOrIncidentId,
        )

    if (probableEvent && incident) {
      get(
        incidentsConfiguration,
        incident.incident_id,
        incidentsConfiguration.fallbackIncidentConfiguration,
      ).applyUpdateIncidentResultToEvent?.(
        probableEvent,
        sport,
        this.incidentsQueuePatchDTO,
        incident,
      )
    }

    if (incident) {
      const dto = new DTO(this.incidentsQueuePatchDTO)

      if (dto.has('status_id')) {
        incident.event_status_id = dto.inner.status_id
        incident.event_status_name = this.resolveStatusName(
          dto.inner.status_id,
          sport,
        )
      }

      if (dto.has('minute', 'second')) {
        incident.event_time = this.resolveEventTime(dto.inner)
      }

      if (dto.has('participant_team_id')) {
        incident.participant_id = dto.inner.participant_team_id || null
        incident.participant_name = this.resolveParticipantName(dto.inner)
      }

      if (dto.has('subparticipant_id')) {
        incident.subparticipant_id = dto.inner.subparticipant_id
          ? dto.inner.subparticipant_id
          : null
        incident.subparticipant_name = dto.inner.subparticipant_name
      }

      if (dto.has('assistant_id')) {
        incident.additional_info = incident.additional_info || {}

        incident.additional_info.assistant_id = dto.inner.assistant_id
        if (dto.inner.assistant_name !== null)
          incident.additional_info.assistant_name = dto.inner.assistant_name
      }

      if (dto.has('secondary_assistant_id')) {
        incident.additional_info = incident.additional_info || {}

        incident.additional_info.secondary_assistant_id =
          dto.inner.secondary_assistant_id
        if (dto.inner.secondary_assistant_name !== null)
          incident.additional_info.secondary_assistant_name =
            dto.inner.secondary_assistant_name
      }

      if (dto.has('attribute_ids')) {
        incident.attribute_ids = dto.inner.attribute_ids || []
      }

      if (dto.has('properties')) {
        incident.properties = dto.inner.properties
      }

      if (dto.isTruthy('x_pos', 'y_pos')) {
        incident.x_pos = dto.inner.x_pos
        incident.y_pos = dto.inner.y_pos
      }

      if (dto.has('confirmation')) {
        incident.confirmation = dto.inner.confirmation
      }
    }
  }

  public getIncidentParticipant(
    participants: Participant[],
  ): Participant | undefined {
    const participantId = this.incidentsQueuePatchDTO.participant_team_id

    if (!participantId) {
      return undefined
    }

    return byId(participants, participantId)
  }
}
