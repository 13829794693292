import { Participant, ResultId } from '@collector/sportsapi-client-legacy'
import { CollectorSport } from '@mobile/reusables/classes/CollectorSport'
import {
  ParticipantData,
  ParticipantDataIdsSet,
  SportData,
} from '@mobile/views/Relation/Shared/VerifyResults/ParticipantsDataTable/Types'

export class ResultIdsSet
  extends Set<ResultId>
  implements ParticipantDataIdsSet<ResultId>
{
  getDataName(): string {
    return 'Results'
  }

  getRelatedParticipantData(participant: Participant): ParticipantData[] {
    return participant.results
  }

  getRelatedSportData(sport: CollectorSport): SportData[] {
    return sport.sportsAPIEntity.results
  }
}
