import { inRange } from 'lodash'

import {
  IncidentId,
  SoccerIncidentId,
  TeamSide,
} from '@collector/sportsapi-client-legacy'

const incidentRanges: { [key: number]: number[] } = {
  [SoccerIncidentId.InPossession]: [50, 100],
  [SoccerIncidentId.Attack]: [25, 50],
  [SoccerIncidentId.DangerousAttack]: [0, 25],
}

function flip(posX: number): number {
  return Math.abs(100 - posX)
}

export function ballPositionInRange(
  possessionSide: TeamSide,
  ballPosX: number,
  minRangePosX: number,
  maxRangePosX: number,
): boolean {
  const validPosX = possessionSide === TeamSide.Away ? ballPosX : flip(ballPosX)

  return inRange(validPosX, minRangePosX, maxRangePosX)
}

export function resolvePossessionIncidentId(
  possessionSide: TeamSide,
  posX: number,
): IncidentId {
  return (
    Number(
      Object.keys(incidentRanges).find((key) => {
        const [minPosX, maxPosX] = incidentRanges[Number(key)]

        return ballPositionInRange(possessionSide, posX, minPosX, maxPosX)
      }),
    ) || SoccerIncidentId.InPossession
  )
}
