<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import { CricketIncidentId } from '@collector/sportsapi-client-legacy'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'

import IllegalBall from './IllegalBall.vue'
import LegalBall from './LegalBall.vue'

export default defineComponent({
  components: {
    LegalBall,
    IllegalBall,
  },
  props: {
    incident: {
      type: Object as PropType<ProbableIncident>,
      required: true,
    },
    probableLinkedParticipant: {
      type: Object as PropType<ProbableLinkedParticipant>,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const isLegalBall = computed(
      () => props.incident.incident_id === CricketIncidentId.LegalBall,
    )

    const componentName = computed(() =>
      isLegalBall.value ? LegalBall : IllegalBall,
    )

    function closePopup(): void {
      emit('close')
    }

    return {
      componentName,
      closePopup,
    }
  },
})
</script>

<template>
  <component
    :is="componentName"
    :incident
    :probableLinkedParticipant
    @close="closePopup"
  />
</template>
