<script lang="ts">
import { computed, defineComponent, toRef } from 'vue'

import { ActionStatus } from '@mobile/ActionQueue/Action'
import { ActionQueueStatus } from '@mobile/ActionQueue/ActionQueue'
import Badge from '@mobile/components/Badge/Badge.vue'
import HeaderButton from '@mobile/components/HeaderButton/HeaderButton.vue'
import * as scoutMessenger from '@mobile/globalState/scoutMessenger'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

import { HeaderPopupKey } from '../HeaderPopupKey'

export default defineComponent({
  components: {
    HeaderButton,
    Badge,
  },
  emits: ['click'],
  setup() {
    const { actionQueue } = useEventInjections()
    const queueLength = computed(() => actionQueue.value.queue.length)
    const isQueueErrored = computed(() => {
      return (
        actionQueue.value.queue.length > 0 &&
        [ActionStatus.Errored, ActionStatus.Unknown].includes(
          actionQueue.value.queue[0].status,
        )
      )
    })
    const isQueueWorking = computed(() => {
      return (
        actionQueue.value.status === ActionQueueStatus.Working &&
        !isQueueErrored.value
      )
    })
    const isConnected = toRef(scoutMessenger.state, 'isConnected')

    return {
      isConnected,
      queueLength,
      isQueueWorking,
      isQueueErrored,
      HeaderPopupKey,
    }
  },
})
</script>

<template>
  <HeaderButton
    :pulse="isQueueErrored"
    data-test-id="QueueButton"
    @click="$emit('click', HeaderPopupKey.Queue)"
  >
    <FontAwesomeIcon
      :class="{ 'text-dimmedred': !isConnected }"
      icon="wifi"
    />
    <FontAwesomeIcon
      class="text-green text-xxxs absolute left-2 top-1"
      :class="{ 'text-red': !isQueueWorking }"
      :icon="isQueueWorking ? 'play' : 'pause'"
    />
    <Badge
      v-if="queueLength > 0"
      class="absolute left-6 top-5"
    >
      {{ queueLength }}
    </Badge>
  </HeaderButton>
</template>
