<script lang="ts">
import { defineComponent, onUnmounted } from 'vue'

import { opChain } from '@collector/shared-utils'
import { Basketball3x3IncidentId } from '@collector/sportsapi-client-legacy'
import PossessionButton from '@mobile/components/PossesionButton/PossessionButton.vue'
import { useEventStatus } from '@mobile/views/Relation/Shared/EventStatus/useEventStatus'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'
import { useBasketBall3x3Configuration } from '@mobile/views/Relation/Sports/Basketball3x3/BasketballRelationDependencies/useBasketBallConfiguration'
import { usePitchOverlays } from '@mobile/views/Relation/Sports/Basketball3x3/LiveView/Pitch/usePitchOverlays'

import { useTimeoutOver } from './useTimeoutOver'

export default defineComponent({
  components: { PossessionButton },
  emits: ['sidesSwitched'],
  setup() {
    const {
      probableFirstParticipant,
      probableSecondParticipant,
      sidesSwitched,
      probableEvent,
      actionQueue,
      probableIncidents,
      notDeletableProbableIncidents,
      probableClock,
    } = useEventInjections()
    const { incidentsConfiguration } = useSportConfiguration()
    const { overlayComponents, visibleOverlay } = usePitchOverlays()
    const { isMatchStarted } = useEventStatus(probableEvent)
    const { activeParticipant } = useBasketBall3x3Configuration()

    const { cleanup: cleanupUseTimeoutOver } = useTimeoutOver(
      actionQueue.value,
      probableIncidents,
      notDeletableProbableIncidents,
      probableClock,
      incidentsConfiguration,
    )

    onUnmounted(() => {
      cleanupUseTimeoutOver()
    })

    return {
      probableFirstParticipant,
      probableSecondParticipant,
      overlayComponents,
      visibleOverlay,
      sidesSwitched,
      isMatchStarted,
      activeParticipant,
      Basketball3x3IncidentId,
      opChain,
    }
  },
})
</script>

<template>
  <div
    class="relative col-start-1 col-end-8 row-start-2 row-end-5 flex h-full w-full"
  >
    <div
      class="bg-basketball3x3-bottom relative w-full overflow-hidden bg-cover"
    >
      <img
        ref="pitchTopRef"
        class="pointer-events-none absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform sm:w-full"
        :class="!isMatchStarted && 'h-full'"
        src="@mobile/assets/pitches/basketball3x3/top.svg"
      />

      <div
        v-if="!visibleOverlay"
        class="absolute inset-0 flex h-full w-full flex-col justify-end space-y-2 p-1"
      >
        <div class="flex w-full gap-4 px-2 py-2 sm:h-1/2 lg:h-1/3">
          <PossessionButton
            class="flex-1"
            :participant="probableFirstParticipant"
            :activeParticipant
            :inPossessionIncidentId="Basketball3x3IncidentId.InPossession"
          />
          <PossessionButton
            class="flex-1"
            :participant="probableSecondParticipant"
            :activeParticipant
            :inPossessionIncidentId="Basketball3x3IncidentId.InPossession"
          />
        </div>
      </div>

      <div
        v-for="(overlayComponent, index) in overlayComponents"
        v-show="
          overlayComponent.props.incidentId ===
          opChain(visibleOverlay, (v) => v.props.incidentId)
        "
        :key="index"
      >
        <component
          :is="overlayComponent.component"
          v-bind="overlayComponent.props"
          @sidesSwitched="$emit('sidesSwitched')"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pitch-bottom {
  background-size: 100% 100%;

  @media only screen and (min-width: 1024px) {
    background-position: center center;
    background-size: 300%;
  }
}
</style>
