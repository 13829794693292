import { CommonResultId } from '@collector/sportsapi-client-legacy'

import { ResultIdsSet } from '../../ParticipantsDataTable/ResultIdsSet'
import { StatIdsSet } from '../../ParticipantsDataTable/StatIdsSet'
import { VerifyResultsSportFactory } from './VerifyResultsSportFactory'

export function verifyResultsBasketball3x3Factory(): VerifyResultsSportFactory {
  function getResultsIdsSet(): ResultIdsSet {
    return new ResultIdsSet([
      CommonResultId.Result,
      CommonResultId.RegularTime,
      CommonResultId.Overtime,
    ])
  }

  function getStatIdsSet(): StatIdsSet {
    return new StatIdsSet([])
  }

  return {
    getResultsIdsSet,
    getStatIdsSet,
  }
}
