import {
  CricketEventDetailId,
  Event,
  relationStatuses,
  SportDetailInputType,
} from '@collector/sportsapi-client-legacy'
import { SelectOption } from '@mobile/components/Select/types/SelectOption'
import { CollectorSport } from '@mobile/reusables/classes/CollectorSport'

import {
  EventPreparationEventDetail,
  EventPreparationEventDetailsSportFactory,
} from './types'
import {
  getDetailName,
  getDetailSelectItems,
  getDetailSelectItemsWithPlaceholder,
} from './utils'

export function eventPreparationEventDetailsCricketFactory(
  event: Event,
  sport: CollectorSport,
): EventPreparationEventDetailsSportFactory {
  function getDetails(): EventPreparationEventDetail[] {
    return [
      {
        name: 'Relation status',
        fieldName: 'relation_status',
        type: SportDetailInputType.Select,
        items: relationStatuses,
      },
      {
        id: CricketEventDetailId.WeatherConditions,
        name: getDetailName(sport, CricketEventDetailId.WeatherConditions),
        type: SportDetailInputType.Select,
        items: getDetailSelectItemsWithPlaceholder(
          getDetailSelectItems(
            event,
            sport,
            CricketEventDetailId.WeatherConditions,
          ),
        ),
      },
      {
        id: CricketEventDetailId.PitchConditions,
        name: getDetailName(sport, CricketEventDetailId.PitchConditions),
        type: SportDetailInputType.Select,
        items: getDetailSelectItemsWithPlaceholder(
          getDetailSelectItems(
            event,
            sport,
            CricketEventDetailId.PitchConditions,
          ),
        ),
      },
      {
        id: CricketEventDetailId.EventFormat,
        name: getDetailName(sport, CricketEventDetailId.EventFormat),
        type: SportDetailInputType.Select,
        items: getDetailSelectItemsWithPlaceholder(
          getDetailSelectItems(event, sport, CricketEventDetailId.EventFormat),
        ),
      },
      {
        id: CricketEventDetailId.NumberOfInnings,
        name: getDetailName(sport, CricketEventDetailId.NumberOfInnings),
        type: SportDetailInputType.Select,
        items: getDetailSelectItemsWithPlaceholder(
          getDetailSelectItems(
            event,
            sport,
            CricketEventDetailId.NumberOfInnings,
          ),
        ),
      },
      {
        name: 'Event status',
        fieldName: 'status_id',
        type: SportDetailInputType.Select,
        items: getEventStatusesSelectItems(),
      },
      {
        id: CricketEventDetailId.TossWon,
        name: getDetailName(sport, CricketEventDetailId.TossWon),
        type: SportDetailInputType.Select,
        items: getDetailSelectItemsWithPlaceholder(
          getDetailSelectItems(event, sport, CricketEventDetailId.TossWon),
        ),
      },
      {
        id: CricketEventDetailId.RaisedTarget,
        name: getDetailName(sport, CricketEventDetailId.RaisedTarget),
        type: SportDetailInputType.Number,
      },
      {
        id: CricketEventDetailId.RaisedOvers,
        name: getDetailName(sport, CricketEventDetailId.RaisedOvers),
        type: SportDetailInputType.Number,
      },
      {
        id: CricketEventDetailId.MaximumOversPerBowler,
        name: getDetailName(sport, CricketEventDetailId.MaximumOversPerBowler),
        type: SportDetailInputType.Number,
      },
    ]
  }

  function getEventStatusesSelectItems(): SelectOption[] {
    return sport.sportsAPIEntity.statuses.map((status) => ({
      name: status.name,
      value: status.id,
    }))
  }

  return { getDetails }
}
