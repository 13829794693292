import type { Writable } from 'type-fest'

import {
  Event,
  IncidentId,
  IncidentsQueuePatchDTO,
  IncidentsQueuePostDTO,
  Sport,
} from '@collector/sportsapi-client-legacy'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'

export const enum ClockStatusAction {
  None = 'none',
  Start = 'start',
  Stop = 'stop',
}

export type IncidentConfiguration = {
  clockStatusAction?: ClockStatusAction
  resetClockTime?: boolean
  applyAddIncidentResultToEvent?: (
    probableEvent: Event,
    sport: Readonly<Sport>,
    dto: Readonly<IncidentsQueuePostDTO>,
    probableIncident: Writable<ProbableIncident, 'additional_info'>,
    probableIncidents: ProbableIncident[],
  ) => void
  applyUpdateIncidentResultToEvent?: (
    probableEvent: Event,
    sport: Readonly<Sport>,
    dto: Readonly<IncidentsQueuePatchDTO>,
    probableIncident: Readonly<ProbableIncident>,
  ) => void
  applyDeleteIncidentResultToEvent?: (
    probableEvent: Event,
    sport: Readonly<Sport>,
    probableIncident: Readonly<ProbableIncident>,
  ) => void
  showInAddIncidentModal?: boolean
}

export type IncidentsConfiguration = {
  [incidentId in IncidentId]?: IncidentConfiguration
} & {
  fallbackIncidentConfiguration: IncidentConfiguration
}
