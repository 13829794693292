import { useNavigationSeparator } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useNavigationSeparator'
import { usePopupButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/usePopupButton'
import { PopupBuilder } from '@mobile/views/Relation/Shared/Popup/builder/PopupBuilder'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'

import WicketOutcomePopupDetails from './WicketOutcomePopupDetails.vue'

export const WicketOutcomePopup = new PopupBuilder('Wicket Outcome')
  .appendBody(WicketOutcomePopupDetails)
  .setNavigationButtons([
    ...useNavigationSeparator(4),
    usePopupButton({
      props: {
        text: 'Close',
        color: 'red',
        onClick: (context: PopupContext | null) => context?.closeAllPopups(),
      },
    }),
  ])
  .build()
