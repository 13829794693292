import Dexie from 'dexie'
import { omit } from 'lodash'

import { AppNotification } from '@mobile/reusables/types/AppNotification'

import { Repository } from './Repository'

export type AppNotificationRecord = {
  eventId: number
  notifications: Omit<AppNotification, 'markAsRead' | 'markAsUnread'>[]
}

export class AppNotificationsRepository extends Repository<AppNotificationRecord> {
  static readonly tableName = 'notifications'
  static readonly indexes = 'eventId'

  constructor(database: Dexie) {
    super(
      database.table<AppNotificationRecord>(
        AppNotificationsRepository.tableName,
      ),
    )
  }

  public async find(
    eventId: number,
  ): Promise<AppNotificationRecord | undefined> {
    return this.table.where({ eventId }).last()
  }

  public async save(
    eventId: number,
    notifications: AppNotification[],
  ): Promise<void> {
    const omittedNotifications = notifications.map((notification) =>
      omit(notification, ['markAsRead', 'markAsUnread']),
    )

    await this.table.where({ eventId }).delete()
    await this.table.put({
      eventId,
      notifications: omittedNotifications,
    })
  }

  public async removeByEventId(eventId: number): Promise<number> {
    return this.table.where({ eventId }).delete()
  }
}
