<script lang="ts">
import { defineComponent, PropType, ref, watch } from 'vue'

import {
  getParticipantLogoUrl,
  logoPlaceholderUrl,
} from '@collector/shared-ui-domain'
import { Participant } from '@collector/sportsapi-client-legacy'

export default defineComponent({
  name: 'ParticipantLogo',
  props: {
    participant: {
      type: Object as PropType<Participant>,
      required: true,
    },
  },
  setup(props) {
    const logoUrl = ref<string>(getParticipantLogoUrl(props.participant))

    watch(
      () => props.participant,
      () => {
        logoUrl.value = getParticipantLogoUrl(props.participant)
      },
    )

    const changeToPlaceholderLogo = (): void => {
      if (logoUrl.value !== logoPlaceholderUrl) {
        logoUrl.value = logoPlaceholderUrl
      }
    }

    return {
      logoUrl,
      changeToPlaceholderLogo,
    }
  },
})
</script>

<template>
  <img
    :src="logoUrl"
    :alt="`${participant.short_name} logo`"
    @error="changeToPlaceholderLogo()"
  />
</template>
