import { Container } from 'inversify'

import {
  AttackAttribute,
  DangerousAttackAttribute,
  InPossessionAttribute,
  ShotOnTargetAttribute,
  SoccerGoalAttribute,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindAssignableIncidentConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.AssignableIncidentsConfiguration)
    .toDynamicValue(() => {
      return {
        [SoccerIncidentId.Goal]: {
          participants: {
            main: 'Player',
            secondary: 'Assist',
          },
          participantsConfiguration: {
            secondary: {
              loadOppositeTeam: false,
              optional: true,
            },
          },
          attributesConfiguration: {
            [SoccerGoalAttribute.OwnGoal]: {
              loadOppositeTeamWhenSelected: true,
            },
          },
        },
        [SoccerIncidentId.YellowCard]: { participants: { main: 'Player' } },
        [SoccerIncidentId.RedCard]: { participants: { main: 'Player' } },
        [SoccerIncidentId.Penalty]: {
          participants: { main: 'Penalty taker', secondary: 'Penalty winner' },
          participantsConfiguration: {
            disablePlayerFilteringRestriction: true,
            secondary: {
              loadOppositeTeam: false,
              optional: false,
            },
          },
        },
        [SoccerIncidentId.PenaltySavedByGoalkeeper]: {
          participants: { main: 'Player' },
        },
        [SoccerIncidentId.MissedPenalty]: { participants: { main: 'Player' } },
        [SoccerIncidentId.SubstitutionIn]: { participants: { main: 'Player' } },
        [SoccerIncidentId.SubstitutionOut]: {
          participants: { main: 'Player' },
        },
        [SoccerIncidentId.ShotOnTarget]: {
          participants: {
            main: 'Player',
            secondary: 'Last line defender',
          },
          participantsConfiguration: {
            secondary: {
              loadOppositeTeam: true,
              availableWhen: (probableIncident) => {
                return probableIncident.value.attribute_ids.includes(
                  ShotOnTargetAttribute.GoallineClearance,
                )
              },
            },
          },
        },
        [SoccerIncidentId.ShotOffTarget]: { participants: { main: 'Player' } },
        [SoccerIncidentId.ShotBlocked]: {
          participants: {
            main: 'Player',
            secondary: 'Blocked by',
          },
          participantsConfiguration: {
            secondary: {
              loadOppositeTeam: true,
              optional: true,
            },
          },
        },
        [SoccerIncidentId.Offside]: { participants: { main: 'Player' } },
        [SoccerIncidentId.Foul]: { participants: { main: 'Player' } },
        [SoccerIncidentId.Injury]: { participants: { main: 'Player' } },
        [SoccerIncidentId.Tackle]: { participants: { main: 'Player' } },
        [SoccerIncidentId.Interception]: { participants: { main: 'Player' } },
        [SoccerIncidentId.BallSkills]: { participants: { main: 'Player' } },
        [SoccerIncidentId.LooseBall]: { participants: { main: 'Player' } },
        [SoccerIncidentId.Attack]: {
          attributesConfiguration: {
            [AttackAttribute.KeyPass]: {
              loadOppositeTeamWhenSelected: false,
              showOnHistoryTableRow: true,
            },
          },
        },
        [SoccerIncidentId.InPossession]: {
          attributesConfiguration: {
            [InPossessionAttribute.KeyPass]: {
              loadOppositeTeamWhenSelected: false,
              showOnHistoryTableRow: true,
            },
          },
        },
        [SoccerIncidentId.DangerousAttack]: {
          attributesConfiguration: {
            [DangerousAttackAttribute.KeyPass]: {
              loadOppositeTeamWhenSelected: false,
              showOnHistoryTableRow: true,
            },
          },
        },
      }
    })
    .inSingletonScope()
}
