import {
  Event,
  EventsLineupsParticipant,
  LineupUpdateResponse,
  TeamSide,
} from '@collector/sportsapi-client-legacy'
import { Action } from '@mobile/ActionQueue/Action'
import * as scoutMessenger from '@mobile/globalState/scoutMessenger'
import * as sportsAPI from '@mobile/globalState/sportsAPI'
import { Milliseconds } from '@mobile/reusables/types/enums/Milliseconds'

export interface UpdateSubParticipantDTO {
  position: string | null
  captain?: boolean | null
  id: number | null
  name: string
  shirt_nr: number | null | string
  player_position?: number | null
  short_name?: string
}

export type UpdateLineupsDTO = {
  eventId: number
  participantId: number
  participantSide: TeamSide
  lineups: UpdateSubParticipantDTO[]
}

export type UpdateLineupsData = {
  probableHomeLineups: EventsLineupsParticipant[]
  probableAwayLineups: EventsLineupsParticipant[]
}

export class UpdateLineups extends Action<UpdateLineupsData> {
  public readonly type = 'UpdateLineups'

  constructor(
    public readonly updateLineupsDTO: UpdateLineupsDTO,
    private readonly skipWaiting = false,
  ) {
    super()
  }

  async execImpl(): Promise<void> {
    const { eventId, participantId, lineups } = this.updateLineupsDTO

    await this.confirmLineupUpdateRequest(
      eventId,
      sportsAPI.state.client.patchLineups(eventId, participantId, lineups),
    )
  }

  public applyProbableResult({
    probableHomeLineups,
    probableAwayLineups,
  }: UpdateLineupsData): void {
    const newLineups = this.transformDTOToDatabaseLineups(
      this.updateLineupsDTO.participantId,
    )
    const lineupsToChange = {
      [TeamSide.Home]: probableHomeLineups,
      [TeamSide.Away]: probableAwayLineups,
    }[this.updateLineupsDTO.participantSide]

    lineupsToChange.splice(0, lineupsToChange.length, ...newLineups)
  }

  private transformDTOToDatabaseLineups(
    participantId: number,
  ): EventsLineupsParticipant[] {
    return this.updateLineupsDTO.lineups.map((teamMember) => {
      return {
        participant_id: teamMember.id,
        participant_team_id: participantId,
        participant_is_captain: teamMember.captain ?? null,
        participant_name: teamMember.name,
        participant_short_name: teamMember.short_name ?? teamMember.name,
        participant_position: teamMember.position,
        participant_shirt_number: teamMember.shirt_nr,
        player_position: teamMember.player_position,
      }
    })
  }

  private async confirmLineupUpdateRequest(
    eventId: Event['id'],
    request: Promise<LineupUpdateResponse>,
  ): Promise<void> {
    if (this.skipWaiting) {
      return this.makeRequest(request)
    } else {
      const confirmationPromise =
        scoutMessenger.state.messageConfirmator.waitForLineupMessage(
          eventId,
          Milliseconds.TEN_SECONDS,
        )

      return confirmationPromise
    }
  }

  private async makeRequest(
    request: Promise<LineupUpdateResponse>,
  ): Promise<void> {
    const response = await request

    if (
      (response.api.data &&
        'message' in response.api.data &&
        response.api.data.message === 'Nothing changed') ||
      (Array.isArray(response.api.data) && response.api.data.length === 0)
    ) {
      return
    }
  }
}
