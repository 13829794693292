<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'

import { OnClickOutside } from '@vueuse/components'

export interface HeaderButtonDropdownItem {
  onClick: () => void
  title: string
  icon?: string
  color?: string
}

export default defineComponent({
  components: { OnClickOutside },
  props: {
    icon: {
      type: String,
      default: 'clipboard-list',
    },
    badgeCount: {
      type: Number,
      required: false,
    },
    items: {
      type: Array as PropType<HeaderButtonDropdownItem[]>,
      required: true,
    },
  },
  emits: ['click'],
  setup() {
    const isVisible = ref(false)

    const onClick = (): void => {
      isVisible.value = !isVisible.value
    }
    const onItemClicked = (item: HeaderButtonDropdownItem): void => {
      isVisible.value = false
      item.onClick()
    }
    const onClickOutside = (): void => {
      if (isVisible.value) {
        isVisible.value = false
      }
    }

    return {
      isVisible,
      onClick,
      onClickOutside,
      onItemClicked,
    }
  },
})
</script>

<template>
  <OnClickOutside @trigger="onClickOutside">
    <button
      class="text-darkblue active:bg-blue hover:bg-blue relative flex h-10 w-12 cursor-pointer items-center justify-center text-center hover:text-white active:text-white"
      @mousedown="onClick()"
    >
      <FontAwesomeIcon :icon />

      <div
        v-show="isVisible"
        class="text-blue absolute -left-20 top-9 z-30 mt-1 w-32 select-none bg-white text-xs tracking-normal"
      >
        <div
          v-for="item in items"
          :key="`eventHeaderButtonItem-${item.title}`"
          class="active:bg-blue hover:bg-blue border-silver -mt-px flex cursor-pointer items-center border border-solid bg-white pb-3 pl-3 pt-3 text-left hover:text-white active:text-white"
          :class="item.color"
          @mousedown.stop="onItemClicked(item)"
        >
          <FontAwesomeIcon
            v-if="item.icon"
            class="mr-2"
            :icon="item.icon"
          />
          {{ item.title }}
        </div>
      </div>
    </button>
  </OnClickOutside>
</template>
