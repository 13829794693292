import { computed, ComputedRef, Ref } from 'vue'

import { TeamSide } from '@collector/sportsapi-client-legacy'

export enum PossessionDirection {
  LeftToRight = 'ltr',
  RightToLeft = 'rtl',
}

type ReturnType = {
  possessionDirection: ComputedRef<PossessionDirection>
}

function flipDirection(direction: PossessionDirection): PossessionDirection {
  return direction === PossessionDirection.LeftToRight
    ? PossessionDirection.RightToLeft
    : PossessionDirection.LeftToRight
}

export function usePossessionDirection(
  sidesSwitched: Ref<boolean>,
  possessionSide: Ref<TeamSide>,
): ReturnType {
  const possessionDirection = computed(() => {
    let direction: PossessionDirection = PossessionDirection.LeftToRight

    if (sidesSwitched.value) {
      direction = flipDirection(direction)
    }

    if (possessionSide.value === TeamSide.Away) {
      direction = flipDirection(direction)
    }

    return direction
  })

  return { possessionDirection }
}
