<script lang="ts">
import { defineComponent, PropType } from 'vue'

import Button from '@mobile/components/Button/Button.vue'
import * as popup from '@mobile/globalState/popup'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'

import { NoBallBatRunsPopup } from '../Popups/NoBallBat/NoBallBatRunsPopup'

export default defineComponent({
  components: { Button },
  props: {
    context: {
      type: Object as PropType<PopupContext | null>,
      required: false,
    },
  },
  setup(props) {
    function handleClick(): void {
      if (props.context) {
        props.context?.openPopup(NoBallBatRunsPopup)
      } else {
        popup.show({
          component: NoBallBatRunsPopup,
          showHeader: true,
        })
      }
    }

    return { handleClick }
  },
})
</script>

<template>
  <Button
    :size="'sm'"
    :color="'white'"
    :withIcon="false"
    :type="'horizontal'"
    @click="handleClick"
  >
    No ball + bat
  </Button>
</template>
