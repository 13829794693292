import {
  BeachVolleyballCardsAttributeIds,
  BeachVolleyballIncidentId,
} from '@collector/sportsapi-client-legacy'
import { PopupBuilder } from '@mobile/views/Relation/Shared/Popup/builder/PopupBuilder'

export const RedCardPopup = new PopupBuilder('RedCard', {
  incident_id: BeachVolleyballIncidentId.RedCard,
  attribute_id: [BeachVolleyballCardsAttributeIds.RedCard],
})
  .withTeamSelection()
  .omitDetailsView()
  .build()
