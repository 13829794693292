<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({})
</script>

<template>
  <div class="table w-full">
    <div class="table-header-group">
      <div class="bg-lightgray table-row font-bold">
        <div class="table-cell p-2"></div>
        <div class="table-cell p-2">Time</div>
        <div class="table-cell p-2">Status</div>
        <div class="table-cell p-2">Event time</div>
        <div class="table-cell p-2"></div>
        <div class="table-cell p-2">Incident</div>
        <div class="table-cell py-2">Action</div>
      </div>
    </div>
    <div class="relative table-row-group bg-white">
      <slot></slot>
    </div>
  </div>
</template>
