import {
  BreakInPlatAttribute,
  CricketIncidentId,
} from '@collector/sportsapi-client-legacy'
import AttributesSelection from '@mobile/views/Relation/Shared/Popup/builder/AttributesSelection.vue'
import { useCloseButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useCloseButton'
import { useNavigationSeparator } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useNavigationSeparator'
import { PopupBuilder } from '@mobile/views/Relation/Shared/Popup/builder/PopupBuilder'

export const BreakInPlayPopup = new PopupBuilder('Break in play', {
  incident_id: CricketIncidentId.BreakInPlay,
})
  .omitDetailsView()
  .setNavigationButtons([
    ...useNavigationSeparator(3),
    useCloseButton({
      text: 'Close',
      color: 'red',
    }),
  ])
  .appendBody(AttributesSelection, {
    title: 'Additional Info',
    attributes: [
      BreakInPlatAttribute.BadLight,
      BreakInPlatAttribute.Drinks,
      BreakInPlatAttribute.Injury,
      BreakInPlatAttribute.Rain,
      BreakInPlatAttribute.TacticalTimeout,
      BreakInPlatAttribute.Other,
    ],
  })
  .build()
