<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import {
  CricketEventStatusId,
  CricketResultId,
} from '@collector/sportsapi-client-legacy'
import { byId } from '@mobile/reusables/entityUtils'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useCricketConfiguration } from '@mobile/views/Relation/Sports/Cricket/CricketRelationDependencies/useCricketConfiguration'
import { useCricketMainResultExtractor } from '@mobile/views/Relation/Sports/Cricket/useCricketMainResultExtractor'

export default defineComponent({
  props: {
    probableLinkedParticipant: {
      type: Object as PropType<ProbableLinkedParticipant>,
      required: true,
    },
  },
  setup() {
    const { activeParticipant } = useCricketConfiguration()
    const { probableEvent } = useEventInjections()
    const { extractedValues } = useCricketMainResultExtractor(
      activeParticipant,
      probableEvent,
    )

    // TOOD: remove all of it bellow when we will have proper computation of main result
    const overs = computed(() => {
      if (!activeParticipant.value.participant) {
        return '-'
      }

      return (
        byId(activeParticipant.value.participant.results, CricketResultId.Overs)
          ?.value ?? '-'
      )
    })

    const currentBall = computed(() => {
      if (!activeParticipant.value.participant) {
        return '-'
      }

      return (
        byId(
          activeParticipant.value.participant.results,
          CricketResultId.CurrentBall,
        )?.value ?? '-'
      )
    })

    const isSuperOverStatus = computed(() =>
      [
        CricketEventStatusId.SuperOverHome,
        CricketEventStatusId.SuperOverAway,
      ].includes(probableEvent.value.status_id as CricketEventStatusId),
    )

    return {
      extractedValues,
      overs,
      currentBall,
      isSuperOverStatus,
    }
  },
})
</script>

<template>
  <div
    class="shadow-condensed flex h-7 items-center justify-center rounded p-1 text-xl font-bold text-white"
    :class="probableLinkedParticipant.css.bgClass"
  >
    <template v-if="isSuperOverStatus">
      {{ extractedValues.overs }}.{{ extractedValues.currentBall }} Overs
    </template>
    <template v-else>{{ overs }}.{{ currentBall }} Overs</template>
  </div>
</template>
