import router from '@mobile/router'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
export function useSportConfiguration() {
  const ioc = router.currentRoute.value.meta.ioc
  if (!ioc) {
    throw Error(
      'IoC Container has to be instantiated and injected into route meta before using sport configuration',
    )
  }

  const eventStatusesConfiguration = ioc.get(
    RelationSportCommonIoCBindings.EventStatusesConfiguration,
  )
  const incidentHandlersConfiguration = ioc.get(
    RelationSportCommonIoCBindings.IncidentHandlersConfiguration,
  )
  const incidentsConfiguration = ioc.get(
    RelationSportCommonIoCBindings.IncidentsConfiguration,
  )
  const eventStatusesList = ioc.get(
    RelationSportCommonIoCBindings.EventStatusesList,
  )
  const lineupsConfiguration = ioc.get(
    RelationSportCommonIoCBindings.LineupsConfiguration,
  )
  const assignableIncidentsConfiguration = ioc.get(
    RelationSportCommonIoCBindings.AssignableIncidentsConfiguration,
  )
  const hooks = ioc.get(RelationSportCommonIoCBindings.EventNotificationHooks)
  const eventHeaderConfiguration = ioc.get(
    RelationSportCommonIoCBindings.EventHeaderConfiguration,
  )
  const eventClockConfiguration = ioc.get(
    RelationSportCommonIoCBindings.EventClockConfiguration,
  )

  return {
    eventStatusesConfiguration,
    incidentHandlersConfiguration,
    incidentsConfiguration,
    eventStatusesList,
    lineupsConfiguration,
    assignableIncidentsConfiguration,
    hooks,
    eventHeaderConfiguration,
    eventClockConfiguration,
  }
}
