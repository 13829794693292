import { ClassConstructor, classToPlain, plainToClass } from 'class-transformer'
import { cloneDeep } from 'lodash'

import {
  Event,
  EventsLineupsParticipant,
} from '@collector/sportsapi-client-legacy'
import { Action } from '@mobile/ActionQueue/Action'
import * as Actions from '@mobile/ActionQueue/Actions/Actions'
import {
  isActionId,
  isIncidentId,
  ProbableIncident,
} from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import { byId } from '@mobile/reusables/entityUtils'
import { IncidentsConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/IncidentsConfiguration'

import { ActionQueue } from './ActionQueue'
import EventAction from './Actions/Event/EventAction'
import AddIncident from './Actions/Incidents/AddIncident'
import DeleteIncident from './Actions/Incidents/DeleteIncident'
import IncidentAction from './Actions/Incidents/IncidentAction'
import UpdateIncident from './Actions/Incidents/UpdateIncident'
import { UpdateLineups } from './Actions/Lineups/UpdateLineups'

export function applyActionsResult(
  probableEvent: Event,
  probableIncidents: ProbableIncident[],
  probableHomeLineups: EventsLineupsParticipant[],
  probableAwayLineups: EventsLineupsParticipant[],
  actionQueue: ActionQueue,
  incidentsConfiguration: IncidentsConfiguration,
): void {
  actionQueue.queue.forEach((action) => {
    if (action instanceof EventAction) {
      action.applyProbableResult(probableEvent, incidentsConfiguration)
    } else if (action instanceof IncidentAction) {
      action.applyProbableResult(
        {
          probableIncidents,
          probableEvent,
        },
        incidentsConfiguration,
      )
    } else if (action instanceof UpdateLineups) {
      action.applyProbableResult({
        probableHomeLineups,
        probableAwayLineups,
      })
    } else {
      /* eslint-disable-next-line no-console */
      console.warn('Unknown action type')
    }
  })
}

export function applyIncidentActionsResult(
  probableIncidents: ProbableIncident[],
  actionQueue: ActionQueue,
  ignoreDeleteActions: boolean,
  incidentsConfiguration: IncidentsConfiguration,
): void {
  let queue: Action<unknown>[] = actionQueue.queue

  if (ignoreDeleteActions) {
    queue = queue.flatMap((action) =>
      action instanceof DeleteIncident ? [] : [action],
    )
  }

  queue
    .flatMap((action) => (action instanceof IncidentAction ? [action] : []))
    .forEach((incidentAction) =>
      incidentAction.applyProbableResult(
        { probableIncidents },
        incidentsConfiguration,
      ),
    )
}

export function serializeQueueAction(
  action: Action<unknown>,
): Record<string, unknown> {
  return classToPlain(action)
}

export function deserializeQueueAction(
  serializedAction: Record<string, unknown>,
): Action<unknown> {
  const actionClass = resolveSerializedActionClass(serializedAction)

  if (!actionClass) {
    throw Error('Cannot deserialize action - action class not found')
  }

  return plainToClass(actionClass, serializedAction)
}

function resolveSerializedActionClass(
  serializedAction: Record<string, unknown>,
): ClassConstructor<Action<unknown>> | null {
  const castAction = serializedAction as Pick<Action<unknown>, 'type'>

  switch (castAction.type) {
    case 'AddIncident':
      return Actions.AddIncident
    case 'DeleteIncident':
      return Actions.DeleteIncident
    case 'UpdateIncident':
      return Actions.UpdateIncident
    case 'UpdateLineups':
      return Actions.UpdateLineups
    case 'UpdateEvent':
      return Actions.UpdateEvent
    case 'UpdateEventParticipants':
      return Actions.UpdateEventParticipants
    case 'UpdateEventTime':
      return Actions.UpdateEventTime
    default: {
      /* eslint-disable-next-line no-console */
      console.info(
        'Cannot deserialize action - action class not found',
        serializedAction,
      )

      return null
    }
  }
}

export function resolveProbableIncident(
  action: IncidentAction,
  actionQueue: ActionQueue,
  notDeletableProbableIncidents: ProbableIncident[],
  incidentsConfiguration: IncidentsConfiguration,
): ProbableIncident | undefined {
  if (action instanceof AddIncident) {
    let probableIncident: ProbableIncident | undefined
    if (action.incidentId) {
      probableIncident = byId(notDeletableProbableIncidents, action.incidentId)
      probableIncident = cloneDeep(probableIncident)
    }

    return (
      probableIncident ?? action.createProbableIncident(incidentsConfiguration)
    )
  }

  if (action instanceof DeleteIncident) {
    if (isActionId(action.actionOrIncidentId)) {
      const _action = actionQueue.queue
        .flatMap((queueAction) =>
          queueAction instanceof IncidentAction ? queueAction : [],
        )
        .find((queueAction) => queueAction.id === action.actionOrIncidentId)

      // NOTE:
      // Dont apply probable result or incident will be removed and in consequence
      // we won't resolve probable incident
      if (_action) {
        return resolveProbableIncident(
          _action,
          actionQueue,
          notDeletableProbableIncidents,
          incidentsConfiguration,
        )
      }

      return
    } else if (isIncidentId(action.actionOrIncidentId)) {
      const probableIncident = byId(
        notDeletableProbableIncidents,
        action.actionOrIncidentId,
      )
      if (probableIncident) {
        return cloneDeep(probableIncident)
      }

      return
    }
  }

  if (action instanceof UpdateIncident) {
    let probableIncident: ProbableIncident | undefined
    if (isActionId(action.actionOrIncidentId)) {
      const _action = actionQueue.queue
        .flatMap((queueAction) =>
          queueAction instanceof IncidentAction ? queueAction : [],
        )
        .find((queueAction) => queueAction.id === action.actionOrIncidentId)

      if (_action) {
        probableIncident = resolveProbableIncident(
          _action,
          actionQueue,
          notDeletableProbableIncidents,
          incidentsConfiguration,
        )
      }
    } else if (isIncidentId(action.actionOrIncidentId)) {
      probableIncident = byId(
        notDeletableProbableIncidents,
        action.actionOrIncidentId,
      )
      if (probableIncident) {
        probableIncident = cloneDeep(probableIncident)
      }
    }

    if (probableIncident) {
      const tempProbableIncidents: ProbableIncident[] = [probableIncident]
      action.applyProbableResult(
        { probableIncidents: tempProbableIncidents },
        incidentsConfiguration,
        true,
      )

      return tempProbableIncidents[0]
    }
  }

  return undefined
}
