import { Container } from 'inversify'
import { ref, watch } from 'vue'

import {
  BasketballEventDetailId,
  BasketballIncidentId,
  Event,
  IncidentId,
  Participant,
  TeamSide,
} from '@collector/sportsapi-client-legacy'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import { ButtonColor } from '@mobile/components/Button/ButtonTypes'
import {
  getAwayTeam,
  getHomeTeam,
  getOppositeTeam,
  isHomeTeam,
} from '@mobile/reusables/entityUtils'
import {
  getOppositeTeamSideFromEventDetail,
  getTeamSideFromEventDetail,
} from '@mobile/reusables/eventUtils'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { BasketballRelationIoCBindings } from '@mobile/views/Relation/Sports/Basketball/BasketballRelationDependencies/BasketballRelationDependencies'

import { ActiveParticipant } from './ActiveParticipant'

const IncidentsChangingActiveParticipant: IncidentId[] = [
  BasketballIncidentId.Turnover,
  BasketballIncidentId.TwoPointShotMade,
  BasketballIncidentId.ThreePointShotMade,
]

const IncidentsRelatedToActiveParticipant: IncidentId[] = [
  ...IncidentsChangingActiveParticipant,
  BasketballIncidentId.ThreePointShotMissed,
  BasketballIncidentId.TwoPointShotMissed,
  BasketballIncidentId.OnePointShotMissed,
  BasketballIncidentId.DefensiveRebound,
  BasketballIncidentId.OffensiveRebound,
  BasketballIncidentId.Steal,
  BasketballIncidentId.OnePointShotMade,
  BasketballIncidentId.FreeThrowsAwarded,
  BasketballIncidentId.InPossession,
]

export function bindActiveParticipant(ioc: Container): void {
  let unwatch: () => void

  ioc
    .bind(BasketballRelationIoCBindings.ActiveParticipant)
    .toDynamicValue(async (ctx) => {
      const [probableIncidents, probableEvent] = await Promise.all([
        ctx.container.getAsync(RelationIoCBindings.ProbableIncidents),
        ctx.container.getAsync(RelationIoCBindings.ProbableEvent),
      ])

      const [lastIncident] = probableIncidents.value

      return ref(getActiveParticipant(lastIncident, probableEvent.value))
    })
    .inSingletonScope()
    .onActivation(async (ctx, activeParticipant) => {
      const [probableIncidents, probableEvent] = await Promise.all([
        ctx.container.getAsync(RelationIoCBindings.ProbableIncidents),
        ctx.container.getAsync(RelationIoCBindings.ProbableEvent),
      ])

      unwatch = watch(probableIncidents, () => {
        const [lastIncident] = probableIncidents.value
        const isLastIncidentRelatedToActiveParticipant =
          IncidentsRelatedToActiveParticipant.find(
            (incidentId) => incidentId === lastIncident?.incident_id,
          )

        if (isLastIncidentRelatedToActiveParticipant) {
          activeParticipant.value = getActiveParticipant(
            lastIncident,
            probableEvent.value,
          )
        }
      })

      return activeParticipant
    })
    .when(() => true)
    .onDeactivation(async () => unwatch())
}

function getActiveParticipant(
  lastIncident: ProbableIncident | undefined,
  probableEvent: Event,
): ActiveParticipant {
  const shouldChangeActiveParticipantToOpposite =
    IncidentsChangingActiveParticipant.includes(
      Number(lastIncident?.incident_id),
    ) ?? false

  return lastIncident === undefined || lastIncident.participant_id === null
    ? getActiveParticipantFromEventDetail(
        probableEvent,
        BasketballEventDetailId.JumpBallWinner,
        shouldChangeActiveParticipantToOpposite,
      )
    : getActiveParticipantFromIncident(
        probableEvent,
        lastIncident,
        shouldChangeActiveParticipantToOpposite,
      )
}

function getActiveParticipantFromEventDetail(
  probableEvent: Event,
  eventDetailId: BasketballEventDetailId,
  shouldChangeActiveParticipantToOpposite: boolean,
): ActiveParticipant {
  const side = shouldChangeActiveParticipantToOpposite
    ? getOppositeTeamSideFromEventDetail(probableEvent.details, eventDetailId)
    : getTeamSideFromEventDetail(probableEvent.details, eventDetailId)

  const participant =
    side === TeamSide.Home
      ? getHomeTeam(probableEvent.participants)
      : getAwayTeam(probableEvent.participants)

  return {
    participant: participant || null,
    side,
    color: side,
  }
}

function getActiveParticipantFromIncident(
  probableEvent: Event,
  lastActiveParticipantRelatedIncident: ProbableIncident,
  shouldChangeActiveParticipantToOpposite: boolean,
): { side: TeamSide; participant: Participant; color: ButtonColor } {
  const participant = probableEvent.participants.find(
    ({ id }) => id === lastActiveParticipantRelatedIncident.participant_id,
  )

  if (!participant) {
    throw Error(
      'Incidents related to active participant must be related to participant',
    )
  }

  if (shouldChangeActiveParticipantToOpposite) {
    const oppositeParticipant = getOppositeTeam(
      probableEvent.participants,
      participant,
    )!
    const side = isHomeTeam(participant) ? TeamSide.Away : TeamSide.Home

    return {
      participant: oppositeParticipant,
      color: side,
      side,
    }
  }

  const side = isHomeTeam(participant) ? TeamSide.Home : TeamSide.Away

  return {
    participant,
    color: side,
    side,
  }
}
