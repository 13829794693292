import { EventStatusId } from '../EventStatusId'
import { ResultId } from '../ResultId'
import { BeachVolleyballResultId } from './BeachVolleyballResultId'
import { BeachVolleyballEventStatusId } from './EventStatusId'

export const beachVolleyballStatusResultsMap: {
  [key in EventStatusId]?: ResultId[]
} = {
  [BeachVolleyballEventStatusId.FirstSet]: [BeachVolleyballResultId.FirstSet],
  [BeachVolleyballEventStatusId.SecondSet]: [BeachVolleyballResultId.SecondSet],
  [BeachVolleyballEventStatusId.ThirdSet]: [BeachVolleyballResultId.ThirdSet],
  [BeachVolleyballEventStatusId.GoldenSet]: [BeachVolleyballResultId.GoldenSet],
}
