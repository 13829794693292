import {
  EventsLineupsParticipant,
  ParticipantSubType,
} from '@collector/sportsapi-client-legacy'

export function getFilteredSelectOptions(
  lineups: EventsLineupsParticipant[],
  searchValue: string,
): {
  name: string
  value: number | null
}[] {
  const filteredParticipantsByCoach = filterParticipantsByCoachPosition(lineups)

  if (!lineups) {
    return mapParticipants(filteredParticipantsByCoach)
  }

  if (searchValue.length && !isNaN(Number(searchValue))) {
    return mapParticipants(
      filterParticipantsByShirtNumber(filteredParticipantsByCoach, searchValue),
    )
  }

  return mapParticipants(
    filterParticipantsBySearchValue(filteredParticipantsByCoach, searchValue),
  )
}

function mapParticipants(participants: EventsLineupsParticipant[]): {
  name: string
  value: number | null
}[] {
  return participants.map((participant) => {
    return {
      name: `${participant.participant_shirt_number !== null && participant.participant_shirt_number !== undefined ? participant.participant_shirt_number + ' - ' : ''}  ${
        participant.participant_name
      } ${participant.participant_position ? '(' + participant.participant_position + ')' : ''}`,
      value: participant.participant_id,
    }
  })
}

function filterParticipantsByShirtNumber(
  participants: EventsLineupsParticipant[],
  shirtNumber: string,
): EventsLineupsParticipant[] {
  return participants.filter(
    (participant) =>
      participant.participant_shirt_number === Number(shirtNumber),
  )
}

function filterParticipantsBySearchValue(
  participants: EventsLineupsParticipant[],
  searchValue: string,
): EventsLineupsParticipant[] {
  return participants.filter((participant) =>
    participant.participant_name
      .toLocaleLowerCase()
      .includes(searchValue.toLocaleLowerCase()),
  )
}

function filterParticipantsByCoachPosition(
  participants: EventsLineupsParticipant[],
): EventsLineupsParticipant[] {
  return participants.filter(
    (participant) =>
      participant.participant_position !== ParticipantSubType.Coach,
  )
}
