export enum IceHockeyIncidentId {
  StartDelayed = 155,
  MatchAboutToStart = 2704,
  FirstPeriodStarted = 115,
  BreakAfterFirstPeriod = 2263,
  SecondPeriodStarted = 116,
  BreakAfterSecondPeriod = 2264,
  ThirdPeriodStarted = 117,
  WaitingForOvertime = 53,
  FirstOvertimeStarted = 2259,
  BreakAfterFirstOvertime = 2266,
  SecondOvertimeStarted = 2260,
  BreakAfterSecondOvertime = 2277,
  ThirdOvertimeStarted = 2261,
  BreakAfterThirdOvertime = 2278,
  FourthOvertimeStarted = 2280,
  PenaltiesStarted = 147,
  Finished = 16,
  FinishedAfterOvertime = 81,
  FinishedAfterPenalties = 80,
  Interrupted = 45,
  Suspension = 2281,
  PenaltyShot = 70,
  Goal = 64,
  SuspensionOver = 2706,
  Stoppage = 2709,
  FaceOff = 2707,
  WinningFaceOff = 145,
  Attack = 48,
  DelayedPenalty = 2271,
  ShotBlocked = 52,
  ShotOffTarget = 51,
  ShotOnTarget = 50,
  GoalkeeperOut = 2710,
  GoalkeeperIn = 2711,
  GoalkeeperChange = 2270,
  Timeout = 77,
  EmptyNet = 119,
  GoalkeeperSaved = 57,
  TimeoutOver = 2708,
  BackGoalkeeper = 146,
  MissedPenalty = 79,
  FaceOffCenter = 122,
  TelevisionTimeout = 78,
  TelevisionTimeoutOver = 2705,
  UnderReview = 2258,
  Hit = 2918,
}
