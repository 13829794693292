<script lang="ts">
import { isNil } from 'lodash'
import { computed, ComputedRef, defineComponent, PropType } from 'vue'

import {
  AmericanFootballIncidentId,
  DetailType,
  IncidentActionType,
  IncidentAttributeId,
  PassAttribute,
  SportId,
} from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import { ButtonColor } from '@mobile/components/Button/ButtonTypes'
import {
  getIncidentAttributeId,
  useSportOptions,
} from '@mobile/views/Relation/DetailsPanel/Sports/composables/useSportOptions'
import { SoccerGroupNames } from '@mobile/views/Relation/DetailsPanel/Sports/Soccer/SoccerDetailsConfiguration'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

type GroupedImportantAttributes = Record<
  string,
  {
    name: string
    attributeIds?: number[]
    attributes?: string[]
    type: DetailType
    important: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fulfilledWhen: (attributeIds: number[], incident: any) => boolean
    min?: number
    max?: number
    placeholder?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    optionsFactory?: any
  }
>

enum DetailsButtonState {
  NotFilled = 'not-filled',
  HalfFilled = 'half-filled',
  Filled = 'filled',
}

const buttonColorsToState: Record<DetailsButtonState, ButtonColor> = {
  [DetailsButtonState.NotFilled]: 'red',
  [DetailsButtonState.HalfFilled]: 'orange',
  [DetailsButtonState.Filled]: 'green',
}

export default defineComponent({
  components: { Button },
  props: {
    linkedIncident: {
      type: Object as PropType<ProbableLinkedIncident>,
      required: true,
    },
  },
  setup(props) {
    const { sport } = useEventInjections()
    const { detailsConfiguration } = useSportOptions(sport)
    const incidentDetailsConfiguration =
      detailsConfiguration[props.linkedIncident.incident.incident_id]
    const isDeleted = computed(
      () => props.linkedIncident.incident.action === IncidentActionType.DELETE,
    )
    const importantDetails =
      incidentDetailsConfiguration?.filter((detail) => detail.important) ?? []

    const groupedImportantAttributes: GroupedImportantAttributes =
      Object.fromEntries(
        importantDetails.map((detail) => {
          if (detail.type === DetailType.Button) {
            return [
              detail.name,
              {
                name: detail.name,
                attributeIds: detail.attributes.map((attributeName) =>
                  getIncidentAttributeId(props.linkedIncident, attributeName),
                ),
                attributes: detail.attributes,
                type: detail.type,
                important: detail.important,
                fulfilledWhen: detail.fulfilledWhen,
              },
            ]
          } else if (detail.type === DetailType.Input) {
            return [
              detail.name,
              {
                name: detail.name,
                type: detail.type,
                important: detail.important,
                fulfilledWhen: detail.fulfilledWhen,
                min: detail.min,
                max: detail.max,
                placeholder: detail.placeholder,
              },
            ]
          } else if (detail.type === DetailType.Select) {
            return [
              detail.name,
              {
                name: detail.name,
                type: detail.type,
                important: detail.important,
                fulfilledWhen: detail.fulfilledWhen,
                optionsFactory: detail.optionsFactory,
                attributeIds: detail.attributes.map((attributeName) =>
                  getIncidentAttributeId(props.linkedIncident, attributeName),
                ),
              },
            ]
          } else {
            return []
          }
        }),
      )

    function hasIncidentFilledProperty(propertyName: string): boolean {
      const incidentProperty = props.linkedIncident.incident.properties?.find(
        ({ short_name }) =>
          short_name.toLowerCase() === propertyName.toLowerCase(),
      )

      return !isNil(incidentProperty?.value)
    }

    function hasIncidentFilledAttributes(attributes: number[]): boolean {
      const incidentAttributes = props.linkedIncident.incident.attribute_ids

      return attributes.some((attributeId) =>
        incidentAttributes.includes(attributeId),
      )
    }

    function hasSetAttribute(attributeId: IncidentAttributeId): boolean {
      const incidentAttributes = props.linkedIncident.incident.attribute_ids

      return incidentAttributes.includes(attributeId)
    }

    function getStatusForEntriesCount(count: number): DetailsButtonState {
      const record: Record<number, DetailsButtonState> = {
        0: DetailsButtonState.NotFilled,
        [importantDetails.length]: DetailsButtonState.Filled,
      }

      return record[count] ?? DetailsButtonState.HalfFilled
    }

    /**
     * TODO: whole logic must be refactored
     * This needs to be done https://statscore.atlassian.net/browse/COLL-678
     */
    const buttonState = computed<DetailsButtonState>(() => {
      let filledEntries = 0

      for (const key in groupedImportantAttributes) {
        const detail = groupedImportantAttributes[key]

        if (detail.type === DetailType.Input) {
          filledEntries += hasIncidentFilledProperty(detail.name) ? 1 : 0
        }

        if (
          [DetailType.Button, DetailType.Select].includes(detail.type) &&
          detail.attributeIds &&
          detail.attributeIds.length
        ) {
          filledEntries += hasIncidentFilledAttributes(detail.attributeIds)
            ? 1
            : 0
        }

        // this is simple hack to provide proper button state but needs to be refactored
        switch (sport.value.sportsAPIEntity.id) {
          case SportId.Soccer:
          case SportId.IceHockey:
            if (detail.name === SoccerGroupNames.Goalkeeper) {
              filledEntries += detail.fulfilledWhen(
                [],
                props.linkedIncident.incident,
              )
                ? 1
                : 0
            }
            break
          default:
            break
        }
      }

      if (sport.value.sportsAPIEntity.id === SportId.AmericanFootball) {
        if (
          props.linkedIncident.incident.incident_id ===
          AmericanFootballIncidentId.Pass
        ) {
          if (
            !hasIncidentFilledAttributes(
              groupedImportantAttributes['Details'].attributeIds ?? [],
            )
          ) {
            filledEntries = 0
          } else if (hasSetAttribute(PassAttribute.PassIncomplete)) {
            filledEntries = importantDetails.length
          }
        }
      }

      return getStatusForEntriesCount(filledEntries)
    })

    const buttonColor: ComputedRef<ButtonColor> = computed(
      () => buttonColorsToState[buttonState.value],
    )

    return {
      buttonColor,
      isDeleted,
    }
  },
})
</script>

<template>
  <Button
    :color="buttonColor"
    :size="'xs'"
    :disabled="isDeleted"
  >
    Details
  </Button>
</template>
