<script lang="ts">
import { defineComponent, toRef } from 'vue'

import { opChain } from '@collector/shared-utils'
import Header from '@mobile/components/Header/Header.vue'
import HeaderButton from '@mobile/components/HeaderButton/HeaderButton.vue'
import * as scoutMessenger from '@mobile/globalState/scoutMessenger'

export default defineComponent({
  components: {
    Header,
    HeaderButton,
  },
  props: { onLogoutClick: { type: Function } },
  setup() {
    const isConnected = toRef(scoutMessenger.state, 'isConnected')

    return { isConnected, opChain }
  },
})
</script>

<template>
  <Header class="relative">
    <div class="ml-auto flex">
      <HeaderButton
        class="border-silver pointer-events-none border-l border-solid px-2"
        :pulse="!isConnected"
      >
        <FontAwesomeIcon
          :class="{ 'text-dimmedred': !isConnected }"
          icon="wifi"
        />
      </HeaderButton>
      <HeaderButton
        class="border-silver w-auto border-l border-solid px-6"
        @click="opChain(onLogoutClick, (v) => v())"
      >
        <FontAwesomeIcon icon="sign-out-alt" />
        <span class="pl-1 text-sm">Logout</span>
      </HeaderButton>
    </div>
  </Header>
</template>
