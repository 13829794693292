import {
  IceHockeyStatId,
  SoccerStatId,
  SportId,
  StatId,
} from '@collector/sportsapi-client-legacy'

type PartialRecord<K extends keyof never, T> = {
  [P in K]?: T
}

type DetailsPanelStatsMapElement = StatId[] | undefined

const detailsPanelStatsMap: PartialRecord<
  SportId,
  DetailsPanelStatsMapElement
> = {
  [SportId.Soccer]: [
    SoccerStatId.Corners,
    SoccerStatId.YellowCards,
    SoccerStatId.RedCards,
    SoccerStatId.Substitutions,
  ],
  [SportId.IceHockey]: [
    IceHockeyStatId.NumberOfPenalties,
    IceHockeyStatId.PenaltyMinutes,
    IceHockeyStatId.TotalShots,
    IceHockeyStatId.ShotsOnTarget,
    IceHockeyStatId.ShotsOffTarget,
    IceHockeyStatId.AttemptsBlocked,
  ],
}

export function getDetailsPanelStats(
  sportId: SportId,
): DetailsPanelStatsMapElement {
  return detailsPanelStatsMap[sportId]
}
