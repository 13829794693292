export enum IllegalBallAttributesId {
  Wides = 608,
  NoBall = 609,
  NoBallBat = 610,
  Wicket = 611,
  Zero = 612,
  One = 613,
  Two = 614,
  Three = 615,
  Four = 616,
  Five = 617,
  Six = 618,
  Seven = 619,
  Eight = 620,
  Nine = 621,
}
