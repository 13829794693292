<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { opChain } from '@collector/shared-utils'
import Button from '@mobile/components/Button/Button.vue'

import { Formation, Formations } from './Formation'

export default defineComponent({
  components: { Button },
  props: {
    currentFormation: {
      type: Object as PropType<Formation>,
      required: true,
    },
  },
  emits: ['formationSelected'],
  setup() {
    return { Formations, opChain }
  },
})
</script>

<template>
  <div class="bg-silver grid grid-cols-4 gap-4 p-2">
    <Button
      v-for="formation in Formations"
      :key="formation"
      :size="'md'"
      :color="
        formation === opChain(currentFormation, (v) => v.statValue)
          ? 'blue'
          : 'white'
      "
      @click="$emit('formationSelected', formation)"
    >
      {{ formation }}
    </Button>
  </div>
</template>
