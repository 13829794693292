<script lang="ts">
import dayjs from 'dayjs'
import JSZip from 'jszip'
import { orderBy } from 'lodash'
import { computed, defineComponent, Ref, ref, watch } from 'vue'

import Sentry from '@collector/shared-plugin-sentry-vue'
import { SendFeedback } from '@collector/shared-ui-domain'
import Select from '@mobile/components/Select/Select.vue'
import * as app from '@mobile/globalState/app'
import { pick } from '@mobile/reusables/entityUtils'
import { AppNotificationType } from '@mobile/reusables/types/AppNotificationType'
import PopupTitle from '@mobile/views/Relation/Shared/Popup/PopupTitle.vue'
import NotificationBlock from '@mobile/views/Relation/Shared/Popups/Notifications/NotificationBlock.vue'
import { Notifications } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

type SentryRequestStatus = null | 'sending' | 'sent' | 'error'

export default defineComponent({
  components: {
    PopupTitle,
    NotificationBlock,
    Select,
    SendFeedback,
  },
  setup() {
    const { event, probableEvent, notifications } = useEventInjections()
    const selectedFilterOptions: Ref<AppNotificationType[]> = ref(
      notifications.currentFilters.value.map((filter) => filter),
    )

    const filterOptions: Ref<{ name: string; value: AppNotificationType }[]> =
      ref([
        {
          name: 'Info',
          value: AppNotificationType.Info,
          disabled: true,
        },
        {
          name: 'Warning',
          value: AppNotificationType.Warning,
          disabled: true,
        },
        {
          name: 'Error',
          value: AppNotificationType.Error,
          disabled: true,
        },
        {
          name: 'Debug',
          value: AppNotificationType.Debug,
        },
      ])

    watch(selectedFilterOptions, (value) => {
      notifications.applyFilters(value)
    })

    const orderedNotifications = computed(() => {
      return orderBy(
        notifications.filteredNotifications.value,
        ({ date }) => date,
        ['desc'],
      )
    })

    function markAllNotificationsAsRead(): void {
      notifications.unfilteredNotifications.value.forEach((notification) => {
        notification.markAsRead()
      })
    }

    function saveLocally(): void {
      const time = new Date().getTime()
      const fileName = `notifications-${probableEvent.value.id}-${time}.json`
      const text = JSON.stringify(
        notifications.unfilteredNotifications.value,
        null,
        2,
      )
      const element = document.createElement('a')

      element.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + encodeURIComponent(text),
      )
      element.setAttribute('download', fileName)
      element.style.display = 'none'

      document.body.appendChild(element)

      element.click()

      document.body.removeChild(element)
    }

    const sentryRequestStatus = ref<SentryRequestStatus>(null)
    async function sendToSentry(message: string): Promise<void> {
      if (sentryRequestStatus.value === 'sending') {
        return
      }
      sentryRequestStatus.value = 'sending'

      try {
        const compressed = await compressNotifications(
          notifications,
          event.value.eventId,
        )

        Sentry.withScope((scope) => {
          const scoutId = Sentry.getIsolationScope().getUser()?.id
          scope.setTag('event', event.value.eventId)
          Sentry.captureFeedbackWithAttachments(
            `Scout (id: ${scoutId}) has sent feedback & notifications for event ${event.value.eventId} - ${event.value.data.name} - ${new Date().toISOString()}`,
            { event: pick(event.value.data, ['id', 'sport_id', 'name']) },
            [
              {
                data: compressed,
                filename: `notifications-${event.value.eventId}-${new Date().toISOString()}.zip`,
                contentType: 'application/zip',
              },
            ],
            message,
          )
        })

        const sentSuccessfully = await Sentry.flush()

        if (sentSuccessfully) {
          sentryRequestStatus.value = 'sent'
        } else {
          sentryRequestStatus.value = 'error'
        }
      } catch (_error) {
        sentryRequestStatus.value = 'error'
      } finally {
        Sentry.getCurrentScope().clearAttachments()
      }
    }

    return {
      filterOptions,
      selectedFilterOptions,
      orderedNotifications,
      saveLocally,
      sendToSentry,
      sentryRequestStatus,
      markAllNotificationsAsRead,
      appVersion: import.meta.env.VITE_RELEASE_NAME,
      isOnline: app.isOnline,
    }
  },
})

async function compressNotifications(
  notifications: Notifications,
  eventId: number,
): Promise<Uint8Array> {
  const jsZip = new JSZip()

  jsZip.file(
    `notifications-${eventId}.json`,
    JSON.stringify(
      notifications.unfilteredNotifications.value.map((notification) => ({
        id: notification.id,
        type: notification.type,
        date: dayjs(notification.date).format('YYYY-MM-DD HH:mm:ss'),
        title: notification.title,
        content: notification.content,
        details: notification.details ?? 'No details provided',
      })),
      undefined,
      2,
    ),
  )

  return jsZip.generateAsync({
    type: 'uint8array',
    compression: 'DEFLATE',
  })
}
</script>

<template>
  <div class="flex h-full flex-col">
    <PopupTitle>
      <template #title>Notifications</template>
      <template #controls>
        <div class="flex items-center gap-3">
          <button
            class="text-blue mr-1 font-bold"
            @click="markAllNotificationsAsRead"
          >
            Mark all as read
          </button>
          <div>Type</div>
          <Select
            v-model:value="selectedFilterOptions"
            class="w-40"
            :options="filterOptions"
            mode="multiple"
            valueProp="value"
            label="name"
            placeholder="Select notification types to display"
            :forceDisabled="true"
          />
          <SendFeedback @submit="sendToSentry" />
        </div>
      </template>
    </PopupTitle>

    <div class="-mt-3 overflow-y-scroll">
      <div class="text-cloudygray px-3 pb-2 text-xs">
        Collector ver.
        <span class="font-bold">{{ appVersion }}</span>
      </div>

      <NotificationBlock
        v-for="notification in orderedNotifications"
        :key="`notification-${notification.date}`"
        v-bind="{ notification }"
        class="px-3 pb-3"
      />
    </div>
  </div>
</template>
