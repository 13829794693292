import {
  Confirmation,
  SoccerGoalAttribute,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'
import { useCancellationButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useCancellationButton'
import { useCloseButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useCloseButton'
import { useConfirmationButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useConfirmationButton'
import { usePopupButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/usePopupButton'
import { PopupBuilder } from '@mobile/views/Relation/Shared/Popup/builder/PopupBuilder'
import SelectedParticipant from '@mobile/views/Relation/Shared/Popup/builder/SelectedParticipant.vue'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'

import { RedCardPopup } from '../RedCard/RedCardPopup'
import { VARPopup } from '../VAR/VARPopup'
import { YellowCardPopup } from '../YellowCard/YellowCardPopup'

export const PenaltyGoalPopup = new PopupBuilder('Penalty Goal', {
  incident_id: SoccerIncidentId.Goal,
  attribute_ids: [SoccerGoalAttribute.PenaltyGoal],
  confirmation: Confirmation.ToBeDone,
})
  .setNavigationButtons([
    usePopupButton({
      props: {
        text: 'VAR',
        color: 'blue',
        onClick: (context: PopupContext | null) => context?.openPopup(VARPopup),
      },
    }),
    usePopupButton({
      props: {
        text: 'Red card',
        color: 'blue',
        onClick: (context: PopupContext | null) =>
          context?.openPopup(RedCardPopup),
      },
    }),
    usePopupButton({
      props: {
        text: 'Yellow card',
        color: 'blue',
        onClick: (context: PopupContext | null) =>
          context?.openPopup(YellowCardPopup),
      },
    }),
    useCloseButton({
      onClick: (context: PopupContext | null) => context?.closeAllPopups(),
    }),
    useCancellationButton({
      onClick: (context: PopupContext | null) => context?.closeAllPopups(),
    }),
    useConfirmationButton({
      onClick: (context: PopupContext | null) => context?.closeAllPopups(),
    }),
  ])
  .appendBody(SelectedParticipant)
  .build()
