import { Container } from 'inversify'

import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindEventStatusesList(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.EventStatusesList)
    .toDynamicValue(() => {
      return []
    })
    .inSingletonScope()
}
