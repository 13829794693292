import {
  DetailType,
  IceHockeyIncidentAttributeNames,
  IceHockeyIncidentId,
} from '@collector/sportsapi-client-legacy'

import { getIncidentAttributeId } from '../composables/useSportOptions'
import { SportDetailsConfiguration } from '../composables/useSportOptions/types'

const SuspensionNumberOfPlayersAttributes = [
  IceHockeyIncidentAttributeNames.ThreePlayers,
  IceHockeyIncidentAttributeNames.FourPlayers,
  IceHockeyIncidentAttributeNames.FivePlayers,
]

const SuspensionOverNumberOfPlayersAttributes = [
  IceHockeyIncidentAttributeNames.ThreePlayers,
  IceHockeyIncidentAttributeNames.FourPlayers,
  IceHockeyIncidentAttributeNames.FivePlayers,
  IceHockeyIncidentAttributeNames.NoPowerPlay,
]

const SuspensionLengthAttributes = [
  IceHockeyIncidentAttributeNames.MinorTwoMinutes,
  IceHockeyIncidentAttributeNames.MinorFiveMinutes,
  IceHockeyIncidentAttributeNames.MisconductTenMinutes,
  IceHockeyIncidentAttributeNames.GameMisconductTwentyMinutes,
  IceHockeyIncidentAttributeNames.MatchTwentyFiveMinutes,
]

const SuspensionType = [
  IceHockeyIncidentAttributeNames.Boarding,
  IceHockeyIncidentAttributeNames.Interference,
  IceHockeyIncidentAttributeNames.Elbowing,
  IceHockeyIncidentAttributeNames.Hooking,
  IceHockeyIncidentAttributeNames.Holding,
  IceHockeyIncidentAttributeNames.HoldingTheStick,
  IceHockeyIncidentAttributeNames.HighSticking,
  IceHockeyIncidentAttributeNames.AbuseOfOfficials,
  IceHockeyIncidentAttributeNames.CrossChecking,
  IceHockeyIncidentAttributeNames.Roughing,
  IceHockeyIncidentAttributeNames.Charging,
  IceHockeyIncidentAttributeNames.UnsportsmanlikeConduct,
  IceHockeyIncidentAttributeNames.TooManyMen,
  IceHockeyIncidentAttributeNames.Fighting,
  IceHockeyIncidentAttributeNames.DelayOfGame,
  IceHockeyIncidentAttributeNames.Other,
]

const GoalType = [
  IceHockeyIncidentAttributeNames.PenaltyGoal,
  IceHockeyIncidentAttributeNames.PowerPlay,
  IceHockeyIncidentAttributeNames.Shorthanded,
  IceHockeyIncidentAttributeNames.EmptyNet,
  IceHockeyIncidentAttributeNames.EvenStrength,
]

const GoalDistance = [
  IceHockeyIncidentAttributeNames.HighDangerRangeGoal,
  IceHockeyIncidentAttributeNames.MidRangeGoal,
  IceHockeyIncidentAttributeNames.LongRangeGoal,
  IceHockeyIncidentAttributeNames.BehindTheNet,
  IceHockeyIncidentAttributeNames.BehindTheBlueLine,
]

const GoalPlacement = [
  IceHockeyIncidentAttributeNames.UpperLeftGoals,
  IceHockeyIncidentAttributeNames.UpperMiddleGoals,
  IceHockeyIncidentAttributeNames.UpperRightGoals,
  IceHockeyIncidentAttributeNames.BottomLeftGoals,
  IceHockeyIncidentAttributeNames.BottomMiddleGoals,
  IceHockeyIncidentAttributeNames.BottomRightGoals,
]

const StoppageType = [
  IceHockeyIncidentAttributeNames.PuckOut,
  IceHockeyIncidentAttributeNames.Fight,
  IceHockeyIncidentAttributeNames.Icing,
  IceHockeyIncidentAttributeNames.Offside,
  IceHockeyIncidentAttributeNames.GoalieStopped,
  IceHockeyIncidentAttributeNames.ShiftedGoal,
  IceHockeyIncidentAttributeNames.Foul,
  IceHockeyIncidentAttributeNames.Injury,
  IceHockeyIncidentAttributeNames.Other,
]

const ShotOnTargetType = [
  IceHockeyIncidentAttributeNames.EvenStrength,
  IceHockeyIncidentAttributeNames.PowerPlay,
  IceHockeyIncidentAttributeNames.Shorthanded,
]

const MissedPenaltyType = [
  IceHockeyIncidentAttributeNames.PenaltySaved,
  IceHockeyIncidentAttributeNames.EvenStrength,
  IceHockeyIncidentAttributeNames.PowerPlay,
  IceHockeyIncidentAttributeNames.Shorthanded,
]

export enum IceHockeyGroupNames {
  NumberOfPlayers = 'Number of players',
  SuspensionLength = 'Suspension length',
  SuspensionType = 'Suspension type',
  GoalType = 'Goal type',
  Distance = 'Distance',
  Placement = 'Placement',
  Goalkeeper = 'Goalkeeper',
  StoppageType = 'Stoppage type',
  ShotOnTargetType = 'Shot on target type',
  MissedPenaltyType = 'Missed penalty type',
}

export const IceHockeyDetailsConfiguration: SportDetailsConfiguration<IceHockeyIncidentId> =
  {
    [IceHockeyIncidentId.Suspension]: [
      {
        name: IceHockeyGroupNames.NumberOfPlayers,
        attributes: SuspensionNumberOfPlayersAttributes,
        cols: 3,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
      {
        name: IceHockeyGroupNames.SuspensionLength,
        attributes: SuspensionLengthAttributes,
        cols: 3,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
      {
        name: IceHockeyGroupNames.SuspensionType,
        optionsFactory: (linkedIncident) => {
          return SuspensionType.map((name) => {
            return { name, value: getIncidentAttributeId(linkedIncident, name) }
          })
        },
        attributes: SuspensionType,
        important: true,
        type: DetailType.Select,
        fulfilledWhen: () => true,
      },
    ],
    [IceHockeyIncidentId.SuspensionOver]: [
      {
        name: IceHockeyGroupNames.NumberOfPlayers,
        attributes: SuspensionOverNumberOfPlayersAttributes,
        cols: 4,
        important: true,
        multiple: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
    ],
    [IceHockeyIncidentId.Goal]: [
      {
        name: IceHockeyGroupNames.GoalType,
        attributes: GoalType,
        cols: 3,
        important: true,
        multiple: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
      {
        name: IceHockeyGroupNames.Distance,
        attributes: GoalDistance,
        cols: 3,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
      {
        name: IceHockeyGroupNames.Placement,
        attributes: GoalPlacement,
        cols: 3,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
      {
        name: IceHockeyGroupNames.Goalkeeper,
        important: true,
        type: DetailType.Button,
        attributes: [],
        fulfilledWhen: (_, probableIncident) => {
          return !!probableIncident?.additional_info?.goalkeeper_id
        },
      },
    ],
    [IceHockeyIncidentId.Stoppage]: [
      {
        name: IceHockeyGroupNames.StoppageType,
        attributes: StoppageType,
        cols: 3,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
    ],
    [IceHockeyIncidentId.ShotOnTarget]: [
      {
        name: IceHockeyGroupNames.ShotOnTargetType,
        attributes: ShotOnTargetType,
        cols: 3,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
      {
        name: IceHockeyGroupNames.Goalkeeper,
        important: true,
        type: DetailType.Button,
        attributes: [],
        fulfilledWhen: (_, probableIncident) => {
          return !!probableIncident?.additional_info?.goalkeeper_id
        },
      },
    ],
    [IceHockeyIncidentId.MissedPenalty]: [
      {
        name: IceHockeyGroupNames.MissedPenaltyType,
        attributes: MissedPenaltyType,
        cols: 3,
        important: true,
        multiple: true,
        type: DetailType.Button,
        fulfilledWhen: () => true,
      },
      {
        name: IceHockeyGroupNames.Goalkeeper,
        attributes: [],
        important: true,
        type: DetailType.Button,
        fulfilledWhen: (_, probableIncident) => {
          return !!probableIncident?.additional_info?.goalkeeper_id
        },
      },
    ],
  }

export const IceHockeyAttributesConfiguration = {
  [IceHockeyIncidentId.ShotBlocked]: [
    IceHockeyIncidentAttributeNames.BlockedByTeammate,
  ],
}
