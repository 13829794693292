import { Container } from 'inversify'

import { AddIncident } from '@mobile/ActionQueue/Actions/Actions'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { IncidentHandler } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/IncidentHandlersConfiguration'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindIncidentHandlersConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.IncidentHandlersConfiguration)
    .toDynamicValue(async (ctx) => {
      const [probableClock, probableEvent, actionQueue] = await Promise.all([
        ctx.container.getAsync(RelationIoCBindings.ProbableClock),
        ctx.container.getAsync(RelationIoCBindings.ProbableEvent),
        ctx.container.getAsync(RelationIoCBindings.ActionQueue),
      ])

      const addIncident: IncidentHandler['handler'] = ({
        incidentId,
        participantId,
        status,
        time = {
          minute: probableClock.value.minute,
          second: probableClock.value.second,
        },
        attributeIds = [],
      }) => {
        actionQueue.value.add(
          new AddIncident({
            incident_id: incidentId,
            status_id: status ?? probableEvent.value.status_id,
            participant_team_id: participantId,
            ...time,
            attribute_ids: attributeIds,
          }),
        )
      }

      return {
        fallbackHandler: {
          handler: ({ incidentId, participantId, status, time }) =>
            addIncident({
              time,
              status,
              incidentId,
              participantId,
            }),
          disabled: () => false,
        },
      }
    })
}
