<script lang="ts">
import { defineComponent } from 'vue'

import { opChain } from '@collector/shared-utils'
import { VolleyballIncidentId } from '@collector/sportsapi-client-legacy'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import { useVolleyballConfiguration } from '@mobile/views/Relation/Sports/Volleyball/VolleyballRelationDependencies/useVolleyballConfiguration'

export default defineComponent({
  components: { IncidentButton },

  setup() {
    const { activeParticipant } = useVolleyballConfiguration()

    return {
      VolleyballIncidentId,
      activeParticipant,
      opChain,
    }
  },
})
</script>

<template>
  <div class="flex flex-col space-y-4">
    <IncidentButton
      class="flex-1"
      :type="'horizontal'"
      :size="'xl'"
      :color="activeParticipant.color"
      :incidentId="VolleyballIncidentId.ServiceError"
      :participantId="opChain(activeParticipant.participant, (v) => v.id)"
    />
    <IncidentButton
      class="flex-1"
      :type="'horizontal'"
      :size="'xl'"
      :color="activeParticipant.color"
      :incidentId="VolleyballIncidentId.AttackError"
      :participantId="opChain(activeParticipant.participant, (v) => v.id)"
    />

    <div class="flex flex-1 space-x-4">
      <IncidentButton
        class="h-full w-1/2"
        :type="'vertical'"
        :size="'md'"
        :color="'white'"
        :incidentId="VolleyballIncidentId.TechnicalErrors"
      />
      <IncidentButton
        class="h-full w-1/2"
        :type="'vertical'"
        :size="'md'"
        :color="'white'"
        :incidentId="VolleyballIncidentId.Point"
      />
    </div>
  </div>
</template>
