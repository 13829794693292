<script lang="ts">
import { defineComponent } from 'vue'

import { opChain } from '@collector/shared-utils'
import { Basketball3x3IncidentId } from '@collector/sportsapi-client-legacy'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'

import { useBasketBall3x3Configuration } from '../../BasketballRelationDependencies/useBasketBallConfiguration'

export default defineComponent({
  components: { IncidentButton },
  setup() {
    const { activeParticipant, inactiveParticipant } =
      useBasketBall3x3Configuration()

    return {
      activeParticipant,
      inactiveParticipant,
      Basketball3x3IncidentId,
      opChain,
    }
  },
})
</script>

<template>
  <div class="grid gap-4">
    <IncidentButton
      class="h-full"
      :type="'horizontal'"
      :size="'xs'"
      :color="'white'"
      :participantId="opChain(activeParticipant.participant, (v) => v.id)"
      :incidentId="Basketball3x3IncidentId.OnePointShotMissed"
    >
      <div class="flex w-full items-center justify-between">
        <span>1pts</span>
        <FontAwesomeIcon
          class="text-red text-lg"
          icon="times"
        />
      </div>
    </IncidentButton>
    <IncidentButton
      class="h-full"
      :type="'horizontal'"
      :size="'xs'"
      :color="'white'"
      :participantId="opChain(activeParticipant.participant, (v) => v.id)"
      :incidentId="Basketball3x3IncidentId.TwoPointShotMissed"
    >
      <div class="flex w-full items-center justify-between">
        <span>2pts</span>
        <FontAwesomeIcon
          class="text-red text-lg"
          icon="times"
        />
      </div>
    </IncidentButton>
    <IncidentButton
      class="h-full"
      :type="'horizontal'"
      :size="'xs'"
      :color="'white'"
      :participantId="opChain(activeParticipant.participant, (v) => v.id)"
      :incidentId="Basketball3x3IncidentId.FreeThrowMissed"
    >
      <div class="flex w-full items-center justify-between">
        <span>FT miss</span>
        <FontAwesomeIcon
          class="text-red text-lg"
          icon="times"
        />
      </div>
    </IncidentButton>
  </div>
  <div class="grid gap-4">
    <IncidentButton
      class="h-full"
      :type="'vertical'"
      :size="'xs'"
      :color="'white'"
      :participantId="opChain(inactiveParticipant.participant, (v) => v.id)"
      :incidentId="Basketball3x3IncidentId.DefensiveRebound"
    >
      DR
    </IncidentButton>
    <IncidentButton
      class="h-full"
      :type="'vertical'"
      :size="'xs'"
      :color="'white'"
      :participantId="opChain(activeParticipant.participant, (v) => v.id)"
      :incidentId="Basketball3x3IncidentId.OffensiveRebound"
    >
      OR
    </IncidentButton>
    <IncidentButton
      class="h-full"
      :type="'vertical'"
      :size="'xs'"
      :color="'white'"
      :participantId="opChain(inactiveParticipant.participant, (v) => v.id)"
      :incidentId="Basketball3x3IncidentId.Block"
    />
  </div>
</template>
