import { Container } from 'inversify'

import {
  IceHockeyIncidentId,
  IceHockeyShotBlockedAttribute,
} from '@collector/sportsapi-client-legacy'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindAssignableIncidentConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.AssignableIncidentsConfiguration)
    .toDynamicValue(() => {
      return {
        [IceHockeyIncidentId.Goal]: {
          participants: {
            main: 'Player',
            secondary: 'Primary assistant',
            ternary: 'Secondary assistant',
          },
          participantsConfiguration: {
            secondary: {
              loadOppositeTeam: false,
              optional: true,
            },
            ternary: {
              loadOppositeTeam: false,
              optional: true,
            },
          },
        },
        [IceHockeyIncidentId.Suspension]: {
          participants: {
            main: 'Player',
          },
        },
        [IceHockeyIncidentId.PenaltyShot]: {
          participants: {
            main: 'Player',
          },
        },
        [IceHockeyIncidentId.MissedPenalty]: {
          participants: {
            main: 'Player',
          },
        },
        [IceHockeyIncidentId.WinningFaceOff]: {
          participants: {
            main: 'Player',
          },
        },
        [IceHockeyIncidentId.ShotBlocked]: {
          participants: {
            main: 'Player',
            secondary: 'Block made by',
          },
          participantsConfiguration: {
            secondary: {
              loadOppositeTeam: true,
              availableWhen: (probableIncident) => {
                return !probableIncident.value.attribute_ids.includes(
                  IceHockeyShotBlockedAttribute.BlockedByTeammate,
                )
              },
            },
          },
        },
        [IceHockeyIncidentId.ShotOffTarget]: {
          participants: {
            main: 'Player',
          },
        },
        [IceHockeyIncidentId.ShotOnTarget]: {
          participants: {
            main: 'Player',
          },
        },
        [IceHockeyIncidentId.GoalkeeperOut]: {
          participants: {
            main: 'Player',
          },
        },
        [IceHockeyIncidentId.GoalkeeperIn]: {
          participants: {
            main: 'Player',
          },
        },
        [IceHockeyIncidentId.Hit]: {
          participants: {
            main: 'Player',
          },
        },
      }
    })
    .inSingletonScope()
}
