import { Container } from 'inversify'
import { cloneDeep } from 'lodash'
import mitt, { Handler } from 'mitt'
import { computed, DeepReadonly, readonly, ref, Ref, watch } from 'vue'

import * as app from '@mobile/globalState/app'
import { AppNotification } from '@mobile/reusables/types/AppNotification'
import { AppNotificationType } from '@mobile/reusables/types/AppNotificationType'
import { generateUniqueId } from '@mobile/uniqueId'

import { RelationIoCBindings } from './types'

export type NotificationsEvents = {
  NewNotification: {
    notification: DeepReadonly<AppNotification>
    isVisible: boolean
  }
}

export type OnNewNotificationCallback = (
  event: NotificationsEvents['NewNotification'],
) => void

const notificationsLimit = Number(import.meta.env.VITE_NOTIFICATIONS_LIMIT)

export function bindNotifications(ioc: Container): void {
  let unwatchNotifications: () => void

  ioc
    .bind(RelationIoCBindings.Notifications)
    .toDynamicValue(async (ctx) => {
      const event = await ctx.container.getAsync(
        RelationIoCBindings.FactualEvent,
      )
      const notifications = await pullNotificationsFromIndexedDB(
        event.value.eventId,
      )
      const eventBus = mitt<NotificationsEvents>()
      const displayedTypes: Ref<AppNotificationType[]> = ref([
        AppNotificationType.Info,
        AppNotificationType.Warning,
        AppNotificationType.Error,
      ])

      return {
        currentFilters: computed(() => displayedTypes.value),
        unfilteredNotifications: computed(() => notifications.value),
        filteredNotifications: computed(() =>
          notifications.value.filter((notification) =>
            displayedTypes.value.includes(notification.type),
          ),
        ),
        applyFilters(filterTypes: AppNotificationType[]): void {
          displayedTypes.value = filterTypes
        },
        onNewNotification(
          cb: Handler<{
            notification: DeepReadonly<AppNotification>
            isVisible: boolean
          }>,
        ): void {
          eventBus.on('NewNotification', cb)
        },
        offNewNotification(
          cb: Handler<{
            notification: DeepReadonly<AppNotification>
            isVisible: boolean
          }>,
        ): void {
          eventBus.off('NewNotification', cb)
        },
        sendNotification(
          notificationData: Pick<
            AppNotification,
            'title' | 'type' | 'content' | 'details'
          >,
        ): void {
          const id = generateUniqueId()
          const date = new Date().getTime()
          const version = import.meta.env.VITE_RELEASE_NAME
          const notification: AppNotification = createAppNotification(
            notifications,
            {
              id,
              date,
              version,
              read: false,
              ...notificationData,
            },
          )

          notifications.value.push(notification)
          if (notifications.value.length > notificationsLimit) {
            notifications.value.splice(notificationsLimit + 1)
          }

          eventBus.emit('NewNotification', {
            notification: readonly(notification),
            isVisible: displayedTypes.value.includes(notification.type),
          })
        },
      }
    })
    .inSingletonScope()
    .onActivation(async (ctx, notifications) => {
      const probableEvent = await ctx.container.getAsync(
        RelationIoCBindings.ProbableEvent,
      )

      unwatchNotifications = watch(
        notifications.unfilteredNotifications,
        (notifications) => {
          app.state.database.notifications.save(
            probableEvent.value.id,
            cloneDeep(notifications),
          )
        },
        { deep: true },
      )

      return notifications
    })
    .when(() => true)
    .onDeactivation(async () => {
      unwatchNotifications()
    })
    .when(() => true)
}

async function pullNotificationsFromIndexedDB(
  eventId: number,
): Promise<Ref<AppNotification[]>> {
  const notificatons: Ref<AppNotification[]> = ref([])
  const DBNotificationsRecord =
    await app.state.database.notifications.find(eventId)
  const DBNotifications = DBNotificationsRecord?.notifications || []
  notificatons.value = DBNotifications.map((notification) =>
    createAppNotification(notificatons, notification),
  )

  return notificatons
}

function createAppNotification(
  notifications: Ref<AppNotification[]>,
  notificationData: Omit<AppNotification, 'markAsRead' | 'markAsUnread'>,
): AppNotification {
  return {
    ...notificationData,
    markAsRead() {
      const index = notifications.value.findIndex(
        ({ id }) => notificationData.id === id,
      )
      if (index !== -1) {
        notifications.value[index].read = true
      }
    },
    markAsUnread() {
      const index = notifications.value.findIndex(
        ({ id }) => notificationData.id === id,
      )
      if (index !== -1) {
        notifications.value[index].read = false
      }
    },
  }
}
