import Dexie from 'dexie'

import { Repository } from '@mobile/database/Repository'

export interface UserLoginAction {
  lastLoginTimestamp: number
  userId: number
}

export class UserLoginActionsRepository extends Repository<UserLoginAction> {
  static readonly tableName = 'userLoginActions'
  static readonly indexes = 'userId'

  constructor(database: Dexie) {
    super(database.table<UserLoginAction>(UserLoginActionsRepository.tableName))
  }

  public async getOne(): Promise<UserLoginAction | undefined> {
    const userLoginActions = await this.table.toArray()

    return userLoginActions[0]
  }
}
