import { Ref } from 'vue'

import {
  Event,
  IncidentId,
  Participant,
  SoccerEventDetailId,
  SoccerIncidentId,
  TeamSide,
} from '@collector/sportsapi-client-legacy'
import { ActionQueue } from '@mobile/ActionQueue/ActionQueue'
import { AddIncident } from '@mobile/ActionQueue/Actions/Actions'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import { resolveProbableIncident } from '@mobile/ActionQueue/ActionUtils'
import {
  byId,
  getOppositeTeamSide,
  getTeamSide,
} from '@mobile/reusables/entityUtils'
import { getTeamSideFromEventDetail } from '@mobile/reusables/eventUtils'
import { IncidentsConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/IncidentsConfiguration'

type SwitcheableIncidentConfiguration =
  | {
      source: 'unknown'
      opposite: boolean
    }
  | { source: 'eventDetails'; detailId: SoccerEventDetailId; opposite: boolean }

type SwitcheableIncidentsConfigurations = {
  [key in IncidentId]?: SwitcheableIncidentConfiguration
}

const switcheableIncidentsConfiguration: SwitcheableIncidentsConfigurations = {
  [SoccerIncidentId.FirstHalf]: {
    source: 'eventDetails',
    detailId: SoccerEventDetailId.KickOffTeam,
    opposite: false,
  },
  [SoccerIncidentId.SecondHalf]: {
    source: 'eventDetails',
    detailId: SoccerEventDetailId.KickOffTeam,
    opposite: true,
  },
  [SoccerIncidentId.ExtratimeFirstHalf]: {
    source: 'eventDetails',
    detailId: SoccerEventDetailId.KickOffTeam,
    opposite: false,
  },
  [SoccerIncidentId.ExtratimeSecondHalf]: {
    source: 'eventDetails',
    detailId: SoccerEventDetailId.KickOffTeam,
    opposite: true,
  },
  [SoccerIncidentId.Goal]: {
    source: 'unknown',
    opposite: true,
  },
  [SoccerIncidentId.Offside]: {
    source: 'unknown',
    opposite: true,
  },
  [SoccerIncidentId.GoalKick]: {
    source: 'unknown',
    opposite: false,
  },
  [SoccerIncidentId.FreeKick]: {
    source: 'unknown',
    opposite: false,
  },
  [SoccerIncidentId.DangerousFreeKick]: {
    source: 'unknown',
    opposite: false,
  },
  [SoccerIncidentId.ThrowIn]: {
    source: 'unknown',
    opposite: false,
  },
  [SoccerIncidentId.KickOff]: {
    source: 'unknown',
    opposite: false,
  },
  [SoccerIncidentId.Corner]: {
    source: 'unknown',
    opposite: false,
  },
}

export function changePossessionSideOnNewIncident(
  action: AddIncident,
  probableEvent: Ref<Event>,
  possessionSide: Ref<TeamSide>,
  probableHomeParticipant: Ref<Participant>,
  probableAwayParticipant: Ref<Participant>,
  actionQueue: ActionQueue,
  notDeletableProbableIncidents: Ref<ProbableIncident[]>,
  incidentsConfiguration: IncidentsConfiguration,
): void {
  function getIncidentConfiguration({
    incident_id: incidentId,
  }: ProbableIncident): SwitcheableIncidentConfiguration | undefined {
    return switcheableIncidentsConfiguration[incidentId]
  }

  function getProbableIncident(
    action: AddIncident,
  ): ProbableIncident | undefined {
    return resolveProbableIncident(
      action,
      actionQueue,
      notDeletableProbableIncidents.value,
      incidentsConfiguration,
    )
  }

  function getIncidentParticipant(
    probableIncident: ProbableIncident | undefined,
  ): Participant | undefined {
    const participants = [
      probableHomeParticipant.value,
      probableAwayParticipant.value,
    ]

    return byId(participants, probableIncident?.participant_id)
  }

  const probableIncident = getProbableIncident(action)

  if (!probableIncident) {
    return
  }

  const participants = [
    probableHomeParticipant.value,
    probableAwayParticipant.value,
  ]
  const incidentConfiguration = getIncidentConfiguration(probableIncident)
  const incidentParticipant = getIncidentParticipant(probableIncident)

  if (incidentParticipant && incidentConfiguration) {
    const participantSide =
      incidentConfiguration.source === 'unknown'
        ? getTeamSide(participants, incidentParticipant.id)
        : getTeamSideFromEventDetail(
            probableEvent.value.details,
            incidentConfiguration.detailId,
          )
    const oppositeParticipantSide = getOppositeTeamSide(
      participants,
      incidentParticipant.id,
    )

    possessionSide.value = incidentConfiguration.opposite
      ? oppositeParticipantSide
      : participantSide
  }
}
