<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import UpdateEventParticipants from '@mobile/ActionQueue/Actions/Event/UpdateEventParticipants'
import QueueAction from '@mobile/views/Relation/Shared/Popups/Queue/Actions/QueueAction.vue'

export default defineComponent({
  components: { QueueAction },
  props: {
    action: {
      type: Object as PropType<UpdateEventParticipants>,
      required: true,
    },
  },
  setup(props) {
    const info = computed(() =>
      JSON.stringify(props.action.updateEventParticipantsDTO, undefined, 2),
    )

    return { info }
  },
})
</script>

<template>
  <QueueAction
    :action
    :actionInformation="'Event Participants'"
  >
    <template #infoPanel>
      {{ info }}
    </template>
  </QueueAction>
</template>
