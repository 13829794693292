import { NavigationButton, PopupContext } from '../types'
import { PopupButtonProps } from './PopupButton/PopupButton'
import PopupButton from './PopupButton/PopupButton.vue'

export function useCloseButton(
  options?: Partial<PopupButtonProps>,
): NavigationButton {
  return {
    component: PopupButton,
    props: {
      text: options?.text ?? 'Not yet',
      attributes: options?.attributes ?? {},
      color: options?.color ?? 'blue',
      onClick: (context: PopupContext | null) => {
        if (options?.onClick) options.onClick(context)
        else context?.closePopup()
      },
    },
  }
}
