import { Container } from 'inversify'
import { ref } from 'vue'

import { EventsLineupsParticipant } from '@collector/sportsapi-client-legacy'
import * as app from '@mobile/globalState/app'

import { RelationIoCBindings } from './types'

export function bindLineups(ioc: Container): void {
  let updateLineups: () => void
  ioc
    .bind(RelationIoCBindings.FactualLineups)
    .toDynamicValue(async (ctx) => {
      const event = await ctx.container.getAsync(
        RelationIoCBindings.FactualEvent,
      )
      const homeParticipant = await ctx.container.getAsync(
        RelationIoCBindings.FactualHomeParticipant,
      )
      const awayParticipant = await ctx.container.getAsync(
        RelationIoCBindings.FactualAwayParticipant,
      )

      return {
        home: ref(
          await loadLineupsFromIndexedDB(
            event.value.eventId,
            homeParticipant.value.id,
          ),
        ),
        away: ref(
          await loadLineupsFromIndexedDB(
            event.value.eventId,
            awayParticipant.value.id,
          ),
        ),
      }
    })
    .inSingletonScope()
    .onActivation(async (ctx, lineups) => {
      const event = await ctx.container.getAsync(
        RelationIoCBindings.FactualEvent,
      )
      const homeParticipant = await ctx.container.getAsync(
        RelationIoCBindings.FactualHomeParticipant,
      )
      const awayParticipant = await ctx.container.getAsync(
        RelationIoCBindings.FactualAwayParticipant,
      )

      updateLineups = async () => {
        const [newHomeLineups, newAwayLineups] = await Promise.all([
          loadLineupsFromIndexedDB(
            event.value.eventId,
            homeParticipant.value.id,
          ),
          loadLineupsFromIndexedDB(
            event.value.eventId,
            awayParticipant.value.id,
          ),
        ])

        lineups.home.value = newHomeLineups
        lineups.away.value = newAwayLineups
      }

      app.state.database.lineups.on('created', updateLineups)
      app.state.database.lineups.on('updated', updateLineups)
      app.state.database.lineups.on('deleted', updateLineups)

      return lineups
    })
    .when(() => true)
    .onDeactivation(async () => {
      app.state.database.lineups.off('created', updateLineups)
      app.state.database.lineups.off('updated', updateLineups)
      app.state.database.lineups.off('deleted', updateLineups)
    })
    .when(() => true)

  ioc
    .bind(RelationIoCBindings.FactualHomeLineups)
    .toDynamicValue(async (ctx) => {
      const lineups = await ctx.container.getAsync(
        RelationIoCBindings.FactualLineups,
      )

      return lineups.home
    })

  ioc
    .bind(RelationIoCBindings.FactualAwayLineups)
    .toDynamicValue(async (ctx) => {
      const lineups = await ctx.container.getAsync(
        RelationIoCBindings.FactualLineups,
      )

      return lineups.away
    })
}

async function loadLineupsFromIndexedDB(
  eventId: number,
  participantId: number,
): Promise<EventsLineupsParticipant[]> {
  const lineups = await app.state.database.lineups.findForParticipant(
    eventId,
    participantId,
  )

  return lineups ?? []
}
