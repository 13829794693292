export enum BasketballEventDetailId {
  // TODO: change common detail ids to `CommonEventDetailId`
  PeriodLength = 16,
  NumberOfPeriods = 17,
  ExtraPeriodLength = 50,
  NumberOfExtraPeriods = 51,
  TimerDirection = 124,
  PossessionTimeLimit = 177,
  JumpBallWinner = 234,
}
