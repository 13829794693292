import {
  SoccerAttributesConfiguration,
  SoccerDetailsConfiguration,
  SoccerGroupNames,
} from './SoccerDetailsConfiguration'
import {
  SoccerDetailsIncidentIds,
  SoccerHideIncidentIds,
  SoccerParticipantIncidentIds,
  SoccerSecondParticipantIncidentIds,
  SoccerSelectedOnStartDetailsIncidentIds,
} from './SoccerFiltersIncidentIds'
import {
  SoccerManualAddIncidentIds,
  SoccerManualAddIncidentSubstitutionIds,
} from './SoccerManualAddIncidentIds'
import { SoccerManualAddStatusIds } from './SoccerManualAddStatusIds'

export const SoccerConfiguration = {
  soccerGroupNames: SoccerGroupNames,
  soccerDetailsConfiguration: SoccerDetailsConfiguration,
  soccerAttributesConfiguration: SoccerAttributesConfiguration,
  soccerHideIncidentIds: SoccerHideIncidentIds,
  soccerParticipantIncidentIds: SoccerParticipantIncidentIds,
  soccerSecondParticipantIncidentIds: SoccerSecondParticipantIncidentIds,
  soccerDetailsIncidentIds: SoccerDetailsIncidentIds,
  soccerSelectedOnStartDetailsIncidentIds:
    SoccerSelectedOnStartDetailsIncidentIds,
  soccerManualAddIncidentIds: SoccerManualAddIncidentIds,
  soccerManualAddIncidentSubstitutionIds:
    SoccerManualAddIncidentSubstitutionIds,
  soccerManualAddStatusIds: SoccerManualAddStatusIds,
}
