<script lang="ts">
import { cloneDeep } from 'lodash'
import {
  ComponentPublicInstance,
  defineComponent,
  nextTick,
  onMounted,
  PropType,
  reactive,
  Ref,
  ref,
} from 'vue'

import {
  EventDetailId,
  EventStatusId,
  SoccerEventDetailId,
  SoccerEventStatusId,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'
import UpdateEvent from '@mobile/ActionQueue/Actions/Event/UpdateEvent'
import AddIncident from '@mobile/ActionQueue/Actions/Incidents/AddIncident'
import Button from '@mobile/components/Button/Button.vue'
import * as popup from '@mobile/globalState/popup'
import { byId } from '@mobile/reusables/entityUtils'
import { resolveUpdateEventDetailsObjectFromEventDetailsDiff } from '@mobile/reusables/eventUtils'
import PopupControls from '@mobile/views/Relation/Shared/Popup/PopupControls.vue'
import { IncidentActionComposable } from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import InputNumeric from '@mobile/views/Relation/Shared/Popups/EventStats/InputNumeric.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: {
    PopupControls,
    Button,
    InputNumeric,
  },
  props: {
    incidentActionComposable: {
      type: Object as PropType<IncidentActionComposable>,
      required: true,
    },
  },
  setup() {
    const { probableEvent, probableClock, actionQueue, sport } =
      useEventInjections()
    const statusMap: { [key in EventStatusId]?: EventDetailId } = {
      [SoccerEventStatusId.FirstHalf]: SoccerEventDetailId.FirstHalfInjuryTime,
      [SoccerEventStatusId.SecondHalf]:
        SoccerEventDetailId.SecondHalfInjuryTime,
      [SoccerEventStatusId.ExtratimeFirstHalf]:
        SoccerEventDetailId.FirstExtraHalfInjuryTime,
      [SoccerEventStatusId.ExtratimeSecondHalf]:
        SoccerEventDetailId.SecondExtraHalfInjuryTime,
    }

    const inputRef: Ref<ComponentPublicInstance<HTMLInputElement> | null> =
      ref(null)
    const additionalTime = ref('')

    function closePopup(): void {
      popup.hide()
    }

    function updateAdditionalTimeAction(): void {
      const staticProbableEvent = reactive(cloneDeep(probableEvent.value))
      const newEvent = reactive(cloneDeep(probableEvent.value))

      const detailId = statusMap[staticProbableEvent.status_id]
      if (!detailId) {
        return
      }

      const detail = byId(newEvent.details, detailId)
      if (detail) {
        detail.value = additionalTime.value
      } else {
        newEvent.details.push({
          id: detailId,
          value: additionalTime.value,
        })
      }

      actionQueue.value.add(
        new UpdateEvent({
          event: {},
          details: resolveUpdateEventDetailsObjectFromEventDetailsDiff(
            staticProbableEvent.details,
            newEvent.details,
            sport.value.sportsAPIEntity,
          ),
        }),
      )

      closePopup()
    }

    onMounted(async () => {
      actionQueue.value.add(
        new AddIncident({
          incident_id: SoccerIncidentId.AddedTime,
          status_id: probableEvent.value.status_id,
          minute: probableClock.value.minute,
          second: probableClock.value.second,
        }),
      )

      await nextTick()

      inputRef.value?.$el.focus()
    })

    return {
      additionalTime,
      closePopup,
      updateAdditionalTimeAction,
      inputRef,
    }
  },
})
</script>

<template>
  <div class="flex items-center space-x-3 px-4 py-6">
    <div class="font-bold">Added time</div>
    <div class="flex-1">
      <InputNumeric
        ref="inputRef"
        v-model:value="additionalTime"
        class="w-full"
        :focusOnMounted="true"
      />
    </div>
  </div>

  <PopupControls :enableSwipe="false">
    <template #buttons>
      <div class="flex justify-end space-x-2">
        <Button
          :color="'blue'"
          :size="'lg'"
          @click="closePopup()"
        >
          Not yet
        </Button>
        <Button
          :color="'green'"
          :size="'lg'"
          @click="updateAdditionalTimeAction()"
        >
          Set added time
        </Button>
      </div>
    </template>
  </PopupControls>
</template>
