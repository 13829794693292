<script lang="ts">
import { cloneDeep, get } from 'lodash'
import { computed, defineComponent, PropType, ref } from 'vue'

import { opChain } from '@collector/shared-utils'
import {
  EventsLineupsParticipant,
  TeamSide,
} from '@collector/sportsapi-client-legacy'
import UpdateIncident from '@mobile/ActionQueue/Actions/Incidents/UpdateIncident'
import Button from '@mobile/components/Button/Button.vue'
import Select from '@mobile/components/Select/Select.vue'
// eslint-disable-next-line unused-imports/no-unused-imports, @typescript-eslint/no-unused-vars
import type { SelectOption } from '@mobile/components/Select/types/SelectOption'
import { incidentDiff } from '@mobile/reusables/eventUtils'
import AssignPlayersToIncident from '@mobile/views/Relation/Shared/AssignPlayersToIncident/AssignPlayersToIncident.vue'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

export default defineComponent({
  components: {
    Button,
    Select,
    AssignPlayersToIncident,
  },
  props: {
    linkedIncident: {
      type: Object as PropType<ProbableLinkedIncident>,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const eventInjections = useEventInjections()
    const { actionQueue, probableEvent } = eventInjections
    const { id: eventId } = probableEvent.value

    const staticLinkedIncident = ref(cloneDeep(props.linkedIncident))
    const incident = computed(() => staticLinkedIncident.value.incident)

    const displayAdditionalInfo = computed(
      () => staticLinkedIncident.value.sportIncident.attributes.length > 0,
    )

    const time = computed(() => {
      const [minute, second] = props.linkedIncident.incident.event_time?.split(
        ':',
      ) ?? ['00', '00']

      return {
        minute,
        second,
      }
    })

    const { assignableIncidentsConfiguration } = useSportConfiguration()
    const assignableIncidentConfiguration = get(
      assignableIncidentsConfiguration,
      incident.value.incident_id,
      null,
    )

    function onMainParticipantUpdate(
      participant: EventsLineupsParticipant | null,
    ): void {
      staticLinkedIncident.value.incident.subparticipant_id =
        participant?.participant_id || null
      staticLinkedIncident.value.incident.subparticipant_name =
        participant?.participant_name || undefined
    }

    function onSecondaryParticipantUpdate(
      participant: EventsLineupsParticipant | null,
    ): void {
      staticLinkedIncident.value.incident.assistant_id =
        participant?.participant_id || null
      staticLinkedIncident.value.incident.assistant_name =
        participant?.participant_name || undefined
    }

    function updateIncident(): void {
      const diff = incidentDiff(
        props.linkedIncident.incident,
        staticLinkedIncident.value.incident,
      )

      actionQueue.value.add(
        new UpdateIncident(props.linkedIncident.incident.id, {
          incident_id: props.linkedIncident.incident.incident_id,
          event_id: eventId,
          status_id: props.linkedIncident.incident.event_status_id,
          attribute_ids: diff.attribute_ids,
          participant_team_id: props.linkedIncident.incident.participant_id,
          participant_id: diff.subparticipant_id,
          assistant_id: diff.assistant_id,
          assistant_name: diff.assistant_name,
          minute: Number(time.value.minute),
          second: Number(time.value.second),
        }),
      )
      emit('close')
    }

    return {
      time,
      TeamSide,
      staticLinkedIncident,
      displayAdditionalInfo,
      assignableIncidentConfiguration,
      incident,
      getIncidentRef: () => incident,
      updateIncident,
      onMainParticipantUpdate,
      onSecondaryParticipantUpdate,
      opChain,
    }
  },
})
</script>

<template>
  <div class="flex w-full flex-col rounded">
    <div
      class="bg-gradient-darkBlue-to-blue text-xxs flex space-x-2 rounded-t py-0.5 pl-1 text-white"
    >
      <div class="font-bold">
        {{ incident.participant_name }}
      </div>
      <div>|</div>
      <div class="font-bold">
        {{ incident.incident_name }}
      </div>
    </div>
    <div
      class="flex items-center rounded-b bg-white p-2"
      :class="
        opChain(
          staticLinkedIncident.probableLinkedParticipant,
          (v) => v.css.gradient32To,
        )
      "
    >
      <div
        class="flex items-center justify-between space-x-2 text-xs font-bold"
      >
        <input
          v-model="time.minute"
          class="text-gray border-darksilver h-full w-8 rounded border border-solid px-1 py-2 text-center text-xs font-bold"
          type="text"
        />
        <div>:</div>
        <input
          v-model="time.second"
          class="text-gray border-darksilver h-full w-8 rounded border border-solid px-1 py-2 text-center text-xs font-bold"
          type="text"
        />
      </div>
      <div class="flex flex-1 flex-wrap">
        <div
          v-if="displayAdditionalInfo"
          class="flex w-full flex-1 flex-wrap"
        >
          <div class="flex-1 px-4">
            <div class="flex items-center">
              <label class="text-gray text-xxs w-14 font-bold">
                Attributes
              </label>
              <Select
                v-model:value="incident.attribute_ids"
                class="mb-2 w-48 flex-1"
                mode="multiple"
                :options="
                  staticLinkedIncident.sportIncident
                    .attributes as unknown as SelectOption[]
                "
                valueProp="id"
                label="name"
                placeholder="Select attribute"
              />
            </div>
          </div>
        </div>
        <div
          v-if="assignableIncidentConfiguration"
          class="flex w-full px-4"
        >
          <AssignPlayersToIncident
            :assignableIncidentConfiguration
            :incident="getIncidentRef()"
            :side="
              opChain(
                staticLinkedIncident.probableLinkedParticipant,
                (v) => v.side,
              )
            "
            selectLabelClasses="w-14 text-xxs font-bold"
            @mainParticipantUpdated="onMainParticipantUpdate"
            @secondaryParticipantUpdated="onSecondaryParticipantUpdate"
          />
        </div>
      </div>
      <div class="flex justify-end">
        <Button
          class="w-10 text-center"
          :color="'red'"
          :size="'xs'"
          @click="$emit('close')"
        >
          <FontAwesomeIcon icon="ban" />
        </Button>
        <Button
          class="ml-2 w-10 text-center"
          :color="'green'"
          :size="'xs'"
          @click="updateIncident()"
        >
          <FontAwesomeIcon icon="save" />
        </Button>
      </div>
    </div>
  </div>
</template>
