import { Container } from 'inversify'

import { CricketIncidentId } from '@collector/sportsapi-client-legacy'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindEventStatusesList(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.EventStatusesList)
    .toDynamicValue(() => {
      return [
        CricketIncidentId.NotStarted,
        CricketIncidentId.StartDelayed,
        CricketIncidentId.Live,
        CricketIncidentId.FirstInningsHomeStarted,
        CricketIncidentId.FirstInningsAwayStarted,
        CricketIncidentId.SuperOverHomeStarted,
        CricketIncidentId.SuperOverAwayStarted,
        CricketIncidentId.InningsBreak,
        CricketIncidentId.AfterDayOne,
        CricketIncidentId.AfterDayTwo,
        CricketIncidentId.AfterDayThree,
        CricketIncidentId.AfterDayFour,
        CricketIncidentId.Finished,
        CricketIncidentId.FinishedAfterSuperOver,
        CricketIncidentId.Cancelled,
        CricketIncidentId.Abandoned,
        CricketIncidentId.Interrupted,
        CricketIncidentId.Postponed,
        CricketIncidentId.ToFinish,
      ]
    })
    .inSingletonScope()
}
