import { SoccerIncidentId } from './IncidentId'

export const SoccerIncidentAcronymMap: {
  [key in SoccerIncidentId]: string
} = {
  [SoccerIncidentId.NotStarted]: 'NST',
  [SoccerIncidentId.StartDelayed]: 'STDE',
  [SoccerIncidentId.FirstHalf]: '1ST',
  [SoccerIncidentId.Halftime]: 'HT',
  [SoccerIncidentId.SecondHalf]: '2ND',
  [SoccerIncidentId.WaitingForExtratime]: 'WFE',
  [SoccerIncidentId.ExtratimeFirstHalf]: 'E1ST',
  [SoccerIncidentId.ExtratimeHalftime]: 'EHT',
  [SoccerIncidentId.ExtratimeSecondHalf]: 'E2ND',
  [SoccerIncidentId.WaitingForPenalty]: 'WFP',
  [SoccerIncidentId.FinishedRegularTime]: 'FT',
  [SoccerIncidentId.FinishedAfterExtratime]: 'FAE',
  [SoccerIncidentId.FinishedAfterPenalties]: 'FAP',
  [SoccerIncidentId.FinishedAwardedWin]: 'FAW',
  [SoccerIncidentId.Interrupted]: 'INT',
  [SoccerIncidentId.Postponed]: 'POS',
  [SoccerIncidentId.Abandoned]: 'ABA',
  [SoccerIncidentId.Cancelled]: 'CAN',
  [SoccerIncidentId.ToFinish]: 'TOF',
  [SoccerIncidentId.AddedTime]: 'AT',
  [SoccerIncidentId.VideoAssistantReferee]: 'VAR',
  [SoccerIncidentId.WaterBreak]: 'WB',
  [SoccerIncidentId.RefereesInjury]: 'RI',
  [SoccerIncidentId.BreakDuringTheGame]: 'BDG',
  [SoccerIncidentId.InPossession]: 'INP',
  [SoccerIncidentId.Attack]: 'ATT',
  [SoccerIncidentId.DangerousAttack]: 'DATT',
  [SoccerIncidentId.ShotOnTarget]: 'SON',
  [SoccerIncidentId.ShotOffTarget]: 'SOF',
  [SoccerIncidentId.GoalKick]: 'GK',
  [SoccerIncidentId.PossibleCorner]: 'PCOR',
  [SoccerIncidentId.Corner]: 'COR',
  [SoccerIncidentId.CornerCancelled]: 'PCC',
  [SoccerIncidentId.ThrowIn]: 'THR',
  [SoccerIncidentId.Foul]: 'FOUL',
  [SoccerIncidentId.FreeKick]: 'FK',
  [SoccerIncidentId.DangerousFreeKick]: 'DFK',
  [SoccerIncidentId.ShotBlocked]: 'SBL',
  [SoccerIncidentId.GoalkeeperSaved]: 'GS',
  [SoccerIncidentId.PossibleGoal]: 'PG',
  [SoccerIncidentId.Goal]: 'GOAL',
  [SoccerIncidentId.Injury]: 'INJ',
  [SoccerIncidentId.PenaltySavedByGoalkeeper]: 'PENS',
  [SoccerIncidentId.Offside]: 'OFF',
  [SoccerIncidentId.PossibleCard]: 'PCA',
  [SoccerIncidentId.YellowCard]: 'YCAR',
  [SoccerIncidentId.RedCard]: 'RCAR',
  [SoccerIncidentId.PossibleCardCancelled]: 'PCC',
  [SoccerIncidentId.PossiblePenalty]: 'PPEN',
  [SoccerIncidentId.Penalty]: 'PEN',
  [SoccerIncidentId.PenaltyCancelled]: 'PPENC',
  [SoccerIncidentId.MissedPenalty]: 'PENM',
  [SoccerIncidentId.KickOff]: 'KO',
  [SoccerIncidentId.SecondYellowCard]: 'SYC',
  [SoccerIncidentId.PenaltyGoal]: 'PG',
  [SoccerIncidentId.OwnGoal]: 'OG',
  [SoccerIncidentId.GoalCancelled]: 'GC',
  [SoccerIncidentId.ShotWoodwork]: 'SW',
  [SoccerIncidentId.AerialDuelWon]: 'AEDW',
  [SoccerIncidentId.PenaltyShootout]: 'PENS',
  [SoccerIncidentId.MatchAboutToStart]: 'MATS',
  [SoccerIncidentId.SubstitutionOut]: 'SUBO',
  [SoccerIncidentId.SubstitutionIn]: 'SUBI',
  [SoccerIncidentId.BookingMarketsDisabled]: 'BMD',
  [SoccerIncidentId.BookingMarketsEnabled]: 'BME',
  [SoccerIncidentId.CornerMarketsDisabled]: 'CMD',
  [SoccerIncidentId.CornerMarketsEnabled]: 'CME',
  [SoccerIncidentId.VarEnded]: 'VE',
  [SoccerIncidentId.Tackle]: 'TCKL',
  [SoccerIncidentId.Interception]: 'INTN',
  [SoccerIncidentId.BallSkills]: 'BSKL',
  [SoccerIncidentId.LooseBall]: 'LOO',
}
