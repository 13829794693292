<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import {
  BasketballIncidentId,
  BasketballStatId,
  Participant,
} from '@collector/sportsapi-client-legacy'
import { AddIncident } from '@mobile/ActionQueue/Actions/Actions'
import Button from '@mobile/components/Button/Button.vue'
import { byId } from '@mobile/reusables/entityUtils'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

export default defineComponent({
  components: { Button },
  props: {
    participant: {
      type: Object as PropType<Participant>,
      required: true,
    },
  },
  setup(props) {
    const { actionQueue, probableEvent } = useEventInjections()
    const { incidentHandlersConfiguration } = useSportConfiguration()

    const isActive = computed(() => {
      if (!byId(props.participant.stats, BasketballStatId.FoulBonus)?.value) {
        return 'white'
      }

      return 'red'
    })

    const isDisabled = computed(() => {
      const { disabled } =
        incidentHandlersConfiguration[BasketballIncidentId.FoulBonus] ??
        incidentHandlersConfiguration.fallbackHandler

      return disabled({ incidentId: BasketballIncidentId.FoulBonus })
    })

    function sendFoulBonusIncident(): void {
      actionQueue.value.add(
        new AddIncident({
          incident_id: BasketballIncidentId.FoulBonus,
          status_id: probableEvent.value.status_id,
          participant_team_id: props.participant.id,
        }),
      )
    }

    return {
      BasketballStatId,
      BasketballIncidentId,
      sendFoulBonusIncident,
      isActive,
      isDisabled,
    }
  },
})
</script>

<template>
  <Button
    size="xs"
    :color="isActive"
    :disabled="isDisabled"
    @click="sendFoulBonusIncident()"
  >
    FB
  </Button>
</template>
