import dayjs from 'dayjs'
import { intersection } from 'lodash'
import { Ref, ref } from 'vue'
import { Wretch } from 'wretch'

import Sentry from '@collector/shared-plugin-sentry-vue'
import {
  ApiClient,
  ApiErrorResponse,
  AuthInfoObject,
  AuthInfoResponse,
  isWretchError,
  RoleId,
  ScoutMessengerClient,
} from '@collector/sportsapi-client-legacy'
import { ApplicationAuthorization } from '@mobile/ApplicationAuthorization'
import { AppDatabase } from '@mobile/database/AppDatabase'
import { DatabasePrefix } from '@mobile/database/consts'
import * as app from '@mobile/globalState/app'
import * as localStorage from '@mobile/globalState/localStorage'
import * as scoutMessenger from '@mobile/globalState/scoutMessenger'
import * as sportsAPI from '@mobile/globalState/sportsAPI'
import { useDatabase } from '@mobile/reusables/useDatabase'

async function fetchAuthInfo(
  client: Wretch,
  token: string,
): Promise<AuthInfoObject> {
  const response = await client
    .url('/auth/info')
    .headers({ Authorization: `Bearer ${token}` })
    .get()
    .json<AuthInfoResponse>()

  return response.api.data
}

async function userHasChanged(database: AppDatabase | null): Promise<void> {
  database?.close()
  sportsAPI.setClientAsNotReady()
  app.setDatabaseAsDisconnected()
}

type Result = {
  authorization: ApplicationAuthorization
  login: () => void
  email: Ref<string>
  password: Ref<string>
  error: Ref<string | null>
}

export function useSportsAPILoginForm(): Result {
  const error: Ref<string | null> = ref(null)
  const authorization = new ApplicationAuthorization(
    localStorage.getSportsAPIJWT(),
  )
  let lastUserEmail: string | undefined = localStorage.getLastUserEmail()

  authorization.onNewToken(async (token, client) => {
    try {
      let authInfo: AuthInfoObject | undefined = localStorage.getAuthInfo()
      if (!authInfo) {
        authInfo = await fetchAuthInfo(client, token)

        const roleIds = authInfo.roles.map((role) => role.id)
        if (
          intersection(roleIds, [
            RoleId.Administrator,
            RoleId.JuniorScout,
            RoleId.Scout,
            RoleId.ScoutsSupervisor,
            RoleId.Editor,
            RoleId.EditorsSupervisor,
            RoleId.ScoutsQualityController,
            RoleId.ScoutsQualityAssistant,
          ]).length === 0
        ) {
          throw Error('Access denied')
        }

        if (localStorage.getLastUserEmail() !== lastUserEmail) {
          userHasChanged(app.state.database)
        }

        if (lastUserEmail) {
          localStorage.setLastUserEmail(lastUserEmail)
        }

        localStorage.setAuthInfo(authInfo)
      }

      if (!app.state.database || !app.state.isDatabaseConnected) {
        const currentDatabaseName = `${DatabasePrefix}${authInfo.id}`
        await AppDatabase.limitDatabasesByMostRecent(
          currentDatabaseName,
          Number(import.meta.env.VITE_MAXIMUM_NUMBER_OF_INDEXED_DBS),
        )
        const database = await useDatabase(currentDatabaseName)
        database.userLoginActions.put({
          lastLoginTimestamp: +dayjs(),
          userId: authInfo.id,
        })
        app.setDatabase(database)
        app.setDatabaseAsConnected()
      }

      await app.state.database.cleanUpFromFinishedEvents()
      localStorage.setSportsAPIJWT(token)
      sportsAPI.setAuthInfo(authInfo)
      sportsAPI.setAsNotAuthorizing()
      sportsAPI.setClientAsReady()
    } catch (_error) {
      authorization.authorize()
    }
  })
  authorization.onTokenExpired(() => {
    localStorage.removeItem(localStorage.ItemKey.AuthInfo)
    localStorage.removeItem(localStorage.ItemKey.SportsAPIJWT)
    sportsAPI.expireToken()
  })
  authorization.onAuthorizationFail((e) => {
    error.value = e.message
    sportsAPI.setAsNotAuthorizing()
  })

  const apiClient = new ApiClient(
    import.meta.env.VITE_SPORTS_API_URL,
    authorization,
  )
  const scoutMessengerClient = new ScoutMessengerClient(
    import.meta.env.VITE_SCOUT_MESSENGER_CONNECTION_SOCKET_URL,
  )

  scoutMessenger.setClient(scoutMessengerClient)

  apiClient
    .initAuthorization(import.meta.env.VITE_SPORTS_API_URL)
    .catch((e) => {
      if (isWretchError(e) && (e.status === 404 || e.status === 500)) {
        Sentry.captureException(
          new Error(`Received ${e.status} while login`),
          (context) => {
            const fromApi: ApiErrorResponse = JSON.parse(e.text || '')
            fromApi.api.method.parameters = {}
            context.setExtra('payload', JSON.stringify(fromApi))

            return context
          },
        )
      }
    })

  sportsAPI.setClient(apiClient)

  const email = ref('')
  const password = ref('')
  const login = (): void => {
    error.value = null
    sportsAPI.setAsIsAuthorizing()
    lastUserEmail = email.value
    authorization.fillCredentials({
      email: email.value,
      password: password.value,
    })
  }

  return {
    authorization,
    login,
    email,
    password,
    error,
  }
}
