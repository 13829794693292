<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, Ref } from 'vue'

import {
  notificationColors,
  notificationIcons,
} from '@mobile/reusables/notifications'
import { clearableRef } from '@mobile/reusables/refs/clearableRef'
import { AppNotification } from '@mobile/reusables/types/AppNotification'
import { OnNewNotificationCallback } from '@mobile/views/Relation/Shared/RelationDependencies/bindNotifications'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  setup() {
    const { notifications } = useEventInjections()

    let lastUnreadNotification: AppNotification | null =
      notifications.filteredNotifications.value.at(-1) ?? null

    if (lastUnreadNotification?.read) {
      lastUnreadNotification = null
    }

    const lastNotification: Ref<Readonly<AppNotification> | null> =
      clearableRef(
        lastUnreadNotification,
        Number(import.meta.env.VITE_NOTIFICATION_FADE_DELAY),
      )

    const notificationBackgroundClass = computed(() =>
      lastNotification.value
        ? `bg-${notificationColors.get(lastNotification.value.type)}`
        : '',
    )
    const notificationIcon = computed(() =>
      lastNotification.value
        ? notificationIcons.get(lastNotification.value.type)
        : '',
    )

    const handleNewNotification: OnNewNotificationCallback = (event) => {
      if (event.isVisible) {
        lastNotification.value = event.notification
      }
    }

    onMounted(() => {
      notifications.onNewNotification(handleNewNotification)
    })

    onUnmounted(() => {
      notifications.offNewNotification(handleNewNotification)
    })

    function closeLastNotification(): void {
      if (!lastNotification.value) {
        return
      }

      lastNotification.value.markAsRead()
      lastNotification.value = null
    }

    return {
      lastNotification,
      notificationIcon,
      closeLastNotification,
      notificationBackgroundClass,
    }
  },
})
</script>

<template>
  <transition name="bubble-fade">
    <div
      v-if="lastNotification"
      class="fixed right-0 top-11 z-30 flex cursor-pointer justify-end px-11"
    >
      <div
        class="bubble relative flex max-w-full items-center space-x-2 rounded-md px-3 py-1.5 text-sm text-white shadow-xl"
        :class="notificationBackgroundClass"
        @click="closeLastNotification"
      >
        <FontAwesomeIcon :icon="notificationIcon" />
        <div class="truncate">
          {{ lastNotification.content }}
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped>
.bubble-fade-enter-active {
  transition: opacity 0.5s ease;
}

.bubble-fade-enter-from,
.bubble-fade-leave-to {
  opacity: 0;
}

.bubble:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-width: 0 8px 8px;
  top: -8px;
  right: 19px;
  margin-left: -8px;
}

.bubble.bg-info:after {
  border-color: theme('colors.info') transparent;
}

.bubble.bg-warning:after {
  border-color: theme('colors.warning') transparent;
}

.bubble.bg-error:after {
  border-color: theme('colors.error') transparent;
}

.bubble.bg-debug:after {
  border-color: theme('colors.gray') transparent;
}
</style>
