export function stringifyError(error: unknown): string {
  if (typeof error === 'string') {
    return error
  }

  if (hasAttribute(error, 'text') && typeof error.text === 'string') {
    return error.text
  }

  if (hasAttribute(error, 'toString') && typeof error.toString === 'function') {
    const stringifiedError = error.toString()

    return stringifiedError === '[object Object]'
      ? JSON.stringify(error)
      : stringifiedError
  }

  return `Unknown error (${JSON.stringify(error)})`
}

function hasAttribute<T extends string>(
  val: unknown,
  attribute: T,
): val is { [key in T]: unknown } {
  return typeof val === 'object' && val !== null && attribute in val
}
