import { difference, groupBy } from 'lodash'

import Sentry from '@collector/shared-plugin-sentry-vue'
import {
  CollectorPushMessageEventsLineups,
  CollectorPushMessageEventsScouts,
  IncidentActionType,
  includesAdminRoles,
  includesControllerRole,
} from '@collector/sportsapi-client-legacy'
import { AppDatabase } from '@mobile/database/AppDatabase'
import * as sportsAPI from '@mobile/globalState/sportsAPI'
import { removeUnassignedEvent } from '@mobile/reusables/useSynchronizedEvents'
import router from '@mobile/router'
import { ScoutMessengerConnection } from '@mobile/ScoutMessengerConnection/ScoutMessengerConnection'

export function useScoutMessengerIndexedDBSynchronization(
  database: AppDatabase,
  socket: ScoutMessengerConnection,
): void {
  socket.on('event', (event) => {
    database.events.save(event)
  })

  socket.on('incident', (incident) => {
    switch (incident.data.action) {
      case IncidentActionType.INSERT:
      case IncidentActionType.UPDATE:
      case IncidentActionType.DELETE:
        database.incidents.save(incident)
        break
      default:
        throw Error(`Unsupported incident action (${incident.data.action})`)
    }
  })

  socket.on('error', async (error) => {
    Sentry.withScope((scope) => {
      scope.setExtra('payload', error)
      Sentry.captureException(error.data.message)
    })
  })

  function isLoggedInScoutUnassigned(
    eventsScouts: CollectorPushMessageEventsScouts,
  ): boolean {
    const roles = sportsAPI.state.authInfo?.roles ?? []

    if (includesAdminRoles(roles) || includesControllerRole(roles)) {
      return false
    }

    return !!(
      sportsAPI.state.authInfo?.id &&
      eventsScouts.data.unassignedScoutIds.includes(sportsAPI.state.authInfo.id)
    )
  }

  socket.on(
    'eventsScouts',
    async (eventsScouts: CollectorPushMessageEventsScouts) => {
      const imNotAssignedToThisEvent = isLoggedInScoutUnassigned(eventsScouts)

      database.eventsScouts.put(eventsScouts)

      if (imNotAssignedToThisEvent) {
        const routeEventId = router.currentRoute.value.params?.eventId

        if (routeEventId?.toString() === eventsScouts.eventId.toString()) {
          router.replace('/events-list')
        }
        await removeUnassignedEvent(database, eventsScouts.eventId)
      }
    },
  )

  socket.on(
    'eventsLineups',
    async (eventsLineups: CollectorPushMessageEventsLineups) => {
      const { eventId } = eventsLineups
      const groupedLineups = groupBy(
        eventsLineups.data.lineups,
        'participant_team_id',
      )
      const participants = Object.keys(groupedLineups).map((id) => Number(id))

      if (!participants.length) {
        const event = await database.events.getByEventId(eventId)
        const participantIds =
          event?.data.participants.map(({ id }) => id) ?? []

        await Promise.all(
          participantIds.map((participantId) => {
            return database.lineups.save(eventId, participantId, [])
          }),
        )
      } else {
        for (const participantId in groupedLineups) {
          await database.lineups.save(
            eventId,
            Number(participantId),
            groupedLineups[participantId],
          )
        }

        const participantIds =
          await database.lineups.findEventParticipants(eventId)
        const participantIdsToClear = difference(participantIds, participants)

        if (participantIdsToClear.length) {
          await database.lineups.clearParticipantsLineups(participantIdsToClear)
        }
      }
    },
  )
}
