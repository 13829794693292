import { Container } from 'inversify'
import { computed } from 'vue'

import { RelationIoCBindings } from './types'

export function bindProbableLinkedEvent(ioc: Container): void {
  ioc
    .bind(RelationIoCBindings.ProbableLinkedEvent)
    .toDynamicValue(async (ctx) => {
      const [probableEventRef, sportRef] = await Promise.all([
        ctx.container.getAsync(RelationIoCBindings.ProbableEvent),
        ctx.container.getAsync(RelationIoCBindings.Sport),
      ])

      return computed(() => {
        const event = probableEventRef.value
        const sport = sportRef.value

        const sportStatus = sport.sportsAPIEntity.statuses.find(
          (sportStatus) => sportStatus.id === event.status_id,
        )
        if (!sportStatus) {
          // @TODO add DEBUG notifiaction
          throw new Error(
            `Couldn't attach SportStatus to LinkedProbableEvent (${event.id}, ${event.status_id})`,
          )
        }

        return {
          event,
          sportStatus,
        }
      })
    })
    .inSingletonScope()
}
