<script lang="ts">
import { cloneDeep } from 'lodash'
import { computed, defineComponent, PropType, ref, Ref, watch } from 'vue'

import {
  CricketResultId,
  IncidentAttributeId,
  LegalBallAttributesId,
  LegalBallAttributesOptions,
} from '@collector/sportsapi-client-legacy'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import UpdateIncident from '@mobile/ActionQueue/Actions/Incidents/UpdateIncident'
import Button from '@mobile/components/Button/Button.vue'
import ToggleButtonGroup, {
  ToggleButtonGroupOption,
} from '@mobile/components/ToggleButton/ToggleButtonGroup.vue'
import ToggleSwitch from '@mobile/components/ToggleSwitch/ToggleSwitch.vue'
import { byId } from '@mobile/reusables/entityUtils'
import { incidentDiff } from '@mobile/reusables/eventUtils'
import { useAttributesToggleOptions } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useAttributesToggleOptions'
import PopupControls from '@mobile/views/Relation/Shared/Popup/PopupControls.vue'
import PopupTitle from '@mobile/views/Relation/Shared/Popup/PopupTitle.vue'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import IncrementableNumber from '@mobile/views/Relation/Sports/Cricket/LiveView/IncrementableNumber/IncrementableNumber.vue'

export default defineComponent({
  components: {
    PopupControls,
    Button,
    ToggleSwitch,
    IncrementableNumber,
    ToggleButtonGroup,
    PopupTitle,
  },
  props: {
    incident: {
      type: Object as PropType<ProbableIncident>,
      required: true,
    },
    probableLinkedParticipant: {
      type: Object as PropType<ProbableLinkedParticipant>,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const eventInjections = useEventInjections()
    const { actionQueue, probableEvent } = eventInjections
    const { id: eventId } = probableEvent.value
    const staticLinkedIncident = ref(cloneDeep(props.incident))
    const staticLinkedIncidentBeforeChange = ref(
      cloneDeep(staticLinkedIncident),
    )
    const isBoundary = ref(
      someAttributesIsBoundary(props.incident.attribute_ids),
    )
    watch(isBoundary, () => {
      runsOffBall.value = getRunsOfBallValue(
        staticLinkedIncidentBeforeChange.value.attribute_ids,
      )
      selectedAttribute.value =
        getSelectedAttributeValue(
          staticLinkedIncidentBeforeChange.value.attribute_ids,
        )?.value || null
      if (isBoundary.value) {
        runsOffBall.value = null
      }
    })
    const participant = props.probableLinkedParticipant.getParticipant()
    const wickets = computed(() =>
      byId(participant.value.results, CricketResultId.Wickets),
    )
    const scoreBeforeChange = computed(() => {
      const ballAttributesOption = [...LegalBallAttributesOptions].find(
        (option) => {
          return staticLinkedIncident.value.attribute_ids.find(
            (attribute) => attribute === option.id,
          )
        },
      )

      return ballAttributesOption?.value as number
    })
    const runsOffBallValue = computed(() =>
      getRunsOfBallValue(staticLinkedIncident.value.attribute_ids),
    )

    function getRunsOfBallValue(
      attributeIds: IncidentAttributeId[],
    ): { id: LegalBallAttributesId; value: number } | null {
      const legalBallAttributesOption = LegalBallAttributesOptions.find(
        (option) => attributeIds.find((attribute) => attribute === option.id),
      )
      const defaultOption = {
        id: LegalBallAttributesId.Zero,
        value: 0,
      }

      return legalBallAttributesOption || defaultOption
    }
    const attributeInputOptions = computed(() => {
      const attributes = isBoundary.value
        ? [
            LegalBallAttributesId.BoundaryFour,
            LegalBallAttributesId.BoundarySix,
          ]
        : [
            LegalBallAttributesId.Runs,
            LegalBallAttributesId.LegByes,
            LegalBallAttributesId.Byes,
          ]

      return useAttributesToggleOptions(attributes, eventInjections, false)
    })
    const selectedAttributeValue = computed(() =>
      getSelectedAttributeValue(staticLinkedIncident.value.attribute_ids),
    )

    function getSelectedAttributeValue(
      attributeIds: IncidentAttributeId[],
    ): ToggleButtonGroupOption | undefined {
      return attributeInputOptions.value.find((option) => {
        return attributeIds.find((attribute) => attribute === option.value)
      })
    }
    const runsOffBall: Ref<{
      id: LegalBallAttributesId
      value: number
    } | null> = ref(runsOffBallValue.value)
    const selectedAttribute = ref(selectedAttributeValue.value?.value || null)

    function someAttributesIsBoundary(
      attributeIds: IncidentAttributeId[],
    ): boolean {
      return attributeIds.some(
        (attributeId) =>
          attributeId === LegalBallAttributesId.BoundarySix ||
          attributeId === LegalBallAttributesId.BoundaryFour,
      )
    }

    function updateIncident(): void {
      const diff = incidentDiff(props.incident, staticLinkedIncident.value)
      const attributeIds = []

      if (selectedAttribute.value) {
        attributeIds.push(Number(selectedAttribute.value))
      }
      if (runsOffBall?.value?.id && !someAttributesIsBoundary(attributeIds)) {
        attributeIds.push(runsOffBall?.value?.id)
      }

      actionQueue.value.add(
        new UpdateIncident(props.incident.id, {
          incident_id: props.incident.incident_id,
          event_id: eventId,
          status_id: props.incident.event_status_id,
          attribute_ids: attributeIds,
          participant_team_id: props.incident.participant_id,
          participant_id: diff.subparticipant_id,
          participant_name: diff.subparticipant_name,
          assistant_id: diff.assistant_id,
          assistant_name: diff.assistant_name,
          minute: null,
          second: null,
        }),
      )

      emit('close')
    }

    return {
      isBoundary,
      runsOffBall,
      LegalBallAttributesId,
      selectedAttribute,
      attributeInputOptions,
      updateIncident,
      LegalBallAttributesOptions,
      scoreBeforeChange,
      wickets,
    }
  },
})
</script>

<template>
  <div class="bg-silver px-3 pt-3">
    <PopupTitle>
      <template #title>Edit legal ball</template>
    </PopupTitle>
  </div>
  <div class="ml-4 mr-4 mt-1 grid rounded-md p-1">
    <div class="grid-gap-2 grid w-full grid-cols-3 bg-gray-400">
      <ToggleSwitch
        v-model:value="isBoundary"
        class="flex h-16"
      >
        <span class="text-gray text-base">Is Boundary</span>
      </ToggleSwitch>
      <IncrementableNumber
        v-if="!isBoundary && runsOffBall && wickets"
        v-model:value="runsOffBall"
        class="col-span-2"
        :scoreBeforeChange
        :wickets
        :options="LegalBallAttributesOptions"
      />
    </div>
    <div class="mt-3 w-full">
      <ToggleButtonGroup
        v-model:value="selectedAttribute"
        :options="attributeInputOptions"
        type="grid"
        :undeselectable="true"
      />
    </div>
  </div>
  <PopupControls>
    <template #buttons>
      <div class="flex justify-end space-x-2">
        <Button
          :color="'red'"
          :size="'lg'"
          @click="$emit('close')"
        >
          Close
        </Button>
        <Button
          :color="'green'"
          :size="'lg'"
          :disabled="!selectedAttribute"
          @click="updateIncident()"
        >
          Send
        </Button>
      </div>
    </template>
  </PopupControls>
</template>
