export enum BasketballIncidentId {
  TvTimeout = 179,
  TvTimeoutOver = 2715,
  NotStarted = 192,
  StartDelayed = 202,
  MatchAboutToStart = 2714,
  FirstQuarterStarted = 183,
  BreakAfter = 2292,
  BreakAfterFirstQuarter = 2293,
  SecondQuarterStarted = 184,
  BreakAfterSecondQuarter = 2294,
  ThirdQuarterStarted = 185,
  BreakAfterThirdQuarter = 2295,
  FourthQuarterStarted = 186,
  FinishedRegularTime = 198,
  WaitingForOvertime = 194,
  FirstOvertimeStarted = 2287,
  BreakAfterFirstOvertime = 2297,
  SecondOvertimeStarted = 2288,
  BreakAfterSecondOvertime = 2298,
  ThirdOvertimeStarted = 2289,
  BreakAfterThirdOvertime = 2299,
  FourthOvertimeStarted = 2290,
  BreakAfterFourthOvertime = 2300,
  FifthOvertimeStarted = 2291,
  FirstHalfStarted = 2285,
  BreakAfterFirstHalf = 2301,
  SecondHalfStarted = 2286,
  FinishedAfterOvertime = 196,
  FinishedAwardedWin = 197,
  Interrupted = 201,
  Postponed = 200,
  Cancelled = 199,
  Abandoned = 203,
  ToFinish = 41,
  TwoPointShotMissed = 162,
  ThreePointShotMissed = 163,
  OnePointShotMissed = 161,
  TwoPointShotMade = 159,
  ThreePointShotMade = 160,
  OnePointShotMade = 158,
  Foul = 164,
  Block = 190,
  Steal = 334,
  DefensiveRebound = 166,
  OffensiveRebound = 165,
  Turnover = 176,
  ThrowIn = 2305,
  FreeThrowsAwarded = 2716,
  FoulBonus = 188,
  InPossession = 156,
  TimeoutOver = 2718,
  Timeout = 2717,
  JumpBall = 180,
  BreakDuringGame = 2303,
  PlayUnderReview = 2304,
  Injury = 181,
  RefereeInjury = 2302,
}
