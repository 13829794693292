import { Container } from 'inversify'

import {
  HandballEventDetailId,
  HandballEventStatusId,
} from '@collector/sportsapi-client-legacy'
import { getNumericDetailValue } from '@mobile/reusables/eventUtils'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindEventStatusesConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.EventStatusesConfiguration)
    .toDynamicValue(async (ctx) => {
      const event = await ctx.container.getAsync(
        RelationIoCBindings.ProbableEvent,
      )
      const periodLength = getNumericDetailValue(
        event.value,
        HandballEventDetailId.PeriodLength,
        30,
      )
      const extraPeriodLength = getNumericDetailValue(
        event.value,
        HandballEventDetailId.ExtraPeriodLength,
        5,
      )

      return {
        [HandballEventStatusId.NotStarted]: {
          time: {
            minute: 0,
            second: 0,
          },
        },
        [HandballEventStatusId.StartDelayed]: {
          time: {
            minute: 0,
            second: 0,
          },
        },
        [HandballEventStatusId.FinishedAwardedWin]: {
          time: {
            minute: 0,
            second: 0,
          },
        },
        [HandballEventStatusId.Postponed]: {
          time: {
            minute: 0,
            second: 0,
          },
        },
        [HandballEventStatusId.Cancelled]: {
          time: {
            minute: 0,
            second: 0,
          },
        },
        [HandballEventStatusId.Abandoned]: {
          time: {
            minute: 0,
            second: 0,
          },
        },
        [HandballEventStatusId.FirstHalf]: {
          time: {
            minute: 0,
            second: 0,
          },
          timeBoundaryInSeconds: periodLength * 60,
        },
        [HandballEventStatusId.FinishedAfterExtratime]: {
          time: {
            minute: 0,
            second: 0,
          },
        },
        [HandballEventStatusId.WaitingForPenalties]: {
          time: {
            minute: 0,
            second: 0,
          },
        },
        [HandballEventStatusId.PenaltyShootout]: {
          time: {
            minute: 0,
            second: 0,
          },
        },
        [HandballEventStatusId.FinishedAfterPenalties]: {
          time: {
            minute: 0,
            second: 0,
          },
        },
        [HandballEventStatusId.Halftime]: {
          time: {
            minute: periodLength,
            second: 0,
          },
        },
        [HandballEventStatusId.SecondHalf]: {
          time: {
            minute: periodLength,
            second: 0,
          },
          timeBoundaryInSeconds: periodLength * 60,
        },
        [HandballEventStatusId.Finished]: {
          time: {
            minute: periodLength,
            second: 0,
          },
        },
        [HandballEventStatusId.WaitingForExtratime]: {
          time: {
            minute: periodLength * 2,
            second: 0,
          },
        },
        [HandballEventStatusId.ExtratimeFirstHalf]: {
          time: {
            minute: periodLength * 2,
            second: 0,
          },
          timeBoundaryInSeconds: extraPeriodLength * 60,
        },
        [HandballEventStatusId.ExtratimeHalftime]: {
          time: {
            minute: periodLength * 2 + extraPeriodLength,
            second: 0,
          },
        },
        [HandballEventStatusId.ExtratimeSecondHalf]: {
          time: {
            minute: periodLength * 2 + extraPeriodLength,
            second: 0,
          },
          timeBoundaryInSeconds: extraPeriodLength * 60,
        },
        [HandballEventStatusId.WaitingForSecondExtratime]: {
          time: {
            minute: periodLength * 2 + extraPeriodLength * 2,
            second: 0,
          },
        },
        [HandballEventStatusId.SecondExtratimeFirstHalf]: {
          time: {
            minute: periodLength * 2 + extraPeriodLength * 2,
            second: 0,
          },
          timeBoundaryInSeconds: extraPeriodLength * 60,
        },
        [HandballEventStatusId.SecondExtratimeHalftime]: {
          time: {
            minute: periodLength * 2 + extraPeriodLength * 3,
            second: 0,
          },
        },
        [HandballEventStatusId.SecondExtratimeSecondHalf]: {
          time: {
            minute: periodLength * 2 + extraPeriodLength * 3,
            second: 0,
          },
          timeBoundaryInSeconds: extraPeriodLength * 60,
        },
      }
    })
    .inSingletonScope()
}
