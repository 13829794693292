import { cloneDeep, get, isEqual, pick as lodashPick } from 'lodash'

import { Participant, TeamSide } from '@collector/sportsapi-client-legacy'

export function updateProps<T extends {}>(
  target: T,
  updateData: T,
  props: Array<keyof T>,
): void {
  props.forEach((prop) => {
    if (!isEqual(target[prop], updateData[prop])) {
      target[prop] = updateData[prop]
    }
  })
}

export function updatePairs<T extends { id: unknown }>(
  target: T[],
  updateData: T[],
  onPair: (targetItem: T, updateDataItem: T) => void,
): void {
  return updatePairsByKey('id', target, updateData, onPair)
}

export function updatePairsByKey<T extends { [key: string | number]: unknown }>(
  key: string | number,
  target: T[],
  updateData: T[],
  onPair: (targetItem: T, updateDataItem: T) => void,
): void {
  // Update existing pairs and add new
  updateData.forEach((updateDataItem) => {
    const targetItem: T | undefined = target.find(
      (targetItem) => targetItem[key] === updateDataItem[key],
    )
    if (targetItem) {
      onPair(targetItem, updateDataItem)
    } else {
      target.push(cloneDeep(updateDataItem))
    }
  })

  // Remove pairs that are not in updateData
  // Iterate reverse so indexes won't mix up when splicing
  for (let index = target.length - 1; index >= 0; index--) {
    const targetItem = target[index]
    const updateDataItem = updateData.find(
      (updateDataItem) => updateDataItem.id === targetItem.id,
    )
    if (!updateDataItem) {
      target.splice(index, 1)
    }
  }
}

export function byId<T extends { id: unknown }>(
  elements: T[],
  id: unknown,
): T | undefined {
  return elements.find((element) => element.id === id)
}

export function isHomeTeam(element: Participant): boolean {
  return element.counter === 1
}

export function isAwayTeam(element: Participant): boolean {
  return element.counter === 2
}

export function getHomeTeam(elements: Participant[]): Participant | undefined {
  return elements.find((element) => isHomeTeam(element))
}

export function getAwayTeam(elements: Participant[]): Participant | undefined {
  return elements.find((element) => isAwayTeam(element))
}

export function getTeamSide(
  participants: Participant[],
  participantId: number,
): TeamSide {
  const participant = byId(participants, participantId)

  if (!participant) {
    throw new Error('Participant not found')
  }

  return isHomeTeam(participant) ? TeamSide.Home : TeamSide.Away
}

export function getOppositeSide(side: TeamSide): TeamSide {
  return side === TeamSide.Home ? TeamSide.Away : TeamSide.Home
}

export function getOppositeTeamSide(
  participants: Participant[],
  participantId: number,
): TeamSide {
  const participant = byId(participants, participantId)

  if (!participant) {
    throw new Error('Participant not found')
  }

  return isHomeTeam(participant) ? TeamSide.Away : TeamSide.Home
}

export function getOppositeTeam(
  elements: Participant[],
  element: Participant,
): Participant | undefined {
  let oppositeTeam: Participant | undefined

  if (isHomeTeam(element)) {
    oppositeTeam = getAwayTeam(elements)
  } else if (isAwayTeam(element)) {
    oppositeTeam = getHomeTeam(elements)
  }

  return oppositeTeam
}

export function getOrThrow<TObject extends object, TKey extends keyof TObject>(
  object: TObject,
  key: TKey,
): NonNullable<TObject[TKey]> {
  const value = get(object, key)

  if (!value) {
    throw new Error(
      `property: ${String(key)} does not exist in object: ${object}`,
    )
  }

  return value as NonNullable<TObject[TKey]>
}

export function pick<T, K extends keyof T>(object: T, keys: K[]): Pick<T, K>
export function pick<T, K extends keyof T>(object: T, ...keys: K[]): Pick<T, K>
export function pick<T, K extends keyof T>(object: T, keys: K[]): Pick<T, K> {
  return lodashPick(object, keys) as Pick<T, K>
}
