<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'

import HistoryDelete from '@mobile/views/Relation/Shared/Popups/History/HistoryItem/HistoryDelete.vue'
import HistoryDisplay from '@mobile/views/Relation/Shared/Popups/History/HistoryItem/HistoryDisplay.vue'
import HistoryEdit from '@mobile/views/Relation/Shared/Popups/History/HistoryItem/HistoryEdit.vue'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'

enum Status {
  Display,
  Edit,
  Delete,
}
export default defineComponent({
  components: {
    HistoryDisplay,
    HistoryEdit,
    HistoryDelete,
  },
  props: {
    linkedIncident: {
      type: Object as PropType<ProbableLinkedIncident>,
      required: true,
    },
  },
  setup() {
    const edit = ref<Status>(Status.Display)
    function showView(status: Status): void {
      edit.value = status
    }

    return {
      edit,
      Status,
      showView,
    }
  },
})
</script>

<template>
  <div>
    <HistoryDisplay
      v-show="edit === Status.Display"
      :linkedIncident
      @toggleEdit="showView(Status.Edit)"
      @toggleDelete="showView(Status.Delete)"
    />
    <HistoryEdit
      v-if="edit === Status.Edit"
      :linkedIncident
      @close="showView(Status.Display)"
    />
    <HistoryDelete
      v-show="edit === Status.Delete"
      :linkedIncident
      @close="showView(Status.Display)"
    />
  </div>
</template>

<style lang="scss" scoped>
@use 'sass:math';
@import '@mobile/styles/variables.scss';
@import '@mobile/styles/mixins.scss';

.header-property {
  $timeWidth: 70px;
  $marginBetweenProperties: 6px;
  $countOfAllProperties: 4;
  $countOfDynamicProperties: 3;

  @mixin dynamicPropertyWidth($percentWidth) {
    $timeOffset: math.div($timeWidth, $countOfDynamicProperties);
    $marginOffset: math.div(
      $marginBetweenProperties * $countOfAllProperties * 2,
      $countOfDynamicProperties
    );

    @include staticWidth(
      calc(#{$percentWidth} - #{$timeOffset} - #{$marginOffset})
    );
  }

  display: flex;
  margin: 0 $marginBetweenProperties;
  overflow: hidden;
  font-size: 10px;
  white-space: nowrap;
  align-items: center;

  &-time {
    @include staticWidth($timeWidth);
  }

  &-status {
    @include dynamicPropertyWidth(24%);
  }

  &-participant {
    @include dynamicPropertyWidth(38%);
  }

  &-info {
    @include dynamicPropertyWidth(38%);
  }
}
</style>
