<script lang="ts">
import { defineComponent } from 'vue'

import { opChain } from '@collector/shared-utils'
import { VolleyballIncidentId } from '@collector/sportsapi-client-legacy'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import { useVolleyballConfiguration } from '@mobile/views/Relation/Sports/Volleyball/VolleyballRelationDependencies/useVolleyballConfiguration'

export default defineComponent({
  components: { IncidentButton },

  setup() {
    const { activeParticipant, inactiveParticipant } =
      useVolleyballConfiguration()

    return {
      VolleyballIncidentId,
      activeParticipant,
      inactiveParticipant,
      opChain,
    }
  },
})
</script>

<template>
  <div class="flex flex-col space-y-4">
    <IncidentButton
      class="flex-1"
      :type="'horizontal'"
      :size="'xl'"
      :color="activeParticipant.color"
      :incidentId="VolleyballIncidentId.ServiceAce"
      :participantId="opChain(activeParticipant.participant, (v) => v.id)"
    />
    <IncidentButton
      class="flex-1"
      :type="'horizontal'"
      :size="'xl'"
      :color="activeParticipant.color"
      :incidentId="VolleyballIncidentId.AttackPoint"
      :participantId="opChain(activeParticipant.participant, (v) => v.id)"
    />
    <IncidentButton
      class="flex-1"
      :type="'horizontal'"
      :size="'xl'"
      :color="inactiveParticipant.color"
      :incidentId="VolleyballIncidentId.BlockPoint"
      :participantId="opChain(inactiveParticipant.participant, (v) => v.id)"
    />
  </div>
</template>
