import {
  HandballGoalAttribute,
  HandballIncidentId,
} from '@collector/sportsapi-client-legacy'
import EventTimeButton from '@mobile/views/Relation/Shared/EventTimeButton/EventTimeButton.vue'
import { useNavigationSeparator } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useNavigationSeparator'
import { usePopupButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/usePopupButton'
import { PopupBuilder } from '@mobile/views/Relation/Shared/Popup/builder/PopupBuilder'
import SelectedParticipant from '@mobile/views/Relation/Shared/Popup/builder/SelectedParticipant.vue'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'

import { RedCardPopup } from './RedCardPopup'
import { SuspensionPopup } from './SuspensionPopup'
import { YellowCardPopup } from './YellowCardPopup'

export const PenaltyPopup = new PopupBuilder('Penalty', {
  incident_id: HandballIncidentId.Penalty,
})
  .withTeamSelection()
  .appendBody(SelectedParticipant)
  .setNavigationButtons([
    usePopupButton({ component: EventTimeButton }),
    usePopupButton({
      props: {
        text: "Referee's consultation",
        color: 'blue',
        onClick: (context: PopupContext | null) => {
          context?.sendIncident(
            {
              incident_id: HandballIncidentId.RefereeConsultation,
              participant_team_id: undefined,
            },
            false,
          )
        },
      },
    }),
    usePopupButton({
      props: {
        text: '2 minute suspension',
        color: 'blue',
        onClick: (context: PopupContext | null) =>
          context?.openPopup(SuspensionPopup),
      },
    }),
    usePopupButton({
      props: {
        text: 'Yellow card',
        color: 'blue',
        onClick: (context: PopupContext | null) =>
          context?.openPopup(YellowCardPopup),
      },
    }),
    usePopupButton({
      props: {
        text: 'Red card',
        color: 'blue',
        onClick: (context: PopupContext | null) =>
          context?.openPopup(RedCardPopup),
      },
    }),
    ...useNavigationSeparator(2),
    usePopupButton({
      props: {
        text: 'Missed',
        color: 'blue',
        onClick: (context: PopupContext | null) => {
          context?.sendIncident({
            incident_id: HandballIncidentId.MissedPenalty,
            participant_team_id: context.incidentActionComposable
              ?.getProbableLinkedParticipant()
              ?.getParticipant().value?.id,
          })
          context?.closeAllPopups()
        },
      },
    }),
    usePopupButton({
      props: {
        text: 'Saved by Goalkeeper',
        color: 'blue',
        onClick: (context: PopupContext | null) => {
          context?.sendIncident({
            incident_id: HandballIncidentId.PenaltySaved,
            participant_team_id: context.incidentActionComposable
              .getProbableLinkedParticipant()
              ?.getParticipant().value?.id,
          })
          context?.closeAllPopups()
        },
      },
    }),
    usePopupButton({
      props: {
        text: 'Goal',
        color: 'blue',
        onClick: (context: PopupContext | null) => {
          context?.sendIncident({
            incident_id: HandballIncidentId.Goal,
            participant_team_id: context.incidentActionComposable
              ?.getProbableLinkedParticipant()
              ?.getParticipant().value?.id,
            attribute_ids: [HandballGoalAttribute.PenaltyGoal],
          })
          context?.closeAllPopups()
        },
      },
    }),
  ])
  .build()
