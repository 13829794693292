<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import {
  BetStatus,
  HandballStatId,
  Participant,
} from '@collector/sportsapi-client-legacy'
import { byId } from '@mobile/reusables/entityUtils'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  props: {
    participant: {
      type: Object as PropType<Participant>,
      required: true,
    },
  },
  setup(props) {
    const { probableEvent } = useEventInjections()
    const betStatus = computed(() => probableEvent.value.bet_status)
    const isEmptyNet = computed(
      () => !!byId(props.participant.stats, HandballStatId.EmptyNet)?.value,
    )

    const fontClasses = computed(() => {
      if (isEmptyNet.value) {
        return ['text-green']
      }

      return betStatus.value === BetStatus.Suspended
        ? ['text-white', 'text-opacity-40']
        : ['text-lightblack', 'text-opacity-40']
    })

    return { fontClasses }
  },
})
</script>

<template>
  <div
    class="text-xs font-bold"
    :class="fontClasses"
  >
    EN
  </div>
</template>
