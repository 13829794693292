import { Ref } from 'vue'

import { IncidentId } from '@collector/sportsapi-client-legacy'
import { Action } from '@mobile/ActionQueue/Action'
import { ActionQueue } from '@mobile/ActionQueue/ActionQueue'
import IncidentAction from '@mobile/ActionQueue/Actions/Incidents/IncidentAction'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import { resolveProbableIncident } from '@mobile/ActionQueue/ActionUtils'
import { EventType } from '@mobile/ActionQueue/EventType'
import { AppNotificationType } from '@mobile/reusables/types/AppNotificationType'
import { Notifications } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { IncidentsConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/IncidentsConfiguration'

import { Hook } from './Hook'

export class HookSidesCheck implements Hook {
  constructor(
    private readonly notifications: Notifications,
    private readonly actionQueue: ActionQueue,
    private readonly notDeletableProbableIncidents: Ref<
      ProbableIncident[] | null
    >,
    private readonly notifyOnIncidentIds: IncidentId[],
    private readonly incidentsConfiguration: IncidentsConfiguration,
  ) {}

  public install(): void {
    this.actionQueue.on(EventType.ActionAdded, this.onActionQueueAdded)
  }

  public uninstall(): void {
    this.actionQueue.off(EventType.ActionAdded, this.onActionQueueAdded)
  }

  private onActionQueueAdded = (action: Action<unknown>): void => {
    if (!(action instanceof IncidentAction)) {
      return
    }

    const incident = resolveProbableIncident(
      action,
      this.actionQueue,
      this.notDeletableProbableIncidents.value || [],
      this.incidentsConfiguration,
    )
    if (!incident || !this.notifyOnIncidentIds.includes(incident.incident_id)) {
      return
    }

    this.notifications.sendNotification({
      type: AppNotificationType.Info,
      title: 'Sides check reminder',
      content: 'Make sure that teams are on correct sides',
    })
  }
}
