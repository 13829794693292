import { ApiError } from '../basic/ApiError'
import { Event } from '../basic/Event'
import { Incident } from '../basic/Incident'
import { ShortEventInfo } from '../basic/ShortEventInfo'
import { EventsLineups } from '../shared/EventsLineups'

export enum PushMessageType {
  Error = 'error',
  Incident = 'incident',
  Event = 'event',
  ConnectionLive = 'connection-live',
  EventsScouts = 'events_scouts',
  EventsLineups = 'events_lineups',
}

export interface PushMessage<T = unknown, D = unknown> {
  data: D
  id: number
  source: number
  ut: number
  uuid: string
  type: T
}

export type PushMessageError = PushMessage<
  PushMessageType.Error,
  {
    error: ApiError
  }
>

export type PushMessageEvent = PushMessage<
  PushMessageType.Event,
  {
    event: Event
  }
>

export type PushMessageIncident = PushMessage<
  PushMessageType.Incident,
  {
    event: Event
    incident: Incident
  }
>

export type PushMessageEventsScouts = PushMessage<
  PushMessageType.EventsScouts,
  {
    shorteventinfo: ShortEventInfo
  }
>

export type PushMessageEventsLineups = PushMessage<
  PushMessageType.EventsScouts,
  {
    eventlineupsinfo: EventsLineups
  }
>

export type PushMessageConnectionAlive = {
  channel: string
  type: PushMessageType.ConnectionLive
}

export const isWebsocketErrorMessage = (
  message: PushMessage,
): message is PushMessageError => {
  return message?.type === PushMessageType.Error
}

export const isWebsocketEventMessage = (
  message: PushMessage,
): message is PushMessageEvent => {
  return message?.type === PushMessageType.Event
}

export const isWebsocketIncidentMessage = (
  message: PushMessage,
): message is PushMessageIncident => {
  return message?.type === PushMessageType.Incident
}

export const isWebsocketEventsScoutsMessage = (
  message: PushMessage,
): message is PushMessageEventsScouts => {
  return message?.type === PushMessageType.EventsScouts
}

export const isWebsocketEventsLineupsMessage = (
  message: PushMessage,
): message is PushMessageEventsLineups => {
  return message?.type === PushMessageType.EventsLineups
}
