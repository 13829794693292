import { watch, WatchCallback, WatchOptions, WatchSource } from 'vue'

export type MapSources<T> = {
  [K in keyof T]: T[K] extends WatchSource<infer V> ? V : never
}
export type MapOldSources<T, Immediate> = {
  [K in keyof T]: T[K] extends WatchSource<infer V>
    ? Immediate extends true
      ? V | undefined
      : V
    : never
}

export function watchOnce<
  T extends Readonly<WatchSource<unknown>[]>,
  Immediate extends Readonly<boolean> = false,
>(
  source: T,
  cb: WatchCallback<MapSources<T>, MapOldSources<T, Immediate>>,
  options?: WatchOptions<Immediate>,
): void

export function watchOnce<
  T extends Readonly<WatchSource<unknown>[]>,
  Immediate extends Readonly<boolean> = false,
>(
  source: T,
  cb: WatchCallback<MapSources<T>, MapOldSources<T, Immediate>>,
  options?: WatchOptions<Immediate>,
): void

export function watchOnce<T, Immediate extends Readonly<boolean> = false>(
  sources: WatchSource<T>,
  cb: WatchCallback<T, Immediate extends true ? T | undefined : T>,
  options?: WatchOptions<Immediate>,
): void

export function watchOnce<Immediate extends Readonly<boolean> = false>(
  source: object,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  cb: Function,
  options?: WatchOptions<Immediate>,
): void {
  const stop = watch(
    source,
    (...args) => {
      stop()

      return cb(...args)
    },
    options,
  )
}
