import { Container } from 'inversify'

import { eventBus } from '@mobile/globalState/eventBus'
import { HookErrors } from '@mobile/views/Relation/Shared/Hooks/HookErrors'
import { HookForceUpdate } from '@mobile/views/Relation/Shared/Hooks/HookForceUpdate'
import { HookQueueNotifications } from '@mobile/views/Relation/Shared/Hooks/HookQueueNotifications'
import { HookScoutMessengerConnectionStatus } from '@mobile/views/Relation/Shared/Hooks/HookScoutMessengerConnectionStatus'
import { HookSystemTimeValidation } from '@mobile/views/Relation/Shared/Hooks/HookSystemTimeValidation'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindRelationHooks(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.EventNotificationHooks)
    .toDynamicValue(async (ctx) => {
      const [notifications, actionQueue] = await Promise.all([
        ctx.container.getAsync(RelationIoCBindings.Notifications),
        ctx.container.getAsync(RelationIoCBindings.ActionQueue),
        ctx.container.getAsync(
          RelationIoCBindings.ProbableNotDeletableIncidents,
        ),
        ctx.container.getAsync(
          RelationSportCommonIoCBindings.IncidentsConfiguration,
        ),
      ])

      const hooks = [
        new HookErrors(notifications, eventBus),
        new HookSystemTimeValidation(notifications),
        new HookScoutMessengerConnectionStatus(notifications),
        new HookQueueNotifications(notifications, actionQueue.value),
        new HookForceUpdate(notifications),
      ]

      return hooks
    })
    .inSingletonScope()
}
