<script lang="ts">
import { defineComponent, PropType, Ref, ref } from 'vue'

import { opChain } from '@collector/shared-utils'
import {
  IncidentId,
  Participant,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import { ButtonColor } from '@mobile/components/Button/ButtonTypes'
import ParticipantLogo from '@mobile/components/ParticipantLogo/ParticipantLogo.vue'
import { BallPosition } from '@mobile/reusables/types/position/BallPosition'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import Breadcrumbs from '@mobile/views/Relation/Shared/Popup/Breadcrumbs.vue'
import PopupControls from '@mobile/views/Relation/Shared/Popup/PopupControls.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: {
    Button,
    PopupControls,
    ParticipantLogo,
    IncidentButton,
    Breadcrumbs,
  },
  props: {
    position: {
      type: Object as PropType<BallPosition>,
      required: true,
    },
  },
  emits: ['close'],
  setup() {
    const { probableFirstParticipant, probableSecondParticipant } =
      useEventInjections()
    const selectedParticipant: Ref<Participant | null> = ref(null)

    function selectParticipant(participant: Participant): void {
      selectedParticipant.value = participant
    }

    function getParticipantColor(participant: Participant): ButtonColor {
      const isParticipantSelected =
        selectedParticipant.value?.id === participant.id
      const colors: ButtonColor[] = ['home', 'away']

      if (!isParticipantSelected) {
        return 'white'
      }

      return colors[participant.counter - 1]
    }

    const incidentIds: IncidentId[] = [
      SoccerIncidentId.FreeKick,
      SoccerIncidentId.Offside,
      SoccerIncidentId.GoalKick,
      SoccerIncidentId.ShotOnTarget,
      SoccerIncidentId.ShotOffTarget,
      SoccerIncidentId.ThrowIn,
      SoccerIncidentId.ShotBlocked,
    ]

    return {
      incidentIds,
      getParticipantColor,
      selectParticipant,
      selectedParticipant,
      probableFirstParticipant,
      probableSecondParticipant,
      opChain,
    }
  },
})
</script>

<template>
  <div>
    <Breadcrumbs name="Add incident with ball position" />

    <div class="px-4 py-6">
      <div class="flex space-x-2 pb-4">
        <Button
          class="flex w-full justify-center"
          :color="getParticipantColor(probableFirstParticipant)"
          :size="'sm'"
          data-test-id="IncidentWithPositionPopupFirstParticipantButton"
          @click="selectParticipant(probableFirstParticipant)"
        >
          <div class="flex h-full items-center space-x-1">
            <ParticipantLogo
              class="h-full w-auto"
              :participant="probableFirstParticipant"
            />
            <div>{{ probableFirstParticipant.short_name }}</div>
          </div>
        </Button>
        <Button
          class="flex w-full justify-center"
          :color="getParticipantColor(probableSecondParticipant)"
          :size="'sm'"
          @click="selectParticipant(probableSecondParticipant)"
        >
          <div class="flex h-full items-center space-x-1">
            <ParticipantLogo
              class="h-full w-auto"
              :participant="probableSecondParticipant"
            />
            <div>{{ probableSecondParticipant.short_name }}</div>
          </div>
        </Button>
      </div>

      <hr class="border-gray mx-auto mb-4 w-4/5 border-opacity-10" />

      <div class="grid grid-cols-3 gap-3">
        <IncidentButton
          v-for="incidentId in incidentIds"
          :key="incidentId"
          :incidentId
          :participantId="opChain(selectedParticipant, (v) => v.id)"
          :position
          :type="'horizontal'"
          :color="'white'"
          :size="'sm'"
          @incidentHandled="$emit('close')"
        />
      </div>
    </div>

    <PopupControls>
      <template #buttons>
        <div class="flex justify-end space-x-2">
          <Button
            :color="'red'"
            :size="'lg'"
            @click="$emit('close')"
          >
            Cancel
          </Button>
        </div>
      </template>
    </PopupControls>
  </div>
</template>
