import { computed, ComputedRef, Ref } from 'vue'

import {
  CricketEventStatusId,
  CricketResultId,
  Event,
} from '@collector/sportsapi-client-legacy'
import { byId } from '@mobile/reusables/entityUtils'
import { ActiveParticipant } from '@mobile/views/Relation/Sports/Cricket/CricketRelationDependencies/ActiveParticipant'

function extractMainResultValues(input: string): {
  runs: number
  wickets: number
  overs: number
  currentBall: number
} {
  const regex = /\d+(\.\d+)?/g
  const matches = input.match(regex) ?? []

  const numbers: number[] = []

  matches.forEach((match) => {
    if (match.includes('.')) {
      const [integerPart, decimalPart] = match.split('.')
      numbers.push(Number(integerPart))
      numbers.push(Number(decimalPart))
    } else {
      numbers.push(Number(match))
    }
  })

  return {
    runs: numbers?.[0] ?? '-',
    wickets: numbers?.[1] ?? '-',
    overs: numbers?.[2] ?? '-',
    currentBall: numbers?.[3] ?? '-',
  }
}

export type CricketMainResultExtractor = {
  extractedValues: ComputedRef<{
    runs: number
    wickets: number
    overs: number
    currentBall: number
  }>
}

export function useCricketMainResultExtractor(
  activeParticipant: Ref<ActiveParticipant>,
  probableEvent: Ref<Event>,
): CricketMainResultExtractor {
  const currentMainResultId = computed(() => {
    const resultId = [
      CricketEventStatusId.FirstInningsHome,
      CricketEventStatusId.FirstInningsAway,
    ].includes(probableEvent.value.status_id as CricketEventStatusId)
      ? CricketResultId.Results
      : CricketResultId.SuperOverResult

    return resultId
  })

  const mainResultValue = computed(() => {
    if (!activeParticipant.value.participant) {
      return null
    }

    return (
      byId(
        activeParticipant.value.participant.results,
        currentMainResultId.value,
      )?.value ?? null
    )
  })

  const extractedValues = computed(() =>
    extractMainResultValues(mainResultValue.value?.toString() ?? ''),
  )

  return { extractedValues }
}
