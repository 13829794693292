<script lang="ts">
import { defineComponent, PropType } from 'vue'

import {
  CricketIncidentId,
  LegalBallAttributesId,
} from '@collector/sportsapi-client-legacy'
import { ToggleButtonGroupOption } from '@mobile/components/ToggleButton/ToggleButtonGroup.vue'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'

import SelectNumberOfPopup from '../SelectNumberOf/SelectNumberOfPopup.vue'

export default defineComponent({
  components: { SelectNumberOfPopup },
  props: {
    context: {
      type: Object as PropType<PopupContext | null>,
      required: false,
    },
  },
  setup() {
    const incidentId: CricketIncidentId.LegalBall = CricketIncidentId.LegalBall
    const coreAttributeId = LegalBallAttributesId.Byes
    const options: ToggleButtonGroupOption[] = [
      {
        name: '0',
        value: LegalBallAttributesId.Zero,
      },
      {
        name: '1',
        value: LegalBallAttributesId.One,
      },
      {
        name: '2',
        value: LegalBallAttributesId.Two,
      },
      {
        name: '3',
        value: LegalBallAttributesId.Three,
      },
      {
        name: '4',
        value: LegalBallAttributesId.Four,
      },
      {
        name: '5',
        value: LegalBallAttributesId.Five,
      },
      {
        name: '6',
        value: LegalBallAttributesId.Six,
      },
      {
        name: '7',
        value: LegalBallAttributesId.Seven,
      },
      {
        name: '8',
        value: LegalBallAttributesId.Eight,
      },
      {
        name: '9',
        value: LegalBallAttributesId.Nine,
      },
    ]

    return {
      options,
      incidentId,
      coreAttributeId,
    }
  },
})
</script>

<template>
  <div class="h-full w-full">
    <SelectNumberOfPopup
      v-bind="{ options, context, coreAttributeId, incidentId }"
      :title="'Select number of Byes'"
    />
  </div>
</template>
