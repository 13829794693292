<script lang="ts">
import { computed, defineComponent } from 'vue'

import { ClockStatus } from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import { useTimeChange } from '@mobile/views/Relation/Shared/Popups/EventTime/useTimeChange'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: { Button },
  setup() {
    const { probableEvent } = useEventInjections()
    const { startTime, pauseTime } = useTimeChange()

    const buttonConfig = computed(() => {
      const isClockRunning =
        probableEvent.value.clock_status === ClockStatus.Running

      return {
        onClick: isClockRunning ? pauseTime : startTime,
        text: isClockRunning ? 'Pause time' : 'Start time',
      }
    })

    return { buttonConfig }
  },
})
</script>

<template>
  <Button
    :color="'white'"
    :size="'lg'"
    @click="buttonConfig.onClick()"
  >
    {{ buttonConfig.text }}
  </Button>
</template>
