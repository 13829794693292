import { chain } from 'lodash'

import {
  Event,
  Participant,
  relationStatuses,
  SoccerEventDetailId,
  SportDetailInputType,
} from '@collector/sportsapi-client-legacy'
import { SelectOption } from '@mobile/components/Select/types/SelectOption'
import { CollectorSport } from '@mobile/reusables/classes/CollectorSport'
import { byId } from '@mobile/reusables/entityUtils'
import { useEventReferee } from '@mobile/views/Relation/useEventReferee'

import {
  EventPreparationEventDetail,
  EventPreparationEventDetailsSportFactory,
} from './types'
import {
  getDetailName,
  getDetailSelectItems,
  getDetailSelectItemsWithPlaceholder,
} from './utils'

export function eventPreparationEventDetailsSoccerFactory(
  event: Event,
  sport: CollectorSport,
): EventPreparationEventDetailsSportFactory {
  let referees: Participant[] = []

  const { getCurrentReferee, searchReferees, setCurrentSelectedReferee } =
    useEventReferee(event)

  function mapRefereeToRefereeSelectOption({
    short_name,
    area_name,
    details,
    id: value,
  }: Participant): SelectOption {
    const areaNameAndBirthdate = chain([area_name, details.birthdate])
      .compact()
      .join(', ')
    const name = `${short_name} (${areaNameAndBirthdate})`

    return {
      name,
      value,
    }
  }

  async function getRefereesOptions(query: string): Promise<SelectOption[]> {
    try {
      if (!query && event.referee_id) {
        const currentReferee = await getCurrentReferee()
        referees = currentReferee ? [currentReferee] : []
      } else if (query) {
        referees = await searchReferees(query)
      }

      return referees.map(mapRefereeToRefereeSelectOption)
    } catch (_error) {
      return []
    }
  }

  function getDetails(): EventPreparationEventDetail[] {
    return [
      {
        name: 'Relation status',
        fieldName: 'relation_status',
        type: SportDetailInputType.Select,
        items: relationStatuses,
      },
      {
        name: 'Referee',
        fieldName: 'referee_id',
        type: SportDetailInputType.Select,
        items: getRefereesOptions,
        selectAttributes: {
          minChars: 3,
          delay: 500,
          searchable: true,
          trackBy: 'name',
        },
        onChange: (refereeId: number) => {
          setCurrentSelectedReferee(byId(referees, refereeId) || null)
        },
      },
      {
        id: SoccerEventDetailId.WeatherConditions,
        name: getDetailName(sport, SoccerEventDetailId.WeatherConditions),
        type: SportDetailInputType.Select,
        items: getDetailSelectItemsWithPlaceholder(
          getDetailSelectItems(
            event,
            sport,
            SoccerEventDetailId.WeatherConditions,
          ),
        ),
      },
      {
        id: SoccerEventDetailId.PitchConditions,
        name: getDetailName(sport, SoccerEventDetailId.PitchConditions),
        type: SportDetailInputType.Select,
        items: getDetailSelectItemsWithPlaceholder(
          getDetailSelectItems(
            event,
            sport,
            SoccerEventDetailId.PitchConditions,
          ),
        ),
      },
      {
        id: SoccerEventDetailId.ExtraPeriodLength,
        name: getDetailName(sport, SoccerEventDetailId.ExtraPeriodLength),
        type: SportDetailInputType.Number,
      },
      {
        id: SoccerEventDetailId.ExtraPeriodType,
        name: getDetailName(sport, SoccerEventDetailId.ExtraPeriodType),
        type: SportDetailInputType.Select,
        items: getDetailSelectItems(
          event,
          sport,
          SoccerEventDetailId.ExtraPeriodType,
        ),
      },
      {
        name: 'Event status',
        fieldName: 'status_id',
        type: SportDetailInputType.Select,
        items: getEventStatusesSelectItems(),
      },
    ]
  }

  function getEventStatusesSelectItems(): SelectOption[] {
    return sport.sportsAPIEntity.statuses.map((status) => ({
      name: status.name,
      value: status.id,
    }))
  }

  return { getDetails }
}
