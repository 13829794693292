<script lang="ts">
import { computed, ComputedRef, defineComponent, Ref, ref } from 'vue'

import { EventStatusId, IncidentId } from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import { getParticipantButtonColor } from '@mobile/components/Button/ButtonUtils'
import ParticipantLogo from '@mobile/components/ParticipantLogo/ParticipantLogo.vue'
import ToggleButtonGroup from '@mobile/components/ToggleButton/ToggleButtonGroup.vue'
import { useSportOptions } from '@mobile/views/Relation/DetailsPanel/Sports/composables/useSportOptions'
import { ListOption } from '@mobile/views/Relation/DetailsPanel/Sports/composables/useSportOptions/types'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

import Modal from './Modal.vue'
import ModalButtons from './ModalButtons.vue'
import ModalTitle from './ModalTitle.vue'

export default defineComponent({
  components: {
    Modal,
    ModalTitle,
    ModalButtons,
    Button,
    ToggleButtonGroup,
    ParticipantLogo,
  },
  emits: ['close'],
  setup(props, { emit }) {
    const {
      sport,
      probableLinkedSecondParticipant,
      probableLinkedFirstParticipant,
      probableFirstParticipant,
      probableSecondParticipant,
    } = useEventInjections()
    const { incidentHandlersConfiguration } = useSportConfiguration()
    const selectedIncident: Ref<IncidentId | null | string> = ref(null)
    const selectedStatus: Ref<EventStatusId | null> = ref(null)
    const selectedMinute: Ref<string | null> = ref(null)
    const selectedSecond: Ref<string | null> = ref(null)
    const selectedParticipant = ref()

    const {
      eventStatuses,
      manualAddIncidents,
      manualAddSubstitutionIncidents,
    } = useSportOptions(sport)

    const manualAddIncidentsList: ComputedRef<ListOption[]> = computed(() => {
      if (manualAddSubstitutionIncidents.value.length) {
        return [
          ...manualAddIncidents.value,
          {
            name: 'Substitution',
            value: 'Substitution',
          },
        ]
      }

      return manualAddIncidents.value
    })

    const firstParticipantColor = computed(() =>
      getParticipantButtonColor(
        probableLinkedFirstParticipant.value.getParticipant().value,
      ),
    )
    const secondParticipantColor = computed(() =>
      getParticipantButtonColor(
        probableLinkedSecondParticipant.value.getParticipant().value,
      ),
    )

    function isParticipantSelected(
      participant: ProbableLinkedParticipant,
    ): boolean {
      if (!selectedParticipant.value) {
        return false
      }

      return participant.side === selectedParticipant.value.side
    }

    function selectParticipant(participant: ProbableLinkedParticipant): void {
      selectedParticipant.value = participant
    }

    function isNumber(evt: KeyboardEvent): void {
      const keysAllowed: string[] = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
      ]
      const keyPressed: string = evt.key

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    }

    function handleForm(): void {
      if (selectedIncident.value === 'Substitution') {
        manualAddSubstitutionIncidents.value.forEach((incident) => {
          addIncidentToQueue(Number(incident.value))
        })
      } else {
        addIncidentToQueue(Number(selectedIncident.value))
      }

      emit('close')
    }

    function addIncidentToQueue(incidentId: IncidentId): void {
      const { handler } =
        incidentHandlersConfiguration[incidentId] ??
        incidentHandlersConfiguration.fallbackHandler
      const minute = Number(selectedMinute.value)
      const second = Number(selectedSecond.value)
      const status = selectedStatus.value ?? undefined
      const participantId = selectedParticipant.value.getParticipant().value.id
      const time = {
        minute,
        second,
      }

      handler({
        incidentId,
        status,
        participantId,
        time,
      })
    }

    return {
      manualAddIncidents,
      eventStatuses,
      selectedIncident,
      probableLinkedFirstParticipant,
      probableLinkedSecondParticipant,
      probableFirstParticipant,
      probableSecondParticipant,
      firstParticipantColor,
      secondParticipantColor,
      selectParticipant,
      selectedStatus,
      selectedMinute,
      selectedSecond,
      handleForm,
      isParticipantSelected,
      selectedParticipant,
      isNumber,
      manualAddIncidentsList,
    }
  },
})
</script>

<template>
  <Modal @close="$emit('close')">
    <ModalTitle title="Manual add incident" />
    <div class="flex">
      <Button
        class="mr-3 flex w-full justify-center border"
        :class="
          isParticipantSelected(probableLinkedFirstParticipant)
            ? ''
            : `text-${firstParticipantColor}`
        "
        :size="'sm'"
        :color="
          isParticipantSelected(probableLinkedFirstParticipant)
            ? firstParticipantColor
            : 'custom'
        "
        data-test-id="ManualAddIncidentHomeParticipantButton"
        @click="selectParticipant(probableLinkedFirstParticipant)"
      >
        <div class="flex h-full items-center space-x-1">
          <ParticipantLogo
            class="h-full w-auto"
            :participant="probableFirstParticipant"
          />
          <div>{{ probableFirstParticipant.short_name }}</div>
        </div>
      </Button>
      <Button
        class="flex w-full justify-center border"
        :class="
          isParticipantSelected(probableLinkedSecondParticipant)
            ? ''
            : `text-${secondParticipantColor}`
        "
        :color="
          isParticipantSelected(probableLinkedSecondParticipant)
            ? secondParticipantColor
            : 'custom'
        "
        :size="'sm'"
        data-test-id="ManualAddIncidentAwayParticipantButton"
        @click="selectParticipant(probableLinkedSecondParticipant)"
      >
        <div class="flex h-full items-center space-x-1">
          <ParticipantLogo
            class="h-full w-auto"
            :participant="probableSecondParticipant"
          />
          <div>{{ probableSecondParticipant.short_name }}</div>
        </div>
      </Button>
    </div>
    <div class="mt-5">
      <span class="font-semibold">Incident:</span>
      <ToggleButtonGroup
        v-model:value="selectedIncident"
        class="mt-1 grid flex-none grid-cols-3 flex-nowrap"
        :options="manualAddIncidentsList"
        :type="'horizontal'"
        :undeselectable="false"
        :bordered="true"
      />
    </div>
    <div class="mt-5">
      <span class="font-semibold">Event status:</span>
      <ToggleButtonGroup
        v-model:value="selectedStatus"
        class="mt-1 grid flex-none grid-cols-3 flex-nowrap"
        :options="eventStatuses"
        :type="'horizontal'"
        :undeselectable="false"
        :bordered="true"
      />
    </div>
    <div class="mt-5">
      <span class="font-semibold">Event time:</span>
      <div class="mt-1 flex items-center">
        <input
          v-model="selectedMinute"
          class="mr-2 w-10 rounded border p-2 text-center"
          type="string"
          placeholder="00"
          data-test-id="ManualAddIncidentTimeMinute"
          @keypress="isNumber($event)"
        />
        :
        <input
          v-model="selectedSecond"
          class="ml-2 w-10 rounded border p-2 text-center"
          type="string"
          placeholder="00"
          data-test-id="ManualAddIncidentTimeSecond"
          @keypress="isNumber($event)"
        />
      </div>
    </div>

    <ModalButtons>
      <Button
        class="mr-2"
        color="red"
        :size="'lg'"
        @click="$emit('close')"
      >
        Cancel
      </Button>
      <Button
        color="green"
        :size="'lg'"
        :disabled="!selectedParticipant || !selectedIncident || !selectedStatus"
        @click="handleForm"
      >
        Add incident
      </Button>
    </ModalButtons>
  </Modal>
</template>
