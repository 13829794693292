<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'

import { EventsLineupsParticipant } from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'

import { useSportConfiguration } from '../RelationSportCommonDependencies/useSportConfiguration'

export default defineComponent({
  components: { Button },
  props: {
    players: {
      type: Array as PropType<EventsLineupsParticipant[]>,
      default: () => [],
    },
    selectedPlayerId: {
      type: Number,
      required: false,
    },
  },
  emits: ['close', 'save'],
  setup(props, { emit }) {
    const player = props.players.find(
      ({ participant_id }) => participant_id === props.selectedPlayerId,
    )
    const { lineupsConfiguration } = useSportConfiguration()

    const selectedShirtNumber = ref<string>('')
    const selectedPlayer = ref<EventsLineupsParticipant | null>(player ?? null)

    const filteredPlayers = computed(() => {
      if (!selectedShirtNumber.value) {
        return props.players
      }

      return props.players.filter(
        (player: EventsLineupsParticipant) =>
          player.participant_shirt_number === Number(selectedShirtNumber.value),
      )
    })

    function findBadgeNameByPosition(
      playerPosition: string | null,
    ): string | undefined {
      return lineupsConfiguration.availablePositions.find(
        (position) => position.name.toLocaleLowerCase() === playerPosition,
      )?.acronym
    }

    function isPlayerSelected(playerName: string): boolean {
      return playerName === selectedPlayer.value?.participant_name
    }

    function selectPlayer(player: EventsLineupsParticipant): void {
      selectedPlayer.value = player === selectedPlayer.value ? null : player
    }

    function add(selectedNumber: number): void {
      const maximumShirtNumberLength = 3
      if (selectedShirtNumber.value.length < maximumShirtNumberLength) {
        selectedShirtNumber.value += selectedNumber
      }
    }

    function deleteLastCharacter(): void {
      const stringWithoutLastCharacter = selectedShirtNumber.value?.slice(0, -1)
      selectedShirtNumber.value = stringWithoutLastCharacter ?? ''
    }

    function save(): void {
      emit('save', selectedPlayer.value)
      emit('close')
      selectedShirtNumber.value = ''
    }

    function close(): void {
      emit('close')
      selectedShirtNumber.value = ''
      selectedPlayer.value = player ?? null
    }

    return {
      selectedShirtNumber,
      filteredPlayers,
      selectedPlayer,
      add,
      deleteLastCharacter,
      selectPlayer,
      isPlayerSelected,
      findBadgeNameByPosition,
      save,
      close,
    }
  },
})
</script>

<template>
  <div
    class="bg-silver absolute inset-0 z-30 flex w-full flex-row items-center justify-between gap-3 px-4 py-3"
  >
    <div class="flex w-2/4 flex-col self-start">
      <div class="relative">
        <input
          v-model="selectedShirtNumber"
          class="z-0 h-14 w-full border-none bg-white pl-10 pr-7 pt-2 text-right text-3xl focus:shadow focus:outline-none"
          type="text"
          :disabled="true"
        />
        <div class="absolute right-1 top-2">
          <button
            class="h-10 w-7 text-xl"
            @click="selectedShirtNumber = ''"
          >
            &times;
          </button>
        </div>
      </div>
      <div
        class="grid-flow-column grid grid-cols-3 grid-rows-3 justify-items-center gap-x-4 gap-y-4 bg-white px-6 py-4"
      >
        <Button
          v-for="(number, index) in 9"
          :key="index"
          class="w-full"
          color="blue"
          :disabled="false"
          :invertedColor="false"
          :selected="false"
          size="sm"
          @click="add(number)"
        >
          {{ number }}
        </Button>
        <Button
          class="col-span-2 w-full"
          color="blue"
          :disabled="!selectedShirtNumber"
          :invertedColor="false"
          :selected="false"
          size="sm"
          @click="add(0)"
        >
          0
        </Button>
        <Button
          class="w-full"
          color="red"
          :disabled="!selectedShirtNumber"
          :invertedColor="false"
          :selected="false"
          size="sm"
          @click="deleteLastCharacter"
        >
          Del
        </Button>
      </div>
    </div>
    <div class="flex h-full w-2/4 flex-col self-start">
      <ul
        v-if="filteredPlayers.length"
        class="h-3/4 overflow-y-auto bg-white text-gray-900"
      >
        <li
          v-for="(player, index) in filteredPlayers"
          :key="player.participant_id || index"
          class="border-b-1 border-silver w-full border-solid px-4 py-2 text-lg"
          :class="{
            'from-tropicalblue bg-gradient-to-r to-white': isPlayerSelected(
              player.participant_name,
            ),
          }"
          @click="selectPlayer(player)"
        >
          <span
            v-if="player.participant_position"
            class="bg-blue mr-2 rounded px-1 py-0.5 text-xs font-semibold text-white"
          >
            {{ findBadgeNameByPosition(player.participant_position) }}
          </span>

          {{
            player.participant_shirt_number !== null &&
            player.participant_shirt_number !== undefined
              ? `${player.participant_shirt_number} - ${player.participant_name}`
              : player.participant_name
          }}
        </li>
      </ul>
      <ul
        v-else
        class="h-3/4 overflow-y-auto bg-white text-gray-900"
      >
        <li
          class="border-b-1 border-silver w-full border-solid px-4 py-2 text-lg"
        >
          There is no player with following number
        </li>
      </ul>
      <div class="flex h-1/4 w-full items-center justify-end">
        <Button
          class="mr-2"
          color="red"
          :invertedColor="false"
          :selected="false"
          size="sm"
          @click="close"
        >
          Close
        </Button>
        <Button
          color="green"
          :disabled="!selectedPlayer"
          :invertedColor="false"
          :selected="false"
          size="sm"
          @click="save"
        >
          Save
        </Button>
      </div>
    </div>
  </div>
</template>
