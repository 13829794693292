import { interfaces } from 'inversify'

import { AssignableIncidentsConfiguration } from './AssignableIncidentsConfiguration'
import { EventClockConfiguration } from './EventClockConfiguration'
import { EventHeaderConfiguration } from './EventHeaderConfiguration'
import { EventNotificationHooks } from './EventNotificationHooks'
import { EventStatusesConfiguration } from './EventStatusesConfiguration'
import { EventStatusesList } from './EventStatusesList'
import { IncidentHandlersConfiguration } from './IncidentHandlersConfiguration'
import { IncidentsConfiguration } from './IncidentsConfiguration'
import { LineupsConfiguration } from './LineupsConfiguration'
import { SeasonParticipants } from './SeasonPartcipants'
import { Skins } from './Skins'

type SI<T> = interfaces.ServiceIdentifier<T>

interface RelationSportCommonIoCBindingsInterface {
  EventStatusesConfiguration: SI<EventStatusesConfiguration>
  EventStatusesList: SI<EventStatusesList>
  IncidentHandlersConfiguration: SI<IncidentHandlersConfiguration>
  IncidentsConfiguration: SI<IncidentsConfiguration>
  SeasonParticipants: SI<SeasonParticipants>
  LineupsConfiguration: SI<LineupsConfiguration>
  AssignableIncidentsConfiguration: SI<AssignableIncidentsConfiguration>
  EventNotificationHooks: SI<EventNotificationHooks>
  EventHeaderConfiguration: SI<EventHeaderConfiguration>
  EventClockConfiguration: SI<EventClockConfiguration>
  Skins: SI<Skins>
}

export const RelationSportCommonIoCBindings: RelationSportCommonIoCBindingsInterface =
  {
    EventStatusesConfiguration: Symbol('EventStatusesConfiguration'),
    EventStatusesList: Symbol('EventStatusesList'),
    IncidentHandlersConfiguration: Symbol('IncidentsHandlersConfiguration'),
    IncidentsConfiguration: Symbol('IncidentsConfiguration'),
    LineupsConfiguration: Symbol('LineupsConfiguration'),
    AssignableIncidentsConfiguration: Symbol(
      'AssignableIncidentsConfiguration',
    ),
    EventNotificationHooks: Symbol('EventNotificationHooks'),
    EventHeaderConfiguration: Symbol('EventHeaderConfiguration'),
    EventClockConfiguration: Symbol('EventClockConfiguration'),
    SeasonParticipants: Symbol('SeasonParticipants'),
    Skins: Symbol('Skins'),
  }
