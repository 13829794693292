<script lang="ts">
import { computed, defineComponent, PropType, Ref, ref } from 'vue'

import { opChain } from '@collector/shared-utils'
import {
  ChallengeAttribute,
  Incident,
  VolleyballIncidentId,
} from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'
import PopupControls from '@mobile/views/Relation/Shared/Popup/PopupControls.vue'
import TeamSelect from '@mobile/views/Relation/Shared/Popup/TeamSelect.vue'
import {
  IncidentActionComposable,
  useIncidentAction,
} from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: {
    TeamSelect,
    Button,
    PopupControls,
  },
  props: {
    context: {
      type: Object as PropType<PopupContext | null>,
      required: false,
    },
  },
  emits: ['close'],
  setup(props) {
    const eventInjections = useEventInjections()
    const { sport } = useEventInjections()
    const selectedParticipant: Ref<ProbableLinkedParticipant | null> = ref(null)
    const selectedIncident: Ref<Incident['incident_id'] | null> = ref(null)
    const processable = computed(() => {
      return !!selectedIncident.value
    })
    const composable: Ref<IncidentActionComposable | null> = ref(null)
    const timeoutOverIncident: Ref<Incident['incident_id'] | null> = ref(null)

    function selectParticipant(participant: ProbableLinkedParticipant): void {
      selectedParticipant.value = participant
      selectedIncident.value = VolleyballIncidentId.Timeout
    }

    function selectIncident(incidentId: Incident['incident_id']): void {
      selectedIncident.value = incidentId
    }

    function proceed(): void {
      if (!selectedIncident.value) {
        return
      }

      composable.value = useIncidentAction(eventInjections, {
        incident_id: selectedIncident.value,
        participant_team_id:
          selectedParticipant.value?.getParticipant().value.id ?? undefined,
      })

      if (timeoutOverIncident.value) {
        return props.context?.closeAllPopups()
      }

      timeoutOverIncident.value =
        selectedIncident.value === VolleyballIncidentId.Timeout
          ? VolleyballIncidentId.TimeoutOver
          : VolleyballIncidentId.TechnicalTimeoutOver

      selectedIncident.value = null
    }

    return {
      sport,
      composable,
      ChallengeAttribute,
      VolleyballIncidentId,
      selectedParticipant,
      timeoutOverIncident,
      selectParticipant,
      selectedIncident,
      selectIncident,
      processable,
      proceed,
      opChain,
    }
  },
})
</script>

<template>
  <div>
    <TeamSelect
      v-if="!composable"
      :withButtons="false"
      :defaultParticipant="selectedParticipant"
      @participantSelected="selectParticipant"
    >
      <div>
        <div class="text-cloudygray py-2 text-center text-sm font-bold">OR</div>
        <Button
          class="w-full"
          :size="'sm'"
          :color="
            selectedIncident === VolleyballIncidentId.TechnicalTimeout
              ? 'blue'
              : 'white'
          "
          @click="selectIncident(VolleyballIncidentId.TechnicalTimeout)"
        >
          {{
            sport.getIncidentTranslation(VolleyballIncidentId.TechnicalTimeout)
          }}
        </Button>
      </div>
    </TeamSelect>

    <div v-else>
      <div class="grid place-items-center px-4 py-6">
        <div class="text-cloudygray py-2 text-center text-sm font-bold">
          Timeout
        </div>
        <div class="flex space-x-2">
          <Button
            v-if="timeoutOverIncident"
            :class="{ 'opacity-50': selectedIncident !== timeoutOverIncident }"
            :color="selectedIncident !== timeoutOverIncident ? 'white' : 'blue'"
            :size="'md'"
            @click="selectIncident(timeoutOverIncident as VolleyballIncidentId)"
          >
            {{ sport.getIncidentTranslation(timeoutOverIncident) }}
          </Button>
        </div>
      </div>
    </div>

    <PopupControls>
      <template #buttons>
        <div
          class="grid gap-2"
          style="grid-template-columns: repeat(auto-fit, minmax(90px, 1fr))"
        >
          <div
            v-for="i in 3"
            :key="`item-${i}`"
          ></div>
          <Button
            :size="'md'"
            :color="'red'"
            @click="opChain(context, (v) => v.closeAllPopups())"
          >
            Cancel
          </Button>
          <Button
            :size="'md'"
            :color="'green'"
            :disabled="!processable"
            @click="proceed()"
          >
            Send
          </Button>
        </div>
      </template>
    </PopupControls>
  </div>
</template>
