<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import { byId } from '@mobile/reusables/entityUtils'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import {
  ParticipantData,
  ParticipantDataIdsSet,
} from '@mobile/views/Relation/Shared/VerifyResults/ParticipantsDataTable/Types'

type Row = {
  id: unknown
  name: string
  home: ParticipantData
  away: ParticipantData
}

export default defineComponent({
  props: {
    ids: {
      type: Object as PropType<ParticipantDataIdsSet>,
      required: true,
    },
  },
  setup(props) {
    const { sport, homeParticipant, awayParticipant } = useEventInjections()

    const tableData = computed(() =>
      Array.from(props.ids).flatMap((id) => {
        const data: Row[] = []
        const home = byId(
          props.ids.getRelatedParticipantData(homeParticipant.value),
          id,
        )
        const away = byId(
          props.ids.getRelatedParticipantData(awayParticipant.value),
          id,
        )
        const name = byId(props.ids.getRelatedSportData(sport.value), id)?.name

        if (home && away && name) {
          data.push({
            id,
            name,
            home,
            away,
          })
        }

        return data
      }),
    )

    return {
      tableData,
      homeParticipant,
      awayParticipant,
    }
  },
})
</script>

<template>
  <div class="flex flex-1 flex-col">
    <div class="text-gray mb-2 text-base font-bold">
      {{ ids.getDataName() }}
    </div>
    <div class="bg-darkblue flex w-full flex-row justify-between">
      <div class="text-silver text-xxs px-2 py-2 font-bold">
        {{ homeParticipant.short_name }}
      </div>
      <div class="text-silver text-xxs px-2 py-2 font-bold">
        {{ awayParticipant.short_name }}
      </div>
    </div>
    <div class="w-full">
      <div
        v-for="row in tableData"
        :key="row.id as number"
        class="even:bg-lightsilver flex flex-row items-center justify-between px-2 py-1 odd:bg-white"
      >
        <div class="text-home text-center text-xs">
          {{ row.home.value }}
        </div>
        <div class="text-center text-xs">
          {{ row.name }}
        </div>
        <div class="text-away text-center text-xs">
          {{ row.away.value }}
        </div>
      </div>
    </div>
  </div>
</template>
