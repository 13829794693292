export enum SoccerIncidentAttributeNames {
  OwnGoal = 'Own goal',
  PenaltyGoal = 'Penalty goal',
  LeftFoot = 'Left foot',
  RightFoot = 'Right foot',
  FreeKick = 'Free kick',
  InsidePenaltyArea = 'Inside penalty area',
  OutsidePenaltyArea = 'Outside penalty area',
  Head = 'Head',
  AfterCorner = 'After corner',
  AfterFastAttack = 'After fast attack',
  AfterPositionalAttack = 'After positional attack',
  UpperLeft = 'Upper left',
  UpperRight = 'Upper right',
  UpperMiddle = 'Upper middle',
  BottomMiddle = 'Bottom middle',
  BottomLeft = 'Bottom left',
  BottomRight = 'Bottom right',
  // TODO Remove this after team API fix name for Bottom right
  BottomRightWithDash = 'Bottom-right',
  ShotWoodwork = 'Shot woodwork',
  SecondYellowCard = '2nd yellow card',
  OffField = 'Off-field',
  DangerousFreeKick = 'Dangerous free kick',
  PossibleGoal = 'Possible goal',
  PossiblePenalty = 'Possible penalty',
  PossibleRedCard = 'Possible red card',
  GoallineClearance = 'Goalline clearance',
  Post = 'Post',
  Crossbar = 'Crossbar',
  OtherBodyPart = 'Other body part',
  Volley = 'Volley',
  BicycleKick = 'Bicycle kick',
  Chip = 'Chip',
  OneOnOne = 'One on one',
  CausesPenalty = 'Causes penalty',
  PowerShot = 'Power shot',
  KeyPass = 'Key pass',
}
