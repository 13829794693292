<script lang="ts">
import { computed, defineComponent, PropType, Ref, ref } from 'vue'

import { opChain } from '@collector/shared-utils'
import {
  ChallengeAttribute,
  Incident,
  VolleyballIncidentId,
} from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'
import PopupControls from '@mobile/views/Relation/Shared/Popup/PopupControls.vue'
import TeamSelect from '@mobile/views/Relation/Shared/Popup/TeamSelect.vue'
import {
  IncidentActionComposable,
  useIncidentAction,
} from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import History from '@mobile/views/Relation/Shared/Popups/History/History.vue'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

import { RefereeChallengePopup } from '../RefereeChallenge/RefereeChallengePopup'

export default defineComponent({
  components: {
    TeamSelect,
    Button,
    PopupControls,
  },
  props: {
    context: {
      type: Object as PropType<PopupContext | null>,
      required: false,
    },
  },
  emits: ['close'],
  setup(props) {
    const eventInjections = useEventInjections()
    const { sport } = useEventInjections()
    const selectedParticipant: Ref<ProbableLinkedParticipant | null> = ref(null)
    const selectedIncident: Ref<Incident['incident_id'] | null> = ref(null)
    const processable = computed(() => {
      if (composable.value) {
        return !!selectedAttribute.value
      }

      return !!selectedIncident.value || !!selectedParticipant.value
    })
    const composable: Ref<IncidentActionComposable | null> = ref(null)
    const selectedAttribute: Ref<ChallengeAttribute | null> = ref(null)
    const { incidentHandlersConfiguration } = useSportConfiguration()

    function selectParticipant(participant: ProbableLinkedParticipant): void {
      selectedParticipant.value = participant
      selectedIncident.value = null
    }

    function selectIncident(incidentId: Incident['incident_id']): void {
      selectedParticipant.value = null
      selectedIncident.value = incidentId
    }

    function proceed(): void {
      if (selectedParticipant.value) {
        if (composable.value && selectedAttribute.value) {
          return updateChallengeAttributes()
        }

        composable.value = useIncidentAction(eventInjections, {
          incident_id: VolleyballIncidentId.Challenge,
          participant_team_id:
            selectedParticipant.value?.getParticipant().value.id,
        })

        return
      } else if (selectedIncident.value) {
        proceedRefereeChallengeIncident()
      }
    }

    function proceedRefereeChallengeIncident(): void {
      if (selectedIncident.value) {
        const { disabled } =
          incidentHandlersConfiguration[selectedIncident.value] ??
          incidentHandlersConfiguration.fallbackHandler

        if (!disabled({ incidentId: selectedIncident.value })) {
          props.context?.openPopup(RefereeChallengePopup)
        }
      }
    }

    function updateChallengeAttributes(): void {
      if (composable.value && selectedAttribute.value) {
        composable.value.updateIncident({
          attribute_ids: [selectedAttribute.value],
        })

        if (selectedAttribute.value === ChallengeAttribute.Correct) {
          props.context?.openPopup(History)
        } else {
          props.context?.closeAllPopups()
        }
      }
    }

    return {
      sport,
      composable,
      selectedAttribute,
      ChallengeAttribute,
      VolleyballIncidentId,
      selectedParticipant,
      selectParticipant,
      selectedIncident,
      selectIncident,
      processable,
      proceed,
      opChain,
    }
  },
})
</script>

<template>
  <div>
    <TeamSelect
      v-if="!composable"
      :withButtons="false"
      :defaultParticipant="selectedParticipant"
      @participantSelected="selectParticipant"
    >
      <div>
        <div class="text-cloudygray py-2 text-center text-sm font-bold">OR</div>
        <Button
          class="w-full"
          :size="'sm'"
          :color="selectedIncident ? 'blue' : 'white'"
          @click="selectIncident(VolleyballIncidentId.RefereeChallenge)"
        >
          {{
            sport.getIncidentTranslation(VolleyballIncidentId.RefereeChallenge)
          }}
        </Button>
      </div>
    </TeamSelect>

    <div v-else>
      <div class="grid place-items-center px-4 py-6">
        <div class="text-cloudygray py-2 text-center text-sm font-bold">
          Challenge
        </div>
        <div class="flex space-x-2">
          <Button
            class="w-20"
            :class="{
              'opacity-50': selectedAttribute !== ChallengeAttribute.Correct,
            }"
            :color="'green'"
            :size="'md'"
            @click="selectedAttribute = ChallengeAttribute.Correct"
          >
            <FontAwesomeIcon
              :size="'lg'"
              :icon="'check'"
            />
          </Button>

          <Button
            class="w-20"
            :class="{
              'opacity-50': selectedAttribute !== ChallengeAttribute.Incorrect,
            }"
            :color="'red'"
            :size="'md'"
            @click="selectedAttribute = ChallengeAttribute.Incorrect"
          >
            <FontAwesomeIcon
              :size="'lg'"
              :icon="'times'"
            />
          </Button>
        </div>
      </div>
    </div>

    <PopupControls>
      <template #buttons>
        <div
          class="grid gap-2"
          style="grid-template-columns: repeat(auto-fit, minmax(90px, 1fr))"
        >
          <div
            v-for="i in 3"
            :key="`item-${i}`"
          ></div>
          <Button
            :size="'md'"
            :color="'red'"
            @click="opChain(context, (v) => v.closeAllPopups())"
          >
            Cancel
          </Button>
          <Button
            :size="'md'"
            :color="'green'"
            :disabled="!processable"
            @click="proceed()"
          >
            Send
          </Button>
        </div>
      </template>
    </PopupControls>
  </div>
</template>
