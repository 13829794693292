import {
  AmericanFootballAttributesConfiguration,
  AmericanFootballDetailsConfiguration,
  AmericanFootballGroupNames,
} from './AmericanFootballDetailsConfiguration'
import {
  AmericanFootballDetailsIncidentIds,
  AmericanFootballHideIncidentIds,
  AmericanFootballParticipantIncidentIds,
  AmericanFootballSecondParticipantIncidentIds,
} from './AmericanFootballFiltersIncidentIds'
import {
  AmericanFootballManualAddIncidentIds,
  AmericanFootballManualAddIncidentSubstitutionIds,
} from './AmericanFootballManualAddIncidentIds'
import { AmericanFootballManualAddStatusIds } from './AmericanFootballManualAddStatusIds'

export const AmericanFootballConfiguration = {
  americanFootballGroupNames: AmericanFootballGroupNames,
  americanFootballDetailsConfiguration: AmericanFootballDetailsConfiguration,
  americanFootballAttributesConfiguration:
    AmericanFootballAttributesConfiguration,
  americanFootballHideIncidentIds: AmericanFootballHideIncidentIds,
  americanFootballParticipantIncidentIds:
    AmericanFootballParticipantIncidentIds,
  americanFootballSecondParticipantIncidentIds:
    AmericanFootballSecondParticipantIncidentIds,
  americanFootballDetailsIncidentIds: AmericanFootballDetailsIncidentIds,
  americanFootballManualAddIncidentIds: AmericanFootballManualAddIncidentIds,
  americanFootballManualAddIncidentSubstitutionIds:
    AmericanFootballManualAddIncidentSubstitutionIds,
  americanFootballManualAddStatusIds: AmericanFootballManualAddStatusIds,
}
