<script lang="ts">
import { Container } from 'inversify'
import { computed, defineComponent } from 'vue'

import { StatusType } from '@collector/sportsapi-client-legacy'
import { bindSeasonParticipants } from '@mobile/views/Relation/Shared/RelationDependencies/bindSeasonParticipants'
import { bindSkins } from '@mobile/views/Relation/Shared/RelationDependencies/bindSkins'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { AmericanFootballRelationIoCBindings } from '@mobile/views/Relation/Sports/AmericanFootball/AmericanFootballRelationDependencies/AmericanFootballRelationDependencies'
import { bindAssignableIncidentConfiguration } from '@mobile/views/Relation/Sports/AmericanFootball/AmericanFootballRelationDependencies/bindAssignableIncidentConfiguration'
import { bindEventClockConfiguration } from '@mobile/views/Relation/Sports/AmericanFootball/AmericanFootballRelationDependencies/bindEventClockConfigration'
import { bindEventHeaderConfiguration } from '@mobile/views/Relation/Sports/AmericanFootball/AmericanFootballRelationDependencies/bindEventHeaderConfiguration'
import { bindEventStatusesConfiguration } from '@mobile/views/Relation/Sports/AmericanFootball/AmericanFootballRelationDependencies/bindEventStatusesConfiguration'
import { bindEventStatusesList } from '@mobile/views/Relation/Sports/AmericanFootball/AmericanFootballRelationDependencies/bindEventStatusesList'
import { bindIncidentHandlersConfiguration } from '@mobile/views/Relation/Sports/AmericanFootball/AmericanFootballRelationDependencies/bindIncidentHandlersConfiguration'
import { bindIncidentsConfiguration } from '@mobile/views/Relation/Sports/AmericanFootball/AmericanFootballRelationDependencies/bindIncidentsConfiguration'
import { bindLineupsConfiguration } from '@mobile/views/Relation/Sports/AmericanFootball/AmericanFootballRelationDependencies/bindLineupsConfiguration'
import { bindRelationHooks } from '@mobile/views/Relation/Sports/AmericanFootball/AmericanFootballRelationDependencies/bindRelationHooks'

export default defineComponent({
  components: {},
  setup() {
    const { probableEvent } = useEventInjections()

    const displayVerifyResults = computed(() => {
      const probableRelationStatus = probableEvent.value?.status_type

      return probableRelationStatus === StatusType.Finished
    })

    return { displayVerifyResults }
  },
})

export function bindAmericanFootballRelationDependencies(
  ioc: Container,
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
): Record<any, any> {
  bindRelationHooks(ioc)
  bindEventHeaderConfiguration(ioc)
  bindEventClockConfiguration(ioc)
  bindIncidentsConfiguration(ioc)
  bindEventStatusesConfiguration(ioc)
  bindEventStatusesList(ioc)
  bindSeasonParticipants(ioc)
  bindLineupsConfiguration(ioc)
  bindIncidentHandlersConfiguration(ioc)
  bindAssignableIncidentConfiguration(ioc)

  bindSkins(ioc)

  return AmericanFootballRelationIoCBindings
}
</script>

<template>AmericanFootball</template>
