import { Container } from 'inversify'
import { computed } from 'vue'

import {
  AmericanFootballEventDetailId,
  AmericanFootballEventStatusId,
  AmericanFootballIncidentId,
} from '@collector/sportsapi-client-legacy'
import {
  getDetailValue,
  getNumericDetailValue,
} from '@mobile/reusables/eventUtils'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { EventStatusConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/EventStatusesConfiguration'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

const defaultStatusConfiguration = {
  time: null,
  allowedIncidents: [],
  clockHidden: true,
}

function defaultStatusConfigurationWithClock(
  clockDirection: string | number | null,
  periodLength: number,
): EventStatusConfiguration {
  return {
    ...defaultStatusConfiguration,
    clockDirectionDown: clockDirection === 'down',
    startTimeFromInSeconds: clockDirection === 'down' ? periodLength * 60 : 0,
    clockHidden: false,
  }
}

export function bindEventStatusesConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.EventStatusesConfiguration)
    .toDynamicValue(async (ctx) => {
      const event = await ctx.container.getAsync(
        RelationIoCBindings.ProbableEvent,
      )
      const clockDirection = computed(() =>
        getDetailValue(
          event.value,
          AmericanFootballEventDetailId.TimerDirection,
        ),
      )
      const periodLength = getNumericDetailValue(
        event.value,
        AmericanFootballEventDetailId.PeriodLength,
        15,
      )
      const extraPeriodLength = getNumericDetailValue(
        event.value,
        AmericanFootballEventDetailId.ExtraPeriodLength,
        15,
      )

      return {
        [AmericanFootballEventStatusId.NotStarted]: {
          time: null,
          allowedIncidents: [
            AmericanFootballIncidentId.FirstHalfStarted,
            AmericanFootballIncidentId.FirstQuarterStarted,
            AmericanFootballIncidentId.StartDelayed,
            AmericanFootballIncidentId.FinishedAwardedWin,
            AmericanFootballIncidentId.Postponed,
            AmericanFootballIncidentId.Abandoned,
            AmericanFootballIncidentId.Cancelled,
            AmericanFootballIncidentId.MatchAboutToStart,
          ],
        },
        [AmericanFootballEventStatusId.FirstQuarter]:
          defaultStatusConfigurationWithClock(
            clockDirection.value,
            periodLength,
          ),
        [AmericanFootballEventStatusId.SecondQuarter]:
          defaultStatusConfigurationWithClock(
            clockDirection.value,
            periodLength,
          ),
        [AmericanFootballEventStatusId.ThirdQuarter]:
          defaultStatusConfigurationWithClock(
            clockDirection.value,
            periodLength,
          ),
        [AmericanFootballEventStatusId.FourthQuarter]:
          defaultStatusConfigurationWithClock(
            clockDirection.value,
            periodLength,
          ),
        [AmericanFootballEventStatusId.FirstHalf]:
          defaultStatusConfigurationWithClock(
            clockDirection.value,
            periodLength,
          ),
        [AmericanFootballEventStatusId.SecondHalf]:
          defaultStatusConfigurationWithClock(
            clockDirection.value,
            periodLength,
          ),
        [AmericanFootballEventStatusId.Halftime]: defaultStatusConfiguration,
        [AmericanFootballEventStatusId.BreakAfterFirstQuarter]:
          defaultStatusConfiguration,
        [AmericanFootballEventStatusId.BreakAfterSecondQuarter]:
          defaultStatusConfiguration,
        [AmericanFootballEventStatusId.BreakAfterThirdQuarter]:
          defaultStatusConfiguration,
        [AmericanFootballEventStatusId.BreakAfterFourthQuarter]:
          defaultStatusConfiguration,
        [AmericanFootballEventStatusId.FirstExtraTime]:
          defaultStatusConfigurationWithClock(
            clockDirection.value,
            extraPeriodLength,
          ),
        [AmericanFootballEventStatusId.SecondExtraTime]:
          defaultStatusConfigurationWithClock(
            clockDirection.value,
            extraPeriodLength,
          ),
        [AmericanFootballEventStatusId.ThirdExtraTime]:
          defaultStatusConfigurationWithClock(
            clockDirection.value,
            extraPeriodLength,
          ),
        [AmericanFootballEventStatusId.FourthExtraTime]:
          defaultStatusConfigurationWithClock(
            clockDirection.value,
            extraPeriodLength,
          ),
        [AmericanFootballEventStatusId.FifthExtraTime]:
          defaultStatusConfigurationWithClock(
            clockDirection.value,
            extraPeriodLength,
          ),
        [AmericanFootballEventStatusId.BreakAfterFirstExtraTime]:
          defaultStatusConfiguration,
        [AmericanFootballEventStatusId.BreakAfterSecondExtraTime]:
          defaultStatusConfiguration,
        [AmericanFootballEventStatusId.BreakAfterThirdExtraTime]:
          defaultStatusConfiguration,
        [AmericanFootballEventStatusId.BreakAfterFourthExtraTime]:
          defaultStatusConfiguration,
        [AmericanFootballEventStatusId.Finished]: defaultStatusConfiguration,
        [AmericanFootballEventStatusId.FinishedAfterExtraTime]:
          defaultStatusConfiguration,
        [AmericanFootballEventStatusId.FinishedAwardedWin]:
          defaultStatusConfiguration,
        [AmericanFootballEventStatusId.ToFinish]: defaultStatusConfiguration,
      }
    })
    .inSingletonScope()
}
