import { computed, ComputedRef, Ref } from 'vue'

import {
  AmericanFootballIncidentId,
  Property,
  SackAttribute,
  SportId,
  SportIncident,
  SportStatus,
} from '@collector/sportsapi-client-legacy'
import { CollectorSport } from '@mobile/reusables/classes/CollectorSport'
import { AmericanFootballConfiguration } from '@mobile/views/Relation/DetailsPanel/Sports/AmericanFootball/AmericanFootballConfiguration'
import { BasketballConfiguration } from '@mobile/views/Relation/DetailsPanel/Sports/Basketball/BasketballConfiguration'
import {
  DetailConfiguration,
  DetailInputConfiguration,
  ListOption,
  SupportedEventStatusId,
  SupportedIncidentId,
} from '@mobile/views/Relation/DetailsPanel/Sports/composables/useSportOptions/types'
import { SoccerConfiguration } from '@mobile/views/Relation/DetailsPanel/Sports/Soccer/SoccerConfiguration'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'

import { IceHockeyConfiguration } from '../../IceHockey/IceHockeyConfiguration'

export const GroupNames = {
  ...SoccerConfiguration.soccerGroupNames,
  ...BasketballConfiguration.basketballGroupNames,
}

export type SportOptionsComposable = ReturnType<typeof useSportOptions>

/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
export function useSportOptions(sport: ComputedRef<CollectorSport>) {
  const hideIncidentsOptions: Ref<ListOption[]> = computed(() =>
    mapIncidents(
      getHideIncidents,
      sport.value.incidents,
      sport.value.sportsAPIEntity.id,
    ),
  )

  const participantIncidentsOptions: Ref<ListOption[]> = computed(() =>
    mapIncidents(
      getParticipantIncidents,
      sport.value.incidents,
      sport.value.sportsAPIEntity.id,
    ),
  )

  const detailsIncidentsOptions: Ref<ListOption[]> = computed(() =>
    mapIncidents(
      getDetailsIncidents,
      sport.value.incidents,
      sport.value.sportsAPIEntity.id,
    ),
  )

  const manualAddIncidents: Ref<ListOption[]> = computed(() =>
    mapIncidents(
      getManualAddIncidents,
      sport.value.incidents,
      sport.value.sportsAPIEntity.id,
    ),
  )

  const manualAddSubstitutionIncidents: Ref<ListOption[]> = computed(() =>
    mapIncidents(
      getManualAddSubstitutionIncidents,
      sport.value.incidents,
      sport.value.sportsAPIEntity.id,
    ),
  )

  const eventStatuses: Ref<ListOption[]> = computed(() =>
    mapIncidents(
      getStatuses,
      sport.value.statuses,
      sport.value.sportsAPIEntity.id,
    ),
  )

  const secondParticipantIncidents = getSecondParticipantIncidents(
    sport.value.sportsAPIEntity.id,
  )

  const detailsConfiguration: Record<number, DetailConfiguration[]> =
    getDetailsConfiguration(sport.value.sportsAPIEntity.id)

  const attributesConfiguration: Record<number, string[]> =
    getAttributesConfiguration(sport.value.sportsAPIEntity.id)

  const selectedOnStartDetailsIncidents = getSelectedOnStartDetailsIncidents(
    sport.value.sportsAPIEntity.id,
  )

  return {
    hideIncidentsOptions,
    participantIncidentsOptions,
    detailsIncidentsOptions,
    manualAddIncidents,
    eventStatuses,
    detailsConfiguration,
    manualAddSubstitutionIncidents,
    attributesConfiguration,
    secondParticipantIncidents,
    selectedOnStartDetailsIncidents,
  }
}

export function mapIncidents(
  fn: (sportId: SportId) => SupportedIncidentId[] | SupportedEventStatusId[],
  list: { [key: number]: SportIncident | SportStatus | undefined },
  sportId: number,
): {
  name: string
  value: string | number
}[] {
  const incidentsList = fn(sportId)

  return Object.values(list)
    .filter((incident) =>
      incidentsList.some((id) => Number(id) === incident?.id),
    )
    .map((incident) => {
      return {
        name: incident?.name ?? '',
        value: incident?.id ?? '',
      }
    })
    .sort(
      (a, b) =>
        incidentsList.indexOf(a?.value as never) -
        incidentsList.indexOf(b?.value as never),
    )
}

function getHideIncidents(sportId: SportId): SupportedIncidentId[] {
  switch (sportId) {
    case SportId.Basketball:
      return BasketballConfiguration.basketballHideIncidentIds
    case SportId.Soccer:
      return SoccerConfiguration.soccerHideIncidentIds
    case SportId.AmericanFootball:
      return AmericanFootballConfiguration.americanFootballHideIncidentIds
    case SportId.IceHockey:
      return IceHockeyConfiguration.iceHockeyHideIncidentIds
    default:
      return []
  }
}

function getParticipantIncidents(sportId: SportId): SupportedIncidentId[] {
  switch (sportId) {
    case SportId.Basketball:
      return BasketballConfiguration.basketballParticipantIncidentIds
    case SportId.Soccer:
      return SoccerConfiguration.soccerParticipantIncidentIds
    case SportId.AmericanFootball:
      return AmericanFootballConfiguration.americanFootballParticipantIncidentIds
    case SportId.IceHockey:
      return IceHockeyConfiguration.iceHockeyParticipantIncidentIds
    default:
      return []
  }
}

function getSecondParticipantIncidents(
  sportId: SportId,
): SupportedIncidentId[] {
  switch (sportId) {
    case SportId.Basketball:
      return BasketballConfiguration.basketballSecondParticipantIncidentIds
    case SportId.Soccer:
      return SoccerConfiguration.soccerSecondParticipantIncidentIds
    case SportId.AmericanFootball:
      return AmericanFootballConfiguration.americanFootballSecondParticipantIncidentIds
    case SportId.IceHockey:
      return IceHockeyConfiguration.iceHockeySecondParticipantIncidentIds
    default:
      return []
  }
}

function getDetailsIncidents(sportId: SportId): SupportedIncidentId[] {
  switch (sportId) {
    case SportId.Basketball:
      return BasketballConfiguration.basketballDetailsIncidentIds
    case SportId.Soccer:
      return SoccerConfiguration.soccerDetailsIncidentIds
    case SportId.AmericanFootball:
      return AmericanFootballConfiguration.americanFootballDetailsIncidentIds
    case SportId.IceHockey:
      return IceHockeyConfiguration.iceHockeySecondDetailsIncidentIds
    default:
      return []
  }
}

function getSelectedOnStartDetailsIncidents(
  sportId: SportId,
): SupportedIncidentId[] {
  switch (sportId) {
    case SportId.Basketball:
      return BasketballConfiguration.basketballDetailsIncidentIds
    case SportId.Soccer:
      return SoccerConfiguration.soccerSelectedOnStartDetailsIncidentIds
    case SportId.AmericanFootball:
      return AmericanFootballConfiguration.americanFootballDetailsIncidentIds
    case SportId.IceHockey:
      return IceHockeyConfiguration.iceHockeySecondDetailsIncidentIds
    default:
      return []
  }
}

function getManualAddIncidents(sportId: SportId): SupportedIncidentId[] {
  switch (sportId) {
    case SportId.Basketball:
      return BasketballConfiguration.basketballManualAddIncidentIds
    case SportId.Soccer:
      return SoccerConfiguration.soccerManualAddIncidentIds
    case SportId.AmericanFootball:
      return AmericanFootballConfiguration.americanFootballManualAddIncidentIds
    case SportId.IceHockey:
      return IceHockeyConfiguration.iceHockeyManualAddIncidentIds
    default:
      return []
  }
}

function getManualAddSubstitutionIncidents(
  sportId: SportId,
): SupportedIncidentId[] {
  switch (sportId) {
    case SportId.Basketball:
      return BasketballConfiguration.basketballManualAddIncidentSubstitutionIds
    case SportId.Soccer:
      return SoccerConfiguration.soccerManualAddIncidentSubstitutionIds
    case SportId.AmericanFootball:
      return AmericanFootballConfiguration.americanFootballManualAddIncidentSubstitutionIds
    default:
      return []
  }
}

function getStatuses(sportId: SportId): SupportedEventStatusId[] {
  switch (sportId) {
    case SportId.Basketball:
      return BasketballConfiguration.basketballManualAddStatusIds
    case SportId.Soccer:
      return SoccerConfiguration.soccerManualAddStatusIds
    case SportId.AmericanFootball:
      return AmericanFootballConfiguration.americanFootballManualAddStatusIds
    case SportId.IceHockey:
      return IceHockeyConfiguration.iceHockeyManualAddStatusIds
    default:
      return []
  }
}

function getDetailsConfiguration(sportId: SportId): {} {
  switch (sportId) {
    case SportId.Basketball:
      return BasketballConfiguration.basketballDetailsConfiguration
    case SportId.Soccer:
      return SoccerConfiguration.soccerDetailsConfiguration
    case SportId.AmericanFootball:
      return AmericanFootballConfiguration.americanFootballDetailsConfiguration
    case SportId.IceHockey:
      return IceHockeyConfiguration.iceHockeyDetailsConfiguration

    default:
      return {}
  }
}

function getAttributesConfiguration(sportId: SportId): {} {
  switch (sportId) {
    case SportId.Basketball:
      return BasketballConfiguration.basketballAttributesConfiguration
    case SportId.Soccer:
      return SoccerConfiguration.soccerAttributesConfiguration
    case SportId.AmericanFootball:
      return AmericanFootballConfiguration.americanFootballAttributesConfiguration
    case SportId.IceHockey:
      return IceHockeyConfiguration.iceHockeyAttributesConfiguration

    default:
      return {}
  }
}

export function getIncidentAttributeId(
  linkedIncident: ProbableLinkedIncident,
  name: string,
): number {
  let attributeId =
    linkedIncident.sportIncident.attributes.find(
      (attribute) => attribute.name === name,
    )?.id ?? 0

  if (
    linkedIncident.sportIncident.sport_id === SportId.AmericanFootball &&
    linkedIncident.incident.incident_id === AmericanFootballIncidentId.Sack
  ) {
    attributeId =
      linkedIncident.sportIncident.attributes.find(
        (attribute) => attribute.name === name,
      )?.id ?? SackAttribute.NotAssisted
  }

  return attributeId
}

export function getPropertyIndex(
  properties: Property[] | undefined,
  propertyName: string | undefined,
): number {
  const index = properties?.findIndex(
    (property) =>
      property.short_name.toLowerCase() === propertyName?.toLowerCase(),
  )

  return !index || index === -1 ? 0 : index
}

export function enforceMinMax(
  property: Property[] | undefined,
  properties: Property[] | undefined,
  config: DetailInputConfiguration,
): void {
  if (property) {
    const selectedPropertyIndex = getPropertyIndex(properties, config.name)

    if (property[selectedPropertyIndex].value === '') {
      return
    }

    if (
      Number(property[selectedPropertyIndex].value) ||
      Number(property[selectedPropertyIndex].value) === 0
    ) {
      if (config.min !== undefined) {
        property[selectedPropertyIndex].value = Math.max(
          Number(property[selectedPropertyIndex].value),
          config.min,
        )
      }

      if (config.max !== undefined) {
        property[selectedPropertyIndex].value = Math.min(
          Number(property[selectedPropertyIndex].value),
          config.max,
        )
      }
    } else {
      property[selectedPropertyIndex].value = null
    }
  }
}
