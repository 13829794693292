import {
  CricketResultId,
  CricketStatId,
  Event,
  Incident,
} from '@collector/sportsapi-client-legacy'
import { setEventStat } from '@mobile/ActionQueue/Actions/Event/EventAction'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import { byId } from '@mobile/reusables/entityUtils'

function getLastRegisteredIncidentInCurrentOver(
  probableIncidents: ProbableIncident[],
  participantId: number,
  current_over: number,
): ProbableIncident | undefined {
  return probableIncidents
    .filter(
      (incident) =>
        incident.participant_id === participantId &&
        incident.additional_info &&
        incident.additional_info.current_over === current_over,
    )
    .sort((a, b) => (b.ct || 0) - (a.ct || 0))[0] // sort by ct (created time) descending, then get the first one
}

export function adjustCurrentActionStat(
  probableEvent: Event,
  participantId: number,
  probableIncidents: ProbableIncident[],
): void {
  const participant = byId(probableEvent.participants, participantId)
  const current_over = Number(
    byId(participant?.results ?? [], CricketResultId.Overs)?.value,
  )
  const current_action = Number(
    byId(participant?.stats ?? [], CricketStatId.CurrentAction)?.value,
  )

  const lastRegisteredIncident = getLastRegisteredIncidentInCurrentOver(
    probableIncidents,
    participantId,
    current_over,
  )

  if (!lastRegisteredIncident) return

  const registeredCurrentAction =
    lastRegisteredIncident.additional_info?.current_action || 0

  if (isNaN(current_action) || registeredCurrentAction > current_action) {
    setEventStat(
      probableEvent,
      participantId,
      CricketStatId.CurrentAction,
      registeredCurrentAction,
    )
  }
}

export function resolveIncidentAdditionalInfo(
  probableEvent: Event,
  participantId: number,
  probableIncidents: ProbableIncident[],
): Incident['additional_info'] {
  const participant = byId(probableEvent.participants, participantId)

  if (!participant) return {}

  const current_over = Number(
    byId(participant?.results ?? [], CricketResultId.Overs)?.value,
  )
  const current_ball = Number(
    byId(participant?.results ?? [], CricketResultId.CurrentBall)?.value,
  )
  let current_action = Number(
    byId(participant?.stats ?? [], CricketStatId.CurrentAction)?.value,
  )

  const lastRegisteredIncident = getLastRegisteredIncidentInCurrentOver(
    probableIncidents,
    participantId,
    current_over,
  )

  if (!lastRegisteredIncident) {
    return {
      current_ball,
      current_over,
      current_action: 0,
    }
  }

  const registeredCurrentAction =
    lastRegisteredIncident.additional_info?.current_action || 0

  if (isNaN(current_action) || registeredCurrentAction > current_action) {
    current_action = registeredCurrentAction
  }

  return {
    current_ball,
    current_over,
    current_action,
  }
}
