<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'

import { EventsLineupsParticipant } from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import { closeAlert, showAlert } from '@mobile/globalState/alert'
import { watchOnce } from '@mobile/reusables/watchOnce'
import PopupControls from '@mobile/views/Relation/Shared/Popup/PopupControls.vue'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'

import { FormationPlayer, Formations } from './Formation'
import FormationHeader from './FormationHeader.vue'
import FormationLineup from './FormationLineup.vue'
import FormationPitch from './FormationPitch/FormationPitch.vue'
import FormationResetAlert from './FormationResetAlert.vue'
import FormationsList from './FormationsList.vue'
import { useParticipantFormation } from './useParticipantFormation'

type FormationResetAlertEvents = Parameters<
  Parameters<
    Exclude<(typeof FormationResetAlert)['setup'], undefined>
  >[1]['emit']
>[0]

export default defineComponent({
  components: {
    Button,
    PopupControls,
    FormationHeader,
    FormationPitch,
    FormationsList,
    FormationLineup,
  },
  props: {
    probableLinkedParticipant: {
      type: Object as PropType<ProbableLinkedParticipant>,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const participant = props.probableLinkedParticipant.getParticipant()
    const lineups = props.probableLinkedParticipant.getLineups()
    const {
      formation,
      isFormationLoading,
      changeFormation,
      addPlayerToFormation,
      resetPlayerPositions,
      removePlayerFromFormation,
      saveFormation,
    } = useParticipantFormation(props.probableLinkedParticipant, lineups)

    const showFormationList = ref(false)
    const selectedFormationPlayer = ref<FormationPlayer | undefined>(undefined)

    function onFormationChange(newFormation: Formations): void {
      if (formation.value.statValue !== newFormation) {
        changeFormation(newFormation)
      }
      selectedFormationPlayer.value = undefined
      showFormationList.value = false
    }

    function onFormationPlayerRemove(
      formationPlayer: EventsLineupsParticipant,
    ): void {
      removePlayerFromFormation(formationPlayer)
      selectedFormationPlayer.value = undefined
    }

    function onFormationPlayerAdd(
      formationPlayer: EventsLineupsParticipant,
    ): void {
      if (
        selectedFormationPlayer.value &&
        !selectedFormationPlayer.value.participant_id
      ) {
        addPlayerToFormation({
          ...formationPlayer,
          player_position: selectedFormationPlayer.value?.player_position,
        })
        selectedFormationPlayer.value = undefined
      }
    }

    function onFormationPlayerSelection(
      formationPlayer: FormationPlayer,
    ): void {
      selectedFormationPlayer.value = formationPlayer
    }

    function showFormationsLeaveAlert(): void {
      showAlert<FormationResetAlertEvents>(FormationResetAlert, {
        confirm: () => {
          selectedFormationPlayer.value = undefined
          resetPlayerPositions()
          closeAlert()
        },
        close: () => closeAlert(),
      })
    }

    function onCancel(): void {
      emit('close')
    }

    function onSave(): void {
      saveFormation()
      emit('close')
    }

    watchOnce(isFormationLoading, () => {
      showFormationList.value = !formation.value.statValue
    })

    return {
      formation,
      showFormationList,
      selectedFormationPlayer,
      isFormationLoading,
      onFormationPlayerSelection,
      onFormationChange,
      onFormationPlayerAdd,
      onFormationPlayerRemove,
      showFormationsLeaveAlert,
      onCancel,
      onSave,
      participant,
    }
  },
})
</script>

<template>
  <div
    v-if="!isFormationLoading"
    class="flex h-full w-full flex-col"
  >
    <FormationHeader
      :probableLinkedParticipant
      :formation
      :showResetButton="!showFormationList"
      @formationButtonClicked="showFormationList = true"
      @resetButtonClicked="showFormationsLeaveAlert()"
    />
    <FormationsList
      v-if="showFormationList"
      :participant
      :currentFormation="formation"
      @formationSelected="onFormationChange"
    />
    <template v-else>
      <div class="bg-silver flex h-full overflow-hidden p-1">
        <FormationPitch
          :formation
          :participant
          :selectedFormationPlayer
          @formationPlayerSelected="onFormationPlayerSelection"
        />
        <FormationLineup
          :formation
          :selectedFormationPlayer
          @addFormationPlayerClicked="onFormationPlayerAdd"
          @removeFormationPlayerClicked="onFormationPlayerRemove"
        />
      </div>
      <PopupControls>
        <template #buttons>
          <div class="flex justify-end space-x-2">
            <Button
              :color="'red'"
              :size="'sm'"
              @click="onCancel"
            >
              Cancel
            </Button>
            <Button
              :color="'green'"
              :size="'sm'"
              @click="onSave"
            >
              Save
            </Button>
          </div>
        </template>
      </PopupControls>
    </template>
  </div>
  <div
    v-else
    class="flex h-full w-full flex-col items-center justify-center"
  >
    <FontAwesomeIcon
      class="text-blue mx-auto animate-spin text-3xl"
      icon="spinner"
    />
  </div>
</template>
