<script lang="ts">
import { defineComponent } from 'vue'

import { HandballIncidentId } from '@collector/sportsapi-client-legacy'
import Breadcrumbs from '@mobile/views/Relation/Shared/Popup/Breadcrumbs.vue'
import TeamSelect from '@mobile/views/Relation/Shared/Popup/TeamSelect.vue'
import { useIncidentAction } from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useHandballConfiguration } from '@mobile/views/Relation/Sports/Handball/HandballRelationDependencies/useHandballConfiguration'

export default defineComponent({
  components: {
    TeamSelect,
    Breadcrumbs,
  },
  emits: ['close'],
  setup(props, { emit }) {
    const eventInjections = useEventInjections()
    const { activeParticipant } = useHandballConfiguration()

    const sendIncident = (
      probableLinkedParticipant: ProbableLinkedParticipant,
    ): void => {
      const incidentId: HandballIncidentId =
        activeParticipant.value.participant?.id ===
        probableLinkedParticipant.getParticipant().value.id
          ? HandballIncidentId.OffensiveFoul
          : HandballIncidentId.Foul

      useIncidentAction(eventInjections, {
        incident_id: incidentId,
        participant_team_id:
          probableLinkedParticipant.getParticipant().value.id,
      })

      emit('close')
    }

    return { sendIncident }
  },
})
</script>

<template>
  <div>
    <Breadcrumbs name="Foul" />
    <TeamSelect
      @participantSelected="sendIncident"
      @close="$emit('close')"
    />
  </div>
</template>
