<script lang="ts">
import { defineComponent } from 'vue'

import Button from '@mobile/components/Button/Button.vue'

export default defineComponent({
  components: { Button },
  props: {
    disableButtons: {
      type: Boolean,
      default: false,
    },
    confirmButtonText: {
      type: String,
      default: 'Save & continue',
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },
  },
  emits: ['confirm', 'cancel'],
})
</script>

<template>
  <div ref="rootElement">
    <template v-if="!disableButtons">
      <div class="pointer-events-none absolute bottom-0 w-full p-4">
        <slot name="buttons">
          <div class="flex justify-between">
            <Button
              :color="'red'"
              :size="'lg'"
              @click="$emit('cancel')"
            >
              {{ cancelButtonText }}
            </Button>
            <Button
              :color="'green'"
              :size="'lg'"
              @click="$emit('confirm')"
            >
              {{ confirmButtonText }}
            </Button>
          </div>
        </slot>
      </div>

      <teleport to="#popup-controls-padding">
        <div class="h-20 w-full"></div>
      </teleport>
    </template>
  </div>
</template>

<style scoped lang="scss">
@import '@mobile/styles/variables.scss';

.swipe-indicator {
  display: flex;
  width: 2px;
  position: fixed;
  top: 0;
  bottom: 0;
  border-radius: 100%;
  justify-content: center;
  align-items: center;

  &-left {
    left: -10px;
    box-shadow: 0 0px 32px 1px $red;
  }

  &-right {
    right: -10px;
    box-shadow: 0 0px 32px 1px $green;
  }
}

.swipe-incicator-button {
  position: fixed;
  width: 12px;
  height: 70px;
  border-radius: 100%;

  &-left {
    background: rgba($red, 0.7);
    left: -8px;
  }

  &-right {
    background: rgba($green, 0.7);
    right: -8px;
  }

  &-higher {
    margin-top: -50px;
  }
}
</style>
