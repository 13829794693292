<script lang="ts">
import { defineComponent, PropType, Ref } from 'vue'

import { nullCoalesce, opChain } from '@collector/shared-utils'
import {
  EventsLineupsParticipant,
  TeamSide,
} from '@collector/sportsapi-client-legacy'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import {
  AssignableIncidentConfiguration,
  AssignableIncidentConfigurationParticipantType,
} from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/AssignableIncidentsConfiguration'
import SelectPlayer from '@mobile/views/Relation/Shared/SelectPlayer/SelectPlayer.vue'

import { useLineupsSelectOptions } from '../Lineups/useLineupsSelectOption'
import { IncidentActionComposable } from '../Popup/useIncidentAction'

export default defineComponent({
  components: { SelectPlayer },
  props: {
    assignableIncidentConfiguration: {
      type: Object as PropType<AssignableIncidentConfiguration>,
      required: true,
    },
    incidentActionComposable: {
      type: Object as PropType<IncidentActionComposable>,
      required: false,
    },
    incident: {
      type: Object as PropType<Ref<ProbableIncident>>,
      required: false,
    },
    side: {
      type: String as PropType<TeamSide>,
      required: false,
    },
    selectLabelClasses: {
      type: String,
      required: false,
    },
    fontSize: {
      type: String,
      default: 'text-xxs',
    },
  },
  emits: [
    'mainParticipantUpdated',
    'secondaryParticipantUpdated',
    'ternaryParticipantUpdated',
  ],
  setup(props, { emit }) {
    if (!props.incident && !props.incidentActionComposable) {
      throw new Error('incident or incidentActionComposable must be provided')
    }

    const {
      selectedMainParticipant,
      selectedSecondaryParticipant,
      selectedTernaryParticipant,
      mainLineupsParticipants,
      secondaryLineupsParticipants,
      ternaryLineupsParticipants,
      lineupsSelectOptions,
      updateMainParticipant,
      updateSecondaryParticipant,
      updateTernaryParticipant,
    } = props.incidentActionComposable
      ? useLineupsSelectOptions(
          props.incidentActionComposable.getProbableIncident(),
          props.incidentActionComposable.getProbableLinkedParticipant()?.side,
        )
      : useLineupsSelectOptions(props.incident!, props.side)

    function onMainParticipantUpdate(
      participant: EventsLineupsParticipant | null,
    ): void {
      updateMainParticipant(participant?.participant_id)
      emit('mainParticipantUpdated', participant)
    }

    function onSecondaryParticipantUpdate(
      participant: EventsLineupsParticipant | null,
    ): void {
      updateSecondaryParticipant(participant?.participant_id)
      emit('secondaryParticipantUpdated', participant)
    }

    function onTernaryParticipantUpdate(
      participant: EventsLineupsParticipant | null,
    ): void {
      updateTernaryParticipant(participant?.participant_id)
      emit('ternaryParticipantUpdated', participant)
    }

    function getConfigParticipantName(
      key: AssignableIncidentConfigurationParticipantType,
    ): string | undefined {
      return opChain(
        props.assignableIncidentConfiguration,
        (v) => v.participants?.[key],
      )
    }

    return {
      selectedMainParticipant,
      selectedSecondaryParticipant,
      selectedTernaryParticipant,
      lineupsSelectOptions,
      mainLineupsParticipants,
      secondaryLineupsParticipants,
      ternaryLineupsParticipants,
      onMainParticipantUpdate,
      onSecondaryParticipantUpdate,
      onTernaryParticipantUpdate,
      nullCoalesce,
      getConfigParticipantName,
    }
  },
})
</script>

<template>
  <SelectPlayer
    v-if="getConfigParticipantName('main')"
    :selectLabel="{
      text: getConfigParticipantName('main')!,
      classes: selectLabelClasses || '',
    }"
    :players="mainLineupsParticipants"
    :lineupsSelectOptions
    :selectedPlayer="nullCoalesce(selectedMainParticipant, undefined)"
    :font-size
    @selectedPlayerChanged="onMainParticipantUpdate"
  />
  <div
    v-if="getConfigParticipantName('secondary')"
    class="mx-4"
  ></div>
  <SelectPlayer
    v-if="getConfigParticipantName('secondary')"
    :selectLabel="{
      text: getConfigParticipantName('secondary')!,
      classes: selectLabelClasses || '',
    }"
    :players="secondaryLineupsParticipants"
    :lineupsSelectOptions
    :selectedPlayer="nullCoalesce(selectedSecondaryParticipant, undefined)"
    :font-size
    @selectedPlayerChanged="onSecondaryParticipantUpdate"
  />
  <SelectPlayer
    v-if="getConfigParticipantName('ternary')"
    :selectLabel="{
      text: getConfigParticipantName('ternary')!,
      classes: selectLabelClasses || '',
    }"
    :players="ternaryLineupsParticipants"
    :lineupsSelectOptions
    :selectedPlayer="selectedTernaryParticipant"
    :font-size
    @selectedPlayerChanged="onTernaryParticipantUpdate"
  />
</template>
