import { CricketIncidentId } from '@collector/sportsapi-client-legacy'
import { PopupBuilder } from '@mobile/views/Relation/Shared/Popup/builder/PopupBuilder'

export const PenaltyRunsPopup = new PopupBuilder('Penalty Runs', {
  incident_id: CricketIncidentId.ExtrasPenaltyRuns,
  attribute_ids: [592],
})
  .withTeamSelection()
  .omitDetailsView()
  .build()
