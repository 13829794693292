import { Container } from 'inversify'

import { SoccerIncidentId } from '@collector/sportsapi-client-legacy'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindEventHeaderConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.EventHeaderConfiguration)
    .toDynamicValue(() => {
      return {
        betMarketButtons: [
          {
            marketName: 'bet_cards',
            size: 'xxs',
            openMarketsText: 'BM',
            closeMarketsText: 'BM',
            openMarketsIncident: SoccerIncidentId.BookingMarketsEnabled,
            closeMarketsIncident: SoccerIncidentId.BookingMarketsDisabled,
            withIcon: false,
            type: 'horizontal',
          },
          {
            marketName: 'bet_corners',
            size: 'xxs',
            openMarketsText: 'CM',
            closeMarketsText: 'CM',
            openMarketsIncident: SoccerIncidentId.CornerMarketsEnabled,
            closeMarketsIncident: SoccerIncidentId.CornerMarketsDisabled,
            withIcon: false,
            type: 'horizontal',
          },
        ],
        hasUnconfirmedEvents: true,
      }
    })
    .inSingletonScope()
}
