import { Container } from 'inversify'

import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindAssignableIncidentConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.AssignableIncidentsConfiguration)
    .toDynamicValue(() => {
      return {}
    })
    .inSingletonScope()
}
