import { Event, EventDetailId } from '@collector/sportsapi-client-legacy'
import { SelectOption } from '@mobile/components/Select/types/SelectOption'
import { CollectorSport } from '@mobile/reusables/classes/CollectorSport'
import { byId } from '@mobile/reusables/entityUtils'

export function getDetailName(
  sport: CollectorSport,
  detailId: EventDetailId,
): string {
  return byId(sport.sportsAPIEntity.details, detailId)?.name ?? 'Unknown'
}

export function getDetailSelectItemsWithPlaceholder(
  items: SelectOption[],
): SelectOption[] {
  items.unshift({
    name: 'Not selected',
    value: null,
    disabled: true,
  })

  return items
}

export function getDetailSelectItems(
  event: Event,
  sport: CollectorSport,
  detailId: EventDetailId,
): SelectOption[] {
  const eventDetail = byId(event.details, detailId)
  const sportDetail = byId(sport.sportsAPIEntity.details, detailId)

  if (!sportDetail?.input || !eventDetail) return []

  const items: Array<SelectOption> = sportDetail.possible_values.map(
    (possibleValues) => ({
      name: possibleValues.value,
      value:
        typeof eventDetail.value === 'number'
          ? possibleValues.key
          : possibleValues.value,
    }),
  )

  return items
}
