<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<template>
  <button
    class="text-blue border-blue h-10 w-10 transform rounded-full border border-solid bg-white text-center text-xs shadow transition hover:scale-110 hover:shadow active:scale-90 active:shadow disabled:pointer-events-none disabled:opacity-20"
    :disabled
  >
    <slot></slot>
  </button>
</template>
