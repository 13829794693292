import {
  Confirmation,
  SoccerIncidentId,
  YellowCardAttribute,
} from '@collector/sportsapi-client-legacy'
import AttributesSelection from '@mobile/views/Relation/Shared/Popup/builder/AttributesSelection.vue'
import { useCancellationButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useCancellationButton'
import { useCloseButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useCloseButton'
import { useConfirmationButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useConfirmationButton'
import { useNavigationSeparator } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useNavigationSeparator'
import { usePopupButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/usePopupButton'
import { PopupBuilder } from '@mobile/views/Relation/Shared/Popup/builder/PopupBuilder'
import SelectedParticipant from '@mobile/views/Relation/Shared/Popup/builder/SelectedParticipant.vue'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'

import { RedCardPopup } from '../RedCard/RedCardPopup'
import { VARPopup } from '../VAR/VARPopup'

export const YellowCardPopup = new PopupBuilder('Yellow card', {
  incident_id: SoccerIncidentId.YellowCard,
  confirmation: Confirmation.ToBeDone,
})
  .withTeamSelection()
  .setNavigationButtons([
    usePopupButton({
      props: {
        text: 'VAR',
        color: 'blue',
        onClick: (context: PopupContext | null) => context?.openPopup(VARPopup),
        attributes: { 'data-cy': 'yellowCardDetailsVARButton' },
      },
    }),
    usePopupButton({
      props: {
        text: 'Red card',
        color: 'blue',
        onClick: (context: PopupContext | null) =>
          context?.openPopup(RedCardPopup),
        attributes: { 'data-cy': 'yellowCardDetailsRedCardButton' },
      },
    }),
    ...useNavigationSeparator(),
    useCloseButton(),
    useCancellationButton(),
    useConfirmationButton(),
  ])
  .appendBody(SelectedParticipant)
  .appendBody(AttributesSelection, {
    title: 'Additional info',
    attributes: [YellowCardAttribute.OffField],
  })
  .build()
