<script lang="ts">
import { computed, defineComponent, PropType, shallowRef } from 'vue'

import {
  Event,
  SportStatInputType,
  TeamSide,
} from '@collector/sportsapi-client-legacy'
import { getParticipantButtonColor } from '@mobile/components/Button/ButtonUtils'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'

import TeamSelect from '../../Popup/TeamSelect.vue'
import { useEventInjections } from '../../RelationDependencies/useEventInjections'
import { useEventPreparationEventStatsFactory } from './factories/EventPreparationEventStatsFactory/useEventPreparationEventStatsFactory'

export default defineComponent({
  components: { TeamSelect },
  props: {
    event: {
      type: Object as PropType<Event>,
      required: true,
    },
    setFullHeight: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['participantStatsUpdated'],
  setup(props, { emit }) {
    const {
      sport,
      probableFirstParticipant,
      probableSecondParticipant,
      probableLinkedFirstParticipant,
      probableLinkedSecondParticipant,
    } = useEventInjections()
    const firstParticipant =
      probableLinkedFirstParticipant.value.getParticipant()
    const secondParticipant =
      probableLinkedSecondParticipant.value.getParticipant()
    const selectedParticipant = shallowRef(
      null as ProbableLinkedParticipant | null,
    )
    const stats = useEventPreparationEventStatsFactory(
      props.event,
      sport.value,
    ).getStats()

    const firstParticipantStats = computed(() => firstParticipant.value.stats)
    const secondParticipantStats = computed(() => secondParticipant.value.stats)

    function isSelectedFirstParticipant(statId: number): boolean {
      const stat = firstParticipantStats.value.find(
        (stat) => stat.id === statId,
      )
      if (!stat) {
        return false
      }

      return stat.value === 1
    }

    function isSelectedSecondParticipant(statId: number): boolean {
      const stat = secondParticipantStats.value.find(
        (stat) => stat.id === statId,
      )
      if (!stat) {
        return false
      }

      return stat.value === 1
    }

    const firstParticipantColor = computed(() =>
      getParticipantButtonColor(firstParticipant.value),
    )
    const secondParticipantColor = computed(() =>
      getParticipantButtonColor(secondParticipant.value),
    )

    function setStat(
      participant: ProbableLinkedParticipant,
      statId: number,
      value: number,
    ): void {
      const stat = participant
        .getParticipant()
        .value.stats.find((stat) => stat.id === statId)
      if (!stat) {
        participant.getParticipant().value.stats.push({
          id: statId,
          value,
        })

        return
      }
      stat.value = value
      emit('participantStatsUpdated', participant.getParticipant())
    }

    function selectParticipant(
      participant: ProbableLinkedParticipant,
      statId: number,
    ): void {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      selectedParticipant.value =
        participant.side === TeamSide.Home
          ? probableLinkedFirstParticipant
          : probableLinkedSecondParticipant
      const unselectedParticipant =
        participant.side === TeamSide.Home
          ? probableLinkedSecondParticipant
          : probableLinkedFirstParticipant
      setStat(selectedParticipant.value as ProbableLinkedParticipant, statId, 1)
      setStat(
        unselectedParticipant.value as ProbableLinkedParticipant,
        statId,
        0,
      )
    }

    function getDefaultParticipant(
      statId: number,
    ): ProbableLinkedParticipant | null {
      if (isSelectedFirstParticipant(statId)) {
        return probableLinkedFirstParticipant.value
      }

      if (isSelectedSecondParticipant(statId)) {
        return probableLinkedSecondParticipant.value
      }

      return null
    }

    return {
      stats,
      SportStatInputType,
      probableLinkedFirstParticipant,
      probableFirstParticipant,
      probableLinkedSecondParticipant,
      probableSecondParticipant,
      firstParticipantColor,
      secondParticipantColor,
      isSelectedFirstParticipant,
      isSelectedSecondParticipant,
      selectParticipant,
      getDefaultParticipant,
    }
  },
})
</script>

<template>
  <div :class="setFullHeight ? 'h-full pb-3' : ''">
    <div
      v-for="(stat, index) in stats"
      :key="`stat-${index}`"
      class="flex w-full items-center pb-3"
    >
      <div class="text-gray w-1/4 text-left text-xs font-bold">
        {{ stat.name }}
      </div>
      <div class="flex w-3/4">
        <template v-if="stat.type === SportStatInputType.SelectParticipant">
          <div class="justify-items flex w-full flex-col items-stretch">
            <TeamSelect
              :withTitle="false"
              :withButtons="false"
              :defaultParticipant="getDefaultParticipant(stat.id)"
              @participantSelected="selectParticipant($event, stat.id)"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
