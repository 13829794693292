<script lang="ts">
import { get } from 'lodash'
import {
  computed,
  defineComponent,
  onUnmounted,
  PropType,
  Ref,
  ref,
  toRef,
} from 'vue'

import { opChain } from '@collector/shared-utils'
import {
  Confirmation,
  IncidentId,
  IncidentsQueuePatchDTO,
} from '@collector/sportsapi-client-legacy'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import UpdateIncident from '@mobile/ActionQueue/Actions/Incidents/UpdateIncident'
import Select from '@mobile/components/Select/Select.vue'
import {
  SelectOptionWithShirtNumber,
  useLineupsSelectOptions,
} from '@mobile/views/Relation/Shared/Lineups/useLineupsSelectOption'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { AssignableIncidentConfigurationParticipantType } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/AssignableIncidentsConfiguration'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

import { getFilteredSelectOptions } from './partcipantsSearchFilter'

type PlayerSelectUpdatePayload = Partial<
  Pick<
    ProbableIncident,
    | 'participant_id'
    | 'participant_name'
    | 'assistant_id'
    | 'assistant_name'
    | 'subparticipant_id'
    | 'subparticipant_name'
    | 'secondary_assistant_id'
    | 'secondary_assistant_name'
  >
>

export default defineComponent({
  components: { Select },
  props: {
    linkedIncident: {
      type: Object as PropType<ProbableLinkedIncident>,
      required: true,
    },
    participantsIncidents: {
      type: Array as PropType<IncidentId[]>,
      default: () => [],
    },
    detailsIncidents: {
      type: Array as PropType<IncidentId[]>,
      default: () => [],
    },
    docked: {
      type: Boolean,
      default: false,
    },
    openedList: {
      type: Boolean,
      default: false,
    },
    isPinnedTable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    openedMainSelect: {
      type: Boolean,
      default: false,
    },
    openedSecondarySelect: {
      type: Boolean,
      default: false,
    },
    openedTernarySelect: {
      type: Boolean,
      default: false,
    },
    time: {
      type: Object as PropType<
        Pick<IncidentsQueuePatchDTO, 'minute' | 'second'>
      >,
      required: true,
    },
  },
  setup(props) {
    const mainParticipantSearchValue: Ref<string> = ref('')
    const secondaryParticipantSearchValue: Ref<string> = ref('')
    const ternaryParticipantSearchValue: Ref<string> = ref('')
    const { actionQueue, probableEvent } = useEventInjections()
    const { id: event_id, status_id } = probableEvent.value
    const { assignableIncidentsConfiguration } = useSportConfiguration()
    const assignableIncidentConfiguration = get(
      assignableIncidentsConfiguration,
      props.linkedIncident.incident.incident_id,
      null,
    )
    const doesSportSupportsThirdParticipant = Object.keys(
      assignableIncidentsConfiguration,
    ).some((key) => {
      return assignableIncidentsConfiguration[key as unknown as IncidentId]
        ?.participants?.ternary
    })

    const showSecondaryParticipantSelect = computed<boolean>(() => {
      if (!assignableIncidentConfiguration?.participants?.secondary) {
        return false
      }

      const { availableWhen } =
        assignableIncidentConfiguration.participantsConfiguration?.secondary ??
        {}

      return availableWhen?.(toRef(props.linkedIncident.incident)) ?? true
    })

    const showTernaryParticipantSelect = computed<boolean>(() => {
      if (!assignableIncidentConfiguration?.participants?.ternary) {
        return false
      }

      const { availableWhen } =
        assignableIncidentConfiguration.participantsConfiguration?.ternary ?? {}

      return availableWhen?.(toRef(props.linkedIncident.incident)) ?? true
    })

    const {
      selectedMainParticipant,
      selectedSecondaryParticipant,
      selectedTernaryParticipant,
      lineupsBothSideSelectOptions,
      mainLineupsParticipants,
      secondaryLineupsParticipants,
      ternaryLineupsParticipants,
    } = useLineupsSelectOptions(
      computed(() => props.linkedIncident.incident),
      props.linkedIncident.side ?? undefined,
      assignableIncidentConfiguration,
    )

    const showParticipantsSelect = computed(() =>
      props.participantsIncidents.includes(
        props.linkedIncident.incident.incident_id,
      ),
    )

    function participantSelectBorder(condition: boolean): string {
      if (props.disabled) {
        return ''
      }
      if (condition) {
        return 'border-2 border-green'
      } else {
        return 'border-2 border-red'
      }
    }

    function onMainParticipantSearchChange(value: string): void {
      mainParticipantSearchValue.value = value
    }

    function onSecondaryParticipantSearchChange(value: string): void {
      secondaryParticipantSearchValue.value = value
    }

    function onTernaryParticipantSearchChange(value: string): void {
      ternaryParticipantSearchValue.value = value
    }

    const filteredMainSelectOptions = computed(() =>
      getFilteredSelectOptions(
        mainLineupsParticipants.value,
        mainParticipantSearchValue.value,
      ),
    )

    const filteredSecondarySelectOptions = computed(() =>
      getFilteredSelectOptions(
        secondaryLineupsParticipants.value,
        secondaryParticipantSearchValue.value,
      ),
    )

    const filteredTernarySelectOptions = computed(() =>
      getFilteredSelectOptions(
        ternaryLineupsParticipants.value,
        ternaryParticipantSearchValue.value,
      ),
    )

    function getParticipantShirtNumber(
      participantId: number,
    ): number | null | undefined | string {
      return findParticipant(participantId)?.shirtNumber
    }

    function findParticipant(
      participantId: number | null,
    ): SelectOptionWithShirtNumber | undefined {
      return lineupsBothSideSelectOptions.value.find(
        (participant) => participant.value === participantId,
      )
    }

    function onMainParticipantUpdate(participantId: number | null): void {
      const participantName = participantId
        ? findParticipant(participantId)?.name
        : ''

      updateIncident({
        participant_id: participantId,
        participant_name: participantName,
        subparticipant_id: participantId,
        subparticipant_name: participantName,
        assistant_id:
          props.linkedIncident.incident.additional_info?.assistant_id,
        assistant_name:
          props.linkedIncident.incident.additional_info?.assistant_name,
        secondary_assistant_id:
          props.linkedIncident.incident.secondary_assistant_id,
        secondary_assistant_name:
          props.linkedIncident.incident.secondary_assistant_name,
      })
    }

    function onSecondaryParticipantUpdate(participantId: number | null): void {
      updateIncident({
        participant_id: props.linkedIncident.incident.subparticipant_id,
        participant_name: props.linkedIncident.incident.subparticipant_name,
        subparticipant_id: props.linkedIncident.incident.subparticipant_id,
        subparticipant_name: props.linkedIncident.incident.subparticipant_name,
        assistant_id: participantId,
        assistant_name: findParticipant(participantId)?.name,
        secondary_assistant_id:
          props.linkedIncident.incident.secondary_assistant_id,
        secondary_assistant_name:
          props.linkedIncident.incident.secondary_assistant_name,
      })
    }

    function onTernaryParticipantUpdate(participantId: number | null): void {
      updateIncident({
        participant_id: props.linkedIncident.incident.subparticipant_id,
        participant_name: props.linkedIncident.incident.subparticipant_name,
        subparticipant_id: props.linkedIncident.incident.subparticipant_id,
        subparticipant_name: props.linkedIncident.incident.subparticipant_name,
        assistant_id:
          props.linkedIncident.incident.additional_info?.assistant_id,
        assistant_name:
          props.linkedIncident.incident.additional_info?.assistant_name,
        secondary_assistant_id: participantId,
        secondary_assistant_name: findParticipant(participantId)?.name,
      })
    }

    onUnmounted(() => {
      mainParticipantSearchValue.value = ''
      secondaryParticipantSearchValue.value = ''
      ternaryParticipantSearchValue.value = ''
    })

    function updateIncident(payload: PlayerSelectUpdatePayload): void {
      actionQueue.value.add(
        new UpdateIncident(props.linkedIncident.incident.id, {
          incident_id: props.linkedIncident.incident.incident_id,
          event_id,
          status_id,
          attribute_ids: props.linkedIncident.incident.attribute_ids,
          participant_team_id: props.linkedIncident.incident.participant_id,
          minute: props.time.minute,
          second: props.time.second,
          // TODO: remove this line when Team API will fix update paylods
          properties: props.linkedIncident.incident.properties,
          ...payload,
        }),
      )
    }

    function getParticipantBorder(
      key: 'secondary' | 'ternary',
      incidentKey: 'assistant_id' | 'secondary_assistant_id',
      linkedIncident: ProbableLinkedIncident,
    ): string {
      const assignableIncidentConfiguration = get(
        assignableIncidentsConfiguration,
        linkedIncident.incident.incident_id,
        null,
      )
      const predicate =
        assignableIncidentConfiguration?.participantsConfiguration?.[key]
          ?.fulfilledWhen
      if (predicate) {
        return participantSelectBorder(predicate(linkedIncident.incident))
      }

      return participantSelectBorder(
        !!(
          linkedIncident.incident[incidentKey] ||
          linkedIncident.incident.additional_info?.[incidentKey]
        ),
      )
    }

    function getConfigParticipantName(
      key: AssignableIncidentConfigurationParticipantType,
    ): string | undefined {
      return opChain(
        assignableIncidentConfiguration,
        (v) => v.participants?.[key],
      )
    }

    return {
      assignableIncidentConfiguration,
      onMainParticipantUpdate,
      onSecondaryParticipantUpdate,
      onTernaryParticipantUpdate,
      Confirmation,
      selectedMainParticipant,
      selectedSecondaryParticipant,
      selectedTernaryParticipant,
      lineupsBothSideSelectOptions,
      updateIncident,
      findParticipant,
      participantSelectBorder,
      onMainParticipantSearchChange,
      onSecondaryParticipantSearchChange,
      onTernaryParticipantSearchChange,
      filteredMainSelectOptions,
      filteredSecondarySelectOptions,
      filteredTernarySelectOptions,
      getParticipantShirtNumber,
      getParticipantBorder,
      showParticipantsSelect,
      showSecondaryParticipantSelect,
      showTernaryParticipantSelect,
      ternaryLineupsParticipants,
      doesSportSupportsThirdParticipant,
      getConfigParticipantName,
      opChain,
    }
  },
})
</script>

<template>
  <div class="flex space-x-12">
    <div class="flex-1">
      <div v-if="getConfigParticipantName('main')">
        <div class="flex items-center justify-between space-x-2">
          <div
            class="text-gray text-xxs font-bold"
            style="width: 80px"
          >
            {{ getConfigParticipantName('main') }}
          </div>
          <Select
            v-model="selectedMainParticipant"
            :class="
              participantSelectBorder(
                !!linkedIncident.incident.subparticipant_id,
              )
            "
            label="name"
            mode="single"
            :options="filteredMainSelectOptions"
            :openedList="openedMainSelect"
            searchable
            trackBy="name"
            :disabled
            data-test-id="DetailsPanelPlayerSelect"
            @searchChange="onMainParticipantSearchChange"
            @update:value="onMainParticipantUpdate"
          />
        </div>
      </div>
    </div>

    <div class="flex-1">
      <div v-if="showSecondaryParticipantSelect">
        <div class="flex items-center justify-between space-x-2">
          <div
            class="text-gray text-xxs font-bold"
            style="width: 80px"
          >
            <div
              v-for="(line, idx) in (
                getConfigParticipantName('secondary') || ''
              ).split('\n')"
              :key="`line-${idx}`"
            >
              {{ line }}
            </div>
          </div>
          <Select
            v-model="selectedSecondaryParticipant"
            :class="
              getParticipantBorder('secondary', 'assistant_id', linkedIncident)
            "
            label="name"
            mode="single"
            :options="filteredSecondarySelectOptions"
            searchable
            :openedList="openedSecondarySelect"
            trackBy="name"
            :disabled
            @searchChange="onSecondaryParticipantSearchChange"
            @update:value="onSecondaryParticipantUpdate"
          />
        </div>
      </div>
    </div>

    <div
      v-if="doesSportSupportsThirdParticipant"
      class="flex-1"
    >
      <div v-if="showTernaryParticipantSelect">
        <div class="flex items-center justify-between space-x-2">
          <div
            class="text-gray text-xxs font-bold"
            style="width: 80px"
          >
            {{ getConfigParticipantName('ternary') }}
          </div>
          <Select
            v-model="selectedTernaryParticipant"
            :class="
              getParticipantBorder(
                'ternary',
                'secondary_assistant_id',
                linkedIncident,
              )
            "
            label="name"
            mode="single"
            :options="filteredTernarySelectOptions"
            searchable
            :openedList="openedTernarySelect"
            trackBy="name"
            :disabled
            @searchChange="onTernaryParticipantSearchChange"
            @update:value="onTernaryParticipantUpdate"
          />
        </div>
      </div>
    </div>
  </div>
</template>
