export const enum BasketballResultId {
  Result = 2,
  RegularTime = 3,
  FirstHalf = 4,
  SecondHalf = 5,
  FirstQuarter = 50,
  SecondQuarter = 51,
  ThirdQuarter = 52,
  FourthQuarter = 53,
  Overtime = 104,
}
