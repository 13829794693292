import { interfaces } from 'inversify'
import { Ref } from 'vue'

import { ActiveParticipant } from './ActiveParticipant'

type SI<T> = interfaces.ServiceIdentifier<T>

interface BasketballRelationIoCBindingsInterface {
  ActiveParticipant: SI<Ref<ActiveParticipant>>
}

export const BasketballRelationIoCBindings: BasketballRelationIoCBindingsInterface =
  { ActiveParticipant: Symbol('ActiveParticipant') }
