export const enum BasketballEventStatusId {
  NotStarted = 1,
  Interrupted = 2,
  Cancelled = 3,
  Postponed = 5,
  StartDelayed = 6,
  Abandoned = 7,
  FirstQuarter = 8,
  SecondQuarter = 10,
  FinishedRegularTime = 11,
  FinishedAwardedWin = 12,
  ThirdQuarter = 19,
  FourthQuarter = 20,
  FirstHalf = 33,
  SecondHalf = 34,
  Break = 42,
  WaitingForOvertime = 138,
  Overtime = 139,
  FinishedAfterOvertime = 140,
  ToFinish = 152,
  BreakAfterFirstQuarter = 212,
  BreakAfterSecondQuarter = 213,
  BreakAfterThirdQuarter = 214,
  BreakAfterFourthQuarter = 215,
  BreakAfterFirstHalf = 263,
  FirstOvertime = 338,
  BreakAfterFirstOvertime = 339,
  SecondOvertime = 340,
  BreakAfterSecondOvertime = 341,
  ThirdOvertime = 342,
  BreakAfterThirdOvertime = 343,
  FourthOvertime = 344,
  BreakAfterFourthOvertime = 345,
  FifthOvertime = 346,
}
