import { Container } from 'inversify'

import { AmericanFootballIncidentId } from '@collector/sportsapi-client-legacy'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindEventStatusesList(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.EventStatusesList)
    .toDynamicValue(() => {
      return [
        AmericanFootballIncidentId.StartDelayed,
        AmericanFootballIncidentId.MatchAboutToStart,
        AmericanFootballIncidentId.FinishedRegularTime,
        AmericanFootballIncidentId.Cancelled,
        AmericanFootballIncidentId.Abandoned,
        AmericanFootballIncidentId.Interrupted,
        AmericanFootballIncidentId.Postponed,
        AmericanFootballIncidentId.FinishedAwardedWin,
        AmericanFootballIncidentId.ToFinish,
        AmericanFootballIncidentId.FinishedAfterExtratime,
        AmericanFootballIncidentId.FirstHalfStarted,
        AmericanFootballIncidentId.SecondHalfStarted,
        AmericanFootballIncidentId.Halftime,
        AmericanFootballIncidentId.FirstQuarterStarted,
        AmericanFootballIncidentId.BreakAfterFirstQuarter,
        AmericanFootballIncidentId.SecondQuarterStarted,
        AmericanFootballIncidentId.BreakAfterSecondQuarter,
        AmericanFootballIncidentId.ThirdQuarterStarted,
        AmericanFootballIncidentId.BreakAfterThirdQuarter,
        AmericanFootballIncidentId.FourthQuarterStarted,
        AmericanFootballIncidentId.BreakAfterFourthQuarter,
        AmericanFootballIncidentId.FirstExtraTimeStarted,
        AmericanFootballIncidentId.BreakAfterFirstExtraTime,
        AmericanFootballIncidentId.SecondExtraTimeStarted,
        AmericanFootballIncidentId.BreakAfterSecondExtraTime,
        AmericanFootballIncidentId.ThirdExtraTimeStarted,
        AmericanFootballIncidentId.BreakAfterThirdExtraTime,
        AmericanFootballIncidentId.FourthExtraTimeStarted,
        AmericanFootballIncidentId.BreakAfterFourthExtraTime,
        AmericanFootballIncidentId.FifthExtraTimeStarted,
      ]
    })
    .inSingletonScope()
}
