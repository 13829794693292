<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  PropType,
  ref,
  watch,
} from 'vue'

import Multiselect from '@vueform/multiselect'

import { SelectOption } from './types/SelectOption'

type MultiselectRef = InstanceType<typeof Multiselect> & {
  open: () => void
  select: (items: SelectOption[]) => void
  close: () => void
  focus: () => void
}

export default defineComponent({
  components: { Multiselect },
  props: {
    mode: {
      type: String as PropType<'single' | 'multiple' | 'tags'>,
      default: 'single',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: [Object, Function] as PropType<
        SelectOption[] | ((query: string) => Promise<SelectOption[]>)
      >,
      required: true,
    },
    label: {
      type: String,
      default: 'label',
    },
    valueProp: {
      type: String,
      default: 'value',
    },
    value: {
      type: [Array, String, Number] as PropType<
        string[] | string | number[] | number
      >,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Select options',
    },
    trackBy: {
      type: String,
      default: null,
    },
    forceDisabled: {
      type: Boolean,
      default: false,
    },
    delay: {
      type: Number,
      default: -1,
    },
    minChars: {
      type: Number,
      default: 0,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    customLabelSlot: {
      type: Boolean,
      default: false,
    },
    deselectable: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    hideSelected: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: String,
      default: 'text-xxs',
    },
    openedList: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:value', 'searchChange'],
  setup(props, { emit }) {
    const select = ref<MultiselectRef>()
    const childValue = computed({
      get: () => props.value,
      set: (value) => {
        emit('update:value', value)
      },
    })
    const closeOnSelect = computed(() =>
      props.mode === 'multiple' ? false : true,
    )

    function createLabel(values: Array<{ name: string }>): string {
      return values.map((value) => value.name).join(', ')
    }

    function searchChange(value: string): void {
      emit('searchChange', value)
    }

    async function onClear(): Promise<void> {
      if (props.forceDisabled && typeof props.options !== 'function') {
        await nextTick()
        await nextTick()
        select.value?.select(props.options.filter((option) => option.disabled))
      }
    }

    onMounted(() => {
      if (props.openedList) {
        select.value?.open()
        select.value?.focus()
      }
    })

    watch(
      () => props.openedList,
      async () => {
        await nextTick()
        if (props.openedList) {
          select.value?.open()
          select.value?.focus()
        } else {
          select.value?.close()
        }
      },
    )

    return {
      childValue,
      createLabel,
      closeOnSelect,
      onClear,
      select,
      searchChange,
    }
  },
})
</script>

<template>
  <Multiselect
    ref="select"
    v-model="childValue"
    v-bind="{
      mode,
      disabled,
      options,
      label,
      valueProp,
      placeholder,
      hideSelected,
      delay,
      minChars,
      searchable,
      trackBy,
      multipleLabel: createLabel,
      canDeselect: deselectable,
      canClear: clearable,
      closeOnSelect,
    }"
    class="h-full"
    :class="fontSize"
    @searchChange="searchChange"
    @clear="onClear"
  >
    <template
      v-if="searchable"
      #nooptions
    >
      <div class="m-2">
        <small>Type at least 3 characters to search</small>
      </div>
    </template>
    <template
      v-if="customLabelSlot"
      #multiplelabel="{ values }"
    >
      <div
        v-if="Array.isArray(values) && values.length > 1"
        class="multiselect-multiple-label"
      >
        {{ values.length }} options selected
      </div>
      <div
        v-else
        class="multiselect-multiple-label"
      >
        {{ (values as SelectOption[])[0].name }}
      </div>
    </template>
  </Multiselect>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
@import '@mobile/styles/variables.scss';

.multiselect {
  --ms-font-size: 1rem;
  --ms-line-height: 1.375;
  --ms-ring-color: #ffffff;
  --ms-placeholder-color: gray;

  --ms-spinner-color: #0093d8;

  --ms-px: 0.425rem;

  --ms-option-font-size: 0.7rem;
  --ms-option-bg-selected: #0093d8;
  --ms-option-bg-selected-pointed: #0093d8;

  .multiselect-multiple-label {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 40px);
    white-space: nowrap;
  }

  .multiselect-caret {
    @apply z-0;
  }
}
</style>
