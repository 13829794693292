import { SoccerIncidentId } from '@collector/sportsapi-client-legacy'

export const SoccerManualAddIncidentIds = [
  SoccerIncidentId.ShotOnTarget,
  SoccerIncidentId.ShotOffTarget,
  SoccerIncidentId.ShotBlocked,
  SoccerIncidentId.Offside,
  SoccerIncidentId.Foul,
  SoccerIncidentId.ThrowIn,
  SoccerIncidentId.Tackle,
  SoccerIncidentId.Interception,
  SoccerIncidentId.BallSkills,
  SoccerIncidentId.LooseBall,
  SoccerIncidentId.Injury,
]

export const SoccerManualAddIncidentSubstitutionIds = [
  SoccerIncidentId.SubstitutionOut,
  SoccerIncidentId.SubstitutionIn,
]
