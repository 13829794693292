import Dexie from 'dexie'

import { Sport, SportId } from '@collector/sportsapi-client-legacy'
import { Repository } from '@mobile/database/Repository'

export class SportsRepository extends Repository<Sport> {
  static readonly tableName = 'sports'
  static readonly indexes = 'id'

  constructor(database: Dexie) {
    super(database.table<Sport>(SportsRepository.tableName))
  }

  public async getBySportId(sportId: SportId): Promise<Sport | undefined> {
    return this.table.get({ id: sportId })
  }
}
