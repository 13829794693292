<script lang="ts">
import { chain } from 'lodash'
import { computed, defineComponent, PropType } from 'vue'

import {
  ParticipantSubType,
  SubParticipant,
} from '@collector/sportsapi-client-legacy'
import { UpdateSubParticipantDTO } from '@mobile/ActionQueue/Actions/Lineups/UpdateLineups'
import Button from '@mobile/components/Button/Button.vue'
import { useLineupMaximumPlayersReached } from '@mobile/views/Relation/Shared/Lineups/useLineupMaximumPlayersReached'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { AvailablePositionType } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/LineupsConfiguration'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'
import { useEventLineups } from '@mobile/views/Relation/useEventLineups'
import { useSeasonParticipants } from '@mobile/views/Relation/useSeasonParticipants'

export default defineComponent({
  components: { Button },
  props: {
    probableLinkedParticipant: {
      type: Object as PropType<ProbableLinkedParticipant>,
      required: true,
    },
    availableSeasonParticipants: {
      type: Array as PropType<SubParticipant[]>,
      required: true,
    },
    lineups: {
      type: Array as PropType<UpdateSubParticipantDTO[]>,
      required: true,
    },
  },
  emits: ['teamMemberAdded'],
  setup(props, { emit }) {
    const participant = props.probableLinkedParticipant.getParticipant()
    const eventInjections = useEventInjections()
    const { lineupsConfiguration } = useSportConfiguration()
    const { reloadSeasonParticipants } = useSeasonParticipants(participant)
    const { reloadEventLineups } = useEventLineups(
      eventInjections,
      props.probableLinkedParticipant,
    )

    const availablePositionsForCoach = computed(() =>
      lineupsConfiguration?.availablePositions.filter(
        (position) => position.acronym === 'COA',
      ),
    )

    const availablePositionsForPlayer = computed(() =>
      lineupsConfiguration?.availablePositions.filter(
        (position) => position.acronym !== 'COA',
      ),
    )

    function availablePositionsForSubtype(
      subtype: ParticipantSubType,
    ): AvailablePositionType[] {
      if (subtype === ParticipantSubType.Coach) {
        return availablePositionsForCoach.value
      }
      if (subtype === ParticipantSubType.Athlete) {
        return availablePositionsForPlayer.value
      }

      return lineupsConfiguration?.availablePositions
    }

    const seasonParticipants = computed(() => sortSeasonParticipants())

    function sortSeasonParticipants(): SubParticipant[] {
      const subtypeOrder = [
        ParticipantSubType.Athlete,
        ParticipantSubType.AthleteCoach,
        ParticipantSubType.Coach,
      ]

      if (lineupsConfiguration.lineupPositionOrder.length === 0) {
        return props.availableSeasonParticipants
      }

      return chain(props.availableSeasonParticipants)
        .orderBy(sortByShirtNr, ['asc'])
        .sortBy(sortByLineupPosition)
        .sortBy(createSortBySubType(subtypeOrder))
        .value()
    }

    function sortByShirtNr(
      participant: SubParticipant,
    ): number | null | string {
      return participant.shirt_nr
    }

    function createSortBySubType(
      subtypeOrder: string[],
    ): (object: SubParticipant) => number {
      return (object: SubParticipant) =>
        subtypeOrder.indexOf(object.details.subtype)
    }

    function sortByLineupPosition(object: SubParticipant): number | undefined {
      return lineupsConfiguration.lineupPositionOrder.indexOf(
        object.details.position_name ?? '',
      )
    }

    function handleAddTeamMember(
      teamMember: SubParticipant,
      availableType: AvailablePositionType,
    ): void {
      emit('teamMemberAdded', {
        ...teamMember,
        position: availableType.name,
      })
    }

    function reloadSubParticipants(): void {
      reloadSeasonParticipants()
      reloadEventLineups()
    }

    return {
      reloadSubParticipants,
      lineupsConfiguration,
      seasonParticipants,
      availablePositionsForSubtype,
      handleAddTeamMember,
      useLineupMaximumPlayersReached,
    }
  },
})
</script>

<template>
  <div
    class="flex justify-between bg-gradient-to-r px-2 py-1"
    :class="[
      probableLinkedParticipant.css.gradientFrom,
      probableLinkedParticipant.css.gradientTo,
    ]"
  >
    <div class="text-sm font-bold text-white">
      {{ probableLinkedParticipant.getParticipant().value.short_name }} - Season
      Squad
    </div>
    <div>
      <button
        class="rounded-md border border-white px-3 text-sm text-white"
        @click="reloadSubParticipants()"
      >
        Refresh
      </button>
    </div>
  </div>
  <table
    class="my-1 w-full table-auto border-separate border-spacing-x-1 text-left text-sm"
  >
    <thead class="text-sm">
      <tr>
        <th class="text-gray px-2 py-1 font-bold">#</th>
        <th class="text-gray px-2 py-1 font-bold">Player</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(teamMember, index) in seasonParticipants"
        :key="teamMember.id || index"
        class="text-gray bg-white"
      >
        <td class="px-2 py-1 align-middle">
          {{ teamMember.shirt_nr }}
        </td>
        <td class="break-all px-2 py-1 align-middle">
          {{ teamMember.short_name || teamMember.name }}
        </td>
        <td class="flex flex-row justify-end space-x-2 py-1 pr-2 align-middle">
          <Button
            v-for="availableType in availablePositionsForSubtype(
              teamMember.details.subtype,
            )"
            :key="`type-${availableType.acronym}`"
            v-bind="{ ...availableType.button }"
            :disabled="useLineupMaximumPlayersReached(lineups, availableType)"
            :size="'xs'"
            @click="handleAddTeamMember(teamMember, availableType)"
          >
            {{ availableType.acronym }}
          </Button>
        </td>
      </tr>
    </tbody>
  </table>
</template>
