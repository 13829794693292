import {
  Event,
  HandballEventDetailId,
  relationStatuses,
  SportDetailInputType,
} from '@collector/sportsapi-client-legacy'
import { CollectorSport } from '@mobile/reusables/classes/CollectorSport'

import {
  EventPreparationEventDetail,
  EventPreparationEventDetailsSportFactory,
} from './types'
import { getDetailName, getDetailSelectItems } from './utils'

export function eventPreparationEventDetailsHandballFactory(
  event: Event,
  sport: CollectorSport,
): EventPreparationEventDetailsSportFactory {
  function getDetails(): EventPreparationEventDetail[] {
    return [
      {
        name: 'Relation status',
        fieldName: 'relation_status',
        type: SportDetailInputType.Select,
        items: relationStatuses,
      },
      {
        id: HandballEventDetailId.PeriodLength,
        name: getDetailName(sport, HandballEventDetailId.PeriodLength),
        type: SportDetailInputType.Number,
      },
      {
        id: HandballEventDetailId.ExtraPeriodType,
        name: getDetailName(sport, HandballEventDetailId.ExtraPeriodType),
        type: SportDetailInputType.Select,
        items: getDetailSelectItems(
          event,
          sport,
          HandballEventDetailId.ExtraPeriodType,
        ),
      },
      {
        id: HandballEventDetailId.ExtraPeriodLength,
        name: getDetailName(sport, HandballEventDetailId.ExtraPeriodLength),
        type: SportDetailInputType.Number,
      },
    ]
  }

  return { getDetails }
}
