import {
  AmericanFootballIncidentAttributeNames,
  AmericanFootballIncidentId,
  DetailType,
  PassAttribute,
  SackAttribute,
} from '@collector/sportsapi-client-legacy'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import { SportDetailsConfiguration } from '@mobile/views/Relation/DetailsPanel/Sports/composables/useSportOptions/types'

export enum AmericanFootballGroupNames {
  Details = 'Details',
  Yards = 'Yards',
}

const TouchdownAttributes = [
  AmericanFootballIncidentAttributeNames.Passing,
  AmericanFootballIncidentAttributeNames.Rushing,
  AmericanFootballIncidentAttributeNames.InterceptionReturn,
  AmericanFootballIncidentAttributeNames.FumbleRecovery,
  AmericanFootballIncidentAttributeNames.PuntReturn,
  AmericanFootballIncidentAttributeNames.KickoffReturn,
]

const PassAttributes = [
  AmericanFootballIncidentAttributeNames.PassComplete,
  AmericanFootballIncidentAttributeNames.PassIncomplete,
]

const SackAttributes = [
  AmericanFootballIncidentAttributeNames.Assisted,
  AmericanFootballIncidentAttributeNames.NotAssisted,
]

const DefensiveReturnAttributes = [
  AmericanFootballIncidentAttributeNames.Fumble,
  AmericanFootballIncidentAttributeNames.Interception,
  AmericanFootballIncidentAttributeNames.Kickoff,
  AmericanFootballIncidentAttributeNames.Punt,
]

function hasYardsPropertyFulfilled(
  incident: ProbableIncident | undefined,
): boolean {
  return (
    incident?.properties?.find(
      (x) => x.short_name.toLowerCase() === 'yards' && x.value !== null,
    ) !== undefined
  )
}

export const AmericanFootballDetailsConfiguration: SportDetailsConfiguration<AmericanFootballIncidentId> =
  {
    [AmericanFootballIncidentId.Touchdown]: [
      {
        name: AmericanFootballGroupNames.Details,
        attributes: TouchdownAttributes,
        cols: 3,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: (attributes) => attributes.length > 0,
      },
    ],
    [AmericanFootballIncidentId.Sack]: [
      {
        name: AmericanFootballGroupNames.Details,
        attributes: SackAttributes,
        cols: 2,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: (attributes, incident) => {
          if (attributes.length === 0) {
            return false
          }

          if (attributes.includes(SackAttribute.Assisted)) {
            return (
              incident?.participant_id !== null &&
              incident?.assistant_id !== null
            )
          }

          return true
        },
      },
    ],
    [AmericanFootballIncidentId.Pass]: [
      {
        name: AmericanFootballGroupNames.Details,
        attributes: PassAttributes,
        cols: 2,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: (attributes, incident) => {
          if (attributes.length === 0) {
            return false
          }
          const attribute = attributes[0]

          switch (attribute) {
            case PassAttribute.PassComplete: {
              return (
                incident?.participant_id !== null &&
                incident?.properties?.find(
                  (x) =>
                    x.short_name.toLowerCase() === 'yards' && x.value !== null,
                ) !== undefined
              )
            }
            case PassAttribute.PassIncomplete: {
              return true
            }
            default: {
              return false
            }
          }
        },
      },
      {
        name: AmericanFootballGroupNames.Yards,
        important: true,
        type: DetailType.Input,
        fulfilledWhen: (attributes, incident) =>
          attributes.includes(PassAttribute.PassIncomplete)
            ? true
            : hasYardsPropertyFulfilled(incident),
        placeholder: 'Yards  (-99 - 99)',
        min: -99,
        max: 99,
      },
    ],
    [AmericanFootballIncidentId.Rush]: [
      {
        name: AmericanFootballGroupNames.Yards,
        important: true,
        type: DetailType.Input,
        fulfilledWhen: (_, incident) => hasYardsPropertyFulfilled(incident),
        placeholder: 'Yards  (-99 - 99)',
        min: -99,
        max: 99,
      },
    ],
    [AmericanFootballIncidentId.Penalty]: [
      {
        name: AmericanFootballGroupNames.Yards,
        important: true,
        type: DetailType.Input,
        fulfilledWhen: (_, incident) => hasYardsPropertyFulfilled(incident),
        placeholder: 'Yards  (0 - 100)',
        min: 0,
        max: 100,
      },
    ],
    [AmericanFootballIncidentId.Punt]: [
      {
        name: AmericanFootballGroupNames.Yards,
        important: true,
        type: DetailType.Input,
        fulfilledWhen: (_, incident) => hasYardsPropertyFulfilled(incident),
        placeholder: 'Yards (-99 - 100)',
        min: -99,
        max: 100,
      },
    ],
    [AmericanFootballIncidentId.DefensiveReturn]: [
      {
        name: AmericanFootballGroupNames.Details,
        attributes: DefensiveReturnAttributes,
        cols: 4,
        important: true,
        type: DetailType.Button,
        fulfilledWhen: (attributes) => attributes.length > 0,
      },
      {
        name: AmericanFootballGroupNames.Yards,
        important: true,
        type: DetailType.Input,
        fulfilledWhen: (_, incident) => hasYardsPropertyFulfilled(incident),
        placeholder: 'Yards (-99 - 99)',
        min: -99,
        max: 99,
      },
    ],
    [AmericanFootballIncidentId.FirstDown]: [
      {
        name: AmericanFootballGroupNames.Yards,
        important: true,
        type: DetailType.Input,
        fulfilledWhen: (_, incident) => hasYardsPropertyFulfilled(incident),
        placeholder: 'Yards (0 - 100)',
        min: 0,
        max: 100,
      },
    ],
    [AmericanFootballIncidentId.SecondDown]: [
      {
        name: AmericanFootballGroupNames.Yards,
        important: true,
        type: DetailType.Input,
        fulfilledWhen: (_, incident) => hasYardsPropertyFulfilled(incident),
        placeholder: 'Yards (0 - 100)',
        min: 0,
        max: 100,
      },
    ],
    [AmericanFootballIncidentId.ThirdDown]: [
      {
        name: AmericanFootballGroupNames.Yards,
        important: true,
        type: DetailType.Input,
        fulfilledWhen: (_, incident) => hasYardsPropertyFulfilled(incident),
        placeholder: 'Yards (0 - 100)',
        min: 0,
        max: 100,
      },
    ],
    [AmericanFootballIncidentId.FourthDown]: [
      {
        name: AmericanFootballGroupNames.Yards,
        important: true,
        type: DetailType.Input,
        fulfilledWhen: (_, incident) => hasYardsPropertyFulfilled(incident),
        placeholder: 'Yards (0 - 100)',
        min: 0,
        max: 100,
      },
    ],
    [AmericanFootballIncidentId.Possession]: [
      {
        name: AmericanFootballGroupNames.Yards,
        important: true,
        type: DetailType.Input,
        fulfilledWhen: (_, incident) => hasYardsPropertyFulfilled(incident),
        placeholder: 'Yards (0 - 100)',
        min: 0,
        max: 100,
      },
    ],
  }

export const AmericanFootballAttributesConfiguration = {
  [AmericanFootballIncidentId.Touchdown]: [...TouchdownAttributes],
  [AmericanFootballIncidentId.Sack]: [...SackAttributes],
  [AmericanFootballIncidentId.DefensiveReturn]: [...DefensiveReturnAttributes],
  [AmericanFootballIncidentId.Pass]: [...PassAttributes],
}
