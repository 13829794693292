<script lang="ts">
import { computed, defineComponent, ref } from 'vue'

import {
  CommonIncidentId,
  SoccerEventStatusId,
  SoccerIncidentId,
  SoccerStatId,
} from '@collector/sportsapi-client-legacy'
import ParticipantComponent from '@mobile/components/Participant/Participant.vue'
import { eventBus } from '@mobile/globalState/eventBus'
import { SwipeEvent } from '@mobile/reusables/useGestureDirective'
import BetMarketButton from '@mobile/views/Relation/Shared/BetMarketButton/BetMarketButton.vue'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import MainResult from '@mobile/views/Relation/Shared/LiveView/LiveViewHeader/MainResult.vue'
import Stat from '@mobile/views/Relation/Shared/LiveView/LiveViewHeader/Stat.vue'
import { useLiveViewBackground } from '@mobile/views/Relation/Shared/LiveView/useLiveViewBackground'
import PocketIncidents from '@mobile/views/Relation/Shared/PocketIncidents/PocketIncidents.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'
import Pitch from '@mobile/views/Relation/Sports/Soccer/LiveView/Pitch/Pitch.vue'

import PenaltyButton from './PenaltyButton.vue'

export default defineComponent({
  components: {
    BetMarketButton,
    ParticipantComponent,
    MainResult,
    Stat,
    IncidentButton,
    PocketIncidents,
    Pitch,
    PenaltyButton,
  },
  setup() {
    const {
      probableFirstParticipant,
      probableLinkedFirstParticipant,
      probableSecondParticipant,
      probableLinkedSecondParticipant,
      probableEvent,
    } = useEventInjections()
    const isPocketIncidentsMenuExpanded = ref(false)
    const { eventHeaderConfiguration } = useSportConfiguration()
    const liveViewBackground = useLiveViewBackground(
      probableEvent,
      eventHeaderConfiguration,
    )
    const isPenaltyShootout = computed(
      () =>
        probableEvent.value.status_id === SoccerEventStatusId.PenaltyShootout,
    )

    function handleSwipeLeft(): void {
      eventBus.emit('SoccerLiveViewSwipe', { event: SwipeEvent.SwipeLeft })
    }

    function handleSwipeRight(): void {
      eventBus.emit('SoccerLiveViewSwipe', { event: SwipeEvent.SwipeRight })
    }

    function expandPocketIncidentsMenu(): void {
      isPocketIncidentsMenuExpanded.value = true
    }

    return {
      SoccerStatId,
      SoccerIncidentId,
      CommonIncidentId,
      probableEvent,
      liveViewBackground,
      probableFirstParticipant,
      probableLinkedFirstParticipant,
      probableSecondParticipant,
      probableLinkedSecondParticipant,
      isPocketIncidentsMenuExpanded,
      expandPocketIncidentsMenu,
      handleSwipeLeft,
      handleSwipeRight,
      isPenaltyShootout,
    }
  },
})
</script>

<template>
  <div
    class="relative h-full overflow-hidden pt-1"
    :class="liveViewBackground"
  >
    <div class="flex h-full max-h-full flex-col space-y-1 overflow-hidden">
      <div class="flex items-center space-x-2 px-2">
        <div
          class="flex h-8 w-32 items-center justify-start md:w-36 lg:w-48 xl:w-56"
        >
          <ParticipantComponent :participant="probableFirstParticipant" />
        </div>
        <div class="flex h-8 flex-1 flex-row items-center justify-between">
          <MainResult
            :probableLinkedParticipant="probableLinkedFirstParticipant"
          />

          <Stat :statId="SoccerStatId.RedCards" />
          <Stat :statId="SoccerStatId.YellowCards" />
          <Stat :statId="SoccerStatId.Corners" />

          <MainResult
            :probableLinkedParticipant="probableLinkedSecondParticipant"
          />
        </div>
        <div
          class="flex h-8 w-32 flex-row items-center justify-end md:w-36 lg:w-48 xl:w-56"
        >
          <ParticipantComponent
            :participant="probableSecondParticipant"
            :reverse="true"
          />
        </div>
      </div>

      <div class="flex h-0 max-h-full flex-1 flex-row space-x-2 px-2 pb-5">
        <div
          class="flex w-32 flex-col items-center justify-center space-y-3 py-3 md:w-36 lg:w-48 xl:w-56"
        >
          <IncidentButton
            class="w-full flex-grow"
            :type="'horizontal'"
            :size="'xs'"
            :color="'white'"
            :incidentId="SoccerIncidentId.YellowCard"
          />
          <IncidentButton
            class="w-full flex-grow"
            :type="'horizontal'"
            :size="'xs'"
            :color="'white'"
            :incidentId="SoccerIncidentId.RedCard"
          />
          <BetMarketButton
            class="w-full flex-grow"
            :size="'xs'"
            :withIcon="true"
            :openMarketsIncident="CommonIncidentId.Betstart"
            :closeMarketsIncident="CommonIncidentId.Betstop"
            marketName="bet_status"
          />
        </div>

        <Pitch
          v-gesture:swipeleft="handleSwipeLeft"
          v-gesture:swiperight="handleSwipeRight"
          v-gesture:swipeup="expandPocketIncidentsMenu"
          class="flex-1"
        />

        <div
          class="flex w-32 flex-col items-center justify-center space-y-3 py-3 md:w-36 lg:w-48 xl:w-56"
        >
          <IncidentButton
            class="w-full flex-grow"
            :type="'horizontal'"
            :size="'xs'"
            :color="'white'"
            :incidentId="SoccerIncidentId.Corner"
          />
          <IncidentButton
            class="w-full flex-grow"
            :type="'horizontal'"
            :size="'xs'"
            :color="'white'"
            :incidentId="SoccerIncidentId.Goal"
          />
          <PenaltyButton />
        </div>
      </div>

      <PocketIncidents
        :isExpanded="isPocketIncidentsMenuExpanded"
        :incidentIds="[
          SoccerIncidentId.SubstitutionOut,
          SoccerIncidentId.Injury,
          SoccerIncidentId.VideoAssistantReferee,
          SoccerIncidentId.AddedTime,
          CommonIncidentId.TechnicalProblems,
          SoccerIncidentId.BreakDuringTheGame,
          SoccerIncidentId.WaterBreak,
          CommonIncidentId.MatchResumed,
          SoccerIncidentId.PenaltySavedByGoalkeeper,
          SoccerIncidentId.MissedPenalty,
        ]"
        @toggle="isPocketIncidentsMenuExpanded = !isPocketIncidentsMenuExpanded"
        @incidentAddedToQueue="isPocketIncidentsMenuExpanded = false"
      />
    </div>
  </div>
</template>
