<script lang="ts">
import { cloneDeep, includes } from 'lodash'
import { computed, defineComponent, PropType, ref } from 'vue'

import {
  CricketEventDetailId,
  CricketIncidentId,
} from '@collector/sportsapi-client-legacy'
import { UpdateEvent } from '@mobile/ActionQueue/Actions/Actions'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import Button from '@mobile/components/Button/Button.vue'
import InputText from '@mobile/components/InputText/InputText.vue'
import { byId } from '@mobile/reusables/entityUtils'
import {
  resolveUpdateEventDetailsObjectFromEventDetailsDiff,
  resolveUpdateEventObjectFromEventDiff,
} from '@mobile/reusables/eventUtils'
import PopupControls from '@mobile/views/Relation/Shared/Popup/PopupControls.vue'
import PopupTitle from '@mobile/views/Relation/Shared/Popup/PopupTitle.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: {
    PopupControls,
    PopupTitle,
    Button,
    InputText,
  },
  props: {
    incident: {
      type: Object as PropType<ProbableIncident>,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { probableIncidents, probableEvent, actionQueue, sport } =
      useEventInjections()
    const preparationEventDetailsData = ref(cloneDeep(probableEvent.value))
    const incidents = computed(() => {
      return probableIncidents.value.filter((incident) => {
        return includes(
          [
            CricketIncidentId.FirstInningsHomeStarted,
            CricketIncidentId.FirstInningsAwayStarted,
          ],
          incident.incident_id,
        )
      })
    })

    const isSecondTeam = incidents.value.length > 1
    const mappedDetails = [
      {
        id: CricketEventDetailId.RaisedTarget,
        name: 'Revised target',
        display: isSecondTeam,
      },
      {
        id: CricketEventDetailId.RaisedOvers,
        name: 'Revised overs',
        display: true,
      },
      {
        id: CricketEventDetailId.MaximumOversPerBowler,
        name: 'Maximum number of overs per bowler',
        display: true,
      },
    ]

    function getCurrentEventDetailValue(detailId: number): string {
      return String(byId(probableEvent.value.details, detailId)?.value ?? '')
    }

    function updateEventDetail(detailId: number, newValue: string): void {
      const eventDetail = byId(
        preparationEventDetailsData.value.details,
        detailId,
      )

      if (eventDetail) {
        eventDetail.value = newValue
      }
    }

    function saveDetails(): void {
      actionQueue.value.add(
        new UpdateEvent({
          details: resolveUpdateEventDetailsObjectFromEventDetailsDiff(
            cloneDeep(probableEvent.value.details),
            preparationEventDetailsData.value.details,
            sport.value.sportsAPIEntity,
          ),
          event: resolveUpdateEventObjectFromEventDiff(
            cloneDeep(probableEvent.value),
            preparationEventDetailsData.value,
          ),
        }),
      )

      emit('close')
    }

    return {
      mappedDetails,
      getCurrentEventDetailValue,
      updateEventDetail,
      saveDetails,
    }
  },
})
</script>

<template>
  <div class="bg-silver px-3 pt-3">
    <PopupTitle>
      <template #title>Duckworth-Lewis method</template>
    </PopupTitle>
  </div>
  <div class="flex w-full">
    <div class="min-w-full px-4 pb-0 pt-4">
      <div class="h-full pb-3">
        <div
          v-for="(detail, index) in mappedDetails"
          :key="`detail-${index}`"
        >
          <div
            v-if="detail.display"
            class="flex w-full items-center pb-3"
          >
            <div class="text-gray w-1/4 text-left text-xs font-bold">
              {{ detail.name }}
            </div>
            <div class="w-3/4">
              <InputText
                :value="getCurrentEventDetailValue(Number(detail.id))"
                @update:value="updateEventDetail(Number(detail.id), $event)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <PopupControls>
    <template #buttons>
      <div class="flex justify-end space-x-2">
        <Button
          :color="'red'"
          :size="'lg'"
          @click="$emit('close')"
        >
          Cancel
        </Button>
        <Button
          :color="'green'"
          :size="'lg'"
          @click="saveDetails()"
        >
          Confirm
        </Button>
      </div>
    </template>
  </PopupControls>
</template>
