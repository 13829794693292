import { computed, ComputedRef, Ref, shallowRef } from 'vue'

import {
  Event,
  IncidentsQueuePatchDTO,
  IncidentsQueuePostDTO,
} from '@collector/sportsapi-client-legacy'
import AddIncident from '@mobile/ActionQueue/Actions/Incidents/AddIncident'
import DeleteIncident from '@mobile/ActionQueue/Actions/Incidents/DeleteIncident'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import UpdateIncident from '@mobile/ActionQueue/Actions/Incidents/UpdateIncident'
import { resolveProbableIncident } from '@mobile/ActionQueue/ActionUtils'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { EventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

export type IncidentStorePayload = Omit<IncidentsQueuePostDTO, 'status_id'>

export type IncidentActionComposable = ReturnType<typeof useIncidentAction>

/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
export function useIncidentAction(
  eventInjections: EventInjections,
  incidentData: IncidentStorePayload,
) {
  const {
    probableClock,
    probableEvent,
    actionQueue,
    notDeletableProbableIncidents,
    getProbableLinkedParticipantById,
  } = eventInjections
  const { incidentsConfiguration } = useSportConfiguration()

  const addAction = new AddIncident({
    ...incidentData,
    minute: probableClock.value.minute,
    second: probableClock.value.second,
    status_id: probableEvent.value.status_id,
  })
  const lastAction: Ref<AddIncident | UpdateIncident> = shallowRef(addAction)

  actionQueue.value.add(lastAction.value)

  function updateIncident(incidentData: Partial<ProbableIncident>): void {
    const data: IncidentsQueuePatchDTO = transformIncidentToPatchDTO(
      probableEvent.value,
      incidentData,
      addAction,
    )
    const incidentId = lastAction.value.incidentId || lastAction.value.id

    lastAction.value = new UpdateIncident(incidentId, {
      ...data,
      ...{
        minute: data.minute || addAction.incidentsQueuePostDTO.minute,
        second: data.second || addAction.incidentsQueuePostDTO.second,
      },
    })

    actionQueue.value.add(lastAction.value)
  }

  function deleteIncident(): void {
    const actionOrIncidentId = addAction.incidentId || addAction.id
    const deleteAction = new DeleteIncident(actionOrIncidentId)

    actionQueue.value.add(deleteAction)
  }

  function getProbableLinkedParticipant():
    | ProbableLinkedParticipant
    | undefined {
    return getProbableLinkedParticipantById(
      Number(addAction.incidentsQueuePostDTO.participant_team_id),
    )
  }

  function getProbableIncident(): ComputedRef<ProbableIncident> {
    return computed(() => {
      const actionIncident = resolveProbableIncident(
        lastAction.value,
        actionQueue.value,
        notDeletableProbableIncidents.value,
        incidentsConfiguration,
      )

      if (!actionIncident) {
        throw Error('Incident not found')
      }

      return actionIncident
    })
  }

  return {
    updateIncident,
    deleteIncident,
    getProbableLinkedParticipant,
    getProbableIncident,
  }
}

function transformIncidentToPatchDTO(
  probableEvent: Event,
  probableIncident: Partial<ProbableIncident>,
  action: AddIncident,
): IncidentsQueuePatchDTO {
  const patchData: IncidentsQueuePatchDTO = {
    participant_team_id: probableIncident.participant_team_id,
    confirmation: probableIncident.confirmation,
    incident_id: action.incidentsQueuePostDTO.incident_id,
    status_id: action.incidentsQueuePostDTO.status_id,
    event_id: probableEvent.id,
  }
  if (probableIncident.event_time) {
    const [minute, second] = probableIncident.event_time.split(':')
    patchData.minute = Number(minute)
    patchData.second = Number(second)
  }

  if (probableIncident.participant_id) {
    patchData.participant_team_id = probableIncident.participant_id
  }

  if (probableIncident.participant_name) {
    patchData.participant_name = probableIncident.participant_name
  }

  if (probableIncident.subparticipant_id) {
    patchData.participant_id = probableIncident.subparticipant_id
  }

  if (probableIncident.subparticipant_name) {
    patchData.subparticipant_name = probableIncident.subparticipant_name
  }

  if (probableIncident.assistant_id) {
    patchData.assistant_id = probableIncident.assistant_id
  }

  if (probableIncident.assistant_name) {
    patchData.assistant_name = probableIncident.assistant_name
  }

  if (probableIncident.secondary_assistant_id) {
    patchData.secondary_assistant_id = probableIncident.secondary_assistant_id
  }

  if (probableIncident.secondary_assistant_name) {
    patchData.secondary_assistant_name =
      probableIncident.secondary_assistant_name
  }

  if (probableIncident.x_pos && probableIncident.y_pos) {
    patchData.x_pos = probableIncident.x_pos
    patchData.y_pos = probableIncident.y_pos
  }

  if (probableIncident.properties && probableIncident.properties) {
    patchData.properties = probableIncident.properties
  }

  if (probableIncident.attribute_ids) {
    patchData.attribute_ids = probableIncident.attribute_ids
  }

  return patchData
}
