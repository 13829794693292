import { Table } from 'dexie'
import EventEmitter from 'eventemitter3'

import { DeepPartial } from '@mobile/reusables/types/DeepPartial'

export interface RepositoryEmitter<T> {
  created: (entity: T) => void
  deleted: (entity: T) => void
  updated: (entity: T) => void
  anything: (entity: T) => void
}

export abstract class Repository<T> extends EventEmitter<RepositoryEmitter<T>> {
  public readonly table: Table<T>

  constructor(table: Table<T>) {
    super()

    this.table = table
    this.initHooks()
  }

  private initHooks(): void {
    const table = this.table
    /* eslint-disable-next-line @typescript-eslint/no-this-alias */
    const that = this

    table.hook('creating', function () {
      this.onsuccess = async (primKey): Promise<void> => {
        const entity = await table.get(primKey)
        if (entity) {
          that.emit('created', entity)
          that.emit('anything', entity)
        }
      }
    })

    table.hook('updating', function (changes, primKey) {
      this.onsuccess = async (): Promise<void> => {
        const entity = await table.get(primKey)
        if (entity) {
          that.emit('updated', entity)
          that.emit('anything', entity)
        }
      }
    })

    table.hook('deleting', function (primKey, entity) {
      this.onsuccess = async (): Promise<void> => {
        if (entity) {
          that.emit('deleted', entity)
          that.emit('anything', entity)
        }
      }
    })
  }

  public async getAll(): Promise<T[]> {
    return await this.table.toArray()
  }

  public async put(entity: T): Promise<void> {
    await this.table.put(entity)
  }

  public async update(
    id: string | number,
    changes: DeepPartial<T>,
  ): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TODO: fix
    await this.table.update(id, changes)
  }

  public async clear(): Promise<void> {
    await this.table.clear()
  }

  public async add(entity: T): Promise<void> {
    await this.table.add(entity)
  }
}
