<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({})
</script>

<template>
  <hr class="text-lightgray my-4" />
  <div class="flex justify-end">
    <slot></slot>
  </div>
</template>
