import { Container } from 'inversify'
import { cloneDeep } from 'lodash'
import { computed, Ref, ref, watchEffect } from 'vue'

import {
  cloneIncident,
  CollectorPushMessageEvent,
  CollectorPushMessageIncident,
  EventsLineupsParticipant,
  Incident,
  SubParticipantPosition,
} from '@collector/sportsapi-client-legacy'
import { ActionQueue } from '@mobile/ActionQueue/ActionQueue'
import IncidentId from '@mobile/ActionQueue/Actions/Incidents/IncidentId'
import {
  isIncidentDeletable,
  ProbableIncident,
} from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import {
  applyActionsResult,
  applyIncidentActionsResult,
} from '@mobile/ActionQueue/ActionUtils'
import { getAwayTeam, getHomeTeam } from '@mobile/reusables/entityUtils'
import { EventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

import { IncidentsConfiguration } from '../RelationSportCommonDependencies/IncidentsConfiguration'
import { RelationIoCBindings } from './types'

export function bindProbables(ioc: Container): void {
  let unwatchProbables: () => void

  ioc
    .bind(RelationIoCBindings.Probables)
    .toDynamicValue(async (ctx) => {
      const [event, homeLineups, awayLineups, incidents, actionQueue, sport] =
        await Promise.all([
          ctx.container.getAsync(RelationIoCBindings.FactualEvent),
          ctx.container.getAsync(RelationIoCBindings.FactualHomeLineups),
          ctx.container.getAsync(RelationIoCBindings.FactualAwayLineups),
          ctx.container.getAsync(RelationIoCBindings.FactualIncidents),
          ctx.container.getAsync(RelationIoCBindings.ActionQueue),
          ctx.container.getAsync(RelationIoCBindings.Sport),
        ])

      const incidentsConfiguration = await ctx.container.getAsync(
        RelationSportCommonIoCBindings.IncidentsConfiguration,
      )

      const {
        probableEvent,
        probableHomeLineups,
        probableAwayLineups,
        probableIncidents,
        probableNotDeletableIncidents,
        probableCurrentIncidents,
        probableSelectableHomeLineups,
        probableSelectableAwayLineups,
      } = resolveProbables(
        event,
        homeLineups,
        awayLineups,
        incidents,
        actionQueue,
        incidentsConfiguration,
        sport,
      )

      const probableEventRef = ref(probableEvent)
      const probableHomeLineupsRef = ref(probableHomeLineups)
      const probableAwayLineupsRef = ref(probableAwayLineups)
      const probableIncidentsRef = ref(probableIncidents)
      const probableNotDeletableIncidentsRef = ref(
        probableNotDeletableIncidents,
      )
      const probableCurrentIncidentsRef = ref(probableCurrentIncidents)
      const probableSelectableHomeLineupsRef = ref(
        probableSelectableHomeLineups,
      )
      const probableSelectableAwayLineupsRef = ref(
        probableSelectableAwayLineups,
      )

      return {
        probableEvent: probableEventRef,
        probableHomeLineups: probableHomeLineupsRef,
        probableAwayLineups: probableAwayLineupsRef,
        probableSelectableHomeLineups: probableSelectableHomeLineupsRef,
        probableSelectableAwayLineups: probableSelectableAwayLineupsRef,
        probableIncidents: probableIncidentsRef,
        probableNotDeletableIncidents: probableNotDeletableIncidentsRef,
        probableCurrentIncidents: probableCurrentIncidentsRef,
        probableHomeParticipant: computed(() => {
          const homeTeam = getHomeTeam(probableEventRef.value.participants)
          if (!homeTeam) {
            throw new Error("Couldn't get home team")
          }

          return homeTeam
        }),
        probableAwayParticipant: computed(() => {
          const awayTeam = getAwayTeam(probableEventRef.value.participants)
          if (!awayTeam) {
            throw new Error("Couldn't get away team")
          }

          return awayTeam
        }),
      }
    })
    .inSingletonScope()
    .onActivation(async (ctx, probables) => {
      const [event, homeLineups, awayLineups, incidents, actionQueue, sport] =
        await Promise.all([
          ctx.container.getAsync(RelationIoCBindings.FactualEvent),
          ctx.container.getAsync(RelationIoCBindings.FactualHomeLineups),
          ctx.container.getAsync(RelationIoCBindings.FactualAwayLineups),
          ctx.container.getAsync(RelationIoCBindings.FactualIncidents),
          ctx.container.getAsync(RelationIoCBindings.ActionQueue),
          ctx.container.getAsync(RelationIoCBindings.Sport),
        ])

      const incidentsConfiguration = await ctx.container.getAsync(
        RelationSportCommonIoCBindings.IncidentsConfiguration,
      )

      unwatchProbables = watchEffect(async () => {
        const {
          probableEvent,
          probableHomeLineups,
          probableAwayLineups,
          probableIncidents,
          probableNotDeletableIncidents,
          probableCurrentIncidents,
          probableSelectableHomeLineups,
          probableSelectableAwayLineups,
        } = resolveProbables(
          event,
          homeLineups,
          awayLineups,
          incidents,
          actionQueue,
          incidentsConfiguration,
          sport,
        )

        probables.probableEvent.value = probableEvent
        probables.probableHomeLineups.value = probableHomeLineups
        probables.probableAwayLineups.value = probableAwayLineups
        probables.probableIncidents.value = probableIncidents
        probables.probableNotDeletableIncidents.value =
          probableNotDeletableIncidents
        probables.probableCurrentIncidents.value = probableCurrentIncidents
        probables.probableSelectableHomeLineups.value =
          probableSelectableHomeLineups
        probables.probableSelectableAwayLineups.value =
          probableSelectableAwayLineups
      })

      return probables
    })
    .when(() => true)
    .onDeactivation(async () => {
      unwatchProbables()
    })
    .when(() => true)

  ioc.bind(RelationIoCBindings.ProbableEvent).toDynamicValue(async (ctx) => {
    const probables = await ctx.container.getAsync(
      RelationIoCBindings.Probables,
    )

    return probables.probableEvent
  })

  ioc
    .bind(RelationIoCBindings.ProbableHomeLineups)
    .toDynamicValue(async (ctx) => {
      const probables = await ctx.container.getAsync(
        RelationIoCBindings.Probables,
      )

      return probables.probableHomeLineups
    })

  ioc
    .bind(RelationIoCBindings.ProbableAwayLineups)
    .toDynamicValue(async (ctx) => {
      const probables = await ctx.container.getAsync(
        RelationIoCBindings.Probables,
      )

      return probables.probableAwayLineups
    })

  ioc
    .bind(RelationIoCBindings.ProbableSelectableHomeLineups)
    .toDynamicValue(async (ctx) => {
      const probables = await ctx.container.getAsync(
        RelationIoCBindings.Probables,
      )

      return probables.probableSelectableHomeLineups
    })

  ioc
    .bind(RelationIoCBindings.ProbableSelectableAwayLineups)
    .toDynamicValue(async (ctx) => {
      const probables = await ctx.container.getAsync(
        RelationIoCBindings.Probables,
      )

      return probables.probableSelectableAwayLineups
    })

  ioc
    .bind(RelationIoCBindings.ProbableIncidents)
    .toDynamicValue(async (ctx) => {
      const probables = await ctx.container.getAsync(
        RelationIoCBindings.Probables,
      )

      return probables.probableIncidents
    })

  ioc
    .bind(RelationIoCBindings.ProbableNotDeletableIncidents)
    .toDynamicValue(async (ctx) => {
      const probables = await ctx.container.getAsync(
        RelationIoCBindings.Probables,
      )

      return probables.probableNotDeletableIncidents
    })

  ioc
    .bind(RelationIoCBindings.ProbableCurrentIncidents)
    .toDynamicValue(async (ctx) => {
      const probables = await ctx.container.getAsync(
        RelationIoCBindings.Probables,
      )

      return probables.probableCurrentIncidents
    })

  ioc
    .bind(RelationIoCBindings.ProbableHomeParticipant)
    .toDynamicValue(async (ctx) => {
      const probables = await ctx.container.getAsync(
        RelationIoCBindings.Probables,
      )

      return probables.probableHomeParticipant
    })

  ioc
    .bind(RelationIoCBindings.ProbableAwayParticipant)
    .toDynamicValue(async (ctx) => {
      const probables = await ctx.container.getAsync(
        RelationIoCBindings.Probables,
      )

      return probables.probableAwayParticipant
    })
}

function resolveProbables(
  event: Ref<CollectorPushMessageEvent>,
  homeLineups: Ref<EventsLineupsParticipant[]>,
  awayLineups: Ref<EventsLineupsParticipant[]>,
  incidents: Ref<CollectorPushMessageIncident[]>,
  actionQueue: Ref<ActionQueue>,
  incidentsConfiguration: IncidentsConfiguration,
  sport: EventInjections['sport'],
): {
  probableEvent: CollectorPushMessageEvent['data']
  probableHomeLineups: EventsLineupsParticipant[]
  probableAwayLineups: EventsLineupsParticipant[]
  probableIncidents: Incident[]
  probableNotDeletableIncidents: Incident[]
  probableCurrentIncidents: Incident[]
  probableSelectableHomeLineups: EventsLineupsParticipant[]
  probableSelectableAwayLineups: EventsLineupsParticipant[]
} {
  const probableEvent = cloneDeep(event.value.data)
  const probableHomeLineups = cloneDeep(homeLineups.value)
  const probableAwayLineups = cloneDeep(awayLineups.value)
  const probableIncidents = incidents.value.map((incidentMessage) =>
    cloneIncident(incidentMessage.data),
  )
  applyActionsResult(
    probableEvent,
    probableIncidents,
    probableHomeLineups,
    probableAwayLineups,
    actionQueue.value,
    incidentsConfiguration,
  )
  sortIncidents(probableIncidents)

  const probableNotDeletableIncidents: Incident[] = incidents.value.map(
    (incidentMessage) => cloneIncident(incidentMessage.data),
  )
  applyIncidentActionsResult(
    probableNotDeletableIncidents,
    actionQueue.value,
    true,
    incidentsConfiguration,
  )
  sortIncidents(probableNotDeletableIncidents)

  const probableSelectableHomeLineups = probableHomeLineups.filter(
    ({ participant_position }) =>
      participant_position !== SubParticipantPosition.WillNotPlay,
  )
  const probableSelectableAwayLineups = probableAwayLineups.filter(
    ({ participant_position }) =>
      participant_position !== SubParticipantPosition.WillNotPlay,
  )

  const probableCurrentIncidents = probableIncidents.filter(
    (probableIncident) => isIncidentDeletable(probableIncident, sport),
  )

  return {
    probableEvent,
    probableHomeLineups,
    probableAwayLineups,
    probableIncidents,
    probableNotDeletableIncidents,
    probableCurrentIncidents,
    probableSelectableHomeLineups,
    probableSelectableAwayLineups,
  }
}

function sortIncidents(incidents: ProbableIncident[]): ProbableIncident[] {
  // @TODO - sort it using game parts and incident ids
  return incidents.sort(
    (a, b) =>
      IncidentId.fromPushMessageFormat(b.id) -
      IncidentId.fromPushMessageFormat(a.id),
  )
}
