export enum HandballIncidentId {
  NotStarted = 271,
  StartDelayed = 282,
  MatchAboutToStart = 2700,
  FirstHalfStarted = 273,
  Halftime = 1913,
  SecondHalfStarted = 274,
  WaitingForExtratime = 345,
  FirstExtratimeFirstHalfStarted = 275,
  ExtratimeHalftime = 346,
  FirstExtratimeSecondHalfStarted = 276,
  WaitingForSecondExtratime = 1957,
  SecondExtratimeFirstHalfStarted = 1901,
  SecondExtratimeSecondHalfStarted = 1902,
  SecondExtratimeHalftime = 1908,
  WaitingForPenalties = 1958,
  PenaltyShootoutStarted = 277,
  FinishedRegularTime = 281,
  FinishedAfterExtratime = 278,
  FinishedAfterPenalties = 279,
  FinishedAwardedWin = 280,
  Interrupted = 284,
  Postponed = 283,
  Abandoned = 285,
  Cancelled = 340,
  ToFinish = 344,
  RefereeInjury = 1910,
  BreakDuringGame = 1911,
  RefereeConsultation = 1912,
  InPossession = 250,
  Goal = 251,
  SuspensionOver = 2701,
  StartWithPossession = 2702,
  TimeoutOver = 2703,
  MissedThrow = 252,
  ThrowSaved = 253,
  Woodwork = 255,
  ShotBlocked = 1953,
  Foul = 258,
  OffensiveFoul = 259,
  Penalty = 262,
  PenaltyGoal = 264,
  PenaltySaved = 2257,
  MissedPenalty = 263,
  GoalDisallowed = 266,
  PassivePlay = 256,
  Injury = 257,
  Timeout = 265,
  Turnover = 1952,
  SteppingTheLine = 1954,
  Travelling = 270,
  DefenceInGoalArea = 1955,
  YellowCard = 260,
  RedCard = 261,
  BlueCard = 1951,
  TwoMinuteSuspension = 267,
  PlayersInPlay = 1960,
  SixPlayersInPlay = 1961,
  FivePlayersInPlay = 1962,
  FourPlayersInPlay = 1963,
  EmptyNet = 1964,
  GoalKeeperBack = 1965,
  FastAttack = 2612,
}
