import { Container } from 'inversify'
import { computed } from 'vue'

import {
  CommonEventDetailId,
  IceHockeyEventStatusId,
} from '@collector/sportsapi-client-legacy'
import {
  getDetailValue,
  getNumericDetailValue,
} from '@mobile/reusables/eventUtils'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindEventStatusesConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.EventStatusesConfiguration)
    .toDynamicValue(async (ctx) => {
      const [probableEvent] = await Promise.all([
        ctx.container.getAsync(RelationIoCBindings.ProbableEvent),
      ])

      const periodLength = computed(
        () =>
          getNumericDetailValue(
            probableEvent.value,
            CommonEventDetailId.PeriodLength,
            10,
          ) * 60,
      )
      const periodLengthInMinutes = computed(() =>
        getNumericDetailValue(
          probableEvent.value,
          CommonEventDetailId.PeriodLength,
          10,
        ),
      )
      const extraPeriodLength = computed(
        () =>
          getNumericDetailValue(
            probableEvent.value,
            CommonEventDetailId.ExtraPeriodLength,
            5,
          ) * 60,
      )
      const extraPeriodLengthInMinutes = computed(() =>
        getNumericDetailValue(
          probableEvent.value,
          CommonEventDetailId.ExtraPeriodLength,
          5,
        ),
      )
      const clockDirection = computed(() =>
        getDetailValue(probableEvent.value, CommonEventDetailId.TimerDirection),
      )
      const startRegularPeriodTime =
        clockDirection.value === 'down' ? periodLength.value : 0
      const startExtraPeriodTime =
        clockDirection.value === 'down' ? extraPeriodLength.value : 0

      return {
        [IceHockeyEventStatusId.NotStarted]: {
          time: { minute: 0, second: 0 },
          clockHidden: true,
        },
        [IceHockeyEventStatusId.Interrupted]: {
          time: { minute: 0, second: 0 },
        },
        [IceHockeyEventStatusId.Cancelled]: {
          time: { minute: 0, second: 0 },
          clockHidden: true,
        },
        [IceHockeyEventStatusId.Postponed]: { time: { minute: 0, second: 0 } },
        [IceHockeyEventStatusId.StartDelayed]: {
          time: { minute: 0, second: 0 },
          clockHidden: true,
        },
        [IceHockeyEventStatusId.FirstPeriod]: {
          time: { minute: 0, second: 0 },
          timeBoundaryInSeconds: periodLength.value,
          clockDirectionDown: clockDirection.value === 'down',
          startTimeFromInSeconds: startRegularPeriodTime,
        },
        [IceHockeyEventStatusId.BreakAfterFirstPeriod]: {
          time: {
            minute: periodLengthInMinutes.value,
            second: 0,
          },
          timeBoundaryInSeconds: periodLength.value * 2,
          clockDirectionDown: clockDirection.value === 'down',
          startTimeFromInSeconds: startRegularPeriodTime,
          clockHidden: true,
        },
        [IceHockeyEventStatusId.SecondPeriod]: {
          time: {
            minute: periodLengthInMinutes.value,
            second: 0,
          },
          timeBoundaryInSeconds: periodLength.value * 2,
          clockDirectionDown: clockDirection.value === 'down',
          startTimeFromInSeconds: startRegularPeriodTime * 2,
        },
        [IceHockeyEventStatusId.BreakAfterSecondPeriod]: {
          time: {
            minute: 2 * periodLengthInMinutes.value,
            second: 0,
          },
          clockDirectionDown: clockDirection.value === 'down',
          startTimeFromInSeconds: startRegularPeriodTime,
          clockHidden: true,
        },
        [IceHockeyEventStatusId.ThirdPeriod]: {
          time: {
            minute: 2 * periodLengthInMinutes.value,
            second: 0,
          },
          timeBoundaryInSeconds: periodLength.value * 3,
          clockDirectionDown: clockDirection.value === 'down',
          startTimeFromInSeconds: startRegularPeriodTime * 3,
        },
        [IceHockeyEventStatusId.WaitingForOvertime]: {
          time: {
            minute: 2 * periodLengthInMinutes.value,
            second: 0,
          },
          timeBoundaryInSeconds:
            periodLength.value * 2 + extraPeriodLength.value,
          clockDirectionDown: clockDirection.value === 'down',
          startTimeFromInSeconds:
            startRegularPeriodTime * 2 + startExtraPeriodTime,
          clockHidden: true,
        },
        [IceHockeyEventStatusId.FirstOvertime]: {
          time: {
            minute: 2 * periodLengthInMinutes.value,
            second: 0,
          },
          timeBoundaryInSeconds:
            periodLength.value * 2 + extraPeriodLength.value,
          clockDirectionDown: clockDirection.value === 'down',
          startTimeFromInSeconds:
            startRegularPeriodTime * 2 + startExtraPeriodTime,
        },
        [IceHockeyEventStatusId.BreakAfterFirstOvertime]: {
          time: {
            minute:
              2 * periodLengthInMinutes.value +
              extraPeriodLengthInMinutes.value,
            second: 0,
          },
          timeBoundaryInSeconds:
            periodLength.value * 2 + extraPeriodLength.value * 2,
          clockDirectionDown: clockDirection.value === 'down',
          startTimeFromInSeconds: startRegularPeriodTime + startExtraPeriodTime,
          clockHidden: true,
        },
        [IceHockeyEventStatusId.SecondOvertime]: {
          time: {
            minute:
              2 * periodLengthInMinutes.value +
              extraPeriodLengthInMinutes.value,
            second: 0,
          },
          timeBoundaryInSeconds:
            periodLength.value * 2 + extraPeriodLength.value * 2,
          clockDirectionDown: clockDirection.value === 'down',
          startTimeFromInSeconds:
            startRegularPeriodTime * 2 + startExtraPeriodTime * 2,
        },
        [IceHockeyEventStatusId.BreakAfterSecondOvertime]: {
          time: {
            minute:
              4 * periodLengthInMinutes.value +
              2 * extraPeriodLengthInMinutes.value,
            second: 0,
          },
          timeBoundaryInSeconds:
            periodLength.value * 4 + extraPeriodLength.value * 3,
          clockDirectionDown: clockDirection.value === 'down',
          startTimeFromInSeconds: startRegularPeriodTime + startExtraPeriodTime,
          clockHidden: true,
        },
        [IceHockeyEventStatusId.ThirdOvertime]: {
          time: {
            minute:
              2 * periodLengthInMinutes.value +
              2 * extraPeriodLengthInMinutes.value,
            second: 0,
          },
          timeBoundaryInSeconds:
            periodLength.value * 2 + extraPeriodLength.value * 3,
          clockDirectionDown: clockDirection.value === 'down',
          startTimeFromInSeconds:
            startRegularPeriodTime * 2 + startExtraPeriodTime * 3,
        },
        [IceHockeyEventStatusId.BreakAfterThirdOvertime]: {
          time: {
            minute:
              2 * periodLengthInMinutes.value +
              3 * extraPeriodLengthInMinutes.value,
            second: 0,
          },
          timeBoundaryInSeconds:
            periodLength.value * 2 + extraPeriodLength.value * 4,
          clockDirectionDown: clockDirection.value === 'down',
          startTimeFromInSeconds: startRegularPeriodTime + startExtraPeriodTime,
          clockHidden: true,
        },
        [IceHockeyEventStatusId.FourthOvertime]: {
          time: {
            minute:
              2 * periodLengthInMinutes.value +
              3 * extraPeriodLengthInMinutes.value,
            second: 0,
          },
          timeBoundaryInSeconds:
            periodLength.value * 2 + extraPeriodLength.value * 4,
          clockDirectionDown: clockDirection.value === 'down',
          startTimeFromInSeconds:
            startRegularPeriodTime * 2 + startExtraPeriodTime * 4,
        },
        [IceHockeyEventStatusId.PenaltyShootout]: {
          time: { minute: 0, second: 0 },
        },
        [IceHockeyEventStatusId.Finished]: {
          time: { minute: 0, second: 0 },
          clockHidden: true,
        },
        [IceHockeyEventStatusId.FinishedAfterOvertime]: {
          time: { minute: 0, second: 0 },
          clockHidden: true,
        },
        [IceHockeyEventStatusId.FinishedAfterPenalties]: {
          time: { minute: 0, second: 0 },
          clockHidden: true,
        },
        [IceHockeyEventStatusId.FinishedAwardedWin]: {
          time: { minute: 0, second: 0 },
          clockHidden: true,
        },
        [IceHockeyEventStatusId.ToFinish]: {
          time: { minute: 0, second: 0 },
          clockHidden: true,
        },
      }
    })
    .inSingletonScope()
}
