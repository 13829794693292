<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted } from 'vue'

import * as popup from '@mobile/globalState/popup'

export default defineComponent({
  components: {},
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    onMounted(() => popup.pushBreadcrumb(props.name))
    onUnmounted(() => popup.popBreadcrumb())

    const previousBreadcrumbs = computed(() =>
      popup.state.breadcrumbs.slice(0, -1),
    )
    const currentBreadcrumb = computed(
      () => popup.state.breadcrumbs[popup.state.breadcrumbs.length - 1],
    )

    function getLastPreviousBreadcrumb(): string {
      return previousBreadcrumbs.value[previousBreadcrumbs.value.length - 1]
    }

    return {
      previousBreadcrumbs,
      currentBreadcrumb,
      getLastPreviousBreadcrumb,
    }
  },
})
</script>

<template>
  <div
    class="from-darkblue to-blue flex h-8 items-center space-x-2 bg-gradient-to-r px-3 text-base text-white"
  >
    <template v-if="previousBreadcrumbs.length > 2">
      <div
        :key="'breadcrumb-dots-separator'"
        class="space-x-2 opacity-70"
      >
        <span>...</span>
        <FontAwesomeIcon icon="angle-right" />
      </div>
      <div
        :key="getLastPreviousBreadcrumb()"
        class="space-x-2 opacity-70"
      >
        <span>{{ getLastPreviousBreadcrumb() }}</span>
        <FontAwesomeIcon icon="angle-right" />
      </div>
    </template>

    <div
      v-for="breadcrumb in previousBreadcrumbs"
      v-else
      :key="breadcrumb"
      class="space-x-2 opacity-70"
    >
      <span>{{ breadcrumb }}</span>
      <FontAwesomeIcon icon="angle-right" />
    </div>

    <span class="font-bold">{{ currentBreadcrumb }}</span>
  </div>
</template>
