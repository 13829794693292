<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import { Participant } from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import ParticipantLogo from '@mobile/components/ParticipantLogo/ParticipantLogo.vue'

import { ButtonColor } from '../Button/ButtonTypes'
import { getParticipantButtonColor } from '../Button/ButtonUtils'

export default defineComponent({
  components: {
    Button,
    ParticipantLogo,
  },
  props: {
    participant: {
      type: Object as PropType<Participant>,
      required: true,
    },
  },
  setup(props) {
    const color = computed<ButtonColor>(() =>
      getParticipantButtonColor(props.participant),
    )

    return { color }
  },
})
</script>

<template>
  <Button
    class="mb-4 flex w-full justify-center"
    :color
    :size="'sm'"
  >
    <div class="flex h-full items-center space-x-1">
      <ParticipantLogo
        class="h-full w-auto"
        :participant
      />
      <div class="font-bold">
        {{ participant.short_name }}
      </div>
    </div>
  </Button>
</template>
