import EventEmitter from 'eventemitter3'
import { shallowReactive } from 'vue'

import { captureErrorWithTransactionName } from '@collector/shared-plugin-sentry-vue'
import {
  ApiClient,
  ApiErrorResponse,
  Event,
  isIncidentDuplicationError,
  isWretchError,
  Sport,
} from '@collector/sportsapi-client-legacy'
import IncidentAction from '@mobile/ActionQueue/Actions/Incidents/IncidentAction'
import { setCurrentEvent } from '@mobile/globalState/event'
import * as scoutMessenger from '@mobile/globalState/scoutMessenger'
import { setCurrentSport } from '@mobile/globalState/sport'
import { stringifyError } from '@mobile/reusables/stringifyError'

import { Action, ActionStatus } from './Action'
import { EventType } from './EventType'

export enum ActionQueueStatus {
  Working = 'Working',
  Paused = 'Paused',
  Pausing = 'Pausing',
}

type Events = {
  [EventType.QueueStarted]: () => void
  [EventType.PausingQueue]: () => void
  [EventType.QueuePaused]: () => void
  [EventType.ActionErrored]: (action: Action<unknown>) => void
  [EventType.ActionAdded]: (action: Action<unknown>) => void
  [EventType.ResendingAction]: (action: Action<unknown>) => void
  [EventType.BeforeActionAdd]: (action: Action<unknown>) => void
}

export class ActionQueue extends EventEmitter<Events> {
  public readonly queue: Action<unknown>[] = []
  public status = ActionQueueStatus.Working
  public currentAction?: Action<unknown>

  public readonly event: Event
  public readonly sport: Sport
  public readonly apiClient: ApiClient

  constructor(event: Event, sport: Sport) {
    super()

    // Create shallow reactivity to solve some optimization issues
    // if ActionQueue object is marked as reactive
    this.event = shallowReactive(event)
    this.sport = shallowReactive(sport)

    setCurrentEvent(event)
    setCurrentSport(sport)
  }

  public add(action: Action<unknown>): void {
    if (this.queue.includes(action)) {
      return
    }

    this.checkIfActionIsSupportedOrThrow(action)

    this.emit(EventType.BeforeActionAdd, action)

    this.queue.push(action)

    if (this.queue.length === 1) {
      this.currentAction = action

      this.execNextAction()
    }

    this.emit(EventType.ActionAdded, action)
  }

  public checkIfActionIsSupportedOrThrow(action: Action<unknown>): void {
    switch (action.type) {
      case 'AddIncident':
      case 'DeleteIncident':
      case 'UpdateIncident':
      case 'UpdateLineups':
      case 'UpdateEvent':
      case 'UpdateEventParticipants':
      case 'UpdateEventTime':
        return
      default: {
        throw new Error(
          "Queue doesn't support passed action. If you've added new Action then make sure that you defined it in Actions list to ensure proper serialization",
        )
      }
    }
  }

  public remove(action: Action<unknown>, removeDependant = true): void {
    if (action.status !== ActionStatus.InProgress) {
      const actionIndex = this.queue.indexOf(action)

      if (actionIndex !== -1) {
        this.queue.splice(actionIndex, 1)
      }
      if (this.currentAction === action) {
        this.currentAction = undefined
      }
      if (removeDependant) {
        this.removeDependantActions(action)
      }
    }
  }

  private removeDependantActions(action: Action<unknown>): void {
    // Iterate backwards to not mess up indexes when splicing in middle of iteration
    for (let index = this.queue.length - 1; index >= 0; index--) {
      const potentialDependantAction = this.queue[index]
      if (potentialDependantAction.dependencies.includes(action.id)) {
        this.remove(potentialDependantAction)
      }
    }
  }

  public start(): boolean {
    if (this.status === ActionQueueStatus.Pausing) {
      return false
    }

    if (
      this.status === ActionQueueStatus.Paused &&
      this.currentAction?.status !== ActionStatus.Errored &&
      this.currentAction?.status !== ActionStatus.Unknown
    ) {
      this.status = ActionQueueStatus.Working
      this.emit(EventType.QueueStarted)
      this.execNextAction()

      return true
    }

    return false
  }

  public async pause(): Promise<void> {
    if (this.currentAction) {
      this.status = ActionQueueStatus.Pausing
      this.emit(EventType.PausingQueue)

      try {
        await this.currentAction.currentPromise
      } catch (err) {
        this.captureErrorWithTransaction(
          'Action processing triggered automatically',
          err,
        )
        // Action will error out and update its status so we don't need to handle it here.
      } finally {
        this.status = ActionQueueStatus.Paused
        this.emit(EventType.QueuePaused)
      }
    } else {
      this.status = ActionQueueStatus.Paused
      this.emit(EventType.QueuePaused)
    }
  }

  public toggle(): void {
    if (this.status === ActionQueueStatus.Working) {
      this.pause()
    } else if (this.status === ActionQueueStatus.Paused) {
      this.start()
    }
  }

  public finishCurrentAction(): void {
    if (this.currentAction?.status === ActionStatus.Completed) {
      this.remove(this.currentAction, false)
      this.currentAction = undefined
    }
  }

  private async execNextAction(): Promise<void> {
    const isQueuePaused = [
      ActionQueueStatus.Pausing,
      ActionQueueStatus.Paused,
    ].includes(this.status)

    if (
      this.isEmpty() ||
      isQueuePaused ||
      this.currentAction?.status === ActionStatus.InProgress
    ) {
      return
    }

    this.currentAction = this.queue[0]

    try {
      await this.execAction(this.currentAction)
      this.finishCurrentAction()
    } catch (error) {
      this.status = ActionQueueStatus.Paused
      this.emit(EventType.QueuePaused)

      this.captureErrorWithTransaction('Action processing resumed', error)
    }

    this.execNextAction()
  }

  private isFirstInQueue(action: Action<never>): boolean {
    return this.queue[0] === action
  }

  private isEmpty(): boolean {
    return this.queue.length === 0
  }

  public async exec(action: Action<never>): Promise<void> {
    if (this.isEmpty() || !this.isFirstInQueue(action)) {
      return
    }

    this.currentAction = action

    try {
      await this.execAction(this.currentAction)
      this.finishCurrentAction()
    } catch (error) {
      this.status = ActionQueueStatus.Paused
      this.emit(EventType.QueuePaused)

      this.captureErrorWithTransaction(
        'Action processing triggered manually',
        error,
      )
    }
  }

  public async execAction(action: Action<unknown>): Promise<void> {
    if (
      this.currentAction === action &&
      this.isStatusExecutable(action.status)
    ) {
      if (this.isResend(action.status)) {
        this.emit(EventType.ResendingAction, action)
      }

      action.error = undefined
      action.status = ActionStatus.InProgress
      action.currentPromise = action.execImpl()

      try {
        const result = await action.currentPromise
        if (result) {
          this.updateDependantActions(action.id, result)
        }
        action.status = ActionStatus.Completed
        this.finishCurrentAction()
      } catch (err) {
        const errorPayload = stringifyError(err)

        if (isWretchError(err)) {
          const apiError = JSON.parse(errorPayload) as ApiErrorResponse

          if (isIncidentDuplicationError(apiError)) {
            try {
              const uuid = apiError.api.method.parameters.uuid

              if (uuid) {
                const incidentId =
                  await scoutMessenger.state.client.getIncidentIdByUuid(uuid)
                this.updateDependantActions(action.id, incidentId)
                action.status = ActionStatus.Completed
                this.finishCurrentAction()
                return
              }
            } catch (err) {
              action.status = ActionStatus.Errored
              action.error =
                'Error occured while resolving incident identifier. Try again in a while.'
              this.emit(EventType.ActionErrored, action)

              throw err
            }
          }
        }

        action.error = errorPayload
        action.status = ActionStatus.Errored
        this.emit(EventType.ActionErrored, action)

        throw err
      }
    }
  }

  private isResend(status: ActionStatus): boolean {
    return [ActionStatus.Errored, ActionStatus.Unknown].includes(status)
  }

  private isStatusExecutable(status: ActionStatus): boolean {
    return [
      ActionStatus.Pending,
      ActionStatus.Errored,
      ActionStatus.Unknown,
    ].includes(status)
  }

  private updateDependantActions(actionId: string, incidentId: string): void {
    // create array of dependencies for each action
    const actionIdToDependencies = this.queue.reduce(
      (acc: Record<string, string[]>, action) => {
        // class with 'actionOrIncidentId'
        if (action instanceof IncidentAction) {
          const { id, actionOrIncidentId } = action

          if (actionOrIncidentId) {
            const dependencies = acc[actionOrIncidentId] || []
            acc[id] = [...dependencies, actionOrIncidentId]
          } else {
            acc[id] = []
          }
        }

        return acc
      },
      {},
    )

    this.queue.forEach((action) => {
      // if current action depends on actionId
      if (
        action instanceof IncidentAction &&
        !action.incidentId &&
        actionIdToDependencies[action.id].includes(actionId)
      ) {
        action.setIncidentId(incidentId)
      }
    })
  }

  private captureErrorWithTransaction(
    transaction: string,
    error: unknown,
  ): void {
    captureErrorWithTransactionName(
      `Error in Event: ${this.event.id}: Action Queue processing Failure`,
      transaction,
      error,
    )
  }
}
