import { EventsLineupsParticipant } from '@collector/sportsapi-client-legacy'

export const maximumPlayersInLine = 5
export const maximumPlayersInFormation = 11

export type Formation = {
  statValue: Formations | string | number | null
  lines: FormationLine[]
  selectedPlayers: FormationPlayer[]
  notSelectedPlayers: FormationPlayer[]
}

export type FormationPlayer = Partial<EventsLineupsParticipant> & {
  short_name?: string
}

export type FormationLine = Array<FormationPlayer>

export enum Formations {
  '3-4-1-2' = '3-4-1-2',
  '3-4-2-1' = '3-4-2-1',
  '3-4-3' = '3-4-3',
  '3-5-2' = '3-5-2',
  '4-1-3-2' = '4-1-3-2',
  '4-1-4-1' = '4-1-4-1',
  '4-2-2-2' = '4-2-2-2',
  '4-2-3-1' = '4-2-3-1',
  '4-3-1-2' = '4-3-1-2',
  '4-3-2-1' = '4-3-2-1',
  '4-4-1-1' = '4-4-1-1',
  '4-3-3' = '4-3-3',
  '4-4-2' = '4-4-2',
  '4-5-1' = '4-5-1',
  '5-2-2-1' = '5-2-2-1',
  '5-3-2' = '5-3-2',
  '5-4-1' = '5-4-1',
}
