import { SoccerIncidentId } from '@collector/sportsapi-client-legacy'
import { PopupBuilder } from '@mobile/views/Relation/Shared/Popup/builder/PopupBuilder'

export const PenaltySavedByGoalkeeperPopup = new PopupBuilder(
  'Penalty saved by goalkeeper',
  { incident_id: SoccerIncidentId.PenaltySavedByGoalkeeper },
)
  .withTeamSelection()
  .omitDetailsView()
  .build()
