import {
  SoccerIncidentId,
  VideoAssistantRefereeAttribute,
} from '@collector/sportsapi-client-legacy'
import AttributesSelection from '@mobile/views/Relation/Shared/Popup/builder/AttributesSelection.vue'
import { useCancellationButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useCancellationButton'
import { useCloseButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useCloseButton'
import { useConfirmationButton } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useConfirmationButton'
import { useNavigationSeparator } from '@mobile/views/Relation/Shared/Popup/builder/buttons/useNavigationSeparator'
import { PopupBuilder } from '@mobile/views/Relation/Shared/Popup/builder/PopupBuilder'

export const VARPopup = new PopupBuilder('VAR', {
  incident_id: SoccerIncidentId.VideoAssistantReferee,
})
  .setNavigationButtons([
    ...useNavigationSeparator(3),
    useCloseButton(),
    useCancellationButton(),
    useConfirmationButton(),
  ])
  .appendBody(AttributesSelection, {
    title: 'Additional Info',
    attributes: [
      VideoAssistantRefereeAttribute.PossibleGoal,
      VideoAssistantRefereeAttribute.PossiblePenalty,
      VideoAssistantRefereeAttribute.PossibleRedCard,
    ],
  })
  .build()
