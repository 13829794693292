import { CommonResultId } from '@collector/sportsapi-client-legacy'

import { ResultIdsSet } from '../../ParticipantsDataTable/ResultIdsSet'
import { StatIdsSet } from '../../ParticipantsDataTable/StatIdsSet'
import { VerifyResultsSportFactory } from './VerifyResultsSportFactory'

export function verifyResultsAmericanFootballFactory(): VerifyResultsSportFactory {
  function getResultsIdsSet(): ResultIdsSet {
    return new ResultIdsSet([CommonResultId.Result])
  }

  function getStatIdsSet(): StatIdsSet {
    return new StatIdsSet([])
  }

  return {
    getResultsIdsSet,
    getStatIdsSet,
  }
}
