import { IncidentAttributeId } from '@collector/sportsapi-client-legacy'
import { ToggleButtonGroupOption } from '@mobile/components/ToggleButton/ToggleButtonGroup.vue'
import { EventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export function useAttributesToggleOptions(
  attributes: IncidentAttributeId[],
  eventInjections: EventInjections,
  withNoneOption?: boolean,
): ToggleButtonGroupOption[] {
  const { sport } = eventInjections

  const attributesToggleOptions = attributes.map((attribute) => ({
    name: sport.value.getAttributeTranslation(Number(attribute)),
    value: Number(attribute),
  }))

  return withNoneOption
    ? [
        ...attributesToggleOptions,
        {
          name: 'None',
          value: null,
        },
      ]
    : attributesToggleOptions
}
