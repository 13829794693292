<script lang="ts">
import { defineComponent, PropType, reactive } from 'vue'

import { Event, relationStatuses } from '@collector/sportsapi-client-legacy'
import ToggleButtonGroup from '@mobile/components/ToggleButton/ToggleButtonGroup.vue'

export default defineComponent({
  components: { ToggleButtonGroup },
  props: {
    event: {
      type: Object as PropType<Event>,
      required: true,
    },
  },
  emits: ['eventPreparation:cancel', 'eventPreparationRelationStatus:save'],
  setup() {
    const options = reactive(relationStatuses)

    return { options }
  },
})
</script>

<template>
  <ToggleButtonGroup
    v-model:value="
      // eslint-disable-next-line vue/no-mutating-props
      event.relation_status
    "
    :options
    :undeselectable="true"
  />
</template>
