import { Ref } from 'vue'

import { Confirmation } from '@collector/sportsapi-client-legacy'
import { Action } from '@mobile/ActionQueue/Action'
import { ActionQueue } from '@mobile/ActionQueue/ActionQueue'
import IncidentAction from '@mobile/ActionQueue/Actions/Incidents/IncidentAction'
import IncidentId from '@mobile/ActionQueue/Actions/Incidents/IncidentId'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import { resolveProbableIncident } from '@mobile/ActionQueue/ActionUtils'
import { EventType } from '@mobile/ActionQueue/EventType'
import { eventBus } from '@mobile/globalState/eventBus'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { IncidentsConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/IncidentsConfiguration'

import { Hook } from './Hook'

export class HookUnconfirmedIncidentsReminder implements Hook {
  private reminderInterval = 0
  private reminderIntervalMs = 60000

  constructor(
    private readonly actionQueue: ActionQueue,
    private readonly linkedProbableIncidents: Ref<
      ProbableLinkedIncident[] | null | undefined
    >,
    private readonly notDeletableProbableIncidents: Ref<
      ProbableIncident[] | null
    >,
    private readonly reminderIncidentsIds: IncidentId[],
    private readonly incidentsConfiguration: IncidentsConfiguration,
  ) {}

  public install(): void {
    if (this.getNumberOfUnconfirmedIncidents()) {
      eventBus.emit('UnconfirmedIncidentsReminder')
    }
    this.actionQueue.on(EventType.ActionAdded, this.onActionQueueAdded)
  }

  public uninstall(): void {
    this.actionQueue.off(EventType.ActionAdded, this.onActionQueueAdded)
    clearInterval(this.reminderInterval)
    this.reminderInterval = 0
  }

  private onActionQueueAdded = (action: Action<unknown>): void => {
    if (!(action instanceof IncidentAction)) {
      return
    }

    const incident = resolveProbableIncident(
      action,
      this.actionQueue,
      this.notDeletableProbableIncidents.value || [],
      this.incidentsConfiguration,
    )
    const isReminderIncident = this.reminderIncidentsIds.includes(
      Number(incident?.incident_id),
    )

    if (
      isReminderIncident &&
      !this.reminderInterval &&
      this.getNumberOfUnconfirmedIncidents()
    ) {
      eventBus.emit('UnconfirmedIncidentsReminder')
      this.reminderInterval = window.setInterval(() => {
        if (this.getNumberOfUnconfirmedIncidents()) {
          return eventBus.emit('UnconfirmedIncidentsReminder')
        }
        clearInterval(this.reminderInterval)
        this.reminderInterval = 0
      }, this.reminderIntervalMs)
    }
  }

  private getNumberOfUnconfirmedIncidents(): number {
    return (
      this.linkedProbableIncidents.value?.filter(
        ({ incident }) => incident.confirmation === Confirmation.ToBeDone,
      ).length ?? 0
    )
  }
}
