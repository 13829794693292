<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'

import { nullCoalesce } from '@collector/shared-utils'
import { EventsLineupsParticipant } from '@collector/sportsapi-client-legacy'
import Select from '@mobile/components/Select/Select.vue'
import { SelectOption } from '@mobile/components/Select/types/SelectOption'

import SelectPlayerModal from './SelectPlayerModal.vue'

export default defineComponent({
  components: {
    Select,
    SelectPlayerModal,
  },
  props: {
    selectedPlayer: {
      type: [Number, String, null] as PropType<number | string | null>,
      required: false,
      default: null,
    },
    lineupsSelectOptions: {
      type: Array as PropType<SelectOption[]>,
      required: true,
    },
    players: {
      type: Array as PropType<EventsLineupsParticipant[]>,
      required: true,
    },
    selectLabel: {
      type: Object as PropType<{ text: string; classes: string }>,
      required: true,
    },
    fontSize: {
      type: String,
      default: 'text-xxs',
    },
  },
  emits: ['selectedPlayerChanged'],
  setup(props, { emit }) {
    const isModalOpen = ref(false)

    function toggleModal(): void {
      isModalOpen.value = !isModalOpen.value
    }

    function onAssignPlayerSave(selectedPlayerId: number): void {
      if (props.selectedPlayer !== selectedPlayerId) {
        emit('selectedPlayerChanged', selectedPlayerId)
      }
    }

    return {
      isModalOpen,
      toggleModal,
      onAssignPlayerSave,
      nullCoalesce,
    }
  },
})
</script>

<template>
  <div class="select-player flex w-full items-center">
    <label :class="selectLabel.classes">{{ selectLabel.text }}</label>
    <Select
      class="flex-1"
      label="name"
      mode="single"
      valueProp="value"
      :value="nullCoalesce(selectedPlayer, undefined)"
      :disabled="true"
      :options="lineupsSelectOptions"
      :font-size
      @click="toggleModal"
    />
  </div>
  <SelectPlayerModal
    v-show="isModalOpen"
    :selectedPlayerId="
      selectedPlayer !== null ? (selectedPlayer as number) : undefined
    "
    :players
    @close="toggleModal"
    @save="onAssignPlayerSave"
  />
</template>
