<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({})
</script>

<template>
  <div class="bg-silver absolute inset-0 h-full w-full">
    <slot></slot>
  </div>
</template>
