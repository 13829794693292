import { Ref, ref, toRaw, watch } from 'vue'

import { IncidentId } from '@collector/sportsapi-client-legacy'
import { EventSettingsType } from '@mobile/database/EventSettingsRepository'
import * as app from '@mobile/globalState/app'
import { SportOptionsComposable } from '@mobile/views/Relation/DetailsPanel/Sports/composables/useSportOptions'
import { EventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

import { useHistoryIncidents } from '../Table/useHistoryIncidents'

const hideIncidents = ref<IncidentId[]>([])
const incidentType = ref<IncidentId[]>([])
const participantsIncidents = ref<IncidentId[]>([])
const detailsIncidents = ref<IncidentId[]>([])
const eventParticipant = ref<number | null>(null)
const enableFilters = ref(true)
const emptyIncidents = ref(false)
const filterKeyPasses = ref(false)

export type EventRelationSettingsReturnType = {
  hideIncidents: Ref<IncidentId[]>
  incidentType: Ref<IncidentId[]>
  enableFilters: Ref<boolean>
  emptyIncidents: Ref<boolean>
  detailsIncidents: Ref<IncidentId[]>
  participantsIncidents: Ref<number[]>
  eventParticipant: Ref<number | null>
  filterKeyPasses: Ref<boolean>
  load: () => Promise<void>
}

export function useEventRelationSettings(
  eventInjections: EventInjections,
  sportOptionsComposable: SportOptionsComposable,
): EventRelationSettingsReturnType {
  const eventId = eventInjections.probableEvent.value.id
  const linkedProbableIncidents = eventInjections.linkedProbableIncidents
  const { pinnedIncidentIds, toggleIncidentPinState } =
    useHistoryIncidents(eventInjections) // Pass the eventInjections argument to the useHistoryIncidents function
  const { selectedOnStartDetailsIncidents, participantIncidentsOptions } =
    sportOptionsComposable

  async function load(): Promise<void> {
    const eventSettings =
      await app.state.database.eventSettingsRepository.getByEventId(eventId)
    hideIncidents.value = eventSettings?.settings.hideIncidents ?? []
    incidentType.value = eventSettings?.settings.incidentType ?? []
    detailsIncidents.value =
      eventSettings?.settings.detailsIncidents ??
      selectedOnStartDetailsIncidents
    participantsIncidents.value =
      eventSettings?.settings.participantsIncidents ??
      (participantIncidentsOptions.value.map(
        (option) => option.value,
      ) as IncidentId[])
    eventParticipant.value = eventSettings?.settings.eventParticipantIds ?? null

    linkedProbableIncidents.value.forEach((incident) => {
      if (
        eventSettings?.settings?.pinnedIncidentIds?.some(
          (incidentId) => incidentId === incident.incident.id,
        )
      ) {
        toggleIncidentPinState(incident)
      }
    })

    watch(
      [
        hideIncidents,
        incidentType,
        detailsIncidents,
        participantsIncidents,
        pinnedIncidentIds,
        eventParticipant,
      ],
      () => {
        updateSettings(
          eventId,
          hideIncidents,
          incidentType,
          detailsIncidents,
          participantsIncidents,
          pinnedIncidentIds,
          eventParticipant,
        )
      },
      { deep: true },
    )
  }

  return {
    hideIncidents,
    incidentType,
    enableFilters,
    emptyIncidents,
    detailsIncidents,
    participantsIncidents,
    eventParticipant,
    filterKeyPasses,
    load,
  }
}

async function updateSettings(
  eventId: number,
  hideIncidents: Ref<number[]>,
  incidentType: Ref<number[]>,
  detailsIncidents: Ref<number[]>,
  participantsIncidents: Ref<number[]>,
  pinnedIncidentIds: Ref<string[]>,
  eventParticipants: Ref<number | null>,
): Promise<void> {
  await app.state.database.eventSettingsRepository.put({
    eventId,
    type: EventSettingsType.DETAILS_PANEL,
    settings: {
      hideIncidents: toRaw(hideIncidents.value),
      incidentType: toRaw(incidentType.value),
      detailsIncidents: toRaw(detailsIncidents.value),
      participantsIncidents: toRaw(participantsIncidents.value),
      pinnedIncidentIds: toRaw(pinnedIncidentIds.value),
      eventParticipantIds: toRaw(eventParticipants.value),
    },
  })
}
