import { Container } from 'inversify'

import {
  IncidentId,
  VolleyballEventStatusId,
  VolleyballIncidentId,
} from '@collector/sportsapi-client-legacy'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindEventStatusesConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.EventStatusesConfiguration)
    .toDynamicValue(() => {
      const withCommonIncidentsAllowedDuringTheGame = (
        incidentsId: IncidentId[] = [],
      ): IncidentId[] => {
        return [
          VolleyballIncidentId.YellowCard,
          VolleyballIncidentId.RedCard,
          VolleyballIncidentId.ServiceError,
          VolleyballIncidentId.AttackError,
          VolleyballIncidentId.TechnicalErrors,
          VolleyballIncidentId.Point,
          VolleyballIncidentId.Serve,
          VolleyballIncidentId.ServiceAce,
          VolleyballIncidentId.AttackPoint,
          VolleyballIncidentId.BlockPoint,
          VolleyballIncidentId.SetBall,
          VolleyballIncidentId.SetWon,
          VolleyballIncidentId.MatchBall,
          VolleyballIncidentId.Challenge,
          VolleyballIncidentId.RefereeChallenge,
          VolleyballIncidentId.Timeout,
          VolleyballIncidentId.BreakDuringTheGame,
          VolleyballIncidentId.RepeatAction,
          ...incidentsId,
        ]
      }

      return {
        [VolleyballEventStatusId.NotStarted]: {
          time: null,
          allowedIncidents: [
            VolleyballIncidentId.FirstSetStared,
            VolleyballIncidentId.StartDelayed,
            VolleyballIncidentId.FinishedAwardedWin,
            VolleyballIncidentId.Postponed,
            VolleyballIncidentId.Abandoned,
            VolleyballIncidentId.Cancelled,
            VolleyballIncidentId.MatchAboutToStart,
          ],
        },
        [VolleyballEventStatusId.Interrupted]: {
          time: null,
          allowedIncidents: [
            VolleyballIncidentId.ToFinish,
            VolleyballIncidentId.FinishedAwardedWin,
            VolleyballIncidentId.Abandoned,
            VolleyballIncidentId.Cancelled,
          ],
        },
        [VolleyballEventStatusId.Cancelled]: {
          time: null,
          allowedIncidents: [VolleyballIncidentId.NotStarted],
        },
        [VolleyballEventStatusId.StartDelayed]: {
          time: null,
          allowedIncidents: [
            VolleyballIncidentId.FirstSetStared,
            VolleyballIncidentId.FinishedAwardedWin,
            VolleyballIncidentId.Postponed,
            VolleyballIncidentId.Abandoned,
            VolleyballIncidentId.Cancelled,
            VolleyballIncidentId.MatchAboutToStart,
          ],
        },
        [VolleyballEventStatusId.Postponed]: {
          time: null,
          allowedIncidents: [
            VolleyballIncidentId.StartDelayed,
            VolleyballIncidentId.FirstSetStared,
            VolleyballIncidentId.FinishedAwardedWin,
            VolleyballIncidentId.Abandoned,
            VolleyballIncidentId.Cancelled,
          ],
        },
        [VolleyballEventStatusId.Abandoned]: {
          time: null,
          allowedIncidents: [VolleyballIncidentId.NotStarted],
        },
        [VolleyballEventStatusId.FinishedAwardedWin]: { time: null },
        [VolleyballEventStatusId.FirstSet]: {
          time: null,
          allowedIncidents: withCommonIncidentsAllowedDuringTheGame([
            VolleyballIncidentId.BreakAfterFirstSet,
            VolleyballIncidentId.Interrupted,
          ]),
        },
        [VolleyballEventStatusId.SecondSet]: {
          time: null,
          allowedIncidents: withCommonIncidentsAllowedDuringTheGame([
            VolleyballIncidentId.BreakAfterSecondSet,
            VolleyballIncidentId.Interrupted,
          ]),
        },
        [VolleyballEventStatusId.ThirdSet]: {
          time: null,
          allowedIncidents: withCommonIncidentsAllowedDuringTheGame([
            VolleyballIncidentId.BreakAfterThirdSet,
            VolleyballIncidentId.Interrupted,
            VolleyballIncidentId.Finished,
          ]),
        },
        [VolleyballEventStatusId.FourthSet]: {
          time: null,
          allowedIncidents: withCommonIncidentsAllowedDuringTheGame([
            VolleyballIncidentId.BreakAfterFourthSet,
            VolleyballIncidentId.Interrupted,
            VolleyballIncidentId.Finished,
          ]),
        },
        [VolleyballEventStatusId.FifthSet]: {
          time: null,
          allowedIncidents: withCommonIncidentsAllowedDuringTheGame([
            VolleyballIncidentId.BreakAfterFifthSet,
            VolleyballIncidentId.Interrupted,
            VolleyballIncidentId.Finished,
          ]),
        },
        [VolleyballEventStatusId.ToFinish]: {
          time: null,
          allowedIncidents: [],
        },
        [VolleyballEventStatusId.GoldenSet]: {
          time: null,
          allowedIncidents: withCommonIncidentsAllowedDuringTheGame([
            VolleyballIncidentId.Interrupted,
            VolleyballIncidentId.Finished,
          ]),
        },
        [VolleyballEventStatusId.BreakAfterFirstSet]: {
          time: null,
          allowedIncidents: [
            VolleyballIncidentId.SecondSetStarted,
            VolleyballIncidentId.Interrupted,
          ],
        },
        [VolleyballEventStatusId.BreakAfterSecondSet]: {
          time: null,
          allowedIncidents: [
            VolleyballIncidentId.ThirdSetStarted,
            VolleyballIncidentId.Interrupted,
          ],
        },
        [VolleyballEventStatusId.BreakAfterThirdSet]: {
          time: null,
          allowedIncidents: [
            VolleyballIncidentId.FourthSetStarted,
            VolleyballIncidentId.Interrupted,
          ],
        },
        [VolleyballEventStatusId.BreakAfterFourthSet]: {
          time: null,
          allowedIncidents: [
            VolleyballIncidentId.FifthSetStarted,
            VolleyballIncidentId.Interrupted,
          ],
        },
        [VolleyballEventStatusId.BreakAfterFifthSet]: {
          time: null,
          allowedIncidents: [
            VolleyballIncidentId.Interrupted,
            VolleyballIncidentId.GoldenSetStart,
          ],
        },
        [VolleyballEventStatusId.Finished]: {
          time: null,
          allowedIncidents: [],
        },
      }
    })
    .inSingletonScope()
}
