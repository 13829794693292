<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
  },
})
</script>

<template>
  <div class="text-blue flex flex-col items-center space-y-3 font-bold">
    <div>{{ text }}</div>
    <div class="flex items-center justify-center space-x-1.5">
      <div
        class="bg-blue h-3 w-3 animate-bounce rounded-full p-1"
        style="animation-delay: 0.1s"
      ></div>
      <div
        class="bg-blue h-3 w-3 animate-bounce rounded-full p-1"
        style="animation-delay: 0.2s"
      ></div>
      <div
        class="bg-blue h-3 w-3 animate-bounce rounded-full p-1"
        style="animation-delay: 0.3s"
      ></div>
    </div>
  </div>
</template>
