import { AmericanFootballIncidentId } from './IncidentId'

export const AmericanFootballAcronymMap: {
  [key in AmericanFootballIncidentId]: string
} = {
  [AmericanFootballIncidentId.Kickoff]: 'KIC',
  [AmericanFootballIncidentId.Touchback]: 'TOU',
  [AmericanFootballIncidentId.Pass]: 'PASS',
  [AmericanFootballIncidentId.Rush]: 'RUSH',
  [AmericanFootballIncidentId.Turnover]: 'TO',
  [AmericanFootballIncidentId.Interception]: 'INT',
  [AmericanFootballIncidentId.Touchdown]: 'TOD',
  [AmericanFootballIncidentId.TwoPointConversion]: 'TPC',
  [AmericanFootballIncidentId.ExtraPoint]: 'EP',
  [AmericanFootballIncidentId.FieldGoal]: 'FG',
  [AmericanFootballIncidentId.Safety]: 'SAF',
  [AmericanFootballIncidentId.Sack]: 'SAC',
  [AmericanFootballIncidentId.Fumble]: 'FUM',
  [AmericanFootballIncidentId.FumbleRecovered]: 'FUMR',
  [AmericanFootballIncidentId.Penalty]: 'PEN',
  [AmericanFootballIncidentId.FairCatch]: 'FC',
  [AmericanFootballIncidentId.OnsideKick]: 'OK',
  [AmericanFootballIncidentId.Punt]: 'PUNT',
  [AmericanFootballIncidentId.Challenge]: 'CHAL',
  [AmericanFootballIncidentId.Timeout]: 'TO',
  [AmericanFootballIncidentId.Injury]: 'INJ',
  [AmericanFootballIncidentId.DefensiveReturn]: 'DR',
  [AmericanFootballIncidentId.Possession]: 'POSS',
  [AmericanFootballIncidentId.FirstDown]: 'FD',
  [AmericanFootballIncidentId.SecondDown]: 'SD',
  [AmericanFootballIncidentId.ThirdDown]: 'TD',
  [AmericanFootballIncidentId.FourthDown]: 'FOD',
  [AmericanFootballIncidentId.InPlay]: 'IP',
  [AmericanFootballIncidentId.MatchAboutToStart]: 'MATS',
  [AmericanFootballIncidentId.FirstQuarterStarted]: 'FQS',
  [AmericanFootballIncidentId.SecondQuarterStarted]: 'SQS',
  [AmericanFootballIncidentId.ThirdQuarterStarted]: 'TQS',
  [AmericanFootballIncidentId.FourthQuarterStarted]: 'FOQS',
  [AmericanFootballIncidentId.FirstExtraTimeStarted]: 'FETS',
  [AmericanFootballIncidentId.SecondExtraTimeStarted]: 'SETS',
  [AmericanFootballIncidentId.ThirdExtraTimeStarted]: 'TETS',
  [AmericanFootballIncidentId.FourthExtraTimeStarted]: 'FOETS',
  [AmericanFootballIncidentId.FifthExtraTimeStarted]: 'FIETS',
  [AmericanFootballIncidentId.EndOfQuarter]: 'EOQ',
  [AmericanFootballIncidentId.BreakAfterFirstQuarter]: 'BAFQ',
  [AmericanFootballIncidentId.BreakAfterSecondQuarter]: 'BASQ',
  [AmericanFootballIncidentId.BreakAfterThirdQuarter]: 'BATQ',
  [AmericanFootballIncidentId.BreakAfterFourthQuarter]: 'BAFOQ',
  [AmericanFootballIncidentId.BreakAfterFirstExtraTime]: 'BFET',
  [AmericanFootballIncidentId.BreakAfterSecondExtraTime]: 'BSET',
  [AmericanFootballIncidentId.BreakAfterThirdExtraTime]: 'BTET',
  [AmericanFootballIncidentId.BreakAfterFourthExtraTime]: 'BFOET',
  [AmericanFootballIncidentId.FirstHalfStarted]: 'FH',
  [AmericanFootballIncidentId.SecondHalfStarted]: 'SH',
  [AmericanFootballIncidentId.Halftime]: 'HT',
  [AmericanFootballIncidentId.Abandoned]: 'ABO',
  [AmericanFootballIncidentId.Cancelled]: 'CAN',
  [AmericanFootballIncidentId.FinishedRegularTime]: 'FRT',
  [AmericanFootballIncidentId.FinishedAfterExtratime]: 'FAET',
  [AmericanFootballIncidentId.FinishedAwardedWin]: 'FAW',
  [AmericanFootballIncidentId.Interrupted]: 'INT',
  [AmericanFootballIncidentId.Postponed]: 'POS',
  [AmericanFootballIncidentId.StartDelayed]: 'SD',
  [AmericanFootballIncidentId.TvTimeout]: 'TVT',
  [AmericanFootballIncidentId.ToFinish]: 'TF',
  [AmericanFootballIncidentId.PlayUnderReview]: 'PUR',
  [AmericanFootballIncidentId.Flag]: 'FLG',
  [AmericanFootballIncidentId.MatchResumed]: 'MR',
  [AmericanFootballIncidentId.PossibleTwoPointConversion]: 'PTPC',
  [AmericanFootballIncidentId.FailedTwoPointConversion]: 'FTPC',
  [AmericanFootballIncidentId.PossibleExtraPoint]: 'PEP',
  [AmericanFootballIncidentId.PossibleFieldGoal]: 'PFG',
  [AmericanFootballIncidentId.NoFieldGoal]: 'NFG',
  [AmericanFootballIncidentId.ExtraPointFailed]: 'EPF',
  [AmericanFootballIncidentId.ChallengeCorrect]: 'CHC',
  [AmericanFootballIncidentId.ChallengeIncorrect]: 'CHI',
}
