import { Participant, StatId } from '@collector/sportsapi-client-legacy'
import { CollectorSport } from '@mobile/reusables/classes/CollectorSport'
import {
  ParticipantData,
  ParticipantDataIdsSet,
  SportData,
} from '@mobile/views/Relation/Shared/VerifyResults/ParticipantsDataTable/Types'

export class StatIdsSet
  extends Set<StatId>
  implements ParticipantDataIdsSet<StatId>
{
  getDataName(): string {
    return 'Stats'
  }

  getRelatedParticipantData(participant: Participant): ParticipantData[] {
    return participant.stats
  }

  getRelatedSportData(sport: CollectorSport): SportData[] {
    return sport.sportsAPIEntity.stats.team
  }
}
