<script lang="ts">
import { defineComponent, PropType } from 'vue'

import Button from '@mobile/components/Button/Button.vue'
import * as popup from '@mobile/globalState/popup'
import { PopupContext } from '@mobile/views/Relation/Shared/Popup/builder/types'
import { useIncidentAction } from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: { Button },
  props: {
    context: {
      type: Object as PropType<PopupContext | null>,
      required: false,
    },
  },
  setup(props) {
    const eventInjections = useEventInjections()
    const { sport } = eventInjections
    const { incidentId } = popup.state.props as { incidentId: number }
    const incidentName = sport.value.getIncidentTranslation(incidentId)

    function close(): void {
      props.context?.closeAllPopups()
      popup.hide()
    }

    function sendIncident(): void {
      useIncidentAction(eventInjections, { incident_id: incidentId })

      close()
    }

    return {
      close,
      sendIncident,
      incidentName,
    }
  },
})
</script>

<template>
  <div class="grid h-full w-full place-items-center">
    <div class="flex flex-col">
      <div class="text-cloudygray pb-2 text-center font-bold">
        Do you want to send {{ incidentName }}?
      </div>
      <div class="flex justify-center space-x-2">
        <Button
          class="w-20"
          :size="'md'"
          :color="'red'"
          @click="close()"
        >
          No
        </Button>
        <Button
          class="w-20"
          :size="'md'"
          :color="'green'"
          @click="sendIncident()"
        >
          Yes
        </Button>
      </div>
    </div>
  </div>
</template>
