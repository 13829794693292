/* eslint-disable no-console */
import EventEmitter from 'eventemitter3'
import { debounce, sortBy } from 'lodash'
import { io, Socket } from 'socket.io-client'

import {
  WebSocketClientEvent,
  WebSocketServerEvent,
} from '@collector/scout-messenger-shared-types'
import Sentry from '@collector/shared-plugin-sentry-vue'
import {
  CollectorPushMessage,
  CollectorPushMessageError,
  CollectorPushMessageEvent,
  CollectorPushMessageEventsLineups,
  CollectorPushMessageEventsScouts,
  CollectorPushMessageIncident,
  isCollectorPushMessageError,
  isCollectorPushMessageEvent,
  isCollectorPushMessageEventsLineups,
  isCollectorPushMessageEventsScouts,
  isCollectorPushMessageIncident,
  ScoutMessengerInfoMessage,
} from '@collector/sportsapi-client-legacy'
import {
  WSIOError,
  WSIOErrorTypes,
} from '@mobile/ScoutMessengerConnection/WSIOErrors'

export interface RelationSocketEmitter {
  event: (event: CollectorPushMessageEvent) => void
  incident: (incident: CollectorPushMessageIncident) => void
  error: (incident: CollectorPushMessageError) => void
  eventsScouts: (eventsScouts: CollectorPushMessageEventsScouts) => void
  eventsLineups: (eventsLineups: CollectorPushMessageEventsLineups) => void
  disconnected: (e: unknown) => void
  connected: () => void
  authorizationFailed: () => void
  synchronizationStarted: () => void
  synchronizationFinished: () => void
  invalidScoutMessengerVersion: () => void
}

export class ScoutMessengerConnection extends EventEmitter<RelationSocketEmitter> {
  private catchUpTime = 0
  private socket?: Socket
  private token?: string
  private messagesQueue: CollectorPushMessage[] = []
  private execMessagesQueueDebounced: () => void
  private isSynchronized = false

  private readonly socketUrl: string = import.meta.env
    .VITE_SCOUT_MESSENGER_CONNECTION_SOCKET_URL

  constructor() {
    super()

    this.execMessagesQueueDebounced = debounce(
      this.execMessagesQueue.bind(this),
      1000,
    )
  }

  public connect(token: string, catchUpTime: number): void {
    this.token = token
    this.catchUpTime = catchUpTime
    console.info('Connecting to scout messenger socket.')

    this.socket?.close()
    this.socket = io(this.socketUrl, {
      query: { token: this.token },
      transports: ['websocket'],
    })

    this.setupCallbacks(this.socket)
  }

  public close(): void {
    this.socket?.close()
  }

  public syncEvent(eventId: number, catchUpUt: number): void {
    this.socket?.emit(WebSocketClientEvent.SyncEvent, {
      eventId,
      catchUpUt,
    })
  }

  public startRelatingEvent(eventId: number): void {
    this.socket?.emit(WebSocketClientEvent.StartRelatingEvent, { eventId })
  }

  public stopRelatingEvent(): void {
    this.socket?.emit(WebSocketClientEvent.StopRelatingEvent)
  }

  public isConnected(): boolean {
    return !!this.socket?.connected
  }

  public joinRelation(eventId: number): void {
    this.socket?.emit(WebSocketClientEvent.JoinRelation, { eventId })
  }

  private setupCallbacks(socket: Socket): void {
    socket.on('connect', () => this.onConnect(socket))
    socket.on('disconnect', (e: unknown) => this.onDisconnect(e))
    socket.on('connect_error', (e: WSIOError) => this.onConnectError(e))

    socket.on(WebSocketServerEvent.Event, (event: CollectorPushMessageEvent) =>
      this.onEventMessage(event),
    )
    socket.on(
      WebSocketServerEvent.Incident,
      (incident: CollectorPushMessageIncident) =>
        this.onIncidentMessage(incident),
    )
    socket.on(WebSocketServerEvent.Error, (error: CollectorPushMessageError) =>
      this.onErrorMessage(error),
    )
    socket.on(
      WebSocketServerEvent.EventsScouts,
      (data: CollectorPushMessageEventsScouts) =>
        this.onEventsScoutsMessage(data),
    )
    socket.on(
      WebSocketServerEvent.EventsLineups,
      (data: CollectorPushMessageEventsLineups) =>
        this.onEventsLineupsMessage(data),
    )
    socket.on(WebSocketServerEvent.SynchronizationFinished, () => {
      this.isSynchronized = true
      this.emit('synchronizationFinished')
    })
    socket.on(
      WebSocketServerEvent.ScoutMessengerInfo,
      (message: ScoutMessengerInfoMessage) =>
        this.onScoutMessengerInfoMessage(message),
    )
  }

  private onConnectError(e: WSIOError): void {
    if (e?.message === WSIOErrorTypes.InvalidToken) {
      console.info('Token authorization failed')
      this.emit('authorizationFailed')
    } else {
      Sentry.captureException(e)
    }
  }

  private onDisconnect(e: unknown): void {
    console.warn('Disconnected. Reconnecting soon.')
    this.emit('disconnected', e)
    if (e === 'io server disconnect' && this.token) {
      this.connect(this.token, this.catchUpTime)
    }
  }

  private async onConnect(socket: Socket): Promise<void> {
    console.info(
      'Connected to scout messenger socket. Sending subscribe message.',
    )
    this.emit('connected')

    this.isSynchronized = false
    this.emit('synchronizationStarted')
    socket.emit(WebSocketClientEvent.Subscribe, { catchUpUt: this.catchUpTime })
  }

  private async execMessagesQueue(): Promise<void> {
    const messages = this.getMessagesWithoutEventMessageDuplicates(
      this.messagesQueue,
    )

    sortBy(messages, (message) => this.messagesQueue.indexOf(message)).forEach(
      (message) => {
        this.emitMessage(message)
      },
    )

    this.messagesQueue.splice(0)
  }

  private getMessagesWithoutEventMessageDuplicates(
    messages: CollectorPushMessage[],
  ): CollectorPushMessage[] {
    const typeUuidKey = (message: CollectorPushMessage): string =>
      `${message.type}${message.uuid}`
    const typeUuidClockTimes: { [typeUuid: string]: number | null } = {}

    const messagesQueueByUuid: { [typeUuid: string]: CollectorPushMessage } = {}
    messages.forEach((message) => {
      messagesQueueByUuid[typeUuidKey(message)] = message

      if (isCollectorPushMessageEvent(message)) {
        typeUuidClockTimes[typeUuidKey(message)] = message.data.clock_time
      }
    })

    const uniqueMessages: { [data: string]: string } = {}
    messages.forEach((message) => {
      if (isCollectorPushMessageEvent(message)) {
        message.data.clock_time = null
        uniqueMessages[JSON.stringify(message.data)] = typeUuidKey(message)
      } else {
        uniqueMessages[JSON.stringify(message)] = typeUuidKey(message)
      }
    })

    const messagesWithoutDuplicateEventMessages: CollectorPushMessage[] = []
    for (const data in uniqueMessages) {
      const typeUuid = uniqueMessages[data]
      const currentMessage = messagesQueueByUuid[typeUuid]
      if (
        isCollectorPushMessageEvent(currentMessage) &&
        typeUuidClockTimes[typeUuid] !== undefined
      ) {
        currentMessage.data.clock_time = typeUuidClockTimes[typeUuid]
      }
      messagesWithoutDuplicateEventMessages.push(messagesQueueByUuid[typeUuid])
    }

    return messagesWithoutDuplicateEventMessages
  }

  private emitMessage(message: CollectorPushMessage): void {
    if (isCollectorPushMessageEvent(message)) {
      this.emit('event', message)
    } else if (isCollectorPushMessageIncident(message)) {
      this.emit('incident', message)
    } else if (isCollectorPushMessageEventsScouts(message)) {
      this.emit('eventsScouts', message)
    } else if (isCollectorPushMessageEventsLineups(message)) {
      this.emit('eventsLineups', message)
    } else if (isCollectorPushMessageError(message)) {
      this.emit('error', message)
    }

    this.updateCatchUpTime(message.pushMessageUt)
  }

  private async onIncidentMessage(
    incident: CollectorPushMessageIncident,
  ): Promise<void> {
    this.messagesQueue.push(incident)

    // @ts-expect-error TODO: add description
    this.execMessagesQueueDebounced.cancel()

    // Don't debounce it on incident - incidents should end chain of redundant incidents
    this.execMessagesQueue()
  }

  private async onEventMessage(
    event: CollectorPushMessageEvent,
  ): Promise<void> {
    if (this.isSynchronized) {
      this.messagesQueue.push(event)
      this.execMessagesQueueDebounced()
    } else {
      this.emitMessage(event)
    }
  }

  private onEventsScoutsMessage(
    eventsScouts: CollectorPushMessageEventsScouts,
  ): void {
    if (this.isSynchronized) {
      this.messagesQueue.push(eventsScouts)
      this.execMessagesQueueDebounced()
    } else {
      this.emitMessage(eventsScouts)
    }
  }

  private onEventsLineupsMessage(
    eventsLineups: CollectorPushMessageEventsLineups,
  ): void {
    if (this.isSynchronized) {
      this.messagesQueue.push(eventsLineups)
      this.execMessagesQueueDebounced()
    } else {
      this.emitMessage(eventsLineups)
    }
  }

  private async onErrorMessage(
    error: CollectorPushMessageError,
  ): Promise<void> {
    if (this.isSynchronized) {
      this.messagesQueue.push(error)
      this.execMessagesQueueDebounced()
    } else {
      this.emitMessage(error)
    }
  }

  private updateCatchUpTime(catchUpTime: number): void {
    if (catchUpTime > this.catchUpTime) {
      this.catchUpTime = catchUpTime
    }
  }

  private onScoutMessengerInfoMessage(
    message: ScoutMessengerInfoMessage,
  ): void {
    if (message.version !== import.meta.env.VITE_RELEASE_NAME) {
      console.warn('SM version:', import.meta.env.VITE_RELEASE_NAME)
      console.warn('SC version:', message.version)
      this.emit('invalidScoutMessengerVersion')
    }
  }
}
