<script lang="ts">
import { defineComponent, PropType, Ref, ref } from 'vue'

import {
  BasketballFoulAttribute,
  BasketballIncidentId,
  Participant,
  TeamSide,
} from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import * as popup from '@mobile/globalState/popup'
import { getOppositeTeam, isHomeTeam } from '@mobile/reusables/entityUtils'
import AttributesSelection from '@mobile/views/Relation/Shared/Popup/builder/AttributesSelection.vue'
import PopupControls from '@mobile/views/Relation/Shared/Popup/PopupControls.vue'
import TeamSelect from '@mobile/views/Relation/Shared/Popup/TeamSelect.vue'
import {
  IncidentActionComposable,
  useIncidentAction,
} from '@mobile/views/Relation/Shared/Popup/useIncidentAction'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

import { useBasketBallConfiguration } from '../../BasketballRelationDependencies/useBasketBallConfiguration'

export default defineComponent({
  components: {
    PopupControls,
    Button,
    TeamSelect,
    AttributesSelection,
  },
  props: {
    incidentActionComposable: {
      type: Object as PropType<IncidentActionComposable>,
      required: true,
    },
  },
  setup() {
    const selectedParticipant: Ref<Participant | null> = ref(null)
    const selectedAttribute: Ref<BasketballFoulAttribute | null> = ref(null)
    const eventInjections = useEventInjections()
    const { probableEvent, probableClock } = eventInjections
    const { activeParticipant } = useBasketBallConfiguration()
    const supportedAttributes = [
      BasketballFoulAttribute.Offensive,
      BasketballFoulAttribute.Technical,
      BasketballFoulAttribute.Unsportsmanlike,
    ]

    function sendIncident(): void {
      if (!selectedParticipant.value) {
        return
      }

      const composable = useIncidentAction(eventInjections, {
        incident_id: BasketballIncidentId.Foul,
        participant_team_id: selectedParticipant.value?.id,
        ...probableClock.value,
      })
      composable.updateIncident({
        attribute_ids: selectedAttribute.value
          ? [Number(selectedAttribute.value)]
          : [],
      })

      updateActiveParticipant(selectedParticipant.value)

      popup.hide()
    }

    function updateActiveParticipant(participant: Participant): void {
      if (selectedAttribute.value === BasketballFoulAttribute.Offensive) {
        const side = isHomeTeam(participant) ? TeamSide.Away : TeamSide.Home

        activeParticipant.value = {
          participant:
            getOppositeTeam(probableEvent.value.participants, participant) ??
            null,
          color: side,
          side,
        }
      }
    }

    function closePopup(): void {
      popup.hide()
    }

    function handleParticipantSelected(
      participant: ProbableLinkedParticipant,
    ): void {
      selectedParticipant.value = participant.getParticipant().value
    }

    function handleAttributeSelected(attribute: BasketballFoulAttribute): void {
      selectedAttribute.value = attribute ? Number(attribute) : null
    }

    return {
      closePopup,
      sendIncident,
      selectedParticipant,
      supportedAttributes,
      handleAttributeSelected,
      handleParticipantSelected,
    }
  },
})
</script>

<template>
  <div class="flex flex-col">
    <TeamSelect
      class="w-full"
      :withButtons="false"
      @participantSelected="handleParticipantSelected"
    />

    <AttributesSelection
      v-bind="{
        incidentActionComposable,
        attributes: supportedAttributes,
        updateIncident: false,
      }"
      title="Select attribute"
      @attributeSelected="handleAttributeSelected"
    />
  </div>

  <PopupControls :enableSwipe="false">
    <template #buttons>
      <div class="flex justify-end space-x-2">
        <Button
          :color="'red'"
          :size="'lg'"
          @click="closePopup()"
        >
          Close
        </Button>
        <Button
          :color="'green'"
          :size="'lg'"
          :disabled="!selectedParticipant"
          @click="sendIncident()"
        >
          Send
        </Button>
      </div>
    </template>
  </PopupControls>
</template>
