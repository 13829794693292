import { chain } from 'lodash'
import { computed, ComputedRef, Ref, ref, watch } from 'vue'

import {
  EventsLineupsParticipant,
  SubParticipant,
  TeamSide,
} from '@collector/sportsapi-client-legacy'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import { SelectOption } from '@mobile/components/Select/types/SelectOption'
import { byId, getOppositeSide } from '@mobile/reusables/entityUtils'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { AssignableIncidentConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/AssignableIncidentsConfiguration'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'
import { useEventSeasonParticipants } from '@mobile/views/Relation/useEventSeasonParticipants'

export interface SelectOptionWithShirtNumber extends SelectOption {
  shirtNumber: number | null | string
}

/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
export function useLineupsSelectOptions(
  incident: Ref<ProbableIncident> | null,
  side = TeamSide.Home,
  assignableIncidentConfiguration?: AssignableIncidentConfiguration | null,
) {
  const { probableSelectableHomeLineups, probableSelectableAwayLineups } =
    useEventInjections()
  const { eventSeasonParticipants } = useEventSeasonParticipants()

  const selectedMainParticipant = ref(incident?.value.subparticipant_id || null)
  const selectedSecondaryParticipant = ref(incident?.value.assistant_id || null)
  const selectedTernaryParticipant = ref(
    incident?.value.secondary_assistant_id || null,
  )
  const sidesToLoadPlayersFrom: Ref<Set<TeamSide>> = ref(new Set([side]))

  const lineups: Ref<EventsLineupsParticipant[]> = computed(() =>
    [...sidesToLoadPlayersFrom.value]
      .map((side) =>
        side === TeamSide.Home
          ? probableSelectableHomeLineups.value
          : probableSelectableAwayLineups.value,
      )
      .flat(),
  )

  const oppositeLineups: Ref<EventsLineupsParticipant[]> = computed(() =>
    [...sidesToLoadPlayersFrom.value]
      .map((side) =>
        side === TeamSide.Home
          ? probableSelectableAwayLineups.value
          : probableSelectableHomeLineups.value,
      )
      .flat(),
  )

  watchAttributesChange(side, incident, sidesToLoadPlayersFrom)
  watchSidesToLoadPlayersFromChange(
    sidesToLoadPlayersFrom,
    selectedMainParticipant,
    selectedSecondaryParticipant,
  )

  watch(
    () => incident?.value.subparticipant_id,
    (participantId) => {
      selectedMainParticipant.value = participantId ?? null
    },
  )

  watch(
    () => incident?.value.additional_info?.assistant_id,
    (participantId) => {
      selectedSecondaryParticipant.value = participantId ?? null
    },
  )

  watch(
    () => incident?.value.additional_info?.secondary_assistant_id,
    (participantId) => {
      selectedTernaryParticipant.value = participantId ?? null
    },
  )

  function getLineupsParticipants(
    loadOppositeTeam: boolean | undefined,
    selectedParticipant: Ref<number | null | undefined>,
  ): EventsLineupsParticipant[] {
    if (loadOppositeTeam) {
      return createSubParticipants(
        oppositeLineups,
        eventSeasonParticipants,
        selectedParticipant,
      )
    }
    return createSubParticipants(
      lineups,
      eventSeasonParticipants,
      selectedParticipant,
    )
  }

  return {
    selectedMainParticipant: computed(() => selectedMainParticipant.value),
    selectedSecondaryParticipant: computed(
      () => selectedSecondaryParticipant.value,
    ),
    selectedTernaryParticipant: computed(
      () => selectedTernaryParticipant.value,
    ),
    mainLineupsParticipants: computed(() => {
      const selectedParticipant = assignableIncidentConfiguration
        ?.participantsConfiguration?.disablePlayerFilteringRestriction
        ? ref(null)
        : selectedSecondaryParticipant

      return getLineupsParticipants(
        assignableIncidentConfiguration?.participantsConfiguration?.main
          ?.loadOppositeTeam,
        selectedParticipant,
      )
    }),
    secondaryLineupsParticipants: computed(() => {
      const selectedParticipant = assignableIncidentConfiguration
        ?.participantsConfiguration?.disablePlayerFilteringRestriction
        ? ref(null)
        : selectedMainParticipant

      return getLineupsParticipants(
        assignableIncidentConfiguration?.participantsConfiguration?.secondary
          ?.loadOppositeTeam,
        selectedParticipant,
      )
    }),
    ternaryLineupsParticipants: computed(() => {
      const selectedParticipant = assignableIncidentConfiguration
        ?.participantsConfiguration?.disablePlayerFilteringRestriction
        ? ref(null)
        : selectedMainParticipant

      return getLineupsParticipants(
        assignableIncidentConfiguration?.participantsConfiguration?.ternary
          ?.loadOppositeTeam,
        selectedParticipant,
      )
    }),
    oppositeLineupsParticipants: computed(() =>
      getLineupsParticipants(true, selectedSecondaryParticipant),
    ),
    lineupsSelectOptions: computed(() =>
      createLineupsSelectOptions(lineups, eventSeasonParticipants),
    ),
    lineupsBothSideSelectOptions: computed(() =>
      createLineupsSelectOptions(
        ref([...lineups.value, ...oppositeLineups.value]),
        eventSeasonParticipants,
      ),
    ),
    updateMainParticipant: updateParticipant(selectedMainParticipant),
    updateSecondaryParticipant: updateParticipant(selectedSecondaryParticipant),
    updateTernaryParticipant: updateParticipant(selectedTernaryParticipant),
  }
}

function watchAttributesChange(
  defaultSide: TeamSide,
  incident: Ref<ProbableIncident> | null,
  sidesToLoadPlayersFrom: Ref<Set<TeamSide>>,
): void {
  const oppositeSide = getOppositeSide(defaultSide)

  const { assignableIncidentsConfiguration } = useSportConfiguration()
  const assignableIncidentConfiguration = incident?.value.incident_id
    ? assignableIncidentsConfiguration[incident.value.incident_id]
    : null

  watch(
    () => incident?.value.attribute_ids,
    (attributes) => {
      const shouldLoadOppositeTeam = attributes?.some(
        (attribute) =>
          !!assignableIncidentConfiguration?.attributesConfiguration?.[
            attribute
          ]?.loadOppositeTeamWhenSelected,
      )
      if (shouldLoadOppositeTeam) {
        sidesToLoadPlayersFrom.value.add(oppositeSide)
      } else {
        sidesToLoadPlayersFrom.value.delete(oppositeSide)
      }

      if (shouldLoadOppositeTeam) {
        sidesToLoadPlayersFrom.value.delete(defaultSide)
      }

      if (!sidesToLoadPlayersFrom.value.size) {
        sidesToLoadPlayersFrom.value.add(defaultSide)
      }
    },
    {
      immediate: true,
      deep: true,
    },
  )
}

function watchSidesToLoadPlayersFromChange(
  sidesToLoadPlayersFrom: Ref<Set<TeamSide>>,
  selectedMainParticipant: Ref<number | null | undefined>,
  selectedSecondaryParticipant: Ref<number | null | undefined>,
): void {
  watch(
    sidesToLoadPlayersFrom,
    () => {
      selectedMainParticipant.value = null
      selectedSecondaryParticipant.value = null
    },
    { deep: true },
  )
}

function updateParticipant(
  participant: Ref<number | null | undefined>,
): (participantId: number | null | undefined) => void {
  return (participantId) =>
    (participant.value = participantId === 0 ? null : participantId)
}

function createLineupsSelectOptions(
  lineups: Ref<EventsLineupsParticipant[]>,
  eventSeasonParticipants: Ref<SubParticipant[]>,
): SelectOptionWithShirtNumber[] {
  const notSelectedSelectOption: SelectOptionWithShirtNumber = {
    name: 'None',
    value: 0,
    shirtNumber: null,
  }

  return [
    notSelectedSelectOption,
    ...chain(lineups.value)
      .map((subParticipant) => {
        return {
          name: getSubParticipantName(
            subParticipant,
            eventSeasonParticipants.value,
          ),
          value: subParticipant.participant_id,
          shirtNumber: subParticipant.participant_shirt_number,
        }
      })
      .value(),
  ]
}

function createSubParticipants(
  lineups: Ref<EventsLineupsParticipant[]>,
  eventSeasonParticipants: ComputedRef<SubParticipant[]>,
  selectedParticipant?: Ref<number | null | undefined>,
): EventsLineupsParticipant[] {
  const notSelectedSubParticipant: EventsLineupsParticipant = {
    participant_name: 'None',
    participant_id: null,
    participant_team_id: 0,
    participant_position: null,
    participant_is_captain: false,
    participant_shirt_number: null,
    player_position: null,
    participant_short_name: 'None',
  }

  return [
    notSelectedSubParticipant,
    ...chain(lineups.value)
      .filter(({ participant_id }) =>
        !selectedParticipant
          ? true
          : participant_id !== selectedParticipant.value,
      )
      .orderBy(
        [
          (player) => player.participant_position,
          (player) => player.participant_shirt_number !== null || '',
        ],
        ['asc'],
      )
      .map((subParticipant) => ({
        ...subParticipant,
        participant_name: getSubParticipantName(
          subParticipant,
          eventSeasonParticipants.value,
        ),
      }))
      .value(),
  ]
}

function getSubParticipantName(
  eventsLineupsParticipant: EventsLineupsParticipant,
  eventSeasonParticipants: SubParticipant[],
): string {
  const seasonParticipant = byId(
    eventSeasonParticipants,
    eventsLineupsParticipant.participant_id,
  )

  return (
    seasonParticipant?.short_name || eventsLineupsParticipant.participant_name
  )
}
