import { Container } from 'inversify'

import { IncidentId } from '@collector/sportsapi-client-legacy'
import { AddIncident } from '@mobile/ActionQueue/Actions/Actions'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { IncidentHandler } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/IncidentHandlersConfiguration'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindIncidentHandlersConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.IncidentHandlersConfiguration)
    .toDynamicValue(async (ctx) => {
      const [actionQueue, probableEvent, probableClock] = await Promise.all([
        await ctx.container.getAsync(RelationIoCBindings.ActionQueue),
        await ctx.container.getAsync(RelationIoCBindings.ProbableEvent),
        await ctx.container.getAsync(RelationIoCBindings.ProbableClock),
        await ctx.container.getAsync(
          RelationIoCBindings.ProbableLinkedHomeParticipant,
        ),
        await ctx.container.getAsync(
          RelationIoCBindings.ProbableLinkedAwayParticipant,
        ),
        await ctx.container.getAsync(RelationIoCBindings.SidesSwitched),
      ])
      const eventStatusesConfiguration = ctx.container.get(
        RelationSportCommonIoCBindings.EventStatusesConfiguration,
      )

      const addIncident: IncidentHandler['handler'] = ({
        incidentId,
        participantId,
        status,
        time = {
          minute: probableClock.value.minute,
          second: probableClock.value.second,
        },
        attributeIds = [],
      }) => {
        actionQueue.value.add(
          new AddIncident({
            incident_id: incidentId,
            status_id: status ?? probableEvent.value.status_id,
            participant_team_id: participantId,
            ...time,
            attribute_ids: attributeIds,
          }),
        )
      }

      const isDisabledByCurrentEventStatus = (
        incidentId: IncidentId,
      ): boolean => {
        const eventStatusConfiguration =
          eventStatusesConfiguration[probableEvent.value.status_id]

        return !eventStatusConfiguration?.allowedIncidents?.includes(incidentId)
      }

      return {
        fallbackHandler: {
          handler: ({ incidentId, participantId, time, status }) =>
            addIncident({
              time,
              status,
              incidentId,
              participantId,
            }),
          disabled: ({ incidentId }) =>
            isDisabledByCurrentEventStatus(incidentId),
        },
      }
    })
    .inSingletonScope()
}
