<script lang="ts">
import { computed, defineComponent, watch } from 'vue'

import PaginationPage from '@mobile/components/Pagination/PaginationPage.vue'

export default defineComponent({
  components: { PaginationPage },
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    currentPageTotal: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:currentPage'],
  setup(props, { emit }) {
    const pagesPerSide = 3

    const totalPages = computed(() => Math.ceil(props.total / props.perPage))

    const prevDisabled = computed(
      () => props.currentPage === 1 || totalPages.value === 0,
    )
    const nextDisabled = computed(
      () => props.currentPage === totalPages.value || totalPages.value === 0,
    )

    const adjacentPages = computed(() => {
      let left = resolveLeftSidePages(
        totalPages.value,
        props.currentPage,
        pagesPerSide,
      )
      let right = resolveRightSidePages(
        totalPages.value,
        props.currentPage,
        pagesPerSide,
      )

      // Fill as many page slots as possible
      if (left.length < pagesPerSide) {
        const leftoverPages = pagesPerSide - left.length
        right = resolveRightSidePages(
          totalPages.value,
          props.currentPage,
          pagesPerSide + leftoverPages,
        )
      }
      if (right.length < pagesPerSide) {
        const leftoverPages = pagesPerSide - right.length
        left = resolveLeftSidePages(
          totalPages.value,
          props.currentPage,
          pagesPerSide + leftoverPages,
        )
      }

      return {
        left,
        right,
      }
    })

    // When data changes and current page is empty we
    // go back until we find first page with data available
    // or reach first page
    watch(
      () => [props.currentPageTotal, props.currentPage],
      () => {
        if (props.currentPageTotal === 0 && props.currentPage > 1) {
          emit('update:currentPage', props.currentPage - 1)
        }
      },
    )

    return {
      totalPages,
      prevDisabled,
      nextDisabled,
      adjacentPages,
    }
  },
})

function resolveLeftSidePages(
  totalPages: number,
  currentPage: number,
  maxPages: number,
): number[] {
  const pages: number[] = []

  if (totalPages === 0) {
    return pages
  }

  for (let i = currentPage - 1; i > 0; i--) {
    pages.unshift(i)

    if (pages.length >= maxPages) {
      break
    }
  }

  return pages
}

function resolveRightSidePages(
  totalPages: number,
  currentPage: number,
  maxPages: number,
): number[] {
  const pages: number[] = []

  if (totalPages === 0) {
    return pages
  }

  for (let i = currentPage + 1; i <= totalPages; i++) {
    pages.push(i)

    if (pages.length >= maxPages) {
      break
    }
  }

  return pages
}
</script>

<template>
  <div class="flex flex-row space-x-2">
    <PaginationPage
      :active="false"
      :disabled="prevDisabled"
      @click="$emit('update:currentPage', 1)"
    >
      <FontAwesomeIcon icon="angle-double-left" />
    </PaginationPage>
    <PaginationPage
      :active="false"
      :disabled="prevDisabled"
      @click="$emit('update:currentPage', currentPage - 1)"
    >
      <FontAwesomeIcon icon="angle-left" />
    </PaginationPage>

    <PaginationPage
      v-for="page in adjacentPages.left"
      :key="page"
      :active="false"
      @click="$emit('update:currentPage', page)"
    >
      {{ page }}
    </PaginationPage>
    <PaginationPage
      v-if="totalPages > 0"
      :active="true"
    >
      {{ currentPage }}
    </PaginationPage>
    <PaginationPage
      v-for="page in adjacentPages.right"
      :key="page"
      :active="false"
      @click="$emit('update:currentPage', page)"
    >
      {{ page }}
    </PaginationPage>

    <PaginationPage
      :active="false"
      :disabled="nextDisabled"
      @click="$emit('update:currentPage', currentPage + 1)"
    >
      <FontAwesomeIcon icon="angle-right" />
    </PaginationPage>
    <PaginationPage
      :active="false"
      :disabled="nextDisabled"
      @click="$emit('update:currentPage', totalPages)"
    >
      <FontAwesomeIcon icon="angle-double-right" />
    </PaginationPage>
  </div>
</template>
