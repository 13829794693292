export enum AmericanFootballIncidentId {
  Kickoff = 635,
  Touchback = 2618,
  Pass = 652,
  Rush = 653,
  Turnover = 650,
  Interception = 661,
  Touchdown = 637,
  PossibleTwoPointConversion = 2619,
  TwoPointConversion = 641,
  FailedTwoPointConversion = 642,
  ExtraPoint = 646,
  ExtraPointFailed = 2621,
  PossibleExtraPoint = 2620,
  PossibleFieldGoal = 647,
  FieldGoal = 648,
  NoFieldGoal = 649,
  Safety = 640,
  Sack = 654,
  Fumble = 655,
  FumbleRecovered = 656,
  Penalty = 634,
  FairCatch = 659,
  OnsideKick = 639,
  Punt = 651,
  Challenge = 643,
  ChallengeCorrect = 644,
  ChallengeIncorrect = 645,
  Timeout = 632,
  Injury = 625,
  DefensiveReturn = 657,
  Possession = 631,
  FirstDown = 662,
  SecondDown = 663,
  ThirdDown = 664,
  FourthDown = 665,
  InPlay = 2613,
  MatchAboutToStart = 2763,
  FirstQuarterStarted = 601,
  SecondQuarterStarted = 602,
  ThirdQuarterStarted = 603,
  FourthQuarterStarted = 604,
  FirstExtraTimeStarted = 605,
  SecondExtraTimeStarted = 606,
  ThirdExtraTimeStarted = 607,
  FourthExtraTimeStarted = 608,
  FifthExtraTimeStarted = 609,
  EndOfQuarter = 610,
  BreakAfterFirstQuarter = 612,
  BreakAfterSecondQuarter = 613,
  BreakAfterThirdQuarter = 614,
  BreakAfterFourthQuarter = 615,
  BreakAfterFirstExtraTime = 616,
  BreakAfterSecondExtraTime = 617,
  BreakAfterThirdExtraTime = 618,
  BreakAfterFourthExtraTime = 619,
  FirstHalfStarted = 2055,
  SecondHalfStarted = 2057,
  Halftime = 2056,
  Abandoned = 620,
  Cancelled = 621,
  FinishedRegularTime = 624,
  FinishedAfterExtratime = 622,
  FinishedAwardedWin = 623,
  Interrupted = 626,
  Postponed = 627,
  StartDelayed = 628,
  TvTimeout = 633,
  ToFinish = 630,
  PlayUnderReview = 2622,
  Flag = 2623,
  MatchResumed = 1001,
}
