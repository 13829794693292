import { EventStatusId } from '../EventStatusId'
import { ResultId } from '../ResultId'
import { VolleyballEventStatusId } from './EventStatusId'
import { VolleyballResultId } from './VolleyballResultId'

export const volleyballStatusResultsMap: {
  [key in EventStatusId]?: ResultId[]
} = {
  [VolleyballEventStatusId.FirstSet]: [VolleyballResultId.FirstSet],
  [VolleyballEventStatusId.SecondSet]: [VolleyballResultId.SecondSet],
  [VolleyballEventStatusId.ThirdSet]: [VolleyballResultId.ThirdSet],
  [VolleyballEventStatusId.FourthSet]: [VolleyballResultId.FourthSet],
  [VolleyballEventStatusId.FifthSet]: [VolleyballResultId.FifthSet],
  [VolleyballEventStatusId.GoldenSet]: [VolleyballResultId.GoldenSet],
}
