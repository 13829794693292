<script lang="ts">
import { defineComponent } from 'vue'

import Alert from '@mobile/components/Alert/Alert.vue'
import Button from '@mobile/components/Button/Button.vue'

export default defineComponent({
  components: {
    Alert,
    Button,
  },
  emits: ['confirm', 'close'],
})
</script>

<template>
  <Alert>
    <strong>
      Are you sure you want to reset formation? All changes will be lost.
    </strong>
    <template #controls>
      <Button
        class="mx-2"
        color="blue"
        :invertedColor="true"
        size="sm"
        @click="$emit('close')"
      >
        Cancel
      </Button>
      <Button
        class="mx-2"
        color="blue"
        size="sm"
        @click="$emit('confirm')"
      >
        Reset formation
      </Button>
    </template>
  </Alert>
</template>
