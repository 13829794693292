import {
  IceHockeyEventStatusId,
  IceHockeyIncidentId,
} from '@collector/sportsapi-client-legacy'

export const IceHockeyManualAddIncidentIds = [
  IceHockeyIncidentId.Suspension,
  IceHockeyIncidentId.SuspensionOver,
  IceHockeyIncidentId.FaceOff,
  IceHockeyIncidentId.WinningFaceOff,
  IceHockeyIncidentId.ShotOnTarget,
  IceHockeyIncidentId.ShotOffTarget,
  IceHockeyIncidentId.ShotBlocked,
  IceHockeyIncidentId.GoalkeeperChange,
  IceHockeyIncidentId.Hit,
]

export const IceHockeyManualAddStatusIds = [
  IceHockeyEventStatusId.FirstPeriod,
  IceHockeyEventStatusId.SecondPeriod,
  IceHockeyEventStatusId.ThirdPeriod,
  IceHockeyEventStatusId.FirstOvertime,
  IceHockeyEventStatusId.SecondOvertime,
  IceHockeyEventStatusId.ThirdOvertime,
  IceHockeyEventStatusId.FourthOvertime,
]
