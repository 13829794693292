<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { nullCoalesce, opChain } from '@collector/shared-utils'
import Button from '@mobile/components/Button/Button.vue'
import { ButtonColor } from '@mobile/components/Button/ButtonTypes'

import { PopupContext } from '../../types'
import { PopupButtonAttributes } from './PopupButton'

export default defineComponent({
  components: { Button },
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String as PropType<ButtonColor>,
      required: true,
    },
    onClick: {
      type: Function as PropType<(context: PopupContext | null) => void>,
    },
    attributes: {
      type: Object as PropType<PopupButtonAttributes>,
      required: false,
    },
    context: {
      type: Object as PropType<PopupContext | null>,
      required: false,
    },
  },
  emits: ['close'],
  setup() {
    return { opChain, nullCoalesce }
  },
})
</script>

<template>
  <Button
    v-bind="attributes"
    :color
    :size="'lg'"
    :disabled="!!opChain(attributes, (v) => v.disabled)"
    @click="opChain(onClick, (v) => v(nullCoalesce(context, null)))"
  >
    {{ text }}
  </Button>
</template>
