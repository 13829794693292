<script lang="ts">
import { computed, defineComponent } from 'vue'

import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: { IncidentButton },
  props: {
    incidentId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { sport } = useEventInjections()

    const incidentName = computed(() => {
      const sportIncident = sport.value.sportsAPIEntity.incidents.find(
        (incident) => incident.id === props.incidentId,
      )

      return sportIncident?.name ?? `Unknown(${props.incidentId})`
    })

    return { incidentName }
  },
})
</script>

<template>
  <div
    class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
  >
    <IncidentButton
      class="mb-5 h-auto w-44"
      :incidentId
      :color="'white'"
      :size="'md'"
      :type="'vertical'"
      :withIcon="false"
    >
      Send {{ incidentName }}
    </IncidentButton>
  </div>
</template>
