import { IncidentAttributeId } from '@collector/sportsapi-client-legacy'
import { getIncidentAttributeId } from '@mobile/views/Relation/DetailsPanel/Sports/composables/useSportOptions'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'

export function useAttributesSelection(
  linkedIncident: ProbableLinkedIncident,
  attributeId: number,
  attributes: string[],
  selectedAttributes: IncidentAttributeId[],
  relatedAttributes: IncidentAttributeId[],
): {
  isOnlyOneAttributeSelected: boolean
  isAttributeSelected: boolean
  filteredAttributes: IncidentAttributeId[]
} {
  const mappedAttributes = attributes.map((attr) =>
    getIncidentAttributeId(linkedIncident, attr),
  )

  const isOnlyOneAttributeSelected =
    selectedAttributes.filter((item) => mappedAttributes.includes(item))
      .length === 1

  const isAttributeSelected = selectedAttributes.includes(attributeId)

  const filteredAttributes = selectedAttributes.filter(
    (attribute) =>
      attribute !== attributeId && !relatedAttributes.includes(attribute),
  )

  return {
    isOnlyOneAttributeSelected,
    isAttributeSelected,
    filteredAttributes,
  }
}
