<script setup lang="ts">
import { IncidentAttributeId } from '@collector/sportsapi-client-legacy'
import { UpdateIncident } from '@mobile/ActionQueue/Actions/Actions'
import Checkbox from '@mobile/components/MultiCheckbox/Checkbox.vue'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

// composables
const { assignableIncidentsConfiguration } = useSportConfiguration()
const {
  actionQueue,
  probableEvent: {
    value: { id: event_id },
  },
} = useEventInjections()

// props
interface Props {
  linkedIncident: ProbableLinkedIncident
}

const props = defineProps<Props>()

// data
const config =
  assignableIncidentsConfiguration[props.linkedIncident.incident.incident_id]
const attributesToShow: IncidentAttributeId[] = Object.keys(
  config?.attributesConfiguration ?? {},
)
  .map(Number)
  .filter((attributeId) => {
    const attributeConfig =
      config?.attributesConfiguration?.[attributeId as IncidentAttributeId]

    return attributeConfig?.showOnHistoryTableRow
  }) as IncidentAttributeId[]

// computed
function hasIncidentAttribute(attributeId: IncidentAttributeId): boolean {
  return props.linkedIncident.incident.attribute_ids?.includes(attributeId)
}

// methods
function getAttributeName(attributeId: IncidentAttributeId): string {
  return (
    props.linkedIncident.sportIncident.attributes.find(
      ({ id }) => id === attributeId,
    )?.name ?? 'N/A'
  )
}

function addAttributeToIncident(attributeId: IncidentAttributeId): void {
  let attributeIds = props.linkedIncident.incident.attribute_ids ?? []

  attributeIds = [...attributeIds, attributeId]

  updateIncident(attributeIds)
}

function removeAttributeFromIncident(attributeId: IncidentAttributeId): void {
  let attributeIds = props.linkedIncident.incident.attribute_ids ?? []

  attributeIds = attributeIds.filter((id) => id !== attributeId)

  updateIncident(attributeIds)
}

function updateIncident(attributeIds: IncidentAttributeId[]): void {
  const [minute, second] = props.linkedIncident.incident.event_time
    ?.split(':')
    .map(Number) || [0, 0]

  actionQueue.value.add(
    new UpdateIncident(props.linkedIncident.incident.id, {
      event_id,
      incident_id: props.linkedIncident.incident.incident_id,
      status_id: props.linkedIncident.incident.event_status_id,
      attribute_ids: attributeIds,
      participant_team_id: props.linkedIncident.incident.participant_id,
      participant_id: props.linkedIncident.incident.subparticipant_id,
      participant_name: props.linkedIncident.incident.subparticipant_id
        ? props.linkedIncident.incident.subparticipant_name
        : '',
      assistant_id: props.linkedIncident.incident.additional_info?.assistant_id,
      assistant_name:
        props.linkedIncident.incident.additional_info?.assistant_name,
      secondary_assistant_id:
        props.linkedIncident.incident.additional_info?.secondary_assistant_id,
      secondary_assistant_name:
        props.linkedIncident.incident.additional_info?.secondary_assistant_name,
      goalkeeper_id:
        props.linkedIncident.incident.additional_info?.goalkeeper_id,
      properties: props.linkedIncident.incident.properties,
      minute,
      second,
    }),
  )
}
</script>

<template>
  <Checkbox
    v-for="attributeId in attributesToShow"
    :key="`attribute-checkbox-${attributeId}`"
    :label="getAttributeName(attributeId)"
    :checked="hasIncidentAttribute(attributeId)"
    :fieldId="'1'"
    @update:checked="
      ($event) =>
        $event
          ? addAttributeToIncident(attributeId)
          : removeAttributeFromIncident(attributeId)
    "
  />
</template>
