<script lang="ts">
import { defineComponent } from 'vue'

import LogoDev from '@mobile/assets/logo-development.svg'
import Logo from '@mobile/assets/logo.svg'

export default defineComponent({
  setup() {
    const logoSrc =
      import.meta.env.VITE_APP_MODE === 'production' ? Logo : LogoDev
    return { logoSrc }
  },
})
</script>

<template>
  <img
    :src="logoSrc"
    alt="STATSCORE Logo"
  />
</template>
