import {
  BasketballAttributesConfiguration,
  BasketballDetailsConfiguration,
  BasketballGroupNames,
} from './BasketballDetailsConfiguration'
import {
  BasketballDetailsIncidentIds,
  BasketballHideIncidentIds,
  BasketballParticipantIncidentIds,
  BasketballSecondParticipantIncidentIds,
} from './BasketballFiltersIncidentIds'
import {
  BasketballManualAddIncidentIds,
  BasketballManualAddIncidentSubstitutionIds,
} from './BasketballManualAddIncidentIds'
import { BasketballManualAddStatusIds } from './BasketballManualAddStatusIds'

export const BasketballConfiguration = {
  basketballGroupNames: BasketballGroupNames,
  basketballDetailsConfiguration: BasketballDetailsConfiguration,
  basketballAttributesConfiguration: BasketballAttributesConfiguration,
  basketballHideIncidentIds: BasketballHideIncidentIds,
  basketballParticipantIncidentIds: BasketballParticipantIncidentIds,
  basketballSecondParticipantIncidentIds:
    BasketballSecondParticipantIncidentIds,
  basketballDetailsIncidentIds: BasketballDetailsIncidentIds,
  basketballManualAddIncidentIds: BasketballManualAddIncidentIds,
  basketballManualAddIncidentSubstitutionIds:
    BasketballManualAddIncidentSubstitutionIds,
  basketballManualAddStatusIds: BasketballManualAddStatusIds,
}
