import dayjs from 'dayjs'
import Dexie from 'dexie'
import { cloneDeep } from 'lodash'

import { CollectorPushMessageEvent } from '@collector/sportsapi-client-legacy'
import { Repository } from '@mobile/database/Repository'

export class EventsRepository extends Repository<CollectorPushMessageEvent> {
  static readonly tableName = 'events'
  static readonly indexes = 'eventId, uuid, pushMessageUt'

  constructor(database: Dexie) {
    super(database.table<CollectorPushMessageEvent>(EventsRepository.tableName))

    this.table.hook('reading', (collectorPushMessageEvent) => {
      if (!collectorPushMessageEvent) {
        return collectorPushMessageEvent
      }

      const newCollectorPushMessageEvent = cloneDeep(collectorPushMessageEvent)
      newCollectorPushMessageEvent.data.start_date = dayjs(
        newCollectorPushMessageEvent.data.start_date,
        'YYYY-MM-DD HH:mm',
      )
        .subtract(new Date().getTimezoneOffset(), 'minutes')
        .format('YYYY-MM-DD HH:mm')

      return newCollectorPushMessageEvent
    })
  }

  public async getByEventId(
    eventId: number,
  ): Promise<CollectorPushMessageEvent | undefined> {
    return await this.table.get(eventId)
  }

  public async getLast(): Promise<CollectorPushMessageEvent | undefined> {
    return await this.table.orderBy('pushMessageUt').last()
  }

  public async removeById(eventId: number): Promise<unknown> {
    return await this.table.delete(eventId)
  }

  public async save(event: CollectorPushMessageEvent): Promise<void> {
    try {
      await this.table.put(event)
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.log(
        `Couldn't save or update event ${event.eventId}. Reason: ${(error as Error).message}`,
      )

      throw error
    }
  }
}
