import { Container } from 'inversify'

import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

export function bindLineupsConfiguration(ioc: Container): void {
  ioc
    .bind(RelationSportCommonIoCBindings.LineupsConfiguration)
    .toDynamicValue(() => {
      return {
        availablePositions: [],
        lineupPositionOrder: [],
      }
    })
    .inSingletonScope()
}
