import { computed } from 'vue'

import {
  BasketballEventDetailId,
  BasketballEventStatusId,
  BasketballIncidentId,
} from '@collector/sportsapi-client-legacy'
import { byId } from '@mobile/reusables/entityUtils'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import SidesSwitchOverlay from '@mobile/views/Relation/Shared/SidesSwitchOverlay/SidesSwitchOverlay.vue'
import StatusButtonOverlay from '@mobile/views/Relation/Shared/StatusButtonOverlay/StatusButtonOverlay.vue'

import StartWithPossessionOverlay from './PitchOverlays/StartWithPossessionOverlay.vue'

/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
export function usePitchOverlays() {
  const { probableIncidents, probableEvent } = useEventInjections()

  const overlayComponents = computed(() => {
    return [
      {
        component: SidesSwitchOverlay,
        isVisible: !probableIncidents.value.find(
          (incident) =>
            incident.incident_id === BasketballIncidentId.MatchAboutToStart,
        ),
        props: { incidentId: BasketballIncidentId.MatchAboutToStart },
      },
      {
        component: StatusButtonOverlay,
        isVisible: !probableIncidents.value.find(
          (incident) =>
            incident.incident_id === BasketballIncidentId.FirstQuarterStarted,
        ),
        props: { incidentId: BasketballIncidentId.FirstQuarterStarted },
      },
      {
        component: SidesSwitchOverlay,
        isVisible:
          probableEvent.value.status_id ===
          BasketballEventStatusId.BreakAfterFirstQuarter,
        props: { incidentId: BasketballIncidentId.SecondQuarterStarted },
      },
      {
        component: SidesSwitchOverlay,
        isVisible:
          probableEvent.value.status_id ===
          BasketballEventStatusId.BreakAfterSecondQuarter,
        props: { incidentId: BasketballIncidentId.ThirdQuarterStarted },
      },
      {
        component: SidesSwitchOverlay,
        isVisible:
          probableEvent.value.status_id ===
          BasketballEventStatusId.BreakAfterThirdQuarter,
        props: { incidentId: BasketballIncidentId.FourthQuarterStarted },
      },
      {
        component: SidesSwitchOverlay,
        isVisible:
          probableEvent.value.status_id ===
          BasketballEventStatusId.WaitingForOvertime,
        props: { incidentId: BasketballIncidentId.FirstOvertimeStarted },
      },
      {
        component: SidesSwitchOverlay,
        isVisible:
          probableEvent.value.status_id ===
          BasketballEventStatusId.BreakAfterFirstOvertime,
        props: { incidentId: BasketballIncidentId.SecondOvertimeStarted },
      },
      {
        component: SidesSwitchOverlay,
        isVisible:
          probableEvent.value.status_id ===
          BasketballEventStatusId.BreakAfterSecondOvertime,
        props: { incidentId: BasketballIncidentId.ThirdOvertimeStarted },
      },
      {
        component: SidesSwitchOverlay,
        isVisible:
          probableEvent.value.status_id ===
          BasketballEventStatusId.BreakAfterThirdOvertime,
        props: { incidentId: BasketballIncidentId.FourthOvertimeStarted },
      },
      {
        component: SidesSwitchOverlay,
        isVisible:
          probableEvent.value.status_id ===
          BasketballEventStatusId.BreakAfterFourthOvertime,
        props: { incidentId: BasketballIncidentId.FifthOvertimeStarted },
      },
      {
        component: StatusButtonOverlay,
        isVisible:
          probableIncidents.value.find(
            (incident) =>
              incident.incident_id === BasketballIncidentId.FirstQuarterStarted,
          ) &&
          !probableIncidents.value.find(
            (incident) =>
              incident.incident_id === BasketballIncidentId.JumpBall,
          ) &&
          !byId(
            probableEvent.value.details,
            BasketballEventDetailId.JumpBallWinner,
          )?.value,
        props: { incidentId: BasketballIncidentId.JumpBall },
      },
      {
        component: StartWithPossessionOverlay,
        isVisible:
          probableIncidents.value.find(
            (incident) =>
              incident.incident_id === BasketballIncidentId.FirstQuarterStarted,
          ) &&
          !byId(
            probableEvent.value.details,
            BasketballEventDetailId.JumpBallWinner,
          )?.value,
        props: { incidentId: BasketballIncidentId.InPossession },
      },
    ]
  })

  const visibleOverlay = computed(() =>
    overlayComponents.value.find(({ isVisible }) => isVisible),
  )

  return {
    overlayComponents,
    visibleOverlay,
  }
}
