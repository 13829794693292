<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({})
</script>

<template>
  <div class="fixed left-0 top-0 z-40 block h-full w-full">
    <span
      class="relative top-1/2 mx-auto my-0 block h-4 w-4 text-green-500 opacity-75"
    >
      <FontAwesomeIcon
        class="text-blue animate-spin text-3xl"
        icon="spinner"
      />
    </span>
  </div>
</template>
