<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import {
  VolleyballEventStatusId,
  VolleyballResultId,
} from '@collector/sportsapi-client-legacy'
import { byId } from '@mobile/reusables/entityUtils'
import { ProbableLinkedParticipant } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  props: {
    probableLinkedParticipant: {
      type: Object as PropType<ProbableLinkedParticipant>,
      required: true,
    },
  },

  setup(props) {
    const { probableEvent } = useEventInjections()
    const mappedStatsByStatus: Readonly<
      Map<VolleyballEventStatusId, VolleyballResultId>
    > = new Map([
      [VolleyballEventStatusId.FirstSet, VolleyballResultId.FirstSet],
      [VolleyballEventStatusId.SecondSet, VolleyballResultId.SecondSet],
      [VolleyballEventStatusId.ThirdSet, VolleyballResultId.ThirdSet],
      [VolleyballEventStatusId.FourthSet, VolleyballResultId.FourthSet],
      [VolleyballEventStatusId.FifthSet, VolleyballResultId.FifthSet],
      [VolleyballEventStatusId.GoldenSet, VolleyballResultId.GoldenSet],
    ])

    const setResult = computed(
      () =>
        byId(
          props.probableLinkedParticipant.getParticipant().value.results,
          mappedStatsByStatus.get(
            probableEvent.value.status_id as VolleyballEventStatusId,
          ),
        )?.value ?? '-',
    )

    return { setResult }
  },
})
</script>

<template>
  <div
    class="shadow-condensed flex h-7 w-10 items-center justify-center rounded p-1 text-xl font-bold text-white"
    :class="probableLinkedParticipant.css.bgClass"
  >
    {{ setResult }}
  </div>
</template>
