import { CommonResultId } from '../common/CommonResultId'
import { EventStatusId } from '../EventStatusId'
import { ResultId } from '../ResultId'
import { BasketballResultId } from './BasketballResultId'
import { BasketballEventStatusId } from './EventStatusId'

export const basketballStatusResultsMap: {
  [key in EventStatusId]?: ResultId[]
} = {
  [BasketballEventStatusId.FirstHalf]: [
    CommonResultId.Result,
    CommonResultId.RegularTime,
    BasketballResultId.FirstHalf,
  ],
  [BasketballEventStatusId.SecondHalf]: [
    CommonResultId.Result,
    CommonResultId.RegularTime,
    BasketballResultId.SecondHalf,
  ],
  [BasketballEventStatusId.FirstQuarter]: [
    CommonResultId.Result,
    CommonResultId.RegularTime,
    BasketballResultId.FirstQuarter,
    BasketballResultId.FirstHalf,
  ],
  [BasketballEventStatusId.SecondQuarter]: [
    CommonResultId.Result,
    CommonResultId.RegularTime,
    BasketballResultId.SecondQuarter,
    BasketballResultId.FirstHalf,
  ],
  [BasketballEventStatusId.ThirdQuarter]: [
    CommonResultId.Result,
    CommonResultId.RegularTime,
    BasketballResultId.ThirdQuarter,
    BasketballResultId.SecondHalf,
  ],
  [BasketballEventStatusId.FourthQuarter]: [
    CommonResultId.Result,
    CommonResultId.RegularTime,
    BasketballResultId.FourthQuarter,
    BasketballResultId.SecondHalf,
  ],
}
