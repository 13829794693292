<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
})
</script>

<template>
  <button
    class="border-blue flex h-8 w-8 min-w-min cursor-pointer flex-row items-center justify-center rounded border border-solid p-1 text-xs font-bold disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-60"
    :class="{
      'bg-blue text-white': active,
      'text-blue bg-white': !active,
    }"
    :disabled
  >
    <slot></slot>
  </button>
</template>
