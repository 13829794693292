<script lang="ts">
import { cloneDeep } from 'lodash'
import { defineComponent, PropType } from 'vue'

import {
  BasketballEventDetailId,
  BasketballIncidentId,
  TeamSide,
} from '@collector/sportsapi-client-legacy'
import { UpdateEvent } from '@mobile/ActionQueue/Actions/Actions'
import ParticipantLogo from '@mobile/components/ParticipantLogo/ParticipantLogo.vue'
import { resolveUpdateEventDetailsObjectFromEventDetailsDiff } from '@mobile/reusables/eventUtils'
import IncidentButton from '@mobile/views/Relation/Shared/IncidentButton/IncidentButton.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

export default defineComponent({
  components: {
    IncidentButton,
    ParticipantLogo,
  },
  props: {
    incidentId: {
      type: Number,
      required: true,
    },
    time: Object as PropType<{ minute: number; second: number }>,
  },
  setup() {
    const {
      probableAwayParticipant,
      probableHomeParticipant,
      sidesSwitched,
      actionQueue,
      probableEvent,
      sport,
    } = useEventInjections()

    function setJumpBallWinnerDetail(
      participantId: number,
      participantSide: string,
    ): void {
      actionQueue.value.add(
        new UpdateEvent({
          details: resolveUpdateEventDetailsObjectFromEventDetailsDiff(
            cloneDeep(probableEvent.value.details),
            [
              {
                id: BasketballEventDetailId.JumpBallWinner,
                value: participantSide,
              },
            ],
            sport.value.sportsAPIEntity,
          ),
          event: {},
        }),
      )
    }

    return {
      probableAwayParticipant,
      probableHomeParticipant,
      BasketballIncidentId,
      sidesSwitched,
      setJumpBallWinnerDetail,
      TeamSide,
    }
  },
})
</script>

<template>
  <div class="absolute inset-0 flex h-full w-full flex-col space-y-2 p-1">
    <div
      class="from-darkblue to-blue mx-auto w-56 bg-gradient-to-r py-1 text-center text-white"
    >
      <span class="relative top-0 block text-sm font-bold">
        Start with possession
      </span>
    </div>
    <div class="flex w-full flex-1">
      <div
        class="flex w-full space-x-4 px-2"
        :class="{
          'flex-row-reverse space-x-reverse': sidesSwitched,
        }"
      >
        <IncidentButton
          class="h-full w-full"
          type="vertical"
          :participantId="probableHomeParticipant.id"
          :incidentId="BasketballIncidentId.InPossession"
          :color="'home'"
          :size="'sm'"
          :withIcon="false"
          @click="
            setJumpBallWinnerDetail(probableHomeParticipant.id, TeamSide.Home)
          "
        >
          <div class="m-auto flex flex-col items-center text-center">
            <ParticipantLogo
              class="h-20 w-20"
              :participant="probableHomeParticipant"
            />
            <span
              class="overflow-x-hidden overflow-ellipsis whitespace-nowrap text-center sm:w-20 lg:w-3/4"
            >
              {{ probableHomeParticipant.short_name }}
            </span>
          </div>
        </IncidentButton>

        <IncidentButton
          class="h-full w-full"
          type="vertical"
          :participantId="probableAwayParticipant.id"
          :incidentId="BasketballIncidentId.InPossession"
          :color="'away'"
          :size="'sm'"
          :withIcon="false"
          @click="
            setJumpBallWinnerDetail(probableAwayParticipant.id, TeamSide.Away)
          "
        >
          <div class="m-auto flex flex-col items-center text-center">
            <ParticipantLogo
              class="h-20 w-20"
              :participant="probableAwayParticipant"
            />
            <span
              class="overflow-x-hidden overflow-ellipsis whitespace-nowrap text-center sm:w-20 lg:w-3/4"
            >
              {{ probableAwayParticipant.short_name }}
            </span>
          </div>
        </IncidentButton>
      </div>
    </div>
  </div>
</template>
