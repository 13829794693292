<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { opChain } from '@collector/shared-utils'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'

import RelationIcon, {
  RelationIconType,
} from '../../../RelationIcon/RelationIcon.vue'

export default defineComponent({
  components: { RelationIcon },
  props: {
    linkedIncident: {
      type: Object as PropType<ProbableLinkedIncident>,
      required: true,
    },
    showParticipants: {
      type: Boolean,
      required: true,
      default: false,
    },
    showAttributes: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    function getAttributesNames(attributeIds: number[]): string {
      const attributesList =
        props.linkedIncident.sportIncident.attributes.filter((attribute) => {
          return attributeIds.includes(attribute.id)
        })

      return attributesList.map((attribute) => attribute.name).join(', ')
    }

    function hasAttributes(incident: ProbableIncident): boolean {
      return !!getAttributesNames(incident.attribute_ids).length
    }

    return {
      getAttributesNames,
      RelationIconType,
      hasAttributes,
      opChain,
    }
  },
})
</script>

<template>
  <div class="flex flex-1 items-center">
    <RelationIcon
      class="mr-2"
      :size="18"
      :iconId="linkedIncident.incident.incident_id"
      :type="RelationIconType.Incidents"
    />
    <div class="flex flex-col">
      <span
        class="text-xxs font-bold"
        :class="
          opChain(
            linkedIncident.probableLinkedParticipant,
            (v) => v.css.textClass,
          )
        "
      >
        {{ linkedIncident.incident.participant_name }}
      </span>
      <div class="flex text-base font-bold">
        {{ linkedIncident.incident.incident_name }}
        <span
          v-if="showParticipants"
          class="ml-1"
        >
          {{ linkedIncident.incident.subparticipant_name }}
        </span>
        <span
          v-if="
            showParticipants &&
            opChain(
              linkedIncident.incident.additional_info,
              (v) => v.assistant_name,
            )
          "
          class="ml-1"
        >
          {{
            opChain(
              linkedIncident.incident.additional_info,
              (v) => v.assistant_name,
            )
          }}
        </span>
        <div
          v-if="hasAttributes(linkedIncident.incident) && showAttributes"
          class="ml-1.5 space-x-1.5"
        >
          <span>-</span>
          <span>
            {{ getAttributesNames(linkedIncident.incident.attribute_ids) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
