import { watch } from 'vue'
import { WretchError } from 'wretch'

import * as app from '@mobile/globalState/app'
import * as scoutMessenger from '@mobile/globalState/scoutMessenger'
import { AppNotificationType } from '@mobile/reusables/types/AppNotificationType'
import { Notifications } from '@mobile/views/Relation/Shared/RelationDependencies/types'

import { Hook } from './Hook'

export class HookSystemTimeValidation implements Hook {
  private unwatchIsOnline?: () => void

  constructor(private readonly notifications: Notifications) {}

  private async verifyTime(): Promise<void> {
    try {
      const hasValidTime = await scoutMessenger.validateSystemTime()

      if (!hasValidTime) {
        this.notifications.sendNotification({
          type: AppNotificationType.Error,
          title: 'Time synchronization error',
          content: `Date/time on your device is probably incorrect.
                  Check your device settings and make sure that you have time synchronization enabled.`,
          details: { hasValidTime },
        })
      }
    } catch (error) {
      const { message } = error as WretchError

      this.notifications.sendNotification({
        type: AppNotificationType.Error,
        title: 'Time synchronization error',
        content: "We couldn't verify if time on your device is correct.",
        details: { message },
      })
    }
  }

  install(): void {
    if (app.isOnline.value) {
      this.verifyTime()
    } else {
      this.unwatchIsOnline = watch(app.isOnline, (isOnline: boolean) => {
        if (!isOnline) {
          return
        }

        this.unwatchIsOnline?.()
        this.verifyTime()
      })
    }
  }

  uninstall(): void {
    this.unwatchIsOnline?.()
  }
}
