<script lang="ts">
import { computed, defineComponent } from 'vue'

import { byId } from '@mobile/reusables/entityUtils'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import RelationIcon, {
  RelationIconType,
} from '@mobile/views/Relation/Shared/RelationIcon/RelationIcon.vue'

export default defineComponent({
  components: { RelationIcon },
  props: {
    statId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const {
      probableFirstParticipant,
      probableLinkedFirstParticipant,
      probableSecondParticipant,
      probableLinkedSecondParticipant,
    } = useEventInjections()
    const leftValue = computed(
      () =>
        byId(probableFirstParticipant.value.stats, props.statId)?.value ?? '-',
    )
    const rightValue = computed(
      () =>
        byId(probableSecondParticipant.value.stats, props.statId)?.value ?? '-',
    )

    return {
      leftValue,
      rightValue,
      RelationIconType,
      probableLinkedFirstParticipant,
      probableLinkedSecondParticipant,
    }
  },
})
</script>

<template>
  <div class="flex items-center justify-center">
    <div
      class="text-xs font-bold"
      :class="probableLinkedFirstParticipant.css.textClass"
    >
      {{ leftValue }}
    </div>
    <RelationIcon
      class="mx-1"
      :iconId="statId"
      :size="16"
      :type="RelationIconType.Stats"
    />
    <div
      class="text-xs font-bold"
      :class="probableLinkedSecondParticipant.css.textClass"
    >
      {{ rightValue }}
    </div>
  </div>
</template>
