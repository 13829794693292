import { Container } from 'inversify'
import { isNumber } from 'lodash'
import { Ref, ref, watch } from 'vue'

import {
  ClockStatus,
  CollectorPushMessageEvent,
  Event,
} from '@collector/sportsapi-client-legacy'
import { getOrThrow } from '@mobile/reusables/entityUtils'
import {
  EventStatusConfiguration,
  EventStatusesConfiguration,
} from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/EventStatusesConfiguration'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'

import { Clock, RelationIoCBindings } from './types'

export function bindClock(ioc: Container): void {
  let unbindClockTicker: () => void

  ioc
    .bind(RelationIoCBindings.FactualClock)
    .toDynamicValue(async (ctx) => {
      const event = await ctx.container.getAsync(
        RelationIoCBindings.FactualEvent,
      )
      const eventStatusesConfiguration = await ctx.container.getAsync(
        RelationSportCommonIoCBindings.EventStatusesConfiguration,
      )

      const eventStatusConfiguration = getOrThrow(
        eventStatusesConfiguration,
        event.value.data.status_id,
      )
      const clockTime = getCurrentClockTime(event.value)
      const { minute, second, minuteFormatted, secondFormatted } =
        getMinuteAndSecond(eventStatusConfiguration, clockTime)
      const status = getCurrentClockStatus(event.value)

      return ref({
        time: clockTime,
        minute,
        second,
        minuteFormatted,
        secondFormatted,
        status,
      })
    })
    .inSingletonScope()
    .onActivation(async (ctx, clock) => {
      const [factualEvent, eventStatusesConfiguration] = await Promise.all([
        await ctx.container.getAsync(RelationIoCBindings.FactualEvent),
        await ctx.container.get(
          RelationSportCommonIoCBindings.EventStatusesConfiguration,
        ),
      ])

      unbindClockTicker = setupClockTicker(
        factualEvent,
        eventStatusesConfiguration,
        clock,
      )

      return clock
    })
    .when(() => true)
    .onDeactivation(async () => {
      unbindClockTicker()
    })
    .when(() => true)
}

function getCurrentClockTime(
  eventMessage: CollectorPushMessageEvent,
): number | null {
  const event = eventMessage.data

  if (
    event.clock_time === null ||
    event.clock_status === null ||
    event.clock_status === ClockStatus.Stopped
  ) {
    return event.clock_time
  } else if (event.clock_status === ClockStatus.Running) {
    const currentUt = Math.floor(Date.now() / 1000)
    const messageUt = eventMessage.pushMessageUt

    return event.clock_time + (currentUt - messageUt)
  }

  return eventMessage.data.clock_time
}

function getTickedClockTime(
  eventStatusConfiguration: EventStatusConfiguration,
  clockTime: number | null,
): number | null {
  if (clockTime === null) {
    return null
  } else {
    const incrementedClockValue = clockTime + 1

    return isTimeInEventStatusBoundary(
      incrementedClockValue,
      eventStatusConfiguration,
    )
      ? incrementedClockValue
      : (eventStatusConfiguration.timeBoundaryInSeconds ?? null)
  }
}

function getCurrentClockStatus(
  eventMessage: CollectorPushMessageEvent,
): ClockStatus | null {
  return eventMessage.data.clock_status
}

export function getMinuteAndSecond(
  eventStatusConfiguration: EventStatusConfiguration,
  clockTime: number | null,
): {
  minute: number | null
  second: number | null
  minuteFormatted: string
  secondFormatted: string
} {
  let minute: number | null
  let second: number | null
  let minuteFormatted: string
  let secondFormatted: string

  if (isNumber(clockTime)) {
    const clockTimeWithPreviousParts =
      clockTime + getCurrentGamePartStartTime(eventStatusConfiguration)

    const extractedTime = extractTime(clockTimeWithPreviousParts)
    minute = extractedTime.minute
    second = extractedTime.second

    if (
      eventStatusConfiguration.clockDirectionDown &&
      eventStatusConfiguration.startTimeFromInSeconds
    ) {
      const elapsedTime =
        eventStatusConfiguration.startTimeFromInSeconds -
        clockTimeWithPreviousParts
      const extractedReverseTime = extractTime(
        elapsedTime > 0 ? elapsedTime : 0,
      )
      minuteFormatted = formatTime(extractedReverseTime.minute)
      secondFormatted = formatTime(extractedReverseTime.second)
    } else {
      minuteFormatted = formatTime(extractedTime.minute)
      secondFormatted = formatTime(extractedTime.second)
    }
  } else {
    minute = null
    second = null

    minuteFormatted = ''
    secondFormatted = ''
  }

  return {
    minute,
    second,
    minuteFormatted,
    secondFormatted,
  }
}

export function extractTime(seconds: number): {
  minute: number
  second: number
} {
  const minute = Math.floor(Math.abs(seconds) / 60)
  const second = Math.abs(seconds) - minute * 60

  return {
    minute,
    second,
  }
}

export function formatTime(time: number): string {
  return time.toString().padStart(2, '0')
}

export function getCurrentGamePartStartTime({
  time,
}: EventStatusConfiguration): number {
  if (time === null) return 0

  return Number(time.minute) * 60 + Number(time.second)
}

export function isTimeInEventStatusBoundary(
  timeInSeconds: number,
  { timeBoundaryInSeconds }: EventStatusConfiguration,
): boolean {
  if (timeBoundaryInSeconds === undefined) {
    return true
  }

  return timeInSeconds <= timeBoundaryInSeconds
}

export function setupClockTicker(
  event: Ref<CollectorPushMessageEvent> | Ref<Event>,
  eventStatusesConfiguration: EventStatusesConfiguration,
  clock: Ref<Clock>,
): () => void {
  let currentClockInterval = 0

  const unwatchClockStatus = watch(
    () => clock.value.status,
    (clockStatus) => {
      if (clockStatus === null || clockStatus === ClockStatus.Stopped) {
        clearInterval(currentClockInterval)
        currentClockInterval = 0

        return
      }

      if (clockStatus !== ClockStatus.Running || currentClockInterval !== 0) {
        return
      }

      currentClockInterval = window.setInterval(() => {
        if (clock.value.status !== ClockStatus.Running) {
          return
        }

        const eventStatusConfiguration =
          'status_id' in event.value
            ? getOrThrow(eventStatusesConfiguration, event.value.status_id)
            : getOrThrow(eventStatusesConfiguration, event.value.data.status_id)

        clock.value.time = getTickedClockTime(
          eventStatusConfiguration,
          clock.value.time,
        )

        const { minute, second, minuteFormatted, secondFormatted } =
          getMinuteAndSecond(eventStatusConfiguration, clock.value.time)
        clock.value.minute = minute
        clock.value.second = second
        clock.value.minuteFormatted = minuteFormatted
        clock.value.secondFormatted = secondFormatted
      }, 1000)
    },
    { immediate: true },
  )

  return () => {
    clearInterval(currentClockInterval)
    unwatchClockStatus()
  }
}
