import { chain } from 'lodash'
import { Ref } from 'vue'

import {
  CommonIncidentId,
  HandballIncidentId,
  IncidentId,
} from '@collector/sportsapi-client-legacy'
import { Action } from '@mobile/ActionQueue/Action'
import { ActionQueue } from '@mobile/ActionQueue/ActionQueue'
import { AddIncident } from '@mobile/ActionQueue/Actions/Actions'
import IncidentAction from '@mobile/ActionQueue/Actions/Incidents/IncidentAction'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import { resolveProbableIncident } from '@mobile/ActionQueue/ActionUtils'
import { EventType } from '@mobile/ActionQueue/EventType'
import { Clock } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { IncidentsConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/IncidentsConfiguration'

export function useTimeoutOver(
  actionQueue: ActionQueue,
  probableIncidents: Ref<ProbableIncident[]>,
  notDeletableProbableIncidents: Ref<ProbableIncident[]>,
  probableClock: Ref<Clock>,
  incidentsConfiguration: IncidentsConfiguration,
): { cleanup: () => void } {
  const addTimeoutOverIncident = onBeforeActionAdd(
    actionQueue,
    probableIncidents,
    notDeletableProbableIncidents,
    probableClock,
    incidentsConfiguration,
  )

  actionQueue.on(EventType.BeforeActionAdd, addTimeoutOverIncident)

  function cleanup(): void {
    actionQueue.off(EventType.BeforeActionAdd, addTimeoutOverIncident)
  }

  return { cleanup }
}

const onBeforeActionAdd = (
  actionQueue: ActionQueue,
  probableIncidents: Ref<ProbableIncident[]>,
  notDeletableProbableIncidents: Ref<ProbableIncident[]>,
  probableClock: Ref<Clock>,
  incidentsConfiguration: IncidentsConfiguration,
): ((action: Action<unknown>) => void) => {
  const notAllowedActionIncidentIds: IncidentId[] = [
    HandballIncidentId.Timeout,
    HandballIncidentId.TimeoutOver,
    CommonIncidentId.Betstart,
    CommonIncidentId.Betstop,
    CommonIncidentId.TechnicalProblems,
  ]
  const incidentsToOmit: IncidentId[] = [
    CommonIncidentId.Betstart,
    CommonIncidentId.Betstop,
    CommonIncidentId.TechnicalProblems,
  ]

  return (action: Action<unknown>): void => {
    if (!(action instanceof IncidentAction)) {
      return
    }

    const currentAction = resolveProbableIncident(
      action,
      actionQueue,
      notDeletableProbableIncidents.value,
      incidentsConfiguration,
    )
    if (
      !currentAction ||
      notAllowedActionIncidentIds.includes(currentAction?.incident_id)
    ) {
      return
    }

    const lastIncident = chain(probableIncidents.value)
      .filter(({ incident_id }) => !incidentsToOmit.includes(incident_id))
      .first()
      .value()

    if (
      lastIncident?.incident_id !== HandballIncidentId.Timeout ||
      !lastIncident.participant_id
    ) {
      return
    }

    actionQueue.add(
      new AddIncident({
        incident_id: HandballIncidentId.TimeoutOver,
        status_id: lastIncident.event_status_id,
        participant_team_id: lastIncident.participant_id,
        minute: probableClock.value.minute,
        second: probableClock.value.second,
      }),
    )
  }
}
