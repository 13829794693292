import { get } from 'lodash'

import { getTimeBasedUUID } from '@collector/shared-utils'
import {
  IncidentsQueuePostDTO,
  Participant,
  SportIncident,
} from '@collector/sportsapi-client-legacy'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import { getCurrentEvent } from '@mobile/globalState/event'
import { getCurrentSport } from '@mobile/globalState/sport'
import * as sportsAPI from '@mobile/globalState/sportsAPI'
import { byId } from '@mobile/reusables/entityUtils'
import { IncidentsConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/IncidentsConfiguration'

import IncidentAction, { ProbableData } from './IncidentAction'

type IncidentQueuePostDTOWithStatusId = Pick<
  Required<IncidentsQueuePostDTO>,
  'status_id'
> &
  IncidentsQueuePostDTO

export default class AddIncident extends IncidentAction {
  public readonly type = 'AddIncident'

  constructor(
    public readonly incidentsQueuePostDTO: IncidentQueuePostDTOWithStatusId,
  ) {
    super()
    this.uuid = getTimeBasedUUID()
  }

  public uuid: string

  public async execImpl(): Promise<string> {
    const event = getCurrentEvent()
    const result = await sportsAPI.state.client.postIncident(
      event.id,
      this.incidentsQueuePostDTO,
      this.uuid,
    )
    this.incidentId = result.incident.data.id

    return this.incidentId
  }

  public applyProbableResult(
    { probableEvent, probableIncidents }: ProbableData,
    incidentsConfiguration: IncidentsConfiguration,
  ): void {
    const sport = getCurrentSport()

    const probableIncident = {
      id: this.id,
      incident_id: this.incidentsQueuePostDTO.incident_id,
      incident_name: this.resolveIncidentName(
        this.incidentsQueuePostDTO.incident_id,
        sport,
      ),
      event_time: this.resolveEventTime(this.incidentsQueuePostDTO),
      event_status_id: this.incidentsQueuePostDTO.status_id,
      event_status_name: this.resolveStatusName(
        this.incidentsQueuePostDTO.status_id,
        sport,
      ),
      participant_id: this.incidentsQueuePostDTO.participant_team_id || null,
      participant_name: this.resolveParticipantName(this.incidentsQueuePostDTO),
      participant_team_id: this.resolveParticipantTeamId(
        this.incidentsQueuePostDTO,
      ),
      x_pos: this.incidentsQueuePostDTO.x_pos ?? null,
      y_pos: this.incidentsQueuePostDTO.y_pos ?? null,
      confirmation: this.incidentsQueuePostDTO.confirmation ?? null,
      attribute_ids: this.incidentsQueuePostDTO.attribute_ids ?? [],
      additional_info: this.incidentsQueuePostDTO.additional_info,
      assistant_id: null,
    }

    if (probableEvent) {
      const incidentConfiguration = get(
        incidentsConfiguration,
        this.incidentsQueuePostDTO.incident_id,
        incidentsConfiguration.fallbackIncidentConfiguration,
      )

      incidentConfiguration.applyAddIncidentResultToEvent?.(
        probableEvent,
        sport,
        this.incidentsQueuePostDTO,
        probableIncident,
        probableIncidents,
      )
    }

    probableIncidents.push(probableIncident)
  }

  protected getSportIncident(
    incidents: SportIncident[],
  ): SportIncident | undefined {
    return byId(incidents, this.incidentsQueuePostDTO.incident_id)
  }

  public getIncidentParticipant(
    participants: Participant[],
  ): Participant | undefined {
    const participantId = this.incidentsQueuePostDTO.participant_team_id

    if (!participantId) {
      return undefined
    }

    return byId(participants, participantId)
  }

  public createProbableIncident(
    incidentsConfiguration: IncidentsConfiguration,
  ): ProbableIncident {
    const tempProbableIncidents: ProbableIncident[] = []
    this.applyProbableResult(
      { probableIncidents: tempProbableIncidents },
      incidentsConfiguration,
    )

    return tempProbableIncidents[0]
  }
}
